import React from 'react';
const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.244" height="20" viewBox="0 0 16.244 20">
      <defs />
      <g transform="translate(0.003 0.001)">
        <path
          fill="#ef212c"
          d="M222.867,154.7a.468.468,0,0,0-.468.468v8.852a.468.468,0,0,0,.937,0v-8.852A.468.468,0,0,0,222.867,154.7Zm0,0"
          transform="translate(-211.985 -147.458)"
        />
        <path
          fill="#ef212c"
          d="M104.867,154.7a.468.468,0,0,0-.468.468v8.852a.468.468,0,0,0,.937,0v-8.852A.468.468,0,0,0,104.867,154.7Zm0,0"
          transform="translate(-99.512 -147.458)"
        />
        <path
          fill="#ef212c"
          d="M1.327,5.953v11.54a2.584,2.584,0,0,0,.687,1.782A2.307,2.307,0,0,0,3.688,20H12.55a2.306,2.306,0,0,0,1.674-.724,2.584,2.584,0,0,0,.687-1.782V5.953a1.789,1.789,0,0,0-.459-3.519h-2.4V1.849A1.84,1.84,0,0,0,10.2,0H6.039a1.84,1.84,0,0,0-1.855,1.85v.585h-2.4a1.789,1.789,0,0,0-.459,3.519ZM12.55,19.062H3.688a1.483,1.483,0,0,1-1.424-1.569V5.994h11.71v11.5A1.483,1.483,0,0,1,12.55,19.062ZM5.121,1.849A.9.9,0,0,1,6.039.936H10.2a.9.9,0,0,1,.918.913v.585h-6ZM1.786,3.371H14.451a.843.843,0,1,1,0,1.686H1.786a.843.843,0,0,1,0-1.686Zm0,0"
          transform="translate(0 0)"
        />
        <path
          fill="#ef212c"
          d="M163.867,154.7a.468.468,0,0,0-.468.468v8.852a.468.468,0,0,0,.937,0v-8.852A.468.468,0,0,0,163.867,154.7Zm0,0"
          transform="translate(-155.748 -147.458)"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
