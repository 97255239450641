import { get, isEmpty } from "lodash";
import { APPLICATION_STATES, SOURCE } from "../../../utils/constants";
import { getAssignedTo } from "../helper";

export const getVersionFullName = (version) => {
  if (version) {
    if (version.includes('NEW')) {
      return 'NEW APPLICATION';
    } else if (version.includes('ER')) {
      return 'EXTERNAL REVISION';
    } else if (version.includes('IR')) {
      return 'INTERNAL REVISION';
    } else if (version.includes('ED')) {
      return 'APPLICATION EDIT';
    }
  }
  return null;
}

export const getPreviousUsers = (app) => {
  const designers = app?.designers;
  const reviewers = app?.reviewers;
  return {
    preDesigner: !isEmpty(designers) ? designers[designers.length - 1]?.user_info : {},
    preReviewer: !isEmpty(reviewers) ? reviewers[reviewers.length - 1]?.user_info : {}
  };
}

export const getSource = (app) => {
  const source = get(app, 'source');
  return source || SOURCE.ENLIGHTEN_PERMIT;
}

export const reloadToMoveToInProgress = (app, emailId) => {
  const stateId = get(app, 'third_party_current_status.state_id', 0);
  const currentUser = getAssignedTo(app)?.email === emailId;
  if (
    currentUser &&
    [APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.id, APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED.id].includes(stateId)
  ) {
    window.location.reload();
  }
}

export const approvalDocExists = (app) => {
  const adminDocs = get(app, 'admin_documents', []);
  const currVersion = get(app, 'current_version', undefined);
  return adminDocs && adminDocs.find(doc => get(doc, 'doc_type', undefined) === 'APPROVAL_DOC' && doc.versions[0] === currVersion);
}