import React, { Fragment } from 'react';
import OtherInfo from '../../../components/Permitting/OtherInformation';
import UploadFile from '../../../components/Permitting/UploadFile';
import { get } from '../../../utils/lodash';
const OtherDetails = ({
  deleteFile,
  fireUpload,
  materialDetails,
  userSelectionData,
  handleChange,
  paymentStatus,
  applicationDetail,
  isMobile,
  photos,
  companyInfo,
  mostUsedValues,
  featureList,
}) => {
  return (
    <Fragment>
      <UploadFile
        type="Document"
        fireUpload={fireUpload}
        deleteFile={deleteFile}
        materialDetails={materialDetails}
        userSelectionData={userSelectionData}
        handleChange={handleChange}
        paymentStatus={paymentStatus}
        applicationDetail={applicationDetail}
        isMobile={isMobile}
        showDocError={get(materialDetails, `errors.otherInfo_document.error`)}
        errorText={get(materialDetails, `errors.otherInfo_document.errorMessage`)}
        featureList={featureList}
      />
      <UploadFile
        type="Photo"
        required
        deleteFile={deleteFile}
        fireUpload={fireUpload}
        photos={photos}
        materialDetails={materialDetails}
        userSelectionData={userSelectionData}
        handleChange={handleChange}
        paymentStatus={paymentStatus}
        applicationDetail={applicationDetail}
        isMobile={isMobile}
      />
      <OtherInfo
        materialDetails={materialDetails}
        handleChange={handleChange}
        applicationDetail={applicationDetail}
        companyInfo={companyInfo}
        mostUsedValues={mostUsedValues}
      />
    </Fragment>
  );
};

export default OtherDetails;
