import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getStatusDetails } from '../../../containers/Admin/helper';
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tab,
  Tabs,
  Divider,
  Box,
} from '@material-ui/core';
import moment from 'moment';
import { getPresingedURL, getFileFromS3, uploadToS3 } from '../../../containers/Admin/actions';
import Documents from './Documents';
import AdminActions from './AdminActions';
import FileUploadDialog from '../../Admin/FileUploadDialog';
import { ACCEPTABLE_FILE_FORMAT } from '../../../containers/Permitting/constants';
import Links from './Links';
import {
  approveDocuments,
  internalKickback,
  moreInformationRequest,
  moveToPermitDrawings,
  moveToQAComplete,
  moveToUnderProgress,
  stampingSubmit,
  saveInternalNotes,
  saveInternalFiles,
  editInternalNotes,
} from '../../../containers/Admin/RequestDetails/actions';
import { APPLICATION_STATES, SOURCE } from '../../../utils/constants';
import { get, isEmpty, includes } from 'lodash';
import { DOC_TYPE } from '../../../containers/Admin/RequestDetails/constants';
import { ADMINS, USERS } from '../../../containers/Admin/constants';
import SnakBar from '../../SnakBar';
import { getSource } from '../../../containers/Admin/RequestDetails/helper';
import OutlinedButton from '../../common/OutlinedButton';
import ContainedButton from '../../common/ContainedButton';
import TextUploadDialog from './TextUploadDialog';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px',
    cursor: 'unset !important',
    '& .MuiExpansionPanelSummary-content': {
      justifyContent: 'space-between',
    },
  },
  heading: {
    fontWeight: 'bold',
    flexShrink: 0,
  },
  listStyle: {
    width: '100%',
    overflow: 'auto',
  },
  link: {
    color: '#3B86FF !important',
    cursor: 'pointer',
  },
  tabsWrapper: {
    '& .MuiTab-root': {
      width: '100px',
      minWidth: '100px',
      maxWidth: '100px',
      textTransform: 'none',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#EA6318',
    },
  },
  activeTab: {
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: '#EA6318',
    opacity: 1,
  },
  inactiveTab: {
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: '#4B4B4B',
    opacity: 0.7,
  },
  tabPanelSection: {
    minHeight: theme.spacing(64.5),
    maxHeight: theme.spacing(64.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  accordionDetails: {
    padding: theme.spacing(1),
  },
});

export class ApplicationDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appDocs: [],
      uploadingFiles: false,
      downLoadError: false,
      downLoadErrorMsgs: [],
      fileDownloadInProgress: false,
      sDetails: {
        sArray: [],
        currentStage: 0,
      },
      showUploadPermitDoc: false,
      currentTab: 0,
      openFileDialog: false,
      fileDialogInfo: {},
      errorMessage: '',
      openAddNoteDialog: false,
      openInternalFileUploadDialog: false,
      internalNotesText: '',
      editInternalNotes: false,
      internalNotesOpendDialogId: '',
      viewInternalNotes: false,
    };
    this.allSelectedFiles = [];
  }

  componentDidMount() {
    const { appDetails } = this.props;
    const sDetails = getStatusDetails(appDetails);
    this.setState({ sDetails });
  }

  handleOpenFileUploadDialog = (fileDialogInfo) => {
    this.setState({ openFileDialog: true, fileDialogInfo });
  };

  callS3AndUpload = (files, comment, isApprovalDoc, isPermitDoc, isResubmissionDoc, isInternalDoc, versions, hasDeviations, cbk) => {
    if (files.length === 0) {
      cbk(this.allSelectedFiles);
      return;
    }
    const file = files[0];
    const typeArray = file.name.split('.');
    const fileType = typeArray ? typeArray[typeArray.length - 1] : '';
    const fileTag = 'PERMIT_DOCS';
    const fileName = `${this.props.appDetails.permit_id}_${fileTag}_${moment().format('DDMMYYYY-hhmmss')}.${fileType}`;
    const docType = isInternalDoc
      ? DOC_TYPE.INTERNAL_DOC
      : isResubmissionDoc
      ? DOC_TYPE.RESUBMISSION_DOC
      : isApprovalDoc
      ? DOC_TYPE.APPROVAL_DOC
      : isPermitDoc
      ? DOC_TYPE.PERMIT_PACKAGE
      : null;
    this.props.getPresingedURL({
      file_name: fileName,
      http_method: 'PUT',
      successCb: (presignedUrl) => {
        this.props.uploadToS3({
          fName: fileName,
          fileObj: file,
          preSignedS3Url: presignedUrl,
          successCbS3: () => {
            this.allSelectedFiles.push({
              file_name: fileName,
              original_file_name: file.name,
              file_size: file.size / 1024 / 1024,
              loading: false,
              comment: comment,
              doc_type: docType,
              versions: versions,
              has_deviations: hasDeviations
            });
            this.callS3AndUpload(
              files.slice(1, files.length),
              comment,
              isApprovalDoc,
              isPermitDoc,
              isResubmissionDoc,
              isInternalDoc,
              versions,
              hasDeviations,
              cbk
            );
          },
        });
      },
    });
  };

  getFileVersions = () => {
    const currentVersion = this.props.permitting_application?.version?.current_version;
    if (this.state.openInternalFileUploadDialog) {
      return [currentVersion, 'Internal'];
    } else if (this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED.id) {
      return [currentVersion, 'Internal'];
    } else if (
      this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.MORE_INFO_REQUESTED.id ||
      this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.QA_COMPLETED.id ||
      this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.STAMPING_SUBMITTED.id
    ) {
      return [currentVersion, 'External'];
    } else if (this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.QA_ON_HOLD.id) {
      const currentIR = this.props.permitting_application?.version?.internal_revision;
      const nextIRVersion = Number(currentIR) + 1;
      return ['IR' + nextIRVersion, 'Internal'];
    }
  };

  fireUpload = (files, comment, kickbackDetails = {}, designer, reviewer, isStamped, retry = 1, hasDeviations) => {
    this.setState({ uploadingFiles: true, errorMessage: '' });
    const versions = this.getFileVersions();
    this.callS3AndUpload(
      files,
      comment,
      this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED.id,
      this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.QA_COMPLETED.id ||
        this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.STAMPING_SUBMITTED.id,
      this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.MORE_INFO_REQUESTED.id,
      this.state.openInternalFileUploadDialog,
      versions,
      hasDeviations === 'true'? true:false,
      () => {
        if (this.state.openInternalFileUploadDialog) {
          this.props.saveInternalFiles({
            permitId: get(this.props.appDetails, 'permit_id'),
            files: this.allSelectedFiles,
            successCbk: () => window.location.reload(),
          });
        } else if (this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED.id) {
          this.props.moveToPermitDrawings({
            permitId: get(this.props.appDetails, 'permit_id'),
            files: this.allSelectedFiles,
            successCbk: () => window.location.reload(),
          });
        } else if (this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.MORE_INFO_REQUESTED.id) {
          this.props.moreInformationRequest({
            permitId: get(this.props.appDetails, 'permit_id'),
            files: this.allSelectedFiles,
            message: comment,
            successCbk: () => window.location.reload(),
            failureCbk: () => {
              if (retry > 0) {
                retry = retry - 1;
                this.fireUpload(files, comment, kickbackDetails, designer, reviewer, isStamped, retry, hasDeviations);
              } else {
                this.allSelectedFiles = [];
                this.setState({
                  uploadingFiles: false,
                  errorMessage: 'Unable to send more information request. Please try again',
                });
              }
            },
          });
        } else if (this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.QA_COMPLETED.id) {
          if (files.length === 0) {
            this.props.approveDocuments({
              permitId: get(this.props.appDetails, 'permit_id'),
              comment: comment,
              successCbk: () => window.location.reload(),
            });
          } else {
            this.props.moveToQAComplete({
              permitId: get(this.props.appDetails, 'permit_id'),
              files: this.allSelectedFiles,
              successCbk: () => window.location.reload(),
              failureCbk: () => {
                if (retry > 0) {
                  retry = retry - 1;
                  this.fireUpload(files, comment, kickbackDetails, designer, reviewer, isStamped, retry, hasDeviations);
                } else {
                  this.allSelectedFiles = [];
                  this.setState({
                    uploadingFiles: false,
                    errorMessage: 'Unable to send permit files. Please try again',
                  });
                }
              },
            });
          }
        } else if (this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.QA_ON_HOLD.id) {
          kickbackDetails.permit_id = this.props.appDetails?.permit_id;
          kickbackDetails.files = this.allSelectedFiles;
          this.props.internalKickback({
            permitId: this.props.appDetails?.permit_id,
            kickbackDetails: kickbackDetails,
            successCbk: () => window.location.reload(),
          });
        } else if (this.state.fileDialogInfo.finalStateId === APPLICATION_STATES.STAMPING_SUBMITTED.id) {
          for (const i in this.allSelectedFiles) {
            this.allSelectedFiles[i].stamped = isStamped;
          }
          this.props.stampingSubmit({
            permitId: get(this.props.appDetails, 'permit_id'),
            files: this.allSelectedFiles,
            successCbk: () => window.location.reload(),
          });
        }
      }
    );
  };

  handleSaveInternalNotes() {
    const currentVersion = this.props.permitting_application?.version?.current_version;
    const versions = [currentVersion, 'Internal'];
    if (this.state.editInternalNotes) {
      this.props.editInternalNotes({
        permitId: get(this.props.appDetails, 'permit_id'),
        internalNotes: {
          content: this.state.internalNotesText,
          versions: versions,
          id: this.state.internalNotesOpendDialogId,
        },
        successCbk: () => window.location.reload(),
      });
    } else {
      this.props.saveInternalNotes({
        permitId: get(this.props.appDetails, 'permit_id'),
        internalNotes: { content: this.state.internalNotesText, versions: versions },
        successCbk: () => window.location.reload(),
      });
    }
  }

  handleCloseInternalNotes() {
    this.setState({
      internalNotesText: '',
      openAddNoteDialog: false,
      editInternalNotes: false,
      viewInternalNotes: false,
    });
  }

  render() {
    const { classes, appDetails, moveToUnderProgress, permitting_application, emailId, permitServiceRole } = this.props;
    const { downLoadError, downLoadErrorMsgs, fileDownloadInProgress, sDetails } = this.state;
    const source = getSource(appDetails);
    return (
      <Fragment>
        {' '}
        <Grid container direction="row">
          <Grid item xs={12}>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary aria-controls="sDetails-content" id="sDetails" className={classes.panelHead}>
                <Typography className={classes.heading}>Documents</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ flexDirection: 'column' }} className={classes.accordionDetails}>
                <Tabs
                  value={this.state.currentTab}
                  onChange={(e, value) => this.setState({ currentTab: value })}
                  className={classes.tabsWrapper}
                >
                  <Tab
                    label="Admin"
                    className={this.state.currentTab === 0 ? classes.activeTab : classes.inactiveTab}
                  />
                  <Tab
                    label="Links"
                    className={this.state.currentTab === 1 ? classes.activeTab : classes.inactiveTab}
                  />
                  <Tab
                    label="Installer"
                    className={this.state.currentTab === 2 ? classes.activeTab : classes.inactiveTab}
                  />
                  {includes([...ADMINS, ...USERS], permitServiceRole) && (
                    <Grid container justifyContent="flex-end">
                      <OutlinedButton
                        text={`Add Internal Note`}
                        handleClick={() => this.setState({ openAddNoteDialog: true })}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <ContainedButton
                        text={`Upload Internal Doc`}
                        handleClick={() => this.setState({ openInternalFileUploadDialog: true })}
                      />
                    </Grid>
                  )}
                </Tabs>
                <Box className={classes.tabPanelSection}>
                  <div hidden={this.state.currentTab !== 0} index={0} className={classes.listStyle}>
                    {this.state.currentTab === 0 && (
                      <Documents
                        appDetails={appDetails}
                        documentType="admin"
                        sDetails={sDetails}
                        fileDownloadInProgress={fileDownloadInProgress}
                        downLoadError={downLoadError}
                        downLoadErrorMsgs={downLoadErrorMsgs}
                        openUploadPermitDocPopup={() => this.setState({ showUploadPermitDoc: true })}
                        pushErrorMessage={(msg) => {
                          let eMsgs = this.state.downLoadErrorMsgs;
                          eMsgs.push(msg);
                          this.setState({ downLoadError: true, downLoadErrorMsgs: eMsgs });
                        }}
                        getPresingedURL={this.props.getPresingedURL}
                        moveToUnderProgress={moveToUnderProgress}
                        emailId={emailId}
                        permitServiceRole={permitServiceRole}
                        handleEditInternalNotes={(text, id) => {
                          this.setState({
                            internalNotesText: text,
                            editInternalNotes: true,
                            internalNotesOpendDialogId: id,
                            openAddNoteDialog: true,
                          });
                        }}
                        handleViewInternalNotes={(text, id) => {
                          this.setState({
                            internalNotesText: text,
                            viewInternalNotes: true,
                            internalNotesOpendDialogId: id,
                            openAddNoteDialog: true,
                          });
                        }}
                      />
                    )}
                  </div>
                  <div hidden={this.state.currentTab !== 1} index={1} className={classes.listStyle}>
                    {this.state.currentTab === 1 && <Links appDetails={appDetails} sDetails={sDetails} />}
                  </div>
                  <div hidden={this.state.currentTab !== 2} index={2} className={classes.listStyle}>
                    {this.state.currentTab === 2 && (
                      <Documents
                        appDetails={appDetails}
                        documentType="installer"
                        sDetails={sDetails}
                        fileDownloadInProgress={fileDownloadInProgress}
                        downLoadError={downLoadError}
                        downLoadErrorMsgs={downLoadErrorMsgs}
                        openUploadPermitDocPopup={() => this.setState({ showUploadPermitDoc: true })}
                        pushErrorMessage={(msg) => {
                          let eMsgs = this.state.downLoadErrorMsgs;
                          eMsgs.push(msg);
                          this.setState({ downLoadError: true, downLoadErrorMsgs: eMsgs });
                        }}
                        getPresingedURL={this.props.getPresingedURL}
                        moveToUnderProgress={moveToUnderProgress}
                        emailId={emailId}
                      />
                    )}
                  </div>
                  <Box className={classes.actionWrapper}>
                    <Divider />
                    <AdminActions
                      openFileUploadDialog={this.handleOpenFileUploadDialog}
                      sDetails={sDetails}
                      permitting_application={permitting_application}
                      emailId={emailId}
                      permitServiceRole={permitServiceRole}
                    />
                  </Box>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <FileUploadDialog
          fileDialogInfo={
            this.state.openInternalFileUploadDialog
              ? { title: 'Upload Document (Internal)', buttonText: 'Submit' }
              : this.state.fileDialogInfo
          }
          fireUpload={this.fireUpload}
          open={this.state.openInternalFileUploadDialog || this.state.openFileDialog}
          setOpen={(open) => {
            this.state.openInternalFileUploadDialog
              ? this.setState({ openInternalFileUploadDialog: open })
              : this.setState({ openFileDialog: open });
          }}
          acceptableFileFormat={ACCEPTABLE_FILE_FORMAT}
          appDetails={appDetails}
          fileUploadInProgress={this.state.uploadingFiles}
          getPresingedURL={this.props.getPresingedURL}
          uploadMultipleFiles={source === SOURCE.ENLIGHTEN_PERMIT}
        />
        {!isEmpty(this.state.errorMessage) && <SnakBar severity="error" message={this.state.errorMessage} />}
        {this.state.openAddNoteDialog && (
          <TextUploadDialog
            open={this.state.openAddNoteDialog}
            onClose={() => {
              this.handleCloseInternalNotes();
            }}
            title={
              this.state.viewInternalNotes
                ? 'Note'
                : this.state.editInternalNotes
                ? 'Edit Note'
                : 'Create New Note for Internal Purpose Only'
            }
            content={this.state.internalNotesText}
            setContent={(e) => {
              this.setState({ internalNotesText: e.target.value });
            }}
            isContentEditable={!this.state.viewInternalNotes}
            containedButtonText={'Save'}
            disableContainedButton={this.state.internalNotesText.length === 0 ? true : false}
            containedButtonClick={() => {
              this.handleSaveInternalNotes();
            }}
            outlinedButtonText={'Close'}
            outlinedButtonClick={() => {
              this.handleCloseInternalNotes();
            }}
            disableOutlinedButton={false}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPresingedURL: (payload) => dispatch(getPresingedURL(payload)),
  getFileFromS3: (payload) => dispatch(getFileFromS3(payload)),
  uploadToS3: (payload) => dispatch(uploadToS3(payload)),
  moveToUnderProgress: (payload) => dispatch(moveToUnderProgress(payload)),
  moveToPermitDrawings: (payload) => dispatch(moveToPermitDrawings(payload)),
  moreInformationRequest: (payload) => dispatch(moreInformationRequest(payload)),
  moveToQAComplete: (payload) => dispatch(moveToQAComplete(payload)),
  approveDocuments: (payload) => dispatch(approveDocuments(payload)),
  internalKickback: (payload) => dispatch(internalKickback(payload)),
  stampingSubmit: (payload) => dispatch(stampingSubmit(payload)),
  saveInternalNotes: (payload) => dispatch(saveInternalNotes(payload)),
  saveInternalFiles: (payload) => dispatch(saveInternalFiles(payload)),
  editInternalNotes: (payload) => dispatch(editInternalNotes(payload)),
});

const mapStateToProps = (state) => ({
  currentlySending: state.adminReducer.currentlySending,
  permitting_application: state.adminReducer.permitting_application,
  emailId: state.appReducer.emailId,
  permitServiceRole: state.appReducer.permitServiceRole,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicationDocuments));
