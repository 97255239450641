import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { clone, get, split } from 'lodash';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { SelectDropdown } from '.';
import { useLabelStyles } from '../../common/Label';
import PanelTemplate from '../../common/PanelTemplate';
import { isPermitSetAvailable } from '../../../utils/helper';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2.5)
  },
  arrayInputField: {
    width: '100%',
    marginRight: theme.spacing(4)
  },
  infoText: {
    fontSize: 14,
    opacity: 0.8
  },
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none'
  },
  inputItemWrapper: {
    maxWidth: '49%',
    padding: theme.spacing(1, 0, 1, 1),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
      paddingRight: '28px !important'
    }
  },
  displayFlex: {
    display: 'flex'
  }
}));

const StorageProperties = ({
  materialDetails,
  projectDetails,
  handleChange,
  userSelectionData,
  otherUserSelectionData,
  mostUsedValues,
  applicationDetail
}) => {
  const labelClass = useLabelStyles();
  const classes = useStyles();
  const { electrical_properties, errors = {} } = materialDetails;
  const permitFilesAvailable = isPermitSetAvailable(applicationDetail);
  const {
    existing_subpanel = '',
    subpanel_breaker_rating = '',
    subpanel_bus_rating = ''
  } = electrical_properties;
  const {
    subpanel_bus_rating: subpanelBusRatingOptions = [],
    subpanel_breaker_rating: subpanelBreakerRatingOptions = []
  } = userSelectionData;
  const {
    subpanel_bus_rating: otherSubpanelBusRatingOptions = [],
    subpanel_breaker_rating: otherSubpanelBreakerRatingOptions = []
  } = otherUserSelectionData;
  const partialStorageBackup = get(projectDetails, 'home_backup_option', '') === 'partial';

  const [ otherSubpanelBreakerRatingEnabled, setOtherSubpanelBreakerRatingEnabled ] = useState(false);
  const [ otherSubpanelBreakerRating, setOtherSubpanelBreakerRating ] = useState('');

  const [ otherSubpanelBusRatingEnabled, setOtherSubpanelBusRatingEnabled ] = useState(false);
  const [ otherSubpanelBusRating, setOtherSubpanelBusRating ] = useState('');

  useEffect(
    () => {
      if (otherSubpanelBreakerRatingEnabled) {
        const otherAttachments = split(subpanel_breaker_rating, '-', 2)[1];
        setOtherSubpanelBreakerRating(otherAttachments);
      }
      if (otherSubpanelBusRatingEnabled) {
        const otherAttachments = split(subpanel_bus_rating, '-', 2)[1];
        setOtherSubpanelBusRating(otherAttachments);
      }
    },
    [
      subpanel_breaker_rating,
      subpanel_bus_rating
    ]
  );

  const handlePropertiesChange = (value, key, fromOtherOption = false, skipValidation = false) => {
    switch (key) {
      case 'subpanel_breaker_rating':
        if (value === 'Other') {
          setOtherSubpanelBreakerRatingEnabled(true);
        } else if (!fromOtherOption) {
          setOtherSubpanelBreakerRatingEnabled(false);
        }
        break;
      case 'subpanel_bus_rating':
        if (value === 'Other') {
          setOtherSubpanelBusRatingEnabled(true);
        } else if (!fromOtherOption) {
          setOtherSubpanelBusRatingEnabled(false);
        }
        break;
      default:
        break;
    }
    if (fromOtherOption) value = `Other-${value}`;
    const data = clone(electrical_properties);
    data[key] = value;
    handleChange(data, 'electrical_properties', { additionalKey: key }, skipValidation);
  }
  const getErrorText = key => (get(errors, `${key}.error`) ? get(errors, `${key}.errorMessage`) : '');

  return (
    <div className={classes.root} id="electrical_properties">
      <PanelTemplate header="Electrical Properties" className={classes.panelContainer}>
        <Grid className={classes.inputItemWrapper} item sm={12} md={6}>
          <Box>
            <SelectDropdown
              id="existing_subpanel"
              labelText="Existing Subpanel"
              className={clsx(classes.arrayInputField, labelClass.customLabel)}
              value={existing_subpanel}
              handleChange={handlePropertiesChange}
              optionList={['Yes', 'No']}
              isRequired={partialStorageBackup}
              classes={classes}
              labelClass={labelClass}
              isPermitSetAvailable={permitFilesAvailable}
              error={get(errors, `existing_subpanel.error`)}
              helperText={getErrorText('existing_subpanel')}
            />
            {existing_subpanel === 'No' &&
              <Typography className={classes.infoText}>New subpanel needs to be added. Please provide more details for new subpanel</Typography>}
          </Box>
        </Grid>
        <Box className={classes.displayFlex}>
          <Grid className={classes.inputItemWrapper} item sm={12} md={6}>
            <SelectDropdown
              id="subpanel_breaker_rating"
              labelText="Subpanel Breaker Rating"
              className={clsx(classes.arrayInputField, labelClass.customLabel)}
              value={subpanel_breaker_rating}
              handleChange={handlePropertiesChange}
              optionList={subpanelBreakerRatingOptions}
              otherOptionList={otherSubpanelBreakerRatingOptions}
              isOtherSelected={otherSubpanelBreakerRatingEnabled}
              otherSelectedValue={otherSubpanelBreakerRating}
              isRequired={partialStorageBackup}
              classes={classes}
              labelClass={labelClass}
              isPermitSetAvailable={permitFilesAvailable}
              showRecent={true}
              mostUsedList={mostUsedValues['subpanel_breaker_rating']}
              otherGrid={true}
              error={get(errors, `subpanel_breaker_rating.error`)}
              helperText={getErrorText('subpanel_breaker_rating')}
            />
          </Grid>
          <Grid className={classes.inputItemWrapper} item sm={12} md={6}>
            <SelectDropdown
              id="subpanel_bus_rating"
              labelText="Subpanel Bus Rating"
              className={clsx(classes.arrayInputField, labelClass.customLabel)}
              value={subpanel_bus_rating}
              handleChange={handlePropertiesChange}
              optionList={subpanelBusRatingOptions}
              otherOptionList={otherSubpanelBusRatingOptions}
              isOtherSelected={otherSubpanelBusRatingEnabled}
              otherSelectedValue={otherSubpanelBusRating}
              isRequired={partialStorageBackup}
              classes={classes}
              labelClass={labelClass}
              isPermitSetAvailable={permitFilesAvailable}
              showRecent={true}
              mostUsedList={mostUsedValues['subpanel_bus_rating']}
              error={get(errors, `subpanel_bus_rating.error`)}
              helperText={getErrorText('subpanel_bus_rating')}
            />
          </Grid>
        </Box>
      </PanelTemplate>
    </div>
  );
};

export default StorageProperties;
