import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  array: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
    background: '#F7F7F7 0% 0% no-repeat padding-box',
  },
  arrayHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrayHeader: {
    fontWeight: 'bold',
  },
  removeButton: {
    padding: theme.spacing(0),
    margin: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.primary.dataBlack,
    },
    '& svg': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  },
  arrayList: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
  },
  labelColorDisabled: {
    color: 'rgba(0, 0, 0, 0.25)',
    fontWeight: 400,
  },
  storageLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
    textTransform: 'none',
  },
  arrayInputInch: {
    width: theme.spacing(8),
  },
  dropdownPointerDisabled: {
    '& .MuiSvgIcon-root.MuiSelect-icon': {
      color: 'rgba(0, 0, 0, 0.25)',
    },
  },
  arrayFieldMaxRafter: {
    marginRight: theme.spacing(3),
  },
  arrayInputField: {
    width: theme.spacing(12.5),
    marginRight: theme.spacing(3),
  },
  structuralWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'nowrap',
  },
  maxRafterFeet: {
    marginRight: theme.spacing(2.5),
  },
  maxRafterWrapper: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  arrayField: {
    width: theme.spacing(15),
    marginRight: theme.spacing(3),
  },
  arrayFieldOther: {
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
    },
  },
  arrayInputOther: {
    width: theme.spacing(20),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(12.5),
    },
  },
  structuralAtText: {
    marginRight: theme.spacing(3),
  },
  selectMenu: {
    whiteSpace: 'normal',
    overflow: 'visible',
  },
  inchLabel: {
    color: '#00000094',
  },
  specSheetWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(160px, 1fr))',
    gridColumnGap: theme.spacing(5),
    gridRowGap: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  inputField: {
    width: '100%',
  },
  dropdownSectionHeaderStyle: {
    color: theme.palette.primary.grey,
    marginLeft: theme.spacing(2),
  },
  dividerStyle: {
    background: theme.palette.primary.grey,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  imgHolder: {
    display: 'inline-flex',
    marginRight: '27px',
    flexDirection: 'column',
    width: '123px',
    wordBreak: 'break-word',
  },
  imgContainer: {
    border: '1px solid #70707040',
    height: '82px',
    width: '121px',
    display: 'flex',
    position: 'relative',
    '& img': {
      margin: 'auto',
      display: 'block',
    },
  },
  addNewArray: {
    fontSize: 14,
    color: '#008EEF',
    display: 'inline-block',
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    opacity: 0.23,
  },
  errorText: {
    fontSize: theme.spacing(1.75),
    color: '#f44336',
  },
  productWrapper: {
    padding: theme.spacing(2, 2, 2, 2),
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    margin: theme.spacing(2),
  },
  otherMaterials: {
    fontSize: 14,
    opacity: 0.7,
    padding: theme.spacing(2, 2, 0, 2),
  },
  flexDisplay: {
    display: 'flex',
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  structuralAt: {
    position: 'relative',
    top: theme.spacing(2),
  },
  tooltipStyle: {
    maxWidth: 600,
  },
  infoIcon: {
    // marginTop: theme.spacing(2),
    padding: 0,
    marginRight: theme.spacing(1.5),
    color: 'grey',
  },
  gridPad: {
    paddingTop: theme.spacing(1),
  },
}));
