import clsx from 'clsx';
import React from 'react';
import { Label } from '../../../../../../common/Label';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get, isEmpty } from '../../../../../../../utils/lodash';
import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import CustomPanel from '../../../../../../common/PanelTemplateWithMandatory';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  addressWrapperWithDropdown: {
    flexDirection: 'row'
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  inputFieldTextDropdown: {
    width: '90%',
    minWidth: theme.spacing(0)
  },
  inputFieldDropdown: {
    width: '10%',
    minWidth: theme.spacing(9),
    margin: theme.spacing(5, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4)
    }
  }
}));

const ElectricalDetailsSection = props => {
  const classes = useStyles();
  const { readOnly, errorData, sectionLabel, electricalDetails, handleElectricalDetails } = props;
  const {
    max_power_rating_stc = '',
    max_power_rating_ptc = '',
    open_circuit_voltage = '',
    max_power_voltage = '',
    short_circuit_current = '',
    max_power_current = '',
    efficiency = '',
    energy_degradation = '',
    voltage_temp_coefficient = '',
    voltage_temp_unit = '',
    current_temp_coefficient = '',
    current_temp_unit = '',
    power_temp_coefficient = '',
    power_temp_unit = '',
    nominal_operating_cell_temperature = '',
  } = electricalDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_power_rating_stc'}
                className={classes.inputField}
                label={<Label text={'Max Power Rating (Pmax) - STC (W)'} isRequired />}
                type="number"
                value={max_power_rating_stc || ''}
                onChange={e => handleElectricalDetails(e, 'max_power_rating_stc')}
                error={!isEmpty(get(errorData, 'max_power_rating_stc'))}
                helperText={get(errorData, 'max_power_rating_stc')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_power_rating_ptc'}
                className={classes.inputField}
                label={<Label text={'Max Power Rating (Pmax) - PTC (W)'} />}
                type="number"
                value={max_power_rating_ptc || ''}
                onChange={e => handleElectricalDetails(e, 'max_power_rating_ptc')}
                error={!isEmpty(get(errorData, 'max_power_rating_ptc'))}
                helperText={get(errorData, 'max_power_rating_ptc')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'open_circuit_voltage'}
                className={classes.inputField}
                label={<Label text={'Open Circuit Voltage (Voc) (V)'} isRequired />}
                type="number"
                value={open_circuit_voltage || ''}
                onChange={e => handleElectricalDetails(e, 'open_circuit_voltage')}
                error={!isEmpty(get(errorData, 'open_circuit_voltage'))}
                helperText={get(errorData, 'open_circuit_voltage')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_power_voltage'}
                className={classes.inputField}
                label={<Label text={'Maximum Power Voltage (Vpmax) (V)'} isRequired />}
                type="number"
                value={max_power_voltage || ''}
                onChange={e => handleElectricalDetails(e, 'max_power_voltage')}
                error={!isEmpty(get(errorData, 'max_power_voltage'))}
                helperText={get(errorData, 'max_power_voltage')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'short_circuit_current'}
                className={classes.inputField}
                label={<Label text={'Short Circuit Current (Isc) (A)'} isRequired />}
                type="number"
                value={short_circuit_current || ''}
                onChange={e => handleElectricalDetails(e, 'short_circuit_current')}
                error={!isEmpty(get(errorData, 'short_circuit_current'))}
                helperText={get(errorData, 'short_circuit_current')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_power_current'}
                className={classes.inputField}
                label={<Label text={'Maximum Power Current (Ipmax) (A)'} isRequired />}
                type="number"
                value={max_power_current || ''}
                onChange={e => handleElectricalDetails(e, 'max_power_current')}
                error={!isEmpty(get(errorData, 'max_power_current'))}
                helperText={get(errorData, 'max_power_current')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'efficiency'}
                className={classes.inputField}
                label={<Label text={'Efficiency (%)'} isRequired />}
                type="number"
                value={efficiency || ''}
                onChange={e => handleElectricalDetails(e, 'efficiency')}
                error={!isEmpty(get(errorData, 'efficiency'))}
                helperText={get(errorData, 'efficiency')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'energy_degradation'}
                className={classes.inputField}
                label={
                  <Label
                    text={'Energy Degradation (%/year) (If not altered a default of 0.5% will be used)'}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={energy_degradation || ''}
                onChange={e => handleElectricalDetails(e, 'energy_degradation')}
                error={!isEmpty(get(errorData, 'energy_degradation'))}
                helperText={get(errorData, 'energy_degradation')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={clsx(classes.addressWrapper, classes.addressWrapperWithDropdown)}>
              <TextField
                id={'voltage_temp_coefficient'}
                className={clsx(classes.inputField, classes.inputFieldTextDropdown)}
                label={<Label text={'Voltage Temp Coefficient (Tvoc)'} isRequired />}
                type="number"
                value={voltage_temp_coefficient || ''}
                onChange={e => handleElectricalDetails(e, 'voltage_temp_coefficient')}
                error={!isEmpty(get(errorData, 'voltage_temp_coefficient'))}
                helperText={get(errorData, 'voltage_temp_coefficient')}
                disabled={readOnly}
              />
              <Autocomplete
                id="voltage_temp_unit"
                disableClearable
                className={clsx(classes.inputField, classes.inputFieldDropdown)}
                options={[ 'V/°C', '%/°C' ]}
                value={voltage_temp_unit || {}}
                onChange={(e, value) => handleElectricalDetails(e, 'voltage_temp_unit', value)}
                renderInput={params => <TextField {...params} variant="standard" />}
              />
            </Box>
            <Box className={clsx(classes.addressWrapper, classes.addressWrapperWithDropdown)}>
              <TextField
                id={'current_temp_coefficient'}
                className={clsx(classes.inputField, classes.inputFieldTextDropdown)}
                label={<Label text={'Current Temp Coefficient (Tisc)'} isRequired />}
                type="number"
                value={current_temp_coefficient || ''}
                onChange={e => handleElectricalDetails(e, 'current_temp_coefficient')}
                error={!isEmpty(get(errorData, 'current_temp_coefficient'))}
                helperText={get(errorData, 'current_temp_coefficient')}
                disabled={readOnly}
              />
              <Autocomplete
                id="current_temp_unit"
                disableClearable
                className={clsx(classes.inputField, classes.inputFieldDropdown)}
                options={[ 'A/°C', '%/°C' ]}
                value={current_temp_unit || {}}
                onChange={(e, value) => handleElectricalDetails(e, 'current_temp_unit', value)}
                renderInput={params => <TextField {...params} variant="standard" />}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={clsx(classes.addressWrapper, classes.addressWrapperWithDropdown)}>
              <TextField
                id={'power_temp_coefficient'}
                className={clsx(classes.inputField, classes.inputFieldTextDropdown)}
                label={<Label text={'Power Temperature Coefficient (Tpmax)'} isRequired />}
                type="number"
                value={power_temp_coefficient || ''}
                onChange={e => handleElectricalDetails(e, 'power_temp_coefficient')}
                error={!isEmpty(get(errorData, 'power_temp_coefficient'))}
                helperText={get(errorData, 'power_temp_coefficient')}
                disabled={readOnly}
              />
              <Autocomplete
                id="power_temp_unit"
                disableClearable
                className={clsx(classes.inputField, classes.inputFieldDropdown)}
                options={['W/°C', '%/°C']}
                value={power_temp_unit || {}}
                onChange={(e, value) => handleElectricalDetails(e, 'power_temp_unit', value)}
                renderInput={params => <TextField {...params} variant="standard" />}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'nominal_operating_cell_temperature'}
                className={classes.inputField}
                label={<Label text={'Nominal Operating Cell Temperature (°C)'} />}
                type="number"
                value={nominal_operating_cell_temperature || ''}
                onChange={e => handleElectricalDetails(e, 'nominal_operating_cell_temperature')}
                error={!isEmpty(get(errorData, 'nominal_operating_cell_temperature'))}
                helperText={get(errorData, 'nominal_operating_cell_temperature')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ElectricalDetailsSection;
