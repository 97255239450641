import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginBottom: theme.spacing(1),
  },
  alert: {
    backgroundColor: '#33597721',
    '& .MuiAlert-message': {
      color: '#335977',
    },
  },
}));

export default function ColorAlerts(props) {
  const classes = useStyles();
  const { permitId, customerName } = props;

  if (isEmpty(customerName)) {
    return (
      <div className={classes.root}>
        <Alert className={classes.alert} icon={false}>
          {`Request Id: ${permitId}`}
        </Alert>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Alert className={classes.alert} icon={false}>
        {`Request Id: ${permitId}`}
        {` - `}
        {`${customerName}`}
      </Alert>
    </div>
  );
}
