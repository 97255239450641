import React from 'react';

function Stamp(props) {
  const { disabled } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35.158" height="40" viewBox="0 0 35.158 40">
      <defs />
      <g transform="translate(-30.987)">
        <g transform="translate(30.987 0)">
          <path
            fill={disabled ? '#919191' : '#016ae3'}
            d="M60.286,25.859h-5.86V24.687a3.521,3.521,0,0,0-2.177-3.25A25.262,25.262,0,0,1,55.7,12.26a8.208,8.208,0,1,0-13.878.609,21.09,21.09,0,0,1,3.064,8.568,3.521,3.521,0,0,0-2.177,3.25v1.172h-5.86a5.866,5.866,0,0,0-5.86,5.86V34.14a1.172,1.172,0,0,0,1.172,1.172h1.172v1.172A3.52,3.52,0,0,0,36.847,40H60.285A3.52,3.52,0,0,0,63.8,36.484V35.312h1.172a1.172,1.172,0,0,0,1.172-1.172V31.718A5.866,5.866,0,0,0,60.286,25.859ZM43.746,11.534a5.859,5.859,0,1,1,9.915-.435,27.564,27.564,0,0,0-3.744,10.072h-2.7A23.43,23.43,0,0,0,43.746,11.534Zm8.336,13.153v1.172H45.05V24.687a1.173,1.173,0,0,1,1.172-1.172H50.91A1.173,1.173,0,0,1,52.082,24.687Zm9.376,11.8a1.173,1.173,0,0,1-1.172,1.172H36.847a1.173,1.173,0,0,1-1.172-1.172V35.312H61.457ZM63.8,32.968H33.331v-1.25A3.52,3.52,0,0,1,36.847,28.2H60.286A3.52,3.52,0,0,1,63.8,31.718Z"
            transform="translate(-30.987 0)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Stamp;
