import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { formattedDate } from '../../../../../containers/Admin/helper';
import { Label } from '../../../../common/Label';
import CustomPanel from '../../../../common/PanelTemplateWithMandatory';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  }
}));

const RecordDetailsSectionOldUI = props => {
  const classes = useStyles();
  const { recordDetails } = props;
  const {
    created_by = '',
    created_at = '',
    modified_by = '',
    modified_at = '',
    verified_by = '',
    verified_at = ''
  } = recordDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={'Record Details'}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Created By'} />}
                value={created_by}
                id="created_by"
                disabled
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Created Date'} />}
                value={formattedDate(created_at)}
                id="created_at"
                disabled
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Modified By'} />}
                value={modified_by}
                id="modified_by"
                disabled
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Modified Date'} />}
                value={formattedDate(modified_at)}
                id="modified_at"
                disabled
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Verified By'} />}
                value={verified_by}
                id="verified_by"
                disabled
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Verified Date'} />}
                value={verified_by ? formattedDate(verified_at) : 'NA'}
                id="verified_at"
                disabled
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default RecordDetailsSectionOldUI;
