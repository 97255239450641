/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import queryString from 'query-string';
import { forwardTo, setToken, getToken, getCookie } from '../../utils/helper';
import { setMobileView, setEnlightenManagerToken, getAccountDtails } from './actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Breadcrubs from '../../components/Breadcrubs';
import settings from '../../utils/settings';
import { isEmpty } from 'lodash';
import { getValue, setValue } from '../../utils/localStorage';

let isTokenMissing = false;

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    height: 'calc(100% - 220px)'
  }
}));

const Layout = ({ children, isMobileView, loading, matchProps, permitServiceRole, ...rest }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Header isMobileView={isMobileView} permitServiceRole={permitServiceRole} />
      <Breadcrubs matchProps={matchProps} />
      <Box className={classes.root}>{children}</Box>
      <a id="login_screen" href={settings.enlightenUrl('login')} referrerPolicy="no-referrer-when-downgrade" />
      <Footer />
    </Fragment>
  );
};

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    const { search, pathname } = props.location;
    const { token } = queryString.parse(search);

    let accessToken = token;
    const cookie_name = settings.getCookieName()
    let tokenFromCookie = getCookie(cookie_name);
    if(isEmpty(tokenFromCookie) && (cookie_name === 'enlighten_manager_token_qa2' || cookie_name === 'enlighten_manager_token_rel')) {
      if (cookie_name === 'enlighten_manager_token_qa2') tokenFromCookie = getToken('enlighten_manager_token_rel')
      if (cookie_name === 'enlighten_manager_token_rel') tokenFromCookie = getToken('enlighten_manager_token_qa2')
    }
    if (!isEmpty(tokenFromCookie)) {
      accessToken = tokenFromCookie;
    }
    if (accessToken && pathname) {
      setToken(accessToken);
      forwardTo(pathname);
    }
    let tkn = getToken();
    if (!tkn || tkn === '' || tkn === -1) {
      isTokenMissing = true;
    }
    if (accessToken) {
      if (!getValue('referrer')) {
        setValue('referrer', document.referrer);
      }
      this.props.setEnlightenManagerToken(accessToken);
    }
    this.props.getAccountDtails();
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const isMobileView = window.innerWidth <= 1200;
    this.props.setMobileView(isMobileView);
  };

  componentDidMount = () => {
    this.handleWindowSizeChange();
    if (isTokenMissing) {
      document.getElementById('login_screen').click();
    }
  };

  render() {
    const { component: Component, isMobileView, loading, permitServiceRole, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout
            isMobileView={isMobileView}
            loading={loading}
            matchProps={matchProps}
            permitServiceRole={permitServiceRole}
          >
            <Component {...matchProps} />
          </Layout>
        )}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setMobileView: isMobileView => dispatch(setMobileView(isMobileView)),
  setEnlightenManagerToken: tokenVal => dispatch(setEnlightenManagerToken(tokenVal)),
  getAccountDtails: () => dispatch(getAccountDtails())
});

const mapStateToProps = state => ({
  isMobileView: state.appReducer.isMobileView,
  loading: state.appReducer.loading,
  permitServiceRole: state.appReducer.permitServiceRole
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
