import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import { get, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { APPLICATION_STATES } from '../../../../utils/constants';
import { getPreviousUsers } from '../../../../containers/Admin/RequestDetails/helper';
import ContainedButton from '../../../common/ContainedButton';
import { theme } from 'highcharts';

const SelectDropdown = ({ classes, reviewersList, assignedUser, onChange, isDesigner }) => {
  return (
    <Autocomplete
      options={reviewersList || []}
      getOptionLabel={(option) =>
        isEmpty(option.email)
          ? ''
          : `${option.first_name} ${option.last_name}
                           ${option.email}`
      }
      getOptionDisabled={(option) => option.email === assignedUser?.email}
      getOptionSelected={(option) => option.email === assignedUser?.email}
      renderOption={(option) => (
        <React.Fragment>
          <Box display="flex" flexDirection="column">
            <span className={classes.popoverName}>{`${option.first_name} ${option.last_name}`}</span>
            <span className={classes.popoverEmail}>{option.email}</span>
          </Box>
        </React.Fragment>
      )}
      value={assignedUser}
      onChange={(event, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          className={classes.selectInputText}
          placeholder={`Select a ${isDesigner ? 'Designer' : 'Reviewer'}`}
          {...params}
          size="small"
          fullWidth
          variant="outlined"
        />
      )}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  boxPadding: {
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    marginBottom: theme.spacing(1),
    minWidth: theme.spacing(25),
  },
  assignText: {
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: '#335977',
    opacity: 1,
    paddingTop: theme.spacing(1.875),
    paddingBottom: theme.spacing(2.125),
  },
  close: {
    opacity: 0.23,
    cursor: 'pointer',
    marginTopp: '-4px',
  },
  switch: {
    marginLeft: 0,
  },
  popoverName: {
    fontSize: '13px',
    color: '#333333',
    opacity: 1,
  },
  popoverEmail: {
    fontSize: '12px',
    color: '#333333',
    opacity: 0.7,
  },
  selectInputText: {
    '& input': {
      textOverflow: 'unset',
    },
  },
}));

const AssignPopup = ({
  handlePopoverClose,
  reviewersList,
  assignButtonCall,
  buttonText,
  currentStateId,
  emailId,
  firstName,
  lastName,
  externalRevision,
  appDetails,
}) => {
  const classes = useStyles();
  const [assignedDesigner, setAssignedDesigner] = useState(null);
  const [assignedReviewer, setAssignedReviewer] = useState(null);
  const [assignToMe, setAssignToMe] = useState(false);
  const [assignToPreviousUsers, setAssignToPreviousUsers] = useState(false);
  const isStampingRequest = [
    APPLICATION_STATES.STAMPING_REQUESTED.id,
    APPLICATION_STATES.STAMPING_SUBMITTED.id,
  ].includes(currentStateId);

  useEffect(() => {
    if (isStampingRequest) {
      setAssignedDesigner({ email: emailId, first_name: firstName, last_name: lastName });
      setAssignedReviewer({ email: emailId, first_name: firstName, last_name: lastName });
      setAssignToMe(true);
    } else {
      let name = appDetails?.current_designer?.user_info?.name;
      setAssignedDesigner({
        email: appDetails?.current_designer?.user_info?.email,
        first_name: name && name.substr(0, name.indexOf(' ')),
        last_name: name && name.substr(name.indexOf(' ') + 1),
      });
      name = appDetails?.current_reviewer?.user_info?.name;
      setAssignedReviewer({
        email: appDetails?.current_reviewer?.user_info?.email,
        first_name: name && name.substr(0, name.indexOf(' ')),
        last_name: name && name.substr(name.indexOf(' ') + 1),
      });
    }
  }, [isStampingRequest, appDetails]);

  const handleSwitchChange = (event, assignToMe) => {
    if (event.target.checked) {
      if (assignToMe) {
        setAssignedDesigner({ email: emailId, first_name: firstName, last_name: lastName });
        setAssignedReviewer({ email: emailId, first_name: firstName, last_name: lastName });
        setAssignToMe(true);
        setAssignToPreviousUsers(false);
      } else {
        const { preDesigner, preReviewer } = getPreviousUsers(appDetails);
        setAssignedDesigner({ email: preDesigner?.email, first_name: preDesigner?.name, last_name: '' });
        setAssignedReviewer({ email: preReviewer?.email, first_name: preReviewer?.name, last_name: '' });
        setAssignToPreviousUsers(true);
        setAssignToMe(false);
      }
    } else {
      setAssignedDesigner(null);
      setAssignedReviewer(null);
      setAssignToMe(false);
      setAssignToPreviousUsers(false);
    }
  };

  const handleProceed = () => {
    let designerEmail = assignedDesigner?.email,
      reviewerEmail = assignedReviewer?.email;
    if (assignedDesigner?.email === appDetails?.current_designer?.user_info?.email) {
      designerEmail = undefined;
    }
    if (assignedReviewer?.email === appDetails?.current_reviewer?.user_info?.email) {
      reviewerEmail = undefined;
    }
    assignButtonCall(designerEmail, reviewerEmail);
  };

  return (
    <Box className={classes.boxPadding}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.assignText}>Assign To</Typography>
        {handlePopoverClose && (
          <IconButton onClick={handlePopoverClose}>
            <Close className={classes.close} fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Box>
        <FormControlLabel
          className={classes.switch}
          control={
            <Switch checked={assignToMe} color="primary" defaultChecked onChange={(e) => handleSwitchChange(e, true)} />
          }
          labelPlacement="start"
          label="Assign to me"
        />
      </Box>
      {externalRevision && (
        <Box>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={assignToPreviousUsers}
                color="primary"
                defaultChecked
                onChange={(e) => handleSwitchChange(e)}
              />
            }
            labelPlacement="start"
            label="Assign to previous designer and reviewer"
          />
        </Box>
      )}
      {!isStampingRequest && (
        <Box className={externalRevision && classes.selectWrapper}>
          <br />
          <Typography>Designer</Typography>
          <SelectDropdown
            classes={classes}
            onChange={(value) => {
              setAssignedDesigner(value);
              setAssignToMe(false);
              setAssignToPreviousUsers(false);
            }}
            reviewersList={reviewersList}
            isDesigner
            assignedUser={assignedDesigner}
          />
          <br />
          <Typography>Reviewer</Typography>
          <SelectDropdown
            classes={classes}
            onChange={(value) => {
              setAssignedReviewer(value);
              setAssignToMe(false);
              setAssignToPreviousUsers(false);
            }}
            reviewersList={reviewersList}
            assignedUser={assignedReviewer}
          />
        </Box>
      )}
      <br />
      <ContainedButton
        disabled={!assignedDesigner && !assignedReviewer}
        text={buttonText ? buttonText : 'Assign'}
        handleClick={() => handleProceed()}
      />
    </Box>
  );
};

export default AssignPopup;
