import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React from 'react';
import TemplateDialog from '../../../containers/Permitting/TemplateDialog';
import PageHeader from '../../common/PageHeader';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%'
    }
  },
  dialogPaper: {
    height: '80vh'
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  styleTemplateText: {
    color: '#696969',
    fontSize: '14px'
  },
  closeButton: {
    padding: 0
  },
}));

const TemplatePopup = ({
  showTemplateDialog,
  handleTemplateDialogClose,
  templates
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={showTemplateDialog}
      onClose={handleTemplateDialogClose}
      className={classes.dialogContainer}
      fullWidth
      maxWidth="xl"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={classes.dialogHeader} disableTypography>
        <div>
          <PageHeader text="Templates" />
          <Typography className={classes.styleTemplateText}>
            Pre-fill your permit assistance application with pre-defined values from one of the below templates
          </Typography>
        </div>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleTemplateDialogClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {templates.map((template, index) => (
          <TemplateDialog key={index} template={template} handleClose={handleTemplateDialogClose} />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default TemplatePopup;
