import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../../components/Loader';
import {
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Link,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  CircularProgress
} from '@material-ui/core';
import Snackbar from '../../../components/SnakBar';
import moment from 'moment';
import { MSG_HISTORY_COLUMNS } from '../../../containers/Admin/constants';
import { isEqual } from '../../../utils/lodash';
import { getMessageHistoryForRequest, getPresingedURL, getFileFromS3 } from '../actions';
import documentSvg from '../../../components/Admin/RequestDetails/images/document.svg';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  fontBold: {
    fontWeight: 'bold'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  subjectCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '300px'
  },
  acceptButton: {
    color: 'white'
  },
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      width: theme.spacing(30)
    }
  }
});

export class MessageHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFullMsg: false,
      fullMsg: { file_names: [] },
      viewFile: false,
      fileLoading: false,
      viewFileSrc: ''
    };
  }

  componentDidMount() {
    const { request_id } = this.props.match.params;
    this.props.getMessageHistoryForRequest({ request_id: request_id });
  }

  closeFullMsg() {
    this.setState({ openFullMsg: false });
  }

  showFullMsg(msg) {
    this.setState({ fullMsg: msg, openFullMsg: true });
  }

  viewFile(fullMsg) {
    // this.setState({ viewFile: true, fileLoading: true });
    this.setState({ fileLoading: true });
    let fName = fullMsg.file_names.join();
    this.props.getPresingedURL({
      file_name: fName,
      http_method: 'GET',
      successCb: presignedURL => {
        // this.props.getFileFromS3({
        //   preSignedS3Url: presignedURL,
        //   successCbS3: data => {
        //     console.log(data);
        //     if (this.state.openFullMsg) {
        //       this.setState({ fileLoading: false, viewFileSrc: data.url });
        //     }
        //   },
        //   failureCbS3: () => {
        //     this.setState({ fileLoading: false });
        //   }
        // });
        this.setState({ fileLoading: false });
        window.open(presignedURL, '_blank');
      },
      failureCb: () => {
        this.setState({ fileLoading: false });
      }
    });
  }

  closeFile() {
    this.setState({ viewFile: false, fileLoading: false, viewFileSrc: '' });
  }

  render() {
    const { classes, currentlySending, message, messageType, msg_histories } = this.props;
    const { openFullMsg, fullMsg, viewFile, fileLoading, viewFileSrc } = this.state;
    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h5" className={classes.header}>
          {'Message History'}
        </Typography>
        {currentlySending === true ? (
          <Loader loading={currentlySending} />
        ) : (
          <Fragment>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {MSG_HISTORY_COLUMNS.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className={classes.fontBold}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {isEqual(msg_histories, []) === true ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={MSG_HISTORY_COLUMNS.length} style={{ textAlign: 'center' }}>
                        <Typography variant="caption">** No Messages Available **</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {msg_histories.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{row.permit_id}</TableCell>
                          <TableCell>{moment(row.message_date).format('MMM Do YY')}</TableCell>
                          <TableCell>{row.subject}</TableCell>
                          <TableCell>
                            <div className={classes.subjectCell}>{row.body_content}</div>
                          </TableCell>
                          <TableCell className={classes.subjectCell}>
                            {row.file_names && row.file_names.length > 0 ? (
                              <Fragment>{row.file_names.join()}</Fragment>
                            ) : (
                              '--'
                            )}
                          </TableCell>
                          <TableCell>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                this.showFullMsg(row);
                              }}
                            >
                              {'View'}
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Dialog
              onClose={() => this.closeFullMsg()}
              aria-labelledby="customized-dialog-title"
              open={openFullMsg}
              maxWidth="sm"
              id="showFullMsg"
              className={classes.dialogContainer}
              fullScreen={viewFile}
            >
              {viewFile && (
                <DialogTitle>
                  Attachment
                  <Button size="small" onClick={() => this.closeFile()} style={{ float: 'right', color: '#246AB0' }}>
                    {'< Back'}
                  </Button>
                </DialogTitle>
              )}
              <DialogContent>
                {viewFile === false ? (
                  <Fragment>
                    <Typography className={classes.fontBold}>{fullMsg.subject}</Typography>
                    <Typography variant="caption">{moment().format('MMM Do YY')}</Typography>
                    <br />
                    <br />
                    <Typography>{fullMsg.body_content}</Typography>
                    <br />
                    <br />

                    {fullMsg.file_names.length > 0 && (
                      <Fragment>
                        <Typography className={classes.fontBold}>Attachment</Typography>
                        <br />
                        <Typography style={{ display: 'flex' }} variant="caption">
                          <Avatar
                            src={documentSvg}
                            variant="square"
                            style={{ width: 20, height: 20 }}
                          />&nbsp;&nbsp;<Link
                            style={{ color: '#246AB0' }}
                            onClick={() => {
                              if (!fileLoading) {
                                this.viewFile(fullMsg);
                              }
                            }}
                          >
                            {fullMsg.file_names.join()}
                          </Link>&nbsp;&nbsp;{fileLoading && <CircularProgress size={20} />}
                        </Typography>
                      </Fragment>
                    )}
                  </Fragment>
                ) : fileLoading === true ? (
                  <div style={{ textAlign: 'center', paddingTop: '45vh' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Fragment>
                    <iframe
                      style={{ width: '100%', height: '100%', overflow: 'hidden' }}
                      title={fullMsg.file_names.join()}
                      src={viewFileSrc}
                    />
                  </Fragment>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.closeFullMsg.bind(this)}
                  color="primary"
                  variant="contained"
                  className={classes.acceptButton}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        )}
        {message && messageType && <Snackbar severity={messageType} message={message} />}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMessageHistoryForRequest: payload => dispatch(getMessageHistoryForRequest(payload)),
  getPresingedURL: payload => dispatch(getPresingedURL(payload)),
  getFileFromS3: payload => dispatch(getFileFromS3(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending,
  msg_histories: state.adminReducer.msg_histories
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MessageHistory));
