import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import DatePicker from '../../../common/DatePicker';
import { downloadFile } from '../../../../containers/Admin/helper';

const DownloadReportPopup = ({ classes, getAnalyticsReport, isDownloadPopupVisible, toggleDownloadPopup }) => {
  const [ file, setFile ] = useState(null);
  const [ startDate, setStartDate ] = useState(null);
  const [ endDate, setEndDate ] = useState(null);

  const handleStartDateChange = date => {
    setStartDate(date);
    setEndDate(null);
  };
  const handleEndDateChange = date => {
    setEndDate(date);
  };

  useEffect(
    () => {
      if (file) downloadFile(file, 'text/csv');
    },
    [ file ]
  );

  const getFormattedDate = (dateType, date) => {
    date.setHours(24, 0, 0);
    if (dateType === 'startDate') date.setDate(date.getDate() - 1);
    return date.toUTCString();
  };

  const handleDownloadReport = () => {
    const start = getFormattedDate('startDate', startDate);
    const end = getFormattedDate('endDate', endDate);
    getAnalyticsReport({
      startDate: start,
      endDate: end,
      successCb: result => {
        setFile({
          content: result.response,
          name: `Permit_Application_data_${moment(startDate).format('DDMMMYYYY')}_${moment(
            endDate.setDate(endDate.getDate() - 1)
          ).format('DDMMMYYYY')}.csv`
        });
      }
    });
  };

  const handleMaxEndDate = () => {
    let arr = [];
    if (startDate) {
      var date = new Date(startDate);
      arr = [ new Date(), new Date(date.setDate(startDate.getDate() + 90)) ];
    } else arr = [ new Date() ];
    return new Date(Math.min(...arr));
  };

  const handleCancel = () => {
    toggleDownloadPopup();
    setStartDate(null);
    setEndDate(null);
    setFile(null);
  };

  return (
    <Box className={classes.boxPadding}>
      <Dialog open={isDownloadPopupVisible} onClose={handleCancel}>
        <DialogTitle className={classes.dialogTitle}>Reports</DialogTitle>
        <Typography className={classes.dialogTitleNote}>
          Note: You can download 3 months of application data at a time.
        </Typography>
        <Box boxShadow={3} className={classes.dialogContent}>
          <DialogContent>
            <DialogContentText>Modified On</DialogContentText>
            <Box className={classes.dateBox}>
              <TextField
                className={classes.dateLabel}
                label="Start Date"
                variant="standard"
                disabled={true}
                InputProps={{
                  disableUnderline: true
                }}
              />
              <DatePicker
                value={startDate}
                format={'MM/dd/yyyy'}
                maxDate={new Date()}
                placeholderText={'mm/dd/yyyy'}
                setDate={handleStartDateChange}
              />
            </Box>
            <Box className={classes.dateBox}>
              <TextField
                className={classes.dateLabel}
                label="End Date"
                variant="standard"
                disabled={true}
                InputProps={{
                  disableUnderline: true
                }}
              />
              <DatePicker
                value={endDate}
                format={'MM/dd/yyyy'}
                minDate={startDate}
                maxDate={handleMaxEndDate()}
                placeholderText={'mm/dd/yyyy'}
                setDate={handleEndDateChange}
              />
            </Box>
          </DialogContent>
        </Box>
        <DialogActions>
          <Button
            onClick={() => {
              handleDownloadReport();
              handleCancel();
            }}
            disabled={!startDate || !endDate}
          >
            Download
          </Button>
          <Button className={classes.actionBtn} onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DownloadReportPopup;
