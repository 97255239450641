import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PanelTemplate from '../../common/PanelTemplate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AutoCompleteDropdown from '../../common/TypableAutoComplete/AutoCompleteDropdown';
import {
  Box,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  Typography,
  useMediaQuery,
  Divider
} from '@material-ui/core';
import clsx from 'clsx';
import { Label, useLabelStyles } from '../../common/Label';
import { clone, split, get, isEmpty } from '../../../utils/lodash';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isPermitSetAvailable } from '../../../utils/helper';
import { preventDefaultDecimal } from '../../../containers/Admin/helper';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2.5)
  },
  specSheetWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  panelWrapper: {
    padding: theme.spacing(2.5)
  },
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none'
  },
  inputField: {
    width: '45%',
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 1, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      minWidth: 'auto'
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    '&:first-child>div': {
      marginTop: 0
    }
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  selectBox: {
    width: '100%'
  },
  errorText: {
    color: '#f44336'
  },
  otherInput: {
    width: '100%'
  },
  otherSelect: {
    paddingRight: theme.spacing(2)
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400
  },
  units: {
    color: 'rgba(0, 0, 0, 0.54)',
    right: 0,
    position: 'absolute',
    pointerEvents: 'none',
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(0.5)
  },
  dropdownSectionHeaderStyle: {
    color: theme.palette.primary.grey,
    marginLeft: theme.spacing(2)
  },
  dividerStyle: {
    background: theme.palette.primary.grey,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  inputAdornment: {
    marginRight: theme.spacing(-2.5)
  },
  showPopper: {
    display: 'block'
  },
  hidePopper: {
    display: 'none'
  }
}));

const modifyOptionList = (toFilterList = [], fromFilterList = [], filterFromFilterList = false) => {
  let modifiedFromFilterList = fromFilterList;
  if (filterFromFilterList) {
    modifiedFromFilterList = fromFilterList.map(value => value.name);
  }
  let modifiedToFilterList = toFilterList.filter(value => {
    if (value.length > 0 && modifiedFromFilterList.includes(value)) {
      return value;
    } else {
      return null;
    }
  });
  return modifiedToFilterList;
};

const showRecentDropdown = (mostUsedList = [], dropdownList = []) => {
  if (mostUsedList.length > 0 && dropdownList.length > 5) return true;
  return false;
};

const removeOtherOption = (optionList) => {
  // remove other option from array
  const index = optionList.indexOf('Other');
  if (index > -1) {
    optionList.splice(index, 1);
  }
  return optionList;
}

const TypableAutocomplete = props => {
  const {
    id,
    value,
    handleRackChange,
    mostUsedList = [],
    label,
    classes,
    labelClass,
    subText,
    hidePopper,
    setHidePopper,
    materialDetails,
    getErrorText
  } = props;
  const recentlyUsedList = [ 'Recently Used' ].concat(mostUsedList).filter(value => {
    if (value === '') {
      return null;
    } else {
      return value;
    }
  });
  if (recentlyUsedList.length > 1) {
    hidePopper && setHidePopper(false);
  }
  return (
    <Autocomplete
      id={id}
      inputValue={value ? value : ''}
      defaultValue={value ? value : ''}
      classes={{ popper: hidePopper ? classes.hidePopper : classes.showPopper }}
      freeSolo
      onInputChange={(event, newValue) => handleRackChange(newValue, id)}
      options={recentlyUsedList.map(value => value)}
      renderOption={(option, { selected }) => {
        if (recentlyUsedList.length < 2) {
          !hidePopper && setHidePopper(true);
          return <div style={{ display: 'none' }} />;
        }
        hidePopper && setHidePopper(false);
        return <React.Fragment>{option}</React.Fragment>;
      }}
      getOptionDisabled={option => option === recentlyUsedList[0]}
      className={clsx(classes.inputField, labelClass.customLabel)}
      renderInput={params => (
        <TextField
          {...params}
          type="number"
          InputProps={{
            ...params.InputProps,
            endAdornment: <InputAdornment className={classes.inputAdornment}>{subText}</InputAdornment>
          }}
          onChange={e => handleRackChange(e.target.value, id)}
          onKeyPress={(e) => preventDefaultDecimal(e)}
          label={label}
          error={get(materialDetails.errors, `${id}.error`)}
          helperText={getErrorText(id)}
        />
      )}
    />
  );
};

function RackingAndAttachments(props) {
  const [ otherMaxSpacingBetweenAttachmentsEnabled, setOtherMaxSpacingBetweenAttachmentsEnabled ] = useState(false);
  const [ otherRackingManufacturerEnabled, setOtherRackingManufacturerEnabled ] = useState(false);
  const [ otherAttachmentManufacturerEnabled, setOtherAttachmentManufacturerEnabled ] = useState(false);
  const {
    materialDetails,
    userSelectionData,
    handleChange,
    paymentStatus,
    rackingMfs,
    applicationDetail,
    mostUsedValues,
    otherUserSelectionData,
    isInputAutocompleteEnabled
  } = props;
  const permitFilesAvailable = isPermitSetAvailable(applicationDetail);
  const { racking_attachment_properties = [], errors = {} } = materialDetails;
  const [ otherMaxSpacingBetweenAttachments, setOtherMaxSpacingBetweenAttachments ] = useState(
    racking_attachment_properties.attachment_spacing
  );
  const [ otherRackingManufacturer, setRackingManufacturer ] = useState(
    racking_attachment_properties.racking_manufacturer
  );
  const [ otherAttachmentManufacturer, setAttachmentManufacturer ] = useState(
    racking_attachment_properties.attachment_manufacturer
  );
  const {
    racking_manufacturer = '',
    racking_model = '',
    attachment_manufacturer = '',
    attachment_model = '',
    attachment_type = '',
    attachment_spacing = '',
    wind_load = '',
    snow_load = '',
    notes = ''
  } = racking_attachment_properties;
  const { max_space_between_attachments = [], attachment_type: attachmentTypeItems = [] } = userSelectionData;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const labelClass = useLabelStyles();

  const [ hideWindLoadPopper, setHideWindLoadPopper ] = useState(false);
  const [ hideSnowLoadPopper, setHideSnowLoadPopper ] = useState(false);

  const handleRackChange = useCallback((value, key, fromOtherOption = false) => {
    if (key === 'racking_manufacturer' && !isInputAutocompleteEnabled) {
      if (value === 'Other') {
        setOtherRackingManufacturerEnabled(true);
      } else if (!fromOtherOption) {
        setOtherRackingManufacturerEnabled(false);
      }
    } else if (key === 'attachment_spacing') {
      if (value === 'Other') {
        setOtherMaxSpacingBetweenAttachmentsEnabled(true);
      } else if (!fromOtherOption) {
        setOtherMaxSpacingBetweenAttachmentsEnabled(false);
      }
    } else if (key === 'attachment_manufacturer' && !isInputAutocompleteEnabled) {
      if (value === 'Other') {
        setOtherAttachmentManufacturerEnabled(true);
      } else if (!fromOtherOption) {
        setOtherAttachmentManufacturerEnabled(false);
      }
    }
    const data = clone(racking_attachment_properties);
    if(isInputAutocompleteEnabled && (key === 'attachment_manufacturer' || key === 'racking_manufacturer')) {
      if (fromOtherOption) {
        data['other_' + key] = `Other-${value}`;
      }
      else {
        data['other_' + key] = '';
      }
    }
    else if (fromOtherOption) {
       value = `Other-${value}`;
    }
    data[key] = value;
    handleChange(data, 'racking_attachment_properties');
  });

  useEffect(
    () => {
      const attachmentSpacing = get(props, 'materialDetails.racking_attachment_properties.attachment_spacing');
      const rackingManufacturer = get(props, 'materialDetails.racking_attachment_properties.racking_manufacturer');
      const attachmentManufacturer = get(
        props,
        'materialDetails.racking_attachment_properties.attachment_manufacturer'
      );
      if (otherMaxSpacingBetweenAttachmentsEnabled) {
        const otherAttachments = split(attachmentSpacing, '-', 2)[1];
        setOtherMaxSpacingBetweenAttachments(otherAttachments);
      }
      if (otherRackingManufacturerEnabled) {
        const otherRackingManufacturer = split(rackingManufacturer, '-', 2)[1];
        setRackingManufacturer(otherRackingManufacturer);
      }
      if (otherAttachmentManufacturerEnabled) {
        const otherAttachmentManufacturer = split(attachmentManufacturer, '-', 2)[1];
        setAttachmentManufacturer(otherAttachmentManufacturer);
      }
    },
    [ props ]
  );

  const panelWrapper = permitFilesAvailable ? clsx(classes.disableForm, classes.panelWrapper) : classes.panelWrapper;
  const rackingManufacturer = otherRackingManufacturerEnabled ? 'Other' : racking_manufacturer;
  const attachmentManufacturer = otherAttachmentManufacturerEnabled ? 'Other' : attachment_manufacturer;
  const rackingManufacturerOptions = [ ...rackingMfs, { name: 'Other' } ];
  const attachmentManufacturerOptions = [ ...rackingMfs, { name: 'Other' } ];
  const {
    racking_manufacturer: otherRackingManufacturerOptions = [],
    attachment_manufacturer: otherAttachmentManufacturerOptions = [],
    max_space_between_attachments: otherAttachmentSpacingOptions = []
  } = otherUserSelectionData;
  const attachmentTypeItemsOptions = attachmentTypeItems;
  const attachmentSpacing = otherMaxSpacingBetweenAttachmentsEnabled ? 'Other' : attachment_spacing;
  const attachmentSpacingOptions = max_space_between_attachments;

  useEffect(
    () => {
      if (
        rackingManufacturer &&
        !rackingManufacturerOptions.find(option => option.name === rackingManufacturer) &&
        !otherRackingManufacturerOptions.find(option => option === rackingManufacturer)
      ) {
        if (permitFilesAvailable) {
          rackingManufacturerOptions.push({ name: rackingManufacturer });
        } else {
          if(!isInputAutocompleteEnabled) {
            setOtherRackingManufacturerEnabled(true) 
          };
          const otherRackingManufacturer = split(rackingManufacturer, '-', 2)[1];
          setRackingManufacturer(otherRackingManufacturer);
        }
      }

      if (
        attachmentManufacturer &&
        !attachmentManufacturerOptions.find(option => option.name === attachmentManufacturer) &&
        !otherAttachmentManufacturerOptions.find(option => option === attachmentManufacturer)
      ) {
        if (permitFilesAvailable) {
          attachmentManufacturerOptions.push({ name: attachmentManufacturer });
        } else {
          const otherAttachmentManufacturer = split(attachmentManufacturer, '-', 2)[1];
          if(!isInputAutocompleteEnabled) {
            setOtherAttachmentManufacturerEnabled(true);
          }
          setAttachmentManufacturer(otherAttachmentManufacturer);
        }
      }

      if (attachment_type && !attachmentTypeItemsOptions.includes(attachment_type)) {
        if (paymentStatus && !permitFilesAvailable) {
          attachmentTypeItemsOptions.push(attachment_type);
        } else {
          handleRackChange('', 'attachment_type');
        }
      }

      if (
        attachmentSpacing &&
        !attachmentSpacingOptions.includes(attachmentSpacing) &&
        !otherAttachmentSpacingOptions.includes(attachmentSpacing)
      ) {
        if (permitFilesAvailable) {
          attachmentSpacingOptions.push(attachmentSpacing);
        } else {
          setOtherMaxSpacingBetweenAttachmentsEnabled(true);
          const otherAttachmentSpacing = split(attachmentSpacing, '-', 2)[1];
          setOtherMaxSpacingBetweenAttachments(otherAttachmentSpacing);
        }
      }
    },
    [
      attachmentSpacing,
      attachmentSpacingOptions,
      attachmentTypeItems,
      attachmentTypeItemsOptions,
      attachment_spacing,
      attachment_type,
      handleRackChange,
      max_space_between_attachments,
      otherMaxSpacingBetweenAttachmentsEnabled,
      otherRackingManufacturerEnabled,
      otherAttachmentManufacturerEnabled,
      paymentStatus,
      permitFilesAvailable,
      rackingManufacturer,
      attachmentManufacturer,
      rackingManufacturerOptions,
      attachmentManufacturerOptions,
      racking_manufacturer,
      attachment_manufacturer,
      rackingMfs
    ]
  );

  const mostUsedRackingManufacturerList =
    modifyOptionList(mostUsedValues['racking_manufacturer'], rackingManufacturerOptions, true) || [];
  const showRecentRackingManufacturer = showRecentDropdown(mostUsedRackingManufacturerList, rackingManufacturerOptions);
  const filteredRackingManufacturerOptions = showRecentRackingManufacturer
    ? rackingManufacturerOptions
        .map(value => value.name)
        .filter(value => mostUsedRackingManufacturerList.indexOf(value) === -1)
    : rackingManufacturerOptions.map(value => value.name);

  const mostUsedAttachmentManufacturerList =
    modifyOptionList(mostUsedValues['attachment_manufacturer'], attachmentManufacturerOptions, true) || [];
  const showRecentAttachmentManufacturer = showRecentDropdown(
    mostUsedAttachmentManufacturerList,
    attachmentManufacturerOptions
  );
  const filteredAttachmentManufacturerOptions = showRecentAttachmentManufacturer
    ? attachmentManufacturerOptions
        .map(value => value.name)
        .filter(value => mostUsedAttachmentManufacturerList.indexOf(value) === -1)
    : attachmentManufacturerOptions.map(value => value.name);

  const mostUsedAttachmentTypeList =
    modifyOptionList(mostUsedValues['attachment_type'], attachmentTypeItemsOptions) || [];
  const showRecentAttachmentType = showRecentDropdown(mostUsedAttachmentTypeList, attachmentTypeItemsOptions);
  const filteredAttachmentTypeItemsOptions = showRecentAttachmentType
    ? attachmentTypeItemsOptions.filter(value => mostUsedAttachmentTypeList.indexOf(value) === -1)
    : attachmentTypeItemsOptions;

  const mostUsedAttachmentSpacingList =
    modifyOptionList(mostUsedValues['attachment_spacing'], attachmentSpacingOptions) || [];
  const showRecentAttachmentSpacing = showRecentDropdown(mostUsedAttachmentSpacingList, attachmentSpacingOptions);
  const filteredAttachmentSpacingOptions = showRecentAttachmentSpacing
    ? attachmentSpacingOptions.filter(value => mostUsedAttachmentSpacingList.indexOf(value) === -1)
    : attachmentSpacingOptions;

  const getErrorText = key => (get(errors, `${key}.error`) ? get(errors, `${key}.errorMessage`) : '');
  return (
    <div className={classes.root}>
      <PanelTemplate header="Racking & Attachment Properties" className={classes.panelContainer}>
        <Box className={panelWrapper}>
          <Grid className={classes.inputWrapper}>
            {/* <TextField
              id="racking_manufacturer"
              className={clsx(classes.inputField, labelClass.customLabel)}
              label={<Label className={classes.labelColor} text="Racking Manufacturer" />}
              value={racking_manufacturer}
              onChange={e => handleRackChange(e.target.value, 'racking_manufacturer')}
            /> */}
            {!isInputAutocompleteEnabled ? (
            <FormControl
              id="racking_manufacturer"
              className={clsx(classes.inputField, labelClass.customLabel)}
              error={false}
            >
              <Grid container>
                <Grid
                  item
                  xs={otherRackingManufacturerEnabled ? 6 : 12}
                  sm={otherRackingManufacturerEnabled ? 6 : 12}
                  className={clsx(otherRackingManufacturerEnabled && classes.otherSelect)}
                >
                  <InputLabel id="rmf-select-label">
                    <Label className={classes.labelColor} text="Racking Manufacturer" isRequired={true} />
                  </InputLabel>
                  <Select
                    IconComponent={props => <ExpandMoreIcon className="MuiSelect-icon" />}
                    labelId="rmf-select-label"
                    onChange={e => handleRackChange(e.target.value, 'racking_manufacturer')}
                    value={rackingManufacturer}
                    className={classes.selectBox}
                  >
                    {showRecentRackingManufacturer && (
                      <Typography className={classes.dropdownSectionHeaderStyle}>Recently Used</Typography>
                    )}
                    {showRecentRackingManufacturer &&
                      mostUsedRackingManufacturerList.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    {showRecentRackingManufacturer && (
                      <Divider variant="middle" classes={{ root: classes.dividerStyle }} />
                    )}
                    {filteredRackingManufacturerOptions &&
                      filteredRackingManufacturerOptions.map((rmf, i) => (
                        <MenuItem value={rmf} key={`id-rmf-${i}`}>
                          {rmf}
                        </MenuItem>
                      ))}
                    {otherRackingManufacturerOptions.length > 0 && (
                      <Divider variant="middle" classes={{ root: classes.dividerStyle }} />
                    )}
                    {otherRackingManufacturerOptions.length > 0 && (
                      <Typography className={classes.dropdownSectionHeaderStyle}>Other Values</Typography>
                    )}
                    {otherRackingManufacturerOptions.length > 0 &&
                      otherRackingManufacturerOptions.map((rmf, i) => (
                        <MenuItem value={rmf} key={`id-rmf-other-${i}`}>
                          {split(rmf, '-', 2)[1]}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText className={classes.errorText}>{getErrorText('racking_manufacturer')}</FormHelperText>
                </Grid>
                {otherRackingManufacturerEnabled && (
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="otherRackingManufacturer"
                      className={clsx(classes.otherInput, labelClass.customLabel)}
                      label={<Label className={classes.labelColor} text="Manufacturer Name" />}
                      value={otherRackingManufacturer || ''}
                      onChange={e => handleRackChange(e.target.value, 'racking_manufacturer', true)}
                    />
                  </Grid>
                )}
              </Grid>
            </FormControl> ) :
            (<AutoCompleteDropdown
              id={'racking_manufacturer'}
              className={clsx(classes.inputField, labelClass.customLabel)}
              value={rackingManufacturer}
              handleChange={handleRackChange}
              optionList={removeOtherOption(filteredRackingManufacturerOptions)}
              otherOptionList={otherRackingManufacturerOptions}
              labelText={'Racking Manufacturer'}
              error={!isEmpty(getErrorText('racking_manufacturer'))}
              helperText={getErrorText('racking_manufacturer')}
              classes={classes}
              labelClass={labelClass}
              isPermitSetAvailable={isPermitSetAvailable}
              showRecent={showRecentRackingManufacturer}
              mostUsedList={mostUsedRackingManufacturerList}
              isRequired
            />)
            }
            <Autocomplete
              className={clsx(classes.inputField, labelClass.customLabel)}
              id="racking_model"
              options={mostUsedValues['racking_model']}
              value={racking_model}
              onChange={(e,value) => handleRackChange(value, 'racking_model')}
              renderInput={params => (
                <TextField
                  {...params}
                  error={get(errors, `racking_model.error`)}
                  helperText={getErrorText('racking_model')}
                  label={<Label className={classes.labelColor} text="Racking Model #" isRequired />}
                  onChange={e => handleRackChange(e.target.value, 'racking_model')}
                />
              )}
            />
          </Grid>

          <Grid className={classes.inputWrapper}>
          {!isInputAutocompleteEnabled ? (
            <FormControl
              id="attachment_manufacturer"
              className={clsx(classes.inputField, labelClass.customLabel)}
              error={false}
            >
              <Grid container>
                <Grid
                  item
                  xs={otherAttachmentManufacturerEnabled ? 6 : 12}
                  sm={otherAttachmentManufacturerEnabled ? 6 : 12}
                  className={clsx(otherAttachmentManufacturerEnabled && classes.otherSelect)}
                >
                  <InputLabel id="amf-select-label">
                    <Label className={classes.labelColor} text="Attachment Manufacturer" isRequired={true} />
                  </InputLabel>
                  <Select
                    IconComponent={props => <ExpandMoreIcon className="MuiSelect-icon" />}
                    labelId="amf-select-label"
                    onChange={e => handleRackChange(e.target.value, 'attachment_manufacturer')}
                    value={attachmentManufacturer}
                    className={classes.selectBox}
                  >
                    {showRecentAttachmentManufacturer && (
                      <Typography className={classes.dropdownSectionHeaderStyle}>Recently Used</Typography>
                    )}
                    {showRecentAttachmentManufacturer &&
                      mostUsedAttachmentManufacturerList.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    {showRecentAttachmentManufacturer && (
                      <Divider variant="middle" classes={{ root: classes.dividerStyle }} />
                    )}
                    {filteredAttachmentManufacturerOptions &&
                      filteredAttachmentManufacturerOptions.map((amf, i) => (
                        <MenuItem value={amf} key={`id-amf-${i}`}>
                          {amf}
                        </MenuItem>
                      ))}
                    {otherAttachmentManufacturerOptions.length > 0 && (
                      <Divider variant="middle" classes={{ root: classes.dividerStyle }} />
                    )}
                    {otherAttachmentManufacturerOptions.length > 0 && (
                      <Typography className={classes.dropdownSectionHeaderStyle}>Other Values</Typography>
                    )}
                    {otherAttachmentManufacturerOptions.length > 0 &&
                      otherAttachmentManufacturerOptions.map((amf, i) => (
                        <MenuItem value={amf} key={`id-amf-other-${i}`}>
                          {split(amf, '-', 2)[1]}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText className={classes.errorText}>
                    {getErrorText('attachment_manufacturer')}
                  </FormHelperText>
                </Grid>
                {otherAttachmentManufacturerEnabled && (
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="otherAttachmentManufacturer"
                      className={clsx(classes.otherInput, labelClass.customLabel)}
                      label={<Label className={classes.labelColor} text="Manufacturer Name" />}
                      value={otherAttachmentManufacturer || ''}
                      onChange={e => handleRackChange(e.target.value, 'attachment_manufacturer', true)}
                    />
                  </Grid>
                )}
              </Grid>
            </FormControl>
            ):(
              <AutoCompleteDropdown
              id={'attachment_manufacturer'}
              className={clsx(classes.inputField, labelClass.customLabel)}
              value={attachmentManufacturer}
              handleChange={handleRackChange}
              optionList={removeOtherOption(filteredAttachmentManufacturerOptions)}
              otherOptionList={otherAttachmentManufacturerOptions}
              labelText={'Attachment Manufacturer'}
              error={!isEmpty(getErrorText('attachment_manufacturer'))}
              helperText={getErrorText('attachment_manufacturer')}
              classes={classes}
              labelClass={labelClass}
              isPermitSetAvailable={isPermitSetAvailable}
              showRecent={showRecentAttachmentManufacturer}
              mostUsedList={mostUsedAttachmentManufacturerList}
              isRequired
            />
            )}
            <Autocomplete
              className={clsx(classes.inputField, labelClass.customLabel)}
              id="attachment_model"
              options={mostUsedValues['attachment_model']}
              value={attachment_model}
              onChange={(e,value) => handleRackChange(value, 'attachment_model')}
              renderInput={params => (
                <TextField
                  {...params}
                  error={get(errors, `attachment_model.error`)}
                  helperText={getErrorText('attachment_model')}
                  label={<Label className={classes.labelColor} text="Attachment Model #" isRequired />}
                  onChange={e => handleRackChange(e.target.value, 'attachment_model')}
                />
              )}
            />
          </Grid>

          <Grid className={classes.inputWrapper}>
            <FormControl id="attachmentType" className={clsx(classes.inputField, labelClass.customLabel)} error={false}>
              <InputLabel id="locale-select-label">
                <Label className={classes.labelColor} text="Attachment Type" />
              </InputLabel>
              <Select
                IconComponent={props => <ExpandMoreIcon className="MuiSelect-icon" />}
                labelId="locale-select-label"
                onChange={e => handleRackChange(e.target.value, 'attachment_type')}
                id="attachmentType"
                value={attachment_type}
              >
                {showRecentAttachmentType && (
                  <Typography className={classes.dropdownSectionHeaderStyle}>Recently Used</Typography>
                )}
                {showRecentAttachmentType &&
                  mostUsedAttachmentTypeList.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                {showRecentAttachmentType && <Divider variant="middle" classes={{ root: classes.dividerStyle }} />}
                <MenuItem value={''}>Please Select</MenuItem>
                {filteredAttachmentTypeItemsOptions &&
                  filteredAttachmentTypeItemsOptions.map((type, i) => (
                    <MenuItem value={type} key={`id-spacing-${i}`}>
                      {type}
                    </MenuItem>
                  ))}
              </Select>
              {false && <FormHelperText>{'Error Message'}</FormHelperText>}
            </FormControl>
            <FormControl
              id="maxSpacingBetweenAttachments"
              className={clsx(classes.inputField, labelClass.customLabel)}
              error={false}
            >
              <Grid container>
                <Grid
                  item
                  xs={otherMaxSpacingBetweenAttachmentsEnabled ? 6 : 12}
                  sm={otherMaxSpacingBetweenAttachmentsEnabled ? 8 : 12}
                  className={clsx(otherMaxSpacingBetweenAttachmentsEnabled && classes.otherSelect)}
                >
                  <InputLabel id="locale-select-label">
                    <Label
                      className={classes.labelColor}
                      text={isSmallScreen ? 'Attachment Spacing (Max)' : 'Max Spacing Between Attachments'}
                    />
                  </InputLabel>
                  <Select
                    IconComponent={props => (
                      <Fragment>
                        {!otherMaxSpacingBetweenAttachmentsEnabled && (
                          <Typography className={classes.units}>{isSmallScreen ? 'in' : 'inches'}</Typography>
                        )}
                        <ExpandMoreIcon className="MuiSelect-icon" />
                      </Fragment>
                    )}
                    labelId="locale-select-label"
                    onChange={e => handleRackChange(e.target.value, 'attachment_spacing')}
                    id="maxSpacingBetweenAttachments"
                    value={attachmentSpacing}
                    className={classes.selectBox}
                    error={get(errors, `maxSpacingBetweenAttachments.error`)}
                  >
                    {showRecentAttachmentSpacing && (
                      <Typography className={classes.dropdownSectionHeaderStyle}>Recently Used</Typography>
                    )}
                    {showRecentAttachmentSpacing &&
                      mostUsedAttachmentSpacingList.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    {showRecentAttachmentSpacing && (
                      <Divider variant="middle" classes={{ root: classes.dividerStyle }} />
                    )}
                    <MenuItem value={''}>Please Select</MenuItem>
                    {filteredAttachmentSpacingOptions &&
                      filteredAttachmentSpacingOptions.map((spacing, i) => (
                        <MenuItem value={spacing} key={`id-spacing-${i}`}>
                          {spacing}
                        </MenuItem>
                      ))}
                    {otherAttachmentSpacingOptions.length > 0 && (
                      <Divider variant="middle" classes={{ root: classes.dividerStyle }} />
                    )}
                    {otherAttachmentSpacingOptions.length > 0 && (
                      <Typography className={classes.dropdownSectionHeaderStyle}>Other Values</Typography>
                    )}
                    {otherAttachmentSpacingOptions.length > 0 &&
                      otherAttachmentSpacingOptions.map((spacing, i) => (
                        <MenuItem value={spacing} key={`id-spacing-other-${i}`}>
                          {split(spacing, '-', 2)[1]}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText className={classes.errorText}>{getErrorText('maxSpacingBetweenAttachments')}</FormHelperText>
                </Grid>
                {otherMaxSpacingBetweenAttachmentsEnabled && (
                  <Grid item xs={6} sm={4}>
                    <TextField
                      type="number"
                      id="otherMaxSpacingBetweenAttachments"
                      className={clsx(labelClass.customLabel, classes.otherInput)}
                      InputProps={{ endAdornment: <InputAdornment position="end">in</InputAdornment> }}
                      label={<Label className={classes.labelColor} text="Provide Value" />}
                      value={otherMaxSpacingBetweenAttachments || ''}
                      onChange={e => handleRackChange(e.target.value, 'attachment_spacing', true)}
                      onKeyPress={(e) => preventDefaultDecimal(e)}
                    />
                  </Grid>
                )}
              </Grid>
            </FormControl>
            {false && <FormHelperText>{'Error Message'}</FormHelperText>}
          </Grid>

          <Grid className={classes.inputWrapper}>
            <TypableAutocomplete
              id="wind_load"
              value={wind_load}
              handleRackChange={handleRackChange}
              mostUsedList={mostUsedValues['wind_load']}
              label={<Label className={classes.labelColor} text="Wind Speed" />}
              classes={classes}
              labelClass={labelClass}
              subText="mph"
              hidePopper={hideWindLoadPopper}
              setHidePopper={setHideWindLoadPopper}
              materialDetails={materialDetails}
              getErrorText={getErrorText}
            />
            <TextField
              id="notes"
              className={clsx(classes.inputField, labelClass.customLabel)}
              label={<Label className={classes.labelColor} text="Structural & Attachment Notes" />}
              value={notes}
              onChange={e => handleRackChange(e.target.value, 'notes')}
            />
          </Grid>

          <Grid className={classes.inputWrapper}>
            <TypableAutocomplete
              id="snow_load"
              value={snow_load}
              handleRackChange={handleRackChange}
              mostUsedList={mostUsedValues['snow_load']}
              label={<Label className={classes.labelColor} text="Snow Load" />}
              classes={classes}
              labelClass={labelClass}
              subText="psf"
              hidePopper={hideSnowLoadPopper}
              setHidePopper={setHideSnowLoadPopper}
              materialDetails={materialDetails}
              getErrorText={getErrorText}
            />
          </Grid>
        </Box>
      </PanelTemplate>
    </div>
  );
}

const mapStateToProps = state => ({
  mostUsedValues: state.permitReducer.mostUsedValues,
  otherUserSelectionData: state.permitReducer.otherUserSelectionData
});

export default connect(mapStateToProps)(RackingAndAttachments);
