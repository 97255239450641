import { Box, CircularProgress, Link, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { INVALID_LINK_WARNING } from '../../../containers/Permitting/constants';
import DocumentIcon from '../images/document';

export const FilesSection = ({ files, atticPhotos, removeAddedDoc, classes }) => {
  return (
    <Fragment>
      {Object.keys(files).map((key, index) => {
        const { loading = false, previewImageUrl = '', attic_photo = false } = files[key];
        if ((atticPhotos && !attic_photo) || (!atticPhotos && attic_photo)) {
          return <Fragment />;
        }
        const fileExt = key.split('.').pop();
        const isImage = [ 'png', 'jpg', 'jpeg', 'svg' ].includes(fileExt);
        return (
          <Box className={classes.imgHolder} key={`${key}-${index}`}>
            {loading && <CircularProgress />}
            {!loading && (
              <Box>
                <Box className={classes.imgContainer}>
                  <Close onClick={() => removeAddedDoc(key)} className={classes.close} />
                  {isImage ? <img className={classes.image} alt={key} src={previewImageUrl} /> : <DocumentIcon />}
                </Box>
                <Typography variant="caption" display="block">
                  {key}
                </Typography>
              </Box>
            )}
          </Box>
        );
      })}
    </Fragment>
  );
};

const AtticFilesUpload = ({
  classes,
  fileRef,
  files,
  handleUrlChange,
  atticPhotosLink,
  atticPhotosLinkErrorText,
  atticPhotosLinkWarning,
  disableChooseFileButton,
  setAddingAtticPhoto,
  removeAddedDoc
}) => {
  return (
    <Box className={classes.sectionBody}>
      <Typography className={classes.info}>
        Attic photos(Attic Far Left, Attic Far Right, Attic Center Left, Attic Center Right) are required for the stamps
      </Typography>
      <Link
        className={classes.link}
        onClick={() => {
          setAddingAtticPhoto(true);
          fileRef.current.click();
        }}
        color="primary"
        variant="outlined"
        size="small"
        disabled={disableChooseFileButton}
      >
        + Add File
      </Link>
      <FilesSection files={files} atticPhotos={true} removeAddedDoc={removeAddedDoc} classes={classes} />
      <Typography variant="caption" display="block">
        Share document link
      </Typography>
      <TextField
        onChange={e => handleUrlChange(e.target.value)}
        className={classes.download}
        label={'Give us a link from GoogleDrive, Dropbox etc'}
        value={atticPhotosLink}
        error={!isEmpty(atticPhotosLinkErrorText)}
        helperText={atticPhotosLinkErrorText}
      />
      {atticPhotosLinkWarning && <Typography className={classes.warningText}>{INVALID_LINK_WARNING}</Typography>}
    </Box>
  );
};

export default AtticFilesUpload;
