import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    fontSize: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    textTransform: 'none',
    height: theme.spacing(4.75),
    padding: theme.spacing(0, 3),
    border: '1px solid',
    borderColor: theme.palette.tertiary.buttonColor,
    color: theme.palette.tertiary.buttonColor,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#f2ece9',
    },
    minWidth: 'auto',
  },
  adminActionButtons: {
    width: '100%',
    fontSize: theme.spacing(1.7),
    overflow: 'auto',
    textOverflow: 'ellipsis',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    marginTop: theme.spacing(1),
  },
  disableStyle: {
    opacity: 0.5,
  },
  smaller: {
    fontSize: theme.spacing(1.7),
    padding: theme.spacing(0, 2),
    height: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  noMargin: {
    margin: 0,
  },
  downloadLogo: {
    fontSize: theme.spacing(2.5),
  },
}));

/**
 * MUI Button with custom outlined styling
 * @param props
 * @returns a MUI button with contained styles
 */
const OutlinedButton = (props) => {
  const classes = useStyles();
  const {
    text,
    size = 'small',
    handleClick,
    adminActionButtons,
    showDownloadButton = false,
    disabled,
    smaller,
    noMargin,
    statusDashboardBtnStyle,
  } = props;
  const classNames = clsx(
    classes.buttonStyle,
    disabled && classes.disableStyle,
    adminActionButtons && classes.adminActionButtons,
    smaller && classes.smaller,
    noMargin && classes.noMargin,
    statusDashboardBtnStyle && statusDashboardBtnStyle
  );
  return (
    <Button onClick={(e) => handleClick(e)} size={size} className={classNames} disabled={disabled} disableRipple>
      {showDownloadButton && <SystemUpdateAltIcon className={clsx(classes.downloadLogo, classes.dataHeader)} />}
      &nbsp;&nbsp;
      {text}
    </Button>
  );
};

export default OutlinedButton;
