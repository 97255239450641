import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  TextField,
  Button,
  Divider,
  DialogContentText
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  commentField: {
    marginBottom: theme.spacing(1.5)
  },
  dialogActions: {
    padding: theme.spacing(1, 3, 2, 3),
    justifyContent: 'end'
  },
  secondaryHeader: {
    marginBottom: theme.spacing(3)
  },
  secondaryHeaderText: {
    fontSize: 14,
    opacity: 0.8
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  close: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    opacity: 0.23
  },
  header: {
    display: 'flex'
  },
  dialogHeader: {
    color: theme.palette.primary.dark,
    fontSize: theme.spacing(2.9),
    fontWeight: 'bold'
  },
  dialogButton: {
    color: theme.palette.primary.white
  },
  commentHeader: {
    marginBottom: theme.spacing(0.5)
  }
}));

const CommentDialog = props => {
  const classes = useStyles();
  const {
    open,
    onClose,
    dialogHeader,
    secondaryHeaderText,
    additionalSecondaryHeaderText,
    handleProceed,
    comment,
    setComment
  } = props;
  return (
    <Fragment>
      <Dialog open={open} fullWidth onClose={onClose}>
        <DialogTitle>
          <Box className={classes.header}>
            <Typography className={classes.dialogHeader}>{dialogHeader}</Typography>
            <IconButton onClick={onClose} className={classes.close}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider className={classes.divider} />
        <DialogContent>
          <Box className={classes.secondaryHeader}>
            <Typography className={classes.secondaryHeaderText}>{secondaryHeaderText}</Typography>
            {!isEmpty(additionalSecondaryHeaderText) && (
              <Typography className={classes.secondaryHeaderText}>{additionalSecondaryHeaderText}</Typography>
            )}
          </Box>
          <DialogContentText className={classes.commentHeader}>Add Comment(Optional)</DialogContentText>
          <TextField
            value={comment}
            onChange={e => setComment(e.target.value)}
            multiline
            rows={5}
            rowsMax={20}
            fullWidth
            variant="outlined"
            className={classes.commentField}
            placeholder="Type here"
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant="contained" color="primary" className={classes.dialogButton} onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" color="primary" className={classes.dialogButton} onClick={handleProceed}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default CommentDialog;
