import moment from 'moment';
import { get, isEmpty, find } from '../../utils/lodash';
import validator from 'validator';
import { APPLICATION_STATES, COMMON_STATES, DESIGNER_STATES } from '../../utils/constants';
import { DOC_TYPE } from './RequestDetails/constants';
import { COMPANY_DELIMITER, VOLTAGE_RATING, VOLTAGE_PHASE } from './constants';
import pdfIcon from '../../components/Admin/RequestDetails/images/pdfIcon.svg';
import jpegIcon from '../../components/Admin/RequestDetails/images/jpegIcon.svg';
import pngIcon from '../../components/Admin/RequestDetails/images/pngIcon.svg';
import notesIcon from '../../components/Admin/RequestDetails/images/notesIcon.svg';
import documentIcon from '../../components/Admin/RequestDetails/images/document.svg';

export function getColor(name) {
  switch (name) {
    case APPLICATION_STATES.NEW_APPLICATION_RECEIVED.name:
      return '#55D8FE';
    case APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.name:
      return '#FFD401';
    case APPLICATION_STATES.APPLICATION_UNDER_REVIEW.name:
      return '#277DFE';
    case APPLICATION_STATES.PERMIT_DRAWINGS_ON_HOLD.name:
      return '#0951BC';
    case APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED.name:
      return '#0951DC';
    case APPLICATION_STATES.QA_STARTED.name:
      return '#490601';
    case APPLICATION_STATES.QA_ON_HOLD.name:
      return '#490601';
    case APPLICATION_STATES.QA_COMPLETED.name:
      return '#89BE11';
    case APPLICATION_STATES.PERMIT_FILES_DOWNLOADED.name:
      return '#FF8B00';
    case APPLICATION_STATES.STAMPING_REQUESTED.name:
      return '#AA0C00';
    case APPLICATION_STATES.STAMPING_PE_REVIEW.name:
      return '#AA0C00';
    case APPLICATION_STATES.STAMPING_SUBMITTED.name:
      return '#119920';
    case APPLICATION_STATES.MORE_INFO_REQUESTED.name:
      return '#2E5528';
    case APPLICATION_STATES.MORE_INFO_RECEIVED.name:
      return '#CC655A';
    default:
  }
}

export function getPieChartDataArray(array) {
  let result = [];
  array.map((value) => {
    result.push({ name: value.stage_name, y: value.total, color: getColor(value.stage_name) });
    return null;
  });
  return result;
}

export function getFullName(appDetails) {
  if (appDetails.first_name === '' && appDetails.last_name === '') {
    return 'NA';
  } else {
    return appDetails.first_name + ' ' + appDetails.last_name;
  }
}

const addStatus = (sArray, state, ind, updateStatus) => {
  sArray.push({
    label: state.name,
    id: state.id,
    index: ind,
    infoText: '',
    completedOn: '',
    actions: { updateStatus: updateStatus },
    isGreen: false,
    substate_id: 0,
    substate_name: '',
    version: '',
    users: [],
  });
};

const addNextStatus = (sArray, currentStage, ind, currentStageVersion) => {
  if (currentStage === APPLICATION_STATES.NEW_APPLICATION_RECEIVED.id) {
    addStatus(sArray, APPLICATION_STATES.NEW_APPLICATION_ASSIGNED, ind++);
    addStatus(sArray, APPLICATION_STATES.APPLICATION_UNDER_REVIEW, ind++);
    addStatus(sArray, APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED, ind++);
    addStatus(sArray, APPLICATION_STATES.QA_STARTED, ind++);
    addStatus(sArray, APPLICATION_STATES.QA_COMPLETED, ind++);
    addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
  } else if (currentStage === APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.id) {
    addStatus(sArray, APPLICATION_STATES.APPLICATION_UNDER_REVIEW, ind++, true);
    addStatus(sArray, APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED, ind++);
    addStatus(sArray, APPLICATION_STATES.QA_STARTED, ind++);
    addStatus(sArray, APPLICATION_STATES.QA_COMPLETED, ind++);
    addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
  } else if (currentStage === APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id) {
    addStatus(sArray, APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED, ind++);
    addStatus(sArray, APPLICATION_STATES.QA_STARTED, ind++);
    addStatus(sArray, APPLICATION_STATES.QA_COMPLETED, ind++);
    addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
  } else if (currentStage === APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED.id) {
    addStatus(sArray, APPLICATION_STATES.QA_STARTED, ind++, true);
    addStatus(sArray, APPLICATION_STATES.QA_COMPLETED, ind++);
    addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
  } else if (currentStage === APPLICATION_STATES.QA_STARTED.id) {
    addStatus(sArray, APPLICATION_STATES.QA_COMPLETED, ind++);
    addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
  } else if (currentStage === APPLICATION_STATES.QA_COMPLETED.id) {
    addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
  } else if (currentStage === APPLICATION_STATES.MORE_INFO_REQUESTED.id) {
    addStatus(sArray, APPLICATION_STATES.MORE_INFO_RECEIVED, ind++, true);
    if (sArray[sArray.length - 3].id === APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id) {
      addStatus(sArray, APPLICATION_STATES.APPLICATION_UNDER_REVIEW, ind++);
      addStatus(sArray, APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED, ind++);
      addStatus(sArray, APPLICATION_STATES.QA_STARTED, ind++);
      addStatus(sArray, APPLICATION_STATES.QA_COMPLETED, ind++);
      addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
    } else if (sArray[sArray.length - 3].id === APPLICATION_STATES.QA_STARTED.id) {
      addStatus(sArray, APPLICATION_STATES.QA_STARTED, ind++);
      addStatus(sArray, APPLICATION_STATES.QA_COMPLETED, ind++);
      addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
    } else {
      addStatus(sArray, APPLICATION_STATES.STAMPING_SUBMITTED, ind++);
    }
  } else if (currentStage === APPLICATION_STATES.MORE_INFO_RECEIVED.id) {
    // In case of multiple more info requests, sArray[sArray.length - 3] won't be QA_STARTED.
    const isMoreInfoReceived = sArray[sArray.length - 3].id === APPLICATION_STATES.MORE_INFO_RECEIVED.id;
    const lastReviewState = getLastReviewState(sArray);
    // QA_COMPLETED only for the current version.
    const isPackageUploaded =
      sArray.filter((state) => state.id === APPLICATION_STATES.QA_COMPLETED.id && state.version === currentStageVersion)
        .length > 0;
    const stampingSubmitIds = [APPLICATION_STATES.STAMPING_REQUESTED.id,APPLICATION_STATES.STAMPING_PE_REVIEW.id, APPLICATION_STATES.STAMPING_SUBMITTED.id];

    if (
      sArray[sArray.length - 3].id === APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id ||
      (isMoreInfoReceived && !isPackageUploaded)
    ) {
      addStatus(sArray, APPLICATION_STATES.APPLICATION_UNDER_REVIEW, ind++, true);
      addStatus(sArray, APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED, ind++);
      addStatus(sArray, APPLICATION_STATES.QA_STARTED, ind++);
      addStatus(sArray, APPLICATION_STATES.QA_COMPLETED, ind++);
      addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
    } else if (sArray[sArray.length - 3].id === APPLICATION_STATES.QA_STARTED.id) {
      addStatus(sArray, APPLICATION_STATES.QA_STARTED, ind++, true);
      addStatus(sArray, APPLICATION_STATES.QA_COMPLETED, ind++);
      addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
    } else if(stampingSubmitIds.includes(lastReviewState.state_id)){
      addStatus(sArray, APPLICATION_STATES.STAMPING_SUBMITTED, ind++);
    } else if (isPackageUploaded) {
      addStatus(sArray, APPLICATION_STATES.QA_COMPLETED, ind++);
      addStatus(sArray, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED, ind++);
    } else {
      addStatus(sArray, APPLICATION_STATES.STAMPING_SUBMITTED, ind++);
    }
  }
  return ind;
};

/**
 * Returns the List of Assigned Users (Designers and Reviewers) for a particular state
 * @param appDetails Permit Application to be used
 * @param stateId State Id to be used
 */
const getAssignedUsers = (appDetails, stateId, version) => {
  const designers = get(appDetails, 'designers', []) || [];
  const reviewers = get(appDetails, 'reviewers', []) || [];
  let users = [];
  for (const i in designers) {
    if (designers[i]?.state_id === stateId && designers[i]?.version === version) {
      users.push({ designer: designers[i] });
    }
  }
  for (const i in reviewers) {
    if (reviewers[i]?.state_id === stateId && reviewers[i]?.version === version) {
      const ind = users.findIndex((user) => user.designer?.assigned_date === reviewers[i]?.assigned_date);
      if (ind !== -1) {
        users[ind].reviewer = reviewers[i];
      } else {
        users.push({ reviewer: reviewers[i] });
      }
    }
  }
  users = users.sort(function (a, b) {
    return (
      new Date(a?.designer?.assigned_date || a?.reviewer?.assigned_date) -
      new Date(b?.designer?.assigned_date || b?.reviewer?.assigned_date)
    );
  });
  return users;
};

export function getStatusDetails(appDetails) {
  let sArray = [];
  const sDetails = {
    sArray: [],
    currentStage: 0,
    index: 0,
  };
  const appStages = appDetails.third_party_statuses;
  let version = '';
  let j = 0,
    ind = 0;
  if (appStages) {
    for (; j < appStages.length; j++) {
      const cStage = appStages[j];
      sArray.push({
        label: cStage.state_name,
        id: cStage.state_id,
        state_id: cStage.state_id,
        index: j + 1,
        infoText: '',
        completedOn: moment(cStage.last_updated_date).format('DD MMM YYYY hh:mm A'),
        actions: {
          updateStatus: false,
          uploadPermitDocument: false,
          uploadReworkDocument: false,
        },
        isGreen: false,
        substate_id: 0,
        substate_name: '',
        version: cStage.version,
        users: getAssignedUsers(appDetails, cStage.state_id, cStage.version),
      });
      sDetails.currentStage = cStage.state_id;
      if (cStage.version !== '' && cStage.version !== version) {
        ind = 0;
        version = cStage.version;
      }
      ind++;
    }
    addNextStatus(sArray, sDetails.currentStage, ind, version);
  }
  sDetails.sArray = sArray;
  sDetails.index = ind;
  return sDetails;
}

/**
 * Returns the Previous state which is neither More information requested nor More information received
 * @param statuses List of Third party statuses to be used
 */
export const getLastReviewState = (statuses) => {
  if (statuses) {
    for (let i = statuses.length - 1; i >= 0; i--) {
      if (
        statuses[i].state_id !== APPLICATION_STATES.MORE_INFO_REQUESTED.id &&
        statuses[i].state_id !== APPLICATION_STATES.MORE_INFO_RECEIVED.id
      ) {
        return statuses[i];
      }
    }
  }
  return null;
};

/**
 * Returns the Current Assigned User (Designer or Reviewer)
 * @param app Permit Application to be used
 */
export const getAssignedTo = (app) => {
  const stateId = get(app, 'third_party_current_status.state_id', 0);
  const statuses = get(app, 'third_party_statuses', []);
  if (COMMON_STATES.includes(stateId)) {
    let status = getLastReviewState(statuses);
    if (status) {
      return status.state_id === APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id
        ? get(app, 'current_designer.user_info', '')
        : get(app, 'current_reviewer.user_info', '');
    }
  }
  return DESIGNER_STATES.includes(stateId)
    ? get(app, 'current_designer.user_info', '')
    : get(app, 'current_reviewer.user_info', '');
};

export function formattedDate(rowDate) {
  if (isEmpty(rowDate)) return 'NA';
  return moment(rowDate).format('DD MMM YYYY hh:mm A');
}

export function selectMultipleRender(selected = {}, optionList = [], key = 'title') {
  if (key === 'name') {
    return selected.map((ele) => get(find(optionList, { name: ele }), key)).join(', ');
  } else if (key === 'full_name') {
    return selected.map((ele) => get(find(optionList, { full_name: ele }), key)).join(', ');
  } else return selected.map((ele) => get(find(optionList, { value: ele }), key)).join(', ');
}

export function preventDefault(event) {
  if (event.which !== 8 && event.which !== 0 && (event.which < 48 || event.which > 57)) {
    event.preventDefault();
  }
}

export function preventDefaultDecimal(event) {
  if (event.which !== 8 && event.which !== 0 && (event.which < 48 || event.which > 57) && event.which !== 46) {
    event.preventDefault();
  }
}

export const getIconPath = (doc) => {
  if (doc.id === 1) {
    return pdfIcon;
  } else {
    const fName = doc.file_name;
    const fTypeArray = fName.split('.');
    const fType = fTypeArray[fTypeArray.length - 1];
    switch (fType.toLowerCase()) {
      case 'pdf':
        return pdfIcon;
      case 'jpg':
      case 'jpeg':
        return jpegIcon;
      case 'png':
        return pngIcon;
      case 'notes':
        return notesIcon;
      default:
        return documentIcon;
    }
  }
};

export const addDocs = (docs, allDocs, key, onlyApprovalDoc) => {
  for (let i = 0; i < docs.length; i++) {
    const doc = docs[i];
    if (doc.file_name && (!onlyApprovalDoc || doc.doc_type === DOC_TYPE.APPROVAL_DOC)) {
      allDocs.push({
        type: 'file',
        name: doc.file_name,
        sub: doc.tag,
        file_name: doc.file_name,
        downloadInProgress: false,
        id: `${i}_${key}`,
        date: get(doc, 'added_date', ''),
        versions: doc.versions,
        comment: doc.comment,
        user_info: doc.user_info,
        doc_url: doc.doc_url,
      });
    }
  }
};

export const addNotes = (notes, allDocs, key) => {
  for (let i = 0; i < notes.length; i++) {
    const note = notes[i];
    allDocs.push({
      type: 'notes',
      file_name: 'notes.notes', // The file_name is required for fetching the display icon
      id: note.id,
      date: get(note, 'date', ''),
      name: note.content,
      user_info: note.user_info,
      versions: note.versions,
    });
  }
};

export const getFiles = (doc, comment, versions) => {
  return {
    file_name: doc.fileName,
    original_file_name: doc.name,
    file_size: doc.fileSize / 1024 / 1024,
    loading: false,
    comment: comment,
    versions: versions,
  };
};

const base64toBlob = (b24) => {
  const byteCharacters = atob(b24);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return byteArray;
};

/**
 * Downloads the analytics report after decoding the API response.
 * @param file Object that has encoded content & fileName.
 * @param mime_type Type of file
 */
export const downloadFile = (file, mime_type = 'text/text') => {
  const blob = new Blob([base64toBlob(file.content)], { type: mime_type });
  var downloadLink = document.createElement('a');
  downloadLink.download = file.name;
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.click();
  downloadLink.remove();
};

/**
 * returns a function which converts an object with installer name and installer id to a string
 * @param name - name of the property which contains installer nameobject
 * @param id - name of the property which conatins installer id
 * @return function which takes company object and converts it to a string
 */
export const companyObjectToString = (name, id) => {
  return (company) => `${company[name]} ${COMPANY_DELIMITER} ${company[id]})`;
};

/**
 * returns a function which converts a given string to an object
 * @param {String = 'ENLIGHTEN_PERMIT', 'SOLARGARF'} source - source of the application
 * @return function which takes a string with installer name and id and returns an object
 */
export const companyStringToObject = (source) => {
  return (company) => {
    const installer_name = company.split(COMPANY_DELIMITER)[0].trim();
    const installer_id = company.split(COMPANY_DELIMITER)[1].replace(')', ' ').trim();
    return {
      source,
      installer_id,
      installer_name,
    };
  };
};

/**
 * get the number of decimal places in a given value
 * @param {String} value
 * @returns the number of decimal places
 */
export const countDecimals = (value) => {
  if (value.includes('.')) {
    const decimalPart = value.split('.')[1];
    return decimalPart.length;
  }
  return 0;
};

export const getFileMetaData = (file, callback) => {
  if (!isEmpty(file.url) && validator.isURL(encodeURI(file.url))) {
    const pdfjsLib = window.pdfjsLib;
    if (pdfjsLib) {
      const loadingTask = pdfjsLib.getDocument(file.url);
      loadingTask.promise
        .then((pdf) => {
          let file_size = pdf._transport._fullReader._contentLength / 1000;
          let meta_data = {
            ...file,
            page_count: pdf.numPages,
            file_size: `${file_size.toFixed(2)} KB`,
            display_file_name: file.url.split('/').at(-1),
            original_file_name: file.url.split('/').at(-1),
          };
          callback(meta_data);
        })
        .catch((e) => {
          let meta_data = {
            ...file,
            page_count: '',
            file_size: '',
            display_file_name: '',
            original_file_name: '',
          };
          callback(meta_data);
        });
    } else {
      let meta_data = {
        ...file,
        page_count: '',
        file_size: '',
        display_file_name: file.url.split('/').at(-1),
        original_file_name: file.url.split('/').at(-1),
      };
      callback(meta_data);
    }
  }
};

/**
 * returns a key (nominal voltage) which is common to both voltage_rating and voltage_phase list
 * @param voltageRating Nominal Voltage AC (AC Panels, Central and Micro Inverters)/Min Voltage AC (Hybrid Inverter)
 * @param voltagePhase Phase Value (e.g: V230_400_3PH ==> 3)
 * @return the nominal voltage key which has voltageRating and voltagePhase as its values
 */
export const getNominalVoltageKey = (voltageRating, voltagePhase) => {
  if (Number(voltageRating) === 400) {
    return `V230_400_${voltagePhase || 3}PH`;
  } else {
    return Object.keys(VOLTAGE_RATING).find(key => VOLTAGE_RATING[key] === voltageRating.toString()) ?? '';
  }
};