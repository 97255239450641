import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { Typography, Box, Toolbar, Avatar, AppBar } from '@material-ui/core';
import PageHeader from '../../common/PageHeader';
import Button from '@material-ui/core/Button';
import FooterLogo from '../images/footer_logo.png';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { createEmailNpsFeedback } from '../../../containers/Permitting/action';
import { cloneDeep } from '../../../utils/lodash';
import SnakBar from '../../SnakBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { npsBackgrdImg } from '../images';

const styles = (theme) => ({
  header: {
    display: 'flex',
    background: theme.palette.primary.white,
    flexDirection: 'row',
    boxShadow: 'none',
    '& .MuiIconButton-root': {
      borderRadius: 0,
    },
    height: theme.spacing(8),
  },
  large: {
    width: theme.spacing(22),
    height: theme.spacing(5),
  },
  toolbar: {
    padding: 0,
    minHeight: 0,
  },
  headerToolbar: {
    margin: theme.spacing(0, 4),
  },
  container: {
    backgroundImage: `url(${npsBackgrdImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    width: '100%',
    height: 'calc(100vh - 64px - 24px)',
    paddingTop: theme.spacing(3),
  },
  card: {
    backgroundColor: theme.palette.primary.white,
    padding: theme.spacing(3),
    width: '38%',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0.1),
      padding: theme.spacing(2),
    },
  },
  ratingButton: {
    border: '1px solid #233643 ',
    borderRadius: 0,
    height: theme.spacing(4.25),
    width: theme.spacing(4.25),
    marginRight: theme.spacing(1.5),
    outlineWidth: 0,
    backgroundColor: 'white',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3.25),
      width: theme.spacing(3.25),
      marginRight: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(2.9),
      width: theme.spacing(2.9),
      marginRight: theme.spacing(1),
    },
  },
  loader: {
    position: 'absolute',
    top: theme.spacing(20),
    left: theme.spacing(10),
  },
  displayFlex: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  displayButtonFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  ratingText: {
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
  },
  backgroundRed: {
    backgroundColor: '#E43E3D',
    borderColor: '#E43E3D !important',
  },
  backgroundOrange: {
    backgroundColor: '#EF874C',
    borderColor: '#EF874C !important',
  },
  backgroundYellow: {
    backgroundColor: '#F8C43C',
    borderColor: '#F8C43C !important',
  },
  backgroundGreen: {
    backgroundColor: '#5BAF2A',
    borderColor: '#5BAF2A !important',
  },
  borderRed: {
    border: '1px solid #E43E3D',
  },
  borderOrange: {
    border: '1px solid #EF874C',
  },
  borderYellow: {
    border: '1px solid #F8C43C',
  },
  borderGreen: {
    border: '1px solid #5BAF2A',
  },
  poorText: {
    paddingLeft: theme.spacing(0.5),
    color: '#EF212C',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  greatText: {
    paddingLeft: theme.spacing(0.5),
    color: '#3C9C46',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    justifyContent: 'start',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    color: theme.palette.primary.white,
    height: theme.spacing(5),
    borderRadius: 0,
    width: theme.spacing(14.5),
  },
  fontSize: {
    fontSize: theme.spacing(3),
  },
  contentColor: {
    color: '#4B4B4B',
    fontSize: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  cancelButton: {
    color: '#919191',
    marginLeft: theme.spacing(2),
  },
});
class NPSFeedback extends Component {
  constructor(props) {
    super(props);

    this.search = window.location.search;
    this.params = new URLSearchParams(this.search);
    this.permitId = this.params.get('permit_id') !== null ? this.params.get('permit_id') : 'NA';
    this.userEmail = this.params.get('email') !== null ? this.params.get('email') : 'NA';
    this.feedbackMode = this.params.get('feedback_mode') !== null ? this.params.get('feedback_mode') : 0;
    this.ratingValue = this.params.get('rating') !== null ? this.params.get('rating') : -1;
    this.state = {
      clicked: false,
      rating: 0,
      feedback: '',
      feedbackSubmitted: false,
      error: false,
      submit: false,
      ratingObject: [
        {
          colourClass: 'backgroundRed',
          borderClass: 'borderRed',
          text: 'Poor',
          className: '',
        },
        {
          colourClass: 'backgroundRed',
          borderClass: 'borderRed',
          text: '',
          className: '',
        },
        {
          colourClass: 'backgroundRed',
          borderClass: 'borderRed',
          text: '',
          className: '',
        },
        {
          colourClass: 'backgroundOrange',
          borderClass: 'borderOrange',
          text: '',
          className: '',
        },
        {
          colourClass: 'backgroundOrange',
          borderClass: 'borderOrange',
          text: '',
          className: '',
        },
        {
          colourClass: 'backgroundOrange',
          borderClass: 'borderOrange',
          text: '',
          className: '',
        },
        {
          colourClass: 'backgroundOrange',
          borderClass: 'borderOrange',
          text: '',
          className: '',
        },
        {
          colourClass: 'backgroundYellow',
          borderClass: 'borderYellow',
          text: '',
          className: '',
        },
        {
          colourClass: 'backgroundYellow',
          borderClass: 'borderYellow',
          text: '',
          className: '',
        },
        {
          colourClass: 'backgroundGreen',
          borderClass: 'borderGreen',
          text: '',
          className: '',
        },
        {
          colourClass: 'backgroundGreen',
          borderClass: 'borderGreen',
          text: 'Great',
          className: '',
        },
      ],
    };
  }
  componentDidMount() {
    if (
      this.ratingValue !== null &&
      this.ratingValue >= 0 &&
      this.ratingValue <= 10 &&
      !this.state.clicked &&
      !this.state.feedbackSubmitted
    ) {
      this.setState({ rating: this.ratingValue, clicked: true });
      this.changeButtonColours(this.ratingValue);
    }
  }
  componentWillReceiveProps(props) {
    if (this.state.submit && !props.currentlySending) {
      if (props.message !== '') {
        this.setState({ error: true });
        window.setTimeout(() => {
          this.setState({ error: false });
        }, 3000);
      } else {
        this.setState({ error: false, feedbackSubmitted: true });
      }
    }
  }

  handleMouseHover = (index) => {
    if (!this.state.clicked) {
      const { classes } = this.props;
      const tempRatingObject = cloneDeep(this.state.ratingObject);
      tempRatingObject[index].className = classes[this.state.ratingObject[index].colourClass];
      this.setState({ ratingObject: [...tempRatingObject] });
    }
  };

  handleMouseLeave = (index) => {
    if (!this.state.clicked) {
      const tempRatingObject = cloneDeep(this.state.ratingObject);
      tempRatingObject[index].className = '';
      this.setState({ ratingObject: [...tempRatingObject] });
    }
  };

  feedbackChange = (e) => {
    this.setState({ feedback: e.target.value });
  };

  buttonClick = (e, ind) => {
    this.setState({ clicked: true, rating: e.target.value });
    this.changeButtonColours(ind);
  };

  changeButtonColours = (ratingValue) => {
    const { classes } = this.props;
    const tempRatingObject = cloneDeep(this.state.ratingObject);
    for (let ind in this.state.ratingObject) {
      tempRatingObject[ind].className = '';
    }
    for (let i = 0; i <= ratingValue; i++) {
      tempRatingObject[i].className = classes[this.state.ratingObject[ratingValue].colourClass];
    }
    this.setState({ ratingObject: [...tempRatingObject] });
  };

  handleFeedbackSubmit = () => {
    this.props.createEmailNpsFeedback({
      application_id: this.permitId,
      email: this.userEmail,
      feedback_mode: this.feedbackMode,
      rating: this.state.rating,
      comment: this.state.feedback,
    });
    this.setState({ submit: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ height: '100%' }}>
        <AppBar position="static" color="secondary" className={classes.header}>
          <Toolbar className={clsx(classes.headerToolbar, classes.toolbar)}>
            <Avatar variant="square" alt="enphase logo" src={FooterLogo} className={classes.large} />
          </Toolbar>
        </AppBar>
        <Box className={classes.container}>
          {this.props.currentlySending && <CircularProgress className={classes.loader} size={40} />}
          {!this.props.currentlySending && (
            <Box className={classes.card}>
              <Box className={classes.dialogHeader}>
                <PageHeader className={classes.fontSize} text={'Feedback'} />
              </Box>
              {!this.state.feedbackSubmitted ? (
                <div>
                  <Box>
                    <Box className={classes.contentColor}>
                      {`Thank you for rating your experience with permit application service.`}{' '}
                    </Box>
                    <div className={classes.displayFlex}>
                      {this.state.ratingObject.map((ele, idx) => {
                        return (
                          <div key={idx} className={classes.displayButtonFlex}>
                            <button
                              id={idx}
                              onMouseOver={(e) => this.handleMouseHover(idx)}
                              onMouseLeave={(e) => this.handleMouseLeave(idx)}
                              className={clsx(classes.ratingButton, classes[ele.borderClass], ele.className)}
                              value={idx}
                              onClick={(e) => this.buttonClick(e, idx)}
                            >
                              {idx}
                            </button>
                            <Typography
                              className={clsx(
                                classes.ratingText,
                                ele.text === 'Poor' ? classes.poorText : classes.greatText
                              )}
                            >
                              {ele.text}
                            </Typography>
                          </div>
                        );
                      })}
                    </div>

                    <Box className={classes.contentColor}>
                      If you would like to share specific comments please enter them below
                    </Box>

                    <TextField
                      value={this.state.feedback}
                      onChange={(e) => this.feedbackChange(e)}
                      multiline
                      rows={4}
                      rowsMax={16}
                      fullWidth
                      variant="outlined"
                    />
                  </Box>

                  <Box className={classes.buttonWrapper}>
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      className={classes.button}
                      onClick={(e) => this.handleFeedbackSubmit()}
                    >
                      Submit
                    </Button>
                  </Box>
                </div>
              ) : (
                <Box>
                  <Box className={classes.contentColor}>{`Thanks for your feedback.`} </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
        {this.state.error && <SnakBar message={this.props.message} severity="error" />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createEmailNpsFeedback: (payload) => dispatch(createEmailNpsFeedback(payload)),
});

const mapStateToProps = (state) => ({
  message: state.permitReducer.message,
  currentlySending: state.permitReducer.currentlySending,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(NPSFeedback)));
