import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import PanelTemplate from '../../common/PanelTemplate';
import { get, isEqual } from '../../../utils/lodash';
import { isPermitSetAvailable } from '../../../utils/helper';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2.5)
  },
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none'
  },
  panelWrapper: {
    padding: theme.spacing(2.5)
  },
  otherInfo: {
    width: '100%',
    maxWidth: '400px',
    margin: theme.spacing(2, 12, 1, 0),
    '& label': {
      fontSize: 14
    }
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  textWrapper: {
    textDecoration: 'underline',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginTop: theme.spacing(1.5),
    width: 'fit-content'
  },
  imageWrapper: {
    width: theme.spacing(18.75),
    height: theme.spacing(18.75)
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  contactNumber: {
    display: 'inline-flex',
    '& div': {
      margin: '0px 0px 8px 0px',
      paddingTop: '8px'
    }
  },
  wireFromAttic: {
    marginTop: theme.spacing(2)
  }
}));

// FIXME: Need to update after the details are provided
const detailsList = [
  'PV01 - Title Page',
  'PV01 - Title Page',
  'PV01 - Title Page',
  'PV01 - Title Page',
  'PV01 - Title Page',
  'PV01 - Title Page',
  'PV01 - Title Page',
  'PV01 - Title Page',
  'PV01 - Title Page',
  'PV01 - Title Page',
  'PV01 - Title Page',
  'PV01 - Title Page'
];

function OtherInfo(props) {
  const { handleChange, materialDetails, applicationDetail, companyInfo, mostUsedValues } = props;
  const classes = useStyles();
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const panelWrapper = isPermitSetAvailable(applicationDetail) ? clsx(classes.disableForm, classes.panelWrapper) : classes.panelWrapper;
  const handleDetailsDialog = () => {
    setOpenDetailsDialog(prevState => !prevState);
  };

  return (
    <div className={classes.root}>
      <PanelTemplate header={'Additional Information/Requests'} className={classes.panelContainer}>
        <Box className={panelWrapper}>

          <Box>
            <Autocomplete
              className={clsx(classes.otherInfo, classes.contactNumber)}
              id='installer_contact_phone'
              options={mostUsedValues['installer_contact_phone']}
              value={get(materialDetails, 'installer_contact_phone')}
              onChange={(e, value) => handleChange(value, 'installer_contact_phone')}
              renderInput={params => (
                <TextField
                  {...params}
                  className={classes.otherInfo}
                  error={get(materialDetails, 'errors.installer_contact_phone.error')}
                  helperText={get(materialDetails, 'errors.installer_contact_phone.errorMessage')}
                  label={'Contact Phone Number'}
                  onChange={e => handleChange(e.target.value, 'installer_contact_phone')}
                />
              )}
            />
            <FormControl component="div" className={classes.wireFromAttic}>
              <FormLabel component="legend">Will you run a wire through the attic?</FormLabel>
              <RadioGroup
                value={get(materialDetails, 'wire_from_attic', '')}
                row
                onChange={e => handleChange(isEqual(e.target.value, 'true'), 'wire_from_attic')}
              >
                <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
          <TextField
            onChange={e => handleChange(e.target.value, 'additional_info')}
            className={classes.otherInfo}
            label={'Comments'}
            value={get(materialDetails, 'additional_info', '')}
          />
        </Box>
      </PanelTemplate>
    </div>
  );
}

export default OtherInfo;
