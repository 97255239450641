import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Grid, Box } from '@material-ui/core';
import { get } from 'lodash';
import clsx from 'clsx';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { handlePopupBlocker } from '../../../../utils/helper';
import { getSource } from '../../../../containers/Admin/RequestDetails/helper';
import { SOURCE } from '../../../../utils/constants';
import { INSTALLER_DETAILS } from '../constants';
import { DisplayGrid } from '../../../common/DisplayGrid';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    fontSize: '14px'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px',
    height: '40px',
    cursor: 'unset'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray'
  },
  dataSubHeader: {
    fontSize: '12px',
    color: 'grey'
  },
  imageWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between'
    }
  },
  iconHolder: {
    display: 'inline-flex',
    marginRight: theme.spacing(1),
    flexDirection: 'column',
    width: '123px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0.5, 1)
    }
  },
  imgHolder: {
    zIndex: '1',
    margin: theme.spacing(1)
  },

  image: {
    margin: 'auto',
    display: 'block',
    maxWidth: theme.spacing(15),
    maxHeight: theme.spacing(10)
  },
  downloadIcon: {
    left: '86%',
    position: 'absolute'
  }
});

export class InstallerDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {
      classes,
      appDetails,
      handleUpdated,
      stateApplicationDetails,
      originalApplicationDetails,
      applicationDetail
    } = this.props;

    return (
      <Fragment>
        <Grid container direction="row" spacing={2}>
          {INSTALLER_DETAILS.map((Item, index) => {
            return (
              <DisplayGrid
                appDetails={appDetails}
                applicationDetail={applicationDetail}
                item={Item}
                handleUpdated={handleUpdated}
                stateApplicationDetails={stateApplicationDetails}
                originalApplicationDetails={originalApplicationDetails}
              />
            );
          })}
        </Grid>
        <br />
      </Fragment>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InstallerDetailsView));
