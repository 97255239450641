import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useState, Fragment } from 'react';
import { Box, Typography, Button, Popover, IconButton, ClickAwayListener, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Icons, {
  existingOpenSubpanelPhoto,
  mspWithDeadFrontPhoto,
  utilityMeterPhoto,
  mspWithoutDeadFront,
  mspPanelStickerPhoto,
  subPanelStickerPhoto,
} from '../images';

const useStyles = makeStyles((theme) => ({
  uploadPhotoInfoIcon: {
    display: 'flex',
  },
  UploadPhotoSection: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  photoHeading: {
    display: 'flex',
    fontSize: 14,
  },
  requiredText: {
    color: 'red',
  },
  tooltipStyle: {
    maxWidth: 600,
  },
  addNew: {
    fontSize: 14,
    color: '#008EEF',
    display: 'inline-block',
    cursor: 'pointer',
    marginTop: theme.spacing(2),
  },
  displayFlex: {
    display: 'flex',
  },
  imagePhotoBorder: {
    marginTop: theme.spacing(1),
  },
  imgBoxDescription: {
    textAlign: 'center',
  },
  imgBoxBorder: {
    borderColor: 'lightgrey',
    border: '1px solid',
  },
  imgIcon: {
    padding: theme.spacing(4),
  },
  imageCloseIcon: {
    fontSize: 14,
  },
  imageFileName: {
    marginTop: theme.spacing(0.5),
  },
  photoErrorText: {
    fontSize: 12,
    color: 'red',
  },
  infoIcon: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    color: 'grey',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const PhotoUploadSection = (props) => {
  const classes = useStyles();
  const {
    content,
    canUpload,
    id,
    keyValue,
    photos,
    error,
    errorText,
    handleFileDialogOpen,
    deletePhotos,
    isRequired,
    getS3URL,
  } = props;

  const [openTooltip, setOpenTooltip] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const getPhotosSource = (key) => {
    switch (key) {
      case 'existing_open_subpanel':
        return existingOpenSubpanelPhoto;
      case 'subpanel_sticker':
        return subPanelStickerPhoto;
      case 'utility_meter':
        return utilityMeterPhoto;
      case 'service_panel_without_dead_front':
        return mspWithoutDeadFront;
      case 'service_panel_sticker':
        return mspPanelStickerPhoto;
      case 'msp_type':
        return mspWithDeadFrontPhoto;
    }
  };

  const getDialogTitle = (key) => {
    switch (key) {
      case 'existing_open_subpanel':
        return 'Existing Open Subpanel Reference Photo';
      case 'subpanel_sticker':
        return 'Subpanel Sticker Reference Photo';
      case 'utility_meter':
        return 'Utility Meter Reference Photo';
      case 'service_panel_without_dead_front':
        return 'Main Service Panel Without Dead Front Reference Photo';
      case 'service_panel_sticker':
        return 'Main Service Panel Sticker Reference Photo';
      case 'msp_type':
        return 'Main Service Panel With Dead Front Reference Photo';
    }
  };

  const defaultMessage = 'To download files, please disable popup blocker in your browser';

  const handlePopupBlocker = (windowObj, message = defaultMessage) => {
    if (windowObj == null || typeof windowObj == 'undefined') alert(message);
    else windowObj.focus();
  };

  const downloadPhoto = (photo) => {
    getS3URL({
      fileName: photo.file_name,
      methodType: 'GET',
      successCb: (preSignedS3Url) => {
        const myWindow = window.open(preSignedS3Url, '_blank');
        handlePopupBlocker(myWindow);
      },
      failureCb: () => {
        return false;
      },
    });
  };

  const fileIcon = (fName) => {
    let fType = 'document';
    if (fName && fName.includes('.')) {
      let fTypeArray = fName.split('.');
      fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
      if (fType === 'jpg') fType = 'jpeg';
      if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
        fType = 'document';
      }
    }
    const Icon = Icons[fType];
    return <Icon />;
  };

  return (
    <Box className={classes.uploadPhotoInfoIcon}>
      <Box className={classes.UploadPhotoSection}>
        <Typography className={classes.photoHeading}>
          {content}
          {isRequired ? <Box className={classes.requiredText}>*</Box> : ''}
        </Typography>
        {canUpload && (
          <Typography id={id} component="div" onClick={() => handleFileDialogOpen(keyValue)} className={classes.addNew}>
            + Add Image
          </Typography>
        )}
        <Box className={clsx(classes.displayFlex, classes.imagePhotoBorder)}>
          {photos.map((photo, i) => {
            return (
              <Box key={`${keyValue}-${i}`}>
                {photo.loading && <CircularProgress size={25} />}
                {!photo.loading && (
                  <Box className={classes.imgBoxDescription}>
                    <Box className={classes.imgBoxBorder}>
                      <Box className={classes.displayFlex}>
                        <Box className={classes.imgIcon}>
                          <IconButton onClick={() => downloadPhoto(photo)}>{fileIcon(photo.file_name)}</IconButton>
                        </Box>
                        <CloseIcon
                          onClick={() => deletePhotos(photo.ind, photo.tag)}
                          className={classes.imageCloseIcon}
                        />
                      </Box>
                    </Box>
                    <Typography variant="caption" display="block" className={classes.imageFileName}>
                      {photo.original_file_name.length > 15
                        ? photo.original_file_name.substr(0, 15).concat(' ...')
                        : photo.original_file_name}
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        {error && <Typography className={classes.photoErrorText}>{errorText}</Typography>}
      </Box>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            classes={{ tooltip: classes.tooltipStyle }}
            placement="bottom-start"
            onClose={handleTooltipClose}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Fragment>
                <img width={600} height={400} src={getPhotosSource(props.keyValue)} />
              </Fragment>
            }
          >
            <IconButton className={classes.infoIcon}>
              <InfoIcon onClick={handleTooltipOpen} />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </Box>
  );
};

export default PhotoUploadSection;
