import { Grid, makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { getAssignedTo, getLastReviewState } from '../../../../containers/Admin/helper';
import { APPLICATION_STATES, SOURCE } from '../../../../utils/constants';
import ContainedButton from '../../../common/ContainedButton';
import OutlinedButton from '../../../common/OutlinedButton';
import { includes } from '../../../../utils/lodash'
import { ADMINS } from '../../../../containers/Admin/constants';
import { approvalDocExists, getSource } from '../../../../containers/Admin/RequestDetails/helper';

const useStyles = makeStyles((theme) => ({
  adminActions: {
    marginTop: theme.spacing(1),
    justifyContent: 'center'
  },
  link: {
    color: '#F3731F !important',
    cursor: 'pointer',
    paddingTop: theme.spacing(1.5)
  }
}));

const AdminActions = ({
  openFileUploadDialog,
  sDetails,
  permitting_application,
  emailId,
  permitServiceRole
}) => {
  const classes = useStyles();
  const uploadForVerficationIds = [APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id];
  const approveDesignDocIds = [APPLICATION_STATES.QA_STARTED.id];
  const uploadNewDocIds = [...approveDesignDocIds, APPLICATION_STATES.QA_COMPLETED.id, APPLICATION_STATES.PERMIT_FILES_DOWNLOADED.id];
  const assignForCorrectionIds = approveDesignDocIds;
  const stampingSubmitIds = [APPLICATION_STATES.STAMPING_REQUESTED.id,APPLICATION_STATES.STAMPING_PE_REVIEW.id, APPLICATION_STATES.STAMPING_SUBMITTED.id];
  const hideRequestMoreInfoIds = [
    APPLICATION_STATES.NEW_APPLICATION_RECEIVED.id,
    APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.id,
    APPLICATION_STATES.MORE_INFO_REQUESTED.id,
    APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED.id,
    APPLICATION_STATES.QA_ON_HOLD.id,
    APPLICATION_STATES.PERMIT_DRAWINGS_ON_HOLD.id
  ];

  const lastReviewState = getLastReviewState(permitting_application?.third_party_statuses);
  if (lastReviewState && (lastReviewState.state_id === APPLICATION_STATES.STAMPING_REQUESTED.id || lastReviewState.state_id === APPLICATION_STATES.STAMPING_PE_REVIEW.id || lastReviewState.state_id === APPLICATION_STATES.STAMPING_SUBMITTED.id)) {
    stampingSubmitIds.push(APPLICATION_STATES.MORE_INFO_RECEIVED.id);
  }
  
  if (lastReviewState && (lastReviewState.state_id === APPLICATION_STATES.PERMIT_FILES_DOWNLOADED.id)) {
    uploadNewDocIds.push(APPLICATION_STATES.MORE_INFO_RECEIVED.id);
  }
  if (lastReviewState && (lastReviewState.state_id === APPLICATION_STATES.QA_COMPLETED.id)) {
    uploadNewDocIds.push(APPLICATION_STATES.MORE_INFO_RECEIVED.id);
  }

  const handleFileUploadDialog = (title, buttonText, finalStateId, approvalDialog, correctionDialog, stampingDialog, resubmissionDialog, sendForVerificationDialog) => {
    openFileUploadDialog({ title, buttonText, approvalDialog, correctionDialog, stampingDialog, resubmissionDialog, sendForVerificationDialog, finalStateId });
  }
  const currentUser = getAssignedTo(permitting_application)?.email === emailId;
  const isAdmin = includes(ADMINS, permitServiceRole);
  const source = getSource(permitting_application);

  return (
    <Fragment>
      <Grid container direction="row" className={classes.adminActions}>
        {uploadForVerficationIds.includes(sDetails.currentStage) && currentUser && (
          <ContainedButton
            text={`Upload and Send for Verification`}
            handleClick={() =>
              handleFileUploadDialog(`Upload Document`, `Send for Verification`, APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED.id, false, false, false, false, true)}
            adminActionButtons={true}
          />
        )}
        {approveDesignDocIds.includes(sDetails.currentStage) && currentUser && source === SOURCE.ENLIGHTEN_PERMIT && approvalDocExists(permitting_application) && (
          <ContainedButton
            text={`Approve Designer Document and Send to Installer`}
            handleClick={() =>
              handleFileUploadDialog(`Approve Designer Document`, `Approve and Send to Installer`, APPLICATION_STATES.QA_COMPLETED.id, true)}
            adminActionButtons={true}
          />
        )}
        {uploadNewDocIds.includes(sDetails.currentStage) && (currentUser || isAdmin) && (
          <ContainedButton
            text={`Upload New Document and Send to Installer`}
            handleClick={() => handleFileUploadDialog(`Upload Permit Document`, `Upload and Send to Installer`, APPLICATION_STATES.QA_COMPLETED.id)}
            adminActionButtons={true}
          />
        )}
        {stampingSubmitIds.includes(sDetails.currentStage) && currentUser && (
          <ContainedButton
            text={`Upload Stamped Package and send to Installer`}
            adminActionButtons={true}
            handleClick={() => handleFileUploadDialog(`Upload Stamped Package and send to Installer`, `Upload and Send to Installer`, APPLICATION_STATES.STAMPING_SUBMITTED.id, false, false, true)}
          />
        )}
        {!hideRequestMoreInfoIds.includes(sDetails.currentStage) && isAdmin && (
          <OutlinedButton
            text={`Request More Information from Installer`}
            handleClick={() => handleFileUploadDialog(`Request More Information from Installer`, `Request More Information`, APPLICATION_STATES.MORE_INFO_REQUESTED.id, false, false, false, true)}
            adminActionButtons={true}
          />
        )}
        {assignForCorrectionIds.includes(sDetails.currentStage) && currentUser && (
          <OutlinedButton
            text={`Assign Back for Correction`}
            adminActionButtons={true}
            handleClick={() => handleFileUploadDialog(`Assign Application Back for Correction`, `Assign Back for Correction`, APPLICATION_STATES.QA_ON_HOLD.id, false, true)}
          />
        )}
      </Grid>
    </Fragment>
  );
}

export default AdminActions;
