import React, { createRef, useState, useLayoutEffect, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  CircularProgress,
  Divider,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  List
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ContainedButton from '../../../common/ContainedButton';
import { clone, isEmpty } from 'lodash';
import { FILE_UPLOAD_SIZE_LIMIT } from '../../../../containers/Admin/constants';
import { getIconPath } from '../../../../containers/Admin/helper';
import { DOC_TYPE } from '../../../../containers/Admin/RequestDetails/constants';
import { SystemUpdateAlt } from '@material-ui/icons';
import { downloadFile, getFile } from '../../../../utils/helper';
import { MESSAGE_TYPES } from '../../../../utils/constants';
import moment from 'moment';
import PageHeader from '../../../common/PageHeader';
import Icons from '../../../../containers/Permitting/Chats/images';

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.primary.dark
  },
  close: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    opacity: 0.23,
    cursor: 'pointer'
  },
  content: {
    border: '1px solid #7070704D',
    margin: theme.spacing(0, 2.5, 2.5, 2.5),
    padding: theme.spacing(2)
  },
  dragArea: {
    margin: theme.spacing(0, 0, 2.5, 0),
    backgroundColor: theme.palette.primary.lightGray,
    width: theme.spacing(65.375),
    height: theme.spacing(30),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      height: 'auto',
    },
  },
  input: {
    visibility: 'hidden',
    height: 0
  },
  err: {
    color: 'red'
  },
  selectedFileWrapper: {
    maxWidth: theme.spacing(40),
    maxheight: theme.spacing(15),
  },
  commentText: {
    fontSize: theme.spacing(1.75),
    marginBottom: theme.spacing(1.5),
  },
  commentField: {
    marginBottom: theme.spacing(1.5),
  },
  resubmissionComment: {
    fontFamily: 'inherit'
  },
  removeFile: {
    cursor: 'pointer',
    opacity: 0.4,
    position: 'relative',
    top: theme.spacing(0.8),
    left: theme.spacing(0.8),
    fontSize: theme.spacing(2.25)
  },
  displayFlex: {
    display: 'flex'
  },
  fileName: {
    fontSize: theme.spacing(1.75),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  subHeader: {
    color: theme.palette.primary.bodyHeader,
    fontWeight: 'bold'
  },
  fileIcon: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(0),
    '& svg': {
      height: '100%'
    }
  }
}));

let filesData = [];
const CorrectionDialog =({
  fireUpload,
  title,
  buttonText,
  open,
  setOpen,
  acceptableFileFormat,
  appDetails,
  fileUploadInProgress,
  getPresingedURL,
  messageList
}) => {
  const classes = useStyles();

  const fileRef = createRef();
  const dropRef = createRef();
  const [ filesName, setFilesName ] = useState([]);
  const [ comment, setComment ] = useState('');
  const [ errMsg, setErrMsg ] = useState('');
  const [ fileSelected, setFileSelected ] = useState(false);
  const [ commentRequired, setCommentRequired ] = useState(false);
  const [ resubmissionDocs, setResubmissionDocs ] = useState([]);
  const [ fileDownloadInProgress, setFileDownloadInProgress ] = useState(false);
  const [ resubmissionComment, setResubmissioComment ] = useState('');

  useEffect(() => {
    setResubmissionDocs(appDetails?.admin_documents && appDetails?.admin_documents.filter(doc => doc.doc_type === DOC_TYPE.RESUBMISSION_DOC));
  }, [ appDetails ]);

  useEffect(() => {
    if (!isEmpty(messageList)) {
      for (let i = 0 ; i < messageList.length; i++) {
        if (messageList[i].message_type === MESSAGE_TYPES.RESUBMISSION_REQUEST) {
          setResubmissioComment(messageList[i].body_content);
        }
      }
    }
  }, [ messageList ]);
  
  

  const selectFile = event => {
    if (event.target.files) {
      setFileSelected(true);
      filesData = filesData.concat(Array.from(event.target.files));
      const names = [];
      filesData.forEach(fileData => {
        names.push(fileData.name);
      })
      setFilesName(names);
    }
  };
  const addFile = () => {
    if ((!filesData || filesData.length === 0) && isEmpty(comment)) {
      setCommentRequired(true);
      return;
    }
    let fileSize = 0;
    filesData && filesData.forEach(fileData => {
      fileSize = fileSize + fileData.size / 1024 / 1024;
    });
    if (fileSize > FILE_UPLOAD_SIZE_LIMIT) {
      setErrMsg(`File size is greater that ${FILE_UPLOAD_SIZE_LIMIT}MB...`);
      return;
    }
    setCommentRequired(false);
    fireUpload(filesData, comment);
  };

  useLayoutEffect(
    () => {
      setTimeout(() => {
        const div = dropRef.current;
        if (div) {
          if (open) {
            div.addEventListener('dragenter', handleDrag);
            div.addEventListener('dragleave', handleDrag);
            div.addEventListener('dragover', handleDrag);
            div.addEventListener('drop', handleDrop);
          } else {
            div.removeEventListener('dragenter', handleDrag);
            div.removeEventListener('dragleave', handleDrag);
            div.removeEventListener('dragover', handleDrag);
            div.removeEventListener('drop', handleDrop);
          }
        }
      }, 1);
    },
    [ dropRef, open ]
  );

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      filesData = filesData.concat(Array.from(e.dataTransfer.files));
      const names = [];
      filesData.forEach(fileData => {
        names.push(fileData.name);
      })
      setFilesName(names);
    }
  };

  const close = () => {
    setErrMsg('');
    setFilesName([]);
    setComment('');
    filesData = [];
    setOpen(false);
    setFileSelected(false);
  };

  const removeFile = (ind) => {
    filesData.splice(ind, 1);
    const names = clone(filesName);
    names.splice(ind, 1);
    setFilesName(names);
    // reset the value of the fileRef so that same file can be re uploaded
    fileRef.current.value = '';
    if (filesData.length === 0) {
      setFileSelected(false);
    }
  };

  const handleCommentsChange = (e) => {
    setComment(e.target.value);
    setCommentRequired(false);
  };

  const downloadFiles = async (doc) => {
    const aDocs = clone(resubmissionDocs);
    let idxOfDownloadingDoc = 0;
    for (let i = 0; i < aDocs.length; i++) {
      if (aDocs[i].file_name === doc.file_name) {
        aDocs[i].downloadInProgress = true;
        idxOfDownloadingDoc = i;
      }
    }
    setResubmissionDocs(aDocs);
    setFileDownloadInProgress(true);
    const fName = doc.file_name;
    const fUrl = await getFile(fName, getPresingedURL);
    window.open(fUrl, '_blank');
    aDocs[idxOfDownloadingDoc].downloadInProgress = false;
    aDocs[idxOfDownloadingDoc].signedUrl = fUrl;
    setResubmissionDocs(aDocs);
    setFileDownloadInProgress(false);
  }

  const fileIcon = fName => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
      fType = 'document';
    }
    const Icon = Icons[fType];
    return <Icon />;
  };

  const inputProps = { accept: acceptableFileFormat };
  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableBackdropClick
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="h4" className={classes.header}>
          <PageHeader text={title} />
          <CloseIcon onClick={close} className={classes.close} size="large" />
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.subHeader} variant="body1">Enphase Comment</Typography>
        <Typography variant="subtitle2"><pre className={classes.resubmissionComment}>{resubmissionComment}</pre></Typography><br />
        {resubmissionDocs && resubmissionDocs.length > 0 && <Fragment><Typography className={classes.subHeader} variant="body1">Uploaded Files</Typography><br /></Fragment>}
        <List>
          {resubmissionDocs && resubmissionDocs.map((doc, idx) => {
            return (
              <ListItem key={idx} title={doc.file_name} className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar src={getIconPath(doc)} variant="square" />
                </ListItemAvatar>
                <ListItemText
                  primary={doc.file_name}
                  secondary={doc.added_date && `Uploaded on ${moment(doc.added_date).format('DD MMM YYYY')}`}
                  classes={{ primary: classes.fileName }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => downloadFiles(doc)}
                    disabled={doc.downloadInProgress || fileDownloadInProgress}
                    className={classes.fileIcon}
                  >
                    {doc.downloadInProgress === true ? (
                      <CircularProgress size={30} />
                    ) : doc.signedUrl ? (
                      fileIcon(doc.file_name)
                    ) : (
                      <SystemUpdateAlt className={classes.fileButton} />
                    )}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogContent className={classes.content}>
        <Typography className={classes.subHeader} variant="body1">Upload Files</Typography><br />
        <Box
          className={classes.dragArea}
          onDrop={handleDrop}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
        >
          <ContainedButton disabled={fileUploadInProgress} text="Select Files" handleClick={() => fileRef.current?.click()} />
          <input disabled={fileUploadInProgress} type="file" onChange={selectFile} ref={fileRef} className={classes.input} {...inputProps} multiple /><br />
          <Box className={classes.selectedFilesWrapper}>
            {filesName && filesName.map((fileName, ind) => {
              return (
                <Box key={`file-admin-${ind}`} className={classes.selectedFileWrapper}>
                  <Typography color="textSecondary" variant="caption" gutterBottom>
                    {fileName}
                    <CloseIcon onClick={() => removeFile(ind)} className={classes.removeFile} />
                  </Typography>
                </Box>
              )
            })}
          </Box>
          {!fileSelected && errMsg && (
            <Typography className={classes.err} gutterBottom>
              {errMsg}
            </Typography>
          )}
          {fileUploadInProgress && <CircularProgress size={20} />}
          <Typography gutterBottom>or drop file here to upload</Typography>
          <Typography color="textSecondary" variant="caption">
            Maximum upload file size :{FILE_UPLOAD_SIZE_LIMIT}MB
          </Typography>
        </Box>
        <Box>
          <Divider /><br />
          <Typography className={classes.subHeader} variant="body1">
            {`If you would like to share specific comments please enter them below`}
          </Typography><br />
          <TextField
            value={comment}
            onChange={(e) => handleCommentsChange(e)}
            multiline
            minRows={4}
            maxRows={16}
            fullWidth
            variant="outlined"
            className={classes.commentField}
            placeholder="Type your comments..."
            error={commentRequired}
            helperText={commentRequired && 'At least one document or comment is mandatory'}
          />
        </Box>
        <Box className={classes.displayFlex}>
          <ContainedButton
            text={buttonText}
            handleClick={addFile}
            disabled={fileUploadInProgress}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CorrectionDialog;