import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { preventDefaultDecimal } from '../../../../../containers/Admin/helper';
import { Label } from '../../../../common/Label';
import CustomPanel from '../../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../../common/SingleSelectDropdown';
import { GARAGE_FLOOR_PLAN_OPTIONS, SEPARATE_BATTERY_DISCONNECT_OPTIONS, YES_NO_OPTIONS } from '../constants';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
  selectLabel: {
    whiteSpace: 'normal',
    transform: 'translate(0, 1px) scale(1)',
  },
}));

const StorageSection = (props) => {
  const classes = useStyles();
  const {
    getErrorText,
    getHelperText,
    handleStorageDesignRequirements,
    storageDesignRequirements,
    sectionLabel,
    readOnly,
    stateSelected,
    errorData,
  } = props;
  const {
    storage_size_limit = '',
    separate_battery_disconnect_required = '',
    battery_specific_placards_required = '',
    battery_mounting_required = '',
    garage_floor_plan_required = '',
    gas_meter_required = '',
    battery_placement_restrictions = '',
    additional_requirements_storage = '',
  } = storageDesignRequirements;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id="storage_size_limit"
                type="number"
                className={classes.inputField}
                label={<Label text={'Storage size limit (kWh)'} capitalizeLabelText={false} />}
                value={storage_size_limit ?? ''}
                onChange={(e) => handleStorageDesignRequirements(e, 'storage_size_limit')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefaultDecimal(e)}
                error={!isEmpty(get(errorData, 'storage_size_limit'))}
                helperText={get(errorData, 'storage_size_limit')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Separate battery disconnect required'} capitalizeLabelText={false} />}
                dropDownOptions={SEPARATE_BATTERY_DISCONNECT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleStorageDesignRequirements}
                fieldId="separate_battery_disconnect_required"
                fieldValue={separate_battery_disconnect_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Battery specific placards required'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleStorageDesignRequirements}
                fieldId="battery_specific_placards_required"
                fieldValue={battery_specific_placards_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Battery mounting required'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleStorageDesignRequirements}
                fieldId="battery_mounting_required"
                fieldValue={battery_mounting_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Garage floor plan required'} capitalizeLabelText={false} />}
                dropDownOptions={GARAGE_FLOOR_PLAN_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleStorageDesignRequirements}
                fieldId="garage_floor_plan_required"
                fieldValue={garage_floor_plan_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Gas meter required'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleStorageDesignRequirements}
                fieldId="gas_meter_required"
                fieldValue={gas_meter_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Battery placement restrictions'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleStorageDesignRequirements}
                fieldId="battery_placement_restrictions"
                fieldValue={battery_placement_restrictions ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Additional requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={additional_requirements_storage ?? ''}
                onChange={(e) => handleStorageDesignRequirements(e, 'additional_requirements_storage')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default StorageSection;
