import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { AddCircleOutline } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Box,
  Divider,
  Typography,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  Tooltip,
  Grid,
  DialogContentText,
  Button,
  InputBase,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEqual, get, set, isEmpty } from '../../../../utils/lodash';
import { Label } from '../../../../components/common/Label';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  dialogWidth: {
    minWidth: '40%',
    maxWidth: '40%',
  },
  dialogContent: {
    padding: 'unset',
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%',
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    padding: 0,
  },
  displayFlexButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  leftPaddingDialog: {
    paddingLeft: theme.spacing(4),
  },
  rightPadding: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0.5),
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3, 2),
  },
  cancelButton: {
    borderColor: 'black',
  },
  saveButton: {
    color: 'white',
    boxShadow: 'none',
  },
}));

const DeleteStructuralAssembly = (props) => {
  const { id, isDelete, deleteButtonClicked, handleIsDelete } = props;

  const classes = useStyles();

  return (
    <Box className={classes.subRoot}>
      <Dialog
        open
        onClose={handleIsDelete}
        aria-labelledby="customized-dialog-title"
        classes={{ paper: classes.dialogWidth }}
        fullWidth
        maxWidth="xs"
        disableEscapeKeyDown
      >
        <DialogTitle id="customized-dialog-title">
          <Box className={classes.dialogTitle}>
            <b>{'Delete Assembly'}</b>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth>
            <br />
            <Typography className={classes.leftPaddingDialog}>Do you want to delete this assembly ?</Typography>
            <br />
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleIsDelete} variant="outlined" color="default" className={classes.cancelButton}>
            {'No'}
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            className={classes.saveButton}
            onClick={deleteButtonClicked}
          >
            {'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteStructuralAssembly;
