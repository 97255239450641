import React from 'react';
import * as ReactDOM from 'react-dom';
import { PanViewer } from 'react-image-pan-zoom-rotate';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CachedIcon from '@material-ui/icons/Cached';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  panViewer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imagePan: {
    transform: ({ rotation, flip }) => `rotate(${rotation * 90}deg) scaleX(${flip ? -1 : 1})`,
    width: '100%',
  },
  zoomIcons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  zoomInZoomOutIcon: {
    display: 'flex',
    backgroundColor: '#F0F0F0',
    border: '2px solid #F0F0F0',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.7),
  },
  zoomIconsDivider: {
    borderLeft: '1px solid #DCDCDC',
    margin: theme.spacing(0.5),
  },
  rotateIcon: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#F0F0F0',
    border: '2px solid #F0F0F0',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.7),
  },
}));

export const ReactPanZoom = (props) => {
  const { image, alt } = props;
  const [dx, setDx] = React.useState(0);
  const [dy, setDy] = React.useState(0);
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);
  const [flip, setFlip] = React.useState(false);
  const classes = useStyles({ rotation: rotation, flip: flip });

  const resetAll = () => {
    setDx(0);
    setDy(0);
    setZoom(1);
    setRotation(0);
    setFlip(false);
  };
  const zoomIn = () => {
    setZoom(zoom + 0.2);
  };

  const zoomOut = () => {
    console.log(' zoom = ' + zoom);
    if (zoom >= 1) {
      setZoom(zoom - 0.2);
    }
  };

  const rotateLeft = () => {
    if (rotation === -3) {
      setRotation(0);
    } else {
      setRotation(rotation - 1);
    }
  };

  const rotateRight = () => {
    if (rotation === 3) {
      setRotation(0);
    } else {
      setRotation(rotation + 1);
    }
  };

  const flipImage = () => {
    setFlip(!flip);
  };

  const onPan = (dx, dy) => {
    setDx(Number(dx));
    setDy(Number(dy));
  };

  return (
    <Box>
      <Box className={classes.container}>
        <PanViewer
          className={classes.panViewer}
          zoom={zoom}
          setZoom={setZoom}
          pandx={dx}
          pandy={dy}
          onPan={onPan}
          rotation={rotation}
          key={dx}
        >
          <img className={classes.imagePan} src={image} alt={alt} ref={props.ref} />
        </PanViewer>
      </Box>
      <Box className={classes.zoomIcons}>
        <Box className={classes.zoomInZoomOutIcon}>
          <ZoomInIcon onClick={zoomIn} />
          <Box className={classes.zoomIconsDivider} />
          <ZoomOutIcon onClick={zoomOut} />
        </Box>
        <Box onClick={rotateLeft}>
          <Box className={classes.rotateIcon}>
            <CachedIcon />
          </Box>
        </Box>
        <Box onClick={rotateRight} />
        <Box onClick={flipImage} />
        <Box onClick={resetAll} id={'resetPanImage'}></Box>
      </Box>
    </Box>
  );
};
