import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CommentIcon from '@material-ui/icons/Comment';
import { Box, Grid, IconButton, Fade, Paper, Popper, Typography, Divider, Tooltip } from '@material-ui/core';
import { getStatus } from '../../../../utils/helper';

import moment from 'moment';
import React from 'react';
import { isEmpty, includes } from '../../../../utils/lodash';

const styles = theme => ({
  boxStyle: {
    textAlign: 'right',
    padding: theme.spacing(1.25, 0, 0, 0)
  },
  stylePopup: {
    width: theme.spacing(50),
    maxWidth: theme.spacing(50),
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.spacing(38)
    },
    height: theme.spacing(33.125),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.tertiary.lighter,
    borderRadius: '5px'
  },
  headStyle: {
    fontSize: '1em !important',
    fontWeight: '700 !important',
    color: '#505050',
    display: 'inline-flex',
    padding: theme.spacing(1.2, 0, 0, 0.9),
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  popupBody: {
    backgroundColor: theme.palette.tertiary.main,
    height: theme.spacing(27.5),
    width: '97%',
    overflow: 'auto',
    margin: theme.spacing('auto', 'auto'),
    borderRadius: '5px'
  },
  commentBlock: {
    marginTop: theme.spacing(0.5)
  },
  commentName: {
    display: 'inline-block',
    float: 'left',
    margin: theme.spacing(0.7, 1.8, 1, 1.8),
    border: '1px solid',
    borderRadius: '50%',
    borderColor: '#808080',
    textAlign: 'center',
    width: theme.spacing(3.75),
    height: theme.spacing(2.875),
    fontWeight: '600',
    fontSize: '1.1em',
    color: '#4f4f4f',
    paddingTop: theme.spacing(0.75)
  },
  commentBody: {
    width: '82%',
    [theme.breakpoints.down('xs')]: {
      width: '75%'
    },
    display: 'inline-block'
  },
  h5: {
    color: '#9e9e9e',
    fontWeight: '300',
    fontSize: '0.7em',
    margin: theme.spacing(0.625, 0, 1.25, 0)
  },
  bar: {
    margin: theme.spacing(0, 0.875, 0, 0.875),
    display: 'inline-block',
    fontSize: '1.2em'
  },
  h3: {
    fontWeight: '500',
    fontSize: '1em',
    margin: theme.spacing(1.5, 0, 1.25, 0),
    color: '#636363'
  },
  containerDiv: {
    align: 'right'
  },
  popupCloseBtn: {
    color: '#335977',
    align: 'right',
    float: 'right',
    minWidth: theme.spacing(2.5)
  },
  popupCloseIcon: {
    fontSize: '1em',
    color: '#4f4f4f',
    height: theme.spacing(1.8),
    width: theme.spacing(1.8)
  },
  commentBtnName: {
    fontSize: '10',
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    color: '#4f4f4f'
  },
  recordStatus: {
    fontSize: '20',
    padding: theme.spacing(0),
    margin: theme.spacing(1.5),
    display: 'inline-block',
    color: '#4f4f4f',
    fontWeight: '500'
  },
  recordStatusBold: {
    fontWeight: 'bold',
    color: 'black'
  },
  dividerSlash: {
    display: 'inline-block',
    color: 'darkgrey',
    fontSize: '20'
  },
  commentBtn: {
    borderRadius: '5%',
    transition: 'none !important',
    '&:hover': {
      borderRadius: '5%'
    }
  },
  commentIcon: {
    padding: theme.spacing(0.375, 0, 0, 1),
    fontSize: 'large',
    color: theme.palette.primary.main
  }
});

const CommentPopupBox = props => {
  const { comments, applicationStatus, classes } = props;
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ open, setOpen ] = React.useState(false);
  const [ placement, setPlacement ] = React.useState();

  const handleClick = newPlacement => event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUsernameInitials = name => {
    let fullName = name;
    if (!isEmpty(name)) {
      name = name.split(' ');
      const len = name.length;
      if (len > 1) {
        name = name[0][0] + name[len - 1][0];
      } else {
        name = name[0][0];
      }
    } else {
      name = 'NA';
      fullName = name;
    }
    return [name, fullName];
  };

  return (
    <Box className={classes.boxStyle}>
      <Popper open={open} onClose={handleClose} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Box className={classes.stylePopup}>
                <Box>
                  <Typography className={classes.headStyle} component={'span'}>
                    Comment History
                  </Typography>
                  <IconButton onClick={handleClose} className={classes.popupCloseBtn}>
                    <CloseIcon className={classes.popupCloseIcon} />
                  </IconButton>
                </Box>

                <Box className={classes.popupBody}>
                  {!isEmpty(comments) &&
                    comments.map((comment, index) => {
                      const [nameInitials, name] = getUsernameInitials(comment.user_name);
                      return (
                        <Box key={'comment' + index} className={classes.commentBlock}>
                          <Tooltip title={name} placement="top">
                            <Box className={classes.commentName}>{nameInitials}</Box>
                          </Tooltip>
                          <Box className={classes.commentBody}>
                            <Grid className={classes.h5}>
                              {moment(comment.created_at).format('DD, MMM YYYY  HH:mm')}
                              <Grid component={'span'} className={classes.bar}>
                                |
                              </Grid>
                              {getStatus(comment, 'event')}
                            </Grid>
                            <Grid className={classes.h3}>{comment.comments}</Grid>
                          </Box>
                          <Divider />
                        </Box>
                      );
                    })}
                  {isEmpty(comments) && <Box className={classes.h3}>No comments yet.</Box>}
                </Box>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>

      {!(includes([null, '', 'INCOMPLETE'], applicationStatus)) && (<Box className={classes.containerDiv}>
        <Typography className={classes.recordStatus}>Status: <span className={classes.recordStatusBold}>{getStatus(props, 'applicationStatus')}</span></Typography>
        <Typography className={classes.dividerSlash}>|</Typography>
        <IconButton className={classes.commentBtn} onClick={handleClick('bottom-end')}>
          <Typography className={classes.commentBtnName}>Comment History</Typography>
          <CommentIcon className={classes.commentIcon} />
        </IconButton>
      </Box>)}
    </Box>
  );
};

export default withStyles(styles)(CommentPopupBox);
