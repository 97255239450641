import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { preventDefault, getFileMetaData } from '../../../../containers/Admin/helper';
import history from '../../../../routes/history';
import { withStyles } from '@material-ui/core/styles';
import SnakBar from '../../../SnakBar';
import PageHeader from '../../../common/PageHeader';
import Icons from '../../../../containers/Permitting/Chats/images';
import uploadFileDetails from '../../../../utils/uploadFileDetails';
import { Box, Button, Grid, TextField, Typography, makeStyles, IconButton, Switch, Divider } from '@material-ui/core';
import DeleteIcon from '../../../../containers/Permitting/TemplateDialog/DeleteTemplateIcon';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty, get, keysIn, hasIn, omit, isEqual, includes, inRange } from '../../../../utils/lodash';
import CustomPanel from '../../../common/PanelTemplate';
import { getSGMaterials } from '../../../../containers/Permitting/action';
import { Label } from '../../../common/Label';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { theme } from 'highcharts';
import SolarAndBatteryImage from './SolarAndBatteryImage';
import BatteryOnlyImage from './BatteryOnlyImage';
import GridTiedBatteryImage from './GridTiedBatteryImage';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backdrop: {
    zIndex: 11,
    color: theme.palette.primary.main,
  },
  btnStyle: { marginLeft: theme.spacing(0.5), color: 'white' },
  subRoot: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 2, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(2, 0, 1, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      // marginTop: theme.spacing(1),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
    '& .MuiInputBase-root.Mui-disabled:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  addBatteryConfigurationButton: {
    width: '152px',
    // marginRight: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    height: '40px',
    border: '1px solid #F37320',
    borderRadius: '2px',
    opacity: 1,
    color: '#F37320',
    // marginBottom: '6px',
    textTransform: 'none',
    background: '#FFFFFF',
    fontWeight: 600,
    '&:hover': { background: '#FFFFFF' },
    minWidth: 'max-content',
    maxWidth: 'max-content',
    '&.Mui-disabled': {
      background: '#eaeaea',
      color: '#FFFFFF',
      border: '1px solid #FFFFFF',
    },
  },
  uploadDataField: {
    width: '44%',
    margin: theme.spacing(5, 1, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  uploadDataLabel: {
    margin: theme.spacing(1, 0),
  },
  filesListWrapper: {
    border: `1px solid #bbb`,
    borderRadius: theme.spacing(0.5),
    maxHeight: theme.spacing(10),
    // marginRight: theme.spacing(10),
  },
  fileAttachment: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75),
  },
  fileNameIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto',
  },
  iconCoverChat: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
    '& .MuiIconButton-label': {
      height: '100%',
      '& svg': {
        height: '100%',
      },
    },
  },
  popupCloseBtn: {
    color: '#335977',
    align: 'right',
    float: 'right',
    minWidth: theme.spacing(2.5),
  },
  errorText: {
    fontSize: theme.spacing(1.75),
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0.3),
  },
  hidden: {
    display: 'none',
  },
  container: {
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
}));

const BatteryConfiguration = (props) => {
  const classes = useStyles();
  const {
    manufacturer,
    battery_group,
    configuration_name,
    batteryBackupAndQuantityList,
    manufacturersList,
    batteryGroupsList,
    handleDetails,
    batteryBackupsList,
    handleAddBatteryButtonClick,
    handleRemoveBattery,
    isBatteryOnlyImageToggleSet,
    handleBatteryOnlyImageToggle,
    isGridTiedBatteryImageToggleSet,
    handleGridTiedBatteryImageToggle,
    indoorImages,
    outdoorImages,
    withoutBackgroundImages,
    fileIcon,
    fileUploadLogs,
    deleteFile,
    errorData,
    handleFilesUpload,
    handleFileDownload,
    edit
  } = props;

  return (
    <Box className={classes.subRoot} key={`battery_configuration`}>
      <CustomPanel header={`Battery Configuration`}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                id="manufacturer"
                className={classes.inputField}
                inputValue={manufacturer}
                disabled={edit}
                options={manufacturersList}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option['name'])}
                disableClearable
                onInputChange={(e, value) => handleDetails(e, 'manufacturer', value)}
                renderInput={(params) => <TextField {...params} label={<Label text={'Manufacturer'} />} />}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                id="battery_group"
                className={classes.inputField}
                inputValue={battery_group}
                options={batteryGroupsList}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option['name'])}
                disableClearable
                disabled={isEmpty(manufacturer) || edit}
                onInputChange={(e, value) => handleDetails(e, 'battery_group', value)}
                renderInput={(params) => <TextField {...params} label={<Label text={'Battery Group'} />} />}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'configuration_name'}
                className={classes.inputField}
                label={<Label text={'Configuration Name'} />}
                value={configuration_name || ''}
                onChange={(e, value) => handleDetails(e, 'configuration_name', value)}
              />
            </Box>
          </Grid>
          <Box className={classes.subRoot}>
            <CustomPanel header={`Battery`}>
              <Grid className={classes.contactDetails}>
                {batteryBackupAndQuantityList.map((batteryBackupAndQuantity) => {
                  return (
                    <Grid className={classes.inputWrapper}>
                      <Box className={classes.addressWrapper} style={{ marginRight: '10%' }}>
                        <Autocomplete
                          id={`battery_backup_${batteryBackupAndQuantity.id}`}
                          // key={`battery_backup_${batteryBackupAndQuantity.id}`}
                          className={classes.inputField}
                          inputValue={batteryBackupAndQuantity['battery_backup']}
                          options={batteryBackupsList}
                          getOptionLabel={(option) => (typeof option === 'string' ? option : option['name'])}
                          disableClearable
                          disabled={isEmpty(battery_group)}
                          onInputChange={(e, value) =>
                            handleDetails(e, 'battery_backup', value, batteryBackupAndQuantity.id)
                          }
                          renderInput={(params) => <TextField {...params} label={<Label text={'Batteries'} />} />}
                        />
                      </Box>
                      <TextField
                        style={{ flexGrow: 1 }}
                        id={`quantity_${batteryBackupAndQuantity.id}`}
                        // key={`quantity_${batteryBackupAndQuantity.id}`}
                        className={classes.inputField}
                        label={<Label text={'Quantity'} />}
                        value={batteryBackupAndQuantity['quantity'] || ''}
                        disabled={isEmpty(battery_group)}
                        error={!isEmpty(get(errorData.quantities, batteryBackupAndQuantity.id))}
                        helperText={get(errorData.quantities, batteryBackupAndQuantity.id)}
                        onChange={(e, value) => handleDetails(e, 'quantity', value, batteryBackupAndQuantity.id)}
                      />
                      {batteryBackupAndQuantity.id > 1 && (
                        <IconButton size="small" onClick={() => handleRemoveBattery(batteryBackupAndQuantity.id)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                  );
                })}
                <Button
                  disableRipple
                  disableElevation
                  className={clsx(classes.addBatteryConfigurationButton)}
                  onClick={handleAddBatteryButtonClick}
                >
                  {' '}
                  + ADD BATTERY{' '}
                </Button>
              </Grid>
            </CustomPanel>
          </Box>
          <Typography className={classes.inputField} style={{ fontWeight: '500', marginBottom: '16px' }}>
            Battery Images
          </Typography>
          <SolarAndBatteryImage
            indoorImages={indoorImages}
            outdoorImages={outdoorImages}
            withoutBackgroundImages={withoutBackgroundImages}
            fileIcon={fileIcon}
            fileUploadLogs={fileUploadLogs}
            deleteFile={deleteFile}
            errorData={errorData}
            handleFilesUpload={handleFilesUpload}
            handleFileDownload={handleFileDownload}
          />
          <BatteryOnlyImage
            indoorImages={indoorImages}
            outdoorImages={outdoorImages}
            withoutBackgroundImages={withoutBackgroundImages}
            fileIcon={fileIcon}
            fileUploadLogs={fileUploadLogs}
            deleteFile={deleteFile}
            errorData={errorData}
            handleFilesUpload={handleFilesUpload}
            isBatteryOnlyImageToggleSet={isBatteryOnlyImageToggleSet}
            handleBatteryOnlyImageToggle={handleBatteryOnlyImageToggle}
            handleFileDownload={handleFileDownload}
          />
          <GridTiedBatteryImage
            handleBatteryOnlyImageToggle={handleBatteryOnlyImageToggle}
            indoorImages={indoorImages}
            outdoorImages={outdoorImages}
            withoutBackgroundImages={withoutBackgroundImages}
            fileIcon={fileIcon}
            fileUploadLogs={fileUploadLogs}
            deleteFile={deleteFile}
            errorData={errorData}
            handleFilesUpload={handleFilesUpload}
            isGridTiedBatteryImageToggleSet={isGridTiedBatteryImageToggleSet}
            handleGridTiedBatteryImageToggle={handleGridTiedBatteryImageToggle}
            handleFileDownload={handleFileDownload}
          />
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default BatteryConfiguration;
