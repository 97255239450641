import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Backdrop, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import PageHeader from '../../../../components/common/PageHeader';
import { toInteger, isEmpty, get, keysIn, hasIn, omit, includes, inRange, isEqual } from '../../../../utils/lodash';
import Icons from '../../../../containers/Permitting/Chats/images';
import history from '../../../../routes/history';
import scrollIntoView from 'scroll-into-view';
import {
  createAHJ,
  getAHJ,
  getAHJCity,
  getAHJNameValidation,
  getAHJGoverningCodes,
  getAHJDefaultFiresetbacks,
  getAHJDefaultAllFiresetbacks,
} from '../../actions';
import SnakBar from '../../../../components/SnakBar';
import { APPLICATION_STATE_TRANSITIONS, REJECT_DIALOG_WARNING, VIEW_ONLY_ROLES } from '../../constants';
import { preventDefault, countDecimals } from '../../helper';
import CommentDialog from '../../../../components/Admin/Automation/CommentDialog';
import CommentPopupBox from '../../../../components/Admin/Automation/Comment';
import BasicDetailsSectionOldUI from '../../../../components/Admin/Automation/BasicDetailsSection/oldUI';
import ReferenceInformationSectionOldUI from '../../../../components/Admin/Automation/ReferenceInformationSection/oldUI';
import NotesSectionOldUI from '../../../../components/Admin/Automation/NotesSection/oldUI';
import PVResidentialPermitDetailsSectionOldUI from '../../../../components/Admin/Automation/PVResidentialPermitDetailsSection/oldUI';
import RequiredPlanSetNotesSectionOldUI from '../../../../components/Admin/Automation/RequiredPlanSetNotesSection/oldUI';
import GoverningCodesSectionOldUI from '../../../../components/Admin/Automation/GoverningCodesSection/oldUI';
import FireSetbacksSectionOldUI from '../../../../components/Admin/Automation/FireSetbacksSection/oldUI';
import WeatherDataSectionOldUI from '../../../../components/Admin/Automation/WeatherDataSection/oldUI';
import PermitOfficeSectionOldUI from '../../../../components/Admin/Automation/PermitOfficeSection/oldUI';
import EVPermitDetailsSectionOldUI from '../../../../components/Admin/Automation/EVPermitDetailsSection/oldUI';
import uploadFileDetails from '../../../../utils/uploadFileDetails';
import { getS3URL, putToS3 } from '../../../Permitting/action';
import RecordDetailsSectionOldUI from '../../../../components/Admin/Automation/Equipment/RecordDetailsSection/oldUI';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
  inputFieldWrapper: {
    width: '37.5%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  multiSelect: {
    whiteSpace: 'normal',
  },
  actionSection: {
    padding: theme.spacing(8, 0, 4, 0),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
    },
  },
  submitButton: {
    marginLeft: theme.spacing(),
  },
  backdrop: {
    zIndex: 11,
    color: theme.palette.primary.main,
  },
  alert: {
    justifyContent: 'center',
    width: 'auto',
    marginTop: theme.spacing(1),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkboxLabel: {
    margin: theme.spacing(4, 0, -1, -0.3),
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
});

class index extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = new Array(3).fill(null);
    this.event = null;
    this.commentDialogHeader = '';
    this.commentDialogSecondaryHeader = '';
    this.commentDialogAddSecondaryHeader = '';
    this.countyList = [];
    this.isComponentDidMountCalled = false;

    this.state = {
      id: '',
      noRequirementsCheck: false,
      evPermitDetailsCheck: false,
      noDataAvailableCheck: false,
      displayOtherCodesCheck: false,
      displayNationalElectricalCodeCheck: false,
      isAHJTownship: false,
      isAHJNameUnique: true,
      showCityCheckbox: false,
      countyRequired: false,
      basicDetails: {
        name: '',
        city: '',
        counties: { county: '', county2: '', county3: '', county4: '', county5: '' },
        state: '',
        created_by: '',
        modified_by: '',
        verified_by: '',
      },
      ahjCityCountyDetails: { cities: [], counties: [] },
      pvResidentialPermitDetails: {
        structural_peservices: [],
        structural_stamp_notes: '',
        electrical_peservices: [],
        electrical_stamp_notes: '',
        structural_calculations_required: '',
      },
      referenceInformation: { reference: '', reference_notes: '', reference_status: '', reference_files: [] },
      referenceFilesUploadError: {
        error: false,
        msg: '',
      },
      referenceFilesSize: 0,
      referenceFilesUploadStatus: 'idle',
      fieldUpdates: {
        reduced_spacing: '',
        miscellaneous_notes_field_updates: '',
      },
      siteAndRoofPlan: {
        min_allowable_tilt: '',
        max_allowable_tilt: '',
        max_allowable_tilt_for_metal_roof: '',
        max_span_for_landscape: '',
        max_span_for_portrait: '',
        conduit_locations: '',
        roof_access_point_required: '',
        building_elevation_required: '',
        flood_map_required: '',
        rafter_framing_details_required: '',
        fire_setbacks_requirements: '',
        flat_roof_requirements: '',
        ground_mount_requirements: '',
        additional_requirements_site_and_roof_plan: '',
      },
      notes: {
        designer_instructions_general: '',
        designer_instructions_site_and_roof_plan: '',
        designer_instructions_electrical: '',
        miscellaneous_notes: '',
      },
      requiredPlanNotes: {
        cover_sheet: '',
        site_and_roof_plan: '',
        mounting_detail: '',
        coastal_overlay_area: '',
        ground_mount: '',
        trenching: '',
        electrical: '',
        storage: '',
        placards: '',
      },
      governingCodeFiresetbacks: { ...this.initialFiresetbacks },
      firesetbacks: { ...this.initialFiresetbacks },
      governingCodes: {
        nec: {
          long_name: '',
          short_name: '',
          firesetbacks: [],
          national_electrical_code: '',
          code_category: { 0: { category_name: '' } },
        },
        ibc: { long_name: '', short_name: '', firesetbacks: [] },
        ifc: { long_name: '', short_name: '', firesetbacks: [], firesetbacks_defined: true },
        irc: { long_name: '', short_name: '', firesetbacks: [], firesetbacks_defined: true },
        other_codes: [],
      },
      ircFireSetBacks: [],
      ifcFireSetBacks: [],
      ahjGoverningCodesList: { nec: [], ibc: [], ifc: [], irc: [], other_codes: [] },
      weatherData: {
        snow_load_ground: '',
        snow_load_roof: '',
        wind_load_residential: '',
        wind_exposure_category: '',
        frost_depth: '',
        seismic_design_category: '',
      },
      permitOffice: {
        permit_office_contact_name: '',
        permit_office_designation: '',
        permit_office_contact_phone: '',
        permit_office_contact_email: '',
        permit_office_contact_address: '',
        permit_office_contact_zip: '',
        permit_office_working_days: '',
        permit_office_operational_hours: '',
        online_permit_submission: false,
        permit_tracking_website: '',
      },
      permitSubmissionTracking: {
        permit_submission_mode: [],
        copies: '',
        submission_through_solarapp_accepted: '',
        permit_fee_amount: '',
        permit_fee_payment_timeline: '',
        permit_fee_payment_mode: '',
        permit_approval_timelines: '',
        customer_signature_required: '',
        home_owner_auth_required: '',
        ppa_loan_agreement_accepted: '',
        insurance_certificate_required: '',
        completion_certificate_required: '',
        completion_affidavit_required: '',
        ca_sb379_compliance_required: '',
        site_visit_required: '',
        permit_tracking_mode: [],
        permit_tracking_details: '',
        permit_application_submittal_documents: '',
        permit_signature_requirements: '',
        permit_type_requirements: '',
        nem_approval_requirement: '',
        business_license_requirement: '',
        resubmission_procedure: '',
        historical_document_requirements: '',
      },
      evPermitDetails: {
        ev_permit_peservices: [],
        ev_construction_doc_peservices: '',
        ev_application_permits_required: [],
        ev_application_doc_signature: [],
        ev_permit_requirement_notes: '',
      },
      current_status: '',
      errorData: {},
      comment: '',
      comments_list: [],
      openCommentDialog: false,
      recordSubmitted: false,
      readOnly: this.isReadOnly(),
      customFiresetback: false,
    };
  }

  initialAHJCode = {
    long_name: '',
    short_name: '',
    firesetbacks: [],
    firesetbacks_defined: true,
    national_electrical_code: '',
    code_category: { 0: { category_name: '' } },
  };

  initialFiresetbacks = {
    id: '',
    flat_roof: '',
    ridge_mp: '',
    ridge_both_sides_lpv: '',
    ridge_both_sides_hpv: '',
    gable: '',
    rake: '',
    eave: '',
    obstruction: '',
    service_drops: '',
    egress: '',
    path_ridge_to_eave: '',
    paths_on_separate_roof_plane: '',
    path_adjacent_array: '',
    paths_on_single_ridge_home: '',
    max_array_span: '',
    ground_mount_perimeter: '',
    ridge_home_with_sprinklers: '',
    ridge_home_with_sprinklers_high: '',
    fsb_each_side: '',
  };

  MAX_ALLOWED_FILE_SIZE = 500;

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ id });
    if (id !== 'new')
      setTimeout(() => {
        this.props.getAHJ({
          id,
          successCB: (newAhj) => {
            this.handleGetSuccessCallback(newAhj);
            const state = newAhj.state;
            this.props.getAHJCity({
              state,
              successCb: () => {
                this.setState(
                  { ahjCityCountyDetails: this.props.ahjCityCountyDetails, readOnly: this.isReadOnly() },
                  () => {
                    this.countyList = [...this.state.ahjCityCountyDetails.counties];
                    this.setState(
                      {
                        isAHJTownship: this.state.basicDetails.city.township,
                      },
                      () => {
                        this.handleSetCountyList();
                      }
                    );
                  }
                );
              },
            });
            this.handleGetGoverningCodes(null, 'all');
            this.setDefaultFiresetbacks();
            this.setState({
              readOnly: this.isReadOnly(),
              firesetbacks: newAhj.custom_firesetbacks
                ? newAhj.firesetbacks || this.initialFiresetbacks
                : this.state.governingCodeFiresetbacks,
            });
          },
          failureCB: () => {
            this.handleCancel();
          },
        });
      }, 1000);
    else {
      this.handleGetGoverningCodes(null, 'all');
      this.setState({
        readOnly: this.isReadOnly(),
        customFiresetback: false,
      });
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSubmit = async (applicationStatus) => {
    const error = this.state.errorData;
    if (applicationStatus === 'EDIT') {
      return this.setState({ readOnly: false }, () => scrollIntoView(document.getElementById('name')));
    }
    if (applicationStatus === 'INCOMPLETE') {
      const nameField = this.state.basicDetails.name;
      this.state.basicDetails.city = this.state.basicDetails.city.id;
      if (!isEmpty(nameField) && !('name' in error)) {
        this.props.createAHJ(this.getPayload('INCOMPLETE'));
        return history.push('/ahj');
      }
    }
    if (applicationStatus === 'PENDING') {
      this.setState({ recordSubmitted: true });
    }
    await this.validateFields('all');
    const { errorData } = this.state;
    if (isEmpty(errorData)) {
      this.event = applicationStatus;
      this.commentDialogHeader = this.getCommentDialog(applicationStatus).headerText;
      this.commentDialogSecondaryHeader = this.getCommentDialog(applicationStatus).secondaryHeaderText;
      this.setState({ openCommentDialog: true });
    }
    const errorKeys = keysIn(errorData);
    scrollIntoView(document.getElementById(errorKeys.includes('name') ? 'name' : errorKeys[0]));
  };

  getCommentDialog = (applicationStatus) => {
    const commentDialog = {
      headerText: '',
      secondaryHeaderText: '',
    };
    const applicationStatusObject = APPLICATION_STATE_TRANSITIONS.find((state) => state.value === applicationStatus);
    commentDialog.headerText = applicationStatusObject.title;
    commentDialog.secondaryHeaderText = applicationStatusObject.key;
    return commentDialog;
  };

  handleProceed = () => {
    this.setState({ openCommentDialog: false }, () => {
      const payload = this.getPayload(this.event);
      payload.requestBody.city = this.state.basicDetails.city.id;
      this.props.createAHJ(payload);
    });
  };

  handleAddComment = (comment) => {
    this.setState({ comment: comment });
  };

  handleCommentDialogClose = () => {
    this.setState({ openCommentDialog: false, comment: '' });
  };

  handleCreateSuccessCallback = (newAhj) => {
    history.push('/ahj');
    this.handleGetSuccessCallback(newAhj, () => {
      history.push('/ahj/' + get(newAhj, 'id'));
    });
    scrollIntoView(document.getElementById('name'));
  };

  handleGetSuccessCallback = (ahj, callback = () => {}) => {
    const {
      no_requirements_checked,
      ev_permit_details_checked,
      no_data_available_checked,
      display_other_codes_checked = false,
      display_national_electrical_code_checked = false,
      reference_information,
      field_updates,
      design_requirements_site_and_roof_plan,
      pv_residential_permit_details,
      notes,
      required_plan_notes,
      firesetbacks,
      custom_firesetbacks,
      governing_codes,
      other_codes,
      weather_data,
      permit_office_details,
      permit_submission_tracking,
      ev_permit_details,
      name = '',
      state = '',
      counties,
      created_by = '',
      modified_by = '',
      verified_by = '',
      id = 'new',
      created_at = '',
      verified_at = '',
      modified_at = '',
      comments_list = [],
      current_status = '',
    } = ahj;
    const city = ahj.city
      ? {
          city_name: ahj.city.city_name,
          county_name: ahj.city.county_name,
          id: ahj.city.id,
          is_city_ahj_exists: ahj.name.includes('City of') || ahj.name.includes('Township of') ? true : false,
          township: ahj.city.township == null || ahj.city.township == false ? false : true,
        }
      : '';
    const county = counties.county ? counties.county.county_name : '';
    const county2 = counties.county2 ? counties.county2.county_name : '';
    const county3 = counties.county3 ? counties.county3.county_name : '';
    const county4 = counties.county4 ? counties.county4.county_name : '';
    const county5 = counties.county5 ? counties.county5.county_name : '';
    const {
      structural_peservices = [],
      structural_stamp_notes = '',
      electrical_peservices = [],
      electrical_stamp_notes = '',
      structural_calculations_required = '',
    } = pv_residential_permit_details;
    const {
      reference = '',
      reference_notes = '',
      reference_status = '',
      reference_files = [],
    } = reference_information || {};
    const { reduced_spacing = '', miscellaneous_notes_field_updates = '' } = field_updates || {};
    const {
      min_allowable_tilt = '',
      max_allowable_tilt = '',
      max_allowable_tilt_for_metal_roof = '',
      max_span_for_landscape = '',
      max_span_for_portrait = '',
      conduit_locations = '',
      roof_access_point_required = '',
      building_elevation_required = '',
      flood_map_required = '',
      rafter_framing_details_required = '',
      fire_setbacks_requirements = '',
      flat_roof_requirements = '',
      ground_mount_requirements = '',
      additional_requirements_site_and_roof_plan = '',
    } = design_requirements_site_and_roof_plan || {};
    const {
      designer_instructions_general = '',
      designer_instructions_site_and_roof_plan = '',
      designer_instructions_electrical = '',
      miscellaneous_notes = '',
    } = notes || {};
    const {
      cover_sheet = '',
      site_and_roof_plan = '',
      mounting_detail = '',
      coastal_overlay_area = '',
      ground_mount = '',
      trenching = '',
      electrical = '',
      storage = '',
      placards = '',
    } = required_plan_notes || {};
    const nec = governing_codes.nec ? governing_codes.nec : this.initialAHJCode;
    const ibc = governing_codes.ibc ? governing_codes.ibc : this.initialAHJCode;
    const ifc = governing_codes.ifc ? governing_codes.ifc : this.initialAHJCode;
    const irc = governing_codes.irc ? governing_codes.irc : this.initialAHJCode;
    const othercodes = other_codes;
    const {
      snow_load_ground = '',
      snow_load_roof = '',
      wind_load_residential = '',
      wind_exposure_category = '',
      frost_depth = '',
      seismic_design_category = '',
    } = weather_data || {};
    const {
      permit_office_contact_name = '',
      permit_office_designation = '',
      permit_office_contact_phone = '',
      permit_office_contact_email = '',
      permit_office_contact_address = '',
      permit_office_contact_zip = '',
      permit_office_working_days = '',
      permit_office_operational_hours = '',
      online_permit_submission = false,
      permit_tracking_website = '',
    } = permit_office_details || {};
    const {
      permit_submission_mode = [],
      copies = '',
      submission_through_solarapp_accepted = '',
      permit_fee_amount = '',
      permit_fee_payment_timeline = '',
      permit_fee_payment_mode = '',
      permit_approval_timelines = '',
      customer_signature_required = '',
      home_owner_auth_required = '',
      ppa_loan_agreement_accepted = '',
      insurance_certificate_required = '',
      completion_certificate_required = '',
      completion_affidavit_required = '',
      ca_sb379_compliance_required = '',
      site_visit_required = '',
      permit_tracking_mode = [],
      permit_tracking_details = '',
      permit_application_submittal_documents = '',
      permit_signature_requirements = '',
      permit_type_requirements = '',
      nem_approval_requirement = '',
      business_license_requirement = '',
      resubmission_procedure = '',
      historical_document_requirements = '',
    } = permit_submission_tracking || {};
    const {
      ev_permit_peservices = [],
      ev_construction_doc_peservices = '',
      ev_application_permits_required = [],
      ev_application_doc_signature = [],
      ev_permit_requirement_notes = '',
    } = ev_permit_details || {};

    this.setState(
      {
        id,
        noRequirementsCheck: no_requirements_checked,
        evPermitDetailsCheck: ev_permit_details_checked,
        noDataAvailableCheck: no_data_available_checked,
        displayOtherCodesCheck: display_other_codes_checked,
        displayNationalElectricalCodeCheck: display_national_electrical_code_checked,
        basicDetails: {
          name,
          city,
          counties: { county, county2, county3, county4, county5 },
          state,
          created_by,
          modified_by,
          verified_by,
          created_at,
          modified_at,
          verified_at,
        },
        referenceInformation: { reference, reference_notes, reference_status, reference_files },
        fieldUpdates: {
          reduced_spacing,
          miscellaneous_notes_field_updates,
        },
        siteAndRoofPlan: {
          min_allowable_tilt,
          max_allowable_tilt,
          max_allowable_tilt_for_metal_roof,
          max_span_for_landscape,
          max_span_for_portrait,
          conduit_locations,
          roof_access_point_required,
          building_elevation_required,
          flood_map_required,
          rafter_framing_details_required,
          fire_setbacks_requirements,
          flat_roof_requirements,
          ground_mount_requirements,
          additional_requirements_site_and_roof_plan,
        },
        pvResidentialPermitDetails: {
          structural_peservices,
          structural_stamp_notes,
          electrical_peservices,
          electrical_stamp_notes,
          structural_calculations_required,
        },
        notes: {
          designer_instructions_general,
          designer_instructions_site_and_roof_plan,
          designer_instructions_electrical,
          miscellaneous_notes,
        },
        requiredPlanNotes: {
          cover_sheet,
          site_and_roof_plan,
          mounting_detail,
          coastal_overlay_area,
          ground_mount,
          trenching,
          electrical,
          storage,
          placards,
        },
        firesetbacks: custom_firesetbacks
          ? firesetbacks || this.initialFiresetbacks
          : this.state.governingCodeFiresetbacks,
        governingCodes: {
          nec,
          ibc,
          ifc,
          irc,
          other_codes: othercodes,
        },
        weatherData: {
          snow_load_ground,
          snow_load_roof,
          wind_load_residential,
          wind_exposure_category,
          frost_depth,
          seismic_design_category,
        },
        permitOffice: {
          permit_office_contact_name,
          permit_office_designation,
          permit_office_contact_phone,
          permit_office_contact_email,
          permit_office_contact_address,
          permit_office_contact_zip,
          permit_office_working_days,
          permit_office_operational_hours,
          online_permit_submission,
          permit_tracking_website,
        },
        permitSubmissionTracking: {
          permit_submission_mode,
          copies,
          submission_through_solarapp_accepted,
          permit_fee_amount,
          permit_fee_payment_timeline,
          permit_fee_payment_mode,
          permit_approval_timelines,
          customer_signature_required,
          home_owner_auth_required,
          ppa_loan_agreement_accepted,
          insurance_certificate_required,
          completion_certificate_required,
          completion_affidavit_required,
          ca_sb379_compliance_required,
          site_visit_required,
          permit_tracking_mode,
          permit_tracking_details,
          permit_application_submittal_documents,
          permit_signature_requirements,
          permit_type_requirements,
          nem_approval_requirement,
          business_license_requirement,
          resubmission_procedure,
          historical_document_requirements,
        },
        evPermitDetails: {
          ev_permit_peservices,
          ev_construction_doc_peservices,
          ev_application_permits_required,
          ev_application_doc_signature,
          ev_permit_requirement_notes,
        },
        current_status,
        comments_list,
        readOnly: this.isReadOnly(),
        customFiresetback: custom_firesetbacks,
      },
      callback
    );
  };

  fileIcon = (fName) => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
      fType = 'document';
    }
    const DocIcon = Icons[fType];
    return <DocIcon />;
  };

  getCodeLongname = (governingCodes) => {
    return {
      nec: governingCodes['nec'].long_name,
      ibc: governingCodes['ibc'].long_name,
      ifc: governingCodes['ifc'].long_name,
      irc: governingCodes['irc'].long_name,
      other_codes: governingCodes['other_codes'],
    };
  };

  getPayload = (applicationStatus) => {
    const {
      evPermitDetailsCheck,
      referenceInformation,
      fieldUpdates,
      siteAndRoofPlan,
      notes,
      pvResidentialPermitDetails,
      requiredPlanNotes,
      governingCodes,
      displayOtherCodesCheck,
      displayNationalElectricalCodeCheck,
      firesetbacks,
      weatherData,
      permitOffice,
      permitSubmissionTracking,
      evPermitDetails,
      comment,
      customFiresetback,
      id,
    } = this.state;
    const governing_codes = this.getCodeLongname(governingCodes);
    const othercodes = governing_codes['other_codes']
      ? governing_codes['other_codes'].map((code) => code.long_name)
      : [''];
    delete governing_codes['other_codes'];
    /* in case of rejection we don't want to send anything other than comment and status.*/
    if (applicationStatus === 'REJECTED') {
      const { city, counties, governing_codes: gov_codes, other_codes: other_cds } = this.props.ahj;
      const city_name = city != null ? city.city_name : '';
      const county = counties ? (counties.county ? counties.county.county_name : '') : '';
      const county2 = counties ? (counties.county2 ? counties.county2.county_name : '') : '';
      const county3 = counties ? (counties.county3 ? counties.county3.county_name : '') : '';
      const county4 = counties ? (counties.county4 ? counties.county4.county_name : '') : '';
      const county5 = counties ? (counties.county5 ? counties.county5.county_name : '') : '';

      const nec = gov_codes.nec ? gov_codes.nec.long_name : '';
      const ibc = gov_codes.ibc ? gov_codes.ibc.long_name : '';
      const ifc = gov_codes.ifc ? gov_codes.ifc.long_name : '';
      const irc = gov_codes.irc ? gov_codes.irc.long_name : '';
      const othercode = other_cds.map((e) => e.long_name);

      return {
        id,
        requestBody: {
          ...this.props.ahj,
          ev_permit_details_checked: this.props.ahj.ev_permit_details_checked,
          city: city_name,
          counties: { county: county, county2: county2, county3: county3, county4: county4, county5: county5 },
          governing_codes: { nec: nec, ibc: ibc, ifc: ifc, irc: irc },
          other_codes: othercode,
          display_other_codes_checked: this.props.ahj.display_other_codes_checked,
          display_national_electrical_code_checked: this.props.ahj.display_national_electrical_code_checked,
          current_status: applicationStatus,
          custom_firesetback: customFiresetback,
          comment: comment,
        },

        successCB: (ahj) => this.handleCreateSuccessCallback(ahj),
      };
    } else {
      return {
        id,
        requestBody: {
          ...this.state.basicDetails,
          ev_permit_details_checked: evPermitDetailsCheck,
          reference_information: referenceInformation,
          field_updates: fieldUpdates,
          design_requirements_site_and_roof_plan: siteAndRoofPlan,
          notes: notes,
          pv_residential_permit_details: pvResidentialPermitDetails,
          required_plan_notes: requiredPlanNotes,
          governing_codes: governing_codes,
          other_codes: othercodes,
          display_other_codes_checked: displayOtherCodesCheck,
          display_national_electrical_code_checked: displayNationalElectricalCodeCheck,
          firesetbacks: firesetbacks,
          weather_data: weatherData,
          permit_office_details: permitOffice,
          permit_submission_tracking: permitSubmissionTracking,
          ev_permit_details: evPermitDetails,
          current_status: applicationStatus,
          comment: comment,
          custom_firesetback: customFiresetback,
        },
        successCB: (ahj) => this.handleCreateSuccessCallback(ahj),
      };
    }
  };

  validateFields = async (section) => {
    const {
      errorData,
      isAHJNameUnique,
      countyRequired,
      basicDetails,
      pvResidentialPermitDetails,
      governingCodes,
      firesetbacks,
      weatherData,
      permitOffice,
      permitSubmissionTracking,
      referenceInformation,
      noRequirementsCheck,
      noDataAvailableCheck,
      siteAndRoofPlan,
    } = this.state;
    const { counties } = basicDetails;
    const omittedFields = [];
    const areFieldsRequired = !noRequirementsCheck && !noDataAvailableCheck;

    // All basic details validations goes here
    if (section === 'basic_details' || section === 'all') {
      const basicDetailsRequiredFields = ['name', 'state', 'county'];
      basicDetailsRequiredFields.forEach((ele) => {
        if (isEqual(ele, 'state') && isEmpty(basicDetails[ele])) errorData['state'] = 'Required';
        else if (isEqual(ele, 'name') && !isEmpty(basicDetails['name']) && !isAHJNameUnique) {
          errorData['name'] = 'AHJ Name already exists';
        } else if (isEqual(ele, 'county') && isEmpty(counties['county']) && countyRequired) {
          errorData['county'] = 'Required';
        } else omittedFields.push(ele);
      });
    }

    // All reference information section validation goes here
    if (section === 'referenceInformation' || section === 'all') {
      let referenceDataValidationFields = ['reference_notes', 'reference_status'];
      referenceDataValidationFields.forEach((ele) => {
        if (
          isEqual(ele, 'reference_notes') &&
          isEmpty(referenceInformation[ele]) &&
          isEqual(referenceInformation['reference_status'], 'AHJ_DATA_POPULATED_USING_REFERENCE_JOB')
        )
          errorData[ele] = 'Required';
        else if (isEqual(ele, 'reference_status') && isEmpty(referenceInformation[ele])) errorData[ele] = 'Required';
        else omittedFields.push(ele);
      });
    }

    // pv residential section validation goes here
    if (section === 'pvResidentialPermitDetails' || section === 'all') {
      let pvResidentialRequiredFields = ['structural_peservices', 'electrical_peservices'];
      pvResidentialRequiredFields.forEach((field) => {
        if (isEmpty(pvResidentialPermitDetails[field]) && areFieldsRequired) {
          errorData[field] = 'Required';
        } else {
          omittedFields.push(field);
        }
      });
    }
    // All governing codes validations goes here
    if (section === 'governingCodes' || section === 'all') {
      let governingCodesRequiredFields = ['nec'];
      governingCodesRequiredFields.forEach((ele) => {
        if (isEmpty(governingCodes[ele].long_name) && areFieldsRequired) {
          errorData[ele] = 'Required';
        } else omittedFields.push(ele);
      });
    }
    // All firesetbacks validations goes here
    if (section === 'firesetbacks' || section === 'all') {
      let firesetbacksValidationFields = [
        'flat_roof',
        'ridge_mp',
        'ridge_both_sides_lpv',
        'ridge_both_sides_hpv',
        'gable',
        'rake',
        'eave',
        'obstruction',
        'service_drops',
        'egress',
        'path_ridge_to_eave',
        'paths_on_separate_roof_plane',
        'path_adjacent_array',
        'paths_on_single_ridge_home',
        'max_array_span',
        'ground_mount_perimeter',
        'ridge_home_with_sprinklers',
        'ridge_home_with_sprinklers_high',
      ];
      firesetbacksValidationFields.forEach((ele) => {
        if (
          (isEqual(ele, 'max_array_span') || isEqual(ele, 'ground_mount_perimeter')) &&
          !inRange(firesetbacks[ele], 0, 501)
        ) {
          errorData[ele] = 'Value should be between 0-500';
        } else if (
          !(isEqual(ele, 'max_array_span') || isEqual(ele, 'ground_mount_perimeter')) &&
          !inRange(firesetbacks[ele], 0, 101)
        ) {
          errorData[ele] = 'Value should be between 0-100';
        } else {
          omittedFields.push(ele);
        }
      });
    }
    // All Weather Data validations goes here
    if (section === 'weatherData' || section === 'all') {
      let weatherDataValidationFields = ['snow_load_ground', 'snow_load_roof', 'wind_load_residential', 'frost_depth'];
      weatherDataValidationFields.forEach((ele) => {
        if (!inRange(weatherData[ele], 0, 10001)) errorData[ele] = 'Value should be between 0-10000';
        else omittedFields.push(ele);
      });
    }
    // All permit office section validations goes here
    if (section === 'permitOffice' || section === 'all') {
      let permitOfficeValidationFields = [
        'permit_office_contact_email',
        'permit_office_contact_zip',
        'permit_office_contact_phone',
      ];
      const zipValidateForm = /^[0-9]{5}(?:-[0-9]{4})?$/i;
      const emailValidateForm = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;
      permitOfficeValidationFields.forEach((ele) => {
        if (
          isEqual(ele, 'permit_office_contact_email') &&
          !isEmpty(permitOffice.permit_office_contact_email) &&
          !emailValidateForm.test(permitOffice.permit_office_contact_email)
        ) {
          errorData[ele] = 'Invalid email address';
        } else if (
          isEqual(ele, 'permit_office_contact_zip') &&
          !isEmpty(permitOffice.permit_office_contact_zip) &&
          !zipValidateForm.test(permitOffice.permit_office_contact_zip)
        ) {
          errorData[ele] = 'Invalid zip code';
        } else if (
          isEqual(ele, 'permit_office_contact_phone') &&
          !isEmpty(permitOffice.permit_office_contact_phone) &&
          !(
            isValidPhoneNumber(permitOffice.permit_office_contact_phone) &&
            isPossiblePhoneNumber(permitOffice.permit_office_contact_phone)
          )
        ) {
          errorData[ele] = 'Invalid phone number';
        } else omittedFields.push(ele);
      });
    }
    if (section === 'permitSubmissionTracking' || section === 'all') {
      let permitSubmissionTrackingValidationFields = ['copies', 'permit_fee_amount'];
      permitSubmissionTrackingValidationFields.forEach((ele) => {
        if (
          isEqual(ele, 'copies') &&
          !isEmpty(permitSubmissionTracking[ele]) &&
          !inRange(permitSubmissionTracking[ele], 0, 101)
        ) {
          errorData[ele] = 'Value should be between 0-100';
        } else if (
          isEqual(ele, 'permit_fee_amount') &&
          !isEmpty(permitSubmissionTracking[ele]) &&
          !inRange(permitSubmissionTracking[ele], 0, 10000.01)
        ) {
          errorData[ele] = 'Value should be between 0-10000';
        } else if (
          isEqual(ele, 'permit_fee_amount') &&
          !isEmpty(permitSubmissionTracking[ele]) &&
          countDecimals(permitSubmissionTracking[ele]) > 2
        ) {
          errorData[ele] = 'Please enter upto 2 decimals only';
        } else {
          omittedFields.push(ele);
        }
      });
    }

    if (section === 'siteAndRoofPlan' || section === 'all') {
      let siteAndRoofPlanValidationFields = [
        'min_allowable_tilt',
        'max_allowable_tilt',
        'max_allowable_tilt_for_metal_roof',
        'max_span_for_landscape',
        'max_span_for_portrait',
      ];
      siteAndRoofPlanValidationFields.forEach((ele) => {
        if (
          isEqual(ele, 'min_allowable_tilt') &&
          !isEmpty(siteAndRoofPlan[ele]) &&
          !inRange(siteAndRoofPlan[ele], 0, 10.01)
        ) {
          errorData[ele] = 'Value should be between 0-10';
        } else if (
          (isEqual(ele, 'max_allowable_tilt') || isEqual(ele, 'max_allowable_tilt_for_metal_roof')) &&
          !isEmpty(siteAndRoofPlan[ele]) &&
          !inRange(siteAndRoofPlan[ele], 30, 60.01)
        ) {
          errorData[ele] = 'Value should be between 30-60';
        } else if (
          (isEqual(ele, 'min_allowable_tilt') ||
            isEqual(ele, 'max_allowable_tilt') ||
            isEqual(ele, 'max_allowable_tilt_for_metal_roof')) &&
          !isEmpty(siteAndRoofPlan[ele]) &&
          countDecimals(siteAndRoofPlan[ele]) > 2
        ) {
          errorData[ele] = 'Please enter upto 2 decimals only';
        } else if (
          (isEqual(ele, 'max_span_for_landscape') || isEqual(ele, 'max_span_for_portrait')) &&
          !isEmpty(siteAndRoofPlan[ele]) &&
          !inRange(siteAndRoofPlan[ele], 1, 101)
        ) {
          errorData[ele] = 'Value should be between 1-100';
        } else {
          omittedFields.push(ele);
        }
      });
    }

    const newErrorData = omit(errorData, omittedFields);
    console.log('newErrorData...', newErrorData);
    console.log('omittedFields...', omittedFields);
    return section === 'all' ? this.setState({ errorData: newErrorData }) : newErrorData;
  };

  handleCityCheckbox = (e) => {
    this.setState({ countyRequired: e.target.checked }, () => {
      this.setAHJName();
    });
  };

  handleCancel() {
    history.push('/ahj');
  }

  handleGoverningCodes = async (e, key, value = null) => {
    const { governingCodes } = this.state;
    governingCodes[key] = value;
    const errorData = await this.validateFields('governingCodes');
    this.setState({ ...this.state, governingCodes, errorData }, () => {
      if (isEqual(key, 'irc') || isEqual(key, 'ifc')) this.setDefaultFiresetbacks();
    });
    if (isEqual(key, 'nec') && value && isEqual(value.code_category[0].category_name, 'NATIONAL ELECTRICAL CODE')) {
      this.setState({ displayNationalElectricalCodeCheck: false });
    }
  };

  getFireSetBacks = async () => {
    const { governingCodes } = this.state;
    const irc = governingCodes['irc'].associated_code !== null ? governingCodes['irc'].associated_code : '';
    const ifc = governingCodes['ifc'].associated_code !== null ? governingCodes['ifc'].associated_code : '';
    this.props.getAHJDefaultAllFiresetbacks({
      irc,
      ifc,
      successCb: (result) => {
        const ircValue = get(result, 'irc') !== null ? [get(result, 'irc')] : [];
        const ifcValue = get(result, 'ifc') !== null ? [get(result, 'ifc')] : [];
        this.setState({ ircFireSetBacks: ircValue }, async () => {
          this.setState({
            ifcFireSetBacks: ifcValue,
          });
        });
      },
      failureCb: () => {
        this.setState({ ircFireSetBacks: [] }, async () => {
          this.setState({
            ifcFireSetBacks: [],
          });
        });
      },
    });
  };
  handleDisplayOtherCodesCheckbox = (e, key) => {
    if (isEqual(key, 'displayOtherCodesCheck')) {
      this.setState({ displayOtherCodesCheck: e.target.checked });
    }
  };

  handleDisplayNECCheckbox = (e, key) => {
    if (isEqual(key, 'displayNationalElectricalCodeCheck')) {
      this.setState({ displayNationalElectricalCodeCheck: e.target.checked });
    }
  };

  handleGetGoverningCodes = async (e, key, value = null) => {
    const { ahjGoverningCodesList } = this.state;
    if (isEqual(key, 'other_codes') && value && !isEmpty(value.trim())) {
      const category = ['othercodes', value];
      this.props.getAHJGoverningCodes({
        category,
        successCb: () => {
          ahjGoverningCodesList[key] = this.props.ahjGoverningCodesList;
          this.setState({ ahjGoverningCodesList });
        },
      });
    } else if (isEqual(key, 'all')) {
      const category = ['all'];
      this.props.getAHJGoverningCodes({
        category,
        successCb: () => {
          this.setState({ ahjGoverningCodesList: this.props.ahjGoverningCodesList });
        },
      });
    } else {
      const category = [key];
      if (ahjGoverningCodesList[key].length === 0)
        this.props.getAHJGoverningCodes({
          category,
          successCb: () => {
            let ahjGoverningCodes = { ...ahjGoverningCodesList };
            ahjGoverningCodes[key] = this.props.ahjGoverningCodesList;
            this.setState({ ahjGoverningCodesList: ahjGoverningCodes });
          },
        });
    }
  };

  setDefaultFiresetbacks() {
    const { governingCodes } = this.state;
    const irc = governingCodes['irc'].associated_code ? governingCodes['irc'].associated_code : '';
    const ifc = governingCodes['ifc'].associated_code !== null ? governingCodes['ifc'].associated_code : '';
    this.getFireSetBacks();
    this.props.getAHJDefaultFiresetbacks({
      irc,
      ifc,
      successCb: (result) => {
        if (result) {
          this.setState({ governingCodeFiresetbacks: result });
          if (!this.state.customFiresetback) {
            this.setState({ firesetbacks: result });
          }
        }
      },
      failureCb: () => {
        this.setState({ governingCodeFiresetbacks: this.initialFiresetbacks });
        if (!this.state.customFiresetback) {
          this.setState({ firesetbacks: this.initialFiresetbacks });
        }
      },
    });
  }

  setAHJName = () => {
    const { basicDetails, isAHJTownship, countyRequired } = this.state;
    let ahjName = basicDetails.name;
    const [stateName, cityName, countyName] = [
      basicDetails.state,
      basicDetails.city.city_name,
      basicDetails.counties.county,
    ];
    if (!this.isComponentDidMountCalled) {
      this.isComponentDidMountCalled = true;
      if (
        !isEmpty(countyName) &&
        !isEmpty(stateName) &&
        !isEmpty(cityName) &&
        basicDetails.name ===
          cityName + ` [${countyName} County]` + (isAHJTownship ? ` (Township of), ` : ` (City of), `) + stateName
      ) {
        this.setState({ countyRequired: true }, () => {
          this.setAHJName();
        });
        return;
      }
    }

    if (!isEmpty(stateName)) {
      if (isEmpty(cityName) && isEmpty(countyName)) {
        ahjName = stateName + ' (State)';
      } else if (isEmpty(cityName) && !isEmpty(countyName)) {
        ahjName = countyName + ' (County of), ' + stateName;
      } else if (countyRequired && !isEmpty(countyName)) {
        ahjName =
          cityName + ` [${countyName} County]` + (isAHJTownship ? ` (Township of), ` : ` (City of), `) + stateName;
      } else {
        ahjName = cityName + (isAHJTownship ? ' (Township of), ' : ' (City of), ') + stateName;
      }
    }
    if (!isEqual(basicDetails.name, ahjName)) {
      basicDetails.name = ahjName;
      this.setState({ basicDetails }, () => {
        if (!isEqual(this.props.ahj.name, ahjName)) {
          this.props.getAHJNameValidation({
            ahjName,
            successCb: () => {
              this.setState({ isAHJNameUnique: this.props.isAHJNameUnique }, async () => {
                this.setState({
                  showCityCheckbox: (!this.props.isAHJNameUnique && !isEmpty(cityName)) || countyRequired,
                });
                const errorData = await this.validateFields('basic_details');
                this.setState({ ...this.state, basicDetails, errorData });
              });
            },
          });
        } else {
          this.setState({ isAHJNameUnique: true }, async () => {
            const errorData = await this.validateFields('basic_details');
            this.setState({ ...this.state, basicDetails, errorData });
          });
        }
      });
    }
  };

  handleSetCountyList() {
    const { basicDetails } = this.state;
    let newCountyList = [...this.countyList];
    const countyFieldSelected = ['county', 'county2', 'county3', 'county4', 'county5'];
    for (let index in countyFieldSelected) {
      const val = countyFieldSelected[index];
      if (!isEmpty(basicDetails.counties[val]) || !isEqual(basicDetails.counties[val], null)) {
        newCountyList = newCountyList.filter((item) => item !== basicDetails.counties[val]);
      }
    }
    const { ahjCityCountyDetails } = this.state;
    ahjCityCountyDetails.counties = newCountyList;
    this.setState({ ahjCityCountyDetails });
    this.setAHJName();
  }

  handleBasicDetails = async (e, key, value) => {
    const { basicDetails } = this.state;
    const countyFieldSelected = ['county', 'county2', 'county3', 'county4', 'county5'];
    if (isEqual(key, 'state')) {
      const state = value;
      basicDetails.state = value;
      this.props.getAHJCity({
        state,
        successCb: () => {
          basicDetails.city = '';
          basicDetails.counties.county = '';
          for (let i = 2; i < 6; i++) {
            basicDetails.counties['county' + i.toString()] = '';
          }
          this.setState({ ahjCityCountyDetails: this.props.ahjCityCountyDetails, basicDetails });
          this.countyList = [...this.state.ahjCityCountyDetails.counties];
          this.setAHJName();
        },
      });
    } else {
      value = isEqual(value, null) ? '' : value;
      if (isEqual(key, 'city')) {
        basicDetails[key] = value;
        const {
          ahjCityCountyDetails: { townships },
        } = this.state;
        this.setState({ isAHJTownship: value.township }, () => {
          this.setAHJName();
        });
      } else if (countyFieldSelected.includes(key)) {
        basicDetails.counties[key] = value;
        this.handleSetCountyList();
      } else {
        basicDetails[key] = e.target.value;
      }
    }

    if (isEqual(key, 'evPermitDetailsCheck')) {
      this.setState({ evPermitDetailsCheck: e.target.checked });
    }
    if (key === 'created_by') {
      basicDetails['modified_by'] = e.target.value;
    }
    const errorData = await this.validateFields('basic_details');
    this.setState({ ...this.state, basicDetails, errorData });
  };

  handleClearCouties = async (e, value) => {
    if (isEmpty(value)) {
      let { basicDetails } = this.state;
      for (let i = 2; i < 6; i++) {
        basicDetails.counties['county' + i.toString()] = '';
      }
      this.setState({ basicDetails });
      this.handleSetCountyList();
    }
  };

  handlePVResidentialPermitDetails = (e, key) => {
    const dropDownFields = ['electrical_peservices', 'structural_peservices', 'structural_calculations_required'];
    const textFields = ['structural_stamp_notes', 'electrical_stamp_notes'];
    const pvResidentialPermitDetails = this.state.pvResidentialPermitDetails;
    const noneSelected = includes(dropDownFields, key) && includes(e.target.value, 'none');
    if (includes(dropDownFields, key)) {
      pvResidentialPermitDetails[key] = noneSelected ? ['none'] : e.target.value;
    } else if (includes(textFields, key)) {
      pvResidentialPermitDetails[key] = e.target.value;
    }
    const errorData = this.validateFields('pvResidentialPermitDetails');
    this.setState({ ...this.state, pvResidentialPermitDetails, errorData });
  };

  handleReferenceInformation = (e, key) => {
    const { referenceInformation } = this.state;

    if (isEqual(key, 'reference_status')) {
      const refStatus = e.target.value;
      this.setState({
        noRequirementsCheck: refStatus === 'REFERENCE_FOUND',
        noDataAvailableCheck:
          refStatus === 'REFERENCE_NOT_FOUND' ||
          refStatus === 'AHJ_DATA_NOT_AVAILABLE' ||
          refStatus === 'AHJ_DOES_NOT_EXIST',
      });
    }
    referenceInformation[key] = e.target.value;
    const errorData = this.validateFields('referenceInformation');
    this.setState({ ...this.state, referenceInformation, errorData });
  };

  handleFieldUpdates = (value, key) => {
    const { fieldUpdates } = this.state;
    fieldUpdates[key] = value;
    const errorData = this.validateFields('fieldUpdates');
    this.setState({ ...this.state, fieldUpdates, errorData });
  };

  handleSiteAndRoofPlan = async (e, key) => {
    const fieldValue = e.target.value;
    const siteAndRoofPlan = this.state.siteAndRoofPlan;
    siteAndRoofPlan[key] = fieldValue;
    const errorData = await this.validateFields('siteAndRoofPlan');
    this.setState({ ...this.state, siteAndRoofPlan, errorData });
  };

  handleNotes = (e, key = false) => {
    const { notes } = this.state;
    notes[key] = e.target.value;
    const errorData = this.validateFields('notes');
    this.setState({ ...this.state, notes, errorData });
  };

  handleRequiredPlanNotes = (e, key = false) => {
    const { requiredPlanNotes } = this.state;
    requiredPlanNotes[key] = e.target.value;
    const errorData = this.validateFields('requiredPlanNotes');
    this.setState({ ...this.state, requiredPlanNotes, errorData });
  };

  /**
   * To set the values of the firesetback as a whole or some specific fields based on toggle or field inputs
   * @param {InputEvent} e input event either from specific textfield or from toggle button
   * @param {string} key value of the specific field of firesetback or undefined in case of toggle event
   */
  handleFireSetbacks = async (e, key) => {
    if (key) {
      const fieldValue = e.target.value;
      const firesetbacks = this.state.firesetbacks;
      const numberFieldValue = isEmpty(fieldValue) ? null : toInteger(fieldValue);
      firesetbacks[key] = e.target.type === 'number' ? numberFieldValue : fieldValue;
      const errorData = await this.validateFields('firesetbacks');
      this.setState({ ...this.state, firesetbacks, errorData });
    } else {
      // if customFiresetback then have either of default firesetbacks or to empty it
      if (e.target.checked) {
        this.setState((prevState) => ({
          ...prevState,
          firesetbacks: this.props.ahj.firesetbacks
            ? { ...this.props.ahj.firesetbacks }
            : this.state.governingCodeFiresetbacks,
          customFiresetback: !prevState.customFiresetback,
        }));
      } else {
        // if gov codes then empty it
        this.setState((prevState) => ({
          ...prevState,
          firesetbacks: this.state.governingCodeFiresetbacks,
          customFiresetback: !prevState.customFiresetback,
        }));
      }
    }
  };

  handleWeatherData = async (e, key) => {
    const fieldValue = e.target.value;
    const weatherData = this.state.weatherData;
    const numberFieldValue = isEmpty(fieldValue) ? null : toInteger(fieldValue);
    weatherData[key] = e.target.type === 'number' ? numberFieldValue : fieldValue;
    const errorData = await this.validateFields('weatherData');
    this.setState({ ...this.state, weatherData, errorData });
  };

  handlePermitOffice = async (e, key) => {
    const permitOffice = this.state.permitOffice;
    if (isEqual(key, 'online_permit_submission')) {
      permitOffice.online_permit_submission = e.target.checked;
    } else {
      const fieldValue = e.target.value;
      const numberFieldValue = isEmpty(fieldValue) ? null : toInteger(fieldValue);
      permitOffice[key] = e.target.type === 'number' ? numberFieldValue : fieldValue;
    }
    const errorData = await this.validateFields('permitOffice');
    this.setState({ ...this.state, permitOffice, errorData });
  };

  handlePermitSubmissionTracking = async (e, key) => {
    const fieldValue = e.target.value;
    const permitSubmissionTracking = this.state.permitSubmissionTracking;
    permitSubmissionTracking[key] = fieldValue;
    const errorData = await this.validateFields('permitSubmissionTracking');
    this.setState({ ...this.state, permitSubmissionTracking, errorData });
  };

  handleEVPermitDetails = (e, key) => {
    const evPermitDetails = this.state.evPermitDetails;
    evPermitDetails[key] = e.target.value;
    const errorData = this.validateFields('evPermitDetails');
    this.setState({ ...this.state, evPermitDetails, errorData });
  };

  setDocumentUploadError(errorDetails = { error: false, msg: '' }, status) {
    this.setState({ referenceFilesUploadError: { error: errorDetails.error, msg: errorDetails.msg } });
    status && this.setState({ referenceFilesUploadStatus: status });
  }

  getFilePrefixName = (basicDetails) => {
    this.validateFields('basic_details');
    scrollIntoView(document.getElementById(keysIn(this.state.errorData)[0]));
    let filePrefixName = '';
    if (!isEmpty(basicDetails.state)) {
      filePrefixName = basicDetails.state;
    }
    if (!isEmpty(basicDetails.counties.county)) {
      filePrefixName += '_' + basicDetails.counties.county;
    }
    if (!isEmpty(basicDetails.counties.county2)) {
      filePrefixName += '_' + basicDetails.counties.county2;
    }
    if (!isEmpty(basicDetails.counties.county3)) {
      filePrefixName += '_' + basicDetails.counties.county3;
    }
    if (!isEmpty(basicDetails.counties.county4)) {
      filePrefixName += '_' + basicDetails.counties.county4;
    }
    if (!isEmpty(basicDetails.counties.county5)) {
      filePrefixName += '_' + basicDetails.counties.county5;
    }
    if (!isEmpty(basicDetails.city)) {
      filePrefixName += '_' + basicDetails.city.city_name;
    }
    return filePrefixName;
  };

  deleteFile = (ind, cb) => {
    // clear the message
    this.setState({ referenceFilesUploadError: { error: false, msg: '' } });
    let reversedRefFiles = this.state.referenceInformation.reference_files.slice().reverse();
    const fileName = reversedRefFiles[ind].file_name;
    const methodType = 'DELETE';
    this.props.getS3URL({
      fileName,
      methodType,
      isPermitAuto: true,
      successCb: (result) => cb(result),
      failureCb: () => {},
    });
    reversedRefFiles.splice(ind, 1);
    const updatedRefInfo = {
      ...this.state.referenceInformation,
      reference_files: reversedRefFiles.reverse(),
    };
    this.setState({ referenceInformation: updatedRefInfo });
  };

  handleFilesUpload = (event) => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' });

    let referenceFiles = this.state.referenceInformation.reference_files;

    const setStatus = (status) => {
      this.setState({ referenceFilesUploadStatus: status });
    };

    const setFilesSize = (filesTotalSize) => {
      this.setState({ referenceFilesSize: filesTotalSize });
    };

    const details = {
      event,
      setStatus,
      setFilesSize,
      documentType: 'referenceFiles',
      namePrefix: this.getFilePrefixName(this.state.basicDetails),
      uploadFiles: referenceFiles !== null ? referenceFiles : [],
      uploadFilesSize: this.state.referenceFilesSize,
      maxFileUploadSize: this.MAX_ALLOWED_FILE_SIZE,
      getS3URL: this.props.getS3URL,
      putToS3: this.props.putToS3,
      setDocumentUploadError: (errorDetails, status) => this.setDocumentUploadError(errorDetails, status),
    };
    if (!hasIn(this.state.errorData, 'state')) {
      uploadFileDetails(details, (uploadFiles) => {
        const { referenceInformation } = this.state;
        referenceInformation.reference_files = uploadFiles;
        this.setState({ referenceInformation });
      });
    }
  };

  handleFileDownload = (file) => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' });
    this.props.getS3URL({
      fileName: file.file_name,
      methodType: 'GET',
      isPermitAuto: true,
      successCb: (preSignedS3Url) => {
        window.open(preSignedS3Url, '_blank');
      },
      failureCb: () => {
        this.setState({
          referenceFilesUploadError: { error: true, msg: 'Unable to preview the file. Please try after some time.' },
        });
      },
    });
  };

  // Available Permit service Roles => :application_user, :application_admin, :db_user, :db_admin, :db_view, :service_admin, :no_access
  isButtonVisible(buttonName) {
    const { readOnly } = this.state;
    const { permitServiceRole, ahj } = this.props;
    const { current_status = '' } = ahj;
    let isVisible = false;
    const dbAdminWithPending =
      includes(['PENDING'], current_status) && includes(['db_admin', 'service_admin'], permitServiceRole);
    switch (buttonName) {
      case 'edit':
        isVisible = readOnly && !includes(VIEW_ONLY_ROLES, permitServiceRole);
        break;
      case 'approve':
      case 'reject':
        isVisible = includes(['PENDING'], current_status) && includes(['db_admin', 'service_admin'], permitServiceRole);
        break;
      case 'submit':
        isVisible =
          !dbAdminWithPending && !readOnly && includes(['db_admin', 'db_user', 'service_admin'], permitServiceRole);
        break;
      case 'save':
        isVisible =
          includes([null, '', 'INCOMPLETE'], current_status) &&
          includes(['db_admin', 'db_user', 'service_admin'], permitServiceRole);
        break;
      case 'outdated':
        isVisible =
          includes(['APPROVED'], current_status) && includes(['db_admin', 'service_admin'], permitServiceRole);
        break;
      default:
        break;
    }
    return isVisible;
  }

  isButtonDisabled(buttonName) {
    let isDisabled;
    switch (buttonName) {
      // case 'submit':
      // const { governingCodes: { irc, ifc }, customFiresetback } = this.state;
      // isDisabled = !customFiresetback &&
      //   (!irc.firesetbacks_defined || (isEmpty(irc.firesetbacks) && !ifc.firesetbacks_defined));
      // break;
      case 'outdated':
        const { readOnly } = this.state;
        isDisabled = !readOnly;
        break;
      default:
        isDisabled = false;
        break;
    }
    return isDisabled;
  }

  isReadOnly() {
    const { permitServiceRole, ahj } = this.props;
    const { current_status = '' } = ahj;
    return (
      includes(['APPROVED', 'PENDING', 'OUTDATED'], current_status) &&
      includes(['db_user', 'db_admin', 'service_admin'], permitServiceRole)
    );
  }

  getErrorText = (fieldName) => {
    return this.state.recordSubmitted ? hasIn(this.state.errorData, fieldName) : false;
  };

  getHelperText = (fieldName) => {
    return this.state.recordSubmitted ? get(this.state.errorData, fieldName) : '';
  };

  render() {
    const { classes, cityDataLoading, currentlySending, messageType, message } = this.props;
    const {
      id,
      basicDetails,
      ahjCityCountyDetails,
      referenceInformation,
      fieldUpdates,
      siteAndRoofPlan,
      notes,
      pvResidentialPermitDetails,
      requiredPlanNotes,
      firesetbacks,
      governingCodes,
      ahjGoverningCodesList,
      weatherData,
      permitOffice,
      permitSubmissionTracking,
      evPermitDetails,
      current_status,
      comments_list,
      readOnly,
      noRequirementsCheck,
      evPermitDetailsCheck,
      noDataAvailableCheck,
      displayOtherCodesCheck,
      displayNationalElectricalCodeCheck,
      showCityCheckbox,
      countyRequired,
    } = this.state;
    const {
      name = '',
      state = '',
      created_by = '',
      modified_by = '',
      verified_by = '',
      created_at = '',
      modified_at = '',
      verified_at = '',
    } = basicDetails;
    const pageHeaderText = isEqual(id, 'new') ? 'Add new AHJ' : name;
    const isNEC = !isEqual(this.state.governingCodes.nec.long_name, 'None')
      ? isEqual(this.state.governingCodes.nec.code_category[0].category_name, 'NATIONAL ELECTRICAL CODE')
      : false;

    return (
      <Box className={classes.root}>
        <Box className={classes.header}>
          <PageHeader text={pageHeaderText} />
          <CommentPopupBox comments={comments_list} applicationStatus={current_status} />
        </Box>
        <Box>
          <BasicDetailsSectionOldUI
            readOnly={readOnly}
            sectionLabel={'Basic Details'}
            errorData={this.state.errorData}
            basicDetails={basicDetails}
            checkBoxValue={evPermitDetailsCheck}
            ahjCityCountyDetails={ahjCityCountyDetails}
            handleBasicDetails={this.handleBasicDetails}
            handleClearCouties={this.handleClearCouties}
            showCityCheckbox={showCityCheckbox}
            countyRequired={countyRequired}
            handleCityCheckbox={this.handleCityCheckbox}
          />

          <ReferenceInformationSectionOldUI
            readOnly={readOnly}
            errorData={this.state.errorData}
            basicDetails={this.state.basicDetails}
            sectionLabel={'Reference Information'}
            referenceInformation={referenceInformation}
            handleReferenceInformation={this.handleReferenceInformation}
            referenceFilesUploadError={this.state.referenceFilesUploadError}
            handleFilesUpload={(e) => this.handleFilesUpload(e)}
            handleFileDownload={this.handleFileDownload}
            fileIcon={this.fileIcon}
            deleteFile={this.deleteFile}
          />

          <NotesSectionOldUI
            handleNotes={this.handleNotes}
            notes={notes}
            readOnly={readOnly}
            stateSelected={!isEmpty(state)}
            sectionLabel={'Notes'}
          />

          <PVResidentialPermitDetailsSectionOldUI
            errorData={this.state.errorData}
            handlePVResidentialPermitDetails={this.handlePVResidentialPermitDetails}
            pvResidentialPermitDetails={pvResidentialPermitDetails}
            isRequired={!noRequirementsCheck && !noDataAvailableCheck}
            readOnly={readOnly}
            stateSelected={!isEmpty(state)}
            panelLabel={'PV Residential Permit Details'}
          />

          <RequiredPlanSetNotesSectionOldUI
            handleRequiredPlanNotes={this.handleRequiredPlanNotes}
            requiredPlanNotes={requiredPlanNotes}
            readOnly={readOnly}
            stateSelected={!isEmpty(state)}
            sectionLabel={'Required Plan-Set Notes'}
          />

          <GoverningCodesSectionOldUI
            errorData={this.state.errorData}
            handleGoverningCodes={this.handleGoverningCodes}
            getFireSetBacks={this.getFireSetBacks}
            handleGetGoverningCodes={this.handleGetGoverningCodes}
            state={state}
            stateparams={this.state}
            governingCodes={governingCodes}
            ahjGoverningCodesList={ahjGoverningCodesList}
            noRequirementsCheck={noRequirementsCheck || noDataAvailableCheck}
            readOnly={readOnly}
            stateSelected={!isEmpty(state)}
            sectionLabel={'Governing Codes'}
            handleDisplayOtherCodesCheckbox={this.handleDisplayOtherCodesCheckbox}
            handleDisplayNECCheckbox={this.handleDisplayNECCheckbox}
            checkBoxOtherCodes={displayOtherCodesCheck}
            checkBoxNEC={displayNationalElectricalCodeCheck}
            isNEC={isNEC}
          />

          <FireSetbacksSectionOldUI
            errorData={this.state.errorData}
            handleFireSetbacks={this.handleFireSetbacks}
            firesetbacks={firesetbacks}
            preventDefault={preventDefault}
            readOnly={readOnly}
            stateSelected={!isEmpty(state)}
            sectionLabel={'Fire Setbacks'}
            customFiresetback={this.state.customFiresetback}
          />

          <WeatherDataSectionOldUI
            errorData={this.state.errorData}
            handleWeatherData={this.handleWeatherData}
            weatherData={weatherData}
            readOnly={readOnly}
            stateSelected={!isEmpty(state)}
            sectionLabel={'Weather Data'}
          />

          <PermitOfficeSectionOldUI
            errorData={this.state.errorData}
            handlePermitOffice={this.handlePermitOffice}
            permitOffice={permitOffice}
            readOnly={readOnly}
            stateSelected={!isEmpty(state)}
            sectionLabel={'Permit Office'}
          />

          {evPermitDetailsCheck && (
            <EVPermitDetailsSectionOldUI
              getErrorText={this.getErrorText}
              getHelperText={this.getHelperText}
              handleEVPermitDetails={this.handleEVPermitDetails}
              evPermitDetails={evPermitDetails}
              readOnly={readOnly}
              stateSelected={!isEmpty(state)}
              panelLabel={'EV Permit Details'}
            />
          )}

          {!includes([null, '', 'INCOMPLETE'], current_status) && (
            <RecordDetailsSectionOldUI recordDetails={basicDetails} />
          )}
        </Box>

        <Box className={classes.actionButtons}>
          <Box>
            <Button id="companyCancel" variant="outlined" color="primary" onClick={() => this.handleCancel()}>
              {'Back'}
            </Button>
          </Box>
          <Box>
            {APPLICATION_STATE_TRANSITIONS.map(
              (transition) =>
                this.isButtonVisible(transition.key) && (
                  <Tooltip
                    key={'status' + transition.key}
                    title="Any changes made by you will be lost"
                    arrow
                    disableHoverListener={transition.key !== 'reject'}
                    disableFocusListener={transition.key !== 'reject'}
                    disableTouchListener={transition.key !== 'reject'}
                    placement="top"
                  >
                    <Button
                      id={transition.key}
                      className={`${transition.key}Button`}
                      variant="contained"
                      color="primary"
                      disabled={this.isButtonDisabled(transition.key)}
                      onClick={() => this.handleSubmit(transition.value)}
                      key={transition.key}
                      style={{ marginLeft: 4, color: 'white' }}
                    >
                      {transition.title}
                    </Button>
                  </Tooltip>
                )
            )}
            <CommentDialog
              open={this.state.openCommentDialog}
              onClose={this.handleCommentDialogClose}
              dialogHeader={`${this.commentDialogHeader} Record`}
              secondaryHeaderText={`Do you want to ${this.commentDialogSecondaryHeader} this record?`}
              additionalSecondaryHeaderText={this.event === 'REJECTED' && REJECT_DIALOG_WARNING}
              handleProceed={this.handleProceed}
              setComment={(e) => this.handleAddComment(e)}
            />
          </Box>
        </Box>
        {(currentlySending || cityDataLoading || this.state.referenceFilesUploadStatus !== 'idle') && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {message && messageType === 'success' && <SnakBar message={message} severity={messageType} />}
        {message && messageType === 'error' && <SnakBar message={message} severity={messageType} />}
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createAHJ: (payload) => dispatch(createAHJ(payload)),
  getAHJ: (payload) => dispatch(getAHJ(payload)),
  getAHJCity: (payload) => dispatch(getAHJCity(payload)),
  getAHJNameValidation: (payload) => dispatch(getAHJNameValidation(payload)),
  getAHJGoverningCodes: (payload) => dispatch(getAHJGoverningCodes(payload)),
  getAHJDefaultFiresetbacks: (payload) => dispatch(getAHJDefaultFiresetbacks(payload)),
  getAHJDefaultAllFiresetbacks: (payload) => dispatch(getAHJDefaultAllFiresetbacks(payload)),
  getS3URL: (payload) => dispatch(getS3URL(payload)),
  putToS3: (payload) => dispatch(putToS3(payload)),
});

const mapStateToProps = (state) => ({
  message: state.adminReducer.message,
  cityDataLoading: state.adminReducer.cityDataLoading,
  currentlySending: state.adminReducer.currentlySending,
  messageType: state.adminReducer.messageType,
  permitServiceRole: state.appReducer.permitServiceRole,
  ahj: state.adminReducer.ahj,
  ahjCityCountyDetails: state.adminReducer.ahjCityCountyDetails,
  ahjGoverningCodesList: state.adminReducer.ahjGoverningCodesList,
  isAHJNameUnique: state.adminReducer.isAHJNameUnique,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
