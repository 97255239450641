import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Label } from '../../common/Label';
import {
  FormControl,
  Select,
  MenuItem,
  Box,
  Input,
  Button,
  Typography,
  Divider,
  FormHelperText,
  TextField,
} from '@material-ui/core';
// import { IconButton, Typography } from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PanelTemplate from '../../common/PanelTemplate';
import { useState } from 'react';
import { clone, isEqual, get, isEmpty, cloneDeep } from '../../../utils/lodash';
import { connect } from 'react-redux';
import { ENPHASE_MANUFACTURER_SG_ID } from '../../../containers/Permitting/constants';
import { isPermitSetAvailable } from '../../../utils/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2.5),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  headerWrapper: {
    display: 'inline-flex',
    padding: theme.spacing(1),
    background: '#F1F1F1 0% 0% no-repeat padding-box',
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(50),
      width: '100%',
    },
  },
  equipmentWrapper: {
    margin: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  equipmentItems: {
    width: theme.spacing(35),
    display: 'flex',
    alignItems: 'center',
    '& .MuiSelect-select:focus': {
      background: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    '& .MuiSelect-selectMenu': {
      overflow: 'auto',
      minHeight: 'auto',
      whiteSpace: 'initial',
      textOverflow: 'initial',
    },
  },
  equipmentItemLocation: {
    width: theme.spacing(35),
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(25),
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  equipmentItemsHeading: {
    width: theme.spacing(35),
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  equipmentItemWrapper: {
    display: 'inline-flex',
    borderBottom: '1px solid #70707036',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(50),
      width: '100%',
    },
  },
  equipmentLocation: {
    width: '100%',
  },
  equipmentDropDownLocation: {
    borderBottom: '1px solid black',
  },
  textFieldStyle: {
    width: '43.5%',
  },
  // removeButton: {
  //   padding: theme.spacing(0),
  //   marginLeft: theme.spacing(5),
  //   opacity: 0.5,
  //   '&:hover': {
  //     backgroundColor: theme.palette.secondary.main
  //   },
  //   '& svg': {
  //     width: theme.spacing(2.5),
  //     height: theme.spacing(2.5)
  //   }
  // },
  // addNewItems: {
  //   fontSize: 14,
  //   color: '#008EEF',
  //   display: 'inline-block',
  //   margin: theme.spacing(0, 2.5),
  //   cursor: 'pointer'
  // },
  saveButton: {
    height: theme.spacing(5),
    margin: theme.spacing(2.5, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2.5),
    },
  },
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  disableDropdown: {
    pointerEvents: 'none',
  },
  saveActive: {
    color: theme.palette.primary.white,
  },
  recentStyle: {
    color: theme.palette.primary.grey,
    marginLeft: theme.spacing(2),
  },
}));

const SelectDropdown = (props) => {
  const classes = useStyles();
  const {
    className,
    id,
    value = '',
    isRequired,
    handleChange,
    optionList = [],
    isEdit,
    paymentStatus,
    showRecent = false,
    mostUsedList = [],
    helperText,
    error = false,
  } = props;
  const modifiedOptionList = optionList;
  if (value && !modifiedOptionList.includes(value)) {
    if (paymentStatus) {
      modifiedOptionList.push(value);
    }
    // else {
    //   handleChange('', id);
    // }
  }
  return (
    <FormControl className={clsx(className, !isEdit && classes.disableDropdown)} error={error}>
      <Select
        id={`${id}-select`}
        value={value.toString()}
        className={isEdit ? classes.equipmentDropDownLocation : ""}
        IconComponent={(props) => (isEdit ? <ExpandMoreIcon className="MuiSelect-icon" /> : <Fragment />)}
        input={<Input disableUnderline />}
        onChange={(e) => handleChange(e.target.value, id)}
        required={isRequired}
        inputProps={{ readOnly: !isEdit }}
      >
        {showRecent && modifiedOptionList.length > 5 && <Typography className={classes.recentStyle}>Recent</Typography>}
        {showRecent &&
          modifiedOptionList.length > 5 &&
          mostUsedList.map((value, index) => {
            if (value.length === 0) {
              return null;
            }
            return (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            );
          })}
        {showRecent && modifiedOptionList.length > 5 && <Divider variant="middle" />}
        {modifiedOptionList &&
          modifiedOptionList.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

function EquipmentLocation(props) {
  const classes = useStyles();
  const {
    materialDetails = {},
    userSelectionData,
    handleChange,
    paymentStatus,
    projectDetails,
    applicationDetail,
    isFrom,
    mostUsedValues,
  } = props;
  const permitFilesAvailable = isPermitSetAvailable(applicationDetail);
  let storageType = cloneDeep(projectDetails.system_types.find((type) => type.name === 'Storage'));
  if (isFrom === 'project-details') {
    storageType.value = true;
  }
  const { equipment_location = [], errors = {} } = materialDetails;
  const {
    //location_item: itemOptions = [],
    location: locationOptions = [],
    all_locations: allLocationOptions,
    equipment_locations: defaultEquipmentLocations,
  } = userSelectionData;
  const modifiedItemOptions = equipment_location.map((obj) => obj.item);
  modifiedItemOptions.push('Storage Location');
  modifiedItemOptions.push('System Controller Location');
  const [isEdit, setIsEdit] = useState(false);
  const [tempData, setTempData] = useState(clone(equipment_location));
  const [isDataChanged, setIsDataChanged] = useState(false);

  useEffect(() => {
    if (!isDataChanged && !isEqual(tempData, equipment_location)) {
      setTempData(clone(equipment_location));
    }
    if (isFrom === 'project-details') {
      setTempData(userSelectionData.equipment_locations);
      if (!isDataChanged) {
        handleChange(tempData, 'equipment_location');
      }
    }
  }, [equipment_location, tempData, isDataChanged]);

  useEffect(() => {
    if (!isEmpty(equipment_location)) {
      const isEnchargeLocationEmpty = isEmpty(equipment_location.find((list) => list.item === 'Encharge Location'));
      const isEnpwerLocationEmpty = isEmpty(equipment_location.find((list) => list.item === 'Enpower Location'));
      const enchargeLocation = defaultEquipmentLocations.find((list) => list.item === 'Encharge Location');
      const enpowerLocation = defaultEquipmentLocations.find((list) => list.item === 'Enpower Location');
      if (storageType.value) {
        let dataPushed = false;
        const data = clone(equipment_location);
        if (isEnchargeLocationEmpty && !isEmpty(enchargeLocation)) {
          data.push(enchargeLocation);
          dataPushed = true;
        }
        if (isEnpwerLocationEmpty && !isEmpty(enpowerLocation)) {
          data.push(enpowerLocation);
          dataPushed = true;
        }
        dataPushed && handleChange(data, 'equipment_location');
      }
    }
  }, [defaultEquipmentLocations, equipment_location, handleChange, storageType]);

  useEffect(() => {
    setIsEdit(false);
  }, [materialDetails]);

  // const handleAddItem = () => {
  //   let data = [];
  //   data = [ ...tempData, { item: '', location: '' } ];
  //   setTempData(data);
  // };

  // const handleRemoveItem = index => {
  //   const data = clone(tempData);
  //   data.splice(index, 1);
  //   setTempData(data);
  // };

  const handleChangeEquipment = (value, key, index) => {
    const data = clone(tempData);
    data[index][key] = value;
    setTempData(data);
    !isDataChanged && setIsDataChanged(true);
  };
  const handleSave = () => {
    handleChange(tempData, 'equipment_location');
    setIsEdit(false);
  };

  const isEnphaseStorage = (materialDetails) => {
    let isEnphStorage = true;
    materialDetails &&
      materialDetails.storage_list &&
      materialDetails.storage_list.forEach((product) => {
        if (product.manufacturer && product.manufacturer.id !== ENPHASE_MANUFACTURER_SG_ID) {
          isEnphStorage = false;
        }
      });
    return isEnphStorage;
  };

  const equipmentContainer = clsx(classes.container, permitFilesAvailable && classes.disableForm);
  const isBackfeedOrDerate =
    get(props.materialDetails, 'interconnection_properties.interconnection_strategy', 'null') === 'Backfeed Breaker' ||
    get(props.materialDetails, 'interconnection_properties.interconnection_strategy', 'null') === 'Derate Main Breaker';

  if (
    ((applicationDetail.material_details == null ||
      isEmpty(get(applicationDetail.material_details, 'equipment_location', []))) &&
      !projectDetails.system_types[1].value &&
      isFrom !== 'project-details') ||
    isEmpty(tempData)
  ) {
    return <Fragment />;
  }
  const getErrorText = (key) => (get(errors, `${key}.error`) ? get(errors, `${key}.errorMessage`) : '');
  return (
    <div className={classes.root}>
      <PanelTemplate header="Location of Equipment" className={classes.panelContainer}>
        <Box className={equipmentContainer}>
          <Box className={classes.equipmentWrapper}>
            <Box className={classes.headerWrapper}>
              <Box className={classes.equipmentItemsHeading}>Item</Box>
              <Box className={classes.equipmentItemLocation}>Location</Box>
            </Box>
            {tempData.map((list, index) => {
              let { item, location } = list;
              const updatedLocationOptions = item.toLowerCase() === 'modules' ? locationOptions : allLocationOptions;
              const hideEnchargeEnpower =
                !storageType.value && (item === 'Enpower Location' || item === 'Encharge Location');
              const hideAcFused = isBackfeedOrDerate && item === 'AC Fused Disconnect';
              const hideAcNonFused = !isBackfeedOrDerate && item === 'AC Non-Fused Disconnect';
              if (hideEnchargeEnpower || hideAcFused || hideAcNonFused) {
                return <Fragment key={index} />;
              }
              const isEnphStorage = isEnphaseStorage(materialDetails);
              if (item === 'Enpower Location') {
                return <Fragment key={index} />;
              }
              if (item === 'Encharge Location') {
                item = 'Storage Location';
              }
              return (
                <Box id={'encharge_location'} className={classes.equipmentItemWrapper} key={`array-${index}`}>
                  <Box className={classes.equipmentItems}>
                    <TextField
                      id="item"
                      value={item}
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                      className={classes.textFieldStyle}
                    />
                    <Label className={classes.labelColor} isRequired />
                  </Box>
                  <Box className={clsx(classes.equipmentItems, classes.equipmentItemLocation)}>
                    <SelectDropdown
                      id="location"
                      className={classes.equipmentLocation}
                      value={location}
                      isRequired={true}
                      handleChange={(value, key) => handleChangeEquipment(value, key, index)}
                      optionList={updatedLocationOptions}
                      isEdit={isEdit}
                      paymentStatus={paymentStatus}
                      showRecent={true}
                      mostUsedList={mostUsedValues['interconnection_location']}
                      error={get(errors, `encharge_location.error`)}
                      helperText={getErrorText('encharge_location')}
                    />
                    {/* {isEdit && (
                      <IconButton className={classes.removeButton} onClick={() => handleRemoveItem(index)}>
                        <CloseIcon fontSize="large" />
                      </IconButton>
                    )} */}
                  </Box>
                </Box>
              );
            })}
          </Box>
          {isEdit ? (
            <Button
              className={clsx(classes.saveButton, classes.saveActive)}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => handleSave()}
            >
              Save
            </Button>
          ) : (
            <Button
              className={classes.saveButton}
              size="small"
              variant="outlined"
              color="primary"
              disableElevation
              onClick={() => setIsEdit(true)}
            >
              Edit Table
            </Button>
          )}
        </Box>
        {/* {isEdit && (
          <Typography component="div" onClick={() => handleAddItem()} className={classes.addNewItems}>
            + Add Item
          </Typography>
        )} */}
      </PanelTemplate>
    </div>
  );
}

const mapStateToProps = (state) => ({
  mostUsedValues: state.permitReducer.mostUsedValues,
});

export default connect(mapStateToProps)(EquipmentLocation);
