import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    fontSize: theme.spacing(1.7),
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(4.75),
    textTransform: 'none',
    padding: theme.spacing(0, 3),
    border: '1px solid',
    borderColor: theme.palette.tertiary.buttonColor,
    backgroundColor: theme.palette.tertiary.buttonColor,
    color: theme.palette.tertiary.main,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#d15d13',
    },
    minWidth: 'auto',
  },
  disableStyle: {
    opacity: 0.8,
  },
  adminActionButtons: {
    width: '100%',
    fontSize: theme.spacing(1.7),
    overflow: 'auto',
    textOverflow: 'ellipsis',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    marginTop: theme.spacing(1)
  },
  smaller: {
    fontSize: theme.spacing(1.7),
    padding: theme.spacing(0, 2),
    height: theme.spacing(4),
  },
  disabledButton: {
    color: theme.palette.primary.lightBlack,
    backgroundColor: theme.palette.primary.disabled,
    border: 'none'
  }
}));

/**
 * MUI Button with custom contained styling
 * @param props
 * @returns a MUI button with contained styles
 */
export const ContainedButton = (props) => {
  const classes = useStyles();
  const { text, size = 'small', handleClick, disabled = false, adminActionButtons, smaller } = props;
  const classNames = clsx(
    classes.buttonStyle,
    disabled && classes.disableStyle,
    adminActionButtons && classes.adminActionButtons,
    smaller && classes.smaller
  );
  return (
    <Button onClick={(e) => handleClick(e)} size={size} className={classNames} disabled={disabled} disableRipple classes={{ disabled: classes.disabledButton }}>
      {text}
    </Button>
  );
};

export default ContainedButton;
