import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import {Tooltip} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import { get, isEmpty } from 'lodash';
import SnakBar from '../../../SnakBar';
import queryString from 'query-string';
import AddOrange from '../../images/AddOrange';
import clsx from 'clsx';
import moment from 'moment';

import {
  Typography,
  Grid,
  Box,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress
} from '@material-ui/core';
import {
  getS3URL,
  putToS3,
  showHideLoader,
  sendStampingRequest,
  reworkRequest,
  filesDownloaded,
  toOrderStamps
} from '../../../../containers/Permitting/action';
import ReworkDialog from '../../ReworkDialog';
import Add from '../images/Add';
import ViewApplication from '../images/ViewApplication';
import Forward from '../images/Forward';
import Stamp from '../images/Stamp';
import Solid from '../images/Solid';
import FeedbackDialog from '../../FeedbackDialog';
import PageHeader from '../../../common/PageHeader';
import { APPLICATION_STATES, INSTALLER_STATES } from '../../../../utils/constants';
import { containsStatus, isPermitSetAvailable } from '../../../../utils/helper';
import { areAllServicesPurchased } from '../../../../containers/Permitting/helper';
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  panelHead: {
    minHeight: 'auto !important',
    padding: theme.spacing(1, 2),
    background: '#FAFAFA',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    }
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    color: '#F37320',
    background: '#FAFAFA'
  },
  buttons: {
    fontSize: 16,
    cursor: 'pointer',
    textAlign: 'center'
  },
  highlightedColor: {
    color: '#016AE3'
  },
  disabledColor: {
    color: '#919191',
    pointerEvents: 'none'
  },
  buttonWrapper: {
    margin: theme.spacing(0, 2.5, 2.5),
    padding: theme.spacing(1, 2)
  },
  subHeading: {
    fontSize: '12px',
    color: '#9F9F9F',
    textAlign: 'center'
  },
  boxWrapper: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #00000029',
    height: '186px'
  },
  boxWrapperHalf: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #00000029',
    height: '145px',
    flex: 0.5
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  bottomPadding: {
    paddingBottom: theme.spacing(2)
  },
  bottomMargin: {
    marginBottom: theme.spacing(2)
  },
  rightMargin: {
    marginRight: theme.spacing(2)
  },
  displayFlexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexDirectionColumn: {
    flexDirection: 'column'
  },
  superscript: {
    fontSize: '10px',
    letterSpacing: '0px',
    color: '#FFFFFF',
    background: '#FF7141 0% 0% no-repeat padding-box',
    borderRadius: '5px',
    padding: '2px'
  },
  buttonFeedback: {
    color: '#016AE3',
    textTransform: 'none',
    marginTop: theme.spacing(1),
    marginBottom: '17px',
    fontSize: '12px',
    border: '1px solid #016AE3',
    '&:hover': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: 'none'
    }
  },
  panelWrapper: {
    maxHeight: '530px',
    minHeight: '530px',
    overflow: 'auto'
  },
  columnFlex: {
    flexDirection: 'column'
  },
  fullWidth: {
    width: '100%'
  },
  additionalPermitButton: {
    color: '#F37320',
    border: '1px solid #F37320',
    backgroundColor: 'white',
    borderRadius: 0,
    height: theme.spacing(3),
    fontSize: 12,
    textTransform: 'none',
    '& svg': {
      width: theme.spacing(2),
      height: theme.spacing(2)
    },
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  maxWidthBox: {
    maxWidth: '528px',
    marginRight: 'auto',
    marginLeft: 'auto'
  },

  contentColor: {
    color: '#4B4B4B',
    fontSize: theme.spacing(2)
  },
  fontSize: {
    fontSize: theme.spacing(3)
  },
  buttonDialogWrapper: {
    justifyContent: 'start',
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  button: {
    color: theme.palette.primary.white,
    height: theme.spacing(4.375),
    borderRadius: 0,
    width: theme.spacing(14.5)
  },
  cancelButton: {
    color: '#4B4B4B'
  },
  disabledBorder: {
    border: '1px solid rgba(0,0,0,0.26)'
  },
  disablePointerEvents: {
    pointerEvents: 'none'
  },
  expansionPanel: {
    boxShadow: 'none',
    borderRadius: '0px !important',
    border: '1px solid #7070701f'
  },
  fileName: {
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(0.4)
  },
  permitFilesTooltip: {
    marginTop: theme.spacing(0.1),
  },
  permitFilesTooltipHeader: {
    display: 'flex'
  },
  fileNameTooltip: {
    color: '#016AE3',
    textDecorationLine: 'underline',
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(0.4)
  },
  dateText: {
    fontSize: theme.spacing(1.5)
  },
  panelHeadContent: {
    margin: '0px !important'
  }
});

const Actions = props => {
  const {
    classes,
    applicationDetail,
    requiredServices,
    reworkServices,
    purchasedServices,
    purchasedReworkServices,
    permitId,
    getS3URL,
    reworkRequest,
    viewPermitApplication,
    isAddServicesClicked,
    getSelectedReworkServices,
    messageList,
    putToS3,
    showHideLoader,
    sendStampingRequest,
    feedback,
    latestFeedback,
    isMobile,
    breadcrumbPath,
    fileDownloadStatus,
    filesDownloaded,
    toOrderStamps
  } = props;

  const [ isMobileView, setIsMobileView ] = useState(isMobile);
  const useWindowSize = () => {
    useEffect(() => {
      function handleResize() {
        if (document.getElementById('actionGrid')) {
          if (document.getElementById('actionGrid').offsetWidth < 370) {
            setIsMobileView(true);
          } else {
            setIsMobileView(false);
          }
        }
      }
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  };

  useWindowSize();
  const [ showDialog, toggleDialog ] = useState(false);
  const [showMessage, toggleShowMessage] = useState(false);
  const handleSuccess = () => {
    getSelectedReworkServices({ permitId });
    toggleShowMessage(true);
    toggleDialog(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  let enableDownloadPermit = false;
  let installerStatuses = [];
  let thirdPartyStatuses = [];
  const isPermitFilesDownloaded = get(applicationDetail, 'third_party_current_status.state_id', 0) >= 7;
  const isMoreInfoReceived = get(applicationDetail, 'third_party_current_status.state_id', 0) === 11;
  const [ showShareFeedbackButton, setShowShareFeedbackButton ] = useState(false);
  const [ isDownloadPermitFeedbackGiven, setIsDownloadPermitFeedbackGiven ] = useState(false);
  const [ sendingPackageForStamping, setSendingPackageForStamping ] = useState(false);
  const permitSetAvailable = isPermitSetAvailable(applicationDetail);

  const closeDialog = () => {
    toggleDialog(false);
  }

  useEffect(
    () => {
      feedback.map(ele => {
        if (ele.application_state > 1) {
          setIsDownloadPermitFeedbackGiven(true);
        }
        return <Fragment />;
      });
    },
    [ feedback ]
  );

  useEffect(
    () => {
      if (!isEmpty(latestFeedback) && latestFeedback.application_state > 1) {
        setIsDownloadPermitFeedbackGiven(true);
      }
    },
    [ latestFeedback ]
  );

  useEffect(
    () => {
      if (permitSetAvailable && !isDownloadPermitFeedbackGiven) {
        setShowShareFeedbackButton(true);
      }
      if (isDownloadPermitFeedbackGiven) {
        setShowShareFeedbackButton(false);
      }
    },
    [ isPermitFilesDownloaded, isDownloadPermitFeedbackGiven, latestFeedback, feedback,permitSetAvailable ]
  );

  const [ showFeedbackDialog, setFeedbackDialog ] = useState(false);
  const [ additionalPermitDialog, setAdditionalPermitDialog ] = useState(false);
  const removeShareFeedbackButton = () => {
    setShowShareFeedbackButton(false);
    setIsDownloadPermitFeedbackGiven(true);
  };
  const closeFeebackDialog = () => {
    setFeedbackDialog(false);
  };
  if (applicationDetail) {
    installerStatuses = applicationDetail.installer_statuses || [];
    thirdPartyStatuses = applicationDetail.third_party_statuses || [];
  }

  if ((get(applicationDetail, 'permit_files', []) || []).length > 0 && installerStatuses.length >= 3 && !isMoreInfoReceived) {
    enableDownloadPermit = true;
  }
  const installerCurrentStateId = applicationDetail &&
  !isEmpty(applicationDetail.installer_current_status) &&
  applicationDetail.installer_current_status.state_id;
  const enableReworkServices = containsStatus(installerStatuses, INSTALLER_STATES.PERMIT_SET_AVAILABLE.id);
  const enablePermitStamping =
    (installerCurrentStateId === INSTALLER_STATES.PERMIT_SET_AVAILABLE.id || installerCurrentStateId === INSTALLER_STATES.REWORK_SUBMITTED.id) &&
    (purchasedServices.includes('Electrical Review') || purchasedServices.includes('Structural Review'));
  const isSiteInfoDownloaded = thirdPartyStatuses.length > 1;

  const getFileUrl = (name, cb) => {
    getS3URL({
      fileName: name,
      methodType: 'GET',
      successCb: async preSignedS3Url => {
        window.open(preSignedS3Url, '_blank');
        cb();
      },
      failureCb: () => {
        fileDownloadStatus(false);
      }
    });
  };

  const downloadPermitFiles = (permitFiles) => {
    if (!permitFiles || permitFiles.length === 0) {
      if (!isDownloadPermitFeedbackGiven) {
        setFeedbackDialog(true);
      }
      fileDownloadStatus(false);
      return;
    }
    getFileUrl(permitFiles[permitFiles.length - 1].file_name, () => {
      permitFiles.pop();
      downloadPermitFiles(permitFiles);
    });
  }

  const handleDownloadPermit = () => {
    fileDownloadStatus(true);
    const canUpdateState = get(applicationDetail, 'third_party_current_status.state_id', 0) === APPLICATION_STATES.QA_COMPLETED.id;
    canUpdateState && filesDownloaded({ permitId });
    const permitFiles = get(applicationDetail, 'permit_files', []).filter(doc => doc.doc_type && doc.doc_type === 'PERMIT_PACKAGE');
    permitFiles.length > 0 && downloadPermitFiles(permitFiles);
  };

  const redirectToApplication = () => {
    viewPermitApplication(true);
  };

  const jumpToServicesPage = () => {
    viewPermitApplication(true);
    isAddServicesClicked(true);
    toOrderStamps(true);
  };

  const fromIframe = () => {
    const params = get(props, 'history.location.search') || '';
    const { origin = 'self' } = queryString.parse(params);
    return origin;
  };

  const startNewApplication = () => {
    const { searchParam = '' } = props;
    const params = searchParam ? `${searchParam}&origin=${fromIframe()}` : `?origin=${fromIframe()}`;
    window.location = `/manager/${breadcrumbPath}/${get(
      props,
      'applicationDetail.site_id'
    )}/permitting-assistance/new${params}`;
  };

  const areAllPermitServicesPurchased = false;
  const permitFilesLength = isEmpty(applicationDetail.permit_files) ? null : applicationDetail.permit_files.length;
  const versionText =  permitFilesLength ? permitFilesLength == 1 ? 'Version : First permit package' : 'Version : Updated permit package ' + String(permitFilesLength - 1) : '';
  const LatestPermitFile =  permitFilesLength ? applicationDetail.permit_files[permitFilesLength - 1].file_name : '';
  const fileDate = permitFilesLength ? moment(applicationDetail.permit_files[permitFilesLength - 1].added_date).format('DD MMM YYYY  HH:mm A') : '';

  return (
    <Fragment>
      <Grid container direction="row" id="actionGrid">
        <Grid item xs={12}>
          <Accordion expanded={true} className={classes.expansionPanel}>
            <AccordionSummary
              className={clsx(classes.panelHead, !isSiteInfoDownloaded && classes.disablePointerEvents)}
              classes={{ content: classes.panelHeadContent }}
            >
              <div className={clsx(classes.displayFlex, classes.fullWidth)}>
                <Typography className={classes.heading}>Actions</Typography>

                <Button
                  disabled={!isSiteInfoDownloaded}
                  className={clsx(classes.additionalPermitButton, !isSiteInfoDownloaded && classes.disabledBorder)}
                  onClick={() => setAdditionalPermitDialog(true)}
                >
                  <AddOrange disabled={!isSiteInfoDownloaded} />
                  Additional Permit
                </Button>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.columnFlex}>
              <div className={clsx(!isMobileView && classes.panelWrapper)}>
                <Box className={clsx(classes.bottomPadding, classes.maxWidthBox)}>
                  <Paper variant="outlined" className={clsx(classes.boxWrapper, classes.displayFlexCenter)}>
                    <Box className={clsx(classes.displayFlexCenter, classes.flexDirectionColumn)}>
                      <IconButton disableRipple disabled={!enableDownloadPermit} onClick={() => handleDownloadPermit()}>
                        <Solid disabled={!enableDownloadPermit} />
                      </IconButton>
                      <Typography
                        className={clsx(
                          classes.buttons,
                          enableDownloadPermit ? classes.highlightedColor : classes.disabledColor
                        )}
                        onClick={() => handleDownloadPermit()}
                      >
                        Download Permit files{' '}
                        {applicationDetail && applicationDetail.stamped && <sup className={classes.superscript}> stamped </sup>}
                      </Typography>
                      {permitFilesLength && <Box>
                        <Tooltip className={classes.permitFilesTooltip} title={LatestPermitFile} placement="right">
                          <Box className={classes.permitFilesTooltipHeader}>
                            <Box className={classes.fileName}>{versionText} </Box>
                            <Box className={classes.fileNameTooltip}>{'[Doc]'}</Box>
                          </Box>
                        </Tooltip>
                      <Typography className={classes.dateText}>
                        {'Uploaded Date : '}{fileDate}
                      </Typography>
                      </Box>}
                      {applicationDetail && applicationDetail.stamped && (
                        <Typography className={classes.subHeading}>Permit package has been stamped</Typography>
                      )}
                      {showShareFeedbackButton && (
                        <Button
                          variant="outlined"
                          disableRipple
                          className={classes.buttonFeedback}
                          onClick={() => {
                            setFeedbackDialog(true);
                          }}
                        >
                          Share your feedback
                        </Button>
                      )}
                    </Box>
                  </Paper>
                </Box>

                <Box
                  className={clsx(
                    !isMobileView && classes.displayFlex,
                    !isMobileView && classes.bottomPadding,
                    classes.maxWidthBox
                  )}
                >
                  <Paper
                    variant="outlined"
                    className={clsx(
                      classes.boxWrapperHalf,
                      !isMobileView && classes.rightMargin,
                      classes.displayFlexCenter,
                      isMobileView && classes.bottomMargin
                    )}
                  >
                    <Box className={clsx(classes.displayFlexCenter, classes.flexDirectionColumn)}>
                      <IconButton disabled={!enableReworkServices} onClick={() => toggleDialog(true)} disableRipple>
                        <Forward disabled={!enableReworkServices} />
                      </IconButton>
                      <Typography
                        onClick={() => toggleDialog(true)}
                        className={clsx(
                          classes.buttons,
                          enableReworkServices ? classes.highlightedColor : classes.disabledColor
                        )}
                      >
                        Submit Request for Correction
                      </Typography>
                    </Box>
                  </Paper>

                  <Paper
                    variant="outlined"
                    className={clsx(
                      classes.boxWrapperHalf,
                      classes.displayFlexCenter,
                      isMobileView && classes.bottomMargin
                    )}
                  >
                    <Box className={clsx(classes.displayFlexCenter, classes.flexDirectionColumn)}>
                      <IconButton
                        disabled={!enablePermitStamping || sendingPackageForStamping}
                        onClick={() => {
                          setSendingPackageForStamping(true);
                          sendStampingRequest({ permitId: permitId, successCbk: () => window.location.reload() })
                        }}
                        disableRipple
                      >
                        <Stamp disabled={!enablePermitStamping || sendingPackageForStamping} />
                      </IconButton>
                      {sendingPackageForStamping && <CircularProgress size={15} color="primary" />}
                      <Typography
                        className={clsx(
                          classes.buttons,
                          enablePermitStamping && !sendingPackageForStamping ? classes.highlightedColor : classes.disabledColor
                        )}
                        onClick={() => {
                          setSendingPackageForStamping(true);
                          sendStampingRequest({ permitId: permitId, successCbk: () => window.location.reload() })
                        }}
                      >
                        Send Permit Package for Stamping
                      </Typography>
                    </Box>
                  </Paper>
                </Box>

                <Box
                  className={clsx(
                    !isMobileView && classes.displayFlex,
                    !isMobileView && classes.bottomPadding,
                    classes.maxWidthBox
                  )}
                >
                  <Paper
                    variant="outlined"
                    className={clsx(
                      classes.boxWrapperHalf,
                      !isMobileView && classes.rightMargin,
                      classes.displayFlexCenter,
                      isMobileView && classes.bottomMargin
                    )}
                  >
                    <Box className={clsx(classes.displayFlexCenter, classes.flexDirectionColumn)}>
                      <IconButton disabled={false} onClick={() => redirectToApplication()} disableRipple>
                        <ViewApplication disabled={false} />
                      </IconButton>

                      <Typography
                        className={clsx(classes.buttons, classes.highlightedColor)}
                        onClick={() => redirectToApplication()}
                      >
                        {permitSetAvailable ? 'View Application' : 'View/Edit Application'}
                      </Typography>
                    </Box>
                  </Paper>

                  <Paper
                    variant="outlined"
                    className={clsx(
                      classes.boxWrapperHalf,
                      classes.displayFlexCenter,
                      isMobileView && classes.bottomMargin
                    )}
                  >
                    <Box className={clsx(classes.displayFlexCenter, classes.flexDirectionColumn)}>
                      <IconButton
                        disabled={areAllPermitServicesPurchased}
                        onClick={jumpToServicesPage}
                        disableRipple
                      >
                        <Add disabled={areAllPermitServicesPurchased} />
                      </IconButton>

                      <Typography
                        className={clsx(
                          classes.buttons,
                          !areAllPermitServicesPurchased && classes.highlightedColor,
                          areAllPermitServicesPurchased && classes.disabledColor
                        )}
                        onClick={jumpToServicesPage}
                      >
                        Order Stamps
                      </Typography>

                      <Typography className={classes.subHeading}>e.g. Electrical/Structural Reviews</Typography>
                    </Box>
                  </Paper>
                </Box>
              </div>
              {showDialog && (
                <ReworkDialog
                  showDialog={showDialog}
                  handleSuccess={handleSuccess}
                  closeDialog={closeDialog}
                  messageList={messageList}
                  permitId={permitId}
                  getS3URL={getS3URL}
                  putToS3={putToS3}
                  showHideLoader={showHideLoader}
                  reworkRequest={reworkRequest}
                  version={applicationDetail?.version}
                />
              )}
              {showMessage && <SnakBar message="Your Re-Work request has been submitted." severity={'success'} />}
              {showFeedbackDialog && (
                <FeedbackDialog
                  isOpen={showFeedbackDialog}
                  content="Please rate the permit package that you received on how well it matches your requirements"
                  closeDialog={closeFeebackDialog}
                  isFrom="Permit Status"
                  removeShareFeedbackButton={removeShareFeedbackButton}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      {setAdditionalPermitDialog && (
        <Dialog open={additionalPermitDialog} onClose={() => setAdditionalPermitDialog(false)} fullWidth maxWidth="xs">
          <DialogTitle disableTypography>
            <PageHeader className={classes.fontSize} text={'Request Additonal Permit'} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.contentColor}>
              Are you sure you want to create a new application for requesting an additional permit package for the same
              system?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonDialogWrapper}>
            <Button
              onClick={() => startNewApplication()}
              color="primary"
              variant="contained"
              disableElevation
              className={classes.button}
            >
              Proceed
            </Button>
            <Button
              onClick={() => setAdditionalPermitDialog(false)}
              variant="contained"
              disableElevation
              className={clsx(classes.button, classes.cancelButton)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  getS3URL: payload => dispatch(getS3URL(payload)),
  filesDownloaded: payload => dispatch(filesDownloaded(payload)),
  putToS3: payload => dispatch(putToS3(payload)),
  showHideLoader: payload => dispatch(showHideLoader(payload)),
  sendStampingRequest: payload => dispatch(sendStampingRequest(payload)),
  reworkRequest: payload => dispatch(reworkRequest(payload)),
  toOrderStamps: payload => dispatch(toOrderStamps(payload))
});

const mapStateToProps = state => ({
  applicationDetail: state.permitReducer.applicationDetail,
  paymentStatus: state.permitReducer.paymentStatus,
  requiredServices: state.permitReducer.requiredServices,
  reworkServices: state.permitReducer.reworkServices,
  purchasedServices: state.permitReducer.purchasedServices,
  purchasedReworkServices: state.permitReducer.purchasedReworkServices,
  activeStep: state.permitReducer.activeStep,
  messageList: state.permitReducer.messageList,
  feedback: state.permitReducer.feedback,
  latestFeedback: state.permitReducer.latestFeedback
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Actions));
