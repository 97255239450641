import {
  GET_STRUCTURAL_USERS_REQUEST,
  GET_STRUCTURAL_USERS_SUCCESS,
  GET_STRUCTURAL_USERS_FAILURE,
  GET_STRUCTURAL_ASSEMBLY_RACK_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_RACK_SUCCESS,
  GET_STRUCTURAL_ASSEMBLY_RACK_FAILURE,
  GET_STRUCTURAL_ASSEMBLY_STANDOFF_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_STANDOFF_SUCCESS,
  GET_STRUCTURAL_ASSEMBLY_STANDOFF_FAILURE,
  GET_STRUCTURAL_ASSEMBLY_FILTERS_REQUEST,
  GET_ALL_STRUCTURAL_ASSEMBLY_REQUEST,
  GET_ALL_STRUCTURAL_ASSEMBLY_SUCCESS,
  GET_ALL_STRUCTURAL_ASSEMBLY_FAILURE,
  GET_STRUCTURAL_ASSEMBLY_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_SUCCESS,
  GET_STRUCTURAL_ASSEMBLY_FAILURE,
  CREATE_STRUCTURAL_ASSEMBLY_REQUEST,
  CREATE_STRUCTURAL_ASSEMBLY_SUCCESS,
  CREATE_STRUCTURAL_ASSEMBLY_FAILURE,
  GET_STRUCTURAL_FILE_DELETE_REQUEST,
  GET_STRUCTURAL_FILE_DELETE_SUCCESS,
  GET_STRUCTURAL_FILE_DELETE_FAILURE,
  GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_SUCCESS,
  GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_FAILURE,
  GET_STRUCTURAL_DELETE_REQUEST,
  GET_STRUCTURAL_DELETE_SUCCESS,
  GET_STRUCTURAL_DELETE_FAILURE,
  GET_ASSEMBLY_COM_REQUEST,
} from '../constants';

let initialState = {
  error: '',
  currentlySending: false,
  totalElements: 0,
  message: '',
  messageTitle: '',
  messageType: '',
  structural: {},
  structuralAssemblyList: [],
  racksList: [],
  usersList: [],
  standoffsList: [],
  rooftypesList: [],
  structuralAssemblyFilters: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_STRUCTURAL_USERS_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_STRUCTURAL_USERS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        usersList: action.usersList,
      };
    case GET_STRUCTURAL_USERS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_STRUCTURAL_ASSEMBLY_RACK_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_STRUCTURAL_ASSEMBLY_RACK_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        racksList: action.racksList,
      };
    case GET_STRUCTURAL_ASSEMBLY_RACK_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_STRUCTURAL_ASSEMBLY_STANDOFF_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_STRUCTURAL_ASSEMBLY_STANDOFF_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        standoffsList: action.standoffsList,
      };
    case GET_STRUCTURAL_ASSEMBLY_STANDOFF_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        rooftypesList: action.rooftypesList,
      };
    case GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_STRUCTURAL_ASSEMBLY_FILTERS_REQUEST:
      return {
        ...state,
        structuralAssemblyFilters: action.payload,
      };
    case GET_ALL_STRUCTURAL_ASSEMBLY_REQUEST:
      return {
        ...state,
        getAllStructuralAssemblyInProgress: true,
        messageType: '',
        message: '',
      };
    case GET_ALL_STRUCTURAL_ASSEMBLY_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        pagingData: action.pagingData,
        getAllStructuralAssemblyInProgress: false,
        structuralAssemblyList: action.structuralAssemblyList,
        structuralAssemblyTotalElements: action.totalElements || 0,
      };
    case GET_ALL_STRUCTURAL_ASSEMBLY_FAILURE:
      return {
        ...state,
        getAllStructuralAssemblyInProgress: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_STRUCTURAL_ASSEMBLY_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_STRUCTURAL_ASSEMBLY_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        structural: action.structural,
      };
    case GET_STRUCTURAL_ASSEMBLY_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case CREATE_STRUCTURAL_ASSEMBLY_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_ASSEMBLY_COM_REQUEST:
      return {
        ...state,
      };
    case CREATE_STRUCTURAL_ASSEMBLY_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
        structural: action.structural,
        // structuralAssemblyList: [...state.structuralAssemblyList, action.payload.structural],
      };
    case CREATE_STRUCTURAL_ASSEMBLY_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_STRUCTURAL_FILE_DELETE_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_STRUCTURAL_FILE_DELETE_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
      };
    case GET_STRUCTURAL_FILE_DELETE_FAILURE:
      return {
        ...state,
        currentlySending: false,
      };
    case GET_STRUCTURAL_DELETE_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_STRUCTURAL_DELETE_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
      };
    case GET_STRUCTURAL_DELETE_FAILURE:
      return {
        ...state,
        currentlySending: false,
      };
    default:
      return state;
  }
}

export default reducer;
