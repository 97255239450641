import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  dataSourceField: {
    marginTop: theme.spacing(6)
  }
}));

const NotesSectionOldUI = props => {
  const classes = useStyles();
  const { notes, readOnly, stateSelected, handleNotes, sectionLabel } = props;
  const {
    designer_instructions_general = '',
    designer_instructions_site_and_roof_plan = '',
    designer_instructions_electrical = '',
    miscellaneous_notes = ''
  } = notes;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box id="designer_instructions_general" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Designer Instructions (General)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={designer_instructions_general}
                onChange={e => handleNotes(e, 'designer_instructions_general')}
              />
            </Box>
            <Box id="designer_instructions_site_and_roof_plan" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Designer Instructions (Site & Roof Plan)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={designer_instructions_site_and_roof_plan}
                onChange={e => handleNotes(e, 'designer_instructions_site_and_roof_plan')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="designer_instructions_electrical" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Designer Instructions (Electrical)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={designer_instructions_electrical}
                onChange={e => handleNotes(e, 'designer_instructions_electrical')}
              />
            </Box>
            <Box id="miscellaneous_notes" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Miscellaneous Notes"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={miscellaneous_notes}
                onChange={e => handleNotes(e, 'miscellaneous_notes')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default NotesSectionOldUI;
