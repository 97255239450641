import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import { SERVICE_DETAILS } from '../constants';
import { DisplayGrid } from '../../../common/DisplayGrid';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
});

export class ServiceDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes, appDetails, originalApplicationDetails,handleUpdated,stateApplicationDetails } = this.props;
    const purchased_services = get(appDetails, 'purchased_service_names') || [];
    const purchased_rework_services = get(appDetails, 'purchased_rework_service_names') || [];
    const allServices = [ ...purchased_services, ...purchased_rework_services ];
    return (
      <Fragment>
        <Grid container direction="row" spacing={2}>
          {SERVICE_DETAILS.map((Item, index) => {
            return (
              <DisplayGrid
                appDetails={appDetails}
                item={Item}
                originalApplicationDetails={originalApplicationDetails}
                handleUpdated={handleUpdated}
                stateApplicationDetails={stateApplicationDetails}
              />
            );
          })}
        </Grid>
        <br />
      </Fragment>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ServiceDetailsView));
