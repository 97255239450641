import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PanelTemplate from '../PanelTemplate';
import { Typography, Box } from '@material-ui/core';
import { isEmpty } from '../../../utils/lodash';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: 'flex'
  },
  mandatoryWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  star: {
    color: '#C62E00'
  },
  mandatoryFeild: {
    color: theme.palette.primary.lightBlack,
    marginLeft: theme.spacing(0.5),
    fontSize: 14
  },
  helpIcon: {
    margin: theme.spacing(0.125, 0, 0, 1)
  }
}));

const PanelTemplateWithMandatory = props => {
  const classes = useStyles();
  const headerText = (
    <Box className={classes.headerWrapper}>
      <Typography>{props.header}</Typography>
      {!isEmpty(props.icon) && <Typography className={classes.helpIcon}>{props.icon}</Typography>}
      <Box className={classes.mandatoryWrapper}>
        <Typography className={classes.star}>*</Typography>
        <Typography className={classes.mandatoryFeild}>{'Mandatory fields'}</Typography>
      </Box>
    </Box>
  );
  return <PanelTemplate header={headerText}>{props.children}</PanelTemplate>;
};

export default PanelTemplateWithMandatory;
