import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Grid } from '@material-ui/core';
import { get } from 'lodash';
import { capitalizeFirstLetter } from '../../../../utils/helper';

const styles = theme => ({
  fontBold: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#999999',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(0.25)
  },
  dataSubHeader: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  bottomMargin: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1)
    }
  }
});

export class ApplicantDetailsView extends React.Component {
  render() {
    const { classes, projectDetails, siteDetails, applicationDetail } = this.props;
    const systemTypes = get(projectDetails, 'system_types', []);
    let systemType = '';
    if (systemTypes !== []) {
      if (systemTypes[0].value === true && systemTypes[1].value === true) {
        systemType = 'Solar with Storage';
      }
      if (systemTypes[0].value === false && systemTypes[1].value === true) {
        systemType = 'Storage';
      }
      if (systemTypes[0].value === true && systemTypes[1].value === false) {
        systemType = 'Solar';
      }
    }
    return (
      <Fragment>
        <Grid container direction="row" spacing={2} className={classes.bottomMargin}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className={classes.dataSubHeader}>Customer Name</Typography>
            <Typography className={classes.fontBold}>{get(projectDetails, 'customer_name') || '--'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className={classes.dataSubHeader}>System Name</Typography>
            <Typography className={classes.fontBold}>{get(projectDetails, 'system_name') || '--'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className={classes.dataSubHeader}>System Type</Typography>
            <Typography className={classes.fontBold}>{systemType}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className={classes.dataSubHeader}>Utility Provider </Typography>
            <Typography className={classes.fontBold}>{get(projectDetails, 'utility_provider') || 'NA'}</Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2} className={classes.bottomMargin}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className={classes.dataSubHeader}>City</Typography>
            <Typography className={classes.fontBold}>{get(projectDetails, 'city') || '--'}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography className={classes.dataSubHeader}>Email</Typography>
            <Typography className={classes.fontBold}>{get(siteDetails, 'email') || '--'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className={classes.dataSubHeader}>AHJ</Typography>
            <Typography className={classes.fontBold}>{get(projectDetails, 'ahj') || '--'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className={classes.dataSubHeader}>Project Type</Typography>
            <Typography className={classes.fontBold}>
              {capitalizeFirstLetter(get(projectDetails, 'project_type')) || '--'}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2} className={classes.bottomMargin}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography className={classes.dataSubHeader}>Requestor Name</Typography>
            <Typography className={classes.fontBold}>
              {get(applicationDetail, 'installer_user_name') || '--'}
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  projectDetails: state.permitReducer.projectDetails,
  siteDetails: state.permitReducer.siteDetails,
  applicationDetail: state.permitReducer.applicationDetail,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicantDetailsView));
