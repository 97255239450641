import React from 'react';
import clsx from 'clsx';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import { Label } from '../Label';
import { get, isEmpty } from '../../../utils/lodash';
import { selectMultipleRender } from '../../../containers/Admin/helper';

const useStyles = makeStyles(theme => ({
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

export const MultipleSelectDropdown = props => {
  const classes = useStyles();
  const {
    dropDownLabel,
    dropDownOptions,
    errorData,
    handleField,
    fieldId,
    fieldValue,
    readOnly,
    stateSelected = true,
    className = ''
  } = props;
  return (
    <FormControl id={fieldId} className={clsx(classes.inputField, className)} error={!isEmpty(get(errorData, fieldId))}>
      <InputLabel>
        <Label text={dropDownLabel} capitalizeLabelText={false} />
      </InputLabel>
      <Select
        value={fieldValue}
        onChange={e => handleField(e, fieldId)}
        input={<Input />}
        multiple
        renderValue={selected => selectMultipleRender(selected, dropDownOptions)}
        MenuProps={MenuProps}
        className={classes.multiSelect}
        id={fieldId}
        disabled={!stateSelected || readOnly}
      >
        {dropDownOptions.map((data, index) => {
          return (
            <MenuItem key={index} value={data.value} style={{ whiteSpace: 'normal' }}>
              <Checkbox className={classes.inputCheckbox} checked={fieldValue.indexOf(data.value) > -1} />
              <ListItemText primary={data.title} />
            </MenuItem>
          );
        })}
      </Select>
      {!isEmpty(get(errorData, fieldId)) && <FormHelperText>{get(errorData, fieldId)}</FormHelperText>}
    </FormControl>
  );
};

export default MultipleSelectDropdown;
