import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2.5, 1),
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5, 0)
    }
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2.5),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  header: {
    fontSize: 20,
    letterSpacing: 0,
    fontWeight: 'bold',
    color: '#233643'
  },
  paperRoot: {
    margin: theme.spacing(2),
    boxShadow: 'none',
    padding: theme.spacing(0, 0, 2, 0)
  },
  mandatoryWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: 'auto',
    marginBottom: 'auto',
    flexDirection: 'column'
  },
  star: {
    color: '#C62E00'
  },
  mandatoryFeild: {
    color: theme.palette.primary.lightBlack,
    marginLeft: theme.spacing(0.5),
    fontSize: 14
  },
  readOnlySecton: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  subText: {
    fontSize: 14,
    color: '#999999'
  },
  headerConatiner: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  displayFlex: {
    display: 'flex'
  }
}));

function PermitTemplate(props) {
  const classes = useStyles();
  const { children, title, mandatorySection = false, readOnly = false, subText = '' } = props;
  return (
    <div className={classes.root}>
      <Typography component="div" className={classes.headerWrapper}>
        <Typography component="div" className={classes.headerConatiner}>
          {title && (
            <Typography component="div" className={classes.header}>
              {title}
            </Typography>
          )}
          {subText && (
            <Typography component="div" className={classes.subText}>
              {subText}
            </Typography>
          )}
        </Typography>
        {mandatorySection && (
          <Typography component="div" className={classes.mandatoryWrapper}>
            <div className={classes.displayFlex}>
              <Typography className={classes.star}>*</Typography>
              <Typography className={classes.mandatoryFeild}>Mandatory fields</Typography>
            </div>
            {mandatorySection}
          </Typography>
        )}
      </Typography>
      <Paper
        square
        variant="outlined"
        className={readOnly ? clsx(classes.paperRoot, classes.readOnlySecton) : classes.paperRoot}
      >
        {children}
      </Paper>
    </div>
  );
}

export default PermitTemplate;
