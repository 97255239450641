import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const NoMatch = ({ location }) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Typography variant="h6" component="h3">
        No Match for <code> {location.pathname} </code>
      </Typography>
    </div>
  );
};

export default NoMatch;
