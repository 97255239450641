import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import { Button, Grid, Dialog, DialogTitle, DialogContent, IconButton, TextField } from '@material-ui/core';
import { Label } from '../../../components/common/Label';
import clsx from 'clsx';
import { get, union, isEqual, isEmpty } from 'lodash';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  getPanelManufacturerList,
  deleteTemplate,
  getTemplates,
  toggleTemplate,
  updateTemplate,
  setSelectedTemplate,
  setApplyTemplate,
  postTemplate,
  removeEmptyTemplate
} from '../action';
import EditTemplateIcon from './EditTemplateIcon';
import DeleteTemplateIcon from './DeleteTemplateIcon';
import CheckedPermitIcon from './CheckedPermitIcon';
import PageHeader from '../../../components/common/PageHeader';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiAccordionSummary-root:hover': {
      cursor: 'default'
    },
    '& .MuiAccordion-root': {
      boxShadow: 'none'
    },
    border: '1px solid #D3D3D3'
  },
  setPointerCursor: {
    cursor: 'pointer'
  },
  heading: {
    fontWeight: 'bold',
    flexShrink: 0,
    lineHeight: 3
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    cursor: 'unset !important'
  },
  templateBlock: {
    display: 'block',
    padding: theme.spacing(3, 0, 0)
  },
  lastTemplateBlock: {
    paddingBottom: theme.spacing(3)
  },
  firstTemplateBlock: {
    paddingTop: theme.spacing(0)
  },
  boldFontWeight: {
    fontWeight: 600
  },
  leftPadding: {
    paddingLeft: theme.spacing(2)
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  displayFlexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400
  },
  inputField: {
    width: '90%',
    '& .MuiInputLabel-shrink': {
      width: '200%'
    }
  },
  button: {
    fontSize: 15,
    borderRadius: '2px',
    textTransform: 'none',
    height: theme.spacing(5),
    width: theme.spacing(15),
    padding: theme.spacing(0, 3),
    border: '1px solid #F3731F',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'white'
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(10)
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(8),
      height: theme.spacing(4),
      fontSize: 13
    }
  },
  saveButton: {
    color: 'white',
    '&:hover': {
      backgroundColor: '#F3731F'
    }
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%'
    }
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  closeButton: {
    padding: 0
  },
  displayFlexButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3)
  },
  leftPaddingDialog: {
    paddingLeft: theme.spacing(4)
  },
  rightPadding: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0.5)
    }
  },
  buttons: {
    marginLeft: 'auto'
  },
  rightExtraPadding: {
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2)
    }
  },
  bottomMargin: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '50%'
    }
  },
  textEditColor: {
    fontSize: '14px',
    color: '#008EEF',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  textDeleteColor: {
    fontSize: '14px',
    color: '#EF212C',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  pipe: {
    borderLeft: '1px solid #D3D3D3;',
    height: theme.spacing(2.75)
  },
  selectedButton: {
    border: '1px solid #75CB00;',
    color: '#FFFFFF',
    boxShadow: 'none',
    backgroundColor: '#75CB00',
    '&:hover': {
      backgroundColor: '#75CB00',
      boxShadow: 'none'
    }
  },
  fontSize: {
    fontSize: theme.spacing(3)
  },
  templatesFieldContainer: {
    display: 'grid',
    marginTop: theme.spacing(3),
    gridRowGap: theme.spacing(2),
    gridColumnGap: theme.spacing(3),
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    padding: theme.spacing(0, 2)
  },
  hideInMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const SelectDropdown = props => {
  const { id, className, value = '', handleChange, optionList = [], labelText, classes } = props;

  return (
    <Fragment>
      <FormControl id={id} className={className}>
        {labelText && (
          <InputLabel id={`${id}-label`}>
            <Label className={classes.labelColor} text={labelText} />
          </InputLabel>
        )}

        <Select
          id={`${id}`}
          value={value}
          IconComponent={props => <ExpandMoreIcon className="MuiSelect-icon" />}
          onChange={e => handleChange(e.target.value, id)}
        >
          {optionList &&
            optionList.map((option, index) => {
              return (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Fragment>
  );
};

const TextInputField = props => {
  const { id, className, value = '', handleChange, labelText, classes } = props;

  return (
    <Fragment>
      <FormControl id={id} className={clsx(className)}>
        {labelText && (
          <InputLabel id={`${id}-label`}>
            <Label className={classes.labelColor} text={labelText} />
          </InputLabel>
        )}
        <Input
          value={value}
          onChange={e => {
            handleChange(e.target.value, id);
          }}
        />
      </FormControl>
    </Fragment>
  );
};

const TemplateData = props => {
  const { heading, value, classes } = props;
  return (
    <Fragment>
      <Typography>{heading}</Typography>
      <Typography className={classes.boldFontWeight}>{value || '------------'}</Typography>
    </Fragment>
  );
};

const TemplateDialog = props => {
  const classes = useStyles();
  const {
    userSelectionData,
    panelManufactureList,
    panelModelList,
    rackingMfs,
    template,
    deleteTemplate,
    getTemplates,
    updateTemplate,
    isFrom = '',
    setSelectedTemplate,
    selectedTemplate,
    setApplyTemplate,
    handleClose,
    postTemplate,
    removeEmptyTemplate
  } = props;
  const {
    roof_type,
    panel_manufacturer,
    racking_manufacturer,
    attachment_manufacturer,
    panel_model,
    interconnection_location,
    interconnection_strategy,
    // interconnection_strategy_enpower,
    attachment_type,
    racking_model,
    attachment_model,
    max_spacing_between_attachments,
    name,
    is_selected,
    empty = ''
  } = template;

  let roofTypeOptions = get(userSelectionData, 'roof_type', []);
  roofTypeOptions = roofTypeOptions.filter(value => value.trim().toLowerCase() !== 'other');
  const interconnectionStrategyNonCaOptions = get(userSelectionData, 'interconnection_strategy', []);
  const interconnectionStrategyCaOptions = get(userSelectionData, 'interconnection_strategy_ca', []);
  const interconnectionStrategyOptions = union(interconnectionStrategyNonCaOptions, interconnectionStrategyCaOptions);
  const interconnectionLocationOptions = get(userSelectionData, 'interconnection_location', []);
  // const interconnectionStrategyEnpowerOptions = get(userSelectionData, 'interconnection_strategy_enpower', []);
  const attachmentTypeOptions = get(userSelectionData, 'attachment_type', []);
  let maxSpacingBetweenAttachmentOptions = get(userSelectionData, 'max_space_between_attachments', []);
  maxSpacingBetweenAttachmentOptions = maxSpacingBetweenAttachmentOptions.filter(
    value => value.trim().toLowerCase() !== 'other'
  );
  const panelManufactureOptions = panelManufactureList.map(option => option.name);
  const panelModelOptions = panelModelList.map(option => option.name);
  const rackingManufacturerOptions = rackingMfs.map(option => option.name);
  const [ expanded, setExpanded ] = useState(false);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ isDelete, setIsDelete ] = useState(false);
  const [ templateName, setTemplateName ] = useState(name);
  const [ emptyTemplateName, setEmptyTemplateName ] = useState(false);
  const [ roofType, setRoofType ] = useState(roof_type);
  const [ panelManufacturer, setPanelManufacturer ] = useState(panel_manufacturer);
  const [ rackingManufacturer, setRackingManufacturer ] = useState(racking_manufacturer);
  const [ attachmentManufacturer, setAttachmentManufacturer ] = useState(attachment_manufacturer);
  const [ panelModel, setPanelModel ] = useState(panel_model);
  const [ interconnectionStrategy, setInterconnectionStrategy ] = useState(interconnection_strategy);
  // const [ interconnectionStrategyEnpower, setInterconnectionStrategyEnpower ] = useState(
  //   interconnection_strategy_enpower
  // );
  const [ interconnectionLocation, setInterconnectionLocation ] = useState(interconnection_location);
  const [ attachmentType, setAttachmentType ] = useState(attachment_type);
  const [ rackingModel, setRackingModel ] = useState(racking_model);
  const [ attachmentModel, setAttachmentModel ] = useState(attachment_model);
  const [ maxSpacingBetweenAttachments, setMaxSpacingBetweenAttachments ] = useState(max_spacing_between_attachments);

  const isCurrentlyThisTemplateSelected = isEqual(template, selectedTemplate);
  const showChoose = !(isFrom === 'Permit Listing');
  const handleAccordionChange = value => {
    setExpanded(value);
  };

  const getPanelModel = label => {
    const result = panelManufactureList.find(obj => obj.name === label);
    if (result) {
      props.getPanelManufacturerList(result.id);
    }
  };

  const deleteButtonClicked = () => {
    deleteTemplate({ id: template.id, successCbk: () => getTemplates() });
    setIsDelete(false);
    handleAccordionChange(false);
    setSelectedTemplate({});
  };

  const chooseButtonClicked = () => {
    setApplyTemplate(true);
    setSelectedTemplate(template);
    handleAccordionChange(true);
    handleClose();
  };
  const resetState = () => {
    setRoofType(roof_type);
    setPanelManufacturer(panel_manufacturer);
    setRackingManufacturer(racking_manufacturer);
    setAttachmentManufacturer(attachment_manufacturer);
    setPanelModel(panel_model);
    setInterconnectionStrategy(interconnection_strategy);
    setInterconnectionLocation(interconnection_location);
    // setInterconnectionStrategyEnpower(interconnection_strategy_enpower);
    setAttachmentType(attachment_type);
    setRackingModel(racking_model);
    setAttachmentModel(attachment_model);
    setMaxSpacingBetweenAttachments(max_spacing_between_attachments);
    setTemplateName(name);
  };

  const cancelButtonClicked = () => {
    if (!empty) {
      handleAccordionChange(false);
      resetState();
      setIsEdit(false);
    } else {
      removeEmptyTemplate();
    }
  };
  const saveButtonClicked = () => {
    if (!emptyTemplateName && templateName.length <= 50 && !isEmpty(templateName)) {
      handleAccordionChange(false);
      const currValues = {
        name: templateName,
        roof_type: roofType,
        panel_model: panelModel,
        panel_manufacturer: panelManufacturer,
        // interconnection_strategy_enpower: interconnectionStrategyEnpower,
        interconnection_strategy: interconnectionStrategy,
        interconnection_location: interconnectionLocation,
        racking_model: rackingModel,
        attachment_model: attachmentModel,
        racking_manufacturer: rackingManufacturer,
        attachment_manufacturer: attachmentManufacturer,
        attachment_type: attachmentType,
        max_spacing_between_attachments: maxSpacingBetweenAttachments,
        is_selected: is_selected
      };
      if (!empty) {
        updateTemplate({
          id: template.id,
          template: currValues,
          successCbk: () => getTemplates()
        });
      } else {
        postTemplate({
          template: currValues,
          successCbk: () => getTemplates()
        });
      }

      setIsEdit(false);
    }
    if (isEmpty(templateName)) {
      setEmptyTemplateName(true);
    }
  };

  const templateNameChange = e => {
    if (e.target.value === '') {
      setEmptyTemplateName(true);
    } else {
      setEmptyTemplateName(false);
    }
    setTemplateName(e.target.value);
  };

  useEffect(() => {
    if (panelManufacturer) {
      getPanelModel(panelManufacturer);
    }
  }, []);

  useEffect(
    () => {
      getPanelModel(panelManufacturer);
    },
    [ panelManufacturer ]
  );

  useEffect(
    () => {
      if (empty) {
        setIsEdit(true);
        setExpanded(true);
      }
    },
    [ empty ]
  );

  const handleChange = (value, id) => {
    if (id === 'roof_type') {
      setRoofType(value);
    } else if (id === 'panel_manufacturer') {
      setPanelManufacturer(value);
    } else if (id === 'interconnection_strategy') {
      setInterconnectionStrategy(value);
    } else if (id === 'interconnection_location') {
      setInterconnectionLocation(value);
    } else if (id === 'attachment_type') {
      // else if (id === 'interconnection_strategy_enpower') {
      //       setInterconnectionStrategyEnpower(value);
      //     }
      setAttachmentType(value);
    } else if (id === 'max_space_between_attachments') {
      setMaxSpacingBetweenAttachments(value);
    } else if (id === 'panel_model') {
      setPanelModel(value);
    } else if (id === 'racking_manufacturer') {
      setRackingManufacturer(value);
    } else if (id === 'attachment_manufacturer') {
      setAttachmentManufacturer(value);
    } else if (id === 'racking_model') {
      setRackingModel(value);
    } else if (id === 'attachment_model') {
      setAttachmentModel(value);
    }
  };
  if (!isEdit) {
    return (
      <div className={classes.root}>
        <Accordion expanded={expanded}>
          <AccordionSummary>
            <Grid container className={classes.displayFlexContainer}>
              <Grid item className={classes.displayFlex}>
                {expanded ? (
                  <ExpandLessIcon
                    className={classes.setPointerCursor}
                    onClick={() => handleAccordionChange(!expanded)}
                  />
                ) : (
                  <ExpandMoreIcon
                    className={classes.setPointerCursor}
                    onClick={() => handleAccordionChange(!expanded)}
                  />
                )}
                <Typography>
                  Template : <span className={classes.boldFontWeight}> {name} </span>
                </Typography>
              </Grid>
              <Grid item className={clsx(classes.displayFlex, classes.buttons)}>
                <Fragment>
                  {showChoose && (
                    <div className={classes.rightExtraPadding}>
                      {!isCurrentlyThisTemplateSelected && (
                        <Button
                          size="small"
                          color="primary"
                          variant="outlined"
                          className={classes.button}
                          disableRipple
                          onClick={chooseButtonClicked}
                        >
                          Choose
                        </Button>
                      )}
                      {isCurrentlyThisTemplateSelected && (
                        <Button
                          size="small"
                          variant="contained"
                          className={clsx(classes.button, classes.selectedButton)}
                          disableRipple
                          onClick={() => {
                            setApplyTemplate(false);
                            setSelectedTemplate({});
                            handleAccordionChange(false);
                          }}
                        >
                          <span className={classes.hideInMobile}>Selected</span>
                          <CheckedPermitIcon />
                        </Button>
                      )}
                    </div>
                  )}

                  <div className={clsx(classes.rightPadding, classes.displayFlex)}>
                    <Typography
                      onClick={() => {
                        if (panelManufacturer) {
                          getPanelModel(panelManufacturer);
                        }
                        setIsEdit(true);
                        handleAccordionChange(true);
                        setSelectedTemplate({});
                      }}
                      className={clsx(classes.textEditColor, classes.setPointerCursor)}
                      display="inline"
                    >
                      Edit
                    </Typography>
                    <IconButton
                      onClick={() => {
                        if (panelManufacturer) {
                          getPanelModel(panelManufacturer);
                        }
                        setIsEdit(true);
                        handleAccordionChange(true);
                        setSelectedTemplate({});
                      }}
                    >
                      <EditTemplateIcon />
                    </IconButton>
                  </div>
                  <span className={clsx(classes.pipe, classes.rightPadding)} />
                  <div className={classes.displayFlex}>
                    <Typography
                      onClick={() => {
                        setIsDelete(true);
                        handleAccordionChange(true);
                      }}
                      className={clsx(classes.textDeleteColor, classes.setPointerCursor)}
                      display="inline"
                    >
                      Delete
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setIsDelete(true);
                        handleAccordionChange(true);
                      }}
                    >
                      <DeleteTemplateIcon />
                    </IconButton>
                  </div>
                </Fragment>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={clsx(classes.templateBlock, classes.firstTemplateBlock)}>
            <div className={classes.panelHead}>
              <Typography className={clsx(classes.heading, classes.leftPadding)}>Panel and Arrays</Typography>
            </div>
            <Grid container className={classes.templatesFieldContainer}>
              <Grid>
                <TemplateData heading="Roof Type" value={roofType} classes={classes} />
              </Grid>
              <Grid>
                <TemplateData heading="Panel Manufacturer" value={panelManufacturer} classes={classes} />
              </Grid>
              <Grid>
                <TemplateData heading="Panel Model" value={panelModel} classes={classes} />
              </Grid>
            </Grid>
          </AccordionDetails>

          <AccordionDetails className={classes.templateBlock}>
            <div className={classes.panelHead}>
              <Typography className={clsx(classes.heading, classes.leftPadding)}>Interconnection</Typography>
            </div>
            <Grid container className={classes.templatesFieldContainer}>
              <Grid>
                <TemplateData heading="Interconnection Strategy" value={interconnectionStrategy} classes={classes} />
              </Grid>
              <Grid>
                <TemplateData heading="Interconnection Location" value={interconnectionLocation} classes={classes} />
              </Grid>
              {/* 
              <Grid>
                <TemplateData
                  heading="Interconnection Strategy Enpower"
                  value={interconnectionStrategyEnpower}
                  classes={classes}
                />
              </Grid> */}
            </Grid>
          </AccordionDetails>

          <AccordionDetails className={clsx(classes.templateBlock, classes.lastTemplateBlock)}>
            <div className={classes.panelHead}>
              <Typography className={clsx(classes.heading, classes.leftPadding)}>
                Racking and Attachment Properties
              </Typography>
            </div>
            <Grid container className={classes.templatesFieldContainer}>
              <Grid>
                <TemplateData heading="Racking Manufacturer" value={rackingManufacturer} classes={classes} />
              </Grid>
              <Grid>
                <TemplateData heading="Attachment Manufacturer" value={attachmentManufacturer} classes={classes} />
              </Grid>
              <Grid>
                <TemplateData heading="Racking Model" value={rackingModel} classes={classes} />
              </Grid>
              <Grid>
                <TemplateData heading="Attachment Model" value={attachmentModel} classes={classes} />
              </Grid>
              <Grid>
                <TemplateData heading="Attachment Type" value={attachmentType} classes={classes} />
              </Grid>
              <Grid>
                <TemplateData
                  heading="Maximum Spacing between Attachments"
                  value={maxSpacingBetweenAttachments}
                  classes={classes}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {isDelete && (
          <Dialog open onClose className={classes.dialogContainer} fullWidth maxWidth="xs">
            <DialogTitle className={classes.dialogHeader} disableTypography>
              <PageHeader className={classes.fontSize} text={'Delete Template'} />
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  setIsDelete(false);
                  handleAccordionChange(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography className={clsx(classes.leftPaddingDialog, classes.boldFontWeight)}>
                {template.name}
              </Typography>
              <br />
              <Typography className={classes.leftPaddingDialog}>
                Are you sure you want to delete this template ?
              </Typography>
              <br />
              <div className={classes.displayFlexButton}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  className={clsx(classes.button, classes.saveButton)}
                  disableRipple
                  onClick={deleteButtonClicked}
                >
                  Delete
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  } else {
    return (
      <div className={classes.root} id={empty ? 'newTemplate' : ''}>
        <Accordion expanded={expanded}>
          <AccordionSummary>
            <Grid container className={classes.displayFlexContainer}>
              <Grid item className={classes.displayFlex}>
                {expanded ? (
                  <ExpandLessIcon
                    className={classes.setPointerCursor}
                    onClick={() => handleAccordionChange(!expanded)}
                  />
                ) : (
                  <ExpandMoreIcon
                    className={classes.setPointerCursor}
                    onClick={() => handleAccordionChange(!expanded)}
                  />
                )}
                <Typography>Template : </Typography>

                <Fragment>
                  &nbsp; &nbsp;
                  <TextField
                    error={emptyTemplateName || templateName.length > 50}
                    className={classes.bottomMargin}
                    id="outlined-error"
                    value={templateName}
                    onChange={e => {
                      templateNameChange(e);
                    }}
                    helperText={
                      (emptyTemplateName && 'Please provide a template name. ') ||
                      (templateName.length > 50 && 'The template name should not have more than 50 characters. ')
                    }
                  />
                </Fragment>
              </Grid>
              <Grid className={clsx(classes.displayFlex, classes.buttons)}>
                <Fragment>
                  <div>
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      className={classes.button}
                      disableRipple
                      onClick={cancelButtonClicked}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={classes.leftPadding}>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      className={clsx(classes.button, classes.saveButton)}
                      disableRipple
                      onClick={saveButtonClicked}
                    >
                      Save
                    </Button>
                  </div>
                </Fragment>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={clsx(classes.templateBlock, classes.firstTemplateBlock)}>
            <div className={classes.panelHead}>
              <Typography className={clsx(classes.heading, classes.leftPadding)}>Panel and Arrays</Typography>
            </div>
            <Grid container className={classes.templatesFieldContainer}>
              <SelectDropdown
                id="roof_type"
                className={classes.inputField}
                value={roofType}
                handleChange={handleChange}
                optionList={roofTypeOptions}
                labelText="Roof Type"
                classes={classes}
              />
              <SelectDropdown
                id="panel_manufacturer"
                className={classes.inputField}
                value={panelManufacturer}
                handleChange={handleChange}
                optionList={panelManufactureOptions}
                labelText="Panel Manufacturer"
                classes={classes}
              />

              <SelectDropdown
                id="panel_model"
                className={classes.inputField}
                value={panelModel}
                handleChange={handleChange}
                optionList={panelModelOptions}
                labelText="Panel Model"
                classes={classes}
              />
            </Grid>
          </AccordionDetails>

          <AccordionDetails className={classes.templateBlock}>
            <div className={classes.panelHead}>
              <Typography className={clsx(classes.heading, classes.leftPadding)}>Interconnection</Typography>
            </div>
            <Grid container className={classes.templatesFieldContainer}>
              <SelectDropdown
                id="interconnection_strategy"
                labelText="Interconnection Strategy (MSP)"
                className={classes.inputField}
                value={interconnectionStrategy}
                handleChange={handleChange}
                optionList={interconnectionStrategyOptions}
                classes={classes}
              />
              <SelectDropdown
                id="interconnection_location"
                labelText="Interconnection Location"
                className={classes.inputField}
                value={interconnectionLocation}
                handleChange={handleChange}
                optionList={interconnectionLocationOptions}
                classes={classes}
              />

              {/* <SelectDropdown
                  id="interconnection_strategy_enpower"
                  labelText="Interconnection Strategy (Enpower)"
                  className={classes.inputField}
                  value={interconnectionStrategyEnpower}
                  handleChange={handleChange}
                  optionList={interconnectionStrategyEnpowerOptions}
                  classes={classes}
                /> */}
            </Grid>
          </AccordionDetails>

          <AccordionDetails className={clsx(classes.templateBlock, classes.lastTemplateBlock)}>
            <div className={classes.panelHead}>
              <Typography className={clsx(classes.heading, classes.leftPadding)}>
                Racking and Attachment Properties
              </Typography>
            </div>
            <Grid container className={classes.templatesFieldContainer}>
              <SelectDropdown
                id="racking_manufacturer"
                labelText="Racking Manufacturer"
                className={classes.inputField}
                value={rackingManufacturer}
                handleChange={handleChange}
                optionList={rackingManufacturerOptions}
                classes={classes}
              />
              <SelectDropdown
                id="attachment_manufacturer"
                labelText="Attachment Manufacturer"
                className={classes.inputField}
                value={attachmentManufacturer}
                handleChange={handleChange}
                optionList={rackingManufacturerOptions}
                classes={classes}
              />
              <TextInputField
                id="racking_model"
                labelText="Racking Model"
                className={classes.inputField}
                value={rackingModel}
                handleChange={handleChange}
                classes={classes}
              />
              <TextInputField
                id="attachment_model"
                labelText="Attachment Model"
                className={classes.inputField}
                value={attachmentModel}
                handleChange={handleChange}
                classes={classes}
              />
              <SelectDropdown
                id="attachment_type"
                labelText="Attachment Type"
                className={classes.inputField}
                value={attachmentType}
                handleChange={handleChange}
                optionList={attachmentTypeOptions}
                classes={classes}
              />
              <SelectDropdown
                id="max_space_between_attachments"
                labelText="Max Space Between Attachments"
                className={classes.inputField}
                value={maxSpacingBetweenAttachments}
                handleChange={handleChange}
                optionList={maxSpacingBetweenAttachmentOptions}
                classes={classes}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
};

const mapStateToProps = state => ({
  userSelectionData: state.permitReducer.userSelectionData,
  panelManufactureList: state.permitReducer.panelManufactureList,
  panelModelList: state.permitReducer.panelModelList,
  rackingMfs: state.permitReducer.racking_mfs,
  templates: state.permitReducer.templates,
  selectedTemplate: state.permitReducer.selectedTemplate,
  applyTemplate: state.permitReducer.applyTemplate
});

const mapDispatchToProps = dispatch => ({
  getPanelManufacturerList: id => dispatch(getPanelManufacturerList({ id })),
  deleteTemplate: payload => dispatch(deleteTemplate(payload)),
  getTemplates: payload => dispatch(getTemplates(payload)),
  toggleTemplate: payload => dispatch(toggleTemplate(payload)),
  updateTemplate: payload => dispatch(updateTemplate(payload)),
  setSelectedTemplate: payload => dispatch(setSelectedTemplate(payload)),
  setApplyTemplate: payload => dispatch(setApplyTemplate(payload)),
  postTemplate: payload => dispatch(postTemplate(payload)),
  removeEmptyTemplate: payload => dispatch(removeEmptyTemplate(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateDialog);
