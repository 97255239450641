import { get } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import ConfirmationDialog from '../../../components/Permitting/ConfirmationDialog';
import PanelArrays from '../../../components/Permitting/PanelArrays';
import Products from '../../../components/Permitting/Products';
import RackingAndAttachments from '../../../components/Permitting/RackingAndAttachments';
import StoragePopup from '../../../components/Permitting/StoragePopup';
import SystemDetails from '../../../components/Permitting/SystemDetails';
import { DEFAULT_SYSTEM_TYPES, FEATURE_FLAG_TYPE, FEATURE_FLAGS } from '../constants';
import { isFeatureEnabled } from '../../../containers/Permitting/helper';

const SystemDesign = ({
  solarNotPurchased,
  siteDetails,
  projectDetails,
  fireUpload,
  deleteFile,
  deletePhotos,
  panelManufactureList,
  materialDetails,
  userSelectionData,
  handleChange,
  handleProjectDetailsChange,
  handleProjectDetailsChanges,
  paymentStatus,
  applicationDetail,
  rackingMfs,
  permitId,
  putToS3,
  getS3URL,
  sgMaterials,
  sgMaterialsLoading,
  isMobile,
  otherUserSelectionData,
  mostUsedValues,
  isPermitSetAvailable,
  addToCartAndGetUrl,
  setErrorObj,
  postAdditionalStorageData,
  storageIncluded,
  permitDirectSubmit,
  featureList,
  subPanelList,
}) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const showExistingArrays =
    Number(get(projectDetails, 'system_type_v2.id')) === 3 && (get(projectDetails, 'documents', []) || []).length === 0;
  const storageSelected = get(projectDetails, 'system_types[1].value', false);
  const openStoragePopup = get(projectDetails, 'openStoragePopup', false);
  const isInputAutocompleteEnabled = isFeatureEnabled(featureList, FEATURE_FLAGS.INPUT_FORM_DROPDOWNS, FEATURE_FLAG_TYPE.company);
  useEffect(() => {
    if (!storageSelected) {
      handleChange([], 'storage_list', {}, true, true);
      handleChange(null, 'third_party_generator');
    }
  }, [handleChange, storageSelected]);

  const handleStorageDialogClose = () => {
    const systemTypes =
      projectDetails && projectDetails.system_types ? projectDetails.system_types : DEFAULT_SYSTEM_TYPES;
    systemTypes[1].value = false;
    handleProjectDetailsChanges(['openStoragePopup', 'system_types'], [false, systemTypes]);
  };

  return (
    <Fragment>
      <SystemDetails
        materialDetails={materialDetails}
        siteDetails={siteDetails}
        handleProjectDetailsChange={handleProjectDetailsChange}
        handleProjectDetailsChanges={handleProjectDetailsChanges}
        permitId={permitId}
        getS3URL={getS3URL}
        putToS3={putToS3}
        paymentStatus={paymentStatus}
        storageIncluded={storageIncluded}
        isPermitSetAvailable={isPermitSetAvailable}
      />
      {solarNotPurchased && (
        <div id="md-top-section">
          {showExistingArrays && (
            <PanelArrays
              materialDetails={materialDetails}
              userSelectionData={userSelectionData}
              handleChange={handleChange}
              handleProjectDetailsChange={handleProjectDetailsChange}
              applicationDetail={applicationDetail}
              panelManufactureList={panelManufactureList}
              fireUpload={fireUpload}
              isExistingArray={true}
              permitId={permitId}
              putToS3={putToS3}
              getS3URL={getS3URL}
              projectDetails={projectDetails}
              showExistingPanelArray={showExistingArrays}
              paymentStatus={paymentStatus}
              featureList={featureList}
              isInputAutocompleteEnabled={isInputAutocompleteEnabled}
            />
          )}
          <PanelArrays
            materialDetails={materialDetails}
            userSelectionData={userSelectionData}
            handleChange={handleChange}
            handleProjectDetailsChange={handleProjectDetailsChange}
            applicationDetail={applicationDetail}
            panelManufactureList={panelManufactureList}
            fireUpload={fireUpload}
            showExistingPanelArray={showExistingArrays}
            permitId={permitId}
            putToS3={putToS3}
            getS3URL={getS3URL}
            projectDetails={projectDetails}
            paymentStatus={paymentStatus}
            featureList={featureList}
            isInputAutocompleteEnabled={isInputAutocompleteEnabled}
          />
        </div>
      )}
      {(solarNotPurchased || Number(get(projectDetails, 'system_type_v2.id')) === 6) && (
        <Products
          productKey="inverters"
          header="Inverter"
          handleChange={handleChange}
          materialDetails={materialDetails}
          handleProjectDetailsChanges={handleProjectDetailsChanges}
          applicationDetail={applicationDetail}
          sgMaterials={sgMaterials}
          sgMaterialsLoading={sgMaterialsLoading}
          getS3URL={getS3URL}
          putToS3={putToS3}
          permitId={permitId}
          featureList={featureList}
          isInputAutocompleteEnabled={isInputAutocompleteEnabled}
        />
      )}
      {storageSelected && (
        <Products
          productKey="storage_list"
          header="Storage"
          handleChange={handleChange}
          materialDetails={materialDetails}
          applicationDetail={applicationDetail}
          sgMaterials={sgMaterials}
          sgMaterialsLoading={sgMaterialsLoading}
          handleProjectDetailsChanges={handleProjectDetailsChanges}
          getS3URL={getS3URL}
          putToS3={putToS3}
          permitId={permitId}
          featureList={featureList}
          isInputAutocompleteEnabled={isInputAutocompleteEnabled}
        />
      )}
      <Products
        productKey="other_materials"
        header="Other Materials"
        handleChange={handleChange}
        materialDetails={materialDetails}
        applicationDetail={applicationDetail}
        sgMaterials={sgMaterials}
        sgMaterialsLoading={sgMaterialsLoading}
        handleProjectDetailsChanges={handleProjectDetailsChanges}
        getS3URL={getS3URL}
        putToS3={putToS3}
        permitId={permitId}
        featureList={featureList}
        isInputAutocompleteEnabled={isInputAutocompleteEnabled}
      />
      {solarNotPurchased && (
        <Fragment>
          <RackingAndAttachments
            materialDetails={materialDetails}
            userSelectionData={userSelectionData}
            handleChange={handleChange}
            paymentStatus={paymentStatus}
            applicationDetail={applicationDetail}
            rackingMfs={rackingMfs}
            isInputAutocompleteEnabled={isInputAutocompleteEnabled}
          />
        </Fragment>
      )}
      {openStoragePopup && (
        <StoragePopup
          open={openStoragePopup}
          handleClose={() => handleStorageDialogClose()}
          setShowConfirmationDialog={setShowConfirmationDialog}
          handleChange={handleChange}
          materialDetails={materialDetails}
          applicationDetail={applicationDetail}
          sgMaterials={sgMaterials}
          sgMaterialsLoading={sgMaterialsLoading}
          handleProjectDetailsChange={handleProjectDetailsChange}
          handleProjectDetailsChanges={handleProjectDetailsChanges}
          projectDetails={projectDetails}
          userSelectionData={userSelectionData}
          paymentStatus={paymentStatus}
          isMobile={isMobile}
          permitId={permitId}
          otherUserSelectionData={otherUserSelectionData}
          mostUsedValues={mostUsedValues}
          isPermitSetAvailable={isPermitSetAvailable}
          fireUpload={fireUpload}
          deletePhotos={deletePhotos}
          addToCartAndGetUrl={addToCartAndGetUrl}
          setErrorObj={setErrorObj}
          postAdditionalStorageData={postAdditionalStorageData}
          storageIncluded={storageIncluded}
          permitDirectSubmit={permitDirectSubmit}
          featureList={featureList}
          getS3URL={getS3URL}
          subPanelList={subPanelList}
          putToS3={putToS3}
          isInputAutocompleteEnabled={isInputAutocompleteEnabled}
        />
      )}
      <ConfirmationDialog open={showConfirmationDialog} handleClose={() => setShowConfirmationDialog(false)} />
    </Fragment>
  );
};

export default SystemDesign;
