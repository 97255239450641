import { get } from 'lodash';
import {
  CREATE_PROJECT_DETAILS_REQUEST,
  CREATE_PROJECT_DETAILS_SUCCESS,
  CREATE_PROJECT_DETAILS_FAILURE,
  GET_PROJECT_DETAILS_REQUEST,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
  GET_PERMIT_LISTING_REQUEST,
  GET_PERMIT_LISTING_SUCCESS,
  GET_PERMIT_LISTING_FAILURE,
  GET_PERMIT_COUNT_BY_INSTALLER_REQUEST,
  GET_PERMIT_COUNT_BY_INSTALLER_SUCCESS,
  GET_PERMIT_COUNT_BY_INSTALLER_FAILURE,
  SITE_DETAILS_REQUEST,
  SITE_DETAILS_SUCCESS,
  SITE_DETAILS_FAILURE,
  SITE_SEARCH_REQUEST,
  SITE_SEARCH_REQUEST_SUCCESS,
  SITE_SEARCH_REQUEST_FAILURE,
  GET_MATERIAL_DETAILS_REQUEST,
  CREATE_MATERIAL_DETAILS_REQUEST,
  CREATE_MATERIAL_DETAILS_SUCCESS,
  CREATE_MATERIAL_DETAILS_FAILURE,
  GET_MATERIAL_DETAILS_SUCCESS,
  GET_MATERIAL_DETAILS_FAILURE,
  GET_USER_SELECTION_REQUEST,
  GET_USER_SELECTION_SUCCESS,
  GET_USER_SELECTION_FAILURE,
  GET_OTHER_USER_SELECTION_REQUEST,
  GET_OTHER_USER_SELECTION_SUCCESS,
  GET_OTHER_USER_SELECTION_FAILURE,
  GET_PANEL_MANUFACTURER_LIST_REQUEST,
  GET_PANEL_MANUFACTURER_LIST_SUCCESS,
  GET_PANEL_MANUFACTURER_LIST_FAILURE,
  UPDATE_PAYMENT_DETAILS_REQUEST,
  UPDATE_PAYMENT_DETAILS_SUCCESS,
  UPDATE_PAYMENT_DETAILS_FAILURE,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
  UPDATE_ADDRESS,
  GET_SELECTED_SERVICES_REQUEST,
  GET_SELECTED_SERVICES_SUCCESS,
  GET_SELECTED_SERVICES_FAILURE,
  UPDATE_SELECTED_SERVICES_REQUEST,
  UPDATE_SELECTED_SERVICES_SUCCESS,
  UPDATE_SELECTED_SERVICES_FAILURE,
  GET_PERMIT_BY_ID_REQUEST,
  GET_PERMIT_BY_ID_SUCCESS,
  GET_PERMIT_BY_ID_FAILURE,
  SET_PAYMET_STATUS_REQUEST,
  PROMOCODE_REQUEST,
  PROMOCODE_SUCCESS,
  PROMOCODE_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  SET_MESSAGE_REQUEST,
  SET_MESSAGE_SUCCESS,
  SET_MESSAGE_FAILURE,
  RESET_SITE_SEARCH_RESULT_REQUEST,
  SHOW_HIDE_LOADER,
  GET_RACKING_MFS_SUCCESS,
  GET_RACKING_MFS_REQUEST,
  GET_RACKING_MFS_FAILURE,
  GET_AB_FEATURES_SUCCESS,
  GET_AB_FEATURES_REQUEST,
  GET_AB_FEATURES_FAILURE,
  GET_UTILITY_PROVIDERS_REQUEST,
  GET_UTILITY_PROVIDERS_SUCCESS,
  GET_UTILITY_PROVIDERS_FAILURE,
  SET_PHOTOS,
  GET_CHAT_INFO_SUCCESS,
  UPDATE_CHAT_STATUS_SUCCESS,
  SET_CHAT_MESSAGE_SUCCESS,
  GET_REWORK_SERVICES_REQUEST,
  GET_REWORK_SERVICES_SUCCESS,
  GET_REWORK_SERVICES_FAILURE,
  UPDATE_REWORK_SERVICES_REQUEST,
  UPDATE_REWORK_SERVICES_SUCCESS,
  UPDATE_REWORK_SERVICES_FAILURE,
  PERMIT_DIRECT_SUBMIT_REQUEST,
  PERMIT_DIRECT_SUBMIT_SUCCESS,
  PERMIT_DIRECT_SUBMIT_FAILURE,
  GET_SELECTED_REWORK_SERVICES_REQUEST,
  GET_SELECTED_REWORK_SERVICES_SUCCESS,
  GET_SELECTED_REWORK_SERVICES_FAILURE,
  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILURE,
  SET_CLOSE_SAVE,
  SET_ERROR,
  SET_PAYMENT_LOADER,
  SET_ACTIVE_STEP,
  ACCOUNT_DETAIL_REQUEST,
  ACCOUNT_DETAIL_SUCCESS,
  REQUEST_FAILURE,
  GET_TEMPLATE_REQUEST,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAILURE,
  SET_SELECTED_TEMPLATE,
  POST_TEMPLATE_FAILURE,
  POST_TEMPLATE_SUCCESS,
  SET_VIEW_TEMPLATE,
  PAYMENT_UPGRADE_TO_STORAGE,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAILURE,
  UPDATE_FEEDBACK_SUCCESS,
  GET_MOST_USED_VALUES_REQUEST,
  GET_MOST_USED_VALUES_SUCCESS,
  GET_MOST_USED_VALUES_FAILURE,
  SET_APPLY_TEMPLATE,
  ADD_EMPTY_TEMPLATE,
  REMOVE_EMPTY_TEMPLATE,
  POST_TEMPLATE_REQUEST,
  CREATE_EMAIL_NPS_FEEDBACK_REQUEST,
  CREATE_EMAIL_NPS_FEEDBACK_SUCCESS,
  CREATE_EMAIL_NPS_FEEDBACK_FAILURE,
  SEND_STAMPING_REQUEST,
  SEND_STAMPING_FAILURE,
  SEND_STAMPING_SUCCESS,
  GET_ARRAY_BUILDER_DETAILS_REQUEST,
  GET_ARRAY_BUILDER_DETAILS_SUCCESS,
  GET_ARRAY_BUILDER_DETAILS_FAILURE,
  SET_V2_SOLAR_UI,
  SET_IS_FROM_PACKAGE,
  GET_CART_URL_REQUEST,
  GET_CART_URL_SUCCESS,
  GET_CART_URL_FAILURE,
  GET_SG_MATERIALS_REQUEST,
  GET_SG_MATERIALS_SUCCESS,
  GET_SG_MATERIALS_FAILURE,
  GET_COMPANY_INFO_SUCCESS,
  ADDITIONAL_STORAGE_REQUEST,
  ADDITIONAL_STORAGE_SUCCESS,
  ADDITIONAL_STORAGE_FAILURE,
  MORE_INFO_SUBMIT_SUCCESS,
  MORE_INFO_SUBMIT_FAILURE,
  MORE_INFO_SUBMIT_REQUEST,
  DRAFT_APPLICATION_REQUEST,
  DRAFT_APPLICATION_SUCCESS,
  DRAFT_APPLICATION_FAILURE,
  REWORK_REQUEST,
  REWORK_REQUEST_SUCCESS,
  REWORK_REQUEST_FAILURE,
  FILES_DOWNLOADED_REQUEST,
  FILES_DOWNLOADED_FAILURE,
  FILES_DOWNLOADED_SUCCESS,
  GET_AHJ_RECOMMENDATIONS_REQUEST,
  GET_AHJ_RECOMMENDATIONS_SUCCESS,
  GET_AHJ_RECOMMENDATIONS_FAILURE,
  ENABLE_SUBMIT,
  GET_AHJID_BYNAME_REQUEST,
  GET_AHJID_BYNAME_SUCCESS,
  GET_AHJID_BYNAME_FAILURE,
  GET_UTILITYID_BYNAME_REQUEST,
  GET_UTILITYID_BYNAME_SUCCESS,
  GET_UTILITYID_BYNAME_FAILURE,
  ORDER_STAMPS,
  UPDATE_COUNTY,
  SET_CHAT_AUTOMATED_MESSAGES_REQUEST,
  GET_S3_URL_REQUEST,
  GET_S3_URL_SUCCESS,
  GET_S3_URL_FAILURE,
  UPDATE_COORDINATES,
} from './constants';

const initialState = {
  permitId: '',
  siteId: '',
  message: '',
  messageType: '',
  currentlySending: false,
  projectDetails: {
    permit_id: '',
    system_name: '',
    address: '',
    zipcode: '',
    customer_name: '',
    project_requirement_description: '',
    utility_provider: '',
    ahj: '',
    ahj_id: null,
    ahj_notes: '',
    latitude: null,
    longitude: null,
    system_types: [
      { name: 'Solar', value: null, keyValue: 'solar' },
      { name: 'Storage', value: false, keyValue: 'storage' },
    ],
    system_type_v2: { name: '', id: '' },
    solar_system_size: '',
    storage_size: '',
    site_id: '',
    preexisting_solar_permit: '',
    documents: [],
  },
  updated_at: '',
  siteDetails: null,
  step_completed: 0,
  permittingRequests: [],
  totalElements: 0,
  getPermittingRequetsInProgress: false,
  siteSearchInProgress: false,
  searchResults: { total_count: 0, result: [] },
  materialDetails: null,
  userSelectionData: {},
  otherUserSelectionData: {},
  clientSecret: '',
  panelManufactureList: [],
  panelModelList: [],
  requiredServices: [],
  selectedServices: [],
  canChangeLayout: false,
  applicationDetail: {},
  paymentStatus: false,
  isValidPromocode: false,
  messageList: [],
  purchasedServices: [],
  racking_mfs: [],
  uProviders: [],
  photos: {},
  selectedSites: [],
  unreadMessageCount: 0,
  reworkServices: [],
  selectedReworkServices: [],
  purchasedReworkServices: [],
  isSaveandClose: false,
  errors: {},
  activeStep: 0,
  accountDetail: {},
  companyInfo: {},
  company: {},
  templates: [],
  selectedTemplate: {},
  viewTemplate: false,
  paymentUpgradeToStorage: false,
  feedback: [],
  latestFeedback: {},
  mostUsedValues: {},
  applyTemplate: false,
  disableAddTemplateButton: false,
  arrayBuilder: {},
  isV2SolarUI: false,
  currentlySendingReworkRequest: false,
  isFromPackage: false,
  storageIncluded: false,
  cartUrl: '',
  sgMaterialsLoading: false,
  sgMaterials: {},
  enableSubmit: false,
  inOrderStamps: false,
  applicationFilters: {},
  isChatAutomatedMessagesDisabled: false,
  featureList: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PROJECT_DETAILS_REQUEST:
      return { ...state, currentlySending: true };
    case GET_PERMIT_LISTING_REQUEST:
      return { ...state, getPermittingRequetsInProgress: true, paymentStatus: false };
    case GET_PERMIT_COUNT_BY_INSTALLER_REQUEST:
      return { ...state, currentlySending: true };
    case GET_MATERIAL_DETAILS_REQUEST:
      return { ...state, currentlySending: true };
    case CREATE_MATERIAL_DETAILS_REQUEST:
      return { ...state, currentlySending: true };
    case SHOW_HIDE_LOADER:
      return { ...state, currentlySending: action.payload, currentlySendingReworkRequest: action.payload };
    case GET_UTILITY_PROVIDERS_REQUEST:
      return { ...state, currentlySending: true };
    case CREATE_MATERIAL_DETAILS_SUCCESS:
      let stepCompleted = state.step_completed;
      if (action.isProceed && state.step_completed < 4) {
        stepCompleted = state.step_completed + 1;
      }
      return {
        ...state,
        currentlySending: false,
        materialDetails: action.materialDetails,
        messageType: 'success',
        message: action.message,
        step_completed: stepCompleted,
      };
    case CREATE_MATERIAL_DETAILS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case PROMOCODE_REQUEST:
      return { ...state, currentlySending: true };
    case PROMOCODE_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        isValidPromocode: true,
      };
    case PROMOCODE_FAILURE:
      return {
        ...state,
        currentlySending: false,
        isValidPromocode: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_NOTIFICATION_REQUEST:
      return { ...state, currentlySending: true };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageList: action.messageList,
        unreadMessageCount: action.unreadMessageCount,
      };
    case GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case SET_MESSAGE_REQUEST:
      return { ...state, currentlySending: true };
    case SET_MESSAGE_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case SET_MESSAGE_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_PERMIT_BY_ID_REQUEST:
      return { ...state, currentlySending: true };
    case GET_PERMIT_BY_ID_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        applicationDetail: action.applicationDetail,
        materialDetails: get(action.applicationDetail, 'material_details', null),
        siteDetails: get(action.applicationDetail, 'site_details', null),
        step_completed: get(action.applicationDetail, 'step_completed', 0),
        siteId: get(action.applicationDetail, 'site_id', null),
        storageIncluded: get(action.applicationDetail, 'project_details.system_types[1].value', false),
      };
    case GET_PERMIT_COUNT_BY_INSTALLER_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        permitApplicationCountByInstallerEmail: action.permitApplicationCountByInstallerEmail,
      };
    case GET_PERMIT_BY_ID_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_MATERIAL_DETAILS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        materialDetails: action.materialDetails,
      };
    case GET_MATERIAL_DETAILS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case GET_USER_SELECTION_REQUEST:
      return { ...state, currentlySending: true };
    case GET_USER_SELECTION_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        userSelectionData: action.userSelectionData,
      };
    case GET_USER_SELECTION_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case GET_OTHER_USER_SELECTION_REQUEST:
      return { ...state, currentlySending: true };
    case GET_OTHER_USER_SELECTION_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        otherUserSelectionData: action.otherUserSelectionData,
      };
    case GET_OTHER_USER_SELECTION_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case GET_UTILITY_PROVIDERS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case GET_PANEL_MANUFACTURER_LIST_REQUEST:
      return { ...state, currentlySending: true };
    case GET_PANEL_MANUFACTURER_LIST_SUCCESS: {
      const { id, result } = action;

      return {
        ...state,
        ...(!id && { panelManufactureList: result }),
        ...(id && { panelModelList: result }),
        currentlySending: false,
      };
    }
    case GET_PANEL_MANUFACTURER_LIST_FAILURE:
      return {
        ...state,
        ...(!action.id && { panelManufactureList: [] }),
        ...(action.id && { panelModelList: [] }),
        currentlySending: false,
      };
    case SET_PAYMET_STATUS_REQUEST:
      return { ...state, paymentStatus: action.payload.paymentStatus };
    case UPDATE_SELECTED_SERVICES_REQUEST:
      return { ...state, currentlySending: true };
    case UPDATE_SELECTED_SERVICES_SUCCESS:
      let completedStep = state.step_completed;
      if (state.step_completed < 3) {
        completedStep = 3;
      }
      return {
        ...state,
        currentlySending: false,
        step_completed: completedStep,
      };
    case UPDATE_SELECTED_SERVICES_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case UPDATE_REWORK_SERVICES_REQUEST:
      return { ...state, currentlySending: true };
    case UPDATE_REWORK_SERVICES_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case UPDATE_REWORK_SERVICES_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case PERMIT_DIRECT_SUBMIT_REQUEST:
      return { ...state, currentlySending: true };
    case PERMIT_DIRECT_SUBMIT_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case PERMIT_DIRECT_SUBMIT_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case GET_SERVICES_REQUEST:
      return { ...state };
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        requiredServices: action.requiredServices,
      };
    case GET_SERVICES_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.error,
      };
    case GET_APPLICATION_REQUEST:
      return { ...state, currentlySending: true };
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case GET_APPLICATION_FAILURE:
      return {
        ...state,
        currentlySending: false,
      };
    case GET_REWORK_SERVICES_REQUEST:
      return { ...state };
    case GET_REWORK_SERVICES_SUCCESS:
      return {
        ...state,
        reworkServices: action.reworkServices,
      };
    case GET_REWORK_SERVICES_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.error,
      };
    case GET_SELECTED_SERVICES_REQUEST:
      return { ...state, currentlySending: true };
    case GET_SELECTED_SERVICES_SUCCESS:
      return {
        ...state,
        selectedServices: action.selectedServices,
        purchasedServices: action.purchasedServices,
        canChangeLayout: action.canChangeLayout,
        currentlySending: false,
      };
    case GET_SELECTED_SERVICES_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.error,
        currentlySending: false,
      };
    case GET_SELECTED_REWORK_SERVICES_REQUEST:
      return { ...state, currentlySending: true };
    case GET_SELECTED_REWORK_SERVICES_SUCCESS:
      return {
        ...state,
        selectedReworkServices: action.selectedReworkServices || [],
        purchasedReworkServices: action.purchasedReworkServices || [],
        selectedServices: action.selectedServices || [],
        purchasedServices: action.purchasedServices || [],
        currentlySending: false,
      };
    case GET_SELECTED_REWORK_SERVICES_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.error,
        currentlySending: false,
      };
    case CREATE_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        message: action.message,
        messageType: 'success',
        currentlySending: false,
        projectDetails: action.projectDetails,
        // what is this need to change
        updated_at: Math.floor(Math.random() * 10 + 1),
        ...(action.stepCompleted && { step_completed: action.stepCompleted }),
        permitId: action.permitId,
      };
    case GET_PERMIT_LISTING_SUCCESS:
      return {
        ...state,
        getPermittingRequetsInProgress: false,
        totalElements: action.totalElements || 0,
        permittingRequests: action.permittingRequests,
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          ...action.payload,
        },
      };
    case SITE_DETAILS_REQUEST:
      return { ...state, currentlySending: true };
    case SITE_DETAILS_SUCCESS:
      if (action.siteDetails && action.siteDetails.length) {
        const siteDetails = action.siteDetails[0];
        let customer_name =
          state.projectDetails.customer_name.trim() || `${siteDetails.first_name || ''} ${siteDetails.last_name || ''}`;
        customer_name = customer_name.trim();
        const addressObject = {
          ...(siteDetails.address1 && { address1: siteDetails.address1 }),
          ...(siteDetails.address2 && { address2: siteDetails.address2 }),
          ...(siteDetails.city && { city: siteDetails.city }),
          ...(siteDetails.state && { state: siteDetails.state }),
          ...(siteDetails.country && { country: siteDetails.country }),
        };

        const address = siteDetails.address1 ? siteDetails.address1 : Object.keys(addressObject).reduce((acu, key) => (acu ? acu + ', ' + addressObject[key] : addressObject[key]), '');

        return {
          ...state,
          currentlySending: false,
          siteDetails,
          projectDetails: {
            ...state.projectDetails,
            zipcode: state.projectDetails.zipcode || siteDetails.zip,
            system_name: siteDetails.site_name,
            customer_name,
            address: address,
            full_address: address,
            ...addressObject,
            site_id: siteDetails.site_id,
            solar_system_size: state.projectDetails.solar_system_size || siteDetails.pv_size || '',
            project_type: siteDetails.site_type === 1 ? 'RESIDENTIAL' : siteDetails.site_type === 2 ? 'COMMERCIAL' : '',
            latitude: siteDetails.latitude,
            longitude: siteDetails.longitude,
          },
        };
      }
      return state;
    case SITE_DETAILS_FAILURE:
      return { ...state, siteDetails: null, currentlySending: false };
    case SITE_SEARCH_REQUEST:
      return { ...state, siteSearchInProgress: true };
    case SITE_SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        siteSearchInProgress: false,
        searchResults: action.searchList,
      };
    case SITE_SEARCH_REQUEST_FAILURE:
      return {
        ...state,
        siteSearchInProgress: false,
        messageType: 'error',
        message: action.error,
      };
    case CREATE_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        message: action.error,
        messageType: 'error',
        currentlySending: false,
      };
    case GET_PERMIT_LISTING_FAILURE:
      return { ...state, message: action.error, messageType: 'error', getPermittingRequetsInProgress: false };
    case GET_PERMIT_COUNT_BY_INSTALLER_FAILURE:
      return { ...state, currentlySending: false };
    case GET_PROJECT_DETAILS_REQUEST:
      return { ...state, currentlySending: true };
    case GET_PROJECT_DETAILS_SUCCESS: {
      const projectDetails = action.projectDetails;
      const addressObject = {
        ...(projectDetails.address1 && { address1: projectDetails.address1 }),
        ...(projectDetails.address2 && { address2: projectDetails.address2 }),
        ...(projectDetails.city && { city: projectDetails.city }),
        ...(projectDetails.county && { county: projectDetails.county }),
        ...(projectDetails.state && { state: projectDetails.state }),
        ...(projectDetails.country && { country: projectDetails.country }),
      };
      const address = projectDetails.full_address
        ? projectDetails.full_address
        : Object.keys(addressObject).reduce(
          (acu, key) => (acu ? acu + ', ' + addressObject[key] : addressObject[key]),
          ''
        );
      return {
        ...state,
        projectDetails: {
          ...action.projectDetails,
          address: address,
        },
        permitId: action.permitId,
        currentlySending: false,
      };
    }
    case GET_PROJECT_DETAILS_FAILURE:
      return { ...state, currentlySending: false };
    case UPDATE_PAYMENT_DETAILS_REQUEST:
      return {
        ...state,
        currentlySending: true,
      };
    case UPDATE_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        message: action.message,
        messageType: 'success',
        currentlySending: false,
        applicationID: action.applicationID,
        applicationDetail: action.applicationDetail,
      };
    case GET_UTILITY_PROVIDERS_SUCCESS:
      return {
        ...state,
        message: action.message,
        messageType: 'success',
        currentlySending: false,
        uProviders: action.uProviders,
      };
    case UPDATE_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case GET_RACKING_MFS_REQUEST:
      return {
        ...state,
        currentlySending: true,
      };
    case GET_RACKING_MFS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        racking_mfs: action.racking_mfs,
        message: action.message,
        messageType: 'success',
      };
    case GET_RACKING_MFS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case GET_AB_FEATURES_REQUEST:
      return {
        ...state,
        currentlySending: true,
      };
    case GET_AB_FEATURES_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        featureList: action.featureList,
        message: action.message,
        messageType: 'success',
      };
    case GET_AB_FEATURES_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case RESET_SITE_SEARCH_RESULT_REQUEST:
      return { ...state, searchResults: initialState.searchResults };
    case SET_PHOTOS:
      return { ...state, photos: { ...state.photos, ...action.payload } };
    case GET_CHAT_INFO_SUCCESS:
      return { ...state, unreadMessageCount: action.unreadMessageCount };
    case UPDATE_CHAT_STATUS_SUCCESS:
      return { ...state, unreadMessageCount: 0, messageAcknowledgedBy: action.messageAcknowledgedBy, messageList: action.messageList };
    case SET_CHAT_MESSAGE_SUCCESS:
      return { ...state, unreadMessageCount: action.unreadMessageCount, messageList: action.messageList };
    case SET_CLOSE_SAVE:
      return { ...state, isSaveandClose: action.isSaveandClose };
    case SET_ERROR:
      return { ...state, errors: action.payload };
    case SET_PAYMENT_LOADER:
      return { ...state, currentlySending: action.payload };
    case SET_ACTIVE_STEP:
      return { ...state, activeStep: action.activeStep };
    case ACCOUNT_DETAIL_REQUEST:
      return { ...state, accountLoading: true };
    case ACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        accountDetail: action.accountDetail,
        permitServiceRole: action.permitServiceRole,
        message: '',
        messageType: '',
        accountLoading: false,
      };
    case GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        companyInfo: action.companyInfo,
        company: action.company,
      };
    case REQUEST_FAILURE:
      return {
        ...state,
        message: action.error,
        messageType: 'error',
        currentlySending: false,
        isUnauthorized: action.isUnauthorized || false,
        accountLoading: false,
      };
    case GET_TEMPLATE_REQUEST:
      return { ...state, currentlySending: true };
    case GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        templates: action.templates,
        messageType: 'success',
      };
    case GET_TEMPLATE_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload,
      };

    case POST_TEMPLATE_SUCCESS:
      return {
        ...state,
      };
    case POST_TEMPLATE_FAILURE:
      return {
        ...state,
        message: action.message,
      };
    case SET_VIEW_TEMPLATE:
      return {
        ...state,
        viewTemplate: action.payload,
      };
    case PAYMENT_UPGRADE_TO_STORAGE:
      return { ...state, paymentUpgradeToStorage: action.payload };

    case GET_FEEDBACK_REQUEST:
      return { ...state, currentlySending: true };
    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        feedback: action.feedback,
      };
    case GET_FEEDBACK_FAILURE:
    case UPDATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        latestFeedback: action.latestFeedback,
      };
    case GET_MOST_USED_VALUES_REQUEST:
      return { ...state, currentlySending: true };
    case GET_MOST_USED_VALUES_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        mostUsedValues: action.mostUsedValues,
      };
    case GET_MOST_USED_VALUES_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case SET_APPLY_TEMPLATE:
      return {
        ...state,
        applyTemplate: action.payload,
      };
    case ADD_EMPTY_TEMPLATE:
      return {
        ...state,
        templates: [...state.templates, action.payload],
        disableAddTemplateButton: true,
      };
    case REMOVE_EMPTY_TEMPLATE:
      return {
        ...state,
        templates: state.templates.slice(0, -1),
        disableAddTemplateButton: false,
      };
    case POST_TEMPLATE_REQUEST:
      return {
        ...state,
        disableAddTemplateButton: false,
      };
    case CREATE_EMAIL_NPS_FEEDBACK_REQUEST:
      return {
        ...state,
        currentlySending: true,
        body: action.payload,
      };
    case CREATE_EMAIL_NPS_FEEDBACK_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: '',
        message: '',
      };
    case CREATE_EMAIL_NPS_FEEDBACK_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case SEND_STAMPING_REQUEST:
      return {
        ...state,
        currentlySending: true,
      };
    case SEND_STAMPING_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case SEND_STAMPING_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case GET_ARRAY_BUILDER_DETAILS_REQUEST:
      return {
        ...state,
        currentlySending: true,
      };
    case GET_ARRAY_BUILDER_DETAILS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        arrayBuilder: action.arrayBuilder,
      };
    case GET_ARRAY_BUILDER_DETAILS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case SET_V2_SOLAR_UI:
      return {
        ...state,
        isV2SolarUI: action.payload,
      };
    case SET_IS_FROM_PACKAGE:
      return { ...state, isFromPackage: action.isFromPackage };
    case GET_CART_URL_REQUEST:
      return {
        ...state,
      };
    case GET_CART_URL_SUCCESS:
      return {
        ...state,
        cartUrl: action.cartUrl,
      };
    case GET_CART_URL_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.error,
      };
    case GET_SG_MATERIALS_REQUEST:
      return {
        ...state,
        sgMaterialsLoading: true,
      };
    case GET_SG_MATERIALS_SUCCESS:
      return {
        ...state,
        sgMaterials: action.sgMaterials,
        sgMaterialsLoading: false,
      };
    case GET_SG_MATERIALS_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.error,
        sgMaterialsLoading: false,
      };
    case ADDITIONAL_STORAGE_REQUEST:
    case MORE_INFO_SUBMIT_REQUEST:
    case DRAFT_APPLICATION_REQUEST:
    case REWORK_REQUEST:
    case FILES_DOWNLOADED_REQUEST:
      return {
        ...state,
        currentlySending: true,
      };
    case ADDITIONAL_STORAGE_SUCCESS:
    case ADDITIONAL_STORAGE_FAILURE:
    case MORE_INFO_SUBMIT_SUCCESS:
    case MORE_INFO_SUBMIT_FAILURE:
    case DRAFT_APPLICATION_SUCCESS:
    case DRAFT_APPLICATION_FAILURE:
    case REWORK_REQUEST_SUCCESS:
    case REWORK_REQUEST_FAILURE:
    case FILES_DOWNLOADED_FAILURE:
    case FILES_DOWNLOADED_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case ENABLE_SUBMIT:
      return {
        ...state,
        enableSubmit: action.enableSubmit,
      };
    case GET_AHJID_BYNAME_REQUEST:
      return { ...state, currentlySending: true };
    case GET_AHJID_BYNAME_SUCCESS:
    case GET_AHJID_BYNAME_FAILURE:
      return { ...state, currentlySending: false };
    case GET_UTILITYID_BYNAME_REQUEST:
      return { ...state, currentlySending: true };
    case GET_UTILITYID_BYNAME_SUCCESS:
    case GET_UTILITYID_BYNAME_FAILURE:
      return { ...state, currentlySending: false };
    case GET_AHJ_RECOMMENDATIONS_REQUEST:
      return { ...state, currentlySending: true };
    case GET_AHJ_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case GET_AHJ_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.error,
        currentlySending: false,
      };
    case UPDATE_COUNTY:
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          county: action.payload,
        },
      };
    case SET_CHAT_AUTOMATED_MESSAGES_REQUEST:
      return { ...state, isChatAutomatedMessagesDisabled: action.payload };
    case GET_S3_URL_REQUEST:
      return { ...state, currentlySending: true };
    case GET_S3_URL_SUCCESS:
      return { ...state, currentlySending: false };
    case GET_S3_URL_FAILURE:
      return { ...state, currentlySending: false };
    case ORDER_STAMPS:
      return {
        ...state,
        inOrderStamps: action.payload,
      };
    case UPDATE_COORDINATES:
      return {
        ...state,
        materialDetails: {
          ...state.materialDetails,
          electrical_properties: {
            ...state.materialDetails.electrical_properties,
            coordinates: { lat: null, lng: null },
            um_coordinates: { lat: null, lng: null },
          },
        },
      };
    default:
      return state;
  }
}

export default reducer;
