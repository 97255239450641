import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(68),
    justifyContent: 'space-between',
    color: theme.palette.primary.dark,
    '& h5': {
      fontWeight: 'bold',
      fontSize: theme.spacing(3.5),
      marginBottom: theme.spacing(-1.5)
    }
  },
  dialogBody: {
    color: '#151515',
    border: '1px solid #c7c7c7',
    borderRadius: theme.spacing(0.5),
    '& p': { fontSize: theme.spacing(1.7) }
  },
  services: {
    fontSize: theme.spacing(1.5),
    margin: theme.spacing(2.5, 1.75, 2.5, 1.75),
    '& span': { fontWeight: 'bold' }
  },
  warning: {
    backgroundColor: '#fff5ef',
    border: '0.5px solid #ff6400',
    margin: theme.spacing(2.5, 1.75),
    padding: theme.spacing(0.625, 0.5, 0.375, 0.5)
  },
  confirmationMessage: { margin: theme.spacing(2.5, 0, 2.5, 1.75) },
  closeIcon: { marginBottom: theme.spacing(-1.5) },
  reportIcon: {
    padding: theme.spacing(0, 1),
    borderRadius: theme.spacing(4),
    color: theme.palette.primary.main
  },
  button: {
    color: theme.palette.primary.white,
    marginLeft: theme.spacing(1.5)
  },
  buttonWrapper: {
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 0, 2, 1.5)
  }
}));
const DirectSubmitDialog = props => {
  const classes = useStyles();
  const {
    open,
    servicesAvailed,
    handleClose = () => {},
    withTitle = true,
    withButtons = false,
    inOrderStamps,
    handleProceed = () => {}
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {withTitle && (
        <DialogTitle className={classes.dialogHeader} disableTypography>
          <Typography variant="h5">{inOrderStamps ? 'Order Stamps' : 'Submit Application'}</Typography>
          <IconButton onClick={handleClose} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      <Fragment>
        <DialogContent>
          <DialogContentText>
            <Box className={classes.dialogBody}>
              <Typography className={classes.services}>
                Service Avail: <span>{[ ...new Set(servicesAvailed) ].join(', ')}</span>
              </Typography>
              <Typography className={classes.warning}>
                <IconButton className={classes.reportIcon}>
                  <ErrorIcon />
                </IconButton>
                Charges will be added to your monthly billing cycle
              </Typography>
              <Typography className={classes.confirmationMessage}>
                Would you like to submit the application ?
              </Typography>

              {withButtons && (
                <div className={classes.buttonWrapper}>
                  <Button onClick={handleClose} color="primary" variant="outlined" disableElevation>
                    No
                  </Button>
                  <Button
                    onClick={handleProceed}
                    color="primary"
                    variant="contained"
                    disableElevation
                    className={classes.button}
                  >
                    Yes
                  </Button>
                </div>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Fragment>
    </Dialog>
  );
};

export default DirectSubmitDialog;
