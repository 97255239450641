import { STANDING_STEAM_ROOF_TYPES } from '../../../components/Permitting/Array/constants';
import { getAcceptablePanelsRange } from '../../../utils/helper';
import { keysIn, isEmpty, get, cloneDeep } from '../../../utils/lodash';
import { ELECTRICAL_TAGS, PROJECT_TYPES, FEATURE_FLAG_TYPE, FEATURE_FLAGS } from '../constants';
import { validateSubpanelFields, validateSubpanelFieldsByKey } from './validateSubpanelFields';

// Feature Flag helper
import { isFeatureEnabled } from '../../../containers/Permitting/helper';
import { countDecimals } from '../../../containers/Admin/helper';

const FIELD_REQUIRED = 'This field is required.';
const MSP_REQUIRED = 'MSP location is required.';
const UM_REQUIRED = 'Utility Meter location is required.';

const FIELD_MUST_BE_POSTITIVE_INTEGER = 'Only Postitive Integers are allowed including 0.';

const PITCH_LIMIT = 'The value should be between 0 and 359';

const QUANTITY_LIMIT = 'The value should be greater than 0';

const SOLAR_SIZE_LIMT_ERROR = 'We do not support project with system size more than 500 KW on Enlighten platform.';

const VALID_PHONE = 'Please enter a valid phone number.';

const VALID_NUMBER_LENGTH = 'Value should be greater than 0 and less than or equal to 1000';

const VALID_DECIMAL_POINT = 'Please enter upto 3 decimal places only';

const VALID_NUMBER = 'Please enter a valid number';

export const validateDetails = (props) => {
  const {
    errors,
    data,
    key,
    additionalKey = '',
    arrayIndex = -1,
    solarSystemSize,
    isArrayAdd,
    isArrayRemove,
    isFrom = '',
    activeStep,
    isExistingPermitPackageUploaded,
    additionalDetails = {},
    featureList,
  } = props;

  const isOptionalPhotoUploadEnabled = isFeatureEnabled(
    featureList,
    FEATURE_FLAGS.OPTIONAL_PHOTO_UPLOAD,
    FEATURE_FLAG_TYPE.company
  );

  const partialStorageBackup = get(data, 'projectDetails.home_backup_option', '') === 'partial';
  const storageSelected = get(data, 'projectDetails.home_backup_option', '') === 'partial' || get(data, 'projectDetails.home_backup_option', '') === 'full' || get(data, 'projectDetails.home_backup_option', '') === 'gridTied';
  const arraysKeysToSkip = [
    'max_rafter_span',
    'structural_number',
    'structural_at',
    'structural_type',
    'other_structural_number',
  ];

  const skipMetalRoofTypeKeyValidate = ['max_rafter_span', 'structural_number', 'other_structural_number'];
  const {
    arrays = [],
    existing_arrays = [],
    electrical_properties = {},
    racking_attachment_properties = {},
    equipment_location = {},
    interconnection_properties,
    projectDetails,
    photos,
    installer_contact_phone,
    third_party_generator,
  } = data;
  const {
    service_type = '',
    utility_meter_location = '',
    feeder_type = '',
    msp_location = '',
    utility_meter_map_location = '',
    location_image_file_name = '',
    existing_subpanel = '',
    subpanel_breaker_rating = '',
    subpanel_bus_rating = '',
    main_bus_rating = '',
    main_breaker_rating = '',
    coordinates = { lat: null, lng: null },
    um_coordinates = { lat: null, lng: null },
    existing_generator = null,
    generator_connection = null,
    generator_manufacturer = '',
    generator_model = '',
    generator_type = 'Generator',
    generator_quantity = 1,
    generator_photos = [],
    subpanels = [],
    subpanelExisting = null,
    backupExistingSubpanel,
  } = !isEmpty(electrical_properties) && electrical_properties;

  const {
    racking_manufacturer = '',
    attachment_manufacturer = '',
    racking_model = '',
    attachment_model = '',
    maxSpacingBetweenAttachments = '',
    wind_load = '',
    snow_load = ''
  } = !isEmpty(racking_attachment_properties) && racking_attachment_properties;
  const interconnection_strategy = get(interconnection_properties, 'interconnection_strategy', '');
  const interconnection_location = get(interconnection_properties, 'interconnection_location', '');
  let fieldsToValidate = {
    ...{ arrays },
    electrical_properties,
    third_party_generator,
    racking_attachment_properties,
    equipment_location,
    interconnection_properties,
    ...(!isExistingPermitPackageUploaded && { existing_arrays }),
    projectDetails,
    installer_contact_phone,
  };
  const arrayKeysToValidate = [
    'quantity',
    'pitch_deg',
    'structural_type',
    'structural_number',
    'other_structural_number',
    'panel_manufacturer',
    'structural_at',
    'panel_model',
    'installation_type',
    'roof_type',
    'panel_wattage',
    'roof_pitch',
  ];
  const arrayKeysWithOtherOption = ['panel_manufacturer', 'panel_model', 'installation_type', 'roof_type'];
  const electricalPhotosKey = ['service_panel_without_dead_front','msp_type','utility_meter'];

  const electricalFieldsToValidate = {
    service_type,
    utility_meter_location,
    feeder_type,
    main_bus_rating,
    main_breaker_rating,
    msp_location,
    coordinates,
    um_coordinates,
    existing_generator,
    backupExistingSubpanel,
    ...(existing_generator === 'Yes' && { generator_connection }),
    ...(existing_generator === 'Yes' && { generator_manufacturer }),
    ...(existing_generator === 'Yes' && { generator_model }),
  };
  const rackingAttachmentFieldsToValidate = {
    racking_manufacturer,
    attachment_manufacturer,
    racking_model,
    attachment_model,
    maxSpacingBetweenAttachments,
    wind_load,
    snow_load
  };
  const interconnectionToValidate = {
    interconnection_strategy,
    interconnection_location,
  };
  const keysInValidate = keysIn(fieldsToValidate);

  const noErrorObj = {
    error: false,
    errorMessage: '',
  };

  const noWarningObj = {
    warning: false,
  };

  const fieldRequired = {
    error: true,
    errorMessage: FIELD_REQUIRED,
  };

  const utilityMeterRequired = {
    error: true,
    errorMessage: UM_REQUIRED,
  };

  const fieldMustBepositiveInteger = {
    error: true,
    errorMessage: FIELD_MUST_BE_POSTITIVE_INTEGER,
  };

  const mspLocationRequired = {
    error: true,
    errorMessage: MSP_REQUIRED,
  };

  const pitchLimit = {
    error: true,
    errorMessage: PITCH_LIMIT,
  };

  const quantityLimit = {
    error: true,
    errorMessage: QUANTITY_LIMIT,
  };

  const validPhone = {
    error: true,
    errorMessage: VALID_PHONE,
  };

  const validNumberLength = {
    error: true,
    errorMessage: VALID_NUMBER_LENGTH,
  }

  const validDecimalPoint = {
    error: true,
    errorMessage: VALID_DECIMAL_POINT
  }

  const validNumber = {
    error: true,
    errorMessage: VALID_NUMBER
  }

  const resetErrorObj = (key) => {
    errors[key] = noErrorObj;
  }

  keysInValidate.forEach((key) => {
    resetErrorObj(key);
  });

  const getArrayErrorObj = (value, key, errorList, fields, keyGlobal, index) => {
    const modifiedValue = parseInt(value, 10);
    const isGroundMount =
      get(fields, keyGlobal, []).length > index &&
      get(fields, keyGlobal)[index].installation_type &&
      get(fields, keyGlobal)[index].installation_type.toLowerCase() === 'ground mount';
    let isStandingSteam = false;
    if (key === 'structural_number' || key === 'other_structural_number') {
      const roofType = fields[keyGlobal][index]['roof_type'];
      isStandingSteam = STANDING_STEAM_ROOF_TYPES.includes(roofType);
    }
    let errorObj = {};
    if (key === 'max_rafter_span') {
      errorObj = {
        ...errorList,
      };
    } else if ((key === 'azimuth' || key === 'pitch_deg') && Number(modifiedValue) >= 0) {
      if (modifiedValue < 0 || modifiedValue > 359) {
        errorObj = {
          ...errorList,
          [key]: pitchLimit,
        };
      } else {
        errorObj = {
          ...errorList,
          [key]: noErrorObj,
        };
      }
    } else if ((key === 'quantity' || key === 'panel_wattage') && Number(modifiedValue) >= 0) {
      if (modifiedValue > 0) {
        errorObj = {
          ...errorList,
          [key]: noErrorObj,
        };
      } else {
        errorObj = {
          ...errorList,
          [key]: quantityLimit,
        };
      }
    } else if (key === 'roof_pitch') {
      errorObj =
        value === ''
          ? {
              ...errorList,
              [key]: fieldRequired,
            }
          : {
              ...errorList,
              [key]: noErrorObj,
            };
    } else if (key === 'structural_at' && Number(modifiedValue) >= 0) {
      errorObj = {
        ...errorList,
        [key]: noErrorObj,
      };
    } else if ((key === 'structural_number' || key === 'structural_type') && !isEmpty(value)) {
      errorObj = {
        ...errorList,
        [key]: noErrorObj,
      };
    } else if (
      key === 'other_structural_number' &&
      (!isEmpty(value) ||
        (get(fields, keyGlobal, []).length > index &&
          !isEmpty(get(fields, keyGlobal)[index].structural_number) &&
          get(fields, keyGlobal)[index].structural_number !== 'Other'))
    ) {
      errorObj = {
        ...errorList,
        [key]: noErrorObj,
      };
    } else if (!isEmpty(value)) {
      if (arrayKeysWithOtherOption.includes(key) && (value === 'Other-' || value === 'Other')) {
        errorObj = {
          ...errorList,
          [key]: fieldRequired,
        };
      } else {
        errorObj = {
          ...errorList,
          [key]: noErrorObj,
        };
      }
    } else if (arraysKeysToSkip.includes(key) && isGroundMount) {
      errors[key] = noErrorObj;
    } else if (key === 'roof_type' && isGroundMount) {
      errors[key] = noErrorObj;
    } else if (key === 'installation_type' && isGroundMount) {
      errors['roof_type'] = noErrorObj;
      arraysKeysToSkip.forEach((skipkey) => {
        errors[skipkey] = noErrorObj;
      });
    } else if (skipMetalRoofTypeKeyValidate.includes(key) && isStandingSteam) {
      errors[key] = noErrorObj;
    } else {
      errorObj = {
        ...errorList,
        [key]: fieldRequired,
      };
    }
    return errorObj;
  };
  const arrayKeyValidation = (arrays, additionalKey, arrayIndex, fields, key) => {
    let arrayError = (errors && errors[key]) || [];
    if (additionalKey && arrayKeysToValidate.includes(additionalKey)) {
      const errorList = (errors && errors[key] && errors[key][arrayIndex]) || {};
      const errorObj = getArrayErrorObj(
        arrays[arrayIndex][additionalKey],
        additionalKey,
        errorList,
        fields,
        key,
        arrayIndex
      );
      arrayError[arrayIndex] = {
        ...arrayError[arrayIndex],
        ...errorObj,
      };
    }
    return arrayError;
  };

  const getArrayQtySum = (arrays) => {
    let qtySum = 0;
    if (!isEmpty(arrays)) {
      for (let i in arrays) {
        qtySum += Number(arrays[i].quantity);
      }
    }
    return qtySum;
  };

  const arrayQtyRangeValidation = (arrays, existing_arrays, errorSection) => {
    let qtySum = getArrayQtySum(arrays);
    qtySum += getArrayQtySum(existing_arrays);
    const qtyRange = getAcceptablePanelsRange(solarSystemSize);
    errors['maxArrayQty'] = noWarningObj;
    errors['minArrayQty'] = noWarningObj;
    if (qtySum > qtyRange.max) {
      errors['maxArrayQty'] = {
        warning: true,
        solarPanels: qtySum,
        solarSize: solarSystemSize,
        errorSection: errorSection,
      };
    } else if (qtySum < qtyRange.min) {
      errors['minArrayQty'] = {
        warning: true,
        solarPanels: qtySum,
        solarSize: solarSystemSize,
        errorSection: errorSection,
      };
    }
  };

  const arrayAllKeyValidation = (arrays, fields, keyGlobal) => {
    let arrayError = [];
    !isEmpty(arrays) &&
      arrays.forEach((array, index) => {
        keysIn(array).forEach((key) => {
          if (arrayKeysToValidate.includes(key)) {
            const value = array[key];
            const errorList = arrayError[index] || (errors && errors[keyGlobal] && errors[keyGlobal][index]) || {};
            const errorObj = getArrayErrorObj(value, key, errorList, fields, keyGlobal, index);
            arrayError[index] = {
              ...arrayError[index],
              ...errorObj,
            };
          }
        });
      });
    return arrayError;
  };

  const arraysValidation = (arrays, fields, key) => {
    if (!isEmpty(additionalKey) && arrayIndex >= 0) {
      return arrayKeyValidation(arrays, additionalKey, arrayIndex, fields, key);
    } else {
      return arrayAllKeyValidation(arrays, fields, key);
    }
  };

  /**
   * Return the list of photos added for given key
   * @param key key can be service_type, utility_meter_location, subpanel_breaker_rating
   */
  const getPhotosByKey = (key) => {
    const images = [];
    for (let index in photos) {
      let photo = photos[index];
      photo['ind'] = index;
      if (ELECTRICAL_TAGS[key] === photo.tag) {
        images.push(photo);
      }
    }
    return images;
  };

  const validateElectricalDetailsPhotos = () => {
    if (isEmpty(additionalKey)) {
      electricalPhotosKey.forEach((key) => {
        errors[key] = isOptionalPhotoUploadEnabled || getPhotosByKey(key).length > 0 ? noErrorObj : fieldRequired;
      });
    } else {
      if (additionalKey === ELECTRICAL_TAGS['msp_type']) {
        errors['msp_type'] =
          isOptionalPhotoUploadEnabled || getPhotosByKey('msp_type').length > 0 ? noErrorObj : fieldRequired;
      } else if (additionalKey === ELECTRICAL_TAGS['utility_meter']) {
        errors['utility_meter'] =
          isOptionalPhotoUploadEnabled || getPhotosByKey('utility_meter').length > 0 ? noErrorObj : fieldRequired;
      } else if (additionalKey === ELECTRICAL_TAGS['service_panel_without_dead_front']) {
        errors['service_panel_without_dead_front'] =
          isOptionalPhotoUploadEnabled || getPhotosByKey('service_panel_without_dead_front').length > 0
            ? noErrorObj
            : fieldRequired;
      }
    }
  };

  const validateOtherDocument = (key) => {
    errors[key] = data['documents'].length > 0 ? noErrorObj : fieldRequired;
  };
  const errorMessage = (key, fields) => {
    let field = (fields[key] && fields[key].toString()) || '';
    if (activeStep >= 1 && key === 'projectDetails') {
      if (
        fields?.projectDetails?.project_type === PROJECT_TYPES.COMMERCIAL &&
        Number(fields?.projectDetails?.solar_system_size) > 500
      ) {
        errors['solar_system_size'] = {
          error: true,
          errorMessage: SOLAR_SIZE_LIMT_ERROR,
        };
      } else {
        errors['solar_system_size'] = { error: false };
      }
    } else if (activeStep >= 1 && (key === 'arrays' || key === 'existing_arrays')) {
      if (isFrom === 'project-details') {
        errors[key] = [];
      } else {
        errors[key] = arraysValidation(fields[key], fields, key);
        if (additionalKey === 'quantity' || isArrayRemove || isArrayAdd) {
          arrayQtyRangeValidation(fields['arrays'], fields['existing_arrays'], key);
        }
      }
    } else if (activeStep >= 2 && key === 'electrical_properties') {
      if (isEmpty(additionalKey)) {
        keysIn(electricalFieldsToValidate).forEach((electricalKey) => {
          errorMessage(electricalKey, electricalFieldsToValidate);
        });
      } else {
        keysIn(electricalFieldsToValidate).includes(additionalKey) &&
          errorMessage(additionalKey, electricalFieldsToValidate);
      }
    } else if (activeStep >= 2 && key === 'interconnection_properties') {
      if (isEmpty(additionalKey)) {
        const keys = keysIn(interconnectionToValidate);
        keys.forEach((electricalKey) => {
          errorMessage(electricalKey, interconnectionToValidate);
        });
      } else {
        keysIn(interconnectionToValidate).includes(additionalKey) &&
          errorMessage(additionalKey, interconnectionToValidate);
      }
    } else if (activeStep >= 1 && key === 'racking_attachment_properties' && isFrom !== 'project-details') {
      if (isEmpty(additionalKey)) {
        keysIn(rackingAttachmentFieldsToValidate).forEach((rackingAttachmentKey) => {
          errorMessage(rackingAttachmentKey, rackingAttachmentFieldsToValidate);
        });
      } else {
        keysIn(rackingAttachmentFieldsToValidate).includes(additionalKey) &&
          errorMessage(additionalKey, rackingAttachmentFieldsToValidate);
      }
    } else if (activeStep >= 1 && (key === 'wind_load' || key === 'snow_load' || key === 'maxSpacingBetweenAttachments')) {
      let fieldValue = fields[key];
      if (key === 'maxSpacingBetweenAttachments') {
        let maxSpace = get(data, 'racking_attachment_properties.attachment_spacing', '')
        if (maxSpace.includes('Other-')) {
          fieldValue = maxSpace.trim().split('Other-')[1];
        } else if (maxSpace === 'Other') {
          fieldValue = '';
        } else {
          fieldValue = maxSpace;
        }
      }
      if (!isEmpty(fieldValue)) {
        if (fieldValue > 1000 || (fieldValue <= 0 && key !== 'maxSpacingBetweenAttachments')) {
          errors[key] = validNumberLength;
        } else if (countDecimals(fieldValue) > 3) {
          errors[key] = validDecimalPoint;
        } else if (isNaN(Number(fieldValue))) {
          errors[key] = validNumber;
        } else {
          errors[key] = noErrorObj;
        }
      } else {
        errors[key] = noErrorObj;
      }
    } else if (activeStep >= 1 && key === 'third_party_generator' && third_party_generator === null) {
      errors[key] = storageSelected === false ? noErrorObj : fieldRequired;
    } else if (activeStep >= 2 && key === 'main_bus_rating' && main_bus_rating === 'Other') {
      errors[key] = fieldRequired;
    } else if (activeStep >= 2 && key === 'main_breaker_rating' && main_breaker_rating === 'Other') {
      errors[key] = fieldRequired;
    } else if (activeStep >= 2 && key === 'service_type' && service_type === 'Other') {
      errors[key] = fieldRequired;
    } else if (electricalPhotosKey.includes(key)) {
      errors[key] = getPhotosByKey(key).length > 0 || !isEmpty(field) ? noErrorObj : fieldRequired;
    } else if (activeStep >= 2 && key === 'coordinates') {
      field = fields[key];
      if ((field === null || field.lat === null || field.lng === null) && !location_image_file_name) {
        errors[key] = mspLocationRequired;
      } else {
        errors[key] = noErrorObj;
      }
    } else if (activeStep >= 2 && key === 'um_coordinates') {
      field = fields[key];
      if ((field === null || field.lat === null || field.lng === null) && !location_image_file_name) {
        errors[key] = utilityMeterRequired;
      } else {
        errors[key] = noErrorObj;
      }
    } else if (activeStep >= 2 && key === 'existing_generator' && existing_generator === null) {
      errors[key] = fieldRequired;
    } else if (activeStep >= 2 && key === 'backupExistingSubpanel') {
      if (partialStorageBackup) {
        errors[key] = backupExistingSubpanel === true || backupExistingSubpanel === false ? noErrorObj : fieldRequired;
      }
    } else if (activeStep >= 2 && key === 'equipment_location') {
      if (projectDetails.home_backup_option) {
        equipment_location.forEach((equipmentLocationLabel) => {
          if (equipmentLocationLabel.item == 'Encharge Location') {
            errors['encharge_location'] = isEmpty(equipmentLocationLabel.location) ? fieldRequired : noErrorObj;
          }
        });
      }
    } else if (
      activeStep >= 2 &&
      key === 'generator_connection' &&
      existing_generator === 'Yes' &&
      generator_connection === null
    ) {
      errors[key] = fieldRequired;
    } else if (
      activeStep >= 2 &&
      key === 'generator_manufacturer' &&
      existing_generator === 'Yes' &&
      generator_manufacturer === ''
    ) {
      errors[key] = fieldRequired;
    } else if (activeStep >= 2 && key === 'generator_model' && existing_generator === 'Yes' && generator_model === '') {
      errors[key] = fieldRequired;
    } else if (key === 'installer_contact_phone') {
      if (isEmpty(field)) {
        errors[key] = noErrorObj;
      } else if (activeStep >= 3) {
        const phoneNumberFormat = /^[- +()]*[0-9][- +()0-9]*$/;
        const isValidPhone = field.match(phoneNumberFormat);
        errors[key] = isValidPhone ? noErrorObj : validPhone;
      }
    } else if (isEmpty(field) || field === null || field === undefined) {
      errors[key] = fieldRequired;
      if (isFrom === 'project-details') {
        if (key === 'storage_size' || key === 'interconnection_strategy_enpower') {
          errors[key] = fieldRequired;
        } else {
          errors[key] = noErrorObj;
        }
      }
    } else {
      errors[key] = noErrorObj;
    }
  };

  if (isEmpty(key)) {
    if (activeStep === 2) {
      validateElectricalDetailsPhotos();
      validateSubpanelFields(
        errors,
        subpanels,
        backupExistingSubpanel,
        partialStorageBackup,
        subpanelExisting,
        fieldRequired,
        additionalDetails
      );
    }
    // This field will we optional if isOptionalPhotoUploadEnabled is true
    else if (activeStep === 3 && !isOptionalPhotoUploadEnabled) {
      validateOtherDocument('otherInfo_document');
    }
    keysInValidate.forEach((key) => {
      errorMessage(key, fieldsToValidate);
    });
  } else {
    if (activeStep === 2) {
      if (!isEmpty(additionalDetails)) {
        validateElectricalDetailsPhotos();
        if (additionalDetails.validateSubpanelKeys) {
          validateSubpanelFieldsByKey(
            errors,
            subpanels,
            backupExistingSubpanel,
            partialStorageBackup,
            subpanelExisting,
            fieldRequired,
            additionalDetails,
            noErrorObj
          );
        }
      }
    }
    // This field will we optional if isOptionalPhotoUploadEnabled is true
    else if (activeStep === 3 && !isOptionalPhotoUploadEnabled) {
      validateOtherDocument('otherInfo_document');
    }
    keysInValidate.includes(key) && errorMessage(key, fieldsToValidate);
  }
  return errors;
};

export default validateDetails;
