export const CONDUIT_LOCATION_OPTIONS = [
  { title: 'Roof top', value: 'roof_top' },
  { title: 'Attic conduit only when roof pitch is greater than 9.5', value: 'attic_conduit' },
  { title: 'All', value: 'all' },
];

export const ROOF_ACCESS_POINT_OPTIONS = [
  { title: 'Yes, minimum 2 are required', value: 'yes_min_2_are_required' },
  { title: 'No', value: 'no' },
];

export const BUILDING_ELEVATION_OPTIONS = [
  { title: 'Yes, when the site address falls under the flood zone', value: 'yes_site_address_falls_under_flood_zone' },
  { title: 'Yes, when the roof is flat', value: 'yes_roof_is_flat' },
  { title: 'Yes', value: 'yes' },
  { title: 'No', value: 'no' },
];

export const FLOOD_MAP_OPTIONS = [
  { title: 'Yes, when the site address falls under the flood zone', value: 'yes_site_address_falls_under_flood_zone' },
  { title: 'Yes', value: 'yes' },
  { title: 'No', value: 'no' },
];

export const YES_NO_OPTIONS = [
  { title: 'Yes', value: Boolean(true) },
  { title: 'No', value: Boolean(false) },
];

export const MIN_EGC_CONDUCTOR_OPTIONS = [
  { title: '6', value: '6' },
  { title: '8', value: '8' },
  { title: '10', value: '10' },
];
export const CONDUIT_TYPE_OPTIONS = [
  { title: 'RMC', value: 'rmc' },
  { title: 'PVC', value: 'pvc' },
  { title: 'EMT', value: 'emt' },
  { title: 'All', value: 'all' },
];
export const INTERCONNECTION_METHODS_ALLOWED = [
  { title: 'Load breaker', value: 'load_breaker' },
  { title: 'Supply breaker', value: 'supply_breaker' },
  { title: 'Protected load tap', value: 'protected_load_tap' },
  { title: 'Supply tap', value: 'supply_tap' },
  { title: 'All', value: 'all' },
];

export const AC_DISCONNECT_OPTIONS = [
  {
    title:
      'Yes, when the electrical equipment is not installed in line of sight or located more than 10ft from the interconnection point',
    value: 'yes_battery_not_in_line_of_sight',
  },
  { title: 'Yes', value: 'yes' },
];
export const EQUIPMENT_ELEVATION_OPTIONS = [
  { title: 'Yes, when battery is installed', value: 'yes_battery_is_installed' },
  { title: 'Yes', value: 'yes' },
];
export const PANEL_SCHEDULE_OPTIONS = [
  {
    title:
      'Yes, when your system require any of the following for interconnection: derate existing main breaker, install battery backup, use protected load tap, Upgrade main service panel, Breaker size adjustment (for interconnection rule)',
    value: 'yes_if_system_requires_interconnection_conditions',
  },
  { title: 'Yes', value: 'yes' },
  { title: 'No', value: 'no' },
];

export const SEPARATE_BATTERY_DISCONNECT_OPTIONS = [
  {
    title:
      'Yes, when the battery is not installed in the line of sight or located more than 10ft from the interconnection point',
    value: 'yes_battery_not_in_line_of_sight',
  },
  { title: 'Yes', value: 'yes' },
  { title: 'No', value: 'no' },
];
export const GARAGE_FLOOR_PLAN_OPTIONS = [
  { title: 'Yes, when the battery is installed inside the garage', value: 'yes_battery_is_installed_inside_garage' },
  { title: 'No', value: 'no' },
];

export const PAGE_SIZE_OPTIONS = [
  { title: '8.5X11', value: '8.5X11' },
  { title: '11X17', value: '11X17' },
  { title: '18X24', value: '18X24' },
  { title: '24X36', value: '24X36' },
];
