import React, { useState } from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { AddCircleOutline } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Box,
  Typography,
  FormControl,
  makeStyles,
  DialogContentText,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEqual, get, isEmpty } from '../../../../../utils/lodash';

import { Label } from '../../../../../components/common/Label';
import { withStyles } from '@material-ui/styles';
import { captureRejectionSymbol } from 'events';
import Loader from '../../../../../components/Loader';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  dialogWidth: {
    minWidth: '40%',
    maxWidth: '40%',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 'unset',
  },
  inputFieldInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3, 1),
  },
  inputFieldHeading: {
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
  },
  inputFieldHint: {
    fontSize: theme.spacing(1.25),
  },
  autocompleteField: {
    padding: theme.spacing(0, 3),
  },
  installerTag: {
    color: theme.palette.primary.white,
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },
  installerTagArrow: {
    color: 'black',
  },
  installerTagTooltip: {
    position: 'relative',
    top: theme.spacing(1),
    background: 'black',
  },
  deleteIcon: {
    fill: theme.palette.primary.white,
  },
  information: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1, 3),
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  informationText: {
    margin: 'unset',
    fontSize: theme.spacing(1.5),
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
    '& .MuiInputBase-root.Mui-disabled:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  radioWrapper: {
    minWidth: theme.spacing(30),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  radioTitle: {
    marginBottom: theme.spacing(1),
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    position: 'relative',
  },
  showButton: {
    marginLeft: 'auto',
    minWidth: '150px',
    height: 'auto',
    padding: theme.spacing(0.25),
    marginBottom: theme.spacing(-1.5),
    flexDirection: 'column',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3, 2),
  },
  cancelButton: {
    borderColor: 'black',
  },
  saveButton: {
    color: 'white',
    boxShadow: 'none',
  },
  uploadDataField: {
    width: '44%',
    margin: theme.spacing(5, 1, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  uploadDataLabel: {
    margin: theme.spacing(1, 0),
  },
  filesListWrapper: {
    border: `1px solid #bbb`,
    borderRadius: theme.spacing(0.5),
    maxHeight: theme.spacing(10),
    marginRight: theme.spacing(-1),
  },
  fileAttachment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75),
  },
  fileNameIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto',
  },
  iconCoverChat: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
    '& .MuiIconButton-label': {
      height: '100%',
      '& svg': {
        height: '100%',
      },
    },
  },
  popupCloseBtn: {
    color: '#335977',
    align: 'right',
    float: 'right',
    minWidth: theme.spacing(2.5),
  },
  errorText: {
    fontSize: theme.spacing(1.75),
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0.3),
  },
  hidden: {
    display: 'none',
  },
}));

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiIconButton-root': {
      borderRadius: 0,
    },
    '& .highcharts-credits': {
      display: 'none',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    zIndex: 9999,
  },
});
const AddMode = (props) => {
  const {
    handleSubmit,
    basicDetails,
    errorData,
    handleBasicDetails,
    modeId,
    manufacturersList,
    modesList,
    handleShowAddModePopup,
    modeMapping,
    handleCloseAddModePopup,
    isEdit,
  } = props;
  const { manufacturer = '', energy_independence = '', savings = '', backup = '' } = basicDetails;
  const [existingModeMapping, setExistingModeMapping] = useState(false);
  const [currentlySending, setCurrentlySending] = useState(false);

  useEffect(() => {
    if (isEdit)
      if (modeMapping) {
        handleBasicDetails(null, 'manufacturer', modeMapping);
        handleBasicDetails(null, 'energy_independence', modeMapping?.metadata?.energyIndependence);
        handleBasicDetails(null, 'savings', modeMapping?.metadata?.savingsOnElectricityBill);
        handleBasicDetails(null, 'backup', modeMapping?.metadata?.backupDuringOutage);
      }
  }, [modeMapping]);

  function getHandleChange(e, key, value) {
    const { handleBasicDetails, modesList } = props;
    handleBasicDetails(e, key, value);
    let manufacturer = value;
    if (manufacturer) {
      setCurrentlySending(true);
      if (modesList.filter((mode) => mode.id === manufacturer.id).length > 0) {
        setCurrentlySending(false);
        setExistingModeMapping(true);
      } else {
        setCurrentlySending(false);
        setExistingModeMapping(false);
      }
    }
  }

  const classes = useStyles();

  const dialogHeaderText = isEqual(modeId, 'new') ? 'Add Operating Mode' : 'Edit Operating Mode';

  return (
    <Box className={classes.subRoot}>
      <Dialog
        open
        onClose={handleShowAddModePopup}
        aria-labelledby="customized-dialog-title"
        classes={{ paper: classes.dialogWidth }}
        disableEscapeKeyDown
      >
        <DialogTitle id="customized-dialog-title">
          <Box className={classes.dialogTitle}>
            <b>{dialogHeaderText}</b>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <FormControl fullWidth>
            <Autocomplete
              id="manufacturer"
              value={manufacturer}
              disabled={isEdit}
              options={manufacturersList}
              getOptionLabel={(option) => option.name || ''}
              disableClearable
              onChange={(e, value) => getHandleChange(e, 'manufacturer', value)}
              renderInput={(params) => <TextField {...params} label={<Label text={'Battery Manufacturer'} />} />}
            />
            <TextField
              id={'energy_independence'}
              className={classes.inputField}
              label={<Label text={'Energy Independence'} />}
              value={energy_independence || ''}
              onChange={(e, value) => handleBasicDetails(e, 'energy_independence', value)}
            />
            <TextField
              id={'savings'}
              className={classes.inputField}
              label={<Label text={'Savings on Electricity Bills'} />}
              value={savings || ''}
              onChange={(e, value) => handleBasicDetails(e, 'savings', value)}
            />
            <TextField
              id={'backup'}
              className={classes.inputField}
              label={<Label text={'Backup During Outrages'} />}
              value={backup || ''}
              onChange={(e, value) => handleBasicDetails(e, 'backup', value)}
            />

            {existingModeMapping && (
              <Box>
                <br />
                <Typography className={classes.errorText}>
                  Operating modes are already added for this manufacturer.
                </Typography>
              </Box>
            )}
            {currentlySending == true ? (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            ) : null}
          </FormControl>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleShowAddModePopup} variant="outlined" color="default" className={classes.cancelButton}>
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.saveButton}
            disabled={
              !(
                basicDetails.manufacturer &&
                basicDetails.energy_independence &&
                basicDetails.savings &&
                basicDetails.backup &&
                !existingModeMapping &&
                !currentlySending
              )
            }
            onClick={handleSubmit}
          >
            {'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddMode));
