import React from 'react';

function AddOrange(props) {
  const { disabled } = props;
  return (
    <svg
      id="add_2_"
      data-name="add (2)"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ marginRight: '8px' }}
    >
      <path
        id="Path_2563"
        data-name="Path 2563"
        d="M17.5,24A6.5,6.5,0,1,1,24,17.5,6.508,6.508,0,0,1,17.5,24Zm0-11.5a5,5,0,1,0,5,5A5.006,5.006,0,0,0,17.5,12.5Z"
        fill={disabled ? 'rgba(0,0,0,0.26)' : '#f37320'}
      />
      <path
        id="Path_2564"
        data-name="Path 2564"
        d="M17.5,21a.75.75,0,0,1-.75-.75v-5.5a.75.75,0,0,1,1.5,0v5.5A.75.75,0,0,1,17.5,21Z"
        fill={disabled ? 'rgba(0,0,0,0.26)' : '#f37320'}
      />
      <path
        id="Path_2565"
        data-name="Path 2565"
        d="M20.25,18.25h-5.5a.75.75,0,0,1,0-1.5h5.5a.75.75,0,0,1,0,1.5Z"
        fill={disabled ? 'rgba(0,0,0,0.26)' : '#f37320'}
      />
      <path
        id="Path_2566"
        data-name="Path 2566"
        d="M9.19,21H2.75A2.752,2.752,0,0,1,0,18.25V2.75A2.752,2.752,0,0,1,2.75,0h11.5A2.752,2.752,0,0,1,17,2.75V8.84a.75.75,0,0,1-1.5,0V2.75A1.252,1.252,0,0,0,14.25,1.5H2.75A1.252,1.252,0,0,0,1.5,2.75v15.5A1.252,1.252,0,0,0,2.75,19.5H9.19a.75.75,0,0,1,0,1.5Z"
        fill={disabled ? 'rgba(0,0,0,0.26)' : '#f37320'}
      />
      <path
        id="Path_2567"
        data-name="Path 2567"
        d="M13.25,9.5H3.75a.75.75,0,0,1,0-1.5h9.5a.75.75,0,0,1,0,1.5Z"
        fill={disabled ? 'rgba(0,0,0,0.26)' : '#f37320'}
      />
      <path
        id="Path_2568"
        data-name="Path 2568"
        d="M9.25,13.5H3.75a.75.75,0,0,1,0-1.5h5.5a.75.75,0,0,1,0,1.5Z"
        fill={disabled ? 'rgba(0,0,0,0.26)' : '#f37320'}
      />
      <path
        id="Path_2569"
        data-name="Path 2569"
        d="M8.25,5.5H3.75a.75.75,0,0,1,0-1.5h4.5a.75.75,0,0,1,0,1.5Z"
        fill={disabled ? 'rgba(0,0,0,0.26)' : '#f37320'}
      />
    </svg>
  );
}

export default AddOrange;
