import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField
} from '@material-ui/core';
import { getStatusDetails, getAssignedTo } from '../../../containers/Admin/helper';
import moment from 'moment';
import { updateStageStatus, getPresingedURL, uploadToS3 } from '../../../containers/Admin/actions';
import { APPLICATION_STATES, INSTALLER_STATES } from '../../../utils/constants';
import { getExternalRevisionDetails, getRevisionDetails, moreInformationSubmit, moveToUnderProgress, stampingRequest } from '../../../containers/Admin/RequestDetails/actions';
import RevisionDetails from '../RevisionDetailsPopup';
import { getVersionFullName } from '../../../containers/Admin/RequestDetails/helper';
import { isAdmin } from '../../../utils/helper';
import { get, isEmpty } from 'lodash';
import { sendMessage } from '../../../containers/Permitting/action';
import scrollIntoView from 'scroll-into-view';
import ExternalRevisionStatusUpdate from '../StatusDashboard/ExternalRevisionStatusUpdate';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px',
    cursor: 'unset !important'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray !important'
  },
  dataSubHeader: {
    fontSize: '16px',
    color: 'grey'
  },
  dataDiv: {
    minHeight: '517px'
  },
  acceptButton: {
    color: theme.palette.primary.white
  },
  formControl: {
    width: '100%'
  },
  statusLabel: {
    fontWeight: 'bold',
    fontSize: '13px'
  },
  panelWrapper: {
    maxHeight: '516px',
    minHeight: '566px',
    overflow: 'auto'
  },
  columnFlex: {
    flexDirection: 'column'
  },
  stepperWrapper: {
    width: '100%',
    padding: '10px'
  },
  version: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 2.5, 2.5),
    borderBottom: `${theme.spacing(0.22)}px solid lightgray`,
    width: '100%',
    paddingLeft: theme.spacing(3)
  },
  notesButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 1),
    border: '1px solid #F3731F',
    borderRadius: theme.spacing(1),
    fontSize: 11,
    marginLeft: theme.spacing(2),
    cursor: 'pointer'
  },
  versionContent: {
    fontSize: 13,
    color: 'gray',
    padding: theme.spacing(0, 1),
    position: 'relative',
    top: theme.spacing(1.25),
    backgroundColor: theme.palette.primary.white
  },
  assignedUser: {
    color: theme.palette.tertiary.dark,
  },
  reviewerName: {
    color: theme.palette.secondary.link,
  },
 commentField: {
    marginBottom: theme.spacing(1.5),
  },
  updateButton:{
    fontSize: theme.spacing(1.5), textTransform: 'none', color: '#3685D1'
  }
});

export class StatusDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sDetails: {
        sArray: [],
        currentStage: 0
      },
      showRevisionPopup: false,
      revisionDetails: {},
      showUpdateStatus: false,
      sDate: moment().format('MM/DD/YYYY'),
      stageForStatusUpdate: {},
      minDate: moment().format('MM/DD/YYYY'),
      maxDate: moment().format('MM/DD/YYYY'),
      showStampingPopUp: false,
      message : "We have raised Stamping Request on your behalf.",
      selectedPermitId: "",
      selectedApplication: {},
      showUpdateExternalRevisionPopup: false
    };
  }

  componentDidMount() {
    const { appDetails } = this.props;
    let sDetails = getStatusDetails(appDetails);
    this.setState({ sDetails: sDetails },
      () => {
        let completedStatusArray = sDetails.sArray.filter(s => s.completedOn != '');
        if (sDetails && completedStatusArray.length === 0) {
          scrollIntoView(document.getElementById('statusDetails'));
        } else if (sDetails && completedStatusArray.length > 0) {
          scrollIntoView(document.getElementById(completedStatusArray.at(-1).index));
        }
      }
    );
  }

  hideUpdateStatus() {
    this.setState({
      showUpdateStatus: false,
      sDate: moment().format('MM/DD/YYYY'),
      stageForStatusUpdate: {}
    });
  }

  viewUpdateStatus(stageForStatusUpdate) {
    const { appDetails } = this.props;
    this.setState({
      showUpdateStatus: true,
      stageForStatusUpdate: stageForStatusUpdate,
      minDate: moment(appDetails.third_party_current_status.last_updated_date).format('MM/DD/YYYY'),
      maxDate: moment().format('MM/DD/YYYY')
    });
  }

  handlePopoverOpen = ( app, isExternalRevision) => {
    this.setState({
      selectedPermitId: get(app, 'permit_id'),
      selectedApplication: app,
      showUpdateExternalRevisionPopup: isExternalRevision
    });
  };

  updateStatus() {
    const { appDetails } = this.props;
    if (this.state.stageForStatusUpdate.id === APPLICATION_STATES.MORE_INFO_RECEIVED.id) {
      this.props.moreInfoSubmit({
        permitId: appDetails.permit_id,
        successCbk: () => window.location.reload()
      })
    } else if (
      this.state.stageForStatusUpdate.id === APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id ||
      this.state.stageForStatusUpdate.id === APPLICATION_STATES.QA_STARTED.id
    ) {
      this.props.moveToUnderProgress({
        permitId: appDetails.permit_id,
        successCbk: () => window.location.reload()
      })
    }
  }

  handleMessageBody(event){
    this.setState({message : event.target.value});
  };

  submitStampingRequest(){
    const { appDetails } = this.props;
    this.props.stampingRequest({ permitId: appDetails.permit_id,message:this.state.message,
      successCbk: () => {
       window.location.reload()
      }
    })

    const body = {
          permit_id: appDetails.permit_id,
          subject: 'Others',
          body_content: this.state.message.trim()
        };

        sendMessage({ body});
  }
  getStatusLabel(state) {
    return state.label;
  }

  getRevisionNotes(version) {
    if (version.includes('IR')) {
      this.props.getRevisionDetails({
        permitId: this.props.permitting_application?.permit_id,
        version: version,
        successCbk: (result) => this.setState({ showRevisionPopup: true, revisionDetails: result, isExternal: false })
      });
    } else {
      this.props.getExternalRevisionDetails({
        permitId: this.props.permitting_application?.permit_id,
        version: version,
        successCbk: (result) => this.setState({ showRevisionPopup: true, revisionDetails: result, isExternal: true })
      });
    }
  }

  // for uploading external revision files
  fireUpload = (files, comment, kickbackDetails = {}) => {
  };

  render() {
    const { classes, permitting_application, emailId, reviewersList, permitServiceRole,stampingRequest} = this.props;
    const {
      sDetails,
      showUpdateStatus,
      sDate,
      stageForStatusUpdate,
      showStampingPopUp
    } = this.state;
    const currentUser = getAssignedTo(permitting_application)?.email === emailId;
    const currentVersion = permitting_application?.version?.current_version;
    const isExternalRevision =
    permitting_application?.version?.current_version.includes('ER') &&
    get(permitting_application, 'third_party_current_status.state_id') ===
    APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.id;
    let version = '';
    let statusesDetails = [], temp = [];
    sDetails && sDetails.sArray.forEach(s => {
      if (s.version !== '' && s.version !== version) {
        if (version !== '') {
          statusesDetails.push(temp);
          temp = [];
        }
        version = s.version;
      }
      temp.push(s);
    });
    statusesDetails.push(temp);
    const purchasedServices = permitting_application.purchased_service_names;
    const installerCurrentStateId = permitting_application &&
      !isEmpty(permitting_application.installer_current_status) &&
      permitting_application.installer_current_status.state_id;
    const enablePermitStamping =
      (installerCurrentStateId === INSTALLER_STATES.PERMIT_SET_AVAILABLE.id || installerCurrentStateId === INSTALLER_STATES.REWORK_SUBMITTED.id) &&
      (purchasedServices.includes('Electrical Review') || purchasedServices.includes('Structural Review'));
    return (
      <Fragment>
        {' '}
        <Grid container direction="row">
          <Grid item xs={12}>
            <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary aria-controls="sDetails-content" id="sDetails" className={classes.panelHead}>
                <Typography className={classes.heading}>Status Details</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.columnFlex} id="statusDetails">
                <div className={classes.panelWrapper}>
                  {statusesDetails && statusesDetails.map(statuses => {
                    let assigned = false, prevDesigner = null, prevReviewer = null;
                    return (
                      <Fragment>
                        <Box className={classes.version}>
                          <Typography component="span" className={classes.versionContent}>
                            {getVersionFullName(statuses[0]?.version)}
                            {' (' + statuses[0]?.version + ')'}
                            {statuses[0]?.version !== 'NEW' && !statuses[0]?.version.includes("ED") && <span className={classes.notesButton} onClick={() => this.getRevisionNotes(statuses[0]?.version)}>Revision Notes</span>}
                          </Typography>
                        </Box>
                        <Stepper activeStep={statuses[0]?.version === currentVersion ? sDetails.index : statuses.length} orientation="vertical" className={classes.stepperWrapper}>
                          {statuses.map(status => {
                            return (
                              <Step key={status.index} expanded={true}>
                                <StepLabel>
                                  <Typography className={classes.statusLabel} id={status.index}>{this.getStatusLabel(status)}</Typography>
                                </StepLabel>
                                <StepContent>
                                  {status.completedOn !== '' && (
                                    <Typography style={{ fontSize: '12px' }}>
                                      <span style={{ color: 'green' }}>{'Updated On : '}</span>
                                      {status.completedOn}
                                    </Typography>
                                  )}
                                  {status.infoText !== '' && (
                                    <Typography style={{ fontSize: '10px' }} color="primary">
                                      {status.infoText}
                                    </Typography>
                                  )}
                                  {(status.actions.updateStatus && ! isExternalRevision &&
                                    (currentUser ||
                                      (status.id === APPLICATION_STATES.MORE_INFO_RECEIVED.id) && isAdmin(permitServiceRole))) && (
                                    <Button size="small" onClick={() => this.viewUpdateStatus(status)}>
                                      <Link style={{ fontSize: '12px', textTransform: 'none', color: '#3685D1' }}>
                                        Update Status
                                      </Link>
                                    </Button>
                                  )}
                                  {enablePermitStamping &&
                                  (status.id === APPLICATION_STATES.QA_COMPLETED.id) && isAdmin(permitServiceRole) && (
                                    <Button size="small" onClick={() => { this.setState({ showStampingPopUp: true})}}>
                                      <Link style={{ fontSize: '12px', textTransform: 'none', color: '#3685D1' }}>
                                        Stamping Request Received
                                      </Link>
                                    </Button>
                                    )}
                                    {(status.actions.updateStatus && isExternalRevision &&
                                    (currentUser ||
                                      (isAdmin(permitServiceRole)))) && (
                                    <Button size="small" onClick={e => this.handlePopoverOpen(permitting_application, isExternalRevision)}>
                                      <Link className={classes.updateButton}>
                                        Update Status
                                      </Link>
                                    </Button>
                                  )}
                                  {status.users && status.users.map(user => {
                                    const assignedText = assigned ? 'Re Assigned' : 'Assigned';
                                    const assignedDate = user?.designer?.assigned_date || user?.reviewer?.assigned_date;
                                    let designerName = '', reviewerName = '';
                                    if (!prevDesigner || (prevDesigner.email !== user?.designer?.user_info?.email)) {
                                      prevDesigner = user?.designer?.user_info;
                                      designerName = user?.designer?.user_info?.name;
                                    }
                                    if (!prevReviewer || (prevReviewer.email !== user?.reviewer?.user_info?.email)) {
                                      prevReviewer = user?.reviewer?.user_info;
                                      reviewerName = user?.reviewer?.user_info?.name;
                                    }
                                    assigned = true;
                                    if (!designerName && !reviewerName) {
                                      return <Fragment />
                                    }
                                    return (
                                      <Typography component="li" variant="caption" className={classes.assignedUser}>
                                        {`${assignedText} to `}
                                        {designerName && (
                                          <Typography variant="caption" component="span"><Typography variant="caption" component="span" className={classes.reviewerName}>{designerName}</Typography> (Designer)</Typography>
                                        )}
                                        {designerName && reviewerName && ' and '}
                                        {reviewerName && (
                                          <Typography variant="caption" component="span"><Typography component="span" variant="caption" className={classes.reviewerName}>{reviewerName}</Typography> (Reviewer)</Typography>
                                        )}
                                        {' on '} {moment(assignedDate).format('DD MMM YYYY hh:mm A')}
                                      </Typography>
                                    )
                                  })}
                                </StepContent>
                              </Step>
                            );
                          })}
                        </Stepper>
                      </Fragment>
                    )
                  })}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Dialog
          onClose={() => this.hideUpdateStatus()}
          aria-labelledby="customized-dialog-title"
          open={showUpdateStatus}
          maxWidth={'md'}
          id="successMsg"
        >
          <DialogTitle id="customized-dialog-title" onClose={() => this.hideUpdateStatus()}>
            Update Status
          </DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: 'bold', fontSize: '13px' }}>{stageForStatusUpdate.label}</Typography>
            <br />
            <div style={{ display: 'flex' }}>
              <span>Date Of Completion : </span>&nbsp;&nbsp;&nbsp;
              <Typography variant="caption">{sDate}</Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.hideUpdateStatus()} variant="contained">
              Close
            </Button>
            <Button
              onClick={() => this.updateStatus()}
              color="primary"
              variant="contained"
              className={classes.acceptButton}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => { this.setState({ showStampingPopUp: false})}}
          aria-labelledby="customized-dialog-title"
          open={showStampingPopUp}
          maxWidth={'md'}
          id="successMsg"
        >
          <DialogTitle id="customized-dialog-title" onClose={() => { this.setState({ showStampingPopUp: false})}}>
            Stamping Request Received
          </DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: 'bold', fontSize: '13px' }}>Comments</Typography>
            <br />
          <TextField
              value={this.state.message}
              onChange={(e) => this.handleMessageBody(e)}
              multiline
              minRows={4}
              maxRows={16}
              fullWidth
              variant="outlined"
              className={classes.commentField}
              placeholder="Type your comments..."
          />
          </DialogContent>
          <DialogActions>
            <Button onClick={() =>{ this.setState({ showStampingPopUp: false})}} variant="contained">
              Close
            </Button>
            <Button
              onClick={() => this.submitStampingRequest()}
              color="primary"
              variant="contained"
              className={classes.acceptButton}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <RevisionDetails
          open={this.state.showRevisionPopup}
          handleClose={() => this.setState({ showRevisionPopup: false })}
          details={this.state.revisionDetails}
          isExternal={this.state.isExternal}
          application={permitting_application}
          getPresingedURL={this.props.getPresingedURL}
        />
         <ExternalRevisionStatusUpdate
          showUpdateExternalRevisionPopup={this.state.showUpdateExternalRevisionPopup}
          handleShowUpdateExternalRevisionPopup={() =>
            this.setState({ showUpdateExternalRevisionPopup: !this.state.showUpdateExternalRevisionPopup })
          }
          reviewersList={this.props.reviewersList}
          selectedApplication={this.state.selectedApplication}
          getExternalRevisionDetails={this.props.getExternalRevisionDetails}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateStageStatus: payload => dispatch(updateStageStatus(payload)),
  getPresingedURL: payload => dispatch(getPresingedURL(payload)),
  uploadToS3: payload => dispatch(uploadToS3(payload)),
  moreInfoSubmit: payload => dispatch(moreInformationSubmit(payload)),
  moveToUnderProgress: payload => dispatch(moveToUnderProgress(payload)),
  getRevisionDetails: payload => dispatch(getRevisionDetails(payload)),
  getExternalRevisionDetails: payload => dispatch(getExternalRevisionDetails(payload)),
  stampingRequest: payload => dispatch(stampingRequest(payload)),
  sendMessage: payload => dispatch(sendMessage(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending,
  permitting_application: state.adminReducer.permitting_application,
  emailId: state.appReducer.emailId,
  reviewersList: state.adminReducer.reviewersList,
  permitServiceRole: state.appReducer.permitServiceRole,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusDetails));
