import { isEqual, isEmpty } from 'lodash';
import moment from 'moment';

const uploadFileDetails = (filesDetail, callback) => {
  const {
    event,
    uploadFiles,
    setStatus,
    setDocumentUploadError,
    namePrefix,
    maxFileUploadSize,
    getS3URL,
    putToS3,
    documentType,
    fileLocation,
  } = filesDetail;

  const eventFilesObject = [...event.target.files];
  const recursiveUpload = async (index) => {
    if (index >= eventFilesObject.length) {
      return;
    }
    let fileData = eventFilesObject[index];
    if (!fileData) {
      return;
    }

    let originalFileName = '';
    originalFileName = eventFilesObject[index].name;
    if (!originalFileName) {
      return;
    }

    let fileExtention = originalFileName.split('.').pop();

    // To check if the file is already uploaded and increment the index by 1
    if (uploadFiles.length > 0) {
      let uploadedFiles = uploadFiles.filter((uFile) => uFile.originalFileName === originalFileName);
      if (uploadedFiles && uploadedFiles.length > 0) {
        const errorDetails = {
          error: false,
          msg: 'A file with same name is already uploaded. You can upload it again, after removing it.',
        };
        setDocumentUploadError(errorDetails, 'idle', documentType);
        if (index + 1 >= eventFilesObject.length) {
          return;
        }
        recursiveUpload(index + 1);
        return;
      }
    }

    setStatus('processing');

    // to set the file display name
    let fileName = originalFileName.slice(0, -fileExtention.length - 1);
    let turncatedFilename = fileName.length > 20 ? fileName.substring(0, 25) + '...' + fileExtention : originalFileName;

    // To set the display file size
    const fileSizeInKb = (fileData.size / 1024).toFixed(0);
    let fileSizeInMb = fileData.size / 1024 / 1024;
    fileSizeInMb = fileSizeInMb.toFixed(2);

    let updatedFileName = '';
    const fileNamesIndexList = uploadFiles.map((file) => Number(file.file_name.split('.')[0].split('_').pop()));
    const maxIndex = !isEmpty(fileNamesIndexList) ? Math.max(...fileNamesIndexList) : 0;
    if (isEqual(documentType, 'referenceFiles')) {
      turncatedFilename = fileName.length > 15 ? fileName.substring(0, 20) + '...' + fileExtention : originalFileName;
      updatedFileName = namePrefix + '_reference_file_' + (maxIndex + 1) + '.' + turncatedFilename.split('.').pop();
    }
    if (isEqual(documentType, 'fieldUpdatesReferenceFiles')) {
      turncatedFilename = fileName.length > 15 ? fileName.substring(0, 20) + '...' + fileExtention : originalFileName;
      updatedFileName = namePrefix + '_field_updates_reference_file_' + (maxIndex + 1) + '.' + turncatedFilename.split('.').pop();
    }

    if (isEqual(documentType, 'specSheet')) {
      updatedFileName =
        namePrefix + '_spec_sheet_' + (uploadFiles.length + 1) + '.' + turncatedFilename.split('.').pop();
    }

    if (isEqual(documentType, 'prodImage')) {
      let fileExtension = turncatedFilename.split('.').pop();
      fileExtension = fileExtension === 'jpg' ? 'jpeg' : fileExtension;
      turncatedFilename = turncatedFilename.split('.')[0] + '.' + fileExtension;
      updatedFileName = namePrefix + '_prod_image_' + (uploadFiles.length + 1) + '.' + `${fileExtension}`;
    }

    if (isEqual(documentType, 'additionalDocument')) {
      let fileExtension = turncatedFilename.split('.').pop();
      fileExtension = fileExtension === 'jpg' ? 'jpeg' : fileExtension;
      turncatedFilename = turncatedFilename.split('.')[0] + '.' + fileExtension;
      updatedFileName = namePrefix + '_additional_document_' + (uploadFiles.length + 1) + '.' + +`${fileExtension}`;
    }

    // to add extra information
    let uploadDate = moment().format('YYYY-MM-DD');
    let uploadStatus = 'Uploaded';

    // To handle unknown upload issue
    const handleFailure = () => {
      const errorDetails = {
        error: true,
        msg: 'We are facing some issue with file upload, you can try after sometime.',
      };
      setDocumentUploadError(errorDetails, 'idle', documentType);
    };

    // To check again max allowed fileUploadSize
    if (fileSizeInMb > maxFileUploadSize) {
      const errorDetails = {
        error: true,
        msg: `Maximum files size allowed is ${maxFileUploadSize} MB`,
      };
      setDocumentUploadError(errorDetails, 'idle', documentType);
      recursiveUpload(index + 1);
    } else if (fileLocation === 'solargraf') {
      setDocumentUploadError({ error: false, msg: '' }, '', documentType);
      uploadFiles.push({
        display_file_name: turncatedFilename,
        file_size: fileSizeInKb > 1024 ? fileSizeInMb + ' MB' : fileSizeInKb + ' KB',
        status: uploadStatus,
        original_file_name: originalFileName,
        fileData: fileData,
      });
      setStatus('idle');
    } else {
      getS3URL({
        fileName: updatedFileName,
        methodType: 'PUT',
        isPermitAuto: true,
        successCb: (presignedUrl) => {
          putToS3({
            fileObj: fileData,
            preSignedS3Url: presignedUrl,
            fName: updatedFileName,
            successCbS3: () => {
              setDocumentUploadError({ error: false, msg: '' }, '', documentType);
              uploadFiles.push({
                display_file_name: turncatedFilename,
                file_name: updatedFileName,
                file_size: fileSizeInKb > 1024 ? fileSizeInMb + ' MB' : fileSizeInKb + ' KB',
                uploaded_date: uploadDate,
                status: uploadStatus,
                original_file_name: originalFileName,
              });
              setStatus('idle');
              recursiveUpload(index + 1);
            },
            failureCbS3: handleFailure,
          });
        },
        failureCb: handleFailure,
      });
    }
  };
  recursiveUpload(0);
  callback(uploadFiles);
};

export default uploadFileDetails;
