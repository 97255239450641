import { get, isEmpty } from 'lodash';
import {
  validateSubpanelFields,
  validateSubpanelFieldsByKey,
} from '../../../containers/Permitting/MaterialDetails/validateSubpanelFields';

const fieldRequired = {
  error: true,
  errorMessage: 'This field is Required',
};

const noErrorObj = {
  error: false,
  errorMessage: '',
};

const quantityError = {
  error: true,
  errorMessage: 'The value should be greater than 0',
};

const objectFields = ['manufacturer', 'model'];

const electricalFields = [];

export const validateSubpanelFieldsByKeyStoragePopup = (
  value,
  key,
  additionalDetails = {},
  skipValidation = false,
  autoPopulated = false,
  materialDetails,
  homeBackup
) => {
  const { electrical_properties } = materialDetails;
  const { subpanels = [], subpanelExisting = null, backupExistingSubpanel } = electrical_properties;
  const partialBackup = homeBackup === 'partial';
  const errors = materialDetails.errors;
  validateSubpanelFieldsByKey(
    errors,
    subpanels,
    backupExistingSubpanel,
    partialBackup,
    subpanelExisting,
    fieldRequired,
    additionalDetails,
    noErrorObj
  );
};

export const validateEquipmentLocation = (homeBackup, equipmentLocation) => {
  let equipmentLocationError = noErrorObj;
  if (homeBackup) {
    equipmentLocation.forEach((equipmentLocationLabel) => {
      if (equipmentLocationLabel.item == 'Encharge Location') {
        equipmentLocationError = isEmpty(equipmentLocationLabel.location) ? fieldRequired : noErrorObj;
      }
    });
  }
  return equipmentLocationError;
};

export const validateDetails = (materialDetails, homeBackup, equipmentLocation, isInputAutocompleteEnabled) => {
  const productKey = 'storage_list';
  const thirdPartyKey = 'third_party_generator';
  const partialBackup = homeBackup === 'partial';
  const errors = { [productKey]: [] , [thirdPartyKey]: '' };
  const { electrical_properties,third_party_generator } = materialDetails;

  const { subpanels = [], subpanelExisting = null, backupExistingSubpanel } = electrical_properties;
  if(third_party_generator === null && (homeBackup === 'partial' || homeBackup === 'full' || homeBackup === 'gridTied')){
    errors['third_party_generator'] = fieldRequired;
  }
  get(materialDetails, productKey, []).map((product, index) => {
    if (!errors[productKey][index]) {
      errors[productKey][index] = {};
    }
    for (const key in product) {
      if (objectFields.includes(key)) {
        if (isEmpty(product[key]) || isEmpty(product[key].name || isEmpty(product[key].id))) {
          errors[productKey][index][key] = fieldRequired;
        } else if(isInputAutocompleteEnabled && !product[key].name) {
          errors[productKey][index][key] = fieldRequired;
        }
        else if (!isInputAutocompleteEnabled && product[key].id === 'other' && (!product[key].name || product[key].name.split('-').length <= 1)) {
          errors[productKey][index][key] = fieldRequired;
        }
      }
      if (product.quantity === '' || product.quantity === null || isNaN(Number(product.quantity))) {
        errors[productKey][index]['quantity'] = quantityError;
      }
      if (product['model'] && product['model'].id === 'other' && !product.capacity) {
        errors[productKey][index]['capacity'] = quantityError;
      }
    }
  });

  if (partialBackup) {
    errors['backupExistingSubpanel'] =
      backupExistingSubpanel === true || backupExistingSubpanel === false ? noErrorObj : fieldRequired;
  }

  errors['encharge_location'] = validateEquipmentLocation(homeBackup, equipmentLocation);
  validateSubpanelFields(errors, subpanels, backupExistingSubpanel, partialBackup, subpanelExisting, fieldRequired);

  return errors;
};
