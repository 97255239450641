import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Field, formValueSelector, reduxForm, change } from 'redux-form';
import {
  Select as SelectField,
  FormHelperText,
  TextField,
  Grid,
  InputLabel,
  Box,
  Typography,
  IconButton,
  MenuItem,
  FormControl,
  Tooltip,
  Button,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HelpIcon from '../../../components/Permitting/images/helpIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, makeStyles, withTheme } from '@material-ui/core/styles';
import { labelStyles, Label } from '../../../components/common/Label';
import PermitTemplate from '../../../components/Permitting/Template';
import { isEmpty, throttle, get, isEqual, debounce } from '../../../utils/lodash';
import StepperFooter from '../StepperFooter';
import {
  createProjectDetails,
  updateAddress,
  setError,
  getUtilityProviders,
  getTemplates,
  setSelectedTemplate,
  deleteTemplate,
  updateMaterialDetails,
  showHideLoader,
  setApplyTemplate,
  setEnableSubmit,
  updateCoordinates,
  getAHJRecommendations,
  updateCounty,
} from '../action';
import { PROJECT_TYPES, STEPS, AHJ_RECOMMENDATIONS_DISCLAIMER } from '../constants';
import SiteLinkDialog from '../../../components/Permitting/SiteLinkDialog';
import TemplatePopup from '../../../components/Permitting/TemplatePopup';
import { isPermitSetAvailable } from '../../../utils/helper';
import { fetchAddress, fetchPlaceId, fetchCounty } from '../helper';

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#111111',
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: theme.spacing(1),
    minWidth: theme.spacing(15),
    maxWidth: 350,
    pointerEvents: 'auto',
    fontWeight: 'normal',
  },
  arrow: {
    color: 'white',
    fontSize: 14,
  },
}))(Tooltip);

const ProjectTypeTooltip = ({ classes }) => {
  const [open, setOpen] = useState(false);
  return (
    <LightTooltip
      title={`Residential = contract with homeowners of single family home. \n
      Commercial = contract with multi-family / commercial property owners.`}
      placement="top-start"
      open={open}
    >
      <IconButton
        aria-label="info"
        className={classes.iconButton}
        onClick={() => setOpen(true)}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <HelpIcon />
      </IconButton>
    </LightTooltip>
  );
};

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
    },
  },
  inputField: {
    width: '100%',
    minWidth: theme.spacing(30),
  },
  inputErrorWrapper: {
    width: '45%',
    margin: theme.spacing(3, 2, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      minWidth: 'auto',
    },
  },
  withoutFormControl: {
    minWidth: '45%',
    width: '100%',
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
    textTransform: 'initial',
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    padding: 0,
  },
  addNew: {
    fontSize: 12,
    color: '#008EEF',
    display: 'inline-block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  chooseTemplateButton: {
    fontSize: 15,
    borderRadius: 3,
    textTransform: 'none',
    padding: theme.spacing(0, 3),
    border: '1px solid #F37320',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    height: theme.spacing(4),
    width: theme.spacing(18),
    color: '#F37320',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  changeTemplateButton: {
    fontSize: 15,
    borderRadius: 3,
    textTransform: 'none',
    padding: theme.spacing(0, 3),
    border: '1px solid #F37320',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    height: theme.spacing(4),
    width: theme.spacing(14.5),
    color: '#F37320',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  templateFontSize: {
    fontSize: '14px',
    minWidth: theme.spacing(21.875),
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2, 2),
    fontSize: 15,
  },
  button: {
    color: theme.palette.primary.white,
    marginLeft: theme.spacing(1.5),
    position: 'relative',
    top: theme.spacing(0.3),
  },
  inputFieldStorage: {
    width: '50%',
    paddingLeft: theme.spacing(1.5),
    '& .MuiInputLabel-root': {
      paddingLeft: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  templateWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    opacity: 0.8,
    cursor: 'pointer',
    fontSize: theme.spacing(2),
    position: 'relative',
    top: theme.spacing(0.5),
  },
  showPopper: {
    display: 'block',
  },
  hidePopper: {
    display: 'none',
  },
  iconButton: {
    padding: theme.spacing(0, 0.5),
  },
  dropdownHeader: {
    margin: theme.spacing(1.5, 0, 0, 2),
    fontSize: theme.spacing(1.75),
    color: '#0666F5',
  },
  disclaimerText: {
    color: theme.palette.secondary.A700,
  },
});

let timeout = null;
let zipCode = '';
let updatedValues = null;
let changedField = null;
let solarV2OptionSelected = null;
let ahjName = '';
let isNotFirst = false;
class ProjectDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    if (window.google) {
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.geocoder = new window.google.maps.Geocoder();
    }
    this.state = {
      options: [],
      materialDetailsState: null,
      showTemplateDialog: false,
      open: false,
      hidePopper: false,
      showTooltip: false,
      showAhjTooltip: false,
      showLicenseNumberTooltip: false,
      showDisclaimerText: false,
      ahjRecommendations: [],
      otherAhjRecommendations: [],
      allAhjRecommendations: [],
      customAHJName: false,
    };
  }

  handleAHJRecommendationsDebounce = debounce(() => {
    const { city, county, state } = this.props.projectDetails;
    this.props.getAHJRecommendations({
      city,
      county,
      state,
      successCbk: (ahjRecommendations) => {
        ahjRecommendations = ahjRecommendations.map((option) => {
          return {
            group: 'Address based recommendations',
            ...option,
          };
        });
        this.setState({ ahjRecommendations: ahjRecommendations, otherAhjRecommendations: [] }, () => {
          this.toggleDisclaimer(this.props.ahj);
          this.combineAllAhjRecommendations();
        });
        this.props.updateCountyField(!county ? null : county);
      },
    });
  }, 1500);

  newApplication = false;
  firstProjectDetailsUpdate = true;

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getTemplates();
    if (this.props.viewTemplate) {
      this.setState({ showTemplateDialog: true });
    }
    if (window.location.href.split('/').pop().trim().split('?')[0] === 'new') {
      this.newApplication = true;
    }
    if (isEmpty(this.props.uProviders)) {
      this.props.getUtilityProviders({ zip_code: this.props.initialValues.zipcode });
    }
    // this will get called when user goes to MDetails and come back to PDetails
    this.getAhjRecommendations();
  }

  componentDidUpdate(prvProp, preState) {
    if (isEmpty(this.state.materialDetailsState) && !isEmpty(this.props.materialDetails)) {
      this.setState({ materialDetailsState: { ...this.props.materialDetails, errors: {}, showTemplateDialog: false } });
    }
    if (get(prvProp.applicationDetail, 'template_id') !== get(this.props.applicationDetail, 'template_id')) {
      this.selectTemplate();
    }

    if (this.firstProjectDetailsUpdate && this.props.projectDetails !== prvProp.projectDetails) {
      this.firstProjectDetailsUpdate = false;
      const { county } = this.props.projectDetails;
      if (!county) {
        fetchPlaceId(
          this.autocompleteService,
          { input: this.props.projectDetails.address },
          (placeId) => {
            fetchCounty(
              this.geocoder,
              placeId,
              (res) => {
                this.props.updateCounty(res);
                this.getAhjRecommendations();
              },
              this.getAhjRecommendations
            );
          },
          this.getAhjRecommendations
        );
      } else {
        this.getAhjRecommendations();
      }
    }
  }

  getAhjRecommendations = () => {
    const { city, county, state, ahj, ahj_id } = this.props.projectDetails;
    this.props.getAHJRecommendations({
      city,
      county,
      state,
      successCbk: (ahjRecommendations) => {
        ahjRecommendations = ahjRecommendations.map((option) => {
          return {
            group: 'Address based recommendations',
            ...option,
          };
        });
        this.setState({ ahjRecommendations: ahjRecommendations }, () => {
          ahj && this.toggleDisclaimer(ahj);
          this.combineAllAhjRecommendations();
        });

        this.props.updateAhjId(!ahj_id ? null : ahj_id);
        this.props.updateCountyField(!county ? null : county);
      },
    });
  };

  getOtherAhjRecommendations = debounce((name) => {
    const state = this.props.projectDetails.state;
    this.props.getAHJRecommendations({
      state,
      name,
      successCbk: (otherAhjRecommendations) => {
        // check to remove AHJs already present in Address based recomendations
        otherAhjRecommendations = otherAhjRecommendations
          .filter((otherAhj) => !this.state.ahjRecommendations.map((ahj) => ahj.name).includes(otherAhj.name))
          .map((option) => {
            return {
              group: 'Other recommendations',
              ...option,
            };
          });

        this.setState({ otherAhjRecommendations: otherAhjRecommendations }, () => {
          this.combineAllAhjRecommendations();
        });

        this.props.updateAhjId(!ahj_id ? null : ahj_id);
        this.props.updateCountyField(!county ? null : county);
      },
    });
  }, 1500);

  combineAllAhjRecommendations = () => {
    // combines Address based recomendations and text level recommendations
    const { otherAhjRecommendations, ahjRecommendations } = this.state;
    const allAhjRecommendations = [...ahjRecommendations, ...otherAhjRecommendations];
    this.setState({ allAhjRecommendations: allAhjRecommendations });
  };

  selectTemplate = () => {
    if (!isEmpty(this.props.applicationDetail)) {
      if (!isEmpty(this.props.applicationDetail.template_id)) {
        const templateId = this.props.applicationDetail.template_id;
        const selectedTemplate = this.props.templates.find((template) => template.id === templateId);
        this.props.setSelectedTemplate(selectedTemplate);
      }
    }
  };

  renderTextField = ({
    withoutFormControl = false,
    classes,
    labelClass,
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => {
    const handleChange = (value) => {
      if (label.props.keyValue === 'customer_name') {
        this.props.handleCustomerNameChange(value);
      }
      changedField = label.props.keyValue;
      input.onChange(value);
    };
    let characterLimit = 500;
    if (label.props.keyValue === 'customer_name') {
      characterLimit = 30;
    }
    return (
      <TextField
        className={!withoutFormControl ? clsx(classes.inputField, labelClass.customLabel) : classes.withoutFormControl}
        error={error && touched}
        label={label}
        helperText={touched && error}
        {...input}
        onChange={(e) => handleChange(e.target.value)}
        inputProps={{
          maxLength: characterLimit,
        }}
        {...custom}
      />
    );
  };

  toggleDisclaimer = (value) => {
    const selectedAHJ = this.state.allAhjRecommendations.find((ahj) => ahj.name === value);
    this.props.updateAhjId(!selectedAHJ ? null : selectedAHJ.id);
    this.setState({
      showDisclaimerText: this.showAHJDisclaimer(selectedAHJ),
      customAHJName: !selectedAHJ,
    });
  };

  showAHJDisclaimer = (ahj) => {
    if (!ahj) return false;
    const { reference_status } = ahj;
    switch (reference_status) {
      case 'TODO':
      case 'REFERENCE_NOT_FOUND':
      case 'AHJ_DATA_NOT_AVAILABLE':
        return true;
      default:
        return false;
    }
  };

  renderTypableDropDown = ({
    withoutFormControl = false,
    classes,
    labelClass,
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => {
    const handleChange = (value) => {
      if (this.isNotFirst) {
        this.toggleDisclaimer(value);
        this.ahjName = value;
        changedField = label.props.keyValue;
        input.onChange(value);
        this.getOtherAhjRecommendations(value);
      }
      this.isNotFirst = true;
    };
    const filterOptions = (options, state) => {
      return options;
    };
    const fields = { groupBy: 'group', value: 'name' };

    return (
      <Autocomplete
        id="ahj"
        freeSolo
        className={!withoutFormControl ? clsx(classes.inputField, labelClass.customLabel) : classes.withoutFormControl}
        inputValue={input.value ? input.value : ''}
        defaultValue={input.value ? input.value : ''}
        onInputChange={(event, newValue) => handleChange(newValue)}
        options={this.state.allAhjRecommendations}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.name}
        fields={fields}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error && touched}
            label={label}
            helperText={this.state.showDisclaimerText && AHJ_RECOMMENDATIONS_DISCLAIMER}
            FormHelperTextProps={{ className: classes.disclaimerText }}
            {...input}
            onChange={(e) => handleChange(e.target.value)}
            {...custom}
          />
        )}
      />
    );
  };
  renderSelectField = ({
    withoutFormControl = false,
    classes,
    labelClass,
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  }) => {
    const { key, projectDetails, siteId, paymentStatus } = custom;
    const handleChange = (value) => {
      changedField = label.props.keyValue;
      input.onChange(value);
    };

    return (
      <FormControl
        error={error && touched}
        className={!withoutFormControl ? clsx(classes.inputField, labelClass.customLabel) : classes.withoutFormControl}
      >
        <InputLabel id={`label_${input.name}`}>{label}</InputLabel>
        <SelectField
          id={`select_field_${input.name}`}
          value="00ff00"
          label={label}
          {...input}
          onChange={(event) => handleChange(event.target.value)}
          children={children}
          {...custom}
          disabled={
            key === 'project_type' && (paymentStatus || (!isEmpty(projectDetails.project_type) && !isEmpty(siteId)))
          }
        />
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  };

  renderAddressField = ({ classes, input }) => {
    const { customAHJName } = this.state;
    let ahj = this.props.projectDetails.ahj || this.ahjName;
    return (
      <Autocomplete
        component="span"
        id="google-map"
        style={{ minWidth: '240px' }}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={this.state.options}
        freeSolo
        autoComplete
        {...input}
        onChange={(event, newValue) => {
          changedField = 'address';
          if (newValue) {
            this.geocoder.geocode({ placeId: newValue.place_id }, (res) => {
              if (res[0]) {
                const address = res[0].address_components;
                const zipcode = address.find((comp) => comp.types.includes('postal_code'));
                const country = address.find((comp) => comp.types.includes('country'));
                const state = address.find((comp) => comp.types.includes('administrative_area_level_1'));
                const county = address.find((comp) => comp.types.includes('administrative_area_level_2'));
                const city = address.find((comp) => comp.types.includes('locality'));
                const latitude = res[0]?.geometry?.location?.lat;
                const longitude = res[0]?.geometry?.location?.lng;
                const find = city || county || state || country;
                let address1 = '';
                address.some((comp) => {
                  if (find.long_name === comp.long_name) {
                    return true;
                  }
                  address1 += address1 ? `, ${comp.long_name}` : comp.long_name;
                  return null;
                });
                if (!customAHJName) {
                  ahj = '';
                  this.ahjName = '';
                }
                this.props.updateAddress({
                  ...updatedValues,
                  ahj,
                  address: res[0].formatted_address,
                  full_address: res[0].formatted_address,
                  address1,
                  address2: null,
                  country: (country && country.short_name) || null,
                  zipcode: (zipcode && zipcode.long_name) || null,
                  state: (state && state.short_name) || null,
                  county: (county && county.long_name) || null,
                  city: (city && city.long_name) || null,
                  latitude: latitude ? latitude() : null,
                  longitude: longitude ? longitude() : null,
                  successCbk: () => this.getAhjRecommendations(),
                });
                this.props.updateCoordinates();
              }
            });
          }
        }}
        onInputChange={(event, newInputValue, reason) => {
          changedField = 'address';
          if (event && newInputValue && reason === 'input') {
            fetchAddress(this.autocompleteService, { input: newInputValue }, (options) => this.setState({ options }));
            if (!customAHJName) {
              ahj = '';
              this.ahjName = '';
            }
            this.props.updateAddress({
              ...updatedValues,
              ahj,
              address1: null,
              address: newInputValue,
              full_address: newInputValue,
              address2: newInputValue,
              country: null,
              zipcode: null,
              state: null,
              county: null,
              city: null,
            });
            this.handleAHJRecommendationsDebounce();
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={<Label keyValue="address" className={classes.labelColor} text="Address" isRequired />}
          />
        )}
        renderOption={(option) => (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Typography variant="body1" color="textPrimary">
                {option.structured_formatting ? option.structured_formatting.main_text : ''}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting ? option.structured_formatting.secondary_text : ''}
              </Typography>
            </Grid>
          </Grid>
        )}
        className={classes.searchArea}
      />
    );
  };

  renderHiddenField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField type="hidden" error={error && touched} {...input} {...custom} />
  );

  handleTemplateDialogClose = () => {
    this.setState({ showTemplateDialog: false });
    this.props.getTemplates();
  };

  openTemplateDialog = () => {
    this.setState({ showTemplateDialog: true });
  };

  render() {
    const labelClass = makeStyles(labelStyles);
    const {
      classes,
      activeStep,
      steps,
      handleNext,
      handleBack,
      handleSaveandClose,
      siteDetails,
      uProviders = [],
      isMobile,
      errors,
      applicationDetail,
      selectedTemplate,
      templates,
      mostUsedValues,
      paymentStatus,
      viewPermitApplication,
      projectDetails,
      siteId,
      projectType,
    } = this.props;
    let isSystemNameDisabled;
    siteDetails && siteDetails.site_name ? (isSystemNameDisabled = true) : (isSystemNameDisabled = false);
    const buttonText = isEmpty(selectedTemplate) ? 'Choose Template' : 'Change';
    let templateName = selectedTemplate && selectedTemplate.name;
    if (templateName) {
      if (templateName.length > 16) {
        templateName = templateName.slice(0, 12) + '...';
      }
    }
    return (
      <div className={classes.root}>
        <PermitTemplate
          title={STEPS[activeStep].name}
          subText={STEPS[activeStep].description}
          mandatorySection={
            templates.length > 0 && (
              <div className={classes.templateWrapper}>
                {!isEmpty(selectedTemplate) && (
                  <Typography display="inline" className={classes.templateFontSize}>
                    {`Template: ${templateName} `}
                  </Typography>
                )}
                <Button
                  size="small"
                  variant="outlined"
                  className={!isEmpty(selectedTemplate) ? classes.changeTemplateButton : classes.chooseTemplateButton}
                  disableRipple
                  onClick={this.openTemplateDialog}
                  disabled={paymentStatus}
                >
                  {buttonText}
                </Button>
              </div>
            )
          }
        >
          <form className={isPermitSetAvailable(applicationDetail) ? classes.disableForm : ''}>
            <Grid className={classes.inputWrapper}>
              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="project_type"
                  component={(props) =>
                    this.renderSelectField({ ...props, key: 'project_type', projectDetails, siteId, paymentStatus })
                  }
                  label={
                    <Label className={classes.labelColor} keyValue="project_type" text="Type" isRequired>
                      <ProjectTypeTooltip classes={classes} />
                    </Label>
                  }
                  classes={classes}
                  labelClass={labelClass}
                >
                  {Object.values(PROJECT_TYPES).map((type, index) => {
                    if (!isEmpty(type)) {
                      return (
                        <MenuItem key={'type-' + index} value={type}>
                          {type.charAt(0) + type.slice(1).toLowerCase()}
                        </MenuItem>
                      );
                    }
                  })}
                </Field>
                {get(errors, 'project_type', '') && (
                  <FormHelperText error>{get(errors, 'project_type')}</FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid className={classes.inputWrapper}>
              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="system_name"
                  component={this.renderTextField}
                  label={
                    <Label
                      className={classes.labelColor}
                      keyValue="system_name"
                      text="System/Project Name"
                      isRequired
                    />
                  }
                  labelClass={labelClass}
                  classes={classes}
                  disabled={isSystemNameDisabled}
                />
                {get(errors, 'system_name', '') && <FormHelperText error>{get(errors, 'system_name')}</FormHelperText>}
              </Box>
              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="customer_name"
                  component={this.renderTextField}
                  label={
                    <Label className={classes.labelColor} keyValue="customer_name" text="Customer Name" isRequired />
                  }
                  labelClass={labelClass}
                  classes={classes}
                />
                {get(errors, 'customer_name', '') && (
                  <FormHelperText error>{get(errors, 'customer_name')}</FormHelperText>
                )}
              </Box>
            </Grid>

            <Grid className={classes.inputWrapper}>
              <Field name="county" component="input" type="hidden" />

              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="address"
                  component={this.renderAddressField}
                  label="Address"
                  labelClass={labelClass}
                  classes={classes}
                />
                {get(errors, 'address', '') && <FormHelperText error>{get(errors, 'address')}</FormHelperText>}
              </Box>
              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="zipcode"
                  component={this.renderTextField}
                  label={<Label className={classes.labelColor} keyValue="zipcode" text="Zip" isRequired />}
                  labelClass={labelClass}
                  classes={classes}
                />
                {get(errors, 'zipcode', '') && <FormHelperText error>{get(errors, 'zipcode')}</FormHelperText>}
              </Box>
            </Grid>
            <Grid className={classes.inputWrapper}>
              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="project_requirement_description"
                  component={this.renderTextField}
                  label={
                    <Label
                      className={classes.labelColor}
                      keyValue="project_requirement_description"
                      text="Project Description & Requirements"
                    >
                      <LightTooltip
                        title="Overview of the project"
                        open={this.state.showTooltip}
                        onClose={(e) => this.setState({ showTooltip: false })}
                      >
                        <IconButton
                          onClick={(e) => this.setState({ showTooltip: true })}
                          onMouseEnter={(e) => this.setState({ showTooltip: true })}
                          onMouseLeave={(e) => this.setState({ showTooltip: false })}
                          aria-label="info"
                          className={classes.iconButton}
                        >
                          <HelpIcon />
                        </IconButton>
                      </LightTooltip>
                    </Label>
                  }
                  labelClass={labelClass}
                  classes={classes}
                />
              </Box>
              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="utility_provider"
                  component={this.renderSelectField}
                  label={
                    <Label
                      className={classes.labelColor}
                      keyValue="utility_provider"
                      text="Utility Provider"
                      isRequired
                    />
                  }
                  classes={classes}
                  labelClass={labelClass}
                >
                  {uProviders.map((utility, index) => {
                    return (
                      <MenuItem key={index} value={utility.name}>
                        {utility.name}
                      </MenuItem>
                    );
                  })}
                </Field>
                {get(errors, 'utility_provider', '') && (
                  <FormHelperText error>{get(errors, 'utility_provider')}</FormHelperText>
                )}
              </Box>
            </Grid>

            <Grid className={classes.inputWrapper}>
              <Field name="ahj_id" component="input" type="hidden" />

              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="ahj"
                  component={this.renderTypableDropDown}
                  label={
                    <Label className={classes.labelColor} keyValue="ahj" text="AHJ" isRequired>
                      <LightTooltip
                        title="AHJ represents ‘Authority Having Jurisdiction’, typically represent the local municipality where the project is located"
                        open={this.state.showAhjTooltip}
                        placement="right"
                      >
                        <IconButton
                          onClick={() => this.setState({ showAhjTooltip: true })}
                          onMouseEnter={() => this.setState({ showAhjTooltip: true })}
                          onMouseLeave={() => this.setState({ showAhjTooltip: false })}
                          aria-label="info"
                          className={classes.iconButton}
                        >
                          <HelpIcon />
                        </IconButton>
                      </LightTooltip>
                    </Label>
                  }
                  labelClass={labelClass}
                  classes={classes}
                  mostUsedList={mostUsedValues['ahj']}
                />
                {get(errors, 'ahj', '') && <FormHelperText error>{get(errors, 'ahj')}</FormHelperText>}
              </Box>
              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="ahj_notes"
                  component={this.renderTextField}
                  label={
                    <Label
                      className={classes.labelColor}
                      keyValue="ahj_notes"
                      text="AHJ Specific Requirements"
                      isRequired={false}
                    />
                  }
                  labelClass={labelClass}
                  classes={classes}
                />
              </Box>
            </Grid>
            <Grid>
              <Field name="site_id" component={this.renderHiddenField} />
            </Grid>
            <Grid className={classes.inputWrapper}>
              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="installer_license_no"
                  component={this.renderTextField}
                  label={
                    <Label
                      className={classes.labelColor}
                      keyValue="installer_license_no"
                      text="Installer License Number"
                    >
                      <LightTooltip
                        title="License Number to be displayed in Permit drawings"
                        open={this.state.showLicenseNumberTooltip}
                        placement="right"
                      >
                        <IconButton
                          onClick={() => this.setState({ showLicenseNumberTooltip: true })}
                          onMouseEnter={() => this.setState({ showLicenseNumberTooltip: true })}
                          onMouseLeave={() => this.setState({ showLicenseNumberTooltip: false })}
                          aria-label="info"
                          className={classes.iconButton}
                        >
                          <HelpIcon />
                        </IconButton>
                      </LightTooltip>
                    </Label>
                  }
                  labelClass={labelClass}
                  classes={classes}
                />
              </Box>
            </Grid>
          </form>
          <StepperFooter
            activeStep={activeStep}
            steps={steps}
            handleNext={handleNext}
            handleBack={handleBack}
            isMobile={isMobile}
            handleSaveandClose={handleSaveandClose}
            viewPermitApplication={viewPermitApplication}
          />
        </PermitTemplate>
        <SiteLinkDialog
          siteId={this.props.siteId}
          isPermitSetAvailable={isPermitSetAvailable(applicationDetail)}
          projectType={projectType}
        />
        {this.state.showTemplateDialog && (
          <TemplatePopup
            showTemplateDialog={this.state.showTemplateDialog}
            handleTemplateDialogClose={this.handleTemplateDialogClose}
            templates={templates}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector('ProjectDetailsForm');
  const projectType = selector(state, 'project_type') || '';
  const ahj = selector(state, 'ahj') || '';
  return {
    ahj,
    projectType,
    initialValues: state.permitReducer.projectDetails,
    siteDetails: state.permitReducer.siteDetails,
    permitId: state.permitReducer.permitId,
    uProviders: state.permitReducer.uProviders,
    isSaveandClose: state.permitReducer.isSaveandClose,
    errors: state.permitReducer.errors,
    applicationDetail: state.permitReducer.applicationDetail,
    paymentStatus: state.permitReducer.paymentStatus,
    templates: state.permitReducer.templates,
    selectedTemplate: state.permitReducer.selectedTemplate,
    viewTemplate: state.permitReducer.viewTemplate,
    materialDetails: state.permitReducer.materialDetails,
    mostUsedValues: state.permitReducer.mostUsedValues,
    applyTemplate: state.permitReducer.applyTemplate,
    projectDetails: state.permitReducer.projectDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateMaterialDetails: (payload) => dispatch(updateMaterialDetails(payload)),
  createProjectDetails: (payload) => dispatch(createProjectDetails(payload)),
  updateAddress: (payload) => dispatch(updateAddress(payload)),
  getUtilityProviders: (payload) => dispatch(getUtilityProviders(payload)),
  setError: (payload) => dispatch(setError(payload)),
  getTemplates: (payload) => dispatch(getTemplates(payload)),
  setSelectedTemplate: (payload) => dispatch(setSelectedTemplate(payload)),
  deleteTemplate: (payload) => dispatch(deleteTemplate(payload)),
  showHideLoader: (payload) => dispatch(showHideLoader(payload)),
  setApplyTemplate: (payload) => dispatch(setApplyTemplate(payload)),
  setEnableSubmit: (enable) => dispatch(setEnableSubmit(enable)),
  updateCoordinates: (enable) => dispatch(updateCoordinates()),
  getAHJRecommendations: (payload) => dispatch(getAHJRecommendations(payload)),
  updateAhjId: (payload) => dispatch(change('ProjectDetailsForm', 'ahj_id', payload)),
  updateCountyField: (payload) => dispatch(change('ProjectDetailsForm', 'county', payload)),
  updateCounty: (payload) => dispatch(updateCounty(payload)),
});

const validate = (data) => {
  const { values, props, prevErrors = {}, changedField = '', isSubmit = false } = data;
  let errors = prevErrors;
  const requiredFields = [
    'system_name',
    'customer_name',
    'utility_provider',
    'ahj',
    'address',
    'zipcode',
    'project_type',
  ];

  if (isEmpty(changedField) && isSubmit) {
    requiredFields.forEach((field) => {
      let value = values[field];
      if (!value || value.trim() === '') {
        value = '';
      }
      if (!value) {
        errors = { ...errors, [field]: 'Required' };
      } else if (field === 'utility_provider' && !props.uProviders.find((uProvider) => uProvider.name === value)) {
        errors = { ...errors, [field]: 'Required' };
      } else {
        errors = { ...errors, [field]: '' };
      }
    });
  } else if (changedField) {
    if (requiredFields.includes(changedField)) {
      let value = values[changedField];
      if (!value || value.trim() === '') {
        value = '';
      }
      if (!value) {
        errors = { ...errors, [changedField]: 'Required' };
      }
      if (changedField === 'zipcode' && value.length > 10) {
        errors = { ...errors, zipcode: 'Max length allowed is 10' };
        values[changedField] = value.substr(0, 10);
      } else {
        errors = { ...errors, [changedField]: '' };
      }
    }
  }
  return errors;
};

const onChange = (values, dispatch, props) => {
  if (updatedValues === null) {
    updatedValues = values;
  }
  if (!isEqual(updatedValues, values)) {
    props.setEnableSubmit(true);
    updatedValues = values;
    const data = {
      values,
      props,
      prevErrors: props.errors,
      changedField,
    };
    const errors = validate(data);
    props.setError(errors);
    if (zipCode !== values.zipcode) {
      zipCode = values.zipcode;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        props.getUtilityProviders({ zip_code: values.zipcode });
      }, 1000);
    }
  }
};

const fillMaterialDetails = (props, permitId) => {
  const isMaterialDetailsNull = get(props.applicationDetail, 'material_details') === null;
  if (!isEmpty(props.selectedTemplate) && props.applyTemplate && !isPermitSetAvailable(props.applicationDetail)) {
    const {
      roof_type,
      panel_manufacturer,
      panel_model,
      interconnection_strategy,
      interconnection_location,
      racking_manufacturer,
      attachment_manufacturer,
      racking_model,
      attachment_model,
      attachment_type,
      max_spacing_between_attachments,
    } = props.selectedTemplate;
    let materialDetailsFilledWithTemplate;
    if (isMaterialDetailsNull) {
      materialDetailsFilledWithTemplate = {
        arrays: [
          {
            quantity: '',
            pitch_deg: '',
            azimuth: '',
            max_rafter_span: 0,
            structural_number: '',
            structural_type: '',
            structural_at: '',
            panel_manufacturer: panel_manufacturer,
            panel_model: panel_model,
            installation_type: '',
            roof_type: roof_type,
          },
        ],
        existing_arrays: [
          {
            quantity: '',
            pitch_deg: '',
            azimuth: '',
            max_rafter_span: 0,
            structural_number: '',
            structural_type: '',
            structural_at: '',
            panel_manufacturer: panel_manufacturer,
            panel_model: panel_model,
            installation_type: '',
            roof_type: roof_type,
          },
        ],
        inverters: [],
        existing_inverters: [],
        storage_list: [],
        spec_sheets: [],
        existing_spec_sheets: [],
        interconnection_properties: {
          interconnection_strategy: interconnection_strategy,
          interconnection_location: interconnection_location,
        },
        electrical_properties: {
          service_type: '',
          utility_meter_location: '',
          feeder_type: '',
          main_bus_rating: '',
          main_breaker_rating: '',
          main_breaker_location: '',
          existing_grounding: '',
          subpanel_breaker_rating: '',
          subpanel_bus_rating: '',
          msp_upgrade: false,
          solar_ready: false,
          spare_breaker_count: null,
          bus_rating: '',
          main_breaker: '',
          msp_location: '',
          location_image_file_name: '',
          coordinates: { lat: null, lng: null },
          existing_generator: null,
          generator_connection: null,
          generator_manufacturer: '',
          generator_model: '',
          generator_type: 'Generator',
          generator_quantity: 1,
          generator_photos: [],
        },
        racking_attachment_properties: {
          racking_manufacturer: racking_manufacturer,
          racking_model: racking_model,
          attachment_manufacturer: attachment_manufacturer,
          attachment_model: attachment_model,
          attachment_type: attachment_type,
          attachment_spacing: max_spacing_between_attachments,
          wind_load: '',
          snow_load: '',
          notes: '',
        },
        documents: [],
        document_link: '',
        photos: [],
        photo_link: '',
        additional_info: '',
        equipment_location: [],
      };
    } else {
      const arrays = get(props.applicationDetail, 'material_details.arrays');
      const existingArrays = get(props.applicationDetail, 'material_details.existing_arrays');
      arrays &&
        arrays.forEach((array) => {
          array.panel_manufacturer = panel_manufacturer;
          array.panel_model = panel_model;
          array.roof_type = roof_type;
        });
      existingArrays &&
        existingArrays.forEach((array) => {
          array.panel_manufacturer = panel_manufacturer;
          array.panel_model = panel_model;
          array.roof_type = roof_type;
        });
      materialDetailsFilledWithTemplate = {
        ...get(props.applicationDetail, 'material_details'),
      };
      materialDetailsFilledWithTemplate.interconnection_properties.interconnection_strategy = interconnection_strategy;
      materialDetailsFilledWithTemplate.interconnection_properties.interconnection_location = interconnection_location;
      materialDetailsFilledWithTemplate.racking_attachment_properties = {
        ...materialDetailsFilledWithTemplate.racking_attachment_properties,
        racking_manufacturer: racking_manufacturer,
        racking_model: racking_model,
        attachment_manufacturer: attachment_manufacturer,
        attachment_model: attachment_model,
        attachment_type: attachment_type,
        attachment_spacing: max_spacing_between_attachments,
      };
      materialDetailsFilledWithTemplate.arrays = arrays;
      materialDetailsFilledWithTemplate.existing_arrays = existingArrays;
    }
    props.updateMaterialDetails({
      ...materialDetailsFilledWithTemplate,
      permit_id: permitId,
      template_id: get(props.selectedTemplate, 'id', ''),
      isProceed: false,
      stepCompleted: 1,
    });
  }

  props.setApplyTemplate(false);
};

const submitProjectDetails = (values, dispatch, props) => {
  values.template_id = get(props, 'selectedTemplate.id', '');
  values.apply_template = false;
  if (values.project_type === PROJECT_TYPES.COMMERCIAL) {
    values.system_types[1].value = false;
    if (!values.system_types[0].value) {
      values.system_types[0].value = true;
      values.system_type_v2.id = 1;
      values.system_type_v2.name = 'New Installation';
    }
  }
  if (props.isSaveandClose) {
    props.createProjectDetails({
      projectDetails: values,
      permitId: props.permitId,
      isProceed: false,
      successCbk: (permitId) => fillMaterialDetails(props, permitId),
    });
  } else {
    const data = {
      values,
      props,
      isSubmit: true,
    };
    const errors = validate(data);
    if (isEmpty(errors) || !Object.keys(errors).find((key) => !isEmpty(errors[key]))) {
      props.createProjectDetails({
        projectDetails: values,
        permitId: props.permitId,
        isProceed: true,
        isSave: props.isSave,
        successCbk: (permitId) => {
          if (props.paymentStatus && props.isSubmit) {
            props.handleSubmit(values, props.materialDetails);
          } else if ((props.isSave && !props.isSaveAndClose) || !props.isSave) {
            props.jumpToPage(1);
            fillMaterialDetails(props, permitId);
          }
        },
      });
    }
    props.setError(errors);
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'ProjectDetailsForm',
    enableReinitialize: true,
    onChange,
    onSubmit: submitProjectDetails,
  })(withTheme(withStyles(styles)(ProjectDetailsForm)))
);
