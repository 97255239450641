import {
  GET_EQUIPMENT_USERS_REQUEST,
  GET_EQUIPMENT_FILE_DELETE_REQUEST,
  GET_EQUIPMENT_MANUFACTURERS_REQUEST,
  GET_ALL_EQUIPMENT_REQUEST,
  GET_EQUIPMENT_REQUEST,
  CREATE_EQUIPMENT_REQUEST,
  CREATE_EQUIPMENT_SUCCESS,
  GET_EQUIPMENT_FILTERS_REQUEST,
  GET_EQUIPMENT_MODEL_VALIDATION_REQUEST,
  GET_EQUIPMENT_DELETE_REQUEST,
  GET_EQUIPMENT_DELETE_SUCCESS,
  GET_EQUIPMENT_DELETE_FAILURE,
  CREATE_BATTERY_GROUP_REQUEST,
  CREATE_BATTERY_GROUP_SUCCESS,
  CREATE_BATTERY_GROUP_FAILURE
} from '../constants';

export function getAllUsers(payload) {
  return { type: GET_EQUIPMENT_USERS_REQUEST, payload };
}

export function deleteEquipmentUploadedFile(payload) {
  return { type: GET_EQUIPMENT_FILE_DELETE_REQUEST, payload };
}

export function getEquipmentModelValidation(payload) {
  return { type: GET_EQUIPMENT_MODEL_VALIDATION_REQUEST, payload };
}

export function getAllManufacturers(payload) {
  return { type: GET_EQUIPMENT_MANUFACTURERS_REQUEST, payload };
}

export function getAllEquipment(payload) {
  return { type: GET_ALL_EQUIPMENT_REQUEST, payload };
}

export function getEquipment(payload) {
  return { type: GET_EQUIPMENT_REQUEST, payload };
}

export function createEquipment(payload) {
  return { type: CREATE_EQUIPMENT_REQUEST, payload };
}

export function createEquipmentSuccess(payload) {
  return { type: CREATE_EQUIPMENT_SUCCESS, payload };
}

export function setEquipmentFilters(payload) {
  return { type: GET_EQUIPMENT_FILTERS_REQUEST, payload };
}

export function deleteEquipment(payload) {
  return { type: GET_EQUIPMENT_DELETE_REQUEST, payload };
}

export function deleteEquipmentSuccess(payload) {
  return { type: GET_EQUIPMENT_DELETE_SUCCESS, payload };
}

export function deleteEquipmentFailure(payload) {
  return { type: GET_EQUIPMENT_DELETE_FAILURE, payload };
}

export function createBatteryGroup(payload) {
  return { type: CREATE_BATTERY_GROUP_REQUEST, payload };
}

export function createBatteryGroupSuccess(payload) {
  return { type: CREATE_BATTERY_GROUP_SUCCESS, payload };
}
