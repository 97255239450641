import { isEqual } from '../../utils/lodash';
import { constructQueryString, getToken } from '../../utils/helper';
import settings from '../../utils/settings';
import { take, put, call, takeLatest } from 'redux-saga/effects';
import { deleteMethod, get, post, putMethod } from '../../utils/api';
import {
  GET_PERMITTING_REQUESTS_REQUEST,
  GET_PERMITTING_SUCCESS,
  GET_REQUEST_FAILURE,
  GET_PERMITTING_STATISTICS_REQUEST,
  GET_PERMITTING_STATISTICS_SUCCESS,
  GET_MSG_HISTORY_REQUEST,
  GET_MSG_HISTORY_SUCCESS,
  GET_PERMITTING_REQUEST_DETAILS_SUCCESS,
  GET_PERMITTING_REQUEST_DETAILS_REQUEST,
  GET_ANALYTICS_REPORT_REQUEST,
  GET_ANALYTICS_REPORT_FAILURE,
  GET_SOLARGRAF_TOKEN,
  GET_SOLARGRAF_TOKEN_SUCCESS,
  GET_SOLARGRAF_USER,
  GET_SOLARGRAF_USER_SUCCESS,
  CREATE_SOLARGRAF_ACCOUNT,
  CREATE_SOLARGRAF_ACCOUNT_SUCCESS,
  CREATE_SOLARGRAF_ACCOUNT_FAILURE,
  UPDATE_STAGE_STATUS_REQUEST,
  UPDATE_STAGE_STATUS_SUCCESS,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_PERMITTING_REQUESTS_FAILURE,
  GET_PERMITTING_STATISTICS_FAILURE,
  GET_NOTIFICATIONS_FAILURE,
  GET_PRESIGNED_URL,
  FILE_UPLOAD_TO_S3_REQUEST,
  GET_PDF_FILE_FROM_S3_REQUEST,
  DOWNLOAD_DETAILS_REQUEST,
  DOWNLOAD_DETAILS_SUCCESS,
  DOWNLOAD_ATTACHMENTS_REQUEST,
  DOWNLOAD_ATTACHMENTS_SUCCESS,
  GET_SFDC_DATA_REQUEST,
  GET_SFDC_DATA_SUCCESS,
  GET_SFDC_DATA_FAILURE,
  CREATE_AHJ_REQUEST,
  CREATE_AHJ_SUCCESS,
  CREATE_AHJ_FAILURE,
  GET_AHJ_REQUEST,
  GET_AHJ_FAILURE,
  GET_AHJ_SUCCESS,
  GET_AHJ_CITY_REQUEST,
  GET_AHJ_CITY_SUCCESS,
  GET_AHJ_CITY_FAILURE,
  GET_AHJ_NAME_VALIDATION_REQUEST,
  GET_AHJ_NAME_VALIDATION_SUCCESS,
  GET_AHJ_NAME_VALIDATION_FAILURE,
  GET_ALL_AHJ_REQUEST,
  GET_ALL_AHJ_SUCCESS,
  GET_ALL_AHJ_FAILURE,
  GET_AHJ_GOVERNING_CODES_REQUEST,
  GET_AHJ_GOVERNING_CODES_SUCCESS,
  GET_AHJ_GOVERNING_CODES_FAILURE,
  GET_AHJ_DEFAULT_FIRESETBACKS_REQUEST,
  GET_AHJ_DEFAULT_FIRESETBACKS_SUCCESS,
  GET_AHJ_DEFAULT_FIRESETBACKS_FAILURE,
  GET_ALL_UTILITY_REQUEST,
  GET_ALL_UTILITY_SUCCESS,
  GET_ALL_UTILITY_FAILURE,
  GET_UTILITY_REQUEST,
  GET_UTILITY_SUCCESS,
  GET_UTILITY_FAILURE,
  CREATE_UTILITY_REQUEST,
  CREATE_UTILITY_SUCCESS,
  CREATE_UTILITY_FAILURE,
  GET_UTILITY_BY_GENABILITY_REQUEST,
  GET_UTILITY_BY_GENABILITY_SUCCESS,
  GET_UTILITY_BY_GENABILITY_FAILURE,
  GET_UTILITY_NAME_VALIDATION_REQUEST,
  GET_UTILITY_NAME_VALIDATION_SUCCESS,
  GET_UTILITY_NAME_VALIDATION_FAILURE,
  GET_REVIEWERS_LIST_REQUEST,
  GET_REVIEWERS_LIST_SUCCESS,
  ASSIGN_REVIEWER_REQUEST,
  ASSIGN_REVIEWER_SUCCESS,
  UPDATE_REVIEWER_REQUEST,
  UPDATE_REVIEWER_SUCCESS,
  GET_EDITED_VERSION_DETAILS_REQUEST,
  GET_EDITED_VERSION_DETAILS_SUCCESS,
  GET_EDITED_VERSION_DETAILS_FAILURE,
  GET_EDITED_APPLICATION_DETAILS_REQUEST,
  GET_EDITED_APPLICATION_DETAILS_SUCCESS,
  GET_EDITED_APPLICATION_DETAILS_FAILURE,
  GET_SG_PROJECT_MAP_REQUEST,
  GET_SG_PROJECT_MAP_SUCCESS,
  GET_SG_PROJECT_MAP_FAILURE,
  SET_SG_PROJECT_ID_REQUEST,
  SET_SG_PROJECT_ID_SUCCESS,
  SET_SG_PROJECT_ID_FAILURE,
  DOWNLOAD_PHOTO_REQUEST,
  DOWNLOAD_PHOTO_SUCCESS,
  GET_SOLARGRAF_COMPANIES_REQUEST,
  GET_SOLARGRAF_COMPANIES_SUCCESS,
  GET_SOLARGRAF_COMPANIES_FAILURE,
  SET_ADMIN_COMPANIES_REQUEST,
  SET_ADMIN_COMPANIES_SUCCESS,
  SET_ADMIN_COMPANIES_FAILURE,
  GET_ADMIN_COMPANIES_REQUEST,
  GET_ADMIN_COMPANIES_SUCCESS,
  GET_ADMIN_COMPANIES_FAILURE,
  GET_SG_APP_ADMINS_REQUEST,
  GET_SG_APP_ADMINS_SUCCESS,
  GET_SG_APP_ADMINS_FAILURE,
  CLONE_PROJECT_FAILURE,
  CLONE_PROJECT_SUCCESS,
  CLONE_PROJECT_REQUEST,
  GET_STATIC_MSP_UM_IMG_URL_REQUEST,
  GET_AHJ_DEFAULT_ALL_FIRESETBACKS_REQUEST,
  GET_AHJ_DEFAULT_ALL_FIRESETBACKS_SUCCESS,
  GET_AHJ_DEFAULT_ALL_FIRESETBACKS_FAILURE,
} from './constants';

let requestingApplications = false;
export function* getPermittingRequestsFlow() {
  if (!requestingApplications) {
    while (true) {
      const request = yield take(GET_PERMITTING_REQUESTS_REQUEST);
      const {
        pageNum,
        recordCount,
        sort_column,
        sort_order,
        city,
        state,
        installerIds,
        customerName,
        permitId,
        status,
        siteId,
        version,
        projectType,
        startDate,
        endDate,
        lastModifiedStartDate,
        lastModifiedEndDate,
        assignTo,
        showAllRecords,
        appType,
        projectName,
        globalFilter,
        list,
        successCb = () => {},
      } = request.payload;
      const paramString = constructQueryString({
        page: pageNum,
        size: recordCount,
        column: sort_column,
        order: sort_order,
        permitId: permitId,
        installerIds: installerIds,
        customerName: customerName,
        city: city,
        state: state,
        applicationState: status,
        startDate: startDate,
        endDate: endDate,
        lastModifiedStartDate: lastModifiedStartDate,
        lastModifiedEndDate: lastModifiedEndDate,
        assignTo: assignTo,
        siteId: siteId,
        version: version,
        projectType: projectType,
        showAllRecords: showAllRecords,
        appType: appType,
        projectName: projectName,
        globalFilter: globalFilter,
        list: list,
      });
      try {
        requestingApplications = true;
        const response = yield call(get, {
          url: settings.permittingCoreUrl('permit/permit/applications' + paramString),
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: GET_PERMITTING_SUCCESS,
            permitting_requests: result.content,
            totalElements: result.totalElements,
            currentlySending: false,
            message: '',
          });
          successCb();
        } else {
          yield put({
            type: GET_PERMITTING_REQUESTS_FAILURE,
            message: result.message || result.messages,
          });
        }
        requestingApplications = false;
      } catch (error) {
        yield put({ type: GET_PERMITTING_REQUESTS_FAILURE, message: error });
        requestingApplications = false;
      }
    }
  }
}

let requestingStats = false;
export function* getPermittingStatisticsFlow() {
  if (!requestingStats) {
    while (true) {
      const request = yield take(GET_PERMITTING_STATISTICS_REQUEST);
      try {
        const { appType = '' } = request.payload;
        requestingStats = true;
        const response = yield call(get, {
          url: settings.permittingCoreUrl(`permit/status_count?appType=${appType}`),
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          let sCount = result.status_count;
          let sData = [];
          if (sCount) {
            for (const [key, value] of Object.entries(sCount)) {
              if (value !== 0) {
                let s = {
                  stage_name: key,
                  total: value,
                };
                sData.push(s);
              }
            }
          }
          yield put({
            type: GET_PERMITTING_STATISTICS_SUCCESS,
            permitting_statistics: sData,
            currentlySending: false,
            message: '',
          });
        } else {
          yield put({
            type: GET_PERMITTING_STATISTICS_FAILURE,
            message: result.message || result.messages,
          });
        }
        requestingStats = false;
      } catch (error) {
        yield put({ type: GET_PERMITTING_STATISTICS_FAILURE, message: error });
        requestingStats = false;
      }
    }
  }
}

let requestingPermitDetails = false;
export function* getPermittingRequestDetailsFlow() {
  if (!requestingPermitDetails) {
    while (true) {
      const request = yield take(GET_PERMITTING_REQUEST_DETAILS_REQUEST);
      const { application_id, successCbk = () => {} } = request.payload;
      try {
        let response;
        requestingPermitDetails = true;
        response = yield call(get, {
          url: settings.permittingCoreUrl(`permit/permit/${application_id}/application`),
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: GET_PERMITTING_REQUEST_DETAILS_SUCCESS,
            permitting_application: result.permit_application,
            currentlySending: false,
            message: '',
          });
          successCbk(result.permit_application);
        } else {
          yield put({
            type: GET_REQUEST_FAILURE,
            message: result.message || result.messages,
          });
        }
        requestingPermitDetails = false;
      } catch (error) {
        yield put({ type: GET_REQUEST_FAILURE, message: error });
        requestingPermitDetails = false;
      }
    }
  }
}

let requestingToken = false;
export function* getSolargrafTokenFlow() {
  if (!requestingToken) {
    while (true) {
      const request = yield take(GET_SOLARGRAF_TOKEN);
      const { successCB, failureCB } = request.payload;
      try {
        let response;
        requestingToken = true;
        response = yield call(post, {
          url: settings.getSolargrafAPIUrl(`enlm/sessions?token=${getToken()}`),
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: GET_SOLARGRAF_TOKEN_SUCCESS,
            solargraf_token: result.token,
            currentlySending: false,
            message: '',
          });
          if (result.token) successCB(result);
        } else {
          failureCB();
          yield put({
            type: GET_REQUEST_FAILURE,
            message: result.message || result.messages,
          });
        }
        requestingToken = false;
      } catch (error) {
        failureCB();
        yield put({ type: GET_REQUEST_FAILURE, message: error });
        requestingToken = false;
      }
    }
  }
}

let requestingSGUser = false;
export function* getSolargrafUserFlow() {
  if (!requestingSGUser) {
    while (true) {
      const request = yield take(GET_SOLARGRAF_USER);
      const { successCB, failureCB, token } = request.payload;
      try {
        let response;
        requestingSGUser = true;
        response = yield call(get, {
          url: settings.getSolargrafAPIUrl(`me`),
          headers: { Authorization: `Bearer ${token}` },
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: GET_SOLARGRAF_USER_SUCCESS,
            currentlySending: false,
            message: '',
            sgUser: result,
          });
          successCB(result);
        } else {
          failureCB();
          yield put({
            type: GET_REQUEST_FAILURE,
            message: result.message || result.messages,
          });
        }
        requestingSGUser = false;
      } catch (error) {
        failureCB();
        yield put({ type: GET_REQUEST_FAILURE, message: error });
        requestingSGUser = false;
      }
    }
  }
}

let creatingSGAcc = false;
export function* createSolargrafAccountFlow() {
  if (!creatingSGAcc) {
    while (true) {
      const request = yield take(CREATE_SOLARGRAF_ACCOUNT);
      const { successCB } = request.payload;
      try {
        creatingSGAcc = true;
        const response = yield call(post, {
          url: settings.permittingCoreUrl(`permit/sg/user/new`),
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: CREATE_SOLARGRAF_ACCOUNT_SUCCESS,
            message: '',
          });
          if (result.user) successCB();
        } else {
          yield put({
            type: CREATE_SOLARGRAF_ACCOUNT_FAILURE,
            message: result.message || result.messages,
          });
        }
        creatingSGAcc = false;
      } catch (error) {
        yield put({ type: CREATE_SOLARGRAF_ACCOUNT_FAILURE, message: error });
        creatingSGAcc = false;
      }
    }
  }
}

export function* getReviewersListFlow() {
  while (true) {
    const request = yield take(GET_REVIEWERS_LIST_REQUEST);
    try {
      const { appType = '' } = request.payload;
      const response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/admin/reviewers?appType=${appType}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: GET_REVIEWERS_LIST_SUCCESS, reviewersList: result });
      } else {
        yield put({
          type: GET_REQUEST_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

let assignReviewer = false;
export function* assignReviewerFlow() {
  while (true) {
    const request = yield take(ASSIGN_REVIEWER_REQUEST);
    if (!assignReviewer) {
      assignReviewer = true;
      try {
        const { permitId, designerEmail, reviewerEmail, details = null, successCbk = () => {} } = request.payload;
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(
            `permit/admin/${permitId}/assign?designer_email=${designerEmail}&reviewer_email=${reviewerEmail}`
          ),
          withBearer: true,
          body: details,
        });
        const { success, status, result } = response;
        assignReviewer = false;
        if (success && status === 200) {
          yield put({ type: ASSIGN_REVIEWER_SUCCESS });
          successCbk();
        } else {
          yield put({
            type: GET_REQUEST_FAILURE,
            message: result.message || result.messages,
          });
        }
      } catch (error) {
        yield put({ type: GET_REQUEST_FAILURE, message: error });
        assignReviewer = false;
      }
    }
  }
}

export function* getMsgHistoryFlow() {
  while (true) {
    const request = yield take(GET_MSG_HISTORY_REQUEST);
    const { request_id } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/messages_pending?permit_id=${request_id}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_MSG_HISTORY_SUCCESS,
          msg_histories: result.message_list,
          currentlySending: false,
          message: '',
        });
      } else {
        yield put({
          type: GET_REQUEST_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

let updateReviewer = false;
export function* updateStatusReviewerFlow() {
  while (true) {
    const request = yield take(UPDATE_REVIEWER_REQUEST);
    if (!updateReviewer) {
      updateReviewer = true;
      try {
        const { permitId, details = null, successCbk = () => {} } = request.payload;
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/underProgress`),
          withBearer: true,
          body: details,
        });
        const { success, status, result } = response;
        updateReviewer = false;
        if (success && status === 200) {
          yield put({ type: ASSIGN_REVIEWER_SUCCESS });
          successCbk();
        } else {
          yield put({
            type: GET_REQUEST_FAILURE,
            message: result.message || result.messages,
          });
        }
      } catch (error) {
        yield put({ type: GET_REQUEST_FAILURE, message: error });
        updateReviewer = false;
      }
    }
  }
}

let updatingStageStatus = false;
export function* updateStageStatusUpdateFlow() {
  if (!updatingStageStatus) {
    while (true) {
      const request = yield take(UPDATE_STAGE_STATUS_REQUEST);
      const { app_id, stage_id, updatedDate, fileObj } = request.payload;
      try {
        updatingStageStatus = true;
        let response;
        let bodyObj = {
          permit_id: app_id,
          state_id: stage_id,
          state_date_in_local: updatedDate,
        };
        if (stage_id === 6 || stage_id === 9) {
          bodyObj.permit_files = fileObj;
        }
        response = yield call(post, {
          url: settings.permittingCoreUrl(`permit/update_state`),
          body: bodyObj,
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: UPDATE_STAGE_STATUS_SUCCESS,
            messageTitle: 'Confirmation',
            message: 'Application status updated successfully.',
            showMessageDialog: true,
          });
        } else {
          yield put({
            type: GET_REQUEST_FAILURE,
            message: result.message || result.messages,
          });
        }
        updatingStageStatus = false;
      } catch (error) {
        yield put({ type: GET_REQUEST_FAILURE, message: error });
        updatingStageStatus = false;
      }
    }
  }
}

let sendingMsg = false;
export function* sendMessageFlow() {
  if (!sendingMsg) {
    while (true) {
      const request = yield take(SEND_MESSAGE_REQUEST);
      const { app_id, mObj, fileName } = request.payload;

      try {
        let response;
        sendingMsg = true;
        response = yield call(post, {
          url: settings.permittingCoreUrl(`permit/message`),
          body: {
            permit_id: app_id,
            subject: mObj.mSubject,
            body_content: mObj.mBody,
            file_names: fileName === '' ? [] : [fileName],
            document_requested: mObj.requested_doc,
          },
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: SEND_MESSAGE_SUCCESS,
            messageTitle: 'Confirmation',
            message: 'Message sent to applicant successfully.',
            showMessageDialog: true,
          });
        } else {
          yield put({
            type: GET_REQUEST_FAILURE,
            message: result.message || result.messages,
          });
        }
        sendingMsg = false;
      } catch (error) {
        yield put({ type: GET_REQUEST_FAILURE, message: error });
        sendingMsg = false;
      }
    }
  }
}

export function* getNotificationsFlow() {
  while (true) {
    const request = yield take(GET_NOTIFICATIONS_REQUEST);
    const { request_id } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/notification_history?permit_id=${request_id}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_NOTIFICATIONS_SUCCESS,
          notifications: result.notifications,
          currentlySending: false,
          message: '',
        });
      } else {
        yield put({
          type: GET_NOTIFICATIONS_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_NOTIFICATIONS_FAILURE, message: error });
    }
  }
}

let getS3Url = false;
export function* getPresignedURLFlow() {
  while (true) {
    const request = yield take(GET_PRESIGNED_URL);
    const { file_name, http_method, successCb, failureCb } = request.payload;
    if (!getS3Url) {
      try {
        getS3Url = true;
        let response;
        response = yield call(get, {
          url: settings.permittingCoreUrl(
            `permit/permit/presignedurl?file_name=${encodeURIComponent(file_name)}&http_method=${http_method}`
          ),
          withBearer: true,
        });
        const { success, status, result } = response;
        getS3Url = false;
        if (success && status === 200) {
          successCb(result);
        } else {
          failureCb();
          yield put({
            type: GET_REQUEST_FAILURE,
            message: result.message || result.messages,
          });
        }
      } catch (error) {
        failureCb();
        getS3Url = false;
        yield put({ type: GET_REQUEST_FAILURE, message: error });
      }
    }
  }
}
let uploadToS3 = false;
export function* uploadToS3Flow() {
  while (true) {
    const request = yield take(FILE_UPLOAD_TO_S3_REQUEST);
    const { preSignedS3Url, fName, fileObj, successCbS3, failureCbS3 } = request.payload;
    if (!uploadToS3) {
      try {
        uploadToS3 = true;
        var formData = new FormData();
        formData.append('data', fileObj, fName);
        var requestOptions = {
          method: 'PUT',
          body: fileObj,
          headers: { 'Content-Type': 'application/octet-stream' },
        };
        fetch(preSignedS3Url, requestOptions).then(function (data) {
          const { status } = data;
          uploadToS3 = false;
          if (status === 200) {
            successCbS3();
          } else {
            failureCbS3();
          }
        });
      } catch (error) {
        yield put({ type: GET_REQUEST_FAILURE, message: error });
        uploadToS3 = false;
        failureCbS3();
      }
    }
  }
}

export function* fileDownloadFromS3Flow() {
  while (true) {
    const request = yield take(GET_PDF_FILE_FROM_S3_REQUEST);
    const { preSignedS3Url, successCbS3, failureCbS3 } = request.payload;

    try {
      var requestOptions = {
        method: 'GET',
      };
      fetch(preSignedS3Url, requestOptions).then(function (data) {
        const { status } = data;
        if (status === 200) {
          successCbS3(data);
        } else {
          failureCbS3();
        }
      });
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCbS3();
    }
  }
}

export function* downloadDetailsFlow() {
  while (true) {
    const request = yield take(DOWNLOAD_DETAILS_REQUEST);
    const { application_id, successCb } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/downloadDetails/${application_id}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: DOWNLOAD_DETAILS_SUCCESS,
          request_details: result,
          currentlySending: false,
          message: '',
        });
        successCb(result);
      } else {
        yield put({
          type: GET_REQUEST_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* downloadAttachments() {
  while (true) {
    const request = yield take(DOWNLOAD_ATTACHMENTS_REQUEST);
    const { application_id, version, successCb } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/downloadAllAttachments/${application_id}/${version}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: DOWNLOAD_ATTACHMENTS_SUCCESS,
          request_details: result,
          currentlySending: false,
          message: '',
        });
        successCb(result);
      } else {
        yield put({
          type: GET_REQUEST_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

export function* getAccountManagerInfoFlow() {
  while (true) {
    const request = yield take(GET_SFDC_DATA_REQUEST);
    const { permitId } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`SFDCAccountManagerData/${permitId}`),
        withBearer: true,
      });
      if (response.success) {
        yield put({
          type: GET_SFDC_DATA_SUCCESS,
          accountManagerInfo: response.result.account_manager_info,
        });
      } else {
        yield put({
          type: GET_SFDC_DATA_FAILURE,
          error: response.result.message || response.result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_SFDC_DATA_FAILURE, error: error });
    }
  }
}

let creatingAHJ = false;
export function* createAHJFlow() {
  if (!creatingAHJ) {
    while (true) {
      const request = yield take(CREATE_AHJ_REQUEST);
      const { requestBody, successCB, id = 'new' } = request.payload;
      try {
        let response;
        let requestMethod = putMethod;
        let message = 'AHJ updated successfully';
        if (id === 'new') {
          requestMethod = post;
          message = 'AHJ created successfully';
        }
        creatingAHJ = true;
        response = yield call(requestMethod, {
          url: settings.permittingCoreUrl(`permit/ahj/${id}`),
          body: requestBody,
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: CREATE_AHJ_SUCCESS,
            message,
            ahj: result,
          });
          if (result.id) {
            successCB(result);
          }
        } else {
          yield put({
            type: CREATE_AHJ_FAILURE,
            message: result.message || result.messages,
          });
        }
        creatingAHJ = false;
      } catch (error) {
        yield put({ type: CREATE_AHJ_FAILURE, message: error });
        creatingAHJ = false;
      }
    }
  }
}

export function* getAHJFlow() {
  while (true) {
    const request = yield take(GET_AHJ_REQUEST);
    const { id, successCB, failureCB } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/ahj/${id}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_AHJ_SUCCESS,
          ahj: result,
        });
        if (result.id) successCB(result);
      } else {
        yield put({
          type: GET_AHJ_FAILURE,
          message: result.message || result.messages,
        });
        failureCB();
      }
    } catch (error) {
      yield put({ type: GET_AHJ_FAILURE, message: error });
    }
  }
}

export function* getAHJCityFlow() {
  while (true) {
    const request = yield take(GET_AHJ_CITY_REQUEST);
    const { state, successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/ahj/cities/${state}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_AHJ_CITY_SUCCESS,
          ahjCityCountyDetails: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_AHJ_CITY_FAILURE, message: error });
    }
  }
}

export function* getAHJNameValidationFlow() {
  while (true) {
    const request = yield take(GET_AHJ_NAME_VALIDATION_REQUEST);
    const { ahjName, successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/ahj/validAHJName?ahj_name=${encodeURIComponent(ahjName)}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_AHJ_NAME_VALIDATION_SUCCESS,
          isAHJNameUnique: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_AHJ_NAME_VALIDATION_FAILURE, message: error });
    }
  }
}

export function* getAllAHJFlow() {
  while (true) {
    const request = yield take(GET_ALL_AHJ_REQUEST);
    const {
      pageNum,
      recordCount,
      sort_column,
      sort_order,
      name = '',
      city = '',
      county = '',
      state = '',
      modified_by = '',
      current_status = '',
      verified_by = '',
      from_modified_at = '',
      to_modified_at = '',
      from_verified_at = '',
      to_verified_at = '',
      filter_record = '',
      reference_status = '',
      successCbk = () => {},
    } = request.payload;
    let defaultParams =
      `page=${pageNum}&size=${recordCount}&column=${sort_column}&order=${sort_order}` +
      `&name=${name}&city=${city}&county=${county}&state=${state}&modified_by=${modified_by}&from_modified_at=${from_modified_at}&to_modified_at=${to_modified_at}` +
      `&current_status=${current_status}&verified_by=${verified_by}&from_verified_at=${from_verified_at}&to_verified_at=${to_verified_at}&filter_record=${filter_record}` +
      `&reference_status=${reference_status}`;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/ahj/?${defaultParams}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_ALL_AHJ_SUCCESS,
          ahjList: result.content,
          totalElements: result.totalElements,
          message: '',
        });
        successCbk();
      } else {
        yield put({
          type: GET_ALL_AHJ_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_ALL_AHJ_FAILURE, message: error });
    }
  }
}

export function* getAHJGoverningCodesFlow() {
  while (true) {
    const request = yield take(GET_AHJ_GOVERNING_CODES_REQUEST);
    const { category, successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: isEqual(category[0], 'othercodes')
          ? settings.permittingCoreUrl(`permit/ahj/ahjcode/${category[0]}?category_name=${category[1]}`)
          : settings.permittingCoreUrl(`permit/ahj/ahjcode/${category[0]}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_AHJ_GOVERNING_CODES_SUCCESS,
          ahjGoverningCodesList: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_AHJ_GOVERNING_CODES_FAILURE, message: error });
    }
  }
}

export function* getAHJDefaultFiresetbacksFlow() {
  while (true) {
    const request = yield take(GET_AHJ_DEFAULT_FIRESETBACKS_REQUEST);
    const { irc = ' ', ifc = ' ', successCb,failureCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/ahj/getFiresetbacksFromGoverningCodes?irc=${irc}&ifc=${ifc}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_AHJ_DEFAULT_FIRESETBACKS_SUCCESS,
        });
        successCb(result);
      } else {
        yield put({
          type: GET_AHJ_DEFAULT_FIRESETBACKS_FAILURE,
          message: result.message || result.messages,
        });
        failureCb();
      }
    } catch (error) {
      yield put({ type: GET_AHJ_DEFAULT_FIRESETBACKS_FAILURE, message: error });
    }
  }
}

export function* getBothFiresetbacksFromGoverningCodes() {
  while (true) {
    const request = yield take(GET_AHJ_DEFAULT_ALL_FIRESETBACKS_REQUEST);
    const { irc = ' ', ifc = ' ', successCb,failureCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/ahj/getBothFiresetbacksFromGoverningCodes?irc=${irc}&ifc=${ifc}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_AHJ_DEFAULT_ALL_FIRESETBACKS_SUCCESS,
        });
        successCb(result);
      } else {
        yield put({
          type: GET_AHJ_DEFAULT_ALL_FIRESETBACKS_FAILURE,
          message: result.message || result.messages,
        });
        failureCb();
      }
    } catch (error) {
      yield put({ type: GET_AHJ_DEFAULT_ALL_FIRESETBACKS_FAILURE, message: error });
    }
  }
}

export function* getAllUtilityFlow() {
  while (true) {
    const request = yield take(GET_ALL_UTILITY_REQUEST);
    const {
      pageNum,
      recordCount,
      sort_column,
      sort_order,
      name = '',
      state = '',
      modified_by = '',
      current_status = '',
      verified_by = '',
      from_modified_at = '',
      to_modified_at = '',
      from_verified_at = '',
      to_verified_at = '',
      filter_record = '',
      successCb = () => {},
    } = request.payload;
    let defaultParams =
      `page=${pageNum}&size=${recordCount}&column=${sort_column}&order=${sort_order}` +
      `&name=${name}&state=${state}&modified_by=${modified_by}&from_modified_at=${from_modified_at}&to_modified_at=${to_modified_at}` +
      `&current_status=${current_status}&verified_by=${verified_by}&from_verified_at=${from_verified_at}&to_verified_at=${to_verified_at}&filter_record=${filter_record}`;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/utility/?${defaultParams}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_ALL_UTILITY_SUCCESS,
          utilityList: result.content,
          totalElements: result.totalElements,
          message: '',
        });
        successCb();
      } else {
        yield put({
          type: GET_ALL_UTILITY_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_ALL_UTILITY_FAILURE, message: error });
    }
  }
}

export function* getUtilityFlow() {
  while (true) {
    const request = yield take(GET_UTILITY_REQUEST);
    const { id, successCB, failureCB } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/utility/${id}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_UTILITY_SUCCESS,
          utility: result,
        });
        if (result.id) successCB(result);
      } else {
        yield put({
          type: GET_UTILITY_FAILURE,
          message: result.message || result.messages,
        });
        failureCB();
      }
    } catch (error) {
      yield put({ type: GET_UTILITY_FAILURE, message: error });
    }
  }
}

let creatingUtility = false;
export function* createUtilityFlow() {
  if (!creatingUtility) {
    while (true) {
      const request = yield take(CREATE_UTILITY_REQUEST);
      const { requestBody, successCB, id = 'new' } = request.payload;
      try {
        let response;
        let requestMethod = putMethod;
        let message = 'Utility updated successfully';
        if (id === 'new') {
          requestMethod = post;
          message = 'Utility created successfully';
        }
        creatingUtility = true;
        response = yield call(requestMethod, {
          url: settings.permittingCoreUrl(`permit/utility/${id}`),
          body: requestBody,
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: CREATE_UTILITY_SUCCESS,
            message,
            utility: result,
          });
          if (result.id) {
            successCB(result);
          }
        } else {
          yield put({
            type: CREATE_UTILITY_FAILURE,
            message: result.message || result.messages,
          });
        }
        creatingUtility = false;
      } catch (error) {
        yield put({ type: CREATE_UTILITY_FAILURE, message: error });
        creatingUtility = false;
      }
    }
  }
}

export function* getUtilityByGenabilityFlow() {
  while (true) {
    const request = yield take(GET_UTILITY_BY_GENABILITY_REQUEST);
    const { pageCount, pageStart, search, successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(
          `permit/utility/utilityProviders?usageTypes=SERVICE&containsItemType=STATE&containsItemValue=${search}` +
            `&pageCount=${pageCount}&pageStart=${pageStart}`
        ),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200 && result.status === 'success') {
        yield put({
          type: GET_UTILITY_BY_GENABILITY_SUCCESS,
          utilityByGenability: result,
        });
        successCb();
      } else {
        yield put({
          type: GET_UTILITY_BY_GENABILITY_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_UTILITY_BY_GENABILITY_FAILURE, message: error });
    }
  }
}

export function* getAnalyticsReportFlow() {
  while (true) {
    const request = yield take(GET_ANALYTICS_REPORT_REQUEST);
    const { startDate, endDate, successCb } = request.payload;
    try {
      const response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/downloadAnalyticsReport/?start_date=${startDate}&end_date=${endDate}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200 && result.status === 'success') {
        successCb(result);
      } else {
        yield put({
          type: GET_ANALYTICS_REPORT_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_ANALYTICS_REPORT_FAILURE, message: error });
    }
  }
}

export function* getUtilityNameValidationFlow() {
  while (true) {
    const request = yield take(GET_UTILITY_NAME_VALIDATION_REQUEST);
    const { utilityName, state, successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/utility/validUtilityName?utility_name=${encodeURIComponent(utilityName)}&state=${state}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_UTILITY_NAME_VALIDATION_SUCCESS,
        });
        successCb(result);
      }
    } catch (error) {
      yield put({ type: GET_UTILITY_NAME_VALIDATION_FAILURE, message: error });
    }
  }
}

export function* getEditedVersionFlow() {
  while (true) {
    const request = yield take(GET_EDITED_VERSION_DETAILS_REQUEST);
    const { successCb, application_id, failureCb = () => {} } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/permit/history/versions/${application_id}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_EDITED_VERSION_DETAILS_SUCCESS,
          versionsList: result.permit_application_versions,
        });
        successCb();
      } else {
        yield put({ type: GET_EDITED_VERSION_DETAILS_FAILURE, message: result.message });
        failureCb();
      }
    } catch (error) {
      yield put({ type: GET_EDITED_VERSION_DETAILS_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* getStaticMspUmImgURLFlow() {
  while (true) {
    const request = yield take(GET_STATIC_MSP_UM_IMG_URL_REQUEST);
    const { successCb, permitId, permitAppHistoryId, isFromHistory, failureCb = () => {} } = request.payload;

    try {
      let response = yield call(get, {
        url: settings.permittingCoreUrl(
          `permit/mspUmImgUrl/${permitId}?permitAppHistoryId=${permitAppHistoryId}&isFromHistory=${isFromHistory}`
        ),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        successCb(result.url);
      } else {
        failureCb();
      }
    } catch (error) {
      failureCb();
    }
  }
}

let getSgProjects = false;
export function* getSgProjectPermitMapFlow() {
  if (!getSgProjects) {
    while (true) {
      const request = yield take(GET_SG_PROJECT_MAP_REQUEST);
      const { permitId = '', sgToken = '', successCb = () => {}, failureCb = () => {} } = request.payload;
      try {
        getSgProjects = true;
        const response = yield call(get, {
          url: settings.getSolargrafAPIUrl(`enlmPermits/${permitId}`),
          headers: { Authorization: `Bearer ${sgToken}` },
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: GET_SG_PROJECT_MAP_SUCCESS,
          });
          successCb(result);
        } else {
          yield put({
            type: GET_SG_PROJECT_MAP_FAILURE,
            message: 'Please wait while we redirect you to Solargraf Design Tool.',
          });
          failureCb();
        }
        getSgProjects = false;
      } catch (error) {
        yield put({ type: GET_SG_PROJECT_MAP_FAILURE, message: error });
        getSgProjects = false;
      }
    }
  }
}

let projectCloning = false;
export function* cloneProjectFlow() {
  if (!projectCloning) {
    while (true) {
      const request = yield take(CLONE_PROJECT_REQUEST);
      const {
        permitId = '',
        applicationId = '',
        source = '',
        installer = '',
        sgToken = '',
        rafterDetails = {},
        successCb = () => {},
        failureCb = () => {},
      } = request.payload;
      try {
        projectCloning = true;
        const payload = {
          source: source,
          applicationId: applicationId,
          installer: installer,
          rafterDetails,
        };
        const response = yield call(post, {
          url: settings.getSolargrafAPIUrl(`partners/noida/permits/${permitId}/clone`),
          body: payload,
          headers: {
            Authorization: `Bearer ${sgToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: CLONE_PROJECT_SUCCESS,
          });
          successCb(result);
        } else {
          yield put({ type: CLONE_PROJECT_FAILURE, message: result.error.message || result.message });
          failureCb();
        }
        projectCloning = false;
      } catch (error) {
        yield put({ type: CLONE_PROJECT_FAILURE, message: error });
        projectCloning = false;
      }
    }
  }
}

let getApplication = false;
export function* getEditedApplicationFlow() {
  if (!getApplication) {
    while (true) {
      const request = yield take(GET_EDITED_APPLICATION_DETAILS_REQUEST);
      const { successCb, application_id, type = '', failureCb } = request.payload;
      try {
        getApplication = true;
        const response = yield call(get, {
          url: settings.permittingCoreUrl(`permit/permit/history/${application_id}`),
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          if (type === 'ORIGINAL') {
            successCb(result.permit_application_history);
          } else {
            yield put({
              type: GET_EDITED_APPLICATION_DETAILS_SUCCESS,
              permit_application_history: result.permit_application_history,
            });
            successCb();
          }
        }
        getApplication = false;
      } catch (error) {
        getApplication = false;
        yield put({ type: GET_EDITED_APPLICATION_DETAILS_FAILURE, message: error });
        failureCb();
      }
    }
  }
}

let setSgProjects = false;
export function* updateSgProjectIdFlow() {
  if (!setSgProjects) {
    while (true) {
      const request = yield take(SET_SG_PROJECT_ID_REQUEST);
      const { permitId = '', sgProjectId = '' } = request.payload;
      try {
        setSgProjects = true;
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/save_sg_project_id?sgProjectId=${sgProjectId}`),
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: SET_SG_PROJECT_ID_SUCCESS,
          });
        } else {
          yield put({ type: SET_SG_PROJECT_ID_FAILURE, message: result.message || result.messages });
        }
        setSgProjects = false;
      } catch (error) {
        yield put({ type: SET_SG_PROJECT_ID_FAILURE, message: error });
        setSgProjects = false;
      }
    }
  }
}

let downloadFlag = false;
export function* downloadPhotosFlow() {
  if (!downloadFlag) {
    downloadFlag = true;
    while (true) {
      const request = yield take(DOWNLOAD_PHOTO_REQUEST);
      const { application_id, successCb, version } = request.payload;
      try {
        let response = yield call(get, {
          url: settings.permittingCoreUrl(`permit/downloadPhotos/${application_id}/${version}`),
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: DOWNLOAD_PHOTO_SUCCESS,
            request_details: result,
            currentlySending: false,
            message: '',
          });
          successCb(result);
          downloadFlag = false;
        } else {
          yield put({
            type: GET_REQUEST_FAILURE,
            message: result.message || result.messages,
          });
          downloadFlag = false;
        }
      } catch (error) {
        yield put({ type: GET_REQUEST_FAILURE, message: error });
        downloadFlag = false;
      }
    }
  }
}

export function* getSolargrafCompaniesFlow() {
  yield takeLatest(GET_SOLARGRAF_COMPANIES_REQUEST, getSolargrafCompanies);
}

export function* getSolargrafCompanies(request) {
  const { filter, successCb = () => {} } = request.payload;
  const queryString = filter ? `filter=${filter}` : '';
  try {
    const response = yield call(get, {
      url: settings.permittingUrl(`admin/sgCompanies?${queryString}`),
      withBearer: true,
    });
    const { success, status, result } = response;
    if (success && status === 200) {
      yield put({
        type: GET_SOLARGRAF_COMPANIES_SUCCESS,
        companies: result,
      });
      successCb(result);
    } else {
      yield put({
        type: GET_SOLARGRAF_COMPANIES_FAILURE,
        message: result.message || result.messages,
      });
    }
  } catch (error) {
    yield put({ type: GET_SOLARGRAF_COMPANIES_FAILURE, message: error });
  }
}

let savingAdminCompanies = false;
export function* setAdminCompaniesFlow() {
  if (!savingAdminCompanies) {
    while (true) {
      savingAdminCompanies = true;
      const request = yield take(SET_ADMIN_COMPANIES_REQUEST);
      const { successCb = () => {} } = request.payload;
      try {
        const response = yield call(putMethod, {
          url: settings.permittingUrl(`admin/adminCompanies`),
          withBearer: true,
          body: request.payload,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: SET_ADMIN_COMPANIES_SUCCESS,
            selectedCompanies: result.selected_companies,
          });
          successCb();
          savingAdminCompanies = false;
        } else {
          yield put({
            type: SET_ADMIN_COMPANIES_FAILURE,
            message: result.message || result.messages,
          });
          savingAdminCompanies = false;
        }
      } catch (error) {
        yield put({ type: SET_ADMIN_COMPANIES_FAILURE, message: error });
        savingAdminCompanies = false;
      }
    }
  }
}

export function* getAdminCompaniesFlow() {
  while (true) {
    yield take(GET_ADMIN_COMPANIES_REQUEST);
    try {
      const response = yield call(get, {
        url: settings.permittingUrl(`admin/adminCompanies`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_ADMIN_COMPANIES_SUCCESS,
          selectedCompanies: result.selected_companies,
        });
      } else {
        yield put({
          type: GET_ADMIN_COMPANIES_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_ADMIN_COMPANIES_FAILURE, message: error });
    }
  }
}

export function* getSgAppAdminsFlow() {
  while (true) {
    const request = yield take(GET_SG_APP_ADMINS_REQUEST);
    const { successCb = () => {} } = request.payload;
    try {
      const response = yield call(get, {
        url: settings.permittingUrl('admin/sgAppAdmins'),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_SG_APP_ADMINS_SUCCESS,
          sgAppAdmins: result.sgAppAdmins,
        });
        successCb(result.sgAppAdmins);
      } else {
        yield put({
          type: GET_SG_APP_ADMINS_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_SG_APP_ADMINS_FAILURE, message: error });
    }
  }
}
