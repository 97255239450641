import { Box, CircularProgress, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { clone, get, isEmpty } from 'lodash';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import SelectDropdown from '../Array/SelectDropdown';
import { useStyles } from '../Array/styles';
import { Label, useLabelStyles } from '../../common/Label';
import { ACCEPTABLE_FILE_FORMAT, INVERTER_TYPES, OTHER_MATERIAL_TYPES, FEATURE_FLAG_TYPE, FEATURE_FLAGS } from '../../../containers/Permitting/constants';
import Close from '@material-ui/icons/Close';
import FileUploadDialog from '../FileUploadDialog';
import AutoCompleteDropdown from '../../common/TypableAutoComplete/AutoCompleteDropdown';
import { isFeatureEnabled } from '../../../containers/Permitting/helper';
import ImgIcon from '../images/img-icon.png';

const Product = ({
  index,
  product,
  productKey,
  errors,
  isInverter,
  isStorage,
  isOtherMaterials,
  handleChange,
  inverterOptions,
  otherMaterialTypes,
  isPermitSetAvailable,
  sgMaterials,
  deleteProduct,
  otherUserSelectionData,
  mostUsedValues,
  noOfProducts,
  fireUpload,
  isInputAutocompleteEnabled
}) => {
  const classes = useStyles();
  const labelClass = useLabelStyles();
  const {
    type = '',
    manufacturer = '',
    model = '',
    capacity = '',
    power_capacity = '',
    quantity = '',
    manufacturerOptions = '',
    modelOptions = '',
    otherMaterialManufacturers = '',
    spec_sheets = ''
  } = product;
  const [ open , setOpen ] = useState(false);

  useEffect(() => {
    if (!isEmpty(manufacturer) && !isEmpty(manufacturer.name)) {
      handleChange('modelOptions', manufacturer.name !== 'Other' ? getModels(manufacturer.name) : [], index);
    }
  }, [ manufacturer ]);

  useEffect(() => {
    if (!isOtherMaterials) {
      const items = isInverter ? sgMaterials.inverter: sgMaterials.batteryBackup;
      const result = {};
      items && items.forEach(item => {
        if (
          item.Manufacturer && item.Manufacturer.name &&
          (item.status === 'approved' || item.status === null) &&
          (isEmpty(type) || item.type === INVERTER_TYPES.find(inverterType => inverterType.name === type)?.key)
        ) {
          result[item.Manufacturer.name] = item.Manufacturer;
        }
      });
      const manufacturers = [];
      for (const key in result) {
        manufacturers.push(result[key]);
      }
      handleChange('manufacturerOptions', manufacturers, index);
    }
  }, [ type, sgMaterials ]);

  useEffect(() => {
    if (!isEmpty(type) && isOtherMaterials) {
      const otherType = OTHER_MATERIAL_TYPES.find(material => material.name === type);
      if (otherType) {
        const materials = sgMaterials[otherType.id];
        const result = {};
        materials && materials.forEach(item => {
          if (item.Manufacturer && item.Manufacturer.name && (item.status === 'approved' || item.status === null)) {
            result[item.Manufacturer.name] = item.Manufacturer;
          }
        });
        const manufacturers = [];
        for (const key in result) {
          manufacturers.push(result[key]);
        }
        handleChange('otherMaterialManufacturers', manufacturers, index);
      }
    }
  }, [ type ]);

  const getErrorText = key => {
    const errorText = get(errors, `${productKey}.${index}.${key}.error`)
      ? get(errors, `${productKey}.${index}.${key}.errorMessage`)
      : '';
    return errorText;
  };

  const getModels = (manufacturer) => {
    const otherType = OTHER_MATERIAL_TYPES.find(material => material.name === type);
    const models = [];
    const materials = isInverter ? sgMaterials.inverter : isStorage ?
      sgMaterials.batteryBackup : otherType ? sgMaterials[otherType.id] : [];
    materials && materials.forEach(material => {
      if (material.Manufacturer && material.Manufacturer.name === manufacturer) {
        models.push(material);
      }
    });
    return models;
  };

  const handleDropdownChange = (val, key, isFromOther) => {
    let value = '';
    if (key === 'manufacturer') {
      if(isInputAutocompleteEnabled) {
        value = !isFromOther ? getManufacturer(val) : { id: 'other', name: val };
        value = value ? value : { id: 'other', name: val };
        handleChange('model', value && !value.id ? { id: 'other', name: value } : '', index);
        if (isFromOther) {
          handleChange('capacity', '', index);
          handleChange('power_capacity', '', index);
        }
      }
      else {
        value = !isFromOther ? getManufacturer(val) : { id: 'other', name: 'Other-' + val };
        value = value ? value : { id: 'other', name: val };
        handleChange('model', value && value.id === 'other' ? { id: 'other', name: 'Other' } : '', index);
        if (value.id === 'other') {
          handleChange('capacity', '', index);
          handleChange('power_capacity', '', index);
        }
      }
    } else if (key === 'model') {
      if(isInputAutocompleteEnabled) {
        value = !isFromOther ? getModel(val) : { id: 'other', name: val };
      }
      else {
        value = !isFromOther ? getModel(val) : { id: 'other', name: 'Other-' + val };
      }
      value = value ? value : { id: 'other', name: val };
      handleChange('capacity', value.capacity ? value.capacity : '', index);
      handleChange('power_capacity', value.power_rating ? value.power_rating : '', index);
      value = { name: value.name, id: value.id };
    } else if (key === 'power_capacity') {
      // user will fill the value in kWac, but we are storing in Wac to DB
      value = val ? val * 1000 : val;
    } else {
      value = val;
      if (key === 'type' && !isStorage) {
        handleChange('manufacturer', '', index);
        handleChange('modelOptions', [], index);
      }
    }
    handleChange(key, value, index);
    if (isInputAutocompleteEnabled && (key === 'model' || key === 'manufacturer')) {
      handleChange(key, value, index);
      if (isFromOther) {
        handleChange('other_' + key, 'Other-' + val, index);
      }
      else {
        handleChange('other_' + key, '', index);
      }
    }
    else {
      let otherValue = `Other-${val}`;
      if(typeof value === 'object') {
        otherValue = {id: 'other', name: `Other-${val}`};
      }
      handleChange(key, isFromOther ? otherValue : value, index);
    }
  }

  const handleTextFieldChange = (key, val) => {
    handleChange(key, val, index);
  }

  const modifiedManufacturerOptions = !isInputAutocompleteEnabled ? 
    [ ...(isOtherMaterials ? otherMaterialManufacturers : manufacturerOptions), { id: 'other', name: 'Other' } ]:
    [ ...(isOtherMaterials ? otherMaterialManufacturers : manufacturerOptions)];

  const modifiedModelOptions = isInputAutocompleteEnabled? 
    [ ...modelOptions] :
    [ ...modelOptions, { id: 'other', name: 'Other' } ] ;
  
  const getManufacturer = val => modifiedManufacturerOptions && modifiedManufacturerOptions.find(option => option.name === val);

  const getModel = val => modifiedModelOptions && modifiedModelOptions.find(option => option.name === val);

  const getKey = () => isInverter ? 'inverter' : isStorage ? 'storage' : 'other_materials';

  const getOtherList = (list) => list && list.map(item => item.name);

  const deleteSpecSheet = ind => {
    const specSheets = clone(spec_sheets) || [];
    specSheets.splice(ind, 1);
    handleChange('spec_sheets', specSheets, index);
  };

  const productText = () => isInverter ? 'Inverters' : isStorage ? 'Storage' : 'Other Materials';

  const uploadSpecSheet = (file, key) => {
    fireUpload(file, index, key, productText() + ' Spec Sheets');
  };

  return (
    <div className={classes.productWrapper}>
      <div className={classes.flexDisplay}>
        <Grid xs={11} className={classes.specSheetWrapper}>
          {isInverter && (
            <SelectDropdown
              id="type"
              className={clsx(classes.inputField, labelClass.customLabel)}
              value={type || ''}
              optionList={inverterOptions}
              labelText="Inverter Type"
              handleChange={handleDropdownChange}
              error={!isEmpty(getErrorText('type'))}
              helperText={getErrorText('type')}
              classes={classes}
              labelClass={labelClass}
              isPermitSetAvailable={isPermitSetAvailable}
              isRequired={true}
            />
          )}
          {isOtherMaterials && (
            <SelectDropdown
              id="type"
              className={clsx(classes.inputField, labelClass.customLabel)}
              value={type || ''}
              optionList={otherMaterialTypes}
              labelText="Type"
              handleChange={handleDropdownChange}
              error={!isEmpty(getErrorText('type'))}
              helperText={getErrorText('type')}
              classes={classes}
              labelClass={labelClass}
              isPermitSetAvailable={isPermitSetAvailable}
              isRequired={true}
            />
          )}
          {isInputAutocompleteEnabled? (
            <AutoCompleteDropdown
            id="manufacturer"
            className={clsx(classes.inputField, labelClass.customLabel)}
            value={manufacturer || ''}
            optionList={modifiedManufacturerOptions}
            otherOptionList={getOtherList(otherUserSelectionData[getKey() + '_manufacturer'])}
            mostUsedList={getOtherList(mostUsedValues[getKey() + '_manufacturer'])}
            labelText="Manufacturer"
            handleChange={handleDropdownChange}
            error={!isEmpty(getErrorText('manufacturer'))}
            helperText={getErrorText('manufacturer')}
            classes={classes}
            labelClass={labelClass}
            isPermitSetAvailable={isPermitSetAvailable}
            isRequired
            showRecent={true}
            disableDropdown={isStorage && noOfProducts > 1}
            />
            ):
            (
            <SelectDropdown
            id="manufacturer"
            className={clsx(classes.inputField, labelClass.customLabel)}
            value={manufacturer || ''}
            optionList={modifiedManufacturerOptions}
            otherOptionList={getOtherList(otherUserSelectionData[getKey() + '_manufacturer'])}
            mostUsedList={getOtherList(mostUsedValues[getKey() + '_manufacturer'])}
            labelText="Manufacturer"
            handleChange={handleDropdownChange}
            error={!isEmpty(getErrorText('manufacturer'))}
            helperText={getErrorText('manufacturer')}
            classes={classes}
            labelClass={labelClass}
            isPermitSetAvailable={isPermitSetAvailable}
            isRequired={true}
            showRecent={true}
            disableDropdown={isStorage && noOfProducts > 1}
            />
            )
          }
          {isInputAutocompleteEnabled? (
            <AutoCompleteDropdown
            id="model"
            className={clsx(classes.inputField, labelClass.customLabel)}
            value={model || ''}
            optionList={modifiedModelOptions}
            otherOptionList={getOtherList(otherUserSelectionData[getKey() + '_model'])}
            mostUsedList={getOtherList(mostUsedValues[getKey() + '_model'])}
            labelText="Model"
            handleChange={handleDropdownChange}
            error={!isEmpty(getErrorText('model'))}
            helperText={getErrorText('model')}
            classes={classes}
            labelClass={labelClass}
            isPermitSetAvailable={isPermitSetAvailable}
            isRequired
            capacity={capacity}
            power_capacity={power_capacity}
            capacityErrorText={getErrorText('capacity')}
            powerCapacityErrorText={getErrorText('power_capacity')}
            isStorage={isStorage}
            showRecent={true}
          />
          ):(
            <SelectDropdown
            id="model"
            className={clsx(classes.inputField, labelClass.customLabel)}
            value={model || ''}
            optionList={modifiedModelOptions}
            otherOptionList={getOtherList(otherUserSelectionData[getKey() + '_model'])}
            mostUsedList={getOtherList(mostUsedValues[getKey() + '_model'])}
            labelText="Model"
            handleChange={handleDropdownChange}
            error={!isEmpty(getErrorText('model'))}
            helperText={getErrorText('model')}
            classes={classes}
            labelClass={labelClass}
            isPermitSetAvailable={isPermitSetAvailable}
            isRequired={true}
            capacity={capacity}
            power_capacity={power_capacity}
            capacityErrorText={getErrorText('capacity')}
            powerCapacityErrorText={getErrorText('power_capacity')}
            isStorage={isStorage}
            showRecent={true}
          />
          )}
          
          <TextField
            id="quantity"
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            className={clsx(classes.inputField, labelClass.customLabel)}
            label={<Label className={classes.labelColor} text="Quantity" isRequired />}
            value={quantity || ''}
            onChange={e => handleTextFieldChange('quantity', e.target.value)}
            error={!isEmpty(getErrorText('quantity'))}
            helperText={getErrorText('quantity')}
          />
        </Grid>
        <Grid xs={1}>
          {(index !== 0 || isOtherMaterials) && (
            <IconButton className={classes.removeButton} onClick={() => deleteProduct(index)}>
              <CloseIcon fontSize="large" />
            </IconButton>
          )}
        </Grid>
      </div>
      <Fragment>
        <Box>
          {spec_sheets &&
            spec_sheets.map((img, i) => {
              return (
                <Box className={classes.imgHolder} key={productKey + `spec_sheets-${i}`}>
                  {img.loading && <CircularProgress />}
                  {!img.loading && (
                    <Box className={classes.imgContainer}>
                      <Close onClick={() => deleteSpecSheet(i)} className={classes.close} />
                      <img alt={img.file_name} src={ImgIcon} />
                    </Box>
                  )}
                  <Typography variant="caption" display="block">
                    {img.original_file_name}
                  </Typography>
                </Box>
              );
            })}
        </Box>
        {!(spec_sheets && spec_sheets.length) && (
          <Typography component="div" className={classes.addNewArray} onClick={() => setOpen(true)}>
            + Add Spec Sheet
          </Typography>
        )}
        <FileUploadDialog
          type={'Spec Sheet'}
          imageKey={'spec_sheets'}
          fireUpload={uploadSpecSheet}
          open={open}
          setOpen={setOpen}
          acceptableFileFormat={ACCEPTABLE_FILE_FORMAT}
        />
      </Fragment>
    </div>
  );
};

export default Product;
