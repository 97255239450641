import React from 'react';

const HelpSectionDownArrow = () => {
  return (
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.73389 7.97893L1.03387 1.27893C0.893668 1.13818 0.814941 0.947598 0.814941 0.748928C0.814941 0.550258 0.893668 0.35968 1.03387 0.218925C1.17463 0.0787191 1.36523 0 1.5639 0C1.76257 0 1.95312 0.0787191 2.09387 0.218925L8.00287 6.12793L13.9119 0.218925C14.0526 0.0787191 14.2432 0 14.4419 0C14.6406 0 14.8311 0.0787191 14.9719 0.218925C15.1121 0.35968 15.1908 0.550258 15.1908 0.748928C15.1908 0.947598 15.1121 1.13818 14.9719 1.27893L8.27185 7.97893C8.2369 8.01491 8.19513 8.04352 8.14893 8.06306C8.10272 8.08259 8.05303 8.09265 8.00287 8.09265C7.9527 8.09265 7.90308 8.08259 7.85687 8.06306C7.81067 8.04352 7.76884 8.01491 7.73389 7.97893Z" fill="#4A4A4A" />
    </svg>
  );
};

export default HelpSectionDownArrow;
