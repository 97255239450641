import React from 'react';

const PNGIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45.888" height="54.926" viewBox="0 0 45.888 54.926">
      <defs>
        <filter id="a" x="0" y="0" width="45.888" height="54.926" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="b" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-35.272)">
        <path
          fill="#2f9dd9"
          d="M110.486,328.9H109.4v1.772h1.086a.906.906,0,0,0,.7-.251.888.888,0,0,0,.236-.635.9.9,0,0,0-.236-.635A.917.917,0,0,0,110.486,328.9Z"
          transform="translate(-63.997 -299.053)"
        />
        <path
          fill="#2f9dd9"
          d="M64.324,285.9H40.3v9.889H60.706Zm-16.536,5.192a1.888,1.888,0,0,1-1.3.428H45.4v1.905h-.842V288.4h1.928a1.84,1.84,0,0,1,1.3.428,1.451,1.451,0,0,1,.473,1.13A1.43,1.43,0,0,1,47.789,291.092Zm5.406,2.334h-.842l-2.223-3.619-.022.007v3.611h-.842V288.4h.842l2.223,3.619.022-.007V288.4h.842Zm4.992-.679a1.886,1.886,0,0,1-.657.524,2.578,2.578,0,0,1-1.174.229,1.981,1.981,0,0,1-1.462-.576,2.009,2.009,0,0,1-.576-1.484V290.39a2.053,2.053,0,0,1,.554-1.484,1.9,1.9,0,0,1,1.425-.576,1.963,1.963,0,0,1,1.366.443,1.426,1.426,0,0,1,.473,1.152l-.007.022h-.79a.929.929,0,0,0-.266-.687,1.023,1.023,0,0,0-.761-.258,1.044,1.044,0,0,0-.842.391,1.5,1.5,0,0,0-.318.99v1.056a1.478,1.478,0,0,0,.332,1,1.092,1.092,0,0,0,.871.391,1.845,1.845,0,0,0,.635-.089.914.914,0,0,0,.354-.222v-1.041H56.289v-.628H58.18v1.9Z"
          transform="translate(0 -259.229)"
        />
        <g filter="url(#a)" transform="matrix(1, 0, 0, 1, 35.27, 0)">
          <path
            fill="#cbcbcb"
            d="M113.213,6H95.082a.987.987,0,0,0-.982.982V25.948h1.964V7.964h17.149v6.809h6.809V40.962H96.064V37.284H94.1v4.66a.987.987,0,0,0,.982.982H121a.987.987,0,0,0,.982-.982V14.774Z"
            transform="translate(-85.1)"
          />
        </g>
        <rect fill="#2f9dd9" width="18.714" height="1.448" transform="translate(48.86 18.215)" />
        <rect fill="#2f9dd9" width="15.753" height="1.448" transform="translate(48.86 21.029)" />
      </g>
    </svg>
  );
};
export default PNGIcon;
