import React, { forwardRef, Fragment } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Field, formValueSelector, reduxForm, change } from 'redux-form';
import {
  Select as SelectField,
  FormHelperText,
  TextField,
  Grid,
  InputLabel,
  Box,
  IconButton,
  MenuItem,
  FormControl,
  Tooltip,
} from '@material-ui/core';
import HelpIcon from '../../../Permitting/images/helpIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, makeStyles, withTheme } from '@material-ui/core/styles';
import { labelStyles, Label } from '../../../common/Label';
import { isEmpty, get, isEqual } from '../../../../utils/lodash';
import { PROJECT_TYPES, AHJ_RECOMMENDATIONS_DISCLAIMER } from '../../../../containers/Permitting/constants';
import { isPermitSetAvailable } from '../../../../utils/helper';
import { fetchPlaceId, fetchCounty } from '../../../../containers/Permitting/helper';
import { getSource } from '../../../../containers/Admin/RequestDetails/helper';
import { SOURCE } from '../../../../utils/constants';

import {
  createProjectDetails,
  updateAddress,
  setError,
  getUtilityProviders,
  getTemplates,
  setSelectedTemplate,
  deleteTemplate,
  updateMaterialDetails,
  showHideLoader,
  setApplyTemplate,
  setEnableSubmit,
  getAHJRecommendations,
  getAhjIdByName,
  getUtilityIdByName,
  updateCounty,
} from '../../../../containers/Permitting/action';
import { PROJECT_DETAILS } from '../constants';
import { DisplayGrid } from '../../../common/DisplayGrid';

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#111111',
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: theme.spacing(1),
    minWidth: theme.spacing(15),
    maxWidth: 350,
    pointerEvents: 'auto',
    fontWeight: 'normal',
  },
  arrow: {
    color: 'white',
    fontSize: 14,
  },
}))(Tooltip);

const styles = (theme) => ({
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
    },
  },
  inputField: {
    width: '100%',
    minWidth: theme.spacing(30),
  },
  inputErrorWrapper: {
    width: '45%',
    margin: theme.spacing(3, 2, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      minWidth: 'auto',
    },
  },
  withoutFormControl: {
    minWidth: '45%',
    width: '100%',
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
    textTransform: 'initial',
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    padding: 0,
  },
  addNew: {
    fontSize: 12,
    color: '#008EEF',
    display: 'inline-block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  chooseTemplateButton: {
    fontSize: 15,
    borderRadius: 3,
    textTransform: 'none',
    padding: theme.spacing(0, 3),
    border: '1px solid #F37320',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    height: theme.spacing(4),
    width: theme.spacing(18),
    color: '#F37320',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  changeTemplateButton: {
    fontSize: 15,
    borderRadius: 3,
    textTransform: 'none',
    padding: theme.spacing(0, 3),
    border: '1px solid #F37320',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    height: theme.spacing(4),
    width: theme.spacing(14.5),
    color: '#F37320',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  templateFontSize: {
    fontSize: '14px',
    minWidth: theme.spacing(21.875),
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2, 2),
    fontSize: 15,
  },
  button: {
    color: theme.palette.primary.white,
    marginLeft: theme.spacing(1.5),
    position: 'relative',
    top: theme.spacing(0.3),
  },
  inputFieldStorage: {
    width: '50%',
    paddingLeft: theme.spacing(1.5),
    '& .MuiInputLabel-root': {
      paddingLeft: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  templateWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    opacity: 0.8,
    cursor: 'pointer',
    fontSize: theme.spacing(2),
    position: 'relative',
    top: theme.spacing(0.5),
  },
  showPopper: {
    display: 'block',
  },
  hidePopper: {
    display: 'none',
  },
  iconButton: {
    padding: theme.spacing(0, 0.5),
  },
  dropdownHeader: {
    margin: theme.spacing(1.5, 0, 0, 2),
    fontSize: theme.spacing(1.75),
    color: '#0666F5',
  },
  disclaimerText: {
    color: theme.palette.secondary.A700,
  },
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  fontBold: {
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    fontSize: '14px',
  },
  dataHeader: {
    color: '#246AB0',
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px',
    height: '40px',
    cursor: 'unset',
  },
  panelDetails: {
    flexDirection: 'column',
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0,
  },
  headingInfo: {
    color: 'darkgray',
  },
  dataSubHeader: {
    fontSize: '12px',
    color: 'grey',
  },
  updatedLabelWrapper: {
    pointerEvents: 'none',
    display: 'flex',
    maxWidth: 'initial',
  },
});

let timeout = null;
let zipCode = '';
let updatedValues = null;
let changedField = null;
class ProjectDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    if (window.google) {
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.geocoder = new window.google.maps.Geocoder();
    }
    this.state = {
      options: [],
      materialDetailsState: null,
      showTemplateDialog: false,
      open: false,
      hidePopper: false,
      showTooltip: false,
      showAhjTooltip: false,
      showLicenseNumberTooltip: false,
      showDisclaimerText: false,
      ahjRecommendations: [],
      customAHJName: false,
      ahjId: '',
      utilityProviderId: '',
    };
  }
  newApplication = false;
  firstProjectDetailsUpdate = true;

  componentDidMount() {
    this.getAhjIdByName();
    this.getUtilityIdByName();
    this.getAhjRecommendations();
  }

  componentDidUpdate(prvProp, preState) {
    if (isEmpty(this.state.materialDetailsState) && !isEmpty(this.props.materialDetails)) {
      this.setState({ materialDetailsState: { ...this.props.materialDetails, errors: {}, showTemplateDialog: false } });
    }
    if (this.props.projectDetails.ahj !== prvProp.projectDetails.ahj) {
      this.getAhjIdByName();
    }
    if (this.props.projectDetails.utility_provider !== prvProp.projectDetails.utility_provider) {
      this.getUtilityIdByName();
    }
    if (this.firstProjectDetailsUpdate && this.props.projectDetails !== prvProp.projectDetails) {
      this.firstProjectDetailsUpdate = false;
      const { county } = this.props.projectDetails;
      if (!county) {
        fetchPlaceId(
          this.autocompleteService,
          { input: this.props.projectDetails.address },
          (placeId) => {
            fetchCounty(
              this.geocoder,
              placeId,
              (res) => {
                this.props.updateCounty(res);
                this.getAhjRecommendations();
              },
              this.getAhjRecommendations
            );
          },
          this.getAhjRecommendations
        );
      } else {
        this.getAhjRecommendations();
      }
    }

    if (prvProp !== this.props && isEmpty(this.props.uProviders)) {
      this.props.getUtilityProviders({ zip_code: this.props.initialValues.zipcode });
    }
  }

  getAhjRecommendations = () => {
    const { city, county, state, ahj, ahj_id } = this.props.projectDetails;
    this.props.getAHJRecommendations({
      city,
      county,
      state,
      successCbk: (ahjRecommendations) => {
        this.setState({ ahjRecommendations: ahjRecommendations }, () => {
          ahj && this.toggleDisclaimer(ahj);
        });
        this.props.updateAhjId(!ahj_id ? null : ahj_id);
        this.props.updateCountyField(!county ? null : county);
      },
    });
  };

  getAhjIdByName = () => {
    const { ahj } = this.props.projectDetails;
    this.props.getAhjIdByName({
      ahj,
      successCbk: (response) => {
        this.setState({ ahjId: response.length > 0 ? response[0].id : '' });
      },
    });
  };
  getUtilityIdByName = () => {
    let { utility_provider: utilityProviderName, state } = this.props.projectDetails;
    if (utilityProviderName != null) {
      const source = getSource(this.props.stateApplicationDetails);
      // As of now we dont have any Utility name with residential or commercial text. So spliting the utility name based on Residential or commercial text to take the exact name.
      if (source === SOURCE.SOLARGRAF) {
        if (utilityProviderName.indexOf('- Residential') > -1) {
          utilityProviderName = utilityProviderName.substr(0, utilityProviderName.indexOf('- Residential')).trim();
        } else if (utilityProviderName.indexOf('- Commercial') > -1) {
          utilityProviderName = utilityProviderName.substr(0, utilityProviderName.indexOf('- Commercial')).trim();
        }
      }
      this.props.getUtilityIdByName({
        utilityProviderName,
        state,
        successCbk: (response) => {
          this.setState({ utilityProviderId: response.length > 0 ? response[0].id : '' });
        },
      });
    }
  };

  toggleDisclaimer = (value) => {
    const selectedAHJ = this.state.ahjRecommendations.find((ahj) => ahj.name === value);
    this.props.updateAhjId(!selectedAHJ ? null : selectedAHJ.id);
    this.setState({
      showDisclaimerText: this.showAHJDisclaimer(selectedAHJ),
      customAHJName: !selectedAHJ,
    });
  };

  showAHJDisclaimer = (ahj) => {
    if (!ahj) return false;
    const { reference_status } = ahj;
    switch (reference_status) {
      case 'TODO':
      case 'REFERENCE_NOT_FOUND':
      case 'AHJ_DATA_NOT_AVAILABLE':
        return true;
      default:
        return false;
    }
  };

  renderTypableDropDown = ({
    withoutFormControl = false,
    classes,
    labelClass,
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => {
    const handleChange = (value) => {
      this.toggleDisclaimer(value);
      this.ahjName = value;
      changedField = label.props.keyValue;
      input.onChange(value);
    };
    const filterOptions = (options) => {
      return options;
    };
    const CustomList = forwardRef((params, ref) => {
      return (
        <div ref={ref}>
          <p className={classes.dropdownHeader}>Address based recommendations</p>
          <ul {...params} />
        </div>
      );
    });
    return (
      <Autocomplete
        id="ahj"
        freeSolo
        className={!withoutFormControl ? clsx(classes.inputField, labelClass.customLabel) : classes.withoutFormControl}
        inputValue={input.value ? input.value : ''}
        defaultValue={input.value ? input.value : ''}
        onInputChange={(event, newValue) => handleChange(newValue)}
        options={this.state.ahjRecommendations.map((ahj) => ahj.name)}
        ListboxComponent={CustomList}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error && touched}
            label={label}
            helperText={this.state.showDisclaimerText && AHJ_RECOMMENDATIONS_DISCLAIMER}
            FormHelperTextProps={{ className: classes.disclaimerText }}
            {...input}
            onChange={(e) => handleChange(e.target.value)}
            {...custom}
          />
        )}
      />
    );
  };

  renderSelectField = ({
    withoutFormControl = false,
    classes,
    labelClass,
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  }) => {
    const { key, projectDetails, paymentStatus } = custom;
    const handleChange = (value) => {
      changedField = label.props.keyValue;
      input.onChange(value);
    };

    return (
      <FormControl
        error={error && touched}
        className={!withoutFormControl ? clsx(classes.inputField, labelClass.customLabel) : classes.withoutFormControl}
      >
        <InputLabel id={`label_${input.name}`}>{label}</InputLabel>
        <SelectField
          id={`select_field_${input.name}`}
          value="00ff00"
          label={label}
          {...input}
          onChange={(event) => handleChange(event.target.value)}
          children={children}
          {...custom}
          disabled={key === 'project_type' && (paymentStatus || !isEmpty(projectDetails.project_type))}
        />
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  };

  render() {
    const labelClass = makeStyles(labelStyles);
    const {
      classes,
      siteDetails,
      uProviders = [],
      errors,
      selectedTemplate,
      mostUsedValues,
      projectDetails,
      isEdit,
      stateApplicationDetails,
      handleUpdated,
      applicationDetail,
      originalApplicationDetails,
    } = this.props;

    return (
      <Fragment>
        <Grid container direction="row" spacing={2}>
          {PROJECT_DETAILS.map((Item, index) => {
            return (
              <DisplayGrid
                appDetails={applicationDetail}
                originalApplicationDetails={originalApplicationDetails}
                handleUpdated={handleUpdated}
                item={Item}
                ahjId={this.state.ahjId}
                utilityProviderId={this.state.utilityProviderId}
                stateApplicationDetails={stateApplicationDetails}
              />
            );
          })}
        </Grid>
        {isEdit ? (
          <form>
            <Grid className={classes.inputWrapper}>
              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="utility_provider"
                  component={this.renderSelectField}
                  label={
                    <Label
                      className={classes.labelColor}
                      keyValue="utility_provider"
                      text="Utility Provider"
                      isRequired
                    />
                  }
                  classes={classes}
                  labelClass={labelClass}
                >
                  {uProviders.map((utility, index) => {
                    return (
                      <MenuItem key={index} value={utility.name}>
                        {utility.name}
                      </MenuItem>
                    );
                  })}
                </Field>
                {get(errors, 'utility_provider', '') && (
                  <FormHelperText error>{get(errors, 'utility_provider')}</FormHelperText>
                )}
              </Box>
              <Field name="ahj_id" component="input" type="hidden" />

              <Box className={classes.inputErrorWrapper}>
                <Field
                  name="ahj"
                  component={this.renderTypableDropDown}
                  label={
                    <Label className={classes.labelColor} keyValue="ahj" text="AHJ" isRequired>
                      <LightTooltip
                        title="AHJ represents ‘Authority Having Jurisdiction’, typically represent the local municipality where the project is located"
                        open={this.state.showAhjTooltip}
                        placement="right"
                      >
                        <IconButton
                          onClick={() => this.setState({ showAhjTooltip: true })}
                          onMouseEnter={() => this.setState({ showAhjTooltip: true })}
                          onMouseLeave={() => this.setState({ showAhjTooltip: false })}
                          aria-label="info"
                          className={classes.iconButton}
                        >
                          <HelpIcon />
                        </IconButton>
                      </LightTooltip>
                    </Label>
                  }
                  labelClass={labelClass}
                  classes={classes}
                  mostUsedList={mostUsedValues['ahj']}
                />
                {get(errors, 'ahj', '') && <FormHelperText error>{get(errors, 'ahj')}</FormHelperText>}
              </Box>
            </Grid>
          </form>
        ) : (
          <Grid container direction="row" spacing={2} />
        )}
        <br />
        <br />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector('ProjectDetailsForm');
  const projectType = selector(state, 'project_type') || '';
  const ahj = selector(state, 'ahj') || '';
  return {
    ahj,
    projectType,
    initialValues: state.permitReducer.projectDetails,
    siteDetails: state.permitReducer.siteDetails,
    permitId: state.permitReducer.permitId,
    uProviders: state.permitReducer.uProviders,
    isSaveandClose: state.permitReducer.isSaveandClose,
    errors: state.permitReducer.errors,
    paymentStatus: state.permitReducer.paymentStatus,
    templates: state.permitReducer.templates,
    selectedTemplate: state.permitReducer.selectedTemplate,
    viewTemplate: state.permitReducer.viewTemplate,
    materialDetails: state.permitReducer.materialDetails,
    mostUsedValues: state.permitReducer.mostUsedValues,
    applyTemplate: state.permitReducer.applyTemplate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateMaterialDetails: (payload) => dispatch(updateMaterialDetails(payload)),
  createProjectDetails: (payload) => dispatch(createProjectDetails(payload)),
  updateAddress: (payload) => dispatch(updateAddress(payload)),
  getUtilityProviders: (payload) => dispatch(getUtilityProviders(payload)),
  setError: (payload) => dispatch(setError(payload)),
  getTemplates: (payload) => dispatch(getTemplates(payload)),
  setSelectedTemplate: (payload) => dispatch(setSelectedTemplate(payload)),
  deleteTemplate: (payload) => dispatch(deleteTemplate(payload)),
  showHideLoader: (payload) => dispatch(showHideLoader(payload)),
  setApplyTemplate: (payload) => dispatch(setApplyTemplate(payload)),
  setEnableSubmit: (enable) => dispatch(setEnableSubmit(enable)),
  getAHJRecommendations: (payload) => dispatch(getAHJRecommendations(payload)),
  getAhjIdByName: (payload) => dispatch(getAhjIdByName(payload)),
  getUtilityIdByName: (payload) => dispatch(getUtilityIdByName(payload)),
  updateAhjId: (payload) => dispatch(change('ProjectDetailsForm', 'ahj_id', payload)),
  updateCountyField: (payload) => dispatch(change('ProjectDetailsForm', 'county', payload)),
  updateCounty: (payload) => dispatch(updateCounty(payload)),
});

const validate = (data) => {
  const { values, props, prevErrors = {}, changedField = '', isSubmit = false } = data;
  let errors = prevErrors;
  const requiredFields = [
    'system_name',
    'customer_name',
    'utility_provider',
    'ahj',
    'address',
    'zipcode',
    'project_type',
  ];

  if (isEmpty(changedField) && isSubmit) {
    requiredFields.forEach((field) => {
      let value = values[field];
      if (!value || value.trim() === '') {
        value = '';
      }
      if (!value) {
        errors = { ...errors, [field]: 'Required' };
      } else if (field === 'utility_provider' && !props.uProviders.find((uProvider) => uProvider.name === value)) {
        errors = { ...errors, [field]: 'Required' };
      } else {
        errors = { ...errors, [field]: '' };
      }
    });
  } else if (changedField) {
    if (requiredFields.includes(changedField)) {
      let value = values[changedField];
      if (!value || value.trim() === '') {
        value = '';
      }
      if (!value) {
        errors = { ...errors, [changedField]: 'Required' };
      }
      if (changedField === 'zipcode' && value.length > 10) {
        errors = { ...errors, zipcode: 'Max length allowed is 10' };
        values[changedField] = value.substr(0, 10);
      } else {
        errors = { ...errors, [changedField]: '' };
      }
    }
  }
  return errors;
};

const onChange = (values, dispatch, props) => {
  if (updatedValues === null) {
    updatedValues = values;
  }
  if (!isEqual(updatedValues, values)) {
    props.setEnableSubmit(true);
    updatedValues = values;
    const data = {
      values,
      props,
      prevErrors: props.errors,
      changedField,
    };
    const errors = validate(data);
    props.setError(errors);
    if (zipCode !== values.zipcode) {
      zipCode = values.zipcode;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        props.getUtilityProviders({ zip_code: values.zipcode });
      }, 1000);
    }
  }
};

const fillMaterialDetails = (props, permitId) => {
  const isMaterialDetailsNull = get(props.applicationDetail, 'material_details') === null;
  if (!isEmpty(props.selectedTemplate) && props.applyTemplate && !isPermitSetAvailable(props.applicationDetail)) {
    const {
      roof_type,
      panel_manufacturer,
      panel_model,
      interconnection_strategy,
      interconnection_location,
      racking_manufacturer,
      attachment_manufacturer,
      racking_model,
      attachment_model,
      attachment_type,
      max_spacing_between_attachments,
    } = props.selectedTemplate;
    let materialDetailsFilledWithTemplate;
    if (isMaterialDetailsNull) {
      materialDetailsFilledWithTemplate = {
        arrays: [
          {
            quantity: '',
            pitch_deg: '',
            azimuth: '',
            max_rafter_span: 0,
            structural_number: '',
            structural_type: '',
            structural_at: '',
            panel_manufacturer: panel_manufacturer,
            panel_model: panel_model,
            installation_type: '',
            roof_type: roof_type,
            trenching_route_photo: [],
          },
        ],
        existing_arrays: [
          {
            quantity: '',
            pitch_deg: '',
            azimuth: '',
            max_rafter_span: 0,
            structural_number: '',
            structural_type: '',
            structural_at: '',
            panel_manufacturer: panel_manufacturer,
            panel_model: panel_model,
            installation_type: '',
            roof_type: roof_type,
            trenching_route_photo: [],
          },
        ],
        inverters: [],
        existing_inverters: [],
        storage_list: [],
        spec_sheets: [],
        existing_spec_sheets: [],
        trenching_route_photo: [],
        interconnection_properties: {
          interconnection_strategy: interconnection_strategy,
          interconnection_location: interconnection_location,
        },
        electrical_properties: {
          service_type: '',
          utility_meter_location: '',
          feeder_type: '',
          main_bus_rating: '',
          main_breaker_rating: '',
          main_breaker_location: '',
          existing_grounding: '',
          subpanel_breaker_rating: '',
          subpanel_bus_rating: '',
          msp_upgrade: false,
          solar_ready: true,
          spare_breaker_count: null,
          bus_rating: '',
          main_breaker: '',
          msp_location: '',
          location_image_file_name: '',
          coordinates: { lat: null, lng: null },
        },
        racking_attachment_properties: {
          racking_manufacturer: racking_manufacturer,
          racking_model: racking_model,
          attachment_manufacturer: attachment_manufacturer,
          attachment_model: attachment_model,
          attachment_type: attachment_type,
          attachment_spacing: max_spacing_between_attachments,
          wind_load: '',
          snow_load: '',
          notes: '',
        },
        documents: [],
        document_link: '',
        photos: [],
        photo_link: '',
        additional_info: '',
        equipment_location: [],
      };
    } else {
      const arrays = get(props.applicationDetail, 'material_details.arrays');
      const existingArrays = get(props.applicationDetail, 'material_details.existing_arrays');
      arrays &&
        arrays.forEach((array) => {
          array.panel_manufacturer = panel_manufacturer;
          array.panel_model = panel_model;
          array.roof_type = roof_type;
        });
      existingArrays &&
        existingArrays.forEach((array) => {
          array.panel_manufacturer = panel_manufacturer;
          array.panel_model = panel_model;
          array.roof_type = roof_type;
        });
      materialDetailsFilledWithTemplate = {
        ...get(props.applicationDetail, 'material_details'),
      };
      materialDetailsFilledWithTemplate.interconnection_properties.interconnection_strategy = interconnection_strategy;
      materialDetailsFilledWithTemplate.interconnection_properties.interconnection_location = interconnection_location;
      materialDetailsFilledWithTemplate.racking_attachment_properties = {
        ...materialDetailsFilledWithTemplate.racking_attachment_properties,
        racking_manufacturer: racking_manufacturer,
        racking_model: racking_model,
        attachment_manufacturer: attachment_manufacturer,
        attachment_model: attachment_model,
        attachment_type: attachment_type,
        attachment_spacing: max_spacing_between_attachments,
      };
      materialDetailsFilledWithTemplate.arrays = arrays;
      materialDetailsFilledWithTemplate.existing_arrays = existingArrays;
    }
    props.updateMaterialDetails({
      ...materialDetailsFilledWithTemplate,
      permit_id: permitId,
      template_id: get(props.selectedTemplate, 'id', ''),
      isProceed: false,
      stepCompleted: 1,
    });
  }

  props.setApplyTemplate(false);
};

const submitProjectDetails = (values, dispatch, props) => {
  values.template_id = get(props, 'selectedTemplate.id', '');
  values.apply_template = false;
  if (values.project_type === PROJECT_TYPES.COMMERCIAL) {
    values.system_types[1].value = false;
    if (!values.system_types[0].value) {
      values.system_types[0].value = true;
      values.system_type_v2.id = 1;
      values.system_type_v2.name = 'New Installation';
    }
  }
  if (props.isSaveandClose) {
    props.createProjectDetails({
      projectDetails: values,
      permitId: props.permitId,
      isProceed: false,
      successCbk: (permitId) => fillMaterialDetails(props, permitId),
    });
  } else {
    const data = {
      values,
      props,
      isSubmit: true,
    };
    const errors = validate(data);
    if (isEmpty(errors) || !Object.keys(errors).find((key) => !isEmpty(errors[key]))) {
      props.createProjectDetails({
        projectDetails: values,
        permitId: props.permitId,
        isProceed: true,
        isSave: props.isSave,
        successCbk: (permitId) => {
          if (props.paymentStatus && props.isSubmit) {
            props.handleSubmit(values, props.materialDetails);
          } else if ((props.isSave && !props.isSaveAndClose) || !props.isSave) {
            props.jumpToPage(1);
            fillMaterialDetails(props, permitId);
          }
        },
      });
    }
    props.setError(errors);
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'ProjectDetailsForm',
    enableReinitialize: true,
    onChange,
    onSubmit: submitProjectDetails,
  })(withTheme(withStyles(styles)(ProjectDetailsForm)))
);
