import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function StoreIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 28.869 26.11">
      <g transform="translate(0.025 0.5)">
        <path stroke={props.storke} strokeWidth="1" d="M8.8,5.13H6.93a.7.7,0,0,1,0-1.39H8.8a.7.7,0,0,1,.06,1.39Z" />
        <path
          stroke={props.storke}
          strokeWidth="1"
          d="M24.16,16.36H6.93a.69.69,0,0,1-.68-.58L4.38,4.55a.691.691,0,0,1,1.36-.24h0L7.52,15H24.16a1.8,1.8,0,0,0,1.78-1.51l1.39-8.34H23.78a.69.69,0,0,1-.05-1.38h4.42a.69.69,0,0,1,.69.69.41.41,0,0,1,0,.11L27.31,13.7A3.16,3.16,0,0,1,24.16,16.36Z"
        />
        <path
          stroke={props.storke}
          strokeWidth="1"
          d="M5.06,5.12a.68.68,0,0,1-.68-.57L4.1,2.89A1.8,1.8,0,0,0,2.32,1.38H.69A.69.69,0,1,1,.64,0H2.32A3.18,3.18,0,0,1,5.47,2.66l.27,1.66a.69.69,0,0,1-.57.8H5.06Z"
        />
        <path
          stroke={props.storke}
          strokeWidth="1"
          d="M25.65,19.48H9.57a3.18,3.18,0,0,1-3.15-2.66l-.17-1a.691.691,0,1,1,1.36-.24h0l.17,1A1.8,1.8,0,0,0,9.57,18.1H25.66a.69.69,0,0,1,0,1.38Z"
        />
        <path
          stroke={props.storke}
          strokeWidth="1"
          d="M23.78,25.1a2.57,2.57,0,1,1,2.56-2.57,2.57,2.57,0,0,1-2.56,2.57Zm0-3.75a1.2,1.2,0,1,0,.04,0Z"
        />
        <path
          stroke={props.storke}
          strokeWidth="1"
          d="M9.43,25.1a2.55,2.55,0,1,1,1.823-.747A2.57,2.57,0,0,1,9.43,25.1Zm0-3.75a1.18,1.18,0,1,0,1.18,1.18,1.18,1.18,0,0,0-1.18-1.18Z"
        />
        <path
          stroke={props.storke}
          strokeWidth="1"
          d="M16.3,13.87a6.94,6.94,0,1,1,6.93-6.94,6.94,6.94,0,0,1-6.93,6.94Zm0-12.48a5.55,5.55,0,1,0,5.54,5.54h0A5.55,5.55,0,0,0,16.3,1.38Z"
        />
        <path
          stroke={props.storke}
          strokeWidth="1"
          d="M16.29,10.12a.69.69,0,0,1-.69-.69h0v-5a.7.7,0,0,1,1.39,0v5a.69.69,0,0,1-.7.69Z"
        />
        <path
          stroke={props.storke}
          strokeWidth="1"
          d="M18.79,7.62h-5a.69.69,0,0,1-.05-1.38h5a.7.7,0,0,1,.72.67.69.69,0,0,1-.67.71Z"
        />
      </g>
    </SvgIcon>
  );
}

export default StoreIcon;
