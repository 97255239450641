import {
  Divider,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { Label } from '../Label';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const isValueAvailable = (val, options) => {
  if (!options || options.length === 0) {
    return false;
  }
  const value = (options.find(option => typeof (option) === 'object' ? option.name === val : option === val))
  return value !== undefined;
};

const useStyles = makeStyles((theme) => ({
  dividerClass: {
    width:'100%',
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0),
    color: 'red'
  },
}));

const AutoCompleteDropdown = props => {
  const autoCompleteClasses = useStyles();
  const {
    id,
    classes,
    className,
    handleChange,
    labelText,
    labelClass,
    helperText,
    pwattErrorText,
    roofPitchErrorText,
    isPermitSetAvailable,
    value = '',
    isRequired = true,
    error = false,
    showRecent = false,
    optionList = [],
    otherOptionList = [],
    mostUsedList = [],
    panel_wattage = '',
    roof_pitch = '',
    capacity = '',
    power_capacity = '',
    capacityErrorText = '',
    powerCapacityErrorText = '',
    isStorage = false,
    disableDropdown = false,
    preventDefault = () => { }
  } = props;
  const isMaterialManufacturer = id === 'manufacturer' || id === 'model';
  const isPanel = id === 'panel_manufacturer' || id === 'panel_model';
  const isRackingFields = id === 'racking_manufacturer' || id === 'attachment_manufacturer'

  const modifiedOptionList = optionList;
  let valueAvailableInOption =
    isValueAvailable(value, otherOptionList) ||
    isValueAvailable(value, modifiedOptionList);

  let dropdownValue = value? (typeof value === 'object' ? value.name : value || typeof value === 'number' ? value.toString() : value) : '';

  if(!dropdownValue) {
    // assign to empty string incase of null/undefined
    dropdownValue = '';
  } 

  let isOtherSelected = dropdownValue && dropdownValue.toLowerCase().startsWith('other-');
  let otherValue = isOtherSelected ? dropdownValue.slice(6) : '';
  
  if (value && !valueAvailableInOption && !isRackingFields) {
    if (isPermitSetAvailable) {
      isPanel ? modifiedOptionList.push({ name: value }) : modifiedOptionList.push(value);
    }
  }
  const filterOptionList =
    id === 'panel_manufacturer' ? modifiedOptionList.map(value => value.name) : modifiedOptionList;
    let recentList = mostUsedList;
  if(!isRackingFields) {
    recentList = mostUsedList.filter(value => {
      if (
        value.length > 0 &&
        (filterOptionList.includes(value) ||
          (filterOptionList && filterOptionList.map(option => option.name).includes(value)))
      ) {
        return value;
      } else {
        return null;
      }
    });
  }
  recentList = recentList.filter((item, ind) => recentList.indexOf(item) === ind);
  const showOtherSection = otherOptionList.length > 0;
  const showRecentDropdown = showRecent && recentList.length > 0 && filterOptionList.length > 5;
  const filtererModifiedOptionList =
    filterOptionList.length > 5
      ? filterOptionList.filter(value => recentList.indexOf(value.name ? value.name : value) === -1)
      : filterOptionList;

  let mergedOptionsList = [];
  let otherOptionListModified = [];

  if (showRecentDropdown) {
    let mergedRecentList = ['Recently Used'].concat(recentList);
    mergedRecentList = mergedRecentList.concat(['Divider'])
    mergedOptionsList = mergedOptionsList.concat(mergedRecentList);
  }
  mergedOptionsList = mergedOptionsList.concat(filtererModifiedOptionList);
  if (showOtherSection && otherOptionList && otherOptionList.length) {
    otherOptionListModified = otherOptionList.map(item => item.toLowerCase().startsWith('other-') ? item.slice(6) : item);
    let mergedOtherList = ['Other Values'].concat(otherOptionListModified);
    mergedOptionsList = mergedOptionsList.concat(mergedOtherList);
  }

  const handleAutoCompleteChange = (value, id) => {
    // value inside Other values list is considered as Other.
    valueAvailableInOption = isValueAvailable(value, mergedOptionsList) && !isValueAvailable(value, otherOptionListModified);
    handleChange(value, id, !valueAvailableInOption);
  }

  const filterOptions = (option, state) => {
    if(state && state.inputValue) {
      const inputVal = state.inputValue.toLowerCase();
      return option.filter(item => (item.toLowerCase().includes(inputVal) && !['Recently Used', 'Other Values', 'Divider'].includes(item)));
    }
    return option;
  }
  const isOtherStorage = isStorage && value && typeof value === 'object' && value.id === 'other' ? true: false;

  return (
    <Fragment>
      <Autocomplete
        className={className}
        id={id}
        freeSolo={true} 
        disabled={disableDropdown}
        options={mergedOptionsList.map((option) => (typeof option === 'object') ? option.name : option)}
        inputValue={isOtherSelected ? otherValue : dropdownValue}
        defaultValue={isOtherSelected ? otherValue : dropdownValue}
        onInputChange={(event, newValue) => handleAutoCompleteChange(newValue, id)}
        getOptionDisabled={option => ['Recently Used', 'Other Values', 'Divider'].includes(option)}
        renderOption={(option) => {
          if (option === 'Divider') {
            return <li {...props} key='divider' className={autoCompleteClasses.dividerClass}>
              <Divider classes={{ root: classes.dividerStyle }} />
            </li>
          }
          return <li key={typeof option === 'object' ? option.id : option}>
            {typeof option === 'object' ? option.name : option}
          </li>
        }}
        filterOptions={filterOptions}
        renderInput={params => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            label={<Label text={labelText} isRequired={isRequired} />}
            onChange={(e, value) =>
              handleAutoCompleteChange(e.target.value, id)}
          />
        )}
      />
      {roof_pitch !== null &&
        id === 'installation_type' && (
          <Fragment>
            <TextField
              type="number"
              id={`roof_pitch`}
              className={clsx(classes.inputField, labelClass.customLabel, classes.otherInput)}
              label={<Label className={classes.labelColor} text={`Roof Pitch (Deg)`} isRequired />}
              value={roof_pitch}
              onChange={e => handleChange(e.target.value, 'roof_pitch', false)}
              error={!isEmpty(roofPitchErrorText)}
              helperText={roofPitchErrorText}
              disabled={dropdownValue === 'Ground Mount' || dropdownValue === 'Flat Roof'}
            />
          </Fragment>
        )}

      {/* for other panel model, user will need to enter panel wattage */}
      {(isOtherSelected || !valueAvailableInOption) &&
        id === 'panel_model' && value !== '' && (
          <Fragment>
            <TextField
              type="number"
              id={`pwatt-${id}`}
              InputProps={{ inputProps: { min: 1 } }}
              onKeyPress={e => preventDefault(e)}
              className={clsx(classes.inputField, labelClass.customLabel, classes.otherInput)}
              label={<Label className={classes.labelColor} text={`Panel Wattage`} isRequired />}
              value={panel_wattage}
              onChange={e => handleChange(e.target.value, 'panel_wattage', false)}
              error={!isEmpty(pwattErrorText)}
              helperText={pwattErrorText}
            />
          </Fragment>
        )}
     
      {isStorage &&
        id === 'model' &&
        isOtherStorage && (
          <Fragment>
            <TextField
              type="number"
              id={`capacity`}
              className={clsx(classes.inputField, labelClass.customLabel, classes.otherInput)}
              label={<Label className={classes.storageLabel} text={`Energy Capacity (kWh)`} isRequired />}
              value={capacity}
              onChange={e => handleChange(e.target.value, 'capacity', false)}
              error={!isEmpty(capacityErrorText)}
              helperText={capacityErrorText}
            />
            <TextField
              type="number"
              id={`power_capacity`}
              className={clsx(classes.inputField, labelClass.customLabel, classes.otherInput)}
              label={<Label className={classes.storageLabel} text={`Power Capacity (kWac)`} isRequired />}
              // from backend, value will come in Wac
              value={power_capacity ? power_capacity / 1000 : power_capacity}
              onChange={e => handleChange(e.target.value, 'power_capacity', false)}
              error={!isEmpty(powerCapacityErrorText)}
              helperText={powerCapacityErrorText}
            />
          </Fragment>
        )}
    </Fragment>
  );
};

export default AutoCompleteDropdown;
