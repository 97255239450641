import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, TextField, Button, Tooltip } from '@material-ui/core';
import PanelTemplate from '../../common/PanelTemplate';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileUploadDialog from '../FileUploadDialog';
import { clone, isEqual, get } from '../../../utils/lodash';
import HelpIcon from '../images/helpIcon';
import DocumentIcon from '../images/document';
import {
  electicalReviewList,
  structuralReviewList,
  INVALID_LINK_WARNING,
  ATTIC_PHOTO_TYPE,
  FEATURE_FLAG_TYPE,
  FEATURE_FLAGS,
} from '../../../containers/Permitting/constants';
import PropTypes from 'prop-types';
import { isPermitSetAvailable } from '../../../utils/helper';
import { ELECTRICAL_MSP_TAGS } from '../../Admin/applicationView/constants';
import imgIcon from '../images/img-icon.png';

// Feature Flag helper
import { isFeatureEnabled } from '../../../containers/Permitting/helper';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FFFFFF !important',
    color: '#0F0F0F',
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: theme.spacing(1),
    top: theme.spacing(-1),
    minWidth: theme.spacing(30),
    maxWidth: 800,
    pointerEvents: 'auto',
    margin: theme.spacing(2.5),
  },
  arrow: {
    color: '#FFFFFF !important',
    fontSize: 14,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2.5),
  },
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  panelWrapper: {
    padding: theme.spacing(2.5),
  },
  download: {
    width: '100%',
    maxWidth: '400px',
    '& label': {
      fontSize: 14,
    },
  },
  addNew: {
    fontSize: 14,
    color: '#008EEF',
    display: 'inline-block',
    marginTop: '10px',
    marginBottom: '20px',
    cursor: 'pointer',
  },
  imp: {
    height: 0,
    textAlign: 'right',
    fontSize: 14,
    color: '#C62E00',
    '& span': {
      color: '#C62E00',
    },
  },
  imgHolder: {
    display: 'inline-flex',
    marginRight: '27px',
    flexDirection: 'column',
    width: '123px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0.5, 1),
    },
  },
  imgContainer: {
    border: '1px solid #70707040',
    height: theme.spacing(10),
    width: theme.spacing(15),
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    opacity: 0.23,
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  image: {
    margin: 'auto',
    display: 'block',
    maxWidth: theme.spacing(15),
    maxHeight: theme.spacing(10),
  },
  imageWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  info: {
    marginBottom: theme.spacing(1.5),
    color: '#00000099',
  },
  documentIcon: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: 'auto',
    '& img': {
      width: '90%',
      height: '90%',
    },
  },
  photoInfo: {
    color: '#00000099',
    marginBottom: theme.spacing(1.5),
  },
  button: {
    color: '#888888',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #00000029',
    textTransform: 'none',
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    height: theme.spacing(3),
  },
  buttonText: {
    marginRight: theme.spacing(1),
    fontSize: 14,
  },
  tooltipWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(38),
      overflowY: 'scroll',
    },
  },
  tooltipContent: {
    padding: theme.spacing(1, 2),
  },
  tooltipHeading: {
    color: '#707070',
    fontSize: 14,
    fontWeight: 'bold',
  },
  tooltipList: {
    fontSize: 12,
    color: '#797979',
    padding: theme.spacing(0.5, 0),
  },
  clickText: {
    color: '#00000099',
    display: 'inline-block',
    margin: theme.spacing(0.5, 0),
  },
  warningText: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  addDocButton: {
    display: 'block',
  },
  docErrorText: {
    color: '#f44336',
    fontSize: '0.75rem',
  },
  important: {
    color: 'red',
  },
}));

const PhotoChecklistTooltip = () => {
  const classes = useStyles();
  return (
    <Box className={classes.tooltipWrapper}>
      <Box className={classes.tooltipContent}>
        <Typography className={classes.tooltipHeading}>For Electrical Review</Typography>
        {electicalReviewList.map((list, index) => (
          <Typography key={index} className={classes.tooltipList}>
            {list}
          </Typography>
        ))}
      </Box>
      <Box className={classes.tooltipContent}>
        <Typography className={classes.tooltipHeading}>For Structural Review</Typography>
        {structuralReviewList.map((list, index) => (
          <Typography key={index} className={classes.tooltipList}>
            {list}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

function UploadFile(props) {
  const {
    type,
    id,
    handleChange,
    materialDetails,
    fireUpload,
    photos = {},
    isMobile = false,
    photoType = null,
    applicationDetail,
    showDocError,
    errorText = '',
    featureList = [],
  } = props;

  const isOptionalPhotoUploadEnabled = isFeatureEnabled(
    featureList,
    FEATURE_FLAGS.OPTIONAL_PHOTO_UPLOAD,
    FEATURE_FLAG_TYPE.company
  );

  const permitFilesAvailable = isPermitSetAvailable(applicationDetail);
  const classes = useStyles();
  const linkType = isEqual(photoType, ATTIC_PHOTO_TYPE) ? 'attic_images_link' : 'photo_link';
  const downloadLinkKey = type === 'Document' ? 'document_link' : linkType;
  const imageKey = type === 'Document' ? 'documents' : 'photos';
  let images = [];
  for (let ind in materialDetails[imageKey]) {
    const image = materialDetails[imageKey][ind];
    // File upload from individual section (like electrical properties)
    // will have file tag starts with 'other-section-' so removing that
    if (image) {
      // file has tag {
      images.push(image);
    }
  }
  const photoTags = props.userSelectionData.photo_names || [];
  const tags = photoType ? [photoType] : photoTags;
  const errors = get(materialDetails, 'errors', {});
  const warnings = get(materialDetails, 'warnings', {});
  let addedPhotos = [];
  if (imageKey === 'photos') {
    addedPhotos = props.materialDetails.photos.map((photo) => photo.tag);
  }
  const [open, setOpen] = useState(false);
  const [uploadedPhotos, setUploadedPhotos] = useState(photos);
  const [isTooltipOpen, setIstooltipOpen] = useState(false);
  const [isTooltipInDialogOpen, setIstooltipInDialogOpen] = useState(false);

  useEffect(() => {
    if (!isEqual(uploadedPhotos, photos)) {
      setUploadedPhotos(photos);
    }
  }, [photos, uploadedPhotos]);

  const deleteImage = async (index) => {
    const newImages = clone(images);
    newImages.splice(index, 1);
    handleChange(newImages, imageKey);
  };

  const error = errors['Structural Review'] && get(errors['Structural Review'], 'photos.error');
  const showError = error && photoType === ATTIC_PHOTO_TYPE;

  const panelWrapper =
    permitFilesAvailable && !isEqual(photoType, ATTIC_PHOTO_TYPE)
      ? clsx(classes.disableForm, classes.panelWrapper)
      : classes.panelWrapper;
  const imageLoading = images.findIndex((image) => image.loading);

  const handlePhotoChecklistTooltipToggle = (inPopup, open) => {
    if (!inPopup) {
      setIstooltipOpen(open);
    } else {
      setIstooltipInDialogOpen(open);
    }
  };

  const showHeader = () => {
    return !isEqual(photoType, ATTIC_PHOTO_TYPE);
  };

  const photoUploadTooltip = (inPopup = false) => {
    return (
      <LightTooltip
        open={!inPopup ? isTooltipOpen : isTooltipInDialogOpen}
        title={<PhotoChecklistTooltip />}
        placement="bottom-end"
        arrow
      >
        <Button
          className={classes.button}
          onClick={(e) => handlePhotoChecklistTooltipToggle(inPopup, true)}
          onMouseEnter={(e) => handlePhotoChecklistTooltipToggle(inPopup, true)}
          onMouseLeave={(e) => handlePhotoChecklistTooltipToggle(inPopup, false)}
        >
          <Typography className={classes.buttonText}>Photo Upload Checklist</Typography>
          <HelpIcon />
        </Button>
      </LightTooltip>
    );
  };

  const HeadingText = (headingProps) => {
    const { fileType = 'Document' } = headingProps;
    return (
      <Fragment>
        {fileType === 'Document' && (
          <Typography id={'otherInfo_document'} className={classes.info}>
            Upload your proposals created in any design tool or hand drawn sketches.
            {!isOptionalPhotoUploadEnabled && (
              <Typography className={classes.important} component="span">
                *
              </Typography>
            )}
          </Typography>
        )}
        {fileType === 'Photo' &&
          (photoType === ATTIC_PHOTO_TYPE ? (
            <Typography className={classes.info}>
              Attic photos(Attic Far Left, Attic Far Right, Attic Center Left, Attic Center Right) are required for the
              stamps
            </Typography>
          ) : (
            <Typography component="div" className={classes.photoInfo}>
              {`Please upload photos of the site that will be helpful in creating the permit package. To see the list of
              photos required for Electrical/ Structural PE review `}
              <div className={classes.clickText}>
                click here:
                {photoUploadTooltip()}
              </div>
            </Typography>
          ))}
      </Fragment>
    );
  };

  return (
    <div className={classes.root} onClick={(e) => e.stopPropagation()}>
      <PanelTemplate header={showHeader() && `${type} Upload`} className={classes.panelContainer}>
        <Box className={panelWrapper} id={imageKey}>
          <HeadingText fileType={type} />
          {showError && (
            <Typography className={classes.imp}>
              <Typography component="span">*</Typography> At least one image or link is mandatory
            </Typography>
          )}
          <Box className={classes.imageWrapper}>
            {images.map((img, i) => {
              return (
                <Fragment>
                  {!ELECTRICAL_MSP_TAGS.includes(img.tag) && (
                    <Box className={classes.imgHolder} key={`${imageKey}-${i}`}>
                      {img.loading && <CircularProgress />}
                      {!img.loading && (
                        <Box className={classes.imgContainer}>
                          <CloseIcon onClick={() => deleteImage(i)} className={classes.close} />
                          {imageKey === 'photos' ? (
                            <img
                              className={classes.image}
                              alt={img.file_name}
                              src={uploadedPhotos[img.file_name] || imgIcon}
                            />
                          ) : (
                            <DocumentIcon />
                          )}
                        </Box>
                      )}
                      <Typography variant="caption" display="block">
                        {type === 'Document' || isEqual(photoType, ATTIC_PHOTO_TYPE) ? img.original_file_name : img.tag}
                      </Typography>
                    </Box>
                  )}
                </Fragment>
              );
            })}
          </Box>
          <Typography
            id={id}
            component="div"
            onClick={() => setOpen(true)}
            className={clsx(
              classes.addNew,
              imageLoading >= 0 && classes.disableForm,
              type === 'Document' && classes.addDocButton
            )}
          >
            + Add {type}
          </Typography>

          {showDocError && <Typography className={classes.docErrorText}>{errorText}</Typography>}
          {type !== 'Document' && (
            <Typography variant="caption" display="block">
              Share document link
            </Typography>
          )}

          {(type === 'Photo' || isOptionalPhotoUploadEnabled) && (
            // Need to show it in Document Upload section only if isOptionalPhotoUploadEnabled flag is enabled.
            <TextField
              onChange={(e) => handleChange(e.target.value, downloadLinkKey)}
              className={classes.download}
              label={isMobile ? 'Link from GoogleDrive etc' : 'Give us a link from GoogleDrive, Dropbox etc'}
              value={materialDetails[downloadLinkKey]}
              error={get(errors, `${downloadLinkKey}.error`)}
              helperText={get(errors, `${downloadLinkKey}.errorMessage`)}
            />
          )}
          {get(warnings, `${downloadLinkKey}`) && (
            <Typography className={classes.warningText}>{INVALID_LINK_WARNING}</Typography>
          )}
        </Box>
      </PanelTemplate>
      <FileUploadDialog
        type={type}
        imageKey={imageKey}
        addedPhotos={addedPhotos}
        fireUpload={fireUpload}
        photoTags={tags}
        open={open}
        setOpen={setOpen}
        photoUploadTooltip={photoUploadTooltip}
        photoType={photoType}
      />
    </div>
  );
}

UploadFile.propTypes = {
  type: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  materialDetails: PropTypes.object.isRequired,
  fireUpload: PropTypes.func.isRequired,
  photos: PropTypes.object,
  isMobile: PropTypes.bool,
  photoType: PropTypes.string,
  applicationDetail: PropTypes.object.isRequired,
};

export default UploadFile;
