import { makeStyles } from '@material-ui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PanelTemplate from '../../common/PanelTemplate';
import { clone, get, isEmpty } from 'lodash';
import { Box, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import Product from './product';
import { INVERTER_TYPES, OTHER_MATERIAL_TYPES } from '../../../containers/Permitting/constants';
import { useStyles } from '../Array/styles';
import { Label } from '../../common/Label';
import { isPermitSetAvailable } from '../../../utils/helper';

const useOwnStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2.5)
  },
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none'
  },
  addNew: {
    fontSize: 14,
    color: '#008EEF',
    display: 'inline-block',
    cursor: 'pointer'
  },
  loader: {
    padding: theme.spacing(2)
  },
  otherInfo: {
    width: '45%',
    minWidth: theme.spacing(30),
    margin: theme.spacing(0, 1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 'auto'
    }
  },
  productFooter: {
    paddingLeft: theme.spacing(2)
  },
  otherMaterialsFooter: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const Products = ({
  header,
  productKey,
  handleChange,
  materialDetails,
  applicationDetail,
  sgMaterials,
  sgMaterialsLoading,
  otherUserSelectionData,
  mostUsedValues,
  getS3URL,
  putToS3,
  permitId,
  featureList,
  isInputAutocompleteEnabled,
  handleProjectDetailsChanges = () => {}
}) => {
  const ownClasses = useOwnStyles();
  const classes = useStyles();
  const isInverter = productKey === 'inverters';
  const isStorage = productKey === 'storage_list';
  const isOtherMaterials = productKey === 'other_materials';
  const permitFilesAvailable = isPermitSetAvailable(applicationDetail);
  const errors = materialDetails && materialDetails.errors;
  const storage_size = materialDetails && materialDetails.projectDetails && materialDetails.projectDetails.storage_size;
  const storage_power =
    materialDetails && materialDetails.projectDetails && materialDetails.projectDetails.storage_power;
  const { arrays = [], existing_arrays = [], third_party_generator } = materialDetails;
  let products = [];
  if (materialDetails) {
    products = isInverter
      ? materialDetails.inverters
      : isStorage ? materialDetails.storage_list : materialDetails.other_materials;
    products = products || [];
  }

  const inverterQuantityUpdate = (val,index) => {
    let inverterQuantity = 0;
    if (
      isInverter && products && products.length >= index+1 &&
      val === 'Micro Inverter' && get(products[index], 'quantity', 0 ) === ''
    ) {
      arrays && arrays.forEach(array => {
        inverterQuantity += Number(array.quantity);
      });
      existing_arrays && existing_arrays.forEach(array => {
        inverterQuantity += Number(array.quantity);
      });
      handleProductChange('quantity', inverterQuantity, index);
    }
  }

  useEffect(
    () => {
      if (!isOtherMaterials && products && products.length === 0) {
        handleChange([ productDetails() ], productKey, { isProductAdd: true }, true, true);
      }
      if (products && products.length > 0 && isStorage) {
        let storageSize = 0,
          storagePower = 0;
        products &&
          products.forEach(product => {
            storageSize += product.quantity * product.capacity;
            storagePower += product.quantity * product.power_capacity;
          });
        storagePower /= 1000;
        handleProjectDetailsChanges([ 'storage_size', 'storage_power' ], [ storageSize, storagePower ], true, true);
      }
    },
    [ products ]
  );

  const productDetails = (
    type = '',
    manufacturer = '',
    model = '',
    capacity = '',
    power_capacity = '',
    quantity = '',
    manufacturerOptions = '',
    modelOptions = ''
  ) => {
    return {
      type,
      manufacturer,
      model,
      capacity,
      power_capacity,
      quantity,
      manufacturerOptions,
      modelOptions
    };
  };

  const onAddProduct = products => {
    const lastIndex = products.length - 1;
    const data = [
      ...products,
      lastIndex >= 0
        ? productDetails(
            products[lastIndex]['type'],
            products[lastIndex]['manufacturer'],
            products[lastIndex]['model'],
            products[lastIndex]['capacity'],
            products[lastIndex]['power_capacity'],
            products[lastIndex]['quantity'],
            products[lastIndex]['manufacturerOptions'],
            products[lastIndex]['modelOptions']
          )
        : productDetails()
    ];
    handleChange(data, productKey, { isProductAdd: true });
  };

  const deleteProduct = index => {
    const data = clone(products);
    data.splice(index, 1);
    handleChange(data, productKey, { isProductRemove: true });
  };

  const handleProductChange = (key, val, index) => {
    const items = clone(products);
    items[index][key] = val;
    handleChange(
      items,
      productKey,
      { additionalKey: key },
      [ 'manufacturerOptions', 'modelOptions' ].includes(key),
      [ 'manufacturerOptions', 'modelOptions' ].includes(key)
    );
    if (isInverter && (key === 'type' || key === 'manufacturer')) {
      inverterQuantityUpdate(val,index);
    }
  };

  const fireUpload = (file, index, key, tag = '') => {
    if (file) {
      const data = clone(products);
      const docs = clone(data[index][key] || []);
      const length = docs.length;
      const date = Date.now();
      const extension = file.name.match(/((\.[a-z]+)|(\.[A-Z]+))$/g)[0];
      const name = `${permitId}_${date}${extension}`;
      const currentVersion = get(applicationDetail, 'version.current_version', 'NEW');
      docs.push({ loading: true, original_file_name: '', tag });
      handleProductChange(key, docs, index);
      getS3URL({
        fileName: name,
        methodType: 'PUT',
        successCb: preSignedS3Url => {
          putToS3({
            fileObj: file,
            preSignedS3Url: preSignedS3Url,
            fName: name,
            successCbS3: () => {
              docs.splice(length, 1);
              docs.push({
                file_name: name,
                original_file_name: file.name,
                tag,
                file_size: file.size / 1024 / 1024,
                versions: [ currentVersion ]
              });
              handleProductChange(key, docs, index);
            },
            failureCbS3: () => {
              docs.splice(length, 1);
              handleProductChange(key, docs, index);
            }
          });
        },
        failureCb: () => {}
      });
    }
  };

  const quantityError = errors && errors[productKey + '-footer'] && errors[productKey + '-footer'].error;
  const getErrorText = (key) => (get(errors, `${key}.error`) ? get(errors, `${key}.errorMessage`) : '');

  return (
    <div className={ownClasses.root}>
      <PanelTemplate header={header} className={ownClasses.panelContainer}>
        {sgMaterialsLoading ? (
          <CircularProgress size={30} color="primary" className={ownClasses.loader} />
        ) : (
          <Box className={clsx(permitFilesAvailable && classes.disableForm)}>
            {isOtherMaterials && (
              <Typography className={classes.otherMaterials}>
                Add Materials like PV Performance Meter etc.
              </Typography>
            )}
            {products.map((product, index) => (
              <Product
                index={index}
                product={product}
                productKey={productKey}
                errors={errors}
                isInverter={isInverter}
                isStorage={isStorage}
                isOtherMaterials={isOtherMaterials}
                handleChange={handleProductChange}
                inverterOptions={INVERTER_TYPES.map(type => type.name)}
                otherMaterialTypes={OTHER_MATERIAL_TYPES}
                isPermitSetAvailable={permitFilesAvailable}
                sgMaterials={sgMaterials}
                deleteProduct={deleteProduct}
                otherUserSelectionData={otherUserSelectionData}
                mostUsedValues={mostUsedValues}
                noOfProducts={products.length}
                fireUpload={fireUpload}
                isInputAutocompleteEnabled={isInputAutocompleteEnabled}
              />
            ))}
            <div
              className={clsx(
                ownClasses.productFooter,
                isOtherMaterials && products.length === 0 && ownClasses.otherMaterialsFooter
              )}
            >
              <Typography component="div" onClick={() => onAddProduct(products)} className={ownClasses.addNew}>
                + Add {isOtherMaterials ? '' : 'New'} {header}
              </Typography>
              <br />
              {isStorage && (
                <Box>
                  <TextField
                    disabled
                    className={ownClasses.otherInfo}
                    label={<Label className={classes.storageLabel} text="Storage Energy Capacity (kWh)" isRequired />}
                    value={storage_size || ''}
                  />
                  <TextField
                    disabled
                    className={ownClasses.otherInfo}
                    label={<Label className={classes.storageLabel} text="Storage Power Capacity (kWac)" isRequired />}
                    value={storage_power || ''}
                  />
                 <FormControl component="div" className={ownClasses.otherInfo}>
                  <FormLabel component="legend">
                    <Label className={classes.labelColor} text="Do you need third party generator support in your system ?" isRequired={true} />
                  </FormLabel>

                  <RadioGroup
                    value={third_party_generator}
                    aria-label="Do you need third party generator support in your system ?"
                    name="customized-radios"
                    row
                    onChange={(e) => handleChange(e.target.value, 'third_party_generator')}
                  >
                    <FormControlLabel value={'Yes'} control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value={'No'} control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                  {getErrorText('third_party_generator') && get(errors, `third_party_generator.error`) && (
                    <FormHelperText className={classes.errorText}>{getErrorText('third_party_generator')}</FormHelperText>
                  )}
                </FormControl>
                </Box>
              )}
              {quantityError && (
                <Typography className={classes.errorText}>{errors[productKey + '-footer'].errorMessage}</Typography>
              )}
            </div>
          </Box>
        )}
      </PanelTemplate>
    </div>
  );
};

const mapStateToProps = state => ({
  otherUserSelectionData: state.permitReducer.otherUserSelectionData,
  mostUsedValues: state.permitReducer.mostUsedValues
});

export default connect(mapStateToProps, null)(Products);
