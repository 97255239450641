import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { AHJ_ELECTRICAL_PE, AHJ_STRUCTURAL_PE } from '../../../../containers/Admin/constants';
import { Label } from '../../../common/Label';
import { MultipleSelectDropdown } from '../../../common/MultipleSelectDropdown';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  multiSelectMargin: {
    marginBottom: theme.spacing(3)
  }
}));

const PVResidentialPermitDetailsSectionOldUI = props => {
  const classes = useStyles();
  const {
    errorData,
    handlePVResidentialPermitDetails,
    pvResidentialPermitDetails,
    panelLabel,
    readOnly,
    stateSelected,
    isRequired
  } = props;
  const {
    structural_peservices = [],
    structural_stamp_notes = '',
    electrical_peservices = [],
    electrical_stamp_notes = ''
  } = pvResidentialPermitDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={panelLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'Structural PE Services'} isRequired={isRequired} />}
                dropDownOptions={AHJ_STRUCTURAL_PE}
                errorData={errorData}
                handleField={handlePVResidentialPermitDetails}
                fieldId="structural_peservices"
                fieldValue={structural_peservices}
                readOnly={readOnly}
                stateSelected={stateSelected}
                className={classes.multiSelectMargin}
              />
              <TextField
                label="Structural Stamp Notes"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={structural_stamp_notes}
                onChange={e => handlePVResidentialPermitDetails(e, 'structural_stamp_notes')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'Electrical PE Services'} isRequired={isRequired} />}
                dropDownOptions={AHJ_ELECTRICAL_PE}
                errorData={errorData}
                handleField={handlePVResidentialPermitDetails}
                fieldId="electrical_peservices"
                fieldValue={electrical_peservices}
                readOnly={readOnly}
                stateSelected={stateSelected}
                className={classes.multiSelectMargin}
              />
              <TextField
                label="Electrical Stamp Notes"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={electrical_stamp_notes}
                onChange={e => handlePVResidentialPermitDetails(e, 'electrical_stamp_notes')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default PVResidentialPermitDetailsSectionOldUI;
