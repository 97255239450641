import {
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Box,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import SubPanel from '../SubPanel/subpanel';
import PanelTemplate from '../../common/PanelTemplate';
import { ELECTRICAL_TAGS, SUBPANEL_FIELDS, SUBPANEL_TAGS } from '../../../containers/Permitting/constants';
import { Label } from '../../common/Label';

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  photoErrorText: {
    fontSize: 12,
    color: 'red',
  },
  addSubpanel: {
    fontSize: 14,
    color: '#008EEF',
    display: 'inline-block',
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  addressWrapper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  imageCloseIcon: {
    fontSize: 14,
  },
  radioWrapper: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(5.5, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
      height: theme.spacing(10),
      marginTop: theme.spacing(4.5),
    },
    fontSize: theme.spacing(0.1),
  },
  requiredText: {
    color: 'red',
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
  },
  radioTitle: {
    display: 'flex',
    color: '#4f4e68',
    fontSize: theme.spacing(1.8),
    lineHeight: theme.spacing(0.1875),
    height: theme.spacing(3.5),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(1.7),
      lineHeight: theme.spacing(0.125),
    },
  },
  radioButton: {
    width: '100%',
    '&>div': {
      margin: '24px 8px 0px 8px',
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
  displayFlex: {
    display: 'flex',
  },
}));

const Subpanels = (props) => {
  const classes = useStyles();
  const {
    materialDetails,
    userSelectionData,
    handleChange,
    projectDetails,
    applicationDetail,
    isFrom,
    mostUsedValues,
    isMobile,
    fireUpload,
    subPanelList,
    getS3URL,
    putToS3,
    permitId,
  } = props;

  const newSubpanel = {
    breakerRating: '',
    busRating: '',
    serviceType: '',
    spareBreakerCount: '',
    breakerLocation: '',
    existingOpenSubpanelPhoto: [],
    subpanelStickerPhoto: [],
  };
  const electricalProperties = materialDetails.electrical_properties;
  let backupExistingSubpanelValue = '';
  if (electricalProperties.backupExistingSubpanel === true) {
    backupExistingSubpanelValue = 'true';
  } else if (electricalProperties.backupExistingSubpanel === false) {
    backupExistingSubpanelValue = 'false';
  }
  const [backupExistingSubpanel, setBackupExistingSubpanel] = useState(backupExistingSubpanelValue);
  const addNewText = (subPanelList || []).length === 0 ? '+ Add Sub Panel' : '+ Add Another Sub Panel';
  const isPartialHomeBackup = get(projectDetails, 'home_backup_option', null) === 'partial';
  const subpanelExisting = isEmpty(electricalProperties.subpanelExisting)
    ? newSubpanel
    : electricalProperties.subpanelExisting;

  const handleSubpanelRadioButton = (e) => {
    setBackupExistingSubpanel(e.target.value);
    electricalProperties.backupExistingSubpanel = e.target.value === 'true' ? true : false;
    handleChange(
      electricalProperties,
      'electrical_properties',
      { additionalKey: 'backupExistingSubpanel' },
      false,
      false
    );
  };

  const handleExistingSubpanelChange = (key, value) => {
    const subpanelItem = subpanelExisting;
    switch (key) {
      case SUBPANEL_FIELDS.bus_rating:
        subpanelItem.busRating = value;
        break;
      case SUBPANEL_FIELDS.breaker_rating:
        subpanelItem.breakerRating = value;
        break;
      case SUBPANEL_FIELDS.service_type:
        subpanelItem.serviceType = value;
        break;
      case SUBPANEL_FIELDS.breaker_location:
        subpanelItem.breakerLocation = value;
        break;
      case SUBPANEL_FIELDS.spare_breaker_count:
        subpanelItem.spareBreakerCount = value;
        break;
      case ELECTRICAL_TAGS.subpanel_sticker:
        subpanelItem.subpanelStickerPhoto = value;
        break;
      case ELECTRICAL_TAGS.existing_open_subpanel:
        subpanelItem.existingOpenSubpanelPhoto = value;
        break;
    }
    electricalProperties.subpanelExisting = subpanelItem;
    handleChange(
      electricalProperties,
      'electrical_properties',
      { additionalKey: key, subpanelIndex: -1, existingSubpanel: true, validateSubpanelKeys: true },
      false,
      false
    );
  };

  const handleSubpanelChange = (key, value, index) => {
    const subpanelListItem = subPanelList;
    const subpanelItem = subpanelListItem[index];
    switch (key) {
      case SUBPANEL_FIELDS.bus_rating:
        subpanelItem.busRating = value;
        break;
      case SUBPANEL_FIELDS.breaker_rating:
        subpanelItem.breakerRating = value;
        break;
      case SUBPANEL_FIELDS.service_type:
        subpanelItem.serviceType = value;
        break;
      case SUBPANEL_FIELDS.breaker_location:
        subpanelItem.breakerLocation = value;
        break;
      case SUBPANEL_FIELDS.spare_breaker_count:
        subpanelItem.spareBreakerCount = value;
        break;
      case ELECTRICAL_TAGS.subpanel_sticker:
        subpanelItem.subpanelStickerPhoto = value;
        break;
      case ELECTRICAL_TAGS.existing_open_subpanel:
        subpanelItem.existingOpenSubpanelPhoto = value;
        break;
    }
    subpanelListItem[index] = subpanelItem;
    electricalProperties.subpanels = subpanelListItem;
    handleChange(
      electricalProperties,
      'electrical_properties',
      { additionalKey: key, subpanelIndex: index, existingSubpanel: false, validateSubpanelKeys: true },
      false,
      false
    );
  };

  const handleRemoveSubpanel = () => {
    const subPlist = subPanelList.slice(0, -1);
    electricalProperties.subpanels = subPlist;
    handleChange(electricalProperties, 'electrical_properties', {}, true, false);
  };

  const addNewSubpanel = () => {
    subPanelList.push({
      breakerRating: '',
      busRating: '',
      serviceType: '',
      spareBreakerCount: '',
      breakerLocation: '',
      existingOpenSubpanelPhoto: [],
      subpanelStickerPhoto: [],
    });
    electricalProperties.subpanels = subPanelList;
    handleChange(electricalProperties, 'electrical_properties', {}, true, false);
  };

  const errors = get(materialDetails, 'errors', {});

  return (
    <PanelTemplate header="Sub Panel Details" className={classes.panelContainer}>
      <Box className={classes.addressWrapper}>
        {isPartialHomeBackup && (
          <Box id={'backupExistingSubpanel'} className={classes.radioButton}>
            <FormControl component="div" className={classes.radioWrapper}>
              <FormLabel component="legend">
                <Label
                  className={classes.labelColor}
                  text="Are you going to backup existing subpanel?"
                  isRequired={true}
                />
              </FormLabel>
              <RadioGroup value={backupExistingSubpanel} row onChange={(e) => handleSubpanelRadioButton(e)}>
                <FormControlLabel value={'true'} control={<Radio color="primary" size="small" />} label="Yes" />
                <FormControlLabel value={'false'} control={<Radio color="primary" size="small" />} label="No" />
              </RadioGroup>
              {get(errors, `backupExistingSubpanel.error`, '') && (
                <Typography className={classes.photoErrorText}>
                  {get(errors, `backupExistingSubpanel.errorMessage`)}
                </Typography>
              )}
            </FormControl>
          </Box>
        )}
        {isPartialHomeBackup && backupExistingSubpanel === 'true' && (
          <SubPanel
            subpanel={subpanelExisting}
            materialDetails={materialDetails}
            userSelectionData={userSelectionData}
            handleChange={handleChange}
            projectDetails={projectDetails}
            applicationDetail={applicationDetail}
            isFrom={isFrom}
            mostUsedValues={mostUsedValues}
            isMobile={isMobile}
            fireUpload={fireUpload}
            subpanelList={subPanelList}
            handleSubpanelChange={handleSubpanelChange}
            handleExistingSubpanelChange={handleExistingSubpanelChange}
            isExistingSubpanel={true}
            backupExistingSubpanel={backupExistingSubpanel}
            getS3URL={getS3URL}
            putToS3={putToS3}
            permitId={permitId}
          />
        )}
        {subPanelList.map((subpanel, index) => {
          return (
            <SubPanel
              subpanel={subpanel}
              index={index}
              materialDetails={materialDetails}
              userSelectionData={userSelectionData}
              handleChange={handleChange}
              projectDetails={projectDetails}
              applicationDetail={applicationDetail}
              isFrom={isFrom}
              mostUsedValues={mostUsedValues}
              isMobile={isMobile}
              fireUpload={fireUpload}
              subpanelList={subPanelList}
              handleRemoveSubpanel={handleRemoveSubpanel}
              handleSubpanelChange={handleSubpanelChange}
              handleExistingSubpanelChange={handleExistingSubpanelChange}
              isExistingSubpanel={false}
              backupExistingSubpanel={backupExistingSubpanel}
              getS3URL={getS3URL}
              putToS3={putToS3}
              permitId={permitId}
            />
          );
        })}
        <Typography className={classes.addSubpanel} onClick={() => addNewSubpanel()}>
          {addNewText}
        </Typography>
      </Box>
    </PanelTemplate>
  );
};

export default Subpanels;
