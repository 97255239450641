import React, { useEffect } from 'react';
import { forwardTo } from '../../../../utils/helper';
import { withStyles } from '@material-ui/core/styles';
import { Box, FormControl, Select, MenuItem } from '@material-ui/core';

const styles = theme => ({
  selectContainer: {
    display: 'flex',
    marginRight: theme.spacing(3)
  },
  selectText: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.7)
  },
  styleSelect: {
    display: 'flex',
    height: theme.spacing(4.25),
    fontSize: theme.spacing(2.8),
    justifyContent: 'space-between',
    color: theme.palette.primary.dark,
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

const ApplicationsDropdown = props => {
  const { pageHeader, classes, onChange, optionsList, disabledOption = false } = props;
  const [ filterDropdown, setFilterDropdown ] = React.useState(pageHeader);

  useEffect(() => {
    setFilterDropdown(pageHeader);
  }, [pageHeader]);

  return (
    <Box className={classes.selectContainer}>
      <FormControl variant="standard">
        <Select
          MenuProps={MenuProps}
          value={filterDropdown}
          className={classes.styleSelect}
          onChange={e => {
            setFilterDropdown(e.target.value);
            onChange ? onChange(e.target.value) : forwardTo('/' + e.target.value);
          }}
        >
          {optionsList.map((data, index) => {
            return (
              <MenuItem
                key={index}
                value={data.value}
                disabled={disabledOption}
              >
                {data.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default withStyles(styles)(ApplicationsDropdown);
