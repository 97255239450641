import { take, put, call } from 'redux-saga/effects';
import settings from '../../utils/settings';
import { get } from '../../utils/api';
import { GET_ACCOUNT_DETAIL_REQUEST, GET_ACCOUNT_DETAIL_SUCCESS, GET_ACCOUNT_DETAIL_FAILURE } from './constants';

export function* getAccountDetailsFlow() {
  while (true) {
    yield take(GET_ACCOUNT_DETAIL_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.getApiUrl(`api_internal/account?service_roles=1`),
      });
      const { success, result, isUnauthorized } = response;
      if (success) {
        const { permit_service_role } = result?.service_roles;
        yield put({
          type: GET_ACCOUNT_DETAIL_SUCCESS,
          permitServiceRole: permit_service_role,
          emailId: result?.email,
          firstName: result?.first_name,
          lastName: result?.last_name,
        });
      } else {
        yield put({ type: GET_ACCOUNT_DETAIL_FAILURE, error: result.message || result.messages, isUnauthorized });
      }
    } catch (error) {
      yield put({ type: GET_ACCOUNT_DETAIL_FAILURE, error });
    }
  }
}
