import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import Close from '@material-ui/icons/Close';
import React, { Fragment } from 'react';
import queryString from 'query-string';
import PageHeader from '../../common/PageHeader';
import ErrorIcon from '@material-ui/icons/Error';
import StorageProperties from '../ElectricalProperties/StorageProperties';
import EquipmentLocation from '../equipmentLocation';
import Products from '../Products';
import { constructQueryString } from '../../../utils/helper';
import { isFeatureEnabled } from '../../../containers/Permitting/helper';
import { get, keysIn } from 'lodash';
import settings from '../../../utils/settings';
import { validateDetails } from './validate';
import { ARRAY_FIELDS, FEATURE_FLAG_TYPE, FEATURE_FLAGS } from '../../../containers/Permitting/constants';
import Subpanels from '../../../components/Permitting/SubPanel/index';
import { validateSubpanelFieldsByKeyStoragePopup, validateEquipmentLocation } from './validate';

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    color: theme.palette.primary.white,
    marginLeft: theme.spacing(1.5),
  },
  dialogContent: {
    border: '1px solid #999999',
    borderRadius: theme.spacing(1),
    margin: theme.spacing(0, 3, 3, 3),
    padding: 0,
  },
  buttonWrapper: {
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 0, 1.5, 3),
  },
  radioWrapper: {
    paddingLeft: theme.spacing(2),
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  firstRadioLabel: {
    marginRight: theme.spacing(3),
  },
  radioLabel: {
    height: theme.spacing(4.75),
    '& .MuiRadio-root': {
      padding: theme.spacing(0, 1),
    },
    '& span': {
      fontSize: 12,
    },
    '& svg': {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
  warning: {
    backgroundColor: '#fff5ef',
    fontSize: theme.spacing(1.7),
    border: '0.5px solid #ff6400',
    margin: theme.spacing(0, 1.75, 3.5, 1.75),
    padding: theme.spacing(0.625, 0.5, 0.375, 0.5),
  },
  reportIcon: {
    padding: theme.spacing(0, 1),
    borderRadius: theme.spacing(4),
    color: theme.palette.primary.main,
  },
}));

const StoragePopup = ({
  open,
  handleClose,
  handleChange,
  materialDetails,
  applicationDetail,
  sgMaterials,
  sgMaterialsLoading,
  handleProjectDetailsChange,
  handleProjectDetailsChanges,
  fireUpload,
  deletePhotos,
  projectDetails,
  userSelectionData,
  paymentStatus,
  isMobile,
  otherUserSelectionData,
  subPanelList,
  mostUsedValues,
  permitId,
  setShowConfirmationDialog,
  addToCartAndGetUrl,
  setErrorObj,
  postAdditionalStorageData,
  storageIncluded,
  permitDirectSubmit,
  featureList,
  getS3URL,
  putToS3,
  isInputAutocompleteEnabled,
}) => {
  const classes = useStyles();
  const isDirectSubmitEnabled = isFeatureEnabled(featureList, FEATURE_FLAGS.DIRECT_SUBMIT, FEATURE_FLAG_TYPE.company);
  const handleBackupChange = (value) => {
    handleProjectDetailsChange('home_backup_option', value);
  };
  const getAdditionalStorageData = () => {
    const equipmentLocation = get(materialDetails, 'equipment_location', []) || [];
    let enchargeLocation = '',
      enpowerLocation = '';
    for (let i in equipmentLocation) {
      const el = equipmentLocation[i];
      if (el.item === 'Encharge Location') {
        enchargeLocation = el.location;
      } else if (el.item === 'Enpower Location') {
        enpowerLocation = el.location;
      }
    }
    const electricalProps = get(materialDetails, 'electrical_properties', {});
    const backUpExistingSubpanelFlag = get(electricalProps, 'backupExistingSubpanel', '');
    return {
      home_backup: get(projectDetails, 'home_backup_option', 'full'),
      storage_size: get(projectDetails, 'storage_size', ''),
      storage_power: get(projectDetails, 'storage_power', ''),
      storage: get(materialDetails, 'storage_list', []),
      third_party_generator: get(materialDetails, 'third_party_generator', null),
      encharge_location: enchargeLocation,
      enpower_location: enpowerLocation,
      existing_subpanel: get(electricalProps, 'existing_subpanel', ''),
      subpanel_bus_rating: get(electricalProps, 'subpanel_bus_rating', ''),
      subpanel_breaker_rating: get(electricalProps, 'subpanel_breaker_rating', ''),
      backup_existing_subpanel: backUpExistingSubpanelFlag,
      subpanel_existing: backUpExistingSubpanelFlag ? get(electricalProps, 'subpanelExisting', {}) : null,
      subpanels: get(electricalProps, 'subpanels', []),
    };
  };
  const handleEquipmentLocationChange = (value, key) => {
    const equipmentLocation = get(materialDetails, 'equipment_location', []);
    const errors = materialDetails.errors;
    errors['encharge_location'] = validateEquipmentLocation(
      get(projectDetails, 'home_backup_option', ''),
      equipmentLocation
    );
    setErrorObj(errors);
    handleChange(value, key);
  };

  const handleStoragePopupSubpanelChange = (
    value,
    key,
    additionalDetails = {},
    skipValidation = false,
    autoPopulated = false
  ) => {
    const errors = validateSubpanelFieldsByKeyStoragePopup(
      value,
      key,
      additionalDetails,
      skipValidation,
      autoPopulated,
      materialDetails,
      get(projectDetails, 'home_backup_option', '')
    );
    if (findErrorKey(errors)) {
      setErrorObj(errors);
    }
    handleChange(value, key, additionalDetails, skipValidation, autoPopulated);
  };

  const handleProceed = () => {
    const equipmentLocation = get(materialDetails, 'equipment_location', []);
    const errors = validateDetails(materialDetails, get(projectDetails, 'home_backup_option', ''), equipmentLocation, isInputAutocompleteEnabled);
    if (findErrorKey(errors)) {
      setErrorObj(errors);
    } else {
      setErrorObj(errors);
      postAdditionalStorageData({
        additionalStorage: getAdditionalStorageData(),
        permitId: permitId,
        successCbk: () =>
          !storageIncluded
            ? !isDirectSubmitEnabled
              ? handleRedirectToStore()
              : permitDirectSubmit({
                permitId: permitId,
                isStorageSelected: true,
                successCbk: () => (window.location.search += '&status=success&payment_for=storage'),
                failureCbk: () => (window.location.search += '&status=failure'),
              })
            : undefined,
      });
    }
  };
  const findErrorKey = (errors) => {
    const errorKey = keysIn(errors).find((key) => {
      if (ARRAY_FIELDS.includes(key)) {
        return (
          errors[key] &&
          errors[key].find((license) => {
            return findErrorKey(license);
          })
        );
      }
      return errors[key].error === true;
    });
    return errorKey;
  };
  const handleRedirectToStore = () => {
    handleClose();
    setShowConfirmationDialog(true);
    addToCartAndGetUrl({
      permitId: permitId,
      selectedServicesName: [],
      addingStorage: true,
      successCbk: (cartUrl) => {
        const urls = window.location.href.split('?');
        const { origin } = queryString.parse(urls[1]);
        const searchParams = constructQueryString({ origin: origin, payment_for: 'storage' }, true);
        const currentUrl = urls[0] + searchParams;
        let urlForStore = getUrlToStoreWithRedirectOptions(cartUrl, currentUrl);
        window.open(urlForStore, '_self');
      },
      failureCbk: () => {
        setShowConfirmationDialog(false);
      },
    });
  };
  const getUrlToStoreWithRedirectOptions = (cartUrl, currentUrl) => {
    const redirectAPI = settings.permittingUrl(`payments/${permitId}/paymentDetails`);
    return `${cartUrl}&redirect_api=${encodeURIComponent(redirectAPI)}&redirect_url=${encodeURIComponent(currentUrl)}`;
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialogContainer}
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={classes.dialogHeader} disableTypography>
        <PageHeader text="Storage Information" />
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Fragment>
          <RadioGroup
            className={classes.radioWrapper}
            value={(projectDetails && projectDetails.home_backup_option) || 'full'}
            aria-label={'home_backup'}
            name={'home_backup'}
            row
            onChange={(e) => handleBackupChange(e.target.value)}
          >
            <FormControlLabel
              className={clsx(classes.radioLabel, classes.firstRadioLabel)}
              value={'full'}
              control={<Radio color="primary" />}
              label={'Whole Home Backup'}
            />
            <FormControlLabel
              className={classes.radioLabel}
              value={'partial'}
              control={<Radio color="primary" />}
              label={'Partial Home Backup'}
            />
            <FormControlLabel
              className={clsx(classes.radioLabel, classes.firstRadioLabel)}
              value={'gridTied'}
              control={<Radio color="primary" />}
              label={'Grid Tied'}
            />
          </RadioGroup>
          <Products
            productKey="storage_list"
            header="Storage"
            handleChange={handleChange}
            materialDetails={materialDetails}
            applicationDetail={applicationDetail}
            sgMaterials={sgMaterials}
            sgMaterialsLoading={sgMaterialsLoading}
            handleProjectDetailsChanges={handleProjectDetailsChanges}
            getS3URL={getS3URL}
            putToS3={putToS3}
            permitId={permitId}
            featureList={featureList}
            isInputAutocompleteEnabled={isInputAutocompleteEnabled}
          />
          <EquipmentLocation
            materialDetails={materialDetails}
            projectDetails={projectDetails}
            userSelectionData={userSelectionData}
            handleChange={handleEquipmentLocationChange}
            paymentStatus={paymentStatus}
            applicationDetail={applicationDetail}
            isMobile={isMobile}
          />
          <Subpanels
            materialDetails={materialDetails}
            projectDetails={projectDetails}
            userSelectionData={userSelectionData}
            handleChange={handleStoragePopupSubpanelChange}
            paymentStatus={paymentStatus}
            deletePhotos={deletePhotos}
            applicationDetail={applicationDetail}
            isMobile={isMobile}
            permitId={permitId}
            fireUpload={fireUpload}
            subPanelList={subPanelList}
            getS3URL={getS3URL}
            putToS3={putToS3}
          />
          {isDirectSubmitEnabled && (
            <Typography className={classes.warning}>
              <IconButton className={classes.reportIcon}>
                <ErrorIcon />
              </IconButton>
              Charges will be added to your monthly billing cycle
            </Typography>
          )}
        </Fragment>
        <div className={classes.buttonWrapper}>
          <Button onClick={handleClose} color="primary" variant="outlined" disableElevation>
            Back
          </Button>
          <Button
            onClick={handleProceed}
            color="primary"
            variant="contained"
            disableElevation
            className={classes.button}
          >
            {!isDirectSubmitEnabled ? `Next` : `Submit Application`}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StoragePopup;
