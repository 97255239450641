export const INSTALLER_DETAILS = [
  {
    id: 'installer_user_name', /// Enln application/ solargraf
    label: 'Installer Name',  // label needs to display
    type: 'text', // type of the input which is defined in the display grid component
    showModified: false // secondary flag to check whether we need to compare
  },
  {
    id: 'email',
    label: 'Installer Email',
    type: 'text',
    showModified: false
  },
  {
    id: 'installer_id',
    label: 'Installer Company (code)',
    type: 'installerCode',
    showModified: false
  },
  {
    id: 'material_details.installer_contact_phone',
    SGid: 'installer_phone',
    label: 'Installer Phone Number',
    type: 'PhoneNumber',
    showModified: true
  },
  {
    id: 'installer_address',
    label: 'Installer Address',
    type: 'text',
    showModified: false
  },
  {
    id: 'project_details.installer_license_no',
    label: 'Installer License Number',
    type: 'textInstaller',
    showModified: true
  },
  {
    id: 'project_details.electrical_license_no',
    label: 'Electrical License Number',
    type: 'textInstaller',
    showModified: true
  },
  {
    id: 'installer_logo',
    label: 'Installer Company Logo',
    type: 'logo',
    sgAttribute: 'sg_attributes.company_logo',
    showModified: false
  }
];

export const SERVICE_DETAILS = [
  {
    id: 'selected_service_names',
    label: 'Required Services',
    type: 'services',
    showModified: false
  },
  {
    id: 'physical_stamp_input.mailing_address',
    label: 'Physical Stamps Mailing Address',
    type: 'physicalStamps',
    showModified: true
  },
  {
    id: 'physical_stamp_input.copies',
    label: 'Number of copies',
    type: 'physicalStamps',
    showModified: true
  },
  {
    id: 'physical_stamp_input.is_permit_cancelled',
    label: 'Is Permit Cancelled',
    type: 'boolean',
    showModified: true
  }
];

export const PROJECT_DETAILS = [
  {
    id: 'project_details.project_type',
    label: 'Project Type',
    type: 'text',
    showModified: true
  },
  {
    id: 'project_details.system_name',
    label: 'System/Project Name',
    type: 'text',
    showModified: true
  },
  {
    id: 'project_details.customer_name',
    label: 'Customer Name',
    type: 'text',
    showModified: true
  },
  {
    id: 'project_details.user_phone',
    label: 'Customer Phone',
    type: 'text',
    showModified: true
  },
  {
    id: 'project_details.user_email',
    label: 'Customer Email',
    type: 'text',
    showModified: true
  },
  {
    id: 'project_details.full_address',
    label: 'Project Address',
    type: 'text',
    showModified: true
  },
  {
    id: 'project_details.zipcode',
    label: 'Zip',
    type: 'text',
    showModified: true
  },
  {
    id: 'project_details.project_requirement_description',
    label: 'Project Description & Requirements',
    type: 'text',
    showModified: true
  },
  {
    id: 'project_details.utility_provider',
    label: 'Utility Provider',
    type: 'utility',
    showModified: true
  },
  {
    id: 'project_details.ahj',
    label: 'AHJ',
    type: 'ahj',
    showModified: true
  },
  {
    id: 'project_details.ahj_notes',
    label: 'AHJ Specific Requirements',
    type: 'text',
    showModified: true
  },
  {
    id: 'project_details.latitude',
    label: 'Latitude',
    type: 'text',
    showModified: true
  },
  {
    id: 'project_details.longitude',
    label: 'Longitude',
    type: 'text',
    showModified: true
  }
];

export const SYSTEM_DETAILS = [
  {
    label: 'System Type, PV',
    id: 'project_details.system_type_v2.name',
    type: 'text',
    showModified: true
  },
  {
    label: 'System Type, New Energy Storage System (ESS)',
    id: '',
    type: 'systemType',
    showModified: true
  },
  {
    label: 'Solar System Size (kWdc)',
    id: 'project_details.solar_system_size',
    type: 'text',
    showModified: true
  },
  {
    label: 'Storage Energy Capacity (kWh)',
    id: 'project_details.storage_size',
    type: 'storageEnergy',
    showModified: true
  },
  {
    label: 'Storage Power Capacity (kWac)',
    id: 'project_details.storage_power',
    type: 'storagePower',
    showModified: true
  },
  {
    label: 'Backup Selected',
    id: '',
    type: 'backUpselected',
    showModified: true
  },
  {
    label: 'Need third party generator support in the system',
    id: 'material_details.third_party_generator',
    type: 'text',
    showModified: true
  },
  {
    label: 'Notes / Other Information',
    id: 'material_details.panels_array_other_information',
    type: 'text',
    showModified: true
  }
];

export const SYSTEM_RACKING_DETAILS = [
  {
    label: 'Racking Manufacturer',
    id: 'material_details.racking_attachment_properties.racking_manufacturer',
    type: 'otherText',
    showModified: true
  },
  {
    label: 'Racking Model',
    id: 'material_details.racking_attachment_properties.racking_model',
    manufacturer: 'material_details.racking_attachment_properties.racking_manufacturer',
    type: 'otherTextWithModel',
    showModified: true,
    attachmentId: 'material_details.racking_attachment_properties.racking_spec_sheet'
  },
  {
    label: 'Attachment Manufacturer',
    id: 'material_details.racking_attachment_properties.attachment_manufacturer',
    type: 'otherText',
    showModified: true
  },
  {
    label: 'Attachment Model',
    id: 'material_details.racking_attachment_properties.attachment_model',
    manufacturer: 'material_details.racking_attachment_properties.attachment_manufacturer',
    type: 'otherTextWithModel',
    showModified: true,
    attachmentId: 'material_details.racking_attachment_properties.attachment_spec_sheet'
  },
  {
    label: 'Attachment Type',
    id: 'material_details.racking_attachment_properties.attachment_type',
    type: 'text',
    showModified: true
  },
  {
    label: 'Max Spacing between Attachments (inches)',
    id: 'material_details.racking_attachment_properties.attachment_spacing',
    type: 'otherText',
    showModified: true
  },
  {
    label: 'Wind Speed (mph)',
    id: 'material_details.racking_attachment_properties.wind_load',
    type: 'textLabel',
    showModified: true,
    valueLabel: 'mph'
  },
  {
    label: 'Snow Load (psf)',
    id: 'material_details.racking_attachment_properties.snow_load',
    type: 'textLabel',
    showModified: true,
    valueLabel: 'psf'
  },
  {
    label: 'Structural & Attachment Notes',
    id: 'material_details.racking_attachment_properties.notes',
    type: 'textLabel',
    showModified: true
  }
];

export const INTERCONNECTION_DETAILS = [
  {
    label: 'Interconnection (Tie-in) Method',
    id: 'material_details.interconnection_properties.interconnection_strategy',
    type: 'text',
    showModified: true
  },
  {
    label: 'Interconnection Location',
    id: 'material_details.interconnection_properties.interconnection_location',
    type: 'text',
    showModified: true
  },
  {
    label: 'Notes/Other Information',
    id: 'material_details.interconnection_properties.other_information',
    type: 'text',
    showModified: true
  }
];

export const ADDITIONAL_DETAILS = [
  {
    label: 'Will you run a wire through the attic ?',
    id: 'material_details.wire_from_attic',
    type: 'boolean',
    showModified: true
  },
  {
    label: 'Additional Information/Requests',
    id: 'material_details.additional_info',
    type: 'additionalInfo',
    showModified: true
  }
];

export const ELECTRICAL_DETAILS = [
  {
    label: 'Service Type',
    id: 'material_details.electrical_properties.service_type',
    type: 'otherText',
    showModified: true
  },
  {
    label: 'Utility Meter Location',
    id: 'material_details.electrical_properties.utility_meter_location',
    type: 'text',
    showModified: true
  },
  {
    label: 'Utility Entrance',
    id: 'material_details.electrical_properties.feeder_type',
    type: 'text',
    showModified: true
  },
  {
    label: 'Feed Location',
    id: 'material_details.electrical_properties.main_breaker_location',
    type: 'text',
    showModified: true
  },
  {
    label: 'MSP Manufacturer',
    id: 'material_details.electrical_properties.msp_manufacturer',
    type: 'text',
    showModified: true
  },
  {
    label: 'MSP Model',
    id: 'material_details.electrical_properties.msp_model',
    type: 'text',
    showModified: true
  },
  {
    label: 'MSP Upgrade',
    id: 'material_details.electrical_properties.msp_upgrade',
    type: 'MSP_Upgrade',
    showModified: true
  },
  {
    label: 'Are you going to backup the existing Subpanel',
    id: 'material_details.electrical_properties.backupExistingSubpanel',
    type: 'backupExistingSubpanel',
    showModified: true
  },
  {
    label: 'Spare Breaker Count',
    id: 'material_details.electrical_properties.spare_breaker_count',
    type: 'text',
    showModified: true
  },
  {
    label: 'Notes/Other Information',
    id: 'material_details.electrical_properties.other_information',
    type: 'text',
    showModified: true
  }
];

export const ELECTRICAL_MSP_TAGS = [
  'other-section-Main Service Panel Location',
  'other-section-Utility Meter Location',
  'other-section-Sub Panel Location',
  'other-section-Service Panel Sticker',
  'other-section-Service Panel Wihout Dead Front'
];

export const ELECTRICAL_MSP_TAGS_DOCUMENT_NAME = new Map([
  ['other-section-Main Service Panel Location', 'Main_Service_Panel_With_Dead_Front'],
  ['other-section-Utility Meter Location', 'Utility_Meter_Photo'],
  ['other-section-Sub Panel Location', 'Sub_Panel_Location'],
  ['other-section-Service Panel Sticker', 'Main_Service_Panel_Sticker_Photo'],
  ['other-section-Service Panel Wihout Dead Front', 'Main_Service_Panel_Without_Dead_Front']
]);

export const ELECTRICAL_MSP_TAGS_SG_DOCUMENTS = [
  'Main_Service_Panel_With_Dead_Front',
  'Main_Service_Panel_Without_Dead_Front',
  'Main_Service_Panel_Sticker_Photo',
  'Utility_Meter_Photo'
];

export const ATTIC_PHOTO_TAGS = [
  'Attic Space - Show existing roof rafter/truss for each'
];

export const CHECK_DIFF = true;
