import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Fragment } from 'react';
import { CHECK_DIFF } from '../../Admin/applicationView/constants';

const styles = theme => ({
  newLabel: {
    background: '#0666f5 0% 0% no-repeat padding-box'
  },
  updatedLabel: {
    background: '#FF7141 0% 0% no-repeat padding-box'
  },
  deletedLabel: {
    background: '#891717 0% 0% no-repeat padding-box'
  },
  uLabel: {
    letterSpacing: '0 !important',
    lineHeight: '0 !important',
    color: '#ffffff',
    textTransform: 'uppercase',
    height: theme.spacing(1.8),
    fontSize: '9px !important',
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1)
  },
  marginLeft1: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.5)
  }
});

export const useLabelStyles = makeStyles(styles);

export const TagLabel = props => {
  const { edit = false, add = false, deleted = false, text = '',applicationDetail } = props;
  const classes = useLabelStyles();
  let newVersion = true
  if(!applicationDetail?.version_updated_date){
    newVersion = applicationDetail?.version?.current_version.includes('NEW') && !applicationDetail?.version?.more_info_request && !applicationDetail?.version?.order_stamp;
  } else {
     newVersion = applicationDetail?.version.includes('NEW') && !applicationDetail?.more_info_request && !applicationDetail?.stamping_request;
  }
  if (!CHECK_DIFF || newVersion) {
    return null;
  }
  if (deleted) {
    return (
      <Fragment>
        <Typography className={clsx(classes.deletedLabel, classes.uLabel)}>{'Deleted'}</Typography>
        <Typography className={classes.marginLeft1}>{text}</Typography>
      </Fragment>
    );
  }
  if (edit) {
    return (
      <Fragment>
        <Typography className={clsx(classes.updatedLabel, classes.uLabel)}>{'Updated'}</Typography>
        <Typography className={classes.marginLeft1}>{text}</Typography>
      </Fragment>
    );
  }
  if (add) {
    return (
      <Fragment>
        <Typography className={clsx(classes.newLabel, classes.uLabel)}>{'New'}</Typography>
        <Typography className={classes.marginLeft1}>{text}</Typography>
      </Fragment>
    );
  }
};

export const labelStyles = styles;
