import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
  FormControl,
  TextField,
  Grid,
  Box,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import OutlinedButton from '../../../common/OutlinedButton';
import ContainedButton from '../../../common/ContainedButton';
import PageHeader from '../../../../components/common/PageHeader';

const useStyles = makeStyles(theme => ({
  dialogTemplateHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 0,
  },
  closeButton: {
    padding: 0,
  },
}));

function TextUploadDialog({
  open,
  onClose,
  title,
  content,
  setContent,
  isContentEditable,
  containedButtonText,
  disableContainedButton,
  containedButtonClick,
  outlinedButtonText,
  outlinedButtonClick,
  disableOutlinedButton,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='lg'
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle className={classes.dialogTemplateHeader} disableTypography>
        <Grid container>
          <Grid item xs={11} md={11}>
            <PageHeader text={title} />
          </Grid>
          <Grid item xs={1} md={1}>
            <Box display='flex' justifyContent='flex-end'>
              <IconButton
                aria-label='close'
                className={classes.closeButton}
                onClick={() => {
                  onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            value={content}
            onChange={e => setContent(e)}
            InputProps={{
              disableUnderline: true,
              readOnly: !isContentEditable,
            }}
            multiline
            rows={8}
            placeholder='Note Content'
            variant='outlined'
          />
        </FormControl>
      </DialogContent>
      {isContentEditable && (
        <DialogActions>
          <OutlinedButton
            text={outlinedButtonText}
            handleClick={() => {
              outlinedButtonClick();
            }}
            disabled={disableOutlinedButton}
          />
          <ContainedButton
            text={containedButtonText}
            handleClick={() => {
              containedButtonClick();
            }}
            disabled={disableContainedButton}
          />
        </DialogActions>
      )}
    </Dialog>
  );
}

export default TextUploadDialog;
