export const APPLICATION_STATES = {
  APPLICATION_INCOMPLETE: { name: 'Application Incomplete', id: 0 },
  NEW_APPLICATION_RECEIVED: { name: 'Application Received', id: 1 },
  NEW_APPLICATION_ASSIGNED: { name: 'Application Assigned', id: 2 },
  APPLICATION_UNDER_REVIEW: { name: 'Application Under Progress', id: 3 },
  PERMIT_DRAWINGS_COMPLETED: { name: 'Permit Drawings Completed', id: 4 },
  QA_STARTED: { name: 'QA Started', id: 5 },
  QA_COMPLETED: { name: 'QA Completed', id: 6 },
  PERMIT_FILES_DOWNLOADED: { name: 'Permit Files Downloaded', id: 7 },
  STAMPING_REQUESTED: { name: 'Stamping Request Received', id: 8 },
  STAMPING_SUBMITTED: { name: 'Stamping Files Uploaded', id: 9 },
  MORE_INFO_REQUESTED: { name: 'More Information Requested', id: 10 },
  MORE_INFO_RECEIVED: { name: 'More Information Received', id: 11 },
  QA_ON_HOLD: { name: 'QA on Hold', id: 12 },
  PERMIT_DRAWINGS_ON_HOLD: { name: 'Permit Drawings on Hold', id: 13 },
  STAMPING_PE_REVIEW : { name: 'Submitted for PE Review', id: 14 },

};

export const INSTALLER_STATES = {
  APPLICATION_INCOMPLETE: { name: 'Application Incomplete', id: 0 },
  NEW_APPLICATION_RECEIVED: { name: 'New Application Submitted', id: 1 },
  APPLICATION_UNDER_REVIEW: { name: 'Application Under Progress', id: 3 },
  PERMIT_SET_AVAILABLE: { name: 'Permit Set Available', id: 6 },
  MORE_INFO_REQUESTED: { name: 'More Information Requested', id: 10 },
  MORE_INFO_RECEIVED: { name: 'More Information Submitted', id: 11 },
  REWORK_REQUEST_SUBMITTED: { name: 'Rework Request Submitted', id: 13 },
  REWORK_SUBMITTED: { name: 'Rework Set Available', id: 14 },
  STAMPING_SUBMITTED: { name: 'Stamping Request Submitted', id: 8 },
  STAMPING_SET_AVAILABLE: { name: 'Stamping Set Available', id: 9 },
  APPLICATION_EDITED: { name: 'Application Edited', id: 15 },
};
export const DESIGNER_STATES = [
  APPLICATION_STATES.NEW_APPLICATION_RECEIVED.id,
  APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.id,
  APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id,
];

export const PERMIT_SERVICE_ROLES = {
  ENLIGHTEN_APPLICATION_ADMIN: 'enlighten_application_admin',
  ENLIGHTEN_APPLICATION_USER: 'enlighten_application_user',
  SOLARGRAF_APPLICATION_ADMIN: 'solargraf_application_admin',
  SOLARGRAF_APPLICATION_USER: 'solargraf_application_user',
  APPLICATION_ADMIN: 'application_admin',
  APPLICATION_USER: 'application_user',
  DB_ADMIN: 'db_admin',
  DB_VIEW: 'db_view',
  DB_USER: 'db_user',
  SERVICE_ADMIN: 'service_admin',
  INSTALLER: 'no_access',
};

export const DOWNLOAD_DOCUMENTS_FILE_NAMES = {
  INSTALLLER_COMPANY_LOGO: 'Installer_Company_Logo',
  EXISTING_PERMIT_PACKAGE: 'Existing_Permit_Package',
  TRENCHING_ROUTE_PHOTO: 'Trenching_Route_Photo',
  MARKER_LOCATION: 'Marker_Location',
  EXISTING_OPEN_SUBPANEL_PHOTO: 'Existing_Open_Subpanel_Photo',
  SUB_PANEL_STICKER_PHOTO: 'Sub_Panel_Sticker_Photo',
  AUTOMATIC_TRANSFER_SWITCH: 'Automatic_Transfer_Switch',
  LAYOUT: 'layout',
  ADDITIONAL_PHOTO: 'Additional_Photo',
  ATTIC_PHOTO: 'Attic_Photo',
};

export const MESSAGE_TYPES = {
  RESUBMISSION_REQUEST: 'RESUBMISSION_REQUEST',
  RESUBMISSION_SUBMIT: 'RESUBMISSION_SUBMIT',
};

export const DOWNLOAD_FILE_NAME_FLAG = true;

export const COMMON_STATES = [APPLICATION_STATES.MORE_INFO_REQUESTED.id, APPLICATION_STATES.MORE_INFO_RECEIVED.id];

export const SOURCE = { ENLIGHTEN_PERMIT: 'ENLIGHTEN_PERMIT', SOLARGRAF: 'SOLARGRAF' };

export const METER_TO_INCH_RATIO = 39.3701;
