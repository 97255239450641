import React from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '../../../components/Permitting/images/helpIcon';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#111111',
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(1),
    minWidth: theme.spacing(15),
    maxWidth: 350,
    pointerEvents: 'auto',
    fontWeight: 'normal'
  }
}))(Tooltip);

export const HelpTextIcon = props => {
  const { message, position, btnClassName } = props;
  return (
    <LightTooltip title={message} placement={position}>
      <span className={btnClassName}>
        <HelpIcon />
      </span>
    </LightTooltip>
  );
};

export default HelpTextIcon;
