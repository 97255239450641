import {
  GET_ALL_MODE_MAPPINGS_REQUEST,
  CREATE_MODE_MAPPING_REQUEST,
  CREATE_MODE_MAPPING_SUCCESS,
  GET_MODE_MAPPINGS_REQUEST,
  GET_ALL_STORAGE_CONFIGURATIONS_REQUEST,
  GET_ALL_STORAGE_CONFIGURATIONS_SUCCESS,
  GET_ALL_STORAGE_CONFIGURATIONS_FAILURE,
  CREATE_STORAGE_CONFIGURATION_REQUEST,
  CREATE_STORAGE_CONFIGURATION_SUCCESS,
  CREATE_STORAGE_CONFIGURATION_FAILURE,
  GET_ALL_BATTERY_BACKUPS_REQUEST,
  GET_ALL_BATTERY_BACKUPS_SUCCESS,
  GET_ALL_BATTERY_BACKUPS_FAILURE,
  GET_BATTERY_CONFIGURATION_FILTERS_REQUEST,
  DELETE_MODE_MAPPINGS_REQUEST,
  DELETE_STORAGE_CONFIGURATION_REQUEST,
} from '../constants';

export function getAllModeMappings(payload) {
  return { type: GET_ALL_MODE_MAPPINGS_REQUEST, payload };
}

export function createModeMapping(payload) {
  return { type: CREATE_MODE_MAPPING_REQUEST, payload };
}

export function createModeMappingSuccess(payload) {
  return { type: CREATE_MODE_MAPPING_SUCCESS, payload };
}

export function getModeMapping(payload) {
  return { type: GET_MODE_MAPPINGS_REQUEST, payload };
}

export function deleteModeMapping(payload) {
  return { type: DELETE_MODE_MAPPINGS_REQUEST, payload };
}

export function getAllStorageConfigurations(payload) {
  return { type: GET_ALL_STORAGE_CONFIGURATIONS_REQUEST, payload };
}

export function createStorageConfiguration(payload) {
  return { type: CREATE_STORAGE_CONFIGURATION_REQUEST, payload };
}

export function createStorageConfigurationSuccess(payload) {
  return { type: CREATE_STORAGE_CONFIGURATION_SUCCESS, payload };
}

export function getAllBatteryBackups(payload) {
  return { type: GET_ALL_BATTERY_BACKUPS_REQUEST, payload };
}

export function setBatteryConfigurationFilters(payload) {
  return { type: GET_BATTERY_CONFIGURATION_FILTERS_REQUEST, payload };
}

export function deleteStorageConfiguration(payload) {
  return { type: DELETE_STORAGE_CONFIGURATION_REQUEST, payload };
}