export const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiIconButton-root': {
      borderRadius: 0,
    },
    '& .highcharts-credits': {
      display: 'none',
    },
  },
  globalFilter: {
    margin: theme.spacing(0, 0.5, 0, 0.5),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.primary.dark,
  },
  button: {
    color: theme.palette.primary.main,
    fontSize: 10,
  },
  container: {
    maxHeight: '100vh',
    minHeight: '100vh',
  },
  cellFontWeight: {
    '& .MuiTableCell-body': {
      fontWeight: 700,
    },
  },
  fadedContent: {
    color: theme.palette.primary.lightBlack,
  },
  bolderText: {
    fontWeight: 'bold',
  },
  expiry: {
    color: 'green',
    fontSize: 'x-small',
  },
  acceptButton: {
    color: theme.palette.primary.white,
    textTransform: 'none',
  },
  buttonDiv: {
    paddingBottom: '10px',
    float: 'right',
    paddingRight: 'inherit',
    paddingTop: '10px',
  },
  rejectButton: {
    color: theme.palette.primary.main,
    background: theme.palette.primary.white,
  },
  optInInfo: {
    background: '#eff0f1',
    padding: '10px',
  },
  dHeader: {
    paddingLeft: '15px',
    paddingTop: '15px',
    fontWeight: 'bold',
    color: 'grey',
    textAlign: 'center',
  },
  headerButton: {
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: '16px',
  },
  tableRow: {
    padding: theme.spacing(1.5),
  },
  styleSelect: {
    height: theme.spacing(4.25),
  },
  selectInput: {
    maxWidth: theme.spacing(15),
  },
  tableHeader: {
    backgroundColor: '#335977',
  },
  headerContainer: {
    color: '#FFE785',
    fontSize: 13,
    fontWeight: 'bold',
    position: 'sticky',
    padding: 0,
    border: 'none',
    backgroundColor: '#fff',
  },
  headerText: {
    color: '#FFE785',
    fontSize: 13,
    fontWeight: 'bold',
    backgroundColor: '#335977',
    position: 'sticky',
    display: 'flex',
  },
  searchTableCell: {
    display: 'flex',
    height: theme.spacing(4.25),
    padding: theme.spacing(1.5),
    backgroundColor: '#fff',
  },
  inputField: {
    height: theme.spacing(1.75),
  },
  headerButtonText: {
    height: theme.spacing(4),
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: '16px',
    width: 'max-content',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  installerListText: {
    display: 'inline',
    position: 'relative',
    top: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    fontSize: theme.spacing(2),
    textDecoration: 'underline',
    color: theme.palette.secondary.link,
    fontWeight: 600,
    cursor: 'pointer',
  },
  dateRangeWrapper: {
    boxShadow: 'none',
    padding: '0px !important',
  },
  okButton: {
    color: '#fff',
  },
  styleLegendColorBox: {
    height: theme.spacing(1),
    width: theme.spacing(3),
    backgroundColor: '#000',
  },
  new_application_received: {
    backgroundColor: '#55D8FE',
  },
  site_information_downloaded: {
    backgroundColor: '#FFD401',
  },
  displayOptionText: {
    fontSize: theme.spacing(1.9),
    lineHeight: theme.spacing(0.18),
  },
  displayOptionBox: {
    width: theme.spacing(28),
    height: theme.spacing(5, '!important'),
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap',
      overflow: 'hidden',
      height: theme.spacing(4.4),
    },
  },
  site_information_verified: {
    backgroundColor: '#277DFE',
  },
  permit_drawings_completed: {
    backgroundColor: '#0951BC',
  },
  qa_completed: {
    backgroundColor: '#490601',
  },
  permit_files_uploaded: {
    backgroundColor: '#89BF11',
  },
  permit_files_downloaded: {
    backgroundColor: '#FF8B00',
  },
  rework_request_submitted: {
    backgroundColor: '#AA0C00',
  },
  rework_submitted: {
    backgroundColor: '#119920',
  },
  stamping_request_submitted: {
    backgroundColor: '#2E5528',
  },
  stamping_permit_file_uploaded: {
    backgroundColor: '#CC655A',
  },
  styleLegendContent: {
    marginLeft: theme.spacing(1),
    color: 'grey',
  },
  styleLegendWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  legendWrapper: {
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editIconBox: {
    '& .MuiIconButton-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  fontTableSize: {
    fontSize: '0.875rem',
  },
  popoverName: {
    fontSize: '13px',
    color: '#333333',
    opacity: 1,
  },
  popoverEmail: {
    fontSize: '12px',
    color: '#333333',
    opacity: 0.7,
  },
  assignText: {
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: '#335977',
    opacity: 1,
    paddingTop: theme.spacing(1.875),
    paddingBottom: theme.spacing(2.125),
  },
  close: {
    opacity: 0.23,
    cursor: 'pointer',
    marginTopp: '-4px',
  },
  boldChildren: {
    '& td': {
      fontWeight: 'bold',
    },
  },
  boldContent: {
    fontWeight: 'bold',
  },
  boxPadding: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    width: '206px',
  },
  assignButton: {
    marginTop: theme.spacing(2.375),
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(2),
    textTransform: 'none',
    width: '48px',
    height: '23px',
    background: '#C1C1C1 0% 0% no-repeat padding-box',
    '&:hover': {
      background: '#C1C1C1 0% 0% no-repeat padding-box',
    },
    borderRadius: '2px',
    opacity: 1,
    color: 'white',
  },
  assignButtonBackground: {
    background: '#F37320 0% 0% no-repeat padding-box',
    '&:hover': {
      background: '#F37320 0% 0% no-repeat padding-box',
    },
  },
  warningIcon: {
    fontSize: 18,
    marginRight: theme.spacing(1),
    position: 'relative',
    left: theme.spacing(1),
  },
  switch: {
    marginLeft: 0,
  },
  editIcon: {
    padding: theme.spacing(1),
  },
  selectInputText: {
    '& input': {
      textOverflow: 'unset',
    },
  },
  dialogTitle: {
    color: theme.palette.primary.dark,
    '& h2': {
      fontWeight: 'bold',
      fontSize: theme.spacing(2.5),
    },
  },
  dialogTitleNote: {
    fontSize: theme.spacing(1.5),
    color: theme.palette.primary.subText,
    margin: theme.spacing(-2, 0, 0.5, 3),
  },
  dialogContent: {
    width: theme.spacing(60),
    margin: theme.spacing(0, 3, 0, 3),
  },
  downloadBtn: {
    marginLeft: theme.spacing(1.25),
  },
  dateLabel: {
    width: '25%',
    marginTop: theme.spacing(-1.5),
  },
  dateField: {
    width: '75%',
  },
  dateBox: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  actionBtn: {
    marginRight: theme.spacing(1),
  },
  onStatusDashboard: {
    fontWeight: 400,
    height: theme.spacing(4.5),
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.6),
  },
  copyableText: {
    userSelect: 'auto',
    whiteSpace: 'nowrap',
    maxWidth: theme.spacing(13),
    overflow: 'auto',
  },
  chatBadge: {
    top: '3px',
    minWidth: theme.spacing(22),
  },
  badgeContent: {
    color: 'white',
    borderRadius: '3px',
    top: theme.spacing(-1),
    right: theme.spacing(2),
  },
  slightlyLeft: {
    '& .MuiBadge-badge': {
      right: theme.spacing(1.6),
    },
  },
  adminListDropdown: {
    width: theme.spacing(22),
    backgroundColor: 'unset',
  },
  adminListDropdownMenuItem: {
    padding: theme.spacing(0),
    backgroundColor: 'unset !important',
  },
  adminListDropdownButtons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1.5, 0, 0.5, 1.5),
  },
  adminListCancelButton: {
    textTransform: 'none',
  },
});
