import { isEmpty } from '../../../utils/lodash';
import { SUBPANEL_TAGS } from '../constants';

export const validateSubpanelFields = (
  errors,
  subpanels,
  backupExistingSubpanel,
  partialStorageBackup,
  subpanelExisting,
  fieldRequired
) => {
  if (errors) {
    errors['subpanels'] = [];
    if (subpanels) {
      subpanels.forEach((subpanel, index) => {
        const error = {};
        if (!subpanel.breakerRating) {
          error['breakerRating'] = fieldRequired;
          errors['subpanelsErrors'] = {...fieldRequired, errorKey: 'breakerRating', isExistingSubpanel: false, subpanelIndex: index };
        }
        if (!subpanel.busRating) {
          error['busRating'] = fieldRequired;
          errors['subpanelsErrors'] = {...fieldRequired, errorKey: 'busRating', isExistingSubpanel: false, subpanelIndex: index};
        }
        if (isEmpty(subpanel.existingOpenSubpanelPhoto)) {
          error['existingOpenSubpanelPhoto'] = fieldRequired;
          errors['subpanelsErrors'] = {...fieldRequired, errorKey: SUBPANEL_TAGS.existing_open_subpanel, isExistingSubpanel: false, subpanelIndex: index};
        }
        errors['subpanels'].push(error);
      });
    }
    errors['subpanelExisting'] = {};
    if (backupExistingSubpanel && partialStorageBackup) {
      if (isEmpty(subpanelExisting)) {
        errors['subpanelExisting']['breakerRating'] = fieldRequired;
        errors['subpanelExisting']['busRating'] = fieldRequired;
        errors['subpanelExisting']['existingOpenSubpanelPhoto'] = fieldRequired;
        errors['subpanelsErrors'] = {...fieldRequired, errorKey: 'breakerRating', isExistingSubpanel: true, subpanelIndex: -1 };
      } else {
        if (!subpanelExisting.breakerRating) {
          errors['subpanelExisting']['breakerRating'] = fieldRequired;
          errors['subpanelsErrors'] = {...fieldRequired, errorKey: 'breakerRating', isExistingSubpanel: true, subpanelIndex: -1 };
        }
        if (!subpanelExisting.busRating) {
          errors['subpanelExisting']['busRating'] = fieldRequired;
          errors['subpanelsErrors'] = {...fieldRequired, errorKey: 'busRating', isExistingSubpanel: true, subpanelIndex: -1 };;
        }
        if (isEmpty(subpanelExisting.existingOpenSubpanelPhoto)) {
          errors['subpanelExisting']['existingOpenSubpanelPhoto'] = fieldRequired;
          errors['subpanelsErrors'] = {...fieldRequired, errorKey: SUBPANEL_TAGS.existing_open_subpanel, isExistingSubpanel: true, subpanelIndex: -1 };
        }
      }
    }
  }
};

export const validateSubpanelFieldsByKey = (
  errors,
  subpanels,
  backupExistingSubpanel,
  partialStorageBackup,
  subpanelExisting,
  fieldRequired,
  additionalDetails,
  noErrorObj
) => {
  const { additionalKey = '', subpanelIndex = -1, existingSubpanel = false } = additionalDetails;
  if (!existingSubpanel) {
    if (!isEmpty(errors['subpanels'])) {
      if (!errors['subpanels'][subpanelIndex]) {
        errors['subpanels'][subpanelIndex] = {};
      }
      if (additionalKey === 'breaker_rating') {
        errors['subpanels'][subpanelIndex]['breakerRating'] = subpanels[subpanelIndex].breakerRating
          ? noErrorObj
          : fieldRequired;
      } else if (additionalKey === 'bus_rating') {
        errors['subpanels'][subpanelIndex]['busRating'] = subpanels[subpanelIndex].busRating
          ? noErrorObj
          : fieldRequired;
      } else if (additionalKey === SUBPANEL_TAGS.existing_open_subpanel) {
        errors['subpanels'][subpanelIndex]['existingOpenSubpanelPhoto'] = subpanels[subpanelIndex]
          .existingOpenSubpanelPhoto
          ? noErrorObj
          : fieldRequired;
      }
    } else {
      errors['subpanels'] = [];
      subpanels.forEach((subpanel, index) => {
        errors['subpanels'].push({});
      });
      if (additionalKey === 'breaker_rating') {
        errors['subpanels'][subpanelIndex]['breakerRating'] = subpanels[subpanelIndex].breakerRating
          ? noErrorObj
          : fieldRequired;
      } else if (additionalKey === 'bus_rating') {
        errors['subpanels'][subpanelIndex]['busRating'] = subpanels[subpanelIndex].busRating
          ? noErrorObj
          : fieldRequired;
      } else if (additionalKey === SUBPANEL_TAGS.existing_open_subpanel) {
        errors['subpanels'][subpanelIndex]['existingOpenSubpanelPhoto'] = subpanels[subpanelIndex]
          .existingOpenSubpanelPhoto
          ? noErrorObj
          : fieldRequired;
      }
    }
  } else {
    if (!errors['subpanelExisting']) {
      errors['subpanelExisting'] = {};
    }
    if (isEmpty(subpanelExisting)) {
      errors['subpanelExisting']['breakerRating'] = fieldRequired;
      errors['subpanelExisting']['busRating'] = fieldRequired;
      errors['subpanelExisting']['existingOpenSubpanelPhoto'] = fieldRequired;
    } else {
      if (additionalKey === 'breaker_rating') {
        errors['subpanelExisting']['breakerRating'] = subpanelExisting.breakerRating ? noErrorObj : fieldRequired;
      } else if (additionalKey === 'bus_rating') {
        errors['subpanelExisting']['busRating'] = subpanelExisting.busRating ? noErrorObj : fieldRequired;
      } else if (additionalKey === SUBPANEL_TAGS.existing_open_subpanel) {
        errors['subpanelExisting']['existingOpenSubpanelPhoto'] = subpanelExisting.existingOpenSubpanelPhoto
          ? noErrorObj
          : fieldRequired;
      }
    }
  }
};
