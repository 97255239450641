import React from 'react';

const PDFIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="312.6" height="358.4" viewBox="0 0 312.6 358.4">
      <defs />
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill="#f1f1f2"
            d="M264.1,26.9V331.5a26.91,26.91,0,0,1-26.9,26.9H40.8a26.91,26.91,0,0,1-26.9-26.9V87.1L101,0H237.1A26.87,26.87,0,0,1,264.1,26.9Z"
          />
          <path fill="#d0d2d3" d="M14,87.1,101.1,0V60.4A26.8,26.8,0,0,1,74.3,87.2H14Z" />
          <polygon
            fill="#d0d2d3"
            points="264.1 232.3 264.1 323.6 96.9 323.6 40.5 308.6 14 308.6 14 247.3 40.5 247.3 40.5 247.2 96.9 232.3 264.1 232.3"
          />
          <path
            fill="#e95935"
            d="M291,312.8H96.9V221.5H291a21.64,21.64,0,0,1,21.6,21.6v48.1A21.57,21.57,0,0,1,291,312.8Z"
          />
          <polygon fill="#e94921" points="96.9 312.8 40.5 297.8 40.5 236.4 96.9 221.5 96.9 312.8" />
          <rect fill="#e95935" y="236.5" width="40.5" height="61.4" />
          <polygon fill="#c43e1c" points="0 236.5 14 228.1 14 236.5 0 236.5" />
          <polygon fill="#c43e1c" points="0 297.8 14 306.2 14 297.8 0 297.8" />
          <path
            fill="#fff"
            d="M136.1,297.5V239.1h19.1c2.3,0,4.4.1,6.2.2a30.63,30.63,0,0,1,4.8.7,18.79,18.79,0,0,1,3.8,1.4,16,16,0,0,1,3.2,2.1,18.7,18.7,0,0,1,5.1,6.7,21.31,21.31,0,0,1,1.8,8.8,22.23,22.23,0,0,1-1.7,8.5,18.83,18.83,0,0,1-4.5,6.7,15.88,15.88,0,0,1-6.9,3.9,36,36,0,0,1-10.2,1.1h-6.4v18.3Zm14.3-31.4h6q9.3,0,9.3-6.9c0-2.5-.8-4.2-2.3-5.3s-4.1-1.6-7.6-1.6h-5.4Z"
          />
          <path
            fill="#fff"
            d="M186.7,297.5V239.1h19.6c2.2,0,4.2.1,5.9.2a31.09,31.09,0,0,1,4.7.7,23.43,23.43,0,0,1,4,1.3,26.43,26.43,0,0,1,3.7,2,25,25,0,0,1,7.1,6.7c3.6,5,5.3,11,5.3,18a35.7,35.7,0,0,1-2,12.1,27.2,27.2,0,0,1-5.6,9.3,24.22,24.22,0,0,1-8.7,6,29.53,29.53,0,0,1-11.3,2.1Zm14.9-13.1H208c4.7,0,8.2-1.4,10.6-4.1s3.6-6.6,3.6-11.8-1.2-9.3-3.7-12.1-6-4.2-10.5-4.2h-6.5v32.2Z"
          />
          <path fill="#fff" d="M244.7,297.5V239.1h31.9v13.1H259v9.9h17v13.1H259v22.2H244.7Z" />
        </g>
      </g>
    </svg>
  );
};

export default PDFIcon;
