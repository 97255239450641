import { isEmpty, isEqual } from 'lodash';

const fieldRequired = {
  error: true,
  errorMessage: 'This field is Required'
};

const quantityError = {
  error: true,
  errorMessage: 'The value should be greater than 0'
};

const copyRangeError = {
  error: true,
  errorMessage: 'The value should be greater than 0'
};

const photoUploadError = {
  error: true,
  errorMessage: 'Either of Link or Image is required'
};

export const validateServices = props => {
  const { service, phyStampInputs, photos, attic_images_link } = props;
  const errors = { [service]: {} };

  if (isEqual(service, 'Physical Stamps')) {
    if (!errors[service]) {
      errors[service] = {};
    }
    const requiredFields = [ 'mailingAddr', 'copies' ];
    requiredFields.forEach(field => {
      if (isEqual(field, 'mailingAddr') && isEmpty(phyStampInputs.mailingAddr)) {
        errors[service][field] = fieldRequired;
      } else if (isEqual(field, 'copies') && isNaN(Number(phyStampInputs.copies))) {
        errors[service][field] = quantityError;
      } else if (isEqual(field, 'copies') && Number(phyStampInputs.copies) < 1) {
        errors[service][field] = copyRangeError;
      }
    });
  } else if (isEqual(service, 'Structural Review')) {
    if (!errors[service]) {
      errors[service] = {};
    }
    if (isEmpty(photos) && isEmpty(attic_images_link)) {
      errors[service]['photos'] = photoUploadError;
    }
  }

  return errors;
};
