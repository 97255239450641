import React from 'react';

const DownloadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.201 9.80099C19.059 9.6592 18.8666 9.57955 18.666 9.57955C18.4653 9.57955 18.2729 9.6592 18.131 9.80099L12.905 15.021V1.98399C12.905 1.78322 12.8252 1.59067 12.6833 1.44871C12.5413 1.30674 12.3487 1.22699 12.148 1.22699C12.0486 1.22699 11.9501 1.24657 11.8583 1.28461C11.7664 1.32266 11.683 1.37842 11.6127 1.44871C11.5424 1.519 11.4866 1.60246 11.4486 1.6943C11.4106 1.78614 11.391 1.88458 11.391 1.98399V15.02L6.16498 9.79999C6.02304 9.6582 5.83061 9.57855 5.62998 9.57855C5.42935 9.57855 5.23692 9.6582 5.09498 9.79999C4.95318 9.94193 4.87354 10.1344 4.87354 10.335C4.87354 10.5356 4.95318 10.728 5.09498 10.87L11.614 17.389C11.7564 17.531 11.9494 17.6107 12.1505 17.6107C12.3516 17.6107 12.5445 17.531 12.687 17.389L19.206 10.87C19.3468 10.7274 19.4254 10.5348 19.4244 10.3345C19.4235 10.1341 19.3431 9.94223 19.201 9.80099Z" fill="#4A4A4A" />
      <path d="M19.7361 18.686V20.963H4.55413V18.686C4.55413 18.4847 4.47417 18.2916 4.33183 18.1493C4.18949 18.007 3.99643 17.927 3.79514 17.927C3.59384 17.927 3.40078 18.007 3.25844 18.1493C3.1161 18.2916 3.03613 18.4847 3.03613 18.686V20.963C3.03613 21.1623 3.07539 21.3597 3.15168 21.5439C3.22797 21.7281 3.33978 21.8954 3.48074 22.0364C3.6217 22.1773 3.78905 22.2892 3.97322 22.3655C4.15739 22.4417 4.35478 22.481 4.55413 22.481H19.7361C20.1387 22.481 20.5248 22.3211 20.8095 22.0364C21.0942 21.7517 21.2541 21.3656 21.2541 20.963V18.686C21.2541 18.4847 21.1742 18.2916 21.0318 18.1493C20.8895 18.007 20.6964 17.927 20.4951 17.927C20.2938 17.927 20.1008 18.007 19.9584 18.1493C19.8161 18.2916 19.7361 18.4847 19.7361 18.686Z" fill="#4A4A4A" />
    </svg>
  );
};

export default DownloadIcon;
