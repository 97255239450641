import { Box, Button, Grid, makeStyles, TextField, Typography, IconButton, Dialog, DialogTitle, DialogContent,
  DialogActions, DialogContentText } from '@material-ui/core';
import clsx from 'clsx';
import { get, isEmpty, transform } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  REDUCED_SPACING_OPTIONS
} from '../../../../containers/Admin/constants';
import CloseIcon from '@material-ui/icons/Close';
import { AddCircleOutline } from '@material-ui/icons';
import HelpTextIcon from '../../../common/HelpTextIcon';
import { Label } from '../../../common/Label';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../common/SingleSelectDropdown';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  dataSourceField: {
    marginTop: theme.spacing(6)
  },
  referenceStatusField: {
    margin: theme.spacing(0, 0, 0, 0),
    '& .MuiFormControl-root': {
      width: `calc(100% - ${theme.spacing(1) + 13}px)`,
    },
  },
  referenceFilesField: {
    margin: theme.spacing(9, 1, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  referenceFilesLabel: {
    margin: theme.spacing(1, 0),
  },
  popupCloseBtn: {
    color: '#335977',
    position: 'relative',
    float: 'right',
    minWidth: theme.spacing(2.5),
  },
  fileAttachment: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.75),
    position: 'relative',
    flexDirection: 'row',
    width: '100%',
    boxSizing: 'border-box',
  },
  fileNameIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flex: 1,
    minWidth: 0,
    overflow: 'hidden',
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto',
  },
  iconCoverChat: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
  },
  filesListWrapper: {
    border: `1px solid #bbb`,
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    overflow: 'hidden',
  },
  errorText: {
    fontSize: theme.spacing(1.75),
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0.3),
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btn: {
    textTransform: 'unset !important',
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0),
      marginLeft: 0,
    },
  },
  button: {
    position: 'absolute',
    top: 16,
    right: -16,
    transform: 'translate(50%, 50%)'

  },
  filesWrapper: {
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  fileNameText: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'middle',
  }
}));

const FieldUpdatesSection = props => {
  const classes = useStyles();
  const {
    getErrorText,
    getHelperText,
    handleFieldUpdates,
    fieldUpdates,
    sectionLabel,
    readOnly,
    stateSelected,
    errorData,
    referenceFilesUploadError,
    handleFilesUpload,
    fileIcon,
    deleteFile,
    handleFileDownload,
  } = props;
  const {
    reduced_spacing = '',
    custom_reduced_spacing = '',
    building_fire_department_requirements = '',
    battery_submittal_requirements = '',
    fire_inspection_requirements = '',
    is_nfpa_enforced = '',
    miscellaneous_notes_field_updates = '',
  } = fieldUpdates;

  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);

  useEffect(
    () => {
      setIsCustomSelected(reduced_spacing === 'custom');
    }, [reduced_spacing]
  );

  return (
    <Box className={classes.subRoot}>
      <Dialog open={confirmation} onClose={() => setConfirmation(false)}>
        <DialogTitle className={classes.dialogHeader} disableTypography>
          <Typography variant="h6">Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>On delete, it can’t be retrieved again.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteFile(selectedFileIndex);
              setConfirmation(false);
            }}
            color="primary"
          >
            Ok
          </Button>
          <Button onClick={() => setConfirmation(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CustomPanel
        header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper} style={{justifyContent: 'flex-start'}}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Reduced spacing approved by fire department'} capitalizeLabelText={false} />}
                dropDownOptions={REDUCED_SPACING_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleFieldUpdates}
                fieldId="reduced_spacing"
                fieldValue={reduced_spacing}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            {isCustomSelected && (
              <Box className={`${classes.addressWrapper}`}>
                <TextField
                  id="custom_reduced_spacing"
                  className={classes.inputField}
                  label={<Label text={'Custom'} capitalizeLabelText={false} isRequired />}
                  value={custom_reduced_spacing}
                  onChange={e => handleFieldUpdates(e, 'custom_reduced_spacing')}
                  disabled={readOnly || !stateSelected}
                  error={!isEmpty(get(errorData, 'custom_reduced_spacing'))}
                  helperText={get(errorData, 'custom_reduced_spacing')}
                />
              </Box>
            )}
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="building_fire_department_requirements" className={classes.addressWrapper} position="relative" >
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Building/fire department requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={building_fire_department_requirements}
                onChange={e => handleFieldUpdates(e, 'building_fire_department_requirements')}
              />
              <HelpTextIcon
                  message="The building department's requirements for solar + ESS permit submittal (Structural document, PE stamp, etc.)"
                  position="top"
                  btnClassName={clsx(classes.button, classes.btn)}
                />
            </Box>
            <Box id="battery_submittal_requirements" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Requirements for battery submittals"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={battery_submittal_requirements}
                onChange={e => handleFieldUpdates(e, 'battery_submittal_requirements')}
              />
            </Box>
            <Box id="fire_inspection_requirements" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Fire department inspection requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={fire_inspection_requirements}
                onChange={e => handleFieldUpdates(e, 'fire_inspection_requirements')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="is_nfpa_enforced" className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Is NFPA 855 adopted or enforced?"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={is_nfpa_enforced}
                onChange={e => handleFieldUpdates(e, 'is_nfpa_enforced')}
              />
            </Box>
            <Box id="miscellaneous_notes_field_updates" className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Miscellaneous requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={miscellaneous_notes_field_updates}
                onChange={e => handleFieldUpdates(e, 'miscellaneous_notes_field_updates')}
              />
            </Box>
            <Box className={`${classes.addressWrapper}`}>
            <Box id="FAE_reference_files" className={`${classes.referenceFilesField}`}>
              <Label capitalizeLabelText={false} text="FAE reference files" className={classes.referenceFilesLabel} />
              <Button
                color="primary"
                component="label"
                disabled={!stateSelected || readOnly}
                className={classes.btn}
              >
                <AddCircleOutline />
                <input
                  type="file"
                  style={{ display: 'none' }}
                  id="FAE_reference_files"
                  name="FAE_reference_files"
                  onChange={(e) => handleFilesUpload(e)}
                  multiple
                />
                Add document
              </Button>
            </Box>
            <Grid className={classes.filesWrapper}>
              {fieldUpdates.field_updates_reference_files !== null && fieldUpdates.field_updates_reference_files.length > 0 && (
                <Box className={classes.filesListWrapper}>
                  {fieldUpdates.field_updates_reference_files
                    .slice()
                    .reverse()
                    .map((file, i) => (
                      <Box
                        key={file.display_file_name + i}
                        id={file.display_file_name}
                        className={classes.fileAttachment}
                      >
                        <Box className={classes.fileNameIconWrapper} onClick={() => handleFileDownload(file)}>
                          <IconButton className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}>
                            {fileIcon(file.file_name)}
                          </IconButton>
                          <Box>
                            <Typography className={classes.fileNameText}>{file.display_file_name}</Typography>
                            <Typography variant="caption">{file.file_size}</Typography>
                          </Box>
                        </Box>
                        {!(!stateSelected || readOnly) && (
                          <IconButton className={classes.popupCloseBtn}>
                            <CloseIcon
                              onClick={() => {
                                setSelectedFileIndex(i);
                                setConfirmation(true);
                              }}
                              className={classes.popupCloseIcon}
                            />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                </Box>
              )}
              {/* for displaying the error */}
              {referenceFilesUploadError.msg !== '' && (
                <Box>
                  <Typography className={classes.errorText}>{referenceFilesUploadError.msg}</Typography>
                </Box>
              )}
            </Grid>
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default FieldUpdatesSection;