import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import queryString from 'query-string';
import { constructQueryString, forwardTo, setToken } from '../../utils/helper';
import { setMobileView, setEmailId, setEnlightenManagerToken } from './actions';
import { isEmpty } from '../../utils/lodash';
import { getValue, setValue } from '../../utils/localStorage';

const Layout = ({ children, isMobileView, loading, matchProps, ...rest }) => {
  return <Fragment>{children}</Fragment>;
};

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    const { search, pathname } = props.location;
    const { token, origin, status, payment_for } = queryString.parse(search);

    const queryParams = constructQueryString({ origin, status, payment_for }, true);
    // it is removing query params from the url
    // dont want to remove origin, status, payment_for coming from store
    if (token && pathname) {
      setToken(token);
      console.log('token=', token)
      console.log('pathname=', pathname)
      console.log('queryParams=', queryParams)
      forwardTo(pathname + queryParams);
    }
    if (token) {
      console.log('token=', token)
      if (!getValue('referrer')) {
        setValue('referrer', document.referrer);
      }
      console.log('referrer=', document.referrer)
      this.props.setEnlightenManagerToken(token);
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const isMobileView = window.innerWidth <= 1200;
    this.props.setMobileView(isMobileView);
  };

  componentDidMount = () => {
    this.handleWindowSizeChange();
  };

  render() {
    const { component: Component, isMobileView, loading, emailId, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout isMobileView={isMobileView} loading={loading} matchProps={matchProps}>
            <Component {...matchProps} emailId={emailId} />
          </Layout>
        )}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setMobileView: isMobileView => dispatch(setMobileView(isMobileView)),
  setEmailId: emailId => dispatch(setEmailId(emailId)),
  setEnlightenManagerToken: tokenVal => dispatch(setEnlightenManagerToken(tokenVal))
});

const mapStateToProps = state => ({
  isMobileView: state.appReducer.isMobileView,
  loading: state.appReducer.loading,
  enlightenManagerToken: state.appReducer.enlightenManagerToken
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
