import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import HelpTextIcon from '../../../../common/HelpTextIcon';
import { Label } from '../../../../common/Label';
import CustomPanel from '../../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../../common/SingleSelectDropdown';
import { PAGE_SIZE_OPTIONS, YES_NO_OPTIONS } from '../constants';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
  selectLabel: {
    whiteSpace: 'normal',
    transform: 'translate(0, 1px) scale(1)',
  },
  iconWrapper: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}));

const GeneralSection = (props) => {
  const classes = useStyles();
  const {
    getErrorText,
    getHelperText,
    handleGeneralDesignRequirements,
    generalDesignRequirements,
    sectionLabel,
    readOnly,
    stateSelected,
    errorData,
  } = props;
  const {
    page_size = '',
    designer_signature_required = '',
    contractor_signature_required = '',
    home_owner_signature_required = '',
    zoning_required = '',
    additional_placards_required = '',
    additional_placards_requirements = '',
    additional_requirements_general = '',
  } = generalDesignRequirements;

  const [initialZoningRequired, setInitialZoningRequired] = useState(null);

  useEffect(() => {
    const savedOption = YES_NO_OPTIONS.find((option) => option.value === zoning_required);
    setInitialZoningRequired(savedOption);
  }, [zoning_required]);

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Page size'} capitalizeLabelText={false} />}
                dropDownOptions={PAGE_SIZE_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleGeneralDesignRequirements}
                fieldId="page_size"
                fieldValue={page_size ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Designer signature required'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleGeneralDesignRequirements}
                fieldId="designer_signature_required"
                fieldValue={designer_signature_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Contractor signature required'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleGeneralDesignRequirements}
                fieldId="contractor_signature_required"
                fieldValue={contractor_signature_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Homeowner signature required'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleGeneralDesignRequirements}
                fieldId="home_owner_signature_required"
                fieldValue={home_owner_signature_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                className={classes.inputField}
                id="zoning_required"
                disableClearable={true}
                getOptionLabel={(option) => get(option, 'title')}
                getOptionSelected={(option, value) => get(option, 'title') === value}
                options={YES_NO_OPTIONS}
                value={initialZoningRequired ?? ''}
                disabled={readOnly || !stateSelected}
                onChange={(e, value) => handleGeneralDesignRequirements(e, 'zoning_required', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    error={!isEmpty(get(errorData, 'zoning_required'))}
                    helperText={get(errorData, 'zoning_required')}
                    InputLabelProps={{ style: { pointerEvents: 'auto', zIndex: '1' } }}
                    label={
                      <Box className={classes.inputWrapper}>
                        <Label text={'Zoning required'} capitalizeLabelText={false} />
                        <HelpTextIcon
                          message={
                            'Display no. of storeys, type of construction and occupancy group details on cover page (PV-1) of permit plan set'
                          }
                          position="top"
                          btnClassName={classes.iconWrapper}
                        />
                      </Box>
                    }
                  />
                )}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Additional placards required'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleGeneralDesignRequirements}
                fieldId="additional_placards_required"
                fieldValue={additional_placards_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Additional placards requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={additional_placards_requirements ?? ''}
                onChange={(e) => handleGeneralDesignRequirements(e, 'additional_placards_requirements')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Additional requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={additional_requirements_general ?? ''}
                onChange={(e) => handleGeneralDesignRequirements(e, 'additional_requirements_general')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default GeneralSection;
