import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Typography } from '@material-ui/core';
export const MultipleSelectAutoComplete = props => {
  const {
    selectedValue,
    options = [],
    displayOptionTextStyle,
    onChangeFunction,
    displayOptionBox,
    displayOptionText,
    multiple = true
  } = props;
  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        return (
          <Typography className={displayOptionText}>
            {value.slice(0, 1).map(option => option.substring(0, 4)).join(', ')}
            {numTags > 1 && ` +${numTags - 1} more`}
          </Typography>
        );
      }}
      value={selectedValue}
      onChange={(e, value) => onChangeFunction(e, value)}
      renderInput={params => (
        <TextField className={displayOptionBox} {...params} label={''} variant="outlined" fullWidth />
      )}
    />
  );
};

export default MultipleSelectAutoComplete;
