import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Link
  } from '@material-ui/core';
import { STORE_REDIRECTION_MESSAGE } from '../../../containers/Permitting/constants';

const useStyles = makeStyles(theme => ({
    dialogHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    learnMoreLink: {
        color: '#008EEF',
        marginTop: theme.spacing(0.3),
        marginLeft: theme.spacing(0.5)
    }
}));
const ConfirmationDialog = (props) => {
    const classes = useStyles();
    const {
        open,
        handleClose = () => {},
        dialogText = STORE_REDIRECTION_MESSAGE,
        withTitle = true,
        withButtons = false,
        handleProceed = () => {},
        withLink = false,
        learnMoreLink = ''
    } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {withTitle &&
                <DialogTitle className={classes.dialogHeader} disableTypography>
                    <Typography variant="h6">Confirmation</Typography>
                </DialogTitle>
            }
            <Fragment>
            <DialogContent>
                <DialogContentText>
                    {dialogText}
                    {withLink &&
                        <Link target="__blank" href={learnMoreLink} className={classes.learnMoreLink}>
                            Learn More
                        </Link>
                    }
                </DialogContentText>
            </DialogContent>
            </Fragment>
            {withButtons &&
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={handleProceed} color="primary">
                        Yes
                    </Button>
              </DialogActions>
            }
        </Dialog>
    );
}

export default ConfirmationDialog;
