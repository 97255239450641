import React, { createRef, useState, useLayoutEffect, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Grid,
  Switch,
  FormControlLabel,
  Checkbox,
  ListItemSecondaryAction,
  IconButton,
  List,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import ContainedButton from '../../common/ContainedButton';
import { CORRECTION_ISSUES_TYPE, ER_ISSUES_TYPE, FILE_UPLOAD_SIZE_LIMIT } from '../../../containers/Admin/constants';
import { clone, get, isEmpty } from 'lodash';
import { addDocs, getIconPath } from '../../../containers/Admin/helper';
import AssignPopup from '../StatusDashboard/AssignPopup';
import { useStyles } from './styles';
import { SystemUpdateAlt } from '@material-ui/icons';
import { downloadFile, getFile } from '../../../utils/helper';
import moment from 'moment';
import OutlinedButton from '../../common/OutlinedButton';
import { APPLICATION_STATES } from '../../../utils/constants';

let filesData = [];
function FileUploadDialog({
  fireUpload,
  fileDialogInfo,
  open,
  setOpen,
  acceptableFileFormat,
  appDetails,
  fileUploadInProgress,
  reviewersList,
  getPresingedURL,
  emailId,
  firstName,
  lastName,
  getExternalRevisionDetails,
  uploadMultipleFiles
}) {
  const classes = useStyles();

  const fileRef = createRef();
  const dropRef = createRef();
  const [filesName, setFilesName] = useState([]);
  const [comment, setComment] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [fileSelected, setFileSelected] = useState(false);
  const [approvalDocs, setApprovalDocs] = useState([]);
  const [revisionFiles, setRevisionFiles] = useState([]);
  const [filesStamped, setFilesStamped] = useState(true);
  const [fileDownloadInProgress, setFileDownloadInProgress] = useState(false);
  const [commentRequired, setCommentRequired] = useState(false);
  const [deviationRequired, setDeviationRequired] = useState(false);
  const [revisionDetailsRequired, setRevisionDetailsRequired] = useState(false);
  const [erReason, setErReason] = useState('');
  const [erComment, setErComment] = useState('');
  const [deviationState, setDeviationState] = useState('');
  const [kickbackDetails, setKickbackDetails] = useState({
    disconnects: { value: false, comment: '' },
    proof_description: { value: false, comment: '' },
    setbacks: { value: false, comment: '' },
    bill_of_materials: { value: false, comment: '' },
    attachment_detail: { value: false, comment: '' },
    tax_placement: { value: false, comment: '' },
    equipment_elevation: { value: false, comment: '' },
    spec_sheets: { value: false, comment: '' },
    others: { value: false, comment: '' },
    files: []
  });
  const [erDetails, setErDetails] = useState({
    homeowner: { enphase_fault: false, comment: '' },
    installer: { enphase_fault: false, comment: '' },
    ahj: { enphase_fault: false, comment: '' },
    utility: { enphase_fault: false, comment: '' },
    homeowner_association: { enphase_fault: false, comment: '' },
    tax_placement: { enphase_fault: false, comment: '' },
    others: { enphase_fault: false, comment: '' },
    files: []
  });

  const selectFile = event => {
    if (event.target.files) {
      setFileSelected(true);
      filesData = uploadMultipleFiles ? filesData.concat(Array.from(event.target.files)) : Array.from(event.target.files).slice(0, 1);
      const names = [];
      filesData.forEach(fileData => {
        names.push(fileData.name);
      })
      setFilesName(names);
    }
  };

  const isRevisionDetailsValid = () => {
    const validDetail = Object.values(fileDialogInfo.correctionDialog ? kickbackDetails : erDetails).find(detail => !isEmpty(detail.comment));
    if (validDetail) {
      return true;
    }
    return false;
  }

  const addFile = (designer, reviewer, isExternalKickback) => {
    const isDeviationRequired = fileDialogInfo.finalStateId === APPLICATION_STATES.QA_COMPLETED.id;
    if (!fileDialogInfo.approvalDialog) {
      if(!deviationState && isDeviationRequired){
        setDeviationRequired(true);
        return;
      }
      if ((fileDialogInfo.resubmissionDialog || (deviationState === 'true' && isDeviationRequired)) && isEmpty(comment)) {
        setCommentRequired(true);
        return;
      }
      if ((fileDialogInfo.correctionDialog || fileDialogInfo.revisionDialog) && !isRevisionDetailsValid()) {
        setRevisionDetailsRequired(true);
        return;
      }
      if (!(fileDialogInfo.resubmissionDialog || fileDialogInfo.revisionUpdateDialog || fileDialogInfo.sendForVerificationDialog || fileDialogInfo.correctionDialog || fileDialogInfo.revisionDialog) && (!filesData || filesData.length === 0)) {
        setErrMsg(`Please select file to continue...`);
        return;
      }
      let fileSize = 0;
      filesData && filesData.forEach(fileData => {
        fileSize = fileSize + fileData.size / 1024 / 1024;
      });
      if (fileSize > FILE_UPLOAD_SIZE_LIMIT) {
        setErrMsg(`File size is greater that ${FILE_UPLOAD_SIZE_LIMIT}MB...`);
        return;
      }
    }
    fireUpload(filesData, comment, isExternalKickback ? erDetails : kickbackDetails, designer, reviewer, filesStamped, 1, deviationState);
  };

  useLayoutEffect(
    () => {
      setTimeout(() => {
        const div = dropRef.current;
        if (div) {
          if (open) {
            div.addEventListener('dragenter', handleDrag);
            div.addEventListener('dragleave', handleDrag);
            div.addEventListener('dragover', handleDrag);
            div.addEventListener('drop', handleDrop);
          } else {
            div.removeEventListener('dragenter', handleDrag);
            div.removeEventListener('dragleave', handleDrag);
            div.removeEventListener('dragover', handleDrag);
            div.removeEventListener('drop', handleDrop);
          }
        }
      }, 1);
    },
    [dropRef, open]
  );

  useEffect(() => {
    if (fileDialogInfo.revisionDialog && appDetails?.version?.current_version && appDetails?.permit_id) {
      getExternalRevisionDetails({
        permitId: appDetails?.permit_id,
        version: appDetails?.version?.current_version,
        successCbk: (result) => {
          setErReason(result?.kickback_reason);
          setErComment(result?.comment);
        }
      });
    }
  }, [fileDialogInfo]);


  useEffect(() => {
    if (!isEmpty(appDetails)) {
      let docs = [];
      const currentVersion = appDetails?.version?.current_version;
      const adminDocs = (get(appDetails, 'admin_documents', []) || []).filter(doc => doc.versions?.includes(currentVersion));
      addDocs(adminDocs, docs, 'admin_documents', true);
      setApprovalDocs(docs);
      docs = [];
      addDocs((appDetails?.installer_documents || []).filter(doc => doc.versions && doc.versions.includes(currentVersion)), docs, 'revision_files', false);
      setRevisionFiles(docs);
    }
  }, [appDetails]);

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      filesData = uploadMultipleFiles ? filesData.concat(Array.from(e.dataTransfer.files)) : Array.from(e.dataTransfer.files).slice(0, 1);
      const names = [];
      filesData.forEach(fileData => {
        names.push(fileData.name);
      })
      setFilesName(names);
      setFileSelected(true);
    }
  };

  const close = () => {
    setErrMsg('');
    setFilesName([]);
    setComment('');
    filesData = [];
    setOpen(false);
    setFileSelected(false);
    fileDialogInfo.finalStateId = '';
    setDeviationState(''); 
    setDeviationRequired(false);
    setCommentRequired(false);
  };

  const removeFile = (ind) => {
    filesData.splice(ind, 1);
    const names = clone(filesName);
    names.splice(ind, 1);
    setFilesName(names);
    // reset the value of the fileRef so that same file can be re uploaded
    fileRef.current.value = '';
    if (filesData.length === 0) {
      setFileSelected(false);
    }
  };

  const handleCommentsChange = (e) => {
    setComment(e.target.value);
    setCommentRequired(false);
  };

  const onSwitchChange = (e, key, isExternalRevision) => {
    if (isExternalRevision) {
      setErDetails({
        ...erDetails,
        [key]: { enphase_fault: e.target.checked, comment: erDetails[key]?.comment }
      })
    } else {
      setKickbackDetails({
        ...kickbackDetails,
        [key]: { value: e.target.checked, comment: kickbackDetails[key]?.comment }
      });
    }
    setRevisionDetailsRequired(false);
  };

  const onCommentChange = (e, key, isExternalRevision) => {
    if (isExternalRevision) {
      setErDetails({
        ...erDetails,
        [key]: { enphase_fault: erDetails[key]?.enphase_fault, comment: e.target.value }
      });
    } else {
      setKickbackDetails({
        ...kickbackDetails,
        [key]: { value: kickbackDetails[key]?.value, comment: e.target.value }
      });
    }
    setRevisionDetailsRequired(false);
  }

  const assignButtonCall = (designer, reviewer) => {
    addFile(designer, reviewer, true);
  }

  const inputProps = { accept: acceptableFileFormat };
  const currentStateId = appDetails?.third_party_current_status?.state_id;

  const downloadFiles = async (doc, isApprovalDoc) => {
    const aDocs = isApprovalDoc ? clone(approvalDocs) : clone(revisionFiles);
    let idxOfDownloadingDoc = 0;
    for (let i = 0; i < aDocs.length; i++) {
      if (aDocs[i].id === doc.id) {
        aDocs[i].downloadInProgress = true;
        idxOfDownloadingDoc = i;
      }
    }
    if (isApprovalDoc) {
      setApprovalDocs(aDocs);
    } else {
      setRevisionFiles(aDocs);
    }
    setFileDownloadInProgress(true);
    const fName = doc.file_name;
    const fUrl = await getFile(fName, getPresingedURL);
    downloadFile(fUrl, fName);
    aDocs[idxOfDownloadingDoc].downloadInProgress = false;
    if (isApprovalDoc) {
      setApprovalDocs(aDocs);
    } else {
      setRevisionFiles(aDocs);
    }
    setFileDownloadInProgress(false);
  }

  const FilesSection = ({ docs, isApprovalDoc }) => {
    return (
      <List>
        {docs && docs.map((doc, idx) => {
          return (
            <ListItem key={idx} title={doc.name} className={classes.listItem}>
              <ListItemAvatar>
                <Avatar src={getIconPath(doc)} variant="square" />
              </ListItemAvatar>
              <ListItemText
                primary={doc.name}
                classes={{ primary: classes.listItemText }}
                secondary={doc.date && `Uploaded ${doc.user_info ? 'by ' + doc.user_info.name : ''} on ${moment(doc.date).format('DD MMM YYYY')}`}
                secondaryTypographyProps={{ style: { fontSize: 12 } }}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="download"
                  onClick={() => downloadFiles(doc, isApprovalDoc)}
                  disabled={doc.downloadInProgress || fileDownloadInProgress}
                >
                  {doc.downloadInProgress === true ? (
                    <CircularProgress size={30} />
                  ) : (
                    <SystemUpdateAlt className={classes.fileButton} />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const onChangeOfDeviation = (value) => {
    setDeviationState(value);
    setDeviationRequired(false);
    if(value === 'false') {
      setCommentRequired(false);
    }
  };

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableBackdropClick
    >
      <DialogTitle id="customized-dialog-title">
        <Typography className={classes.header}>
          {fileDialogInfo.title}
          <CloseIcon onClick={close} className={classes.close} size="large" />
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {fileDialogInfo.approvalDialog && (
          <Fragment>
            <Typography>
              We will share the documents with Installer on approval. If you want to modify and upload a new permit plan set,
              then please go back to the previous screen and Click on <b>Upload New Document and Send to Installer</b>.
            </Typography><br />
            <Typography>
              <b>I approve the following Permit Plan Set.</b>
            </Typography>
            <FilesSection docs={approvalDocs} isApprovalDoc />
          </Fragment>
        )}
        {fileDialogInfo.correctionDialog && (
          <Box>
            <Typography>What are the issues?</Typography><br />
            <Box className={classes.displayFlex}>
              <Grid md={4}>Issue</Grid>
              <Grid md={2}>Yes/No</Grid>
              <Grid md={6}>Comment</Grid>
            </Box>
            <br /><Divider /><br />
            {CORRECTION_ISSUES_TYPE.map(type => {
              return (
                <Box className={classes.typeRow}>
                  <Grid md={4}>{type.name}</Grid>
                  <Grid md={2}>
                    <Switch onChange={e => onSwitchChange(e, type.key)} color="primary" />
                  </Grid>
                  <Grid md={6}>
                    <TextField
                      onChange={e => onCommentChange(e, type.key)}
                      placeholder="Type your comments..."
                      variant="outlined"
                      size="small"
                      disabled={!kickbackDetails[type.key]?.value}
                    />
                  </Grid>
                </Box>
              );
            })}
            {revisionDetailsRequired && (
              <Typography className={classes.err} gutterBottom>
                {'At least one issue is required'}
              </Typography>
            )}
            <br /><Divider /><br />
          </Box>
        )}
        {(fileDialogInfo.revisionDialog || fileDialogInfo.revisionUpdateDialog) &&  (
          <Box>
            <Typography><b>Installer Revision Details</b></Typography><br />
            <Typography variant="body2">Who has requested revision? <span><b>{erReason}</b></span></Typography><br />
            <Typography variant="body2" color="textSecondary">Revision Comment</Typography>
            <Typography variant="body2">{erComment}</Typography><br />
            <Typography><b>Uploaded File</b></Typography>
            <FilesSection docs={revisionFiles} />
            <br />
            <Typography><b>Admin Revision Details</b></Typography><br />
            <Box className={classes.displayFlex}>
              <Grid md={4}><Typography variant="body2" color="textSecondary">Entity Requesting Revision</Typography></Grid>
              <Grid md={5}><Typography variant="body2" color="textSecondary">Revision Comment</Typography></Grid>
              <Grid md={3}><Typography variant="body2" color="textSecondary">Enphase Fault</Typography></Grid>
            </Box>
            <br /><Divider /><br />
            {ER_ISSUES_TYPE.map(type => {
              return (
                <Box className={classes.typeRow}>
                  <Grid md={4}>{type.name}</Grid>
                  <Grid md={5}>
                    <TextField
                      onChange={e => onCommentChange(e, type.key, true)}
                      placeholder="Type your comments..."
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid md={3}>
                    <Switch onChange={e => onSwitchChange(e, type.key, true)} color="primary" />
                  </Grid>
                </Box>
              );
            })}
            {revisionDetailsRequired && (
              <Typography className={classes.err} gutterBottom>
                {'At least one issue is required'}
              </Typography>
            )}
            <br /><Divider /><br />
          </Box>
        )}
        {!fileDialogInfo.approvalDialog && (
          <div
            className={clsx(classes.dragArea, fileDialogInfo.correctionDialog || fileDialogInfo.revisionDialog && classes.smallArea)}
            onDrop={handleDrop}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
          >
            <ContainedButton disabled={fileUploadInProgress} text="Select Files" handleClick={() => fileRef.current?.click()} />
            <input disabled={fileUploadInProgress} type="file" onChange={selectFile} ref={fileRef} className={classes.input} {...inputProps} multiple={uploadMultipleFiles} /><br />
            <div className={classes.selectedFilesWrapper}>
              {filesName && filesName.map((fileName, ind) => {
                return (
                  <div key={`file-admin-${ind}`} className={classes.selectedFileWrapper}>
                    <Typography color="textSecondary" variant="caption" gutterBottom>
                      {fileName}
                      <CloseIcon onClick={() => removeFile(ind)} className={classes.removeFile} />
                    </Typography>
                  </div>
                )
              })}
            </div>
            {!fileSelected && errMsg && (
              <Typography className={classes.err} gutterBottom>
                {errMsg}
              </Typography>
            )}
            {fileUploadInProgress && <CircularProgress size={20} />}
            <Typography gutterBottom>or drop file here to upload</Typography>
            <Typography color="textSecondary" variant="caption">
              Maximum upload file size :{FILE_UPLOAD_SIZE_LIMIT}MB
            </Typography>
          </div>
        )}
        {fileDialogInfo.revisionDialog && (
          <AssignPopup
            classes={classes}
            assignButtonCall={assignButtonCall}
            reviewersList={reviewersList}
            buttonText={`Submit and Assign to Designer`}
            emailId={emailId}
            firstName={firstName}
            lastName={lastName}
            currentStateId={currentStateId}
            appDetails={appDetails}
            externalRevision
          />
        )}
        {!fileDialogInfo.correctionDialog && !fileDialogInfo.revisionDialog && (
          <Box>
            <Divider /><br />
            {fileDialogInfo.finalStateId === APPLICATION_STATES.QA_COMPLETED.id && !fileDialogInfo.approvalDialog &&
             (
              <div>
                  <Typography className={classes.commentText}>
                    {`Are there any deviations in the permit package based on the information provided in the input form. If yes, please mention it *`}
                  </Typography>
                  <RadioGroup
                    row
                    value={deviationState}
                    required
                    onChange={e => {onChangeOfDeviation(e.target.value)}}
                    >
                    <FormControlLabel value={'true'} control={<Radio color="primary"/>} label="Yes" />
                    <FormControlLabel value={'false'} control={<Radio color="primary"/>} label="No" />
                  </RadioGroup>
                  {deviationRequired && <Typography className={classes.err}>Please select an option</Typography>}
              </div>
             )}
            <br />
            {deviationState === 'true' ? (
              <Typography className={classes.deviationCommentText}>
                {`Please mention the deviation(s) in the comment box before submitting the package`}
              </Typography>
            ): (
              <Typography className={classes.commentText}>
                {`If you would like to share specific comments please enter them below`}
              </Typography>
            )}
            <TextField
              value={comment}
              onChange={(e) => handleCommentsChange(e)}
              multiline
              rows={4}
              rowsMax={16}
              fullWidth
              variant="outlined"
              className={classes.commentField}
              placeholder="Type your comments..."
              error={commentRequired}
              helperText={commentRequired && 'Please provide the comments'}
            />
            {fileDialogInfo.stampingDialog && <FormControlLabel control={<Checkbox color="primary" defaultChecked onChange={() => setFilesStamped(!filesStamped)} />} label="Permit Package Stamped" />}
          </Box>
        )}
        {!fileDialogInfo.revisionDialog && !fileDialogInfo.revisionUpdateDialog &&(
          <Box className={classes.displayFlex}>
            <ContainedButton
              text={fileDialogInfo.buttonText}
              handleClick={addFile}
              disabled={fileUploadInProgress}
            />
          </Box>
        )}
        {fileDialogInfo.revisionUpdateDialog && (

          <Box className={classes.displayFlex}>
            <Grid md={2}>
              <ContainedButton
                text={fileDialogInfo.buttonText}
                handleClick={assignButtonCall}
                disabled={fileUploadInProgress}
              />
            </Grid>
            <Grid md={1}></Grid>
            <Grid md={2}>
                <OutlinedButton
                  text={`Close`}
                  handleClick={close}
                />
              </Grid>
        </Box>

        )}

      </DialogContent>
    </Dialog>
  );
}

export default FileUploadDialog;
