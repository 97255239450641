import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Breadcrumbs, Link } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HistoryIcon from '@material-ui/icons/History';
import { forwardTo } from '../../utils/helper';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  breadcrumb: {
    padding: theme.spacing(1, 4.5, 1)
  },
  link: {
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  }
});

export class Breadcrubs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { bData: [], currentLocation: {} };
  }

  componentDidMount() {
    this.updateBData(window.location.pathname);
  }
  componentDidUpdate(nextProps) {
    if (nextProps !== this.props) {
      this.updateBData(window.location.pathname);
    }
  }

  updateBData(pathName) {
    const { classes } = this.props;
    let pArray = pathName.split('/');
    let bData = [];
    let cLocation = {};
    if (pArray[pArray.length - 2] === 'request') {
      //request details path
      bData.push({
        id: 'dashboard',
        label: 'Permitting Dashboard',
        link: '/permitting_dashboard',
        icon: <HomeIcon className={classes.icon} />
      });
      cLocation = {
        id: 'request_details',
        label: 'Application Details',
        icon: <MenuBookIcon className={classes.icon} />
      };
    } else if (pArray[pArray.length - 1] === 'msg_history') {
      bData.push({
        id: 'dashboard',
        label: 'Permitting Dashboard',
        link: '/permitting_dashboard',
        icon: <HomeIcon className={classes.icon} />
      });
      bData.push({
        id: 'request_details',
        label: 'Application Details',
        link: `/request/${pArray[pArray.length - 2]}`,
        icon: <MenuBookIcon className={classes.icon} />
      });
      cLocation = {
        id: 'msg_history',
        label: 'Message History',
        icon: <HistoryIcon className={classes.icon} />
      };
    } else {
      //do nothing
    }
    this.setState({ bData: bData, currentLocation: cLocation });
  }

  render() {
    const { classes } = this.props;
    const { bData, currentLocation } = this.state;
    return (
      <Fragment>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
          {bData.length > 0 &&
            bData.map(b => (
              <Link key={b.id} color="inherit" onClick={() => forwardTo(b.link)} className={classes.link}>
                {b.icon}
                {b.label}
              </Link>
            ))}
          {bData.length > 0 && (
            <Typography color="textPrimary" className={classes.link}>
              {currentLocation.icon}
              {currentLocation.label}
            </Typography>
          )}
        </Breadcrumbs>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Breadcrubs));
