import { Box, Grid, TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { preventDefault } from '../../../../../containers/Admin/helper';
import HelpTextIcon from '../../../../common/HelpTextIcon';
import { Label } from '../../../../common/Label';
import { MultipleSelectDropdown } from '../../../../common/MultipleSelectDropdown';
import CustomPanel from '../../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../../common/SingleSelectDropdown';
import {
  AC_DISCONNECT_OPTIONS,
  CONDUIT_TYPE_OPTIONS,
  EQUIPMENT_ELEVATION_OPTIONS,
  INTERCONNECTION_METHODS_ALLOWED,
  MIN_EGC_CONDUCTOR_OPTIONS,
  PANEL_SCHEDULE_OPTIONS,
  YES_NO_OPTIONS,
} from '../constants';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
  iconWrapper: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  longLabel: {
    transform: 'translate(0, -20px)',
  },
}));

const ElectricalDesignRequirementsSection = (props) => {
  const classes = useStyles();
  const {
    getErrorText,
    getHelperText,
    handleElectricalDesignRequirements,
    electricalDesignRequirements,
    sectionLabel,
    readOnly,
    stateSelected,
    errorData,
  } = props;
  const {
    clearance_around_main_panel_and_utility_meter = '',
    min_egc_conductor = '',
    conduit_type = '',
    interconnection_methods_allowed = [],
    three_line_diagram = '',
    ac_disconnect_required = '',
    equipment_elevation_required = '',
    panel_schedule_required = '',
    load_calculation_required = '',
    load_calculation_required_for_battery_backup = '',
    load_calculation_required_for_msp_upgrade = '',
    load_calculation_required_for_derating_existing_main_breaker_or_protected_load_tap = '',
    additional_requirements_electrical = '',
  } = electricalDesignRequirements;

  const [initialPanelScheduleOption, setInitialPanelScheduleOption] = useState(null);

  useEffect(() => {
    const savedOption = PANEL_SCHEDULE_OPTIONS.find((option) => option.value === panel_schedule_required);
    setInitialPanelScheduleOption(savedOption);
  }, [panel_schedule_required]);

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id="clearance_around_main_panel_and_utility_meter"
                type="number"
                className={classes.inputField}
                label={
                  <Label text={'Clearance around main panel and utility meter (ft)'} capitalizeLabelText={false} />
                }
                value={clearance_around_main_panel_and_utility_meter ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'clearance_around_main_panel_and_utility_meter')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefault(e)}
                error={!isEmpty(get(errorData, 'clearance_around_main_panel_and_utility_meter'))}
                helperText={get(errorData, 'clearance_around_main_panel_and_utility_meter')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Minimum EGC conductor (awg)'} capitalizeLabelText={false} />}
                dropDownOptions={MIN_EGC_CONDUCTOR_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="min_egc_conductor"
                fieldValue={min_egc_conductor ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Conduit type'} capitalizeLabelText={false} />}
                dropDownOptions={CONDUIT_TYPE_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="conduit_type"
                fieldValue={conduit_type ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'Interconnection (tie-in) methods allowed'} capitalizeLabelText={false} />}
                dropDownOptions={INTERCONNECTION_METHODS_ALLOWED}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="interconnection_methods_allowed"
                fieldValue={interconnection_methods_allowed ?? []}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'3 line diagram required'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="three_line_diagram"
                fieldValue={three_line_diagram ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'AC disconnect required'} capitalizeLabelText={false} />}
                dropDownOptions={AC_DISCONNECT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="ac_disconnect_required"
                fieldValue={ac_disconnect_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Equipment elevation required'} capitalizeLabelText={false} />}
                dropDownOptions={EQUIPMENT_ELEVATION_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="equipment_elevation_required"
                fieldValue={equipment_elevation_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                className={classes.inputField}
                id="panel_schedule_required"
                disableClearable={true}
                getOptionLabel={(option) => get(option, 'title')}
                getOptionSelected={(option, value) => get(option, 'title') === value}
                options={PANEL_SCHEDULE_OPTIONS}
                value={initialPanelScheduleOption ?? ''}
                disabled={readOnly || !stateSelected}
                onChange={(e, value) => handleElectricalDesignRequirements(e, 'panel_schedule_required', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    error={!isEmpty(get(errorData, 'panel_schedule_required'))}
                    helperText={get(errorData, 'panel_schedule_required')}
                    InputLabelProps={{ style: { pointerEvents: 'auto', zIndex: '1' } }}
                    label={
                      <Box className={classes.inputWrapper}>
                        <Label text={'Panel schedule required'} capitalizeLabelText={false} />
                        <HelpTextIcon
                          message={
                            'Represent which breaker supplies power to each piece of electrical equipment on the permit plan-set'
                          }
                          position="top"
                          btnClassName={classes.iconWrapper}
                        />
                      </Box>
                    }
                  />
                )}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Load calculation required'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="load_calculation_required"
                fieldValue={load_calculation_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={
                  <Label text={'Load calculation required for battery backup'} capitalizeLabelText={false} />
                }
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="load_calculation_required_for_battery_backup"
                fieldValue={load_calculation_required_for_battery_backup ?? ''}
                readOnly={readOnly || !stateSelected || load_calculation_required}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Load calculation required for MSP upgrade'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="load_calculation_required_for_msp_upgrade"
                fieldValue={load_calculation_required_for_msp_upgrade ?? ''}
                readOnly={readOnly || !stateSelected || load_calculation_required}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={
                  <Label
                    text={'Load calculation required for derating existing main breaker or protected load tap'}
                    capitalizeLabelText={false}
                    className={classes.longLabel}
                  />
                }
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="load_calculation_required_for_derating_existing_main_breaker_or_protected_load_tap"
                fieldValue={load_calculation_required_for_derating_existing_main_breaker_or_protected_load_tap ?? ''}
                readOnly={readOnly || load_calculation_required || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Additional requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={additional_requirements_electrical ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'additional_requirements_electrical')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ElectricalDesignRequirementsSection;
