import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Grid, ExpansionPanel, ExpansionPanelDetails, IconButton, Tooltip } from '@material-ui/core';
import ViewIcon from '../images/viewIcon';
import { getS3URL } from '../../../../containers/Permitting/action';
import { handlePopupBlocker, formatString, downloadFileSG } from '../../../../utils/helper';
import { get, isEmpty, isEqual, split } from 'lodash';
import { TagLabel } from '../../../common/TagLabel';

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#111111',
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: theme.spacing(1),
    minWidth: theme.spacing(15),
    maxWidth: 350,
    pointerEvents: 'auto',
    fontWeight: 'normal'
  },
  arrow: {
    color: 'white',
    fontSize: 14
  }
}))(Tooltip);

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    display: 'flex',
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    fontSize: '14px'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px',
    height: '40px',
    cursor: 'unset'
  },
  panelDetails: {
    flexDirection: 'column',
    boxShadow: '1px -1px 4px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray'
  },
  dataSubHeader: {
    fontSize: '12px',
    color: 'grey',
    display: 'flex'
  },
  padding4: {
    paddingTop: theme.spacing(3.125),
    paddingBottom: theme.spacing(0.625)
  }
});

export class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSheetToolTip: false
    };
  }

  inventorId = 'material_details.inverters';
  storageId = 'material_details.storage_list';
  otherMaterialId = 'material_details.other_materials';

  componentDidMount() {
    const { classes, arrayList, title, isStorage = false, toolTipId = '' } = this.props;
    const stateList = [];
    for (const i in arrayList) {
      let id = toolTipId + '_' + i;
      if (!isEmpty(arrayList[i].spec_sheets)) {
        stateList.push({ id: false });
      }
      if (i == arrayList.length - 1) {
        this.setState({ ...this.state, stateList });
      }
    }
  }

  splitOtherValues(value) {
    let otherValue = !isEmpty(value) && value.startsWith('Other-') ? split(value, '-', 2)[1] : value;
    return otherValue;
  }

  downloadFile = (fileName, downloadFileName) => {
    this.props.getS3URL({
      fileName: fileName,
      methodType: 'GET',
      downloadFileName: downloadFileName,
      successCb: preSignedS3Url => {
        const myWindow = window.open(preSignedS3Url, '_blank');
        handlePopupBlocker(myWindow);
      },
      failureCb: () => {
        return false;
      }
    });
  };

  getModifiedtag(arrayItem, name) {
    const { originalApplicationDetails, toolTipId, applicationDetail } = this.props;
    let compareArray = [];
    if (isEmpty(originalApplicationDetails)) {
      return null;
    }
    if (toolTipId === 'inverters' && !isEmpty(get(originalApplicationDetails, this.inventorId))) {
      compareArray = originalApplicationDetails.material_details.inverters.filter(s => s.id === arrayItem.id);
    } else if (toolTipId === 'storage' && !isEmpty(get(originalApplicationDetails, this.storageId))) {
      compareArray = originalApplicationDetails.material_details.storage_list.filter(s => s.id === arrayItem.id);
    } else if (!isEmpty(get(originalApplicationDetails, this.otherMaterialId))) {
      compareArray = originalApplicationDetails.material_details.other_materials.filter(s => s.id === arrayItem.id);
    }
    if (isEmpty(compareArray)) {
      if (arrayItem[name]) return <TagLabel add applicationDetail={applicationDetail} />;
    } else {
      if (!isEqual(compareArray[0][name], arrayItem[name])) {
        if (!compareArray[0][name]) {
          return <TagLabel add applicationDetail={applicationDetail} />;
        } else {
          return <TagLabel edit applicationDetail={applicationDetail} />;
        }
      }
    }
  }

  getDeletedtag() {
    const { originalApplicationDetails, toolTipId, arrayList, applicationDetail } = this.props;
    let deletedValue = 0;
    if (toolTipId === 'inverters') {
      if (isEmpty(arrayList) && !isEmpty(get(originalApplicationDetails, this.inventorId))) {
        return <TagLabel edit text=" Inverter(s)" applicationDetail={applicationDetail} />;
      } else if (!isEmpty(get(originalApplicationDetails, this.inventorId))) {
        get(originalApplicationDetails, this.inventorId).map(item => {
          let compareArray = arrayList.filter(s => s.id === item.id);
          if (isEmpty(compareArray)) {
            deletedValue += 1;
          }
        });
      }
    } else if (toolTipId === 'storage') {
      if (isEmpty(arrayList) && !isEmpty(get(originalApplicationDetails, this.storageId))) {
        return <TagLabel edit text=" Storage(s)" applicationDetail={applicationDetail} />;
      } else if (!isEmpty(get(originalApplicationDetails, this.storageId))) {
        get(originalApplicationDetails, this.storageId).map(item => {
          let compareArray = arrayList.filter(s => s.id === item.id);
          if (isEmpty(compareArray)) {
            deletedValue += 1;
          }
        });
      }
    } else {
      if (isEmpty(arrayList) && !isEmpty(get(originalApplicationDetails, this.otherMaterialId))) {
        return <TagLabel edit text=" Other Materials(s)" applicationDetail={applicationDetail} />;
      } else if (!isEmpty(get(originalApplicationDetails, this.otherMaterialId))) {
        get(originalApplicationDetails, this.otherMaterialId).map(item => {
          let compareArray = arrayList.filter(s => s.id === item.id);
          if (isEmpty(compareArray)) {
            deletedValue += 1;
          }
        });
      }
    }
    if (deletedValue > 0) {
      return (
        <TagLabel
          edit
          text={' [' + deletedValue + ' ' + toolTipId + '(s) deleted ]'}
          applicationDetail={applicationDetail}
        />
      );
    }
  }

  render() {
    const { classes, arrayList, title, isStorage = false, toolTipId = '' } = this.props;

    return (
      <Fragment>
        <Grid container direction="row" className={classes.padding4} spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography className={classes.fontBold}>
              {title} {this.getDeletedtag()}
            </Typography>
          </Grid>
        </Grid>

        <br />
        {!isStorage ? (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={12}>
              {arrayList &&
                arrayList.map((arrayItem, index) => {
                  let product_id = toolTipId + '_' + index;
                  return (
                    <ExpansionPanel expanded={true}>
                      <ExpansionPanelDetails className={classes.panelDetails} style={{ flexDirection: 'column' }}>
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={12} md={3}>
                            <Typography className={classes.dataSubHeader}>
                              Type {this.getModifiedtag(arrayItem, 'type')}
                            </Typography>
                            <Typography className={classes.fontBold}>{arrayItem.type || '--'}</Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography className={classes.dataSubHeader}>
                              Manufacturer {this.getModifiedtag(arrayItem, 'manufacturer')}
                            </Typography>
                            <Typography className={classes.fontBold}>
                              {arrayItem.manufacturer && arrayItem.manufacturer.name ? (
                                this.splitOtherValues(arrayItem.manufacturer.name)
                              ) : (
                                '--'
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography className={classes.dataSubHeader}>
                              Model {this.getModifiedtag(arrayItem, 'model')}
                            </Typography>
                            <Typography className={classes.fontBold}>
                              {arrayItem.model && arrayItem.model.name ? (
                                this.splitOtherValues(arrayItem.model.name)
                              ) : (
                                '--'
                              )}
                              {!isEmpty(arrayItem.spec_sheets) ? (
                                <LightTooltip title="View Spec Sheet" open={this.state.product_id} placement="right">
                                  <IconButton
                                    onClick={() =>
                                      this.downloadFile(
                                        arrayItem.spec_sheets[0].file_name,
                                        formatString(
                                          this.splitOtherValues(arrayItem.manufacturer.name) +
                                            '_' +
                                            this.splitOtherValues(arrayItem.model.name)
                                        )
                                      )}
                                    onMouseEnter={() => this.setState({ product_id: true })}
                                    onMouseLeave={() => this.setState({ product_id: false })}
                                    aria-label="info"
                                    className={classes.iconButton}
                                  >
                                    <ViewIcon />
                                  </IconButton>
                                </LightTooltip>
                              ) : !isEmpty(arrayItem.sg_spec_sheet) ? (
                                <LightTooltip title="View Spec Sheet" open={this.state.product_id} placement="right">
                                  <IconButton
                                    onClick={() => {
                                      downloadFileSG(
                                        arrayItem.sg_spec_sheet,
                                        formatString(
                                          this.splitOtherValues(arrayItem.manufacturer.name) +
                                            '_' +
                                            this.splitOtherValues(arrayItem.model.name)
                                        )
                                      );
                                    }}
                                    onMouseEnter={() => this.setState({ product_id: true })}
                                    onMouseLeave={() => this.setState({ product_id: false })}
                                    aria-label="info"
                                    className={classes.iconButton}
                                  >
                                    <ViewIcon />
                                  </IconButton>
                                </LightTooltip>
                              ) : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography className={classes.dataSubHeader}>
                              Quantity {this.getModifiedtag(arrayItem, 'quantity')}
                            </Typography>
                            <Typography className={classes.fontBold}>{arrayItem.quantity || '--'}</Typography>
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  );
                })}
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={12}>
              {arrayList &&
                arrayList.map((arrayItem, index) => {
                  let product_id = toolTipId + '_' + index;
                  return (
                    <ExpansionPanel expanded={true}>
                      <ExpansionPanelDetails className={classes.panelDetails} style={{ flexDirection: 'column' }}>
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={12} md={2}>
                            <Typography className={classes.dataSubHeader}>
                              Manufacturer {this.getModifiedtag(arrayItem, 'manufacturer')}
                            </Typography>
                            <Typography className={classes.fontBold}>
                              {arrayItem.manufacturer && arrayItem.manufacturer.name ? (
                                this.splitOtherValues(arrayItem.manufacturer.name)
                              ) : (
                                '--'
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Typography className={classes.dataSubHeader}>
                              Model {this.getModifiedtag(arrayItem, 'model')}
                            </Typography>
                            <Typography className={classes.fontBold}>
                              {arrayItem.model && arrayItem.model.name ? (
                                this.splitOtherValues(arrayItem.model.name)
                              ) : (
                                '--'
                              )}{' '}
                              {!isEmpty(arrayItem.spec_sheets) ? (
                                <LightTooltip title="View Spec Sheet" open={this.state.product_id} placement="right">
                                  <IconButton
                                    onClick={() =>
                                      this.downloadFile(
                                        arrayItem.spec_sheets[0].file_name,
                                        formatString(
                                          this.splitOtherValues(arrayItem.manufacturer.name) +
                                            '_' +
                                            this.splitOtherValues(arrayItem.model.name)
                                        )
                                      )}
                                    onMouseEnter={() => this.setState({ product_id: true })}
                                    onMouseLeave={() => this.setState({ product_id: false })}
                                    aria-label="info"
                                    className={classes.iconButton}
                                  >
                                    <ViewIcon />
                                  </IconButton>
                                </LightTooltip>
                              ) : !isEmpty(arrayItem.sg_spec_sheet) ? (
                                <LightTooltip title="View Spec Sheet" open={this.state.product_id} placement="right">
                                  <IconButton
                                    onClick={() => {
                                      downloadFileSG(
                                        arrayItem.sg_spec_sheet,
                                        formatString(
                                          this.splitOtherValues(arrayItem.manufacturer.name) +
                                            '_' +
                                            this.splitOtherValues(arrayItem.model.name)
                                        )
                                      );
                                    }}
                                    onMouseEnter={() => this.setState({ product_id: true })}
                                    onMouseLeave={() => this.setState({ product_id: false })}
                                    aria-label="info"
                                    className={classes.iconButton}
                                  >
                                    <ViewIcon />
                                  </IconButton>
                                </LightTooltip>
                              ) : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Typography className={classes.dataSubHeader}>
                              Energy Capacity (kWh) {this.getModifiedtag(arrayItem, 'capacity')}
                            </Typography>
                            <Typography className={classes.fontBold}>{arrayItem.capacity || '--'}</Typography>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Typography className={classes.dataSubHeader}>
                              Power Capacity (kWac) {this.getModifiedtag(arrayItem, 'power_capacity')}
                            </Typography>
                            <Typography className={classes.fontBold}>
                              {Number(arrayItem.power_capacity) ? arrayItem.power_capacity / 1000 : '--'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Typography className={classes.dataSubHeader}>
                              Quantity {this.getModifiedtag(arrayItem, 'quantity')}
                            </Typography>
                            <Typography className={classes.fontBold}>{arrayItem.quantity || '--'}</Typography>
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getS3URL: payload => dispatch(getS3URL(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProductList));
