import {
  Box,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { clone, get, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { ACCEPTABLE_FILE_FORMAT, FEATURE_FLAG_TYPE, FEATURE_FLAGS } from '../../../containers/Permitting/constants';
import { Label } from '../../common/Label';
import FileUploadDialog from '../FileUploadDialog';
import { STANDING_STEAM, STANDING_STEAM_ROOF_TYPES } from './constants';
import SelectDropdown from './SelectDropdown';
import AutoCompleteDropdown from '../../common/TypableAutoComplete/AutoCompleteDropdown';
import { useStyles } from './styles';
import InfoIcon from '@material-ui/icons/Info';
import ImgIcon from '../images/img-icon.png';

import { isFeatureEnabled } from '../../../containers/Permitting/helper';
import { trenchingRoute } from '../images';

const Array = ({
  array,
  index,
  errors,
  inchesOptions,
  preventDefault,
  handleChange,
  tempFeet,
  setTempFeet,
  setDeleteArrayIndex,
  changedRows,
  isPermitSetAvailable,
  labelClass,
  structuralType,
  structuralTypeStandingSeam,
  structuralNumber,
  modifiedPanelManufactureList,
  mostUsedValues,
  installationOptions,
  otherInstallationOptions,
  roofOptions,
  otherRoofOptions,
  otherPanelManufacturerOptions,
  getPanelModel,
  fireUpload,
  isExistingArray,
  isInputAutocompleteEnabled,
}) => {
  const {
    name = '',
    quantity = '',
    pitch_deg = '',
    azimuth = '',
    max_rafter_span = '',
    structural_number = '',
    other_structural_number = '',
    structural_type = '',
    structural_at = '',
    panel_manufacturer = '',
    panel_model = '',
    panel_wattage = '',
    installation_type = '',
    roof_type = '',
    roof_pitch = '',
    spec_sheets = [],
    trenching_route_photo = [],
    panelModelList = [],
  } = array;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openTrench, setOpenTrench] = useState(false);
  let maxRafter = max_rafter_span || parseFloat(max_rafter_span) >= 0 ? parseFloat(max_rafter_span) / 12 : '';
  maxRafter = maxRafter.toString().split('.');
  const maxRafterFeet = maxRafter[0] || maxRafter[0] >= 0 ? maxRafter[0] : '';
  const maxRafterInch = maxRafter[1] || maxRafter[1] >= 0 ? (`.${maxRafter[1].substring(0, 2)}` * 12).toFixed(0) : 0;
  const isGroundMount = installation_type && installation_type.toLowerCase() === 'ground mount';
  const arrayKey = isExistingArray ? 'existing_arrays' : 'arrays';
  const modifiedPanelModelList = [...panelModelList, { id: 'other', name: 'Other' }];

  const [isTooltipOpen, setIstooltipOpen] = useState(false);

  const removeOtherOption = (optionList) => {
    let modifiedOptionsList = [];
    if (optionList && optionList.length) {
      modifiedOptionsList = optionList.slice();
      const otherIndex = modifiedOptionsList.findIndex((item) => item.toLowerCase() === 'other');
      otherIndex > -1 && modifiedOptionsList.splice(otherIndex, 1);
    }
    return modifiedOptionsList;
  };

  const getErrorText = (key) => {
    const errorText = get(errors, `${arrayKey}.${index}.${key}.error`)
      ? get(errors, `${arrayKey}.${index}.${key}.errorMessage`)
      : '';
    return errorText;
  };
  const getMaxRafter = (value, isFeet) => {
    let maxRafter = max_rafter_span;
    const maxRafterValue = value ? parseInt(value, 10) : 0;
    const rafterInch = maxRafterInch || parseInt(maxRafterInch) >= 0 ? parseInt(maxRafterInch) : 0;
    const rafterFeet = maxRafterFeet || parseInt(maxRafterFeet) >= 0 ? parseInt(maxRafterFeet) : 0;
    maxRafter = isFeet ? maxRafterValue * 12 + rafterInch : rafterFeet * 12 + maxRafterValue;
    return maxRafter;
  };
  const handleRafterChange = (value, isFeet) => {
    if (isFeet) {
      setTempFeet({ ...tempFeet, [index]: value });
    }
    let maxRafter = getMaxRafter(value, isFeet);
    handleChange(maxRafter, 'max_rafter_span', index);
  };
  const handleOnBlur = (value) => {
    if (value === '') {
      let maxRafter = getMaxRafter(value, true);
      setTempFeet({ ...tempFeet, [index]: 0 });
      handleChange(maxRafter, 'max_rafter_span', index);
    }
  };

  const checkRafterTypeSelected = () => {
    if(!isEmpty(structural_type) && structuralTypeStandingSeam.includes(structural_type)) {
        return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isEmpty(panel_manufacturer)) {
      getPanelModel(panel_manufacturer, index);
    }
  }, [panel_manufacturer]);

  useEffect(() => {
    if (!isEmpty(panel_model)) {
      const panelModel = panelModelList && panelModelList.find((model) => model.name === panel_model);
      if (panelModel) {
        handleChange(panelModel.stc_rating, 'panel_wattage', index, false, true);
      }
    }
  }, [panel_model, panelModelList]);

  useEffect(() => {
    if (isStandingSteam && !checkRafterTypeSelected()) {
      handleChange(STANDING_STEAM, 'structural_type', index);
      handleChange('', 'structural_number', index);
    }
  }, [roof_type]);

  // This is for panel_manfacturer, panel_model, installation_type, roof_type
  const handlePanelDataChange = (val, key, isFromOther) => {
    if (key === 'panel_manufacturer') {
      isInputAutocompleteEnabled
        ? handleChange('', 'panel_model', index)
        : handleChange(isFromOther || val === 'Other' ? 'Other' : '', 'panel_model', index);
    }
    if (key === 'panel_model') {
      const panelModel = panelModelList && panelModelList.find((model) => model.name === val);
      handleChange(panelModel ? panelModel.stc_rating : '', 'panel_wattage', index);
    }
    if (key === 'installation_type') {
      handleChange(
        val.toLowerCase() === 'flush roof'
          ? null
          : val.toLowerCase() === 'flat roof' || val.toLowerCase() === 'ground mount'
          ? 0
          : '',
        'roof_pitch',
        index
      );
    }
    if (key === 'installation_type' && !isFromOther && val && val.toLowerCase() === 'ground mount') {
      handleChange('', 'roof_type', index);
      handleChange('', 'structural_type', index);
      handleChange('', 'structural_at', index);
      handleChange('', 'max_rafter_span', index);
      handleChange('', 'structural_number', index);
    }
    if (key === 'roof_type') {
      if (!STANDING_STEAM_ROOF_TYPES.includes(val)) {
        handleChange('', 'structural_type', index);
        handleChange('', 'structural_number', index);
      }
    }

    if (isInputAutocompleteEnabled && key !== 'installation_type' && key !== 'roof_type') {
      handleChange(val, key, index);
      if (isFromOther) {
        handleChange('Other-' + val, 'other_' + key, index);
      } else {
        handleChange('', 'other_' + key, index);
      }
    } else {
      handleChange(isFromOther ? `Other-${val}` : val, key, index);
    }
  };

  const uploadSpecSheet = (file, key) => {
    fireUpload(file, index, key, isExistingArray ? 'Existing Array Spec Sheets' : 'Array Spec Sheets');
  };

  const uploadTrenchingRoutePhoto = (file, key) => {
    fireUpload(file, index, key, 'Trenching route');
  };

  const deleteImage = (ind) => {
    const newImages = clone(spec_sheets) || [];
    newImages.splice(ind, 1);
    handleChange(newImages, 'spec_sheets', index);
  };

  const deleteTrenchingRoutePhoto = (ind) => {
    const newImages = clone(trenching_route_photo) || [];
    newImages.splice(ind, 1);
    handleChange(newImages, 'trenching_route_photo', index);
  };

  useEffect(() => {
    if (!isGroundMount) {
      handleChange([], 'trenching_route_photo', index);
    }
  }, [isGroundMount]);

  const isStandingSteam = STANDING_STEAM_ROOF_TYPES.includes(roof_type);
  //remove other options from the list
  const modifiedRoofTypeList = removeOtherOption(roofOptions);
  const modifiedInstallationTypeList = removeOtherOption(installationOptions);
  const modifiedstructuralNumberList = removeOtherOption(structuralNumber);

  return (
    <Box className={classes.array} key={arrayKey + index}>
      <Box className={classes.arrayHeaderWrapper}>
        <Typography className={classes.arrayHeader}>
          {`Array #${index + 1}`} {!isEmpty(name) && !changedRows.includes(index) && `: ${name}`}
        </Typography>
        {index !== 0 && (
          <IconButton className={classes.removeButton} onClick={() => setDeleteArrayIndex(index)}>
            <CloseIcon fontSize="large" />
          </IconButton>
        )}
      </Box>
      <Box className={classes.specSheetWrapper}>
        {isInputAutocompleteEnabled ? (
          <AutoCompleteDropdown
            id={'panel_manufacturer'}
            className={clsx(classes.inputField, labelClass.customLabel)}
            value={panel_manufacturer}
            handleChange={handlePanelDataChange}
            optionList={modifiedPanelManufactureList}
            otherOptionList={otherPanelManufacturerOptions}
            labelText={'Panel Manufacturer'}
            error={!isEmpty(getErrorText('panel_manufacturer'))}
            helperText={getErrorText('panel_manufacturer')}
            classes={classes}
            labelClass={labelClass}
            isPermitSetAvailable={isPermitSetAvailable}
            showRecent={true}
            mostUsedList={mostUsedValues['array_panel_manufacturer']}
            isRequired
          />
        ) : (
          <SelectDropdown
            id={'panel_manufacturer'}
            className={clsx(classes.inputField, labelClass.customLabel)}
            value={panel_manufacturer}
            handleChange={handlePanelDataChange}
            optionList={modifiedPanelManufactureList}
            otherOptionList={otherPanelManufacturerOptions}
            labelText="Panel Manufacturer"
            error={!isEmpty(getErrorText('panel_manufacturer'))}
            helperText={getErrorText('panel_manufacturer')}
            classes={classes}
            labelClass={labelClass}
            isPermitSetAvailable={isPermitSetAvailable}
            showRecent={true}
            mostUsedList={mostUsedValues['array_panel_manufacturer']}
          />
        )}

        {isInputAutocompleteEnabled ? (
          <AutoCompleteDropdown
            id={'panel_model'}
            className={clsx(classes.inputField, labelClass.customLabel)}
            value={panel_model}
            panel_wattage={panel_wattage}
            handleChange={handlePanelDataChange}
            optionList={[...panelModelList]}
            labelText="Panel Model"
            error={!isEmpty(getErrorText('panel_model'))}
            helperText={getErrorText('panel_model')}
            pwattErrorText={getErrorText('panel_wattage')}
            classes={classes}
            labelClass={labelClass}
            isPermitSetAvailable={isPermitSetAvailable}
            preventDefault={preventDefault}
          />
        ) : (
          <SelectDropdown
            id={'panel_model'}
            className={clsx(classes.inputField, labelClass.customLabel)}
            value={panel_model}
            panel_wattage={panel_wattage}
            handleChange={handlePanelDataChange}
            optionList={modifiedPanelModelList}
            labelText="Panel Model"
            error={!isEmpty(getErrorText('panel_model'))}
            helperText={getErrorText('panel_model')}
            pwattErrorText={getErrorText('panel_wattage')}
            classes={classes}
            labelClass={labelClass}
            isPermitSetAvailable={isPermitSetAvailable}
            preventDefault={preventDefault}
          />
        )}
        <SelectDropdown
          id={'installation_type'}
          className={clsx(classes.inputField, labelClass.customLabel)}
          value={installation_type}
          handleChange={handlePanelDataChange}
          optionList={installationOptions}
          otherOptionList={otherInstallationOptions}
          labelText="Installation Type"
          classes={classes}
          labelClass={labelClass}
          error={!isEmpty(getErrorText('installation_type'))}
          helperText={getErrorText('installation_type')}
          isPermitSetAvailable={isPermitSetAvailable}
          showRecent={true}
          mostUsedList={mostUsedValues['array_installation_type']}
          roof_pitch={roof_pitch}
          roofPitchErrorText={getErrorText('roof_pitch')}
        />
        {!isGroundMount && (
          <SelectDropdown
            id={'roof_type'}
            className={clsx(classes.inputField, labelClass.customLabel)}
            value={roof_type}
            handleChange={handlePanelDataChange}
            optionList={roofOptions}
            otherOptionList={otherRoofOptions}
            labelText="Roof Type"
            classes={classes}
            labelClass={labelClass}
            error={!isEmpty(getErrorText('roof_type'))}
            helperText={getErrorText('roof_type')}
            isPermitSetAvailable={isPermitSetAvailable}
            showRecent={true}
            mostUsedList={mostUsedValues['array_roof_type']}
          />
        )}
      </Box>
      <Box>
        {spec_sheets &&
          spec_sheets.map((img, i) => {
            return (
              <Box
                className={classes.imgHolder}
                key={isExistingArray ? `existing_spec_sheets-${i}` : `spec_sheets-${i}`}
              >
                {img.loading && <CircularProgress />}
                {!img.loading && (
                  <Box className={classes.imgContainer}>
                    <CloseIcon onClick={() => deleteImage(i)} className={classes.close} />
                    <img alt={img.file_name} src={ImgIcon} />
                  </Box>
                )}
                <Typography variant="caption" display="block">
                  {img.original_file_name}
                </Typography>
              </Box>
            );
          })}
      </Box>
      {!(spec_sheets && spec_sheets.length) && (
        <Typography component="div" className={classes.addNewArray} onClick={() => setOpen(true)}>
          + Add Spec Sheet
        </Typography>
      )}
      <Box>
        {isGroundMount &&
          trenching_route_photo &&
          trenching_route_photo.map((img, i) => {
            return (
              <Box className={clsx(classes.imgHolder, classes.gridPad)} key={`trenching_route_photo-${i}`}>
                {img.loading && <CircularProgress />}
                {!img.loading && (
                  <Box className={classes.imgContainer}>
                    <CloseIcon onClick={() => deleteTrenchingRoutePhoto(i)} className={classes.close} />
                    <img alt={img.file_name} src={ImgIcon} />
                  </Box>
                )}
                <Typography variant="caption" display="block">
                  {img.original_file_name}
                </Typography>
              </Box>
            );
          })}
      </Box>
      {isGroundMount && !(trenching_route_photo && trenching_route_photo.length) && (
        <Grid container spacing={1} className={classes.gridPad}>
          <Grid item xs={3} sm={3} md={3}>
            <Typography component="div" className={classes.addNewArray} onClick={() => setOpenTrench(true)}>
              + Add Trenching Route photo
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <ClickAwayListener className={classes.addNewArray} onClickAway={(e) => setIstooltipOpen(false)}>
              <div>
                <Tooltip
                  classes={{ tooltip: classes.tooltipStyle }}
                  placement="bottom-start"
                  onClose={(e) => setIstooltipOpen(false)}
                  open={isTooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <Fragment>
                      <img width={600} height={400} src={trenchingRoute} />
                    </Fragment>
                  }
                >
                  <IconButton className={classes.infoIcon}>
                    <InfoIcon onClick={(e) => setIstooltipOpen(true)} />
                  </IconButton>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Grid>
        </Grid>
      )}
      <Box className={classes.arrayList}>
        <TextField
          id="quantity"
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          className={clsx(classes.arrayInputInch, classes.arrayFieldMaxRafter, labelClass.customLabel)}
          label={<Label className={classes.labelColor} text="Qty" isRequired />}
          value={quantity || ''}
          onKeyPress={(e) => preventDefault(e)}
          onChange={(e) => handleChange(e.target.value, 'quantity', index)}
          error={!isEmpty(getErrorText('quantity'))}
          helperText={getErrorText('quantity')}
        />
        <TextField
          id="pitch_deg"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          className={clsx(classes.arrayInputField, labelClass.customLabel)}
          label={<Label className={classes.labelColor} text="Pitch (deg)" isRequired />}
          value={pitch_deg || ''}
          onKeyPress={(e) => preventDefault(e)}
          onChange={(e) => handleChange(e.target.value, 'pitch_deg', index)}
          error={!isEmpty(getErrorText('pitch_deg'))}
          helperText={getErrorText('pitch_deg')}
        />
        <TextField
          id="azimuth"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          className={clsx(classes.arrayInputField, labelClass.customLabel)}
          label={<Label className={classes.labelColor} text="Azimuth (deg)" />}
          value={azimuth || ''}
          onKeyPress={(e) => preventDefault(e)}
          onChange={(e) => handleChange(e.target.value, 'azimuth', index)}
          error={!isEmpty(getErrorText('azimuth'))}
          helperText={getErrorText('azimuth')}
        />
        {!isGroundMount && (
          <FormControl
            id="max_rafter_span"
            className={clsx(classes.arrayFieldMaxRafter, labelClass.customLabel)}
            error={!isEmpty(getErrorText('max_rafter_span'))}
          >
            <InputLabel id="max_rafter_span-label" shrink>
              <Label className={classes.labelColor} text="Max Rafter Span" />
            </InputLabel>
            <Box className={clsx('MuiInput-formControl', classes.maxRafterWrapper)}>
              <Box className={clsx(classes.structuralWrapper, classes.maxRafterFeet)}>
                <TextField
                  className={classes.arrayInputInch}
                  type="number"
                  value={tempFeet[index] === '' ? tempFeet[index] : maxRafterFeet}
                  onKeyPress={(e) => preventDefault(e)}
                  InputProps={{ inputProps: { min: 0, onBlur: (e) => handleOnBlur(e.target.value) } }}
                  onChange={(e) => handleRafterChange(e.target.value, true)}
                />
                <Typography className={classes.inchLabel}>ft</Typography>
              </Box>
              <Box className={classes.structuralWrapper}>
                <SelectDropdown
                  id={`inch-${index}`}
                  className={
                    classes.arrayInputInch
                  }
                  value={maxRafterInch ? parseInt(maxRafterInch, 10) : ''}
                  optionList={inchesOptions()}
                  handleChange={(value, key) => handleRafterChange(value, false)}
                  isRequired={false}
                  classes={classes}
                  isPermitSetAvailable={isPermitSetAvailable}
                  labelClass={labelClass}
                />
                <Typography className={classes.inchLabel}>in</Typography>
              </Box>
            </Box>
            {getErrorText('max_rafter_span') && <FormHelperText>{getErrorText('max_rafter_span')}</FormHelperText>}
          </FormControl>
        )}

        {!isGroundMount && (
          <Box className={classes.structuralWrapper}>
            <SelectDropdown
              id="structural_number"
              className={
               clsx(classes.arrayField, classes.arrayFieldOther, labelClass.customLabel)
              }
              value={structural_number || ''}
              optionList={structuralNumber}
              labelText="Size of Rafter"
              handleChange={(value, key, others, skipValidation = false) =>
                handleChange(value, key, index, skipValidation)
              }
              error={!isEmpty(getErrorText('structural_number'))}
              helperText={getErrorText('structural_number')}
              classes={classes}
              isPermitSetAvailable={isPermitSetAvailable}
              isRequired={!isStandingSteam}
              labelClass={labelClass}
              exceptOtherField
            />
            {structural_number === 'Other' && (
              <TextField
                id="other_structural_number"
                className={clsx(classes.arrayInputOther)}
                value={other_structural_number || ''}
                onChange={(e) => handleChange(e.target.value, 'other_structural_number', index)}
                error={!isEmpty(getErrorText('other_structural_number'))}
                helperText={getErrorText('other_structural_number')}
                label={<Label className={classes.labelColor} text="Provide Value (inches)" />}
              />
            )}
          </Box>
        )}
        {!isGroundMount && (
          <Box className={classes.structuralWrapper}>
            <SelectDropdown
              id="structural_type"
              className={
                clsx(classes.arrayInputField, labelClass.customLabel)
              }
              value={structural_type || ''}
              labelText="Rafter Type"
              handleChange={(value, key, others, skipValidation = false) => {
                handleChange(value, key, index, skipValidation);
              }}
              optionList={isStandingSteam ? structuralTypeStandingSeam : structuralType}
              error={!isEmpty(getErrorText('structural_type'))}
              helperText={getErrorText('structural_type')}
              classes={classes}
              labelClass={labelClass}
              isPermitSetAvailable={isPermitSetAvailable}
              isRequired={!isStandingSteam}
            />
            <Typography className={classes.structuralAtText}>at</Typography>
          </Box>
        )}

        {!isGroundMount && (
          <Box className={clsx(classes.structuralWrapper, classes.structuralAt)}>
            <TextField
              id="structural_at"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              className={clsx(classes.arrayInputInch, labelClass.customLabel)}
              value={structural_at || ''}
              onKeyPress={(e) => preventDefault(e)}
              onChange={(e) => handleChange(e.target.value, 'structural_at', index)}
              error={!isEmpty(getErrorText('structural_at'))}
              helperText={getErrorText('structural_at')}
              required
            />
            <Label className={classes.inchLabel} capitalizeLabelText={false} text='" o.c' isRequired />
          </Box>
        )}
      </Box>
      <FileUploadDialog
        type={'Spec Sheet'}
        imageKey={'spec_sheets'}
        fireUpload={uploadSpecSheet}
        open={open}
        setOpen={setOpen}
        acceptableFileFormat={ACCEPTABLE_FILE_FORMAT}
      />
      <FileUploadDialog
        type={'Photos'}
        imageKey={'trenching_route_photo'}
        fireUpload={uploadTrenchingRoutePhoto}
        open={openTrench}
        setOpen={setOpenTrench}
        acceptableFileFormat={ACCEPTABLE_FILE_FORMAT}
      />
    </Box>
  );
};

export default Array;
