import { SET_MOBILE_VIEW, SET_EMAIL_ID, SET_ENLIGHTEN_MANAGER_TOKEN, GET_ACCOUNT_DETAIL_REQUEST } from './constants';

export function setMobileView(isMobileView) {
  return { type: SET_MOBILE_VIEW, isMobileView };
}

export function setEmailId(emailId) {
  return { type: SET_EMAIL_ID, emailId };
}

export function setEnlightenManagerToken(enlightenManagerToken) {
  return { type: SET_ENLIGHTEN_MANAGER_TOKEN, enlightenManagerToken };
}

export function getAccountDtails() {
  return { type: GET_ACCOUNT_DETAIL_REQUEST };
}
