import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { includes, isEmpty, isEqual, get } from '../../../../utils/lodash';
import Loader from '../../../../components/Loader';
import clsx from 'clsx';
import { selectMultipleRender } from '../../helper';
import Icons from '../../../../containers/Permitting/Chats/images';
import {
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  IconButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import DateFilter from '../../../../components/Admin/Automation/List/DateFilter';
import { VIEW_ONLY_ROLES, STRUCTURAL_ASSEMBLY_LISTING_COLUMNS, DATE_FILTER_LIST } from '../../constants';
import Snackbar from '../../../../components/SnakBar';
import StructuralAssemblyInfo from '../Info';
import DeleteStructuralAssembly from '../Info/deleteStructural';
import EditStructuralAssembly from '../Info/editStructural';
import OutlinedButton from '../../../../components/common/OutlinedButton';
import { DEFAULT_ROW_PER_PAGE } from '../../StatusDashboard/constants';
import moment from 'moment';
import { getUserSelectionData } from '../../../Permitting/action';
import {
  getAllRacks,
  getAllStandoffs,
  setStructuralAssemblyFilters,
  getAllStructural,
  createStructural,
  createStructuralSuccess,
  getStructural,
  deleteStructuralUploadedFile,
  getAllUsers,
  getAllRoofTypes,
  deleteStructural,
} from '../actions';
import { getFileMetaData } from '../../helper';
import uploadFileDetails from '../../../../utils/uploadFileDetails';
import EditIcon from '../../../Permitting/TemplateDialog/EditTemplateIcon';
import DeleteIcon from '../../../Permitting/TemplateDialog/DeleteTemplateIcon';
import MultipleSelectAutoComplete from '../../../../components/common/MultipleSelectAutoComplete';
import Menu from '@material-ui/icons/Menu';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { useState } from 'react';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiIconButton-root': {
      borderRadius: 0,
    },
    '& .highcharts-credits': {
      display: 'none',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  button: {
    color: theme.palette.primary.main,
    fontSize: 10,
  },
  container: {
    maxHeight: '70vh',
    minHeight: '65vh',
  },
  headerButton: {
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: '16px',
  },
  tableRow: {
    padding: theme.spacing(1.5),
  },
  tableHeader: {
    backgroundColor: '#335977',
  },
  headerContainer: {
    color: '#FFE785',
    fontSize: 13,
    fontWeight: 'bold',
    position: 'sticky',
    padding: 0,
    border: 'none',
    backgroundColor: '#fff',
  },
  headerText: {
    color: '#FFE785',
    fontSize: 13,
    fontWeight: 'bold',
    backgroundColor: '#335977',
    position: 'sticky',
    display: 'flex',
  },
  searchTableCell: {
    display: 'flex',
    height: theme.spacing(4.25),
    padding: theme.spacing(1.5),
    backgroundColor: '#fff',
  },
  inputField: {
    height: theme.spacing(1.75),
  },
  headerButtonText: {
    height: theme.spacing(4),
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: '16px',
    width: 'max-content',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  headerSideElements: {
    display: 'flex',
  },
  selectContainer: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  clearFilterButton: {
    fontWeight: 400,
    height: theme.spacing(4.5),
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.6),
    marginRight: theme.spacing(2),
  },
  displayOptionText: {
    fontSize: theme.spacing(1.9),
    lineHeight: theme.spacing(0.18),
  },
  displayOptionBox: {
    width: theme.spacing(28),
    height: theme.spacing(5, '!important'),
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap',
      overflow: 'hidden',
      height: theme.spacing(4.4),
    },
  },
  styleSelect: {
    height: theme.spacing(4.25),
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  inputCheckboxForm: {
    width: theme.spacing(17),
  },
  textEditColor: {
    fontSize: '14px',
    color: '#008EEF',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  textDeleteColor: {
    fontSize: '14px',
    color: '#EF212C',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  setPointerCursor: {
    cursor: 'pointer',
  },
  menu: {
    cursor: 'pointer',
  },
  menuOptions: {
    position: 'absolute',
    top: '0',
    right: '0',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%',
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    padding: 0,
  },
  displayFlexButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  leftPaddingDialog: {
    paddingLeft: theme.spacing(4),
  },
  rightPadding: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0.5),
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3, 2),
  },
  cancelButton: {
    borderColor: 'black',
  },
  saveButton: {
    color: 'white',
    boxShadow: 'none',
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  pipe: {
    borderLeft: '1px solid #D3D3D3;',
    height: theme.spacing(2.75),
  },
});

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export class StatusDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.event = null;
    this.state = {
      page: 0,
      showAddStructuralPopup: false,
      selectedStructural: false,
      isDelete: false,
      showEditStructuralPopup: false,
      editBasicDetails: {
        edit_rack_details: '',
        edit_standoff_details: '',
        edit_assembly: [],
        edit_roof_material: '',
      },
      sort_column: '',
      rowsPerPage: DEFAULT_ROW_PER_PAGE,
      sort_order: '',
      modifiedDate: '',
      modifiedDateText: '',
      modifiedDateValue: '',
      isFromModifiedDate: false,
      selectedDate: '',
      isDateRangeOpen: false,
      enableButton: false,
      dateRange: {},
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
      isServiceRoleFetched: false,
      selectedModifiedBy: ['All'],
      selectedRooftype: ['All'],
      selectedRack: ['All'],
      selectedStandoff: ['All'],
      filterValues: {},
      structuralAssemblyTotalElements: '',
      usersList: [],
      roofMaterialName: '',
      id: 'new',
      rooftypesList: [],
      racksList: [],
      standoffsList: [],
      basicDetails: {
        rack_details: '',
        standoff_details: '',
        assembly: [],
        modified_by: '',
        created_by: '',
        modified_at: '',
        roof_material: '',
      },
      fileUploadLogs: {
        assembly: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
      },
      errorData: {},
      recordSubmitted: false,
      readOnly: this.isReadOnly(),
    };
    this.timeout = null;
    this.filterParams = { ...this.filterParams };
    this.selectedModifiedByValue = 0;
    this.selectedRooftypeValue = 0;
    this.selectedRackValue = 0;
    this.selectedStandoffValue = 0;
    this.setChartData = false;
  }

  MAX_ALLOWED_FILE_SIZE = 53200;

  sortData(column_id) {
    let sOrder = '';
    const structuralType = 'structuralAssembly';
    if (this.state.sort_column === column_id) {
      if (this.state.sort_order === 'asc') {
        sOrder = 'desc';
      } else {
        sOrder = 'asc';
      }
      this.props.getAllStructural({
        structuralType,
        recordCount: this.state.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: sOrder,
        ...this.filterParams,
      });
      this.setState({ sort_order: sOrder }, () => {
        this.saveSearchFilters();
      });
    } else {
      this.props.getAllStructural({
        structuralType,
        recordCount: this.state.rowsPerPage,
        sort_column: column_id,
        sort_order: 'asc',
        ...this.filterParams,
      });
      this.setState({ sort_column: column_id, sort_order: 'asc' }, () => {
        this.saveSearchFilters();
      });
    }
  }

  saveSearchFilters = () => {
    const state = this.state;
    const filterParams = this.filterParams;
    const searchFilters = {
      filterParams: filterParams,
      page: state.page,
      rowsPerPage: state.rowsPerPage,
      sort_column: state.sort_column,
      sort_order: state.sort_order,
      modifiedDate: state.modifiedDate,
      modifiedDateValue: state.modifiedDateValue,
      modifiedDateText: state.modifiedDateText,
      isFromModifiedDate: state.isFromModifiedDate,
      selectedDate: state.selectedDate,
      dateRange: state.dateRange,
      filterDropdown: filterParams.filterDropdown,
      selectedModifiedBy: state.selectedModifiedBy,
      selectedRooftype: state.selectedRooftype,
      selectedRack: state.selectedRack,
      selectedStandoff: state.selectedStandoff,
      usersList: state.usersList,
      rooftypesList: state.rooftypesList,
      racksList: state.racksList,
      standoffsList: state.standoffsList,
      roofMaterialName: state.roofMaterialName,
    };
    localStorage.setItem('structuralAssemblyFilter', JSON.stringify(searchFilters));
    this.props.setStructuralAssemblyFilters(searchFilters);
  };

  clearFilters() {
    this.filterParams = { filter_record: '' };

    this.setState(
      {
        page: 0,
        rowsPerPage: DEFAULT_ROW_PER_PAGE,
        sort_column: '',
        sort_order: '',
        modifiedDate: '',
        modifiedDateValue: '',
        modifiedDateText: '',
        isFromModifiedDate: false,
        selectedDate: '',
        selectedModifiedBy: ['All'],
        selectedRooftype: ['All'],
        selectedRack: ['All'],
        selectedStandoff: ['All'],
        roofMaterialName: '',
      },
      () => {
        this.handleFilter();
      }
    );
  }

  handleListFilter = (e, search = {}, value = '') => {
    const searchKey = search.key;
    let { selectedModifiedByValue, selectedRackValue, selectedStandoffValue, selectedRooftypeValue } = this.state;
    let inputValue = e.target.value;
    if (searchKey != 'assembly') {
      if (searchKey === 'rack_details') {
        const inputLength = value.length;
        const indexOfAll = value.indexOf('All');
        if (includes(value, 'All')) {
          selectedRackValue = indexOfAll === inputLength - 1 ? ['All'] : value.filter((item) => item !== 'All');
        } else {
          selectedRackValue = value;
        }
        let rackIDs;
        this.setState({ selectedRack: selectedRackValue }, () => {
          rackIDs = this.props.racksList
            .filter((obj) => selectedRackValue.includes(obj['name']))
            .map((item) => item['rack_id']);
          this.filterParams[searchKey] = rackIDs;
          this.handleFilter();
        });
      } else if (searchKey === 'standoff_details') {
        const inputLength = value.length;
        const indexOfAll = value.indexOf('All');
        if (includes(value, 'All')) {
          selectedStandoffValue = indexOfAll === inputLength - 1 ? ['All'] : value.filter((item) => item !== 'All');
        } else {
          selectedStandoffValue = value;
        }
        let standoffIDs;
        this.setState({ selectedStandoff: selectedStandoffValue }, () => {
          standoffIDs = this.props.standoffsList
            .filter((obj) => selectedStandoffValue.includes(obj['name']))
            .map((item) => item['standoff_id']);
          this.filterParams[searchKey] = standoffIDs;
          this.handleFilter();
        });
      } else if (searchKey === 'modified_by') {
        const inputLength = inputValue.length;
        const indexOfAll = inputValue.indexOf('All');
        if (includes(inputValue, 'All')) {
          selectedModifiedByValue =
            indexOfAll === inputLength - 1 ? ['All'] : inputValue.filter((item) => item !== 'All');
        } else {
          selectedModifiedByValue = inputValue;
        }
        let modifiedByIDs;
        this.setState({ selectedModifiedBy: selectedModifiedByValue }, () => {
          modifiedByIDs = this.props.usersList
            .filter((obj) => selectedModifiedByValue.includes(obj['full_name']))
            .map((item) => item['user_id']);
          this.filterParams[searchKey] = modifiedByIDs;
          this.handleFilter();
        });
      } else if (searchKey === 'roof_material') {
        const inputLength = value.length;
        const indexOfAll = value.indexOf('All');
        if (includes(value, 'All')) {
          selectedRooftypeValue = indexOfAll === inputLength - 1 ? ['All'] : value.filter((item) => item !== 'All');
        } else {
          selectedRooftypeValue = value;
        }
        let rooftypeIDs;
        this.setState({ selectedRooftype: selectedRooftypeValue }, () => {
          rooftypeIDs = this.props.rooftypesList
            .filter((obj) => selectedRooftypeValue.includes(obj['name']))
            .map((item) => item['key']);
          this.filterParams[searchKey] = rooftypeIDs;
          this.handleFilter();
        });
      } else {
        this.filterParams[searchKey] = inputValue;
        this.handleFilter();
      }
    }
  };

  handleFilter = () => {
    const structuralType = 'structuralAssembly';
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getAllStructural({
        structuralType,
        recordCount: this.state.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        ...this.filterParams,
        successCb: () => {
          this.setState({
            isServiceRoleFetched: true,
            structuralAssemblyTotalElements: this.props.structuralAssemblyTotalElements,
            page: 0,
          });
          this.saveSearchFilters();
        },
      });
    }, 1000);
  };

  handleFilterDateChange = (event, column) => {
    let value = get(event, 'target.value', '');
    if (!isEmpty(value)) {
      this.setState({ modifiedDateValue: value });
    } else {
      value = this.state.modifiedDateValue;
    }
    let showDatePicker = false;
    const selectedDateFilter = DATE_FILTER_LIST.find((dateFilter) => dateFilter.key === value);
    if (value === 'date_range') {
      showDatePicker = true;
      this.setState({ isDateRangeOpen: true });
      this.setState({ isFromModifiedDate: true });
    } else if (['specific_date', 'all_dates_before', 'all_dates_after'].includes(value)) {
      showDatePicker = true;
      this.setState({ selectSpecificDateOpen: true });
      this.setState({ isFromModifiedDate: true });
      if (value === 'all_dates_before') {
        this.setState({ isAllDaysBefore: true });
      } else if (value === 'all_dates_after') {
        this.setState({ isAllDaysAfter: true });
      } else {
        this.setState({ isAllDaysBefore: false, isAllDaysAfter: false });
      }
    }
    if (!showDatePicker) {
      const startSearchKey = 'from_modified_at';
      const endSearchKey = 'to_modified_at';
      const todayDate = new Date();
      const todayStartingDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
      switch (value) {
        case 'today':
          this.filterParams[startSearchKey] = todayStartingDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'past_7_days':
          const weekEarlierDate = new Date(todayStartingDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          this.filterParams[startSearchKey] = weekEarlierDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'month_date':
          const startMonthDate = new Date(moment().startOf('month'));
          this.filterParams[startSearchKey] = startMonthDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'year_date':
          const startYearDate = new Date(moment().startOf('year'));
          this.filterParams[startSearchKey] = startYearDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'clear_dates':
          value = '';
          this.filterParams[startSearchKey] = '';
          this.filterParams[endSearchKey] = '';
          break;
        default:
          break;
      }
      this.handleFilter();
      const dateText = value === '' ? '' : get(selectedDateFilter, 'text', '');
      this.setState({ modifiedDateText: dateText });
    }
  };

  handleCloseDateRange = () => {
    this.setState({
      isDateRangeOpen: false,
      enableButton: false,
      isFromModifiedDate: false,
    });
  };

  handleDateRangeChange = (range) => {
    this.setState({ dateRange: range });
    !this.state.enableButton && this.setState({ enableButton: true });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    !this.state.enableSelectedButton && this.setState({ enableSelectedButton: true });
  };

  handleSaveDateRange = () => {
    this.setState({ isDateRangeOpen: false, enableButton: false });
    const { startDate, endDate } = this.state.dateRange;
    const modifiedStartDate = moment(startDate).format('M/D/YY');
    const modifiedEndDate = moment(endDate).format('M/D/YY');
    const dateFilterText = `${modifiedStartDate} - ${modifiedEndDate}`;
    let startSearchKey;
    let endSearchkey;
    if (this.state.isFromModifiedDate) {
      startSearchKey = 'from_modified_at';
      endSearchkey = 'to_modified_at';
      this.setState({
        modifiedDateText: dateFilterText,
        isFromModifiedDate: false,
      });
    }
    this.filterParams[startSearchKey] = new Date(modifiedStartDate).getTime();
    this.filterParams[endSearchkey] = new Date(modifiedEndDate).getTime() + 86400000;
    this.handleFilter();
  };

  handleSaveSelectedDate = () => {
    this.setState({
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
    });
    let filterText = `${moment(this.state.selectedDate).format('M/D/YY')}`;
    const filterDate = new Date(filterText);
    let dateFilterText = filterText;
    let startSearchKey;
    let endSearchKey;
    if (this.state.isFromModifiedDate) {
      startSearchKey = 'from_modified_at';
      endSearchKey = 'to_modified_at';
    }
    if (this.state.isAllDaysBefore) {
      this.filterParams[startSearchKey] = '';
      this.filterParams[endSearchKey] = filterDate.getTime();
      dateFilterText = ` - ${dateFilterText}`;
    } else if (this.state.isAllDaysAfter) {
      this.filterParams[startSearchKey] = filterDate.getTime();
      this.filterParams[endSearchKey] = '';
      dateFilterText = `${dateFilterText} - `;
    } else {
      this.filterParams[startSearchKey] = filterDate.getTime();
      this.filterParams[endSearchKey] = filterDate.getTime() + 86400000;
    }
    this.handleFilter();
    this.setState({
      isAllDaysBefore: false,
      isAllDaysAfter: false,
    });
    if (this.state.isFromModifiedDate) {
      this.setState({ modifiedDateText: dateFilterText });
    }
  };

  getDateValueAndText = (column) => {
    const stateDateValueName = 'modifiedDateValue';
    const stateDateTextName = 'modifiedDateText';
    return {
      dateValue: get(this.state, stateDateValueName),
      dateText: get(this.state, stateDateTextName),
    };
  };

  handleCloseSelectedDate = () => {
    this.setState({
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
      isAllDaysBefore: false,
      isAllDaysAfter: false,
      selectedDate: '',
      isFromModifiedDate: false,
    });
  };

  toggle = () => this.setState({ isDateRangeOpen: !this.state.isDateRangeOpen });

  handleChangeRowsPerPage(event) {
    if (event.target.value) {
      const structuralType = 'structuralAssembly';
      this.props.getAllStructural({
        structuralType,
        recordCount: event.target.value,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        ...this.filterParams,
        successCb: () => {
          this.setState(
            {
              page: 0,
              rowsPerPage: event.target.value,
              structuralAssemblyTotalElements: this.props.structuralAssemblyTotalElements,
            },
            () => {
              this.saveSearchFilters();
            }
          );
        },
      });
    }
  }

  handleChangePage(event, newPage) {
    let after = '',
      before = '';
    const { page } = this.state;
    const { pagingData } = this.props;
    if (newPage > page && pagingData['after'] !== null) after = pagingData['after'];
    if (newPage < page && pagingData['before'] !== null) before = pagingData['before'];
    const structuralType = 'structuralAssembly';
    this.props.getAllStructural({
      after,
      before,
      structuralType,
      recordCount: this.state.rowsPerPage,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      ...this.filterParams,
    });
    this.setState({ page: newPage }, () => {
      this.saveSearchFilters();
    });
  }

  setInitialFilters(initalFilters) {
    const searchFilters = !isEmpty(localStorage.getItem('structuralAssemblyFilter'))
      ? JSON.parse(localStorage.getItem('structuralAssemblyFilter'))
      : initalFilters;

    if (!isEmpty(searchFilters)) {
      this.filterParams = searchFilters.filterParams || searchFilters.filter_params;
      this.rowsPerPage = searchFilters.rowsPerPage;
      this.pageNum = searchFilters.page;
      this.setState(searchFilters, () => {
        this.handleFilter();
      });
    }
  }

  getStructuralAssemblyFilterValues() {
    const {
      page,
      rowsPerPage: rows_per_page,
      sort_column,
      sort_order,
      modifiedDate: modified_date,
      modifiedDateValue: modified_date_value,
      modifiedDateText: modified_date_text,
      isFromModifiedDate: is_from_modified_date,
      selectedDate: selected_date,
      dateRange: date_range,
      selectedModifiedBy: selected_modified_by,
      selectedRooftype: selected_rooftype,
      selectedRack: selected_rack,
      selectedStandoff: selected_standoff,
      usersList: users_list,
      rooftypesList: rooftypes_list,
      racksList: racks_list,
      standoffsList: standoffs_list,
      roofMaterialName: roof_material_name,
    } = this.state;

    return {
      filter_params: {
        ...this.filterParams,
      },
      other_filters: {
        page,
        rows_per_page,
        sort_column,
        sort_order,
        modified_date,
        modified_date_value,
        modified_date_text,
        is_from_modified_date,
        selected_date,
        date_range,
        selected_modified_by,
        selected_rooftype,
        selected_rack,
        selected_standoff,
        users_list,
        rooftypes_list,
        racks_list,
        standoffs_list,
        roof_material_name,
      },
    };
  }

  getUserName(id) {
    const userList = this.props.usersList;
    const currentUser = userList.filter((item) => item['user_id'] === id);
    return isEmpty(currentUser) ? '' : currentUser[0]['full_name'];
  }

  toggleDrawer() {
    this.setState({ selectedStructural: !this.state.selectedStructural });
  }

  getStructuralService() {
    const structuralType = 'structuralAssembly';
    this.timeout = setTimeout(() => {
      this.props.getAllStructural({
        structuralType,
        recordCount: this.state.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        ...this.filterParams,
        successCb: () => {
          this.setState({
            isServiceRoleFetched: true,
            structuralAssemblyTotalElements: this.props.structuralAssemblyTotalElements,
          });
          this.saveSearchFilters();
        },
      });
    }, 1000);
  }

  componentDidMount() {
    this.getStructuralService();

    if (!this.state.showAddStructuralPopup && !this.state.showEditStructuralPopup) {
      if (!isEmpty(this.props.permitServiceRole)) {
        if (!isEmpty(localStorage.getItem('structuralAssemblyFilter'))) {
          this.filterParams = JSON.parse(localStorage.getItem('structuralAssemblyFilter')).filterParams;
        }
        if (!isEmpty(this.props.structuralAssemblyFilters.filter_params)) {
          this.filterParams = this.props.structuralAssemblyFilters.filter_params;
        }
        let initialStructuralAssemblyFilters = {};
        if (!isEmpty(localStorage.getItem('structuralAssemblyFilter'))) {
          initialStructuralAssemblyFilters = JSON.parse(localStorage.getItem('structuralAssemblyFilter'));
        } else {
          initialStructuralAssemblyFilters = isEmpty(this.props.structuralAssemblyFilters)
            ? this.getStructuralAssemblyFilterValues()
            : this.props.structuralAssemblyFilters;
        }
        this.setInitialFilters(initialStructuralAssemblyFilters);
      }
      if (isEmpty(this.state.rooftypesList)) {
        this.props.getAllRoofTypes({
          successCb: () => {
            this.setState({
              rooftypesList: [{ name: 'All', key: null }, ...this.props.rooftypesList],
            });
          },
        });
      }
      if (isEmpty(this.state.racksList)) {
        this.props.getAllRacks({
          successCb: () => {
            this.setState({
              racksList: [
                { rackId: null, name: 'All', rackManufacturer: null, rackModel: null },
                ...this.props.racksList,
              ],
            });
          },
        });
      }
      if (isEmpty(this.state.standoffsList)) {
        this.props.getAllStandoffs({
          successCb: () => {
            this.setState({
              standoffsList: [
                { standoffId: null, name: 'All', standoffManufacturer: null, standoffModel: null },
                ...this.props.standoffsList,
              ],
            });
          },
        });
      }
      if (isEmpty(this.state.usersList)) {
        this.props.getAllUsers({
          successCb: () => {
            this.setState({
              usersList: [
                {
                  id: '',
                  user_id: null,
                  user_name: null,
                  full_name: 'All',
                },
                ...(this.props.usersList ? this.props.usersList : []),
              ],
            });
          },
        });
      }
    } else {
      const { id } = this.props.match.params;
      const requestSource = 'structuralAssembly';
      if (id !== 'new')
        this.props.getStructural({
          id,
          requestSource,
          successCB: (newStructural) => {
            this.handleGetSuccessCallback(newStructural);
          },
          failureCB: () => {
            this.handleCancel();
          },
        });
      if (isEmpty(this.state.rooftypesList)) {
        this.props.getAllRoofTypes({
          successCb: () => {
            this.setState({ rooftypesList: [{ name: 'All', key: null }, ...this.props.rooftypesList] });
          },
        });
      }
      if (isEmpty(this.state.racksList)) {
        this.props.getAllRacks({
          successCb: () => {
            this.setState({
              racksList: [
                { rackId: null, name: 'All', rackManufacturer: null, rackModel: null },
                ...this.props.racksList,
              ],
            });
          },
        });
      }
      if (isEmpty(this.state.standoffsList)) {
        this.props.getAllStandoffs({
          successCb: () => {
            this.setState({
              standoffsList: [
                { standoffId: null, name: 'All', standoffManufacturer: null, standoffModel: null },
                ...this.props.standoffsList,
              ],
            });
          },
        });
      }
    }
  }

  handleSubmit = () => {
    const payload = this.getPayload(this.event);
    payload.successCB = (structural, response) => {
      this.props.createStructuralSuccess({ response });
      this.handleCreateSuccessCallback(structural);
      this.setState({
        basicDetails: {
          rack_details: '',
          standoff_details: '',
          assembly: [],
          modified_by: '',
          created_by: '',
          modified_at: '',
          roof_material: '',
        },
        editBasicDetails: {
          edit_rack_details: '',
          edit_standoff_details: '',
          edit_assembly: [],
          edit_roof_material: '',
        },
      });
      this.handleCancel();
      this.handleCancelEdit();
      this.getStructuralService();
    };
    payload.failureCB = (response) => {
      response = response.error;
      this.props.createStructuralSuccess({ response });
    };
    this.props.createStructural(payload);
  };

  handleDialogClose = () => {
    this.setState({ showAddStructuralPopup: false });
  };

  handleCreateSuccessCallback = (newStructural) => {
    this.handleGetSuccessCallback(newStructural, () => {});
  };

  handleGetSuccessCallback = (structural, callback = () => {}) => {
    const { id = 'new', assembly_url = '' } = structural;

    const enl_catalog_user = isEmpty(structural.enl_catalog_user)
      ? { created_by: '', updated_by: '' }
      : structural.enl_catalog_user;

    this.setState(
      {
        id,
        basicDetails: {
          roof_material: structural?.roofType,
          rack_details: structural?.rackId ? structural['rackName'] : '',
          standoff_details: structural?.standoffId ? structural['standoffName'] : '',
          assembly: !isEmpty(assembly_url) ? [{ url: assembly_url }] : [],
          modified_by: enl_catalog_user['updated_by'],
          modified_at: structural?.updated_at,
          created_by: enl_catalog_user['created_by'],
        },
        readOnly: this.isReadOnly(),
      },
      () => {
        callback();
        if (!isEmpty(this.state.basicDetails.assembly)) {
          getFileMetaData(this.state.basicDetails['assembly'][0], (meta_data) => {
            let basicDetails = {
              ...this.state.basicDetails,
              assembly: [{ ...meta_data, material_id: this.state.id }],
            };
            this.setState({ basicDetails });
          });
        }
      }
    );
  };

  getPayload = () => {
    const { id, basicDetails } = this.state;
    const rackName = basicDetails.rack_details;
    const rackObject = this.state.racksList.filter((val) => val['name'] === rackName);
    const standoffName = basicDetails.standoff_details;
    const standoffObject = this.state.standoffsList.filter((val) => val['name'] === standoffName);
    const roofMaterialName = basicDetails.roof_material;
    const roofMaterialObject = this.state.rooftypesList.filter((val) => val['name'] === roofMaterialName);
    let assemblyFileData = null,
      assembly = {};

    if (!isEmpty(basicDetails['assembly'])) {
      assemblyFileData = basicDetails['assembly'][0]['fileData'];
      assembly = basicDetails['assembly'][0];
    } else {
      if (this.props.structural && !isEmpty(this.props.structural['assembly_url'])) {
        const fileType = 'assembly';
        const requestSource = 'structuralAssembly';
        this.props.deleteStructuralUploadedFile({
          id,
          fileType,
          requestSource,
          successCb: () => {
            basicDetails['assembly'] = [];
            this.setState({ basicDetails });
          },
        });
      }
    }

    return {
      id,
      requestBody: {
        jsonPayload: {
          requestSource: 'structuralAssembly',
          RoofType: roofMaterialObject.length === 1 ? roofMaterialObject[0] : { roofType: roofMaterialName },
          Racks: rackObject.length === 1 ? rackObject[0] : { name: rackName },
          Standoffs: standoffObject.length === 1 ? standoffObject[0] : { name: standoffName },
          roofType: roofMaterialObject[0]['key'],
          rackId: rackObject[0]['rack_id'],
          standoffId: standoffObject[0]['standoff_id'],
        },
        assembly: assemblyFileData,
      },
      successCB: (structural, response = {}) => {
        this.props.createStructuralSuccess({ response });
        this.handleCreateSuccessCallback(structural);
        this.handleDialogClose();
      },
      failureCB: (response) => {
        this.props.createStructuralSuccess({ response });
      },
    };
  };

  handleCancel() {
    this.setState({
      showAddStructuralPopup: false,
      basicDetails: {
        rack_details: '',
        standoff_details: '',
        assembly: [],
        modified_by: '',
        created_by: '',
        modified_at: '',
        roof_material: '',
      },
      editBasicDetails: {
        edit_rack_details: '',
        edit_standoff_details: '',
        edit_assembly: [],
        edit_roof_material: '',
      },
      errorData: {},
    });
  }

  handleCancelDelete() {
    this.setState({ isDelete: false });
  }

  handleCancelEdit() {
    this.setState({
      showEditStructuralPopup: false,
      page: 0,
      editBasicDetails: {
        edit_rack_details: '',
        edit_standoff_details: '',
        edit_assembly: [],
        edit_roof_material: '',
      },
      id: 'new',
      errorData: {},
      basicDetails: {
        rack_details: '',
        standoff_details: '',
        assembly: [],
        modified_by: '',
        created_by: '',
        modified_at: '',
        roof_material: '',
      },
    });
  }

  handleBasicDetails = async (e, key, value) => {
    const basicDetails = this.state.basicDetails;
    let errorData = this.state.errorData;
    if (isEqual(key, 'rack_details')) {
      basicDetails[key] = value;
      this.setState({ ...this.state, basicDetails });
    } else if (isEqual(key, 'standoff_details')) {
      basicDetails[key] = value;
      this.setState({ ...this.state, basicDetails });
    } else if (isEqual(key, 'roof_material')) {
      basicDetails[key] = value;
      this.setState({ ...this.state, basicDetails });
    } else {
      basicDetails[key] = e.target.value;
      if (key === 'created_by') {
        basicDetails['modified_by'] = e.target.value;
      }
    }
    this.setState({ basicDetails: basicDetails });
    this.setState({ ...this.state, basicDetails, errorData });
  };

  setDocumentUploadError(errorDetails = { error: false, msg: '' }, status) {
    let { fileUploadLogs } = this.state;
    fileUploadLogs['assembly'].uploadError = {
      error: errorDetails.error,
      msg: errorDetails.msg,
    };
    if (status) {
      fileUploadLogs['assembly'].uploadStatus = status;
    }
    this.setState({ fileUploadLogs: fileUploadLogs });
  }

  deleteFile = (ind) => {
    // clear the message
    let { basicDetails, fileUploadLogs, errorData, editBasicDetails } = this.state;
    fileUploadLogs['assembly'].uploadError = { error: false, msg: '' };
    this.setState({ fileUploadLogs: fileUploadLogs });
    basicDetails['assembly'] = [];
    delete errorData['assembly'];
    editBasicDetails['edit_assembly'] = [];
    delete errorData['edit_assembly'];

    this.setState({ basicDetails, errorData, editBasicDetails });
  };

  handleFilesUpload = (event) => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' }, '');

    let { fileUploadLogs } = this.state;
    let files = this.state.basicDetails.assembly;

    const setStatus = (status) => {
      fileUploadLogs['assembly'].uploadStatus = status;
      this.setState({ fileUploadLogs: fileUploadLogs });
    };

    const setFilesSize = (filesTotalSize) => {
      fileUploadLogs['assembly'].size = filesTotalSize;
      this.setState({ fileUploadLogs: fileUploadLogs });
    };

    const details = {
      event,
      setStatus,
      setFilesSize,
      documentType: assembly,
      fileLocation: 'solargraf',
      namePrefix: this.getFilePrefixName(this.state.basicDetails),
      uploadFiles: files !== null ? files : [],
      uploadFilesSize: this.state.fileUploadLogs['assembly'].size,
      maxFileUploadSize: this.MAX_ALLOWED_FILE_SIZE,
      setDocumentUploadError: (errorDetails, status) => this.setDocumentUploadError(errorDetails, status),
    };

    uploadFileDetails(details, (uploadFiles) => {
      const { basicDetails, errorData } = this.state;
      let whiteListedFileType = ['dwg', 'application/dwg', 'image/vnd.dwg', 'application/acad', 'application/x-acad'];
      let fileType = uploadFiles[0].fileData.type;
      let fileTypeArray = uploadFiles[0].original_file_name.split('.');
      let fileTypeByName = fileTypeArray[fileTypeArray.length - 1];
      if (!whiteListedFileType.includes(fileType)) {
        if (fileTypeByName.toLowerCase() != 'dwg') {
          errorData['assembly'] = 'Please upload a valid dwg document';
        } else {
          delete errorData['assembly'];
        }
      } else {
        delete errorData['assembly'];
      }

      basicDetails.assembly = uploadFiles;
      this.setState({ basicDetails, errorData });
    });
  };

  handleFileDownload = () => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' }, '');
    let { id, fileUploadLogs, basicDetails, editBasicDetails } = this.state;
    if (id === 'new') {
      fileUploadLogs['assembly'].uploadError = {
        error: true,
        msg: 'Unable to preview the file. Please try after some time.',
      };
      this.setState({
        fileUploadLogs: fileUploadLogs,
      });
    } else {
      const name = 'assembly';
      if (basicDetails[name][0]['url']) {
        window.open(basicDetails[name][0]['url'], '_blank');
      } else if (editBasicDetails['edit_assembly'][0]['url']) {
        window.open(editBasicDetails['edit_assembly'][0]['url'], '_blank');
      } else {
        fileUploadLogs['assembly'].uploadError = {
          error: true,
          msg: 'Unable to preview the file. Please try after some time.',
        };
        this.setState({
          fileUploadLogs: fileUploadLogs,
        });
      }
    }
  };

  fileIcon = (fName) => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
      fType = 'document';
    }
    const DocIcon = Icons[fType];
    return <DocIcon />;
  };

  getFilePrefixName = (basicDetails) => {
    let filePrefixName = '';
    if (!isEmpty(basicDetails.roof_material)) {
      filePrefixName = basicDetails.roof_material;
    }
    if (!isEmpty(basicDetails.rack_details)) {
      filePrefixName += '_' + basicDetails.rack_details;
    }
    if (!isEmpty(basicDetails.standoff_details)) {
      filePrefixName += '_' + basicDetails.standoff_details;
    }
    return filePrefixName;
  };

  isReadOnly() {
    //readonly is false for new Record
    if (this.props.match.params.id === 'new') return false;
    const { permitServiceRole, structural } = this.props;
    return (
      includes(['db_user', 'db_admin'], permitServiceRole) ||
      includes(['db_user', 'db_admin', 'service_admin'], permitServiceRole)
    );
  }

  isButtonVisible(buttonName) {
    const { readOnly } = this.state;
    const { permitServiceRole, structural } = this.props;
    let isVisible = false;
    const dbWithApproved = includes(['db_admin', 'db_user'], permitServiceRole);
    const dbAdminWithPending = includes(['db_admin', 'service_admin'], permitServiceRole);
    const dbAdminUserAccess = includes(['db_admin', 'db_user'], permitServiceRole);
    switch (buttonName) {
      case 'edit':
        isVisible = readOnly && !includes(VIEW_ONLY_ROLES, permitServiceRole) && !dbWithApproved && !dbAdminUserAccess;
        break;
      case 'submit':
        isVisible =
          !dbAdminWithPending &&
          !dbWithApproved &&
          !readOnly &&
          !dbAdminUserAccess &&
          includes(['db_admin', 'db_user', 'service_admin'], permitServiceRole);
        break;
      case 'save':
        isVisible = !dbAdminUserAccess && includes(['db_admin', 'db_user', 'service_admin'], permitServiceRole);
        break;
      default:
        break;
    }
    return isVisible;
  }

  deleteButtonClicked = () => {
    const requestSource = 'structuralAssembly';
    const { id } = this.state;
    this.props.deleteStructural({
      id: id,
      requestSource,
      successCb: () => {
        this.setState({ isDelete: false, id: 'new', page: 0 });
        this.getStructuralService();
      },
    });
  };

  downloadAssemblyFile = (linkUrl, fileName) => {
    fetch(linkUrl).then((response) =>
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName + '.' + linkUrl.split('/').pop().split('.').pop();
        link.click();
      })
    );
  };

  getRoofTypeName = (row) => {
    const roofType = this.props.rooftypesList.find((obj) => obj['key'] === get(row, 'roofType'));
    return roofType ? roofType.name : get(row, 'roofType');
  }

  render() {
    const {
      classes,
      currentlySending,
      message,
      messageType,
      permitServiceRole,
      getAllStructuralAssemblyInProgress,
      structuralAssemblyList,
      userSelectionData,
    } = this.props;
    const {
      page,
      rowsPerPage,
      sort_column,
      sort_order,
      showAddStructuralPopup,
      showEditStructuralPopup,
      structuralAssemblyTotalElements,
      usersList,
      selectedStructural,
      id,
      basicDetails,
      fileUploadLogs,
      readOnly,
      editBasicDetails,
    } = this.state;
    const racksList = this.state.racksList.map((item) => item['name']);
    const standoffsList = this.state.standoffsList.map((item) => item['name']);
    const rooftypesList = this.state.rooftypesList.map((item) => item['name']);

    return (
      <div className={classes.root}>
        <Box className={classes.headerWrapper}>
          <Typography variant="h5" component="h5" className={classes.header}></Typography>
          <Box className={classes.headerSideElements}>
            <OutlinedButton
              text={'Clear Filter'}
              handleClick={() => {
                this.clearFilters();
              }}
              style={classes.clearFilterButton}
              statusDashboardBtnStyle={classes.clearFilterButton}
            />
            {!includes(VIEW_ONLY_ROLES, permitServiceRole) && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => this.setState({ showAddStructuralPopup: true })}
              >
                Create a New Record
              </Button>
            )}
          </Box>
        </Box>
        {currentlySending === true ? (
          <Loader loading={currentlySending} />
        ) : (
          <Fragment>
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <Paper>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader>
                      <TableHead className={classes.tableHeader}>
                        <TableRow className={classes.tableRow}>
                          {STRUCTURAL_ASSEMBLY_LISTING_COLUMNS.map((column, index) => {
                            return (
                              <TableCell
                                className={classes.headerContainer}
                                key={`structural_assembly_columns_${index}`}
                                style={{ minWidth: column.minWidth + 'px' }}
                              >
                                <TableCell className={classes.headerText} key={index}>
                                  {column.key != 'menu' ? (
                                    <IconButton size="small" className={classes.headerButtonText} disableRipple={true}>
                                      {column.label}
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      size="small"
                                      className={classes.headerButtonText}
                                      disableRipple={true}
                                      disabled={true}
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  className={classes.searchTableCell}
                                  colSpan={STRUCTURAL_ASSEMBLY_LISTING_COLUMNS.length}
                                >
                                  {column.hasSearch && column.key === 'roof_material' && (
                                    <MultipleSelectAutoComplete
                                      options={rooftypesList}
                                      selectedValue={this.state.selectedRooftype}
                                      onChangeFunction={(e, value) => this.handleListFilter(e, column, value)}
                                      displayOptionBox={classes.displayOptionBox}
                                      displayOptionText={classes.displayOptionText}
                                      column={column}
                                    />
                                  )}
                                  {/* {column.key === 'modified_at' && (
                                    <DateFilter
                                      dateValue={this.getDateValueAndText(column).dateValue}
                                      dateText={this.getDateValueAndText(column).dateText}
                                      dateRange={this.state.dateRange}
                                      enableButton={this.state.enableButton}
                                      enableSelectedButton={this.state.enableSelectedButton}
                                      selectedDate={this.state.selectedDate}
                                      selectSpecificDateOpen={this.state.selectSpecificDateOpen}
                                      isDateRangeOpen={this.state.isDateRangeOpen}
                                      handleSaveDateRange={() => this.handleSaveDateRange()}
                                      handleFilterDateChange={(e) => this.handleFilterDateChange(e, column)}
                                      handleCloseDateRange={() => this.handleCloseDateRange()}
                                      handleDateRangeChange={(range) => this.handleDateRangeChange(range)}
                                      handleCloseSelectedDate={() => this.handleCloseSelectedDate()}
                                      handleSaveSelectedDate={() => this.handleSaveSelectedDate()}
                                      handleDateChange={(date) => this.handleDateChange(date)}
                                      toggle={() => this.toggle()}
                                    />
                                  )} */}
                                  {column.hasSearch && column.key === 'rack_details' && (
                                    <MultipleSelectAutoComplete
                                      options={racksList}
                                      selectedValue={this.state.selectedRack}
                                      onChangeFunction={(e, value) => this.handleListFilter(e, column, value)}
                                      displayOptionBox={classes.displayOptionBox}
                                      displayOptionText={classes.displayOptionText}
                                      column={column}
                                    />
                                  )}
                                  {column.hasSearch && column.key === 'standoff_details' && (
                                    <MultipleSelectAutoComplete
                                      options={standoffsList}
                                      selectedValue={this.state.selectedStandoff}
                                      onChangeFunction={(e, value) => this.handleListFilter(e, column, value)}
                                      displayOptionBox={classes.displayOptionBox}
                                      displayOptionText={classes.displayOptionText}
                                      column={column}
                                    />
                                  )}
                                  {column.key === 'assembly' && (
                                    <TextField disabled InputProps={{ disableUnderline: true }} />
                                  )}
                                  {/* {column.hasSearch && column.key === 'modified_by' && (
                                    <FormControl className={classes.inputCheckboxForm}>
                                      <Select
                                        variant="outlined"
                                        MenuProps={MenuProps}
                                        onChange={(e) => this.handleListFilter(e, column)}
                                        value={this.state.selectedModifiedBy}
                                        className={classes.styleSelect}
                                        renderValue={(selected) =>
                                          selectMultipleRender(selected, usersList, 'full_name')
                                        }
                                        multiple
                                      >
                                        {usersList.map((data, index) => {
                                          return (
                                            <MenuItem key={index} value={data.full_name}>
                                              <Checkbox
                                                className={classes.inputCheckbox}
                                                checked={this.state.selectedModifiedBy.indexOf(data.full_name) > -1}
                                              />
                                              <ListItemText primary={data.full_name} />
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  )} */}
                                </TableCell>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      {getAllStructuralAssemblyInProgress === true || !this.state.isServiceRoleFetched ? (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={STRUCTURAL_ASSEMBLY_LISTING_COLUMNS.length}
                              style={{ textAlign: 'center', border: 'none' }}
                            >
                              <br />
                              <br />
                              <br />
                              <CircularProgress size={15} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : isEqual(structuralAssemblyList, []) === true ? (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={STRUCTURAL_ASSEMBLY_LISTING_COLUMNS.length}
                              style={{ textAlign: 'center' }}
                            >
                              <Typography variant="caption">** No Records Available **</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {structuralAssemblyList.map((row, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{this.getRoofTypeName(row) || 'NA'}</TableCell>
                                <TableCell>
                                  {get(row['fastener']['manufacturer']['name'], 'en') +
                                    ' - ' +
                                    get(row['fastener']['name'], 'en') || 'NA'}
                                </TableCell>
                                <TableCell>
                                  {get(row['rack']['manufacturer']['name'], 'en') +
                                    ' - ' +
                                    get(row['rack']['name'], 'en') || 'NA'}
                                </TableCell>
                                <TableCell>
                                  <Box>
                                    <IconButton
                                      fontSize="small"
                                      className={classes.setPointerCursor}
                                      onClick={() => {
                                        this.downloadAssemblyFile(
                                          get(row, 'assemblyUrl'),
                                          '' +
                                            get(row, 'roofType') +
                                            ' ' +
                                            get(row['rack']['manufacturer']['name'], 'en') +
                                            '-' +
                                            get(row['rack']['name'], 'en') +
                                            ' ' +
                                            get(row['fastener']['manufacturer']['name'], 'en') +
                                            '-' +
                                            get(row['fastener']['name'], 'en')
                                        );
                                      }}
                                    >
                                      <CloudDownloadOutlinedIcon />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                                <TableCell>{this.getUserName(get(row, 'updatedBy')) || 'NA'}</TableCell>
                                <TableCell>
                                  {get(row, 'updatedAt') !== null
                                    ? moment(get(row, 'updatedAt')).format('DD MMM YYYY hh:mm A')
                                    : 'NA'}
                                </TableCell>
                                <TableCell>
                                  {!includes(VIEW_ONLY_ROLES, permitServiceRole) && (
                                    <div className={clsx(classes.rightPadding, classes.displayFlex)}>
                                      <Typography
                                        onClick={() => {
                                          this.setState(
                                            {
                                              showEditStructuralPopup: true,
                                              id: get(row, 'id'),
                                              editBasicDetails: {
                                                edit_rack_details:
                                                  get(row['rack']['manufacturer']['name'], 'en') +
                                                  ' - ' +
                                                  get(row['rack']['name'], 'en'),
                                                edit_standoff_details:
                                                  get(row['fastener']['manufacturer']['name'], 'en') +
                                                  ' - ' +
                                                  get(row['fastener']['name'], 'en'),
                                                edit_assembly: [{ url: get(row, 'assemblyUrl') }],
                                                edit_roof_material: this.getRoofTypeName(row),
                                              },
                                            },
                                            () => {
                                              if (!isEmpty(this.state.editBasicDetails.edit_assembly)) {
                                                getFileMetaData(
                                                  this.state.editBasicDetails.edit_assembly[0],
                                                  (meta_data) => {
                                                    let editBasicDetails = {
                                                      ...this.state.editBasicDetails,
                                                      edit_assembly: [{ ...meta_data, material_id: this.state.id }],
                                                    };
                                                    this.setState({ editBasicDetails });
                                                  }
                                                );
                                              }
                                            }
                                          );
                                        }}
                                        className={clsx(classes.textEditColor, classes.setPointerCursor)}
                                        display="inline"
                                      >
                                        Edit
                                      </Typography>
                                      <IconButton
                                        onClick={() => {
                                          this.setState(
                                            {
                                              showEditStructuralPopup: true,
                                              id: get(row, 'id'),
                                              editBasicDetails: {
                                                edit_rack_details:
                                                  get(row['rack']['manufacturer']['name'], 'en') +
                                                  ' - ' +
                                                  get(row['rack']['name'], 'en'),
                                                edit_standoff_details:
                                                  get(row['fastener']['manufacturer']['name'], 'en') +
                                                  ' - ' +
                                                  get(row['fastener']['name'], 'en'),
                                                edit_assembly: [{ url: get(row, 'assemblyUrl') }],
                                                edit_roof_material: this.getRoofTypeName(row),
                                              },
                                            },
                                            () => {
                                              if (!isEmpty(this.state.editBasicDetails.edit_assembly)) {
                                                getFileMetaData(
                                                  this.state.editBasicDetails.edit_assembly[0],
                                                  (meta_data) => {
                                                    let editBasicDetails = {
                                                      ...this.state.editBasicDetails,
                                                      edit_assembly: [{ ...meta_data, material_id: this.state.id }],
                                                    };
                                                    this.setState({ editBasicDetails });
                                                  }
                                                );
                                              }
                                            }
                                          );
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <span className={clsx(classes.pipe, classes.rightPadding)} />
                                      <Typography
                                        onClick={() => {
                                          this.setState({ isDelete: true, id: get(row, 'id') });
                                        }}
                                        className={clsx(classes.textDeleteColor, classes.setPointerCursor)}
                                        display="inline"
                                      >
                                        Delete
                                      </Typography>
                                      <IconButton
                                        onClick={() => {
                                          this.setState({ isDelete: true, id: get(row, 'id') });
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={structuralAssemblyTotalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => this.handleChangePage(e, newPage)}
                    onRowsPerPageChange={(e) => this.handleChangeRowsPerPage(e)}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Fragment>
        )}
        {message && messageType && <Snackbar severity={messageType} message={message} />}
        {this.state.showAddStructuralPopup && (
          <StructuralAssemblyInfo
            id={id}
            fileIcon={this.fileIcon}
            readOnly={readOnly}
            racksList={racksList.filter((item) => item !== 'All')}
            rooftypesList={rooftypesList.filter((item) => item !== 'All')}
            userSelectionData={userSelectionData}
            standoffsList={standoffsList.filter((item) => item !== 'All')}
            basicDetails={basicDetails}
            fileUploadLogs={fileUploadLogs}
            deleteFile={this.deleteFile}
            errorData={this.state.errorData}
            stateRooftypesDetails={this.props.rooftypesList}
            stateRacksDetails={this.props.racksList}
            stateStandoffDetails={this.props.standoffsList}
            // structuralAssemblyList={structuralAssemblyList}
            handleSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
            handleFilesUpload={this.handleFilesUpload}
            handleFileDownload={this.handleFileDownload}
            handleBasicDetails={this.handleBasicDetails}
            showAddStructuralPopup={this.state.showAddStructuralPopup}
            handleShowStructuralPopup={() =>
              this.setState({
                showAddStructuralPopup: !this.state.showAddStructuralPopup,
                basicDetails: {
                  rack_details: '',
                  standoff_details: '',
                  assembly: [],
                  modified_by: '',
                  created_by: '',
                  modified_at: '',
                  roof_material: '',
                },
                errorData: {},
              })
            }
          />
        )}
        {this.state.showEditStructuralPopup && (
          <EditStructuralAssembly
            id={id}
            fileIcon={this.fileIcon}
            readOnly={readOnly}
            racksList={racksList.filter((item) => item !== 'All')}
            rooftypesList={rooftypesList.filter((item) => item !== 'All')}
            standoffsList={standoffsList.filter((item) => item !== 'All')}
            basicDetails={basicDetails}
            fileUploadLogs={fileUploadLogs}
            deleteFile={this.deleteFile}
            errorData={this.state.errorData}
            handleSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
            handleFilesUpload={this.handleFilesUpload}
            handleFileDownload={this.handleFileDownload}
            handleBasicDetails={this.handleBasicDetails}
            stateRooftypesDetails={this.props.rooftypesList}
            stateRacksDetails={this.props.racksList}
            stateStandoffDetails={this.props.standoffsList}
            showEditStructuralPopup={this.state.showEditStructuralPopup}
            editBasicDetails={editBasicDetails}
            handleShowEditStructuralPopup={() =>
              this.setState({
                showEditStructuralPopup: !this.state.showEditStructuralPopup,
                editBasicDetails: {
                  edit_rack_details: '',
                  edit_standoff_details: '',
                  edit_assembly: [],
                  edit_roof_material: '',
                },
                id: 'new',
                errorData: {},
                basicDetails: {
                  rack_details: '',
                  standoff_details: '',
                  assembly: [],
                  modified_by: '',
                  created_by: '',
                  modified_at: '',
                  roof_material: '',
                },
              })
            }
          />
        )}
        {this.state.isDelete && (
          <DeleteStructuralAssembly
            id={id}
            isDelete={this.state.isDelete}
            deleteButtonClicked={this.deleteButtonClicked}
            handleIsDelete={() => this.setState({ isDelete: !this.state.isDelete, id: 'new' })}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllStructural: (payload) => dispatch(getAllStructural(payload)),
  createStructural: (payload) => dispatch(createStructural(payload)),
  setStructuralAssemblyFilters: (payload) => dispatch(setStructuralAssemblyFilters(payload)),
  getUserSelectionData: () => dispatch(getUserSelectionData()),
  getAllRoofTypes: (payload) => dispatch(getAllRoofTypes(payload)),
  getAllRacks: (payload) => dispatch(getAllRacks(payload)),
  getAllStandoffs: (payload) => dispatch(getAllStandoffs(payload)),
  getAllUsers: (payload) => dispatch(getAllUsers(payload)),
  getStructural: (payload) => dispatch(getStructural(payload)),
  createStructuralSuccess: (payload) => dispatch(createStructuralSuccess(payload)),
  deleteStructuralUploadedFile: (payload) => dispatch(deleteStructuralUploadedFile(payload)),
  deleteStructural: (payload) => dispatch(deleteStructural(payload)),
});

const mapStateToProps = (state) => ({
  currentlySending: state.structuralAssemblyReducer.currentlySending,
  usersList: state.structuralAssemblyReducer.usersList,
  message: state.structuralAssemblyReducer.message,
  messageType: state.structuralAssemblyReducer.messageType,
  permitServiceRole: state.appReducer.permitServiceRole,
  getAllStructuralAssemblyInProgress: state.structuralAssemblyReducer.getAllStructuralAssemblyInProgress,
  structuralAssemblyList: state.structuralAssemblyReducer.structuralAssemblyList,
  structuralAssemblyTotalElements: state.structuralAssemblyReducer.structuralAssemblyTotalElements,
  structuralAssemblyFilters: state.structuralAssemblyReducer.structuralAssemblyFilters,
  userSelectionData: state.permitReducer.userSelectionData,
  racksList: state.structuralAssemblyReducer.racksList,
  standoffsList: state.structuralAssemblyReducer.standoffsList,
  rooftypesList: state.structuralAssemblyReducer.rooftypesList,
  structural: state.structuralAssemblyReducer.structural,
  pagingData: state.structuralAssemblyReducer.pagingData,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusDashboard));
