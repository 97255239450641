import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Avatar,
  Toolbar,
  List,
  ListItem,
  Divider,
  Tooltip,
  IconButton,
  Button,
  ListItemText,
} from '@material-ui/core';
import HeaderImage from './Icons/enphase.png';
import settings from '../../utils/settings';
import { includes, isEmpty } from '../../utils/lodash';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { setToken, forwardTo } from '../../utils/helper';
import { ENSTORE_URL, ENLIGHTEN_SUPPORT_URL } from '../../config/constants';
import Logout from './Icons/Logout';
import StoreIcon from './Icons/StoreIcon';
import HelpIcon from './Icons/HelpIcon';
import { removeKey } from '../../utils/localStorage';

const styles = (theme) => ({
  root: {
    display: 'flex',
    background: theme.palette.primary.main,
    flexDirection: 'row',
    boxShadow: 'none',
    '& .MuiIconButton-root': {
      borderRadius: 0,
    },
  },
  large: {
    width: theme.spacing(15),
    height: 'auto',
  },
  navigation: {
    fontSize: 16,
    margin: theme.spacing(1),
    color: theme.palette.tertiary.main,
    fontWeight: '400',
    textTransform: 'none !important',
  },
  toolbar: {
    padding: 0,
    minHeight: 0,
  },
  headerToolbar: {
    margin: theme.spacing(1, 4),
  },
  active: {
    fontWeight: '700',
  },
  mobileToolbar: {
    padding: 0,
    minHeight: 0,
    flexDirection: 'row-reverse',
    flexGrow: 1,
  },
  menuIcon: {
    cursor: 'pointer',
    color: theme.palette.tertiary.main,
    marginRight: theme.spacing(4.5),
  },
  menuStore: {
    width: theme.spacing(4),
    height: theme.spacing(3),
  },
  fullList: {
    width: theme.spacing(25),
  },
  menuList: {
    justifyContent: 'center',
  },
  rightSection: {
    // marginLeft: 'auto',
    padding: 0,
    minHeight: 0,
  },
  mobileMenuIcon: {
    color: theme.palette.tertiary.dark,
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  tooltipWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: ' space-between',
  },
  headerLink: {
    cursor: 'pointer',
    color: theme.palette.tertiary.main,
  },
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showApplicationTab: false,
    };
  }

  hideApplicationTab = () => {
    if (
      !isEmpty(this.props.permitServiceRole) &&
      !['db_user', 'db_admin', 'db_view'].includes(this.props.permitServiceRole)
    ) {
      this.setState({ showApplicationTab: true });
    }
  };

  componentDidMount() {
    this.hideApplicationTab();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permitServiceRole !== this.props.permitServiceRole) {
      this.hideApplicationTab();
    }
  }

  toggleDrawer() {
    this.setState({ open: !this.state.open });
  }

  redirect(menu, isInternal = false) {
    let url;
    if (isInternal) url = settings.enlightenUrl(menu);
    else {
      if (menu === 'enstore') url = ENSTORE_URL;
      else if (menu === 'help') url = ENLIGHTEN_SUPPORT_URL;
    }
    if (menu === 'logout') {
      setToken('');
      const cookieName =settings.getCookieName();
      removeKey(cookieName);
      removeKey('referrer');
      url = settings.enlightenUrl(menu);
    }
    if (url) window.location.replace(url);
  }

  renderSideList() {
    const { classes } = this.props;
    return (
      <div className={classes.fullList} role="presentation">
        <List>
          {/* {keysIn(enlightenLinks()).map((menu, index) => {
            if (!this.props.isAdmin && menu === 'admin') {
              return <React.Fragment />;
            } else {
              return (
                <Fragment key={index}>
                  <ListItem button key={index} onClick={() => this.redirect(this.getEnlightenUrl(menu, 'url'), true)}>
                    <ListItemText primary={this.getEnlightenUrl(menu, 'name')} />
                  </ListItem>
                  <Divider />
                </Fragment>
              );
            }
          })} */}
          {this.state.showApplicationTab && (
            <Fragment>
              <ListItem
                button
                onClick={() => {
                  forwardTo('/permitting_dashboard');
                }}
              >
                <ListItemText primary={'Applications'} />
              </ListItem>
              <Divider />
            </Fragment>
          )}
          <ListItem
            button
            onClick={() => {
              forwardTo('/ahj');
            }}
          >
            <ListItemText primary={'AHJs'} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              forwardTo('/utility');
            }}
          >
            <ListItemText primary={'Utilities'} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              forwardTo('/equipment');
            }}
          >
            <ListItemText primary={'Equipments'} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              forwardTo('/structuralAssembly');
            }}
          >
            <ListItemText primary={'Structural Assembly'} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              forwardTo('/battery-configuration');
            }}
          >
            <ListItemText primary={'Battery Configuration'} />
          </ListItem>
          <Divider />
          <ListItem button className={classes.menuList}>
            <Tooltip title="Enphase Store">
              <IconButton className={classes.iconButton} onClick={() => this.redirect('enstore')}>
                <StoreIcon
                  className={clsx(classes.menuIcon, classes.mobileMenuIcon, classes.menuStore)}
                  stroke="#7E7E7E"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Enphase Support">
              <IconButton className={classes.iconButton} onClick={() => this.redirect('help')}>
                <HelpIcon className={clsx(classes.menuIcon, classes.mobileMenuIcon)} stroke="#7E7E7E" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton className={classes.iconButton} onClick={() => this.redirect('logout', true)}>
                <Logout className={clsx(classes.menuIcon, classes.mobileMenuIcon)} stroke="#7E7E7E" />
              </IconButton>
            </Tooltip>
          </ListItem>
          <Divider />
        </List>
      </div>
    );
  }

  render() {
    const { classes, isMobileView } = this.props;

    return (
      <Fragment>
        <AppBar position="static" color="secondary" className={classes.root}>
          <Toolbar className={clsx(classes.headerToolbar, classes.toolbar)}>
            <Avatar variant="square" alt="enphase logo" src={HeaderImage} className={classes.large} />
          </Toolbar>
          {isMobileView ? (
            <Toolbar className={classes.mobileToolbar}>
              <MenuIcon className={classes.menuIcon} onClick={() => this.toggleDrawer()} />
            </Toolbar>
          ) : (
            <Fragment>
              <div className={classes.tooltipWrapper}>
                <Toolbar className={classes.rightSection}>
                  {this.state.showApplicationTab && (
                    <Tooltip title="Applications">
                      <Button
                        className={classes.navigation}
                        onClick={() => {
                          forwardTo('/permitting_dashboard');
                        }}
                        id={'applications'}
                      >
                        {'Applications'}
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip title="AHJs">
                    <Button
                      className={classes.navigation}
                      onClick={() => {
                        forwardTo('/ahj');
                      }}
                      id={'ahj'}
                    >
                      {'AHJs'}
                    </Button>
                  </Tooltip>
                  <Tooltip title="Utilities">
                    <Button
                      className={classes.navigation}
                      onClick={() => {
                        forwardTo('/utility');
                      }}
                      id={'utility'}
                    >
                      {'Utilities'}
                    </Button>
                  </Tooltip>
                  <Tooltip title="Equipments">
                    <Button
                      className={classes.navigation}
                      onClick={() => {
                        forwardTo('/equipment');
                      }}
                      id={'equipment'}
                    >
                      {'Equipments'}
                    </Button>
                  </Tooltip>
                  <Tooltip title="Structural Assembly">
                    <Button
                      className={classes.navigation}
                      onClick={() => {
                        forwardTo('/structuralAssembly');
                      }}
                      id={'structuralAssembly'}
                    >
                      {'Structural Assembly'}
                    </Button>
                  </Tooltip>
                  <Tooltip title="Battery Configuration">
                    <Button
                      className={classes.navigation}
                      onClick={() => {
                        forwardTo('/battery-configuration');
                      }}
                      id={'batteryConfiguration'}
                    >
                      {'Battery Configuration'}
                    </Button>
                  </Tooltip>
                </Toolbar>
                <Toolbar className={classes.rightSection}>
                  <Tooltip title="Enphase Store">
                    <IconButton className={classes.iconButton} onClick={() => this.redirect('enstore')}>
                      <StoreIcon className={clsx(classes.menuIcon, classes.menuStore)} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Enphase Support">
                    <IconButton className={classes.iconButton} onClick={() => this.redirect('help')}>
                      <HelpIcon className={classes.menuIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Logout">
                    <IconButton className={classes.iconButton} onClick={() => this.redirect('logout', true)}>
                      <Logout className={classes.menuIcon} stroke="white" />
                    </IconButton>
                  </Tooltip>
                </Toolbar>
              </div>
            </Fragment>
          )}
        </AppBar>
        {isMobileView && (
          <SwipeableDrawer
            anchor="right"
            open={this.state.open}
            onClose={() => this.toggleDrawer()}
            onOpen={() => this.toggleDrawer()}
          >
            {this.renderSideList()}
          </SwipeableDrawer>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(Header);
