import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ApplicantDetailsView from '../ApplicantDetailsView';

const styles = theme => ({
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    color: '#F37320',
    background: '#FAFAFA'
  },
  panelHead: {
    marginBottom: theme.spacing(2),
    minHeight: 'auto !important',
    padding: theme.spacing(1, 2),
    background: '#FAFAFA'
  },
  expansionPanel: {
    boxShadow: 'none',
    borderRadius: '0px !important',
    border: '1px solid #7070701f'
  },
  panelHeadContent: {
    margin: '0px !important'
  }
});

const ApplicationDetails = props => {
  const { classes } = props;
  return (
    <Fragment>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Accordion expanded={true} className={classes.expansionPanel}>
            <AccordionSummary className={classes.panelHead} classes={{ content: classes.panelHeadContent }}>
              <Typography className={classes.heading}>Application Details</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetails}>
              <ApplicantDetailsView />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withStyles(styles)(ApplicationDetails);
