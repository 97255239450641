import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function Logout(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 23.327 27.039">
      <path
        d="M11.955.5a.767.767 0 01.53.159v9.97a.6.6 0 01-.53.212h-.053a1.2 1.2 0 01-.53-.159V.659A.944.944 0 0111.955.5z"
        stroke={props.storke}
        strokeWidth="1"
      />
      <path
        d="M11.69 26.539A11.226 11.226 0 01.5 15.349 11.089 11.089 0 016.44 5.273c.053 0 .106-.053.159-.053H6.7a.414.414 0 01.212.212.237.237 0 010 .265.414.414 0 01-.212.212 10.325 10.325 0 00-5.515 9.387 10.449 10.449 0 1015.432-9.387.356.356 0 01.318-.636 11.33 11.33 0 01-5.25 21.266z"
        stroke={props.storke}
        strokeWidth="1"
      />
    </SvgIcon>
  );
}

export default Logout;
