import React from 'react';
import clsx from 'clsx';
import { Label } from '../../../common/Label';
import { get, isEmpty } from '../../../../utils/lodash';
import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
}));

const WeatherDataSection = (props) => {
  const classes = useStyles();
  const { errorData, handleWeatherData, weatherData, sectionLabel, readOnly, stateSelected } = props;
  const {
    snow_load_ground = '',
    snow_load_roof = '',
    wind_load_residential = '',
    wind_exposure_category = '',
    frost_depth = '',
    seismic_design_category = '',
    additional_requirements = '',
  } = weatherData;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                id={'snow_load_ground'}
                label={<Label capitalizeLabelText={false} text={'Snow load - ground (PSF)'} />}
                value={snow_load_ground ?? ''}
                onChange={(e) => handleWeatherData(e, 'snow_load_ground')}
                type="number"
                error={!isEmpty(get(errorData, 'snow_load_ground'))}
                helperText={get(errorData, 'snow_load_ground')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                id={'snow_load_roof'}
                label={<Label capitalizeLabelText={false} text={'Snow load - roof (PSF)'} />}
                value={snow_load_roof ?? ''}
                onChange={(e) => handleWeatherData(e, 'snow_load_roof')}
                type="number"
                error={!isEmpty(get(errorData, 'snow_load_roof'))}
                helperText={get(errorData, 'snow_load_roof')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                id={'wind_load_residential'}
                label={<Label capitalizeLabelText={false} text={'Wind load - residential (MPH)'} />}
                value={wind_load_residential ?? ''}
                onChange={(e) => handleWeatherData(e, 'wind_load_residential')}
                type="number"
                error={!isEmpty(get(errorData, 'wind_load_residential'))}
                helperText={get(errorData, 'wind_load_residential')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label capitalizeLabelText={false} text={'Wind exposure category'} />}
                value={wind_exposure_category || ''}
                onChange={(e) => handleWeatherData(e, 'wind_exposure_category')}
                error={!isEmpty(get(errorData, 'wind_exposure_category'))}
                helperText={get(errorData, 'wind_exposure_category')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                id={'frost_depth'}
                label={<Label capitalizeLabelText={false} text={'Frost depth (in) '} />}
                value={frost_depth ?? ''}
                onChange={(e) => handleWeatherData(e, 'frost_depth')}
                type="number"
                error={!isEmpty(get(errorData, 'frost_depth'))}
                helperText={get(errorData, 'frost_depth')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label capitalizeLabelText={false} text={'Seismic design category'} />}
                value={seismic_design_category || ''}
                onChange={(e) => handleWeatherData(e, 'seismic_design_category')}
                error={!isEmpty(get(errorData, 'seismic_design_category'))}
                helperText={get(errorData, 'seismic_design_category')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Additional requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={additional_requirements ?? ''}
                onChange={(e) => handleWeatherData(e, 'additional_requirements')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default WeatherDataSection;
