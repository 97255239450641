import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import scrollIntoView from 'scroll-into-view';
import EquipmentLocation from '../../../components/Permitting/equipmentLocation';
import PermitTemplate from '../../../components/Permitting/Template';
import { clone, keysIn, get, isEmpty, isEqual } from '../../../utils/lodash';
import { Typography, Button } from '@material-ui/core';
import StepperFooter from '../StepperFooter';
import {
  getMaterialDetails,
  updateMaterialDetails,
  getS3URL,
  putToS3,
  getPanelManufacturerList,
  setPhotos,
  setSelectedTemplate,
  getTemplates,
  setApplyTemplate,
  checkUrlValidity,
  createProjectDetails,
  getSGMaterials,
  addToCartAndGetUrl,
  postAdditionalStorageData,
  setEnableSubmit,
  permitDirectSubmit,
} from '../action';
import { validateDetails } from './validate';
import ConfirmationDialog from '../../../components/Permitting/ConfirmationDialog';
import { ARRAY_FIELDS, PV_TO_ESS_LINK, REQUIRED_ELECTRICAL_PROPERTIES, STEPS } from '../constants';
import SystemDesign from './SystemDesign';
import Properties from './Properties';
import OtherDetails from './OtherDetails';
import { validateProducts } from '../../../components/Permitting/Products/validate';
import TemplatePopup from '../../../components/Permitting/TemplatePopup';
import { isPermitSetAvailable } from '../../../utils/helper';
import { isFeatureEnabled } from '../../../containers/Permitting/helper';
import { FEATURE_FLAG_TYPE, FEATURE_FLAGS } from '../../../containers/Permitting/constants';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2.5),
  },
  header: {
    fontSize: 20,
    letterSpacing: 0,
    fontWeight: 'bold',
    color: '#233643',
  },
  templateWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  chooseTemplateButton: {
    fontSize: 15,
    borderRadius: 3,
    textTransform: 'none',
    padding: theme.spacing(0, 3),
    border: '1px solid #F37320',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    height: theme.spacing(4),
    width: theme.spacing(18),
    color: '#F37320',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  changeTemplateButton: {
    fontSize: 15,
    borderRadius: 3,
    textTransform: 'none',
    padding: theme.spacing(0, 3),
    border: '1px solid #F37320',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    height: theme.spacing(4),
    width: theme.spacing(14.5),
    color: '#F37320',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  templateFontSize: {
    fontSize: '14px',
    minWidth: theme.spacing(21.875),
  },
});

const filterList = (temp, value = '') => {
  if (value !== '') {
    return value;
  }
  if (temp && temp.length > 0) return temp[0];
  return '';
};

const prefillFilter = (toFilterList = [], fromFilterList = [], filterFromFilterList = false, value = '') => {
  if (value !== '') {
    return value;
  }
  let modifiedFromFilterList = filterFromFilterList ? [] : fromFilterList;
  if (filterFromFilterList) {
    modifiedFromFilterList = fromFilterList.map((value) => value.name);
  }
  if (toFilterList.length === 0) {
    return '';
  }
  return modifiedFromFilterList.includes(toFilterList[0]) ? toFilterList[0] : '';
};

let isInputAutocompleteEnabled = false;

class index extends Component {
  constructor(props) {
    super(props);
    this.errorKey = [];
    let materialDetails = this.props.materialDetails;
    let projectDetails = this.props.projectDetails;
    this.isSystemTypeV2Id3 = get(this.props.projectDetails, 'system_type_v2.id', 0) === 3;

    if (!isEmpty(get(materialDetails, 'equipment_location', []))) {
      const { equipment_location, ...remainingMaterialDetails } = materialDetails;
      materialDetails = remainingMaterialDetails;
    }
    // removing existing arrays from material details
    if (
      this.isSystemTypeV2Id3 &&
      get(this.props.projectDetails, 'documents', []).length === 0 &&
      !isEmpty(materialDetails) &&
      isEmpty(get(materialDetails, 'existing_arrays', []))
    ) {
      const { existing_arrays, ...remainingMd } = materialDetails;
      materialDetails = remainingMd;
    }

    this.state = {
      arrays: [
        {
          quantity: '',
          pitch_deg: '',
          azimuth: '',
          max_rafter_span: 0,
          structural_number: '',
          other_structural_number: '',
          structural_type: '',
          structural_at: '',
          panel_manufacturer: '',
          other_panel_manufacturer: '',
          panel_model: '',
          other_panel_model: '',
          installation_type: '',
          other_installation_type: '',
          roof_type: '',
          other_roof_type: '',
          spec_sheets: [],
          trenching_route_photo: [],
        },
      ],
      existing_arrays: [
        {
          quantity: '',
          pitch_deg: '',
          azimuth: '',
          max_rafter_span: 0,
          structural_number: '',
          other_structural_number: '',
          structural_type: '',
          structural_at: '',
          panel_manufacturer: '',
          panel_model: '',
          installation_type: '',
          roof_type: '',
          spec_sheets: [],
          trenching_route_photo: [],
        },
      ],
      inverters: [],
      existing_inverters: [],
      storage_list: [],
      third_party_generator : null,
      interconnection_properties: {
        interconnection_strategy: '',
        interconnection_location: '',
        interconnection_strategy_enpower: '',
        other_information: '',
      },
      electrical_properties: {
        service_type: '',
        utility_meter_location: '',
        feeder_type: '',
        main_bus_rating: '',
        main_breaker_rating: '',
        main_breaker_location: '',
        existing_grounding: '',
        subpanel_breaker_rating: '',
        subpanel_bus_rating: '',
        msp_upgrade: false,
        solar_ready: false,
        spare_breaker_count: null,
        bus_rating: '',
        main_breaker: '',
        msp_location: '',
        location_image_file_name: '',
        coordinates: { lat: null, lng: null },
        um_coordinates: { lat: null, lng: null },
        existing_generator: null,
        generator_connection: null,
        generator_manufacturer: '',
        generator_model: '',
        generator_type: 'Generator',
        generator_quantity: 1,
        generator_photos: [],
        subpanels: [],
        backupExistingSubpanel: '',
        subpanelExisting: null,
      },
      racking_attachment_properties: {
        racking_manufacturer: '',
        other_racking_manufacturer: '',
        racking_model: '',
        attachment_manufacturer: '',
        other_attachment_manufacturer: '',
        attachment_model: '',
        attachment_type: '',
        attachment_spacing: '',
        wind_load: '',
        snow_load: '',
        notes: '',
      },
      documents: [],
      document_link: '',
      photos: [],
      photo_link: '',
      micro_inverters_upgrade: true,
      company_logo: {}, // file object
      additional_info: '',
      wire_from_attic: null,
      installer_contact_phone: this.props.companyInfo.phone,
      ...materialDetails,
      equipment_location: this.getEquipmentLocation(),
      errors: {},
      warnings: {},
      showTemplateDialog: false,
      openConfirmationDialog: false,
      projectDetails: { ...projectDetails },
    };
    this.isUpgradedToStorage = false;
    this.noErrorObj = {
      error: false,
      errorMessage: '',
    };
    this.linkValidError = {
      error: true,
      errorMessage: 'The link provided is not valid',
    };
    this.linkValidError = {
      error: true,
      errorMessage: 'The link provided is not valid',
    };
    this.photoTimeout = null;
    this.documentTimeout = null;
    this.saveAndClose = false;
    isInputAutocompleteEnabled = isFeatureEnabled(
      this.props.featureList,
      FEATURE_FLAGS.INPUT_FORM_DROPDOWNS,
      FEATURE_FLAG_TYPE.company
    );
  }

  componentDidMount() {
    const { permitId, getSGMaterials } = this.props;
    if (permitId) this.props.getMaterialDetails({ permitId: permitId });
    getSGMaterials();
    this.fillInterconnectionStrategy();
    this.prefillFromMostUsed();
    if (!isEmpty(this.props.selectedTemplate)) {
      this.prefillFromTemplates();
    }

    this.fillArrayBuilderDetails();
    window.scrollTo(0, 0);
  }

  getOldMicros = () => {
    const { siteDetails } = this.props;
    return !isEmpty(siteDetails)
      ? keysIn(siteDetails.inverters).map((key) => {
          return {
            inverter_type: key,
            quantity: siteDetails.inverters[key],
          };
        })
      : [];
  };

  //Storage selected interconnection strategy defaults to Interconnection in Enpower
  fillInterconnectionStrategy = () => {
    if (
      get(this.props.projectDetails, 'system_types', [])[1]['value'] &&
      get(this.props.materialDetails, 'interconnection_properties.interconnection_strategy', '') === ''
    ) {
      this.setState({
        ...this.state,
        interconnection_properties: {
          ...this.state.interconnection_properties,
          interconnection_strategy: 'Interconnection in Enpower',
        },
      });
    }
  };

  fillEmptyValues = () => {
    this.setState({
      arrays: [
        {
          quantity: '',
          pitch_deg: '',
          azimuth: '',
          max_rafter_span: 0,
          structural_number: '',
          structural_type: '',
          structural_at: '',
          panel_manufacturer: '',
          panel_model: '',
          installation_type: '',
          roof_type: '',
          spec_sheets: [],
          trenching_route_photo: [],
        },
      ],
      inverters: [],
      interconnection_properties: {
        interconnection_strategy: '',
        interconnection_location: '',
        interconnection_strategy_enpower: '',
        other_information: '',
      },
      electrical_properties: {
        service_type: '',
        utility_meter_location: '',
        feeder_type: '',
        main_bus_rating: '',
        main_breaker_rating: '',
        main_breaker_location: '',
        existing_grounding: '',
        subpanel_breaker_rating: '',
        subpanel_bus_rating: '',
        msp_upgrade: '',
        solar_ready: '',
        spare_breaker_count: null,
        bus_rating: '',
        main_breaker: '',
        msp_location: '',
        location_image_file_name: '',
        coordinates: { lat: null, lng: null },
        um_coordinates: { lat: null, lng: null },
        existing_generator: null,
        generator_connection: null,
        generator_manufacturer: '',
        generator_model: '',
        generator_type: 'Generator',
        generator_quantity: 1,
        generator_photos: [],
      },
      racking_attachment_properties: {
        racking_manufacturer: '',
        racking_model: '',
        attachment_manufacturer: '',
        attachment_model: '',
        attachment_type: '',
        attachment_spacing: '',
        wind_load: '',
        snow_load: '',
        notes: '',
      },
      errors: {},
    });
  };

  prefillFromMostUsed = () => {
    const solarNotPurchased =
      [1, 3].includes(Number(get(this.state, 'projectDetails.system_type_v2.id'))) ||
      (get(this.state, 'projectDetails.documents', []) || []).length === 0;
    if (solarNotPurchased && !this.props.paymentStatus) {
      const {
        racking_manufacturer = [],
        attachment_spacing = [],
        service_type = [],
        snow_load = [],
        attachment_type = [],
        wind_load = [],
        interconnection_strategy = [],
        interconnection_location = [],
        attachment_manufacturer = [],
      } = this.props.mostUsedValues;
      this.setState({
        ...this.state,
        interconnection_properties: {
          ...this.state.interconnection_properties,
          interconnection_strategy: prefillFilter(
            interconnection_strategy,
            this.props.projectDetails.state === 'CA'
              ? this.props.userSelectionData.interconnection_strategy_ca
              : this.props.userSelectionData.interconnection_strategy,
            false,
            this.state.interconnection_properties.interconnection_strategy
          ),
          interconnection_location: prefillFilter(
            interconnection_location,
            this.props.userSelectionData.interconnection_location,
            false,
            this.state.interconnection_properties.interconnection_location
          ),
        },
        racking_attachment_properties: {
          ...this.state.racking_attachment_properties,
          racking_manufacturer: prefillFilter(
            racking_manufacturer,
            this.props.rackingMfs,
            true,
            this.state.racking_attachment_properties.racking_manufacturer
          ),
          attachment_manufacturer: prefillFilter(
            attachment_manufacturer,
            this.props.rackingMfs,
            true,
            this.state.racking_attachment_properties.attachment_manufacturer
          ),
          attachment_type: prefillFilter(
            attachment_type,
            this.props.userSelectionData.attachment_type,
            false,
            this.state.racking_attachment_properties.attachment_type
          ),
          attachment_spacing: prefillFilter(
            attachment_spacing,
            this.props.userSelectionData.max_spacing_between_attachments,
            false,
            this.state.racking_attachment_properties.attachment_spacing
          ),
          wind_load: filterList(wind_load, this.state.racking_attachment_properties.wind_load),
          snow_load: filterList(snow_load, this.state.racking_attachment_properties.snow_load),
        },
        electrical_properties: {
          ...this.state.electrical_properties,
          service_type: prefillFilter(
            service_type,
            this.props.userSelectionData.service_type,
            false,
            this.state.electrical_properties.service_type
          ),
          //TODO - variable name should be same everywhere eg. feeder_type and feader_type
        },
      });
    }
  };

  prefillFromTemplates = () => {
    const solarNotPurchased =
      [1, 3].includes(Number(get(this.state, 'projectDetails.system_type_v2.id'))) ||
      (get(this.state, 'projectDetails.documents', []) || []).length === 0;
    if (
      solarNotPurchased &&
      !isPermitSetAvailable(this.props.applicationDetail) &&
      !isEmpty(this.props.selectedTemplate)
    ) {
      const {
        interconnection_strategy,
        interconnection_location,
        racking_manufacturer,
        attachment_manufacturer,
        racking_model,
        attachment_model,
        attachment_type,
        max_spacing_between_attachments,
        panel_manufacturer,
        panel_model,
        roof_type,
      } = this.props.selectedTemplate;
      const arrays = this.state.arrays;
      const existingArrays = this.state.existing_arrays;
      arrays &&
        arrays.forEach((array) => {
          array.panel_manufacturer = panel_manufacturer;
          array.panel_model = panel_model;
          array.roof_type = roof_type;
        });
      existingArrays &&
        existingArrays.forEach((array) => {
          array.panel_manufacturer = panel_manufacturer;
          array.panel_model = panel_model;
          array.roof_type = roof_type;
        });
      this.setState({
        interconnection_properties: {
          interconnection_strategy: interconnection_strategy,
          interconnection_location: interconnection_location,
        },
        racking_attachment_properties: {
          racking_manufacturer: racking_manufacturer,
          racking_model: racking_model,
          attachment_manufacturer: attachment_manufacturer,
          attachment_model: attachment_model,
          attachment_type: attachment_type,
          attachment_spacing: max_spacing_between_attachments,
        },
        arrays: arrays,
        existing_arrays: existingArrays,
      });
    } else if (solarNotPurchased && !this.props.paymentStatus && isEmpty(this.props.selectedTemplate)) {
      this.setState({
        interconnection_properties: {
          interconnection_strategy: '',
          interconnection_location: '',
        },
        racking_attachment_properties: {
          racking_manufacturer: '',
          racking_model: '',
          attachment_manufacturer: '',
          attachment_model: '',
          attachment_type: '',
          attachment_spacing: '',
        },
        arrays: [
          {
            quantity: '',
            pitch_deg: '',
            azimuth: '',
            max_rafter_span: 0,
            structural_number: '',
            structural_type: '',
            structural_at: '',
            panel_manufacturer: '',
            panel_model: '',
            installation_type: '',
            roof_type: '',
          },
        ],
        existing_arrays: [],
      });
    }
  };

  fillArrayDetails = (isExisting, array_details, panel_manufacturer, panel_model) => {
    const arrToFill = isExisting ? this.state.existing_arrays[0] : this.state.arrays[0];
    const {
      quantity = '',
      pitch_deg = '',
      azimuth = '',
      max_rafter_span = 0,
      structural_number = '',
      structural_type = '',
      structural_at = '',
    } = arrToFill;
    if (
      !Number(quantity) &&
      !Number(pitch_deg) &&
      !Number(azimuth) &&
      max_rafter_span === 0 &&
      isEmpty(structural_number) &&
      isEmpty(structural_type) &&
      isEmpty(structural_at)
    ) {
      const arrayDetails = [];
      array_details.map((array) => {
        arrayDetails.push({
          name: array.name,
          quantity: array.quantity,
          pitch_deg: array.pitch || '',
          azimuth: array.azimuth,
          max_rafter_span: 0,
          structural_number: '',
          structural_type: '',
          structural_at: '',
          panel_manufacturer: panel_manufacturer,
          panel_model: panel_model,
        });
        return null;
      });

      isExisting ? this.setState({ existing_arrays: arrayDetails }) : this.setState({ arrays: arrayDetails });
    }
  };

  fillArrayBuilderDetails = () => {
    if (!isEmpty(this.props.arrayBuilder)) {
      const { panel_manufacturer, panel_model, array_details } = this.props.arrayBuilder;
      if (
        !isEmpty(array_details) &&
        this.state.arrays !== null &&
        this.state.arrays.length === 1 &&
        !this.props.paymentStatus &&
        !this.isSystemTypeV2Id3
      ) {
        this.fillArrayDetails(false, array_details, panel_manufacturer, panel_model);
      }

      if (
        !isEmpty(array_details) &&
        this.state.existing_arrays !== null &&
        this.state.existing_arrays.length === 1 &&
        !this.props.paymentStatus &&
        this.isSystemTypeV2Id3
      ) {
        this.fillArrayDetails(true, array_details, panel_manufacturer, panel_model);
      }
    }
  };

  getEquipmentLocation = () => {
    const { userSelectionData, materialDetails } = this.props;
    if (!isEmpty(get(materialDetails, 'equipment_location', []))) {
      return get(materialDetails, 'equipment_location', []);
    } else {
      return get(userSelectionData, 'equipment_locations', []);
    }
  };

  componentDidUpdate(prvProp, preState) {
    if (!isEqual(prvProp.selectedTemplate, this.props.selectedTemplate)) {
      this.prefillFromTemplates();
    }
    const { permitId } = this.props;
    if (permitId && prvProp.permitId !== permitId) {
      this.props.getMaterialDetails({ permitId });
    }
    if (!prvProp.materialDetails && this.props.materialDetails) {
      let equipment_location =
        this.props.materialDetails.equipment_location.length > 0
          ? this.props.materialDetails.equipment_location
          : this.props.userSelectionData.equipment_locations;
      this.setState({ ...this.props.materialDetails, equipment_location });
    }
    if (isEmpty(get(prvProp, 'materialDetails.photos')) && !isEmpty(get(this.props, 'materialDetails.photos'))) {
      const photos = this.props.materialDetails.photos;
      let i = 0;
      const getUrl = () => {
        this.props.getS3URL({
          fileName: photos[i].file_name,
          methodType: 'GET',
          successCb: (preSignedS3Url) => {
            this.props.setPhotos({ [photos[i].file_name]: preSignedS3Url });
            i += 1;
            if (i < photos.length) {
              getUrl();
            }
          },
          failureCb: () => {},
        });
      };
      if (photos && photos.length) {
        getUrl();
      }
    }
  }

  doesFileExist = (urlToFile, cb) => {
    this.props.checkUrlValidity({
      url: encodeURIComponent(urlToFile),
      cbk: (fileExists) => cb(!fileExists),
    });
  };

  handleSolarTypeChange = () => {
    const systemTypeId = Number(get(this.state, 'projectDetails.system_type_v2.id'));
    const projectDocs = get(this.state, 'projectDetails.documents', []) || [];
    const solarNotPurchased = [1, 3].includes(systemTypeId) || projectDocs.length === 0;
    if (!solarNotPurchased) {
      this.fillEmptyValues();
    }
    if ([4, 5, 6].includes(Number(systemTypeId)) && (!projectDocs || projectDocs.length === 0)) {
      const { arrays } = this.state;
      arrays &&
        arrays.forEach((array) => {
          array.panel_manufacturer = '';
          array.panel_model = '';
        });
      this.setState({ ...this.state, arrays });
    }
  };

  handleProjectDetailsChange = (key, value, autoPopulated = false) => {
    if (!autoPopulated) {
      this.props.setEnableSubmit(true);
    }
    this.setState({ projectDetails: { ...this.state.projectDetails, [key]: value } }, () => {
      if (key === 'documents' || key === 'system_type_v2') {
        this.handleSolarTypeChange();
      }
    });
  };

  handleProjectDetailsChanges = (keys, values, autoPopulated = false) => {
    if (!autoPopulated) {
      this.props.setEnableSubmit(true);
    }
    const newPD = { ...this.state.projectDetails };
    keys &&
      keys.forEach((key, ind) => {
        newPD[key] = values[ind];
      });
    this.setState({ projectDetails: newPD }, () => {
      if (keys.includes('documents') || keys.includes('system_type_v2')) {
        this.handleSolarTypeChange();
      }
    });
  };

  handleChange = (
    value,
    key,
    additionalDetails = {},
    skipValidation = false,
    autoPopulated = false,
    successCb = () => {}
  ) => {
    if (!autoPopulated) {
      this.props.setEnableSubmit(true);
    }
    this.setState({ [key]: value }, () => {
      successCb();
    });
    if (key === 'photo_link') {
      clearTimeout(this.photoTimeout);
      this.photoTimeout = setTimeout(() => {
        if (isEmpty(value)) {
          this.setState({
            errors: { ...this.state.errors, photo_link: this.noErrorObj },
            warnings: { ...this.state.warnings, photo_link: false },
          });
        } else {
          this.doesFileExist(value, (imageError) => {
            if (imageError) {
              this.setState({
                warnings: {
                  ...this.state.warnings,
                  photo_link: true,
                },
              });
            } else {
              this.setState({
                warnings: {
                  ...this.state.warnings,
                  photo_link: false,
                },
              });
            }
          });
        }
      }, 100);
    } else if (key === 'document_link') {
      clearTimeout(this.documentTimeout);
      this.documentTimeout = setTimeout(() => {
        if (isEmpty(value)) {
          this.setState({
            errors: {
              ...this.state.errors,
              document_link: this.noErrorObj,
            },
            warnings: { ...this.state.warnings, document_link: false },
          });
        } else {
          this.doesFileExist(value, (fileError) => {
            if (fileError) {
              this.setState({
                warnings: {
                  ...this.state.warnings,
                  document_link: true,
                },
              });
            } else {
              this.setState({
                warnings: {
                  ...this.state.warnings,
                  document_link: false,
                },
              });
            }
          });
        }
      }, 100);
    }

    if (!skipValidation) {
      const { errors, ...remainingState } = this.state;
      const {
        additionalKey = '',
        arrayIndex = -1,
        isArrayRemove = false,
        isArrayAdd = false,
      } = !isEmpty(additionalDetails) && additionalDetails;
      const props = {
        errors: clone(errors),
        data: { ...remainingState, [key]: value },
        key,
        additionalKey,
        arrayIndex,
        activeStep: this.props.activeStep,
        storageSize: get(this.props.projectDetails, 'storage_size', ''),
        solarSystemSize: get(this.props.projectDetails, 'solar_system_size', ''),
        isArrayAdd,
        isArrayRemove,
        additionalDetails: additionalDetails,
        featureList: this.props.featureList,
      };
      const error = validateDetails(props);
      let modifiedError = clone(error);
      if (isArrayRemove && modifiedError && modifiedError[key] && modifiedError[key][arrayIndex]) {
        modifiedError[key].splice(arrayIndex, 1);
      }
      const totalErrors = {
        ...modifiedError,
        ...(key === 'inverters' && validateProducts(remainingState.inverters, 'inverters', additionalDetails)),
        ...(key === 'storage_list' &&
          validateProducts(
            remainingState.storage_list,
            'storage_list',
            additionalDetails,
            get(remainingState, 'projectDetails.system_types[1].value', '')
          )),
        ...(key === 'other_materials' &&
          validateProducts(remainingState.other_materials, 'other_materials', additionalDetails)),
      };
      this.setState({ errors: totalErrors });
    }
    if (this.props.isFrom === 'project-details' && !this.props.paymentDialogBack) {
      this.props.onMaterialDetailsChange({ ...this.state, [key]: value });
    }
  };

  getFileUrl = (name) => {
    this.props.getS3URL({
      fileName: name,
      methodType: 'GET',
      successCb: (preSignedS3Url) => {
        this.props.setPhotos({ [name]: preSignedS3Url });
      },
      failureCb: () => {},
    });
  };

  fireUpload = (file, key, tag = '') => {
    if (file) {
      const images = clone(this.state[key]);
      const index = images.length;
      const date = Date.now();
      const extension = file.name.match(/((\.[a-z]+)|(\.[A-Z]+))$/g)[0];
      const name = `${this.props.permitId}_${date}${extension}`;
      const currentVersion = get(this.props.applicationDetail, 'version.current_version', 'NEW');
      images.push({ loading: true, original_file_name: '', tag });
      const additionalDetails = { additionalKey: tag };
      this.handleChange(images, key, additionalDetails);
      this.props.getS3URL({
        fileName: name,
        methodType: 'PUT',
        successCb: (preSignedS3Url) => {
          this.props.putToS3({
            fileObj: file,
            preSignedS3Url: preSignedS3Url,
            fName: name,
            successCbS3: () => {
              images.splice(index, 1);
              images.push({
                file_name: name,
                original_file_name: file.name,
                tag,
                file_size: file.size / 1024 / 1024,
                versions: [currentVersion],
              });
              if (key === 'photos') {
                this.getFileUrl(name);
              }
              this.handleChange(images, key, additionalDetails);
            },
            failureCbS3: () => {
              images.splice(index, 1);
              this.handleChange(images, key, additionalDetails);
            },
          });
        },
        failureCb: () => {},
      });
    }
  };

  findErrorKey = (errors) => {
    const errorKey = keysIn(errors).find((key) => {
      if (ARRAY_FIELDS.includes(key)) {
        return (
          errors[key] &&
          errors[key].find((license) => {
            return this.findErrorKey(license);
          })
        );
      }
      return errors[key].error === true;
    });
    if (errorKey) {
      if (REQUIRED_ELECTRICAL_PROPERTIES.includes(errorKey)) {
        this.errorKey.push('electrical_properties');
      } else if (errorKey === 'subpanelsErrors') {
        const subpanelIdSuffix = errors['subpanelsErrors'].isExistingSubpanel
          ? '_subpanel_existing'
          : `_subpanel_${errors['subpanelsErrors'].subpanelIndex}`;
        this.errorKey.push(`${errors['subpanelsErrors'].errorKey}${subpanelIdSuffix}`);
      } else {
        this.errorKey.push(errorKey);
      }
    }
  };

  handleProceed = () => {
    // reset warnings
    const errors = clone(this.state.errors);
    errors['minArrayQty'] = { warning: false };
    this.setState({ openConfirmationDialog: false, errors: errors }, () => this.handleNext());
  };

  handlePopupClose = () => {
    this.setState({ openConfirmationDialog: false });
    scrollIntoView(document.getElementById('md-top-section'));
  };

  saveProjectDetails = (isSaveandClose, isSave, isSubmit, materialDetails) => {
    this.props.createProjectDetails({
      projectDetails: this.state.projectDetails,
      permitId: this.props.permitId,
      isProceed: false,
      isSave,
      successCbk: () => {
        if (isSaveandClose) {
          this.props.handleSaveandClose();
        } else if (isSubmit) {
          this.props.handleSubmit(this.state.projectDetails, materialDetails);
        } else {
          this.props.handleNext(isSave, isSubmit);
        }
      },
    });
  };

  handleOtherValues(remainingState, key) {
    const itemList = get(remainingState, key, []);
    if (isInputAutocompleteEnabled && itemList) {
      if (key === 'racking_attachment_properties') {
        if (itemList.other_racking_manufacturer) {
          itemList.racking_manufacturer = itemList.other_racking_manufacturer;
        }
        if (itemList.other_attachment_manufacturer) {
          itemList.attachment_manufacturer = itemList.other_attachment_manufacturer;
        }
      } else {
        itemList.forEach((array) => {
          if (key === 'arrays') {
            if (array.other_panel_manufacturer) {
              array.panel_manufacturer = array.other_panel_manufacturer;
            }
            if (array.other_panel_model) {
              array.panel_model = array.other_panel_model;
            }
            if (array.other_roof_type) {
              array.roof_type = array.other_roof_type;
            }
          } else {
            if (array.other_manufacturer) {
              array.manufacturer = { id: 'other', name: array.other_manufacturer };
            }
            if (array.other_model) {
              array.model = { id: 'other', name: array.other_model };
            }
          }
        });
      }
    }
    return itemList;
  }

  /**
   * To handle Physical Stamp Inputs
   * @param {boolean} isSaveandClose If true, it will call the api to save the data (without validations) and close the application
   * @param {boolean} isSave If true, it will call the backend api to save the data (with validations) in DB and move to the next step
   *                          , else, it will store the data in reducer and move to the next step
   * @param {boolean} isSubmit If true, it will call the submit api with all the saved data in reducer
   */
  handleNext = (isSaveandClose, isSave, isSubmit) => {
    this.saveAndClose = isSaveandClose;
    if (!isPermitSetAvailable(this.props.applicationDetail)) {
      const { errors, ...remainingState } = this.state;
      const solarNotPurchased =
        [1, 3].includes(Number(get(this.state, 'projectDetails.system_type_v2.id'))) ||
        (get(remainingState, 'projectDetails.documents', []) || []).length === 0;
      const solarPurchased =
        [2].includes(Number(get(this.state, 'projectDetails.system_type_v2.id'))) ||
        (get(remainingState, 'projectDetails.documents', []) || []).length != 0;
      const isExistingPanel = Number(get(remainingState, 'projectDetails.system_type_v2.id')) === 3;
      this.errorKey = [];
      const props = {
        errors: {},
        data: { ...remainingState },
        key: '',
        additionalKey: '',
        arrayIndex: -1,
        storageSize: get(this.props.projectDetails, 'storage_size', ''),
        activeStep: this.props.activeStep,
        paymentStatus: this.props.paymentStatus,
        isExistingPermitPackageUploaded: isExistingPanel && get(this.state.projectDetails, 'documents', []).length > 0,
        featureList: this.props.featureList,
      };

      if (!isSaveandClose && solarNotPurchased) {
        const error = validateDetails(props);
        const totalErrors = {
          ...error,
          ...validateProducts(remainingState.inverters, 'inverters'),
          ...validateProducts(
            remainingState.storage_list,
            'storage_list',
            {},
            get(remainingState, 'projectDetails.system_types[1].value', '')
          ),
          ...validateProducts(remainingState.other_materials, 'other_materials'),
        };
        this.findErrorKey(totalErrors);
        this.setState({ errors: totalErrors });
      } else if (!isSaveandClose && solarPurchased) {
        const stateThird = remainingState.third_party_generator;
        const thirdPartyKey = "third_party_generator"
        let thirdError = {[thirdPartyKey] : ""};
        if(stateThird === null){
          thirdError = { [thirdPartyKey] : {error: true,
          errorMessage: 'This field is Required'}}
        }
        const totalErrors = {
          ...thirdError,
          ...validateProducts(
            remainingState.storage_list,
            'storage_list',
            {},
            get(remainingState, 'projectDetails.system_types[1].value', '')
          ),
        };
        this.findErrorKey(totalErrors);
        this.setState({ errors: totalErrors });
      } else if (!isSaveandClose) {
        this.findErrorKey(errors);
      }

      if (!!!this.errorKey[0]) {
        // check for warnings
        if (get(this.state.errors, 'minArrayQty.warning')) {
          this.setState({ openConfirmationDialog: true });
          return;
        }
        const storageType = this.props.projectDetails.system_types.find((type) => type.name === 'Storage');
        const isBackfeedOrDerate =
          get(remainingState, 'interconnection_properties.interconnection_strategy', 'null') === 'Backfeed Breaker' ||
          get(remainingState, 'interconnection_properties.interconnection_strategy', 'null') === 'Derate Main Breaker';
        let equipment_location = [];
        const equipmentLocation = get(remainingState, 'equipment_location', []);
        if (isBackfeedOrDerate) {
          equipment_location = equipmentLocation.filter((data) => data.item !== 'AC Fused Disconnect');
        } else {
          equipment_location = equipmentLocation.filter((data) => data.item !== 'AC Non-Fused Disconnect');
        }
        if (!storageType.value) {
          equipment_location = equipment_location.filter((data) => data.item !== 'Enpower Location');
          equipment_location = equipment_location.filter((data) => data.item !== 'Encharge Location');
        }
        const panelArrays = this.handleOtherValues(remainingState, 'arrays');
        const modifiedInverters = this.handleOtherValues(remainingState, 'inverters');
        const modifiedOtherMaterials = this.handleOtherValues(remainingState, 'other_materials');
        const modifiedStorageList = this.handleOtherValues(remainingState, 'storage_list');
        const modifiedRackingList = this.handleOtherValues(remainingState, 'racking_attachment_properties');
        if (isExistingPanel) {
          const oldMicros = this.getOldMicros();
          if (get(this.state.projectDetails, 'documents', []).length > 0) {
            this.setState(
              {
                existing_inverters: [],
                existing_spec_sheets: [],
                existing_arrays: [],
              },
              () => {
                this.setState({ existing_inverters: oldMicros }, () => {
                  const { errors, ...remainingState } = this.state;
                  this.props.updateMaterialDetails({
                    ...remainingState,
                    arrays: panelArrays,
                    inverters: modifiedInverters,
                    other_materials: modifiedOtherMaterials,
                    storage_list: modifiedStorageList,
                    racking_attachment_properties: modifiedRackingList,
                    equipment_location,
                    permit_id: this.props.permitId,
                    isProceed: !isSaveandClose,
                    stepCompleted: this.props.activeStep + 1,
                    template_id: get(this.props.selectedTemplate, 'id', ''),
                    isSave,
                    successCbk: () =>
                      this.saveProjectDetails(isSaveandClose, isSave, isSubmit, {
                        ...remainingState,
                        arrays: panelArrays,
                        inverters: modifiedInverters,
                        other_materials: modifiedOtherMaterials,
                        storage_list: modifiedStorageList,
                        racking_attachment_properties: modifiedRackingList,
                        equipment_location,
                        permit_id: this.props.permitId,
                        isProceed: !isSaveandClose,
                        stepCompleted: this.props.activeStep + 1,
                        template_id: get(this.props.selectedTemplate, 'id', ''),
                      }),
                  });
                });
              }
            );
          } else {
            this.setState({ existing_inverters: oldMicros }, () => {
              const { errors, ...remainingState } = this.state;
              this.props.updateMaterialDetails({
                ...remainingState,
                arrays: panelArrays,
                inverters: modifiedInverters,
                other_materials: modifiedOtherMaterials,
                storage_list: modifiedStorageList,
                racking_attachment_properties: modifiedRackingList,
                equipment_location,
                permit_id: this.props.permitId,
                isProceed: !isSaveandClose,
                stepCompleted: this.props.activeStep + 1,
                template_id: get(this.props.selectedTemplate, 'id', ''),
                isSave,
                successCbk: () =>
                  this.saveProjectDetails(isSaveandClose, isSave, isSubmit, {
                    ...remainingState,
                    arrays: panelArrays,
                    inverters: modifiedInverters,
                    other_materials: modifiedOtherMaterials,
                    storage_list: modifiedStorageList,
                    racking_attachment_properties: modifiedRackingList,
                    equipment_location,
                    permit_id: this.props.permitId,
                    isProceed: !isSaveandClose,
                    stepCompleted: this.props.activeStep + 1,
                    template_id: get(this.props.selectedTemplate, 'id', ''),
                  }),
              });
            });
          }
        } else {
          this.setState(
            {
              existing_inverters: [],
              existing_spec_sheets: [],
              existing_arrays: [],
            },
            () => {
              const { errors, ...remainingState } = this.state;
              this.props.updateMaterialDetails({
                ...remainingState,
                arrays: panelArrays,
                inverters: modifiedInverters,
                other_materials: modifiedOtherMaterials,
                storage_list: modifiedStorageList,
                racking_attachment_properties: modifiedRackingList,
                equipment_location,
                permit_id: this.props.permitId,
                isProceed: !isSaveandClose,
                stepCompleted: this.props.activeStep + 1,
                template_id: get(this.props.selectedTemplate, 'id', ''),
                isSave,
                successCbk: () =>
                  this.saveProjectDetails(isSaveandClose, isSave, isSubmit, {
                    ...remainingState,
                    arrays: panelArrays,
                    inverters: modifiedInverters,
                    other_materials: modifiedOtherMaterials,
                    storage_list: modifiedStorageList,
                    racking_attachment_properties: modifiedRackingList,
                    equipment_location,
                    permit_id: this.props.permitId,
                    isProceed: !isSaveandClose,
                    stepCompleted: this.props.activeStep + 1,
                    template_id: get(this.props.selectedTemplate, 'id', ''),
                  }),
              });
            }
          );
        }
      } else {
        scrollIntoView(document.getElementById(this.errorKey[0]));
      }
    } else {
      this.props.handleNext();
    }
  };

  deleteFile = (fileName, cb, methodType = 'DELETE') => {
    this.props.getS3URL({
      fileName,
      methodType,
      successCb: (res) => cb(res),
      failureCb: () => {},
    });
  };

  deletePhotos = (index, tag = '') => {
    let photos = clone(this.state['photos']);
    photos.splice(index, 1);
    this.handleChange(photos, 'photos');
  };

  setErrorObj = (errorObj) => {
    this.setState({ errors: { ...errorObj } });
  };

  openTemplateDialog = () => {
    this.setState({ showTemplateDialog: true });
  };
  handleTemplateDialogClose = () => {
    this.setState({ showTemplateDialog: false });
    this.props.getTemplates();
  };

  render() {
    const {
      classes,
      userSelectionData,
      activeStep,
      steps,
      handleBack,
      paymentStatus,
      panelManufactureList,
      permitId,
      getS3URL,
      putToS3,
      siteDetails,
      rackingMfs,
      photos,
      applicationDetail,
      isMobile,
      selectedTemplate,
      templates,
      inverterManufacturers,
      sgMaterials,
      sgMaterialsLoading,
      otherUserSelectionData,
      mostUsedValues,
      addToCartAndGetUrl,
      postAdditionalStorageData,
      storageIncluded,
      permitDirectSubmit,
      featureList,
      companyInfo,
    } = this.props;
    const props = {
      materialDetails: this.state,
      userSelectionData: userSelectionData,
      handleChange: this.handleChange,
      handleProjectDetailsChange: this.handleProjectDetailsChange,
      handleProjectDetailsChanges: this.handleProjectDetailsChanges,
      projectDetails: this.state.projectDetails,
      paymentStatus: paymentStatus,
      applicationDetail: applicationDetail,
      isMobile: isMobile,
      companyInfo: companyInfo,
      mostUsedValues: mostUsedValues,
    };
    const solarNotPurchased =
      [1, 3].includes(Number(get(this.state, 'projectDetails.system_type_v2.id'))) ||
      (get(this.state, 'projectDetails.documents', []) || []).length === 0;

    const buttonText = isEmpty(selectedTemplate) ? 'Choose Template' : 'Change';
    let templateName = selectedTemplate && selectedTemplate.name;
    const subPanelList = this.state.electrical_properties.subpanels || [];
    if (templateName) {
      if (templateName.length > 16) {
        templateName = templateName.slice(0, 12) + '...';
      }
    }

    return (
      <div className={classes.root}>
        <PermitTemplate
          title={STEPS[activeStep].name}
          subText={STEPS[activeStep].description}
          mandatorySection={
            templates.length > 0 && (
              <div className={classes.templateWrapper}>
                {!isEmpty(selectedTemplate) && (
                  <Typography display="inline" className={classes.templateFontSize}>
                    {`Template: ${templateName} `}
                  </Typography>
                )}
                <Button
                  size="small"
                  variant="outlined"
                  className={!isEmpty(selectedTemplate) ? classes.changeTemplateButton : classes.chooseTemplateButton}
                  disableRipple
                  disabled={paymentStatus}
                  onClick={this.openTemplateDialog}
                >
                  {buttonText}
                </Button>
              </div>
            )
          }
        >
          {activeStep === 1 && (
            <SystemDesign
              solarNotPurchased={solarNotPurchased}
              isSystemTypeV2Id3={this.isSystemTypeV2Id3}
              fireUpload={this.fireUpload}
              deleteFile={this.deleteFile}
              deletePhotos={this.deletePhotos}
              panelManufactureList={panelManufactureList}
              siteDetails={siteDetails}
              setErrorObj={this.setErrorObj}
              rackingMfs={rackingMfs}
              permitId={permitId}
              putToS3={putToS3}
              getS3URL={getS3URL}
              inverterManufacturers={inverterManufacturers}
              sgMaterials={sgMaterials}
              sgMaterialsLoading={sgMaterialsLoading}
              otherUserSelectionData={otherUserSelectionData}
              mostUsedValues={mostUsedValues}
              addToCartAndGetUrl={addToCartAndGetUrl}
              postAdditionalStorageData={postAdditionalStorageData}
              storageIncluded={storageIncluded}
              isPermitSetAvailable={isPermitSetAvailable(applicationDetail)}
              permitDirectSubmit={permitDirectSubmit}
              featureList={featureList}
              subPanelList={subPanelList}
              {...props}
            />
          )}
          {activeStep === 2 && (
            <Properties
              solarNotPurchased={solarNotPurchased}
              handleMspLocationChange={this.handleMspLocationChange}
              deleteFile={this.deleteFile}
              deletePhotos={this.deletePhotos}
              fireUpload={this.fireUpload}
              getS3URL={getS3URL}
              putToS3={putToS3}
              permitId={permitId}
              subPanelList={subPanelList}
              featureList={featureList}
              {...props}
            />
          )}
          {activeStep === 3 && (
            <OtherDetails
              featureList={featureList}
              deleteFile={this.deleteFile}
              fireUpload={this.fireUpload}
              photos={photos}
              {...props}
            />
          )}
        </PermitTemplate>
        <StepperFooter
          activeStep={activeStep}
          steps={steps}
          handleNext={(isSave, isSubmit) => this.handleNext(false, isSave, isSubmit)}
          handleBack={(goToApplicationDetails) => {
            this.setState({ errors: {} }, () => handleBack(goToApplicationDetails));
          }}
          isMobile={isMobile}
          handleSaveandClose={() => this.handleNext(true, true)}
          materialDetails={this.state}
        />
        {this.state.openConfirmationDialog && (
          <ConfirmationDialog
            open={this.state.openConfirmationDialog}
            handleClose={this.handlePopupClose}
            handleProceed={this.handleProceed}
            withTitle={false}
            withButtons={true}
            withLink={true}
            learnMoreLink={PV_TO_ESS_LINK}
            dialogText={`You have entered only ${get(this.state.errors, 'minArrayQty.solarPanels')}
            solar panels for ${get(
              this.state.errors,
              'minArrayQty.solarSize'
            )} KW solar system size. Are you sure you want to proceed ?`}
          />
        )}

        {this.state.showTemplateDialog && (
          <TemplatePopup
            showTemplateDialog={this.state.showTemplateDialog}
            handleTemplateDialogClose={this.handleTemplateDialogClose}
            templates={templates}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  materialDetails: state.permitReducer.materialDetails,
  permitId: state.permitReducer.permitId,
  projectDetails: state.permitReducer.projectDetails,
  userSelectionData: state.permitReducer.userSelectionData,
  currentlySending: state.permitReducer.currentlySending,
  messageType: state.permitReducer.messageType,
  paymentStatus: state.permitReducer.paymentStatus,
  panelManufactureList: state.permitReducer.panelManufactureList,
  siteDetails: state.permitReducer.siteDetails,
  rackingMfs: state.permitReducer.racking_mfs,
  photos: state.permitReducer.photos,
  applicationDetail: state.permitReducer.applicationDetail,
  templates: state.permitReducer.templates,
  selectedTemplate: state.permitReducer.selectedTemplate,
  mostUsedValues: state.permitReducer.mostUsedValues,
  appplyTemplate: state.permitReducer.appplyTemplate,
  arrayBuilder: state.permitReducer.arrayBuilder,
  inverterManufacturers: state.permitReducer.inverterManufacturers,
  sgMaterials: state.permitReducer.sgMaterials,
  sgMaterialsLoading: state.permitReducer.sgMaterialsLoading,
  otherUserSelectionData: state.permitReducer.otherUserSelectionData,
  storageIncluded: state.permitReducer.storageIncluded,
  featureList: state.permitReducer.featureList,
  companyInfo: state.permitReducer.companyInfo,
});

const mapDispatchToProps = (dispatch) => ({
  updateMaterialDetails: (payload) => dispatch(updateMaterialDetails(payload)),
  getMaterialDetails: (payload) => dispatch(getMaterialDetails(payload)),
  getS3URL: (payload) => dispatch(getS3URL(payload)),
  putToS3: (payload) => dispatch(putToS3(payload)),
  getPanelManufacturerList: (id) => dispatch(getPanelManufacturerList({ id })),
  setPhotos: (payload) => dispatch(setPhotos(payload)),
  setSelectedTemplate: (payload) => dispatch(setSelectedTemplate(payload)),
  getTemplates: (payload) => dispatch(getTemplates(payload)),
  setApplyTemplate: (payload) => dispatch(setApplyTemplate(payload)),
  checkUrlValidity: (payload) => dispatch(checkUrlValidity(payload)),
  createProjectDetails: (payload) => dispatch(createProjectDetails(payload)),
  getSGMaterials: (payload) => dispatch(getSGMaterials(payload)),
  addToCartAndGetUrl: (payload) => dispatch(addToCartAndGetUrl(payload)),
  postAdditionalStorageData: (payload) => dispatch(postAdditionalStorageData(payload)),
  setEnableSubmit: (enable) => dispatch(setEnableSubmit(enable)),
  permitDirectSubmit: (payload) => dispatch(permitDirectSubmit(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
