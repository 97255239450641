export const MOVE_TO_UNDER_PROGRESS_REQUEST = 'MOVE_TO_UNDER_PROGRESS_REQUEST';
export const MOVE_TO_UNDER_PROGRESS_SUCCESS = 'MOVE_TO_UNDER_PROGRESS_SUCCESS';
export const MOVE_TO_UNDER_PROGRESS_FAILURE = 'MOVE_TO_UNDER_PROGRESS_FAILURE';

export const MOVE_TO_PERMIT_DRAWINGS_COMPLETED_REQUEST = 'MOVE_TO_PERMIT_DRAWINGS_COMPLETED_REQUEST';
export const MOVE_TO_PERMIT_DRAWINGS_COMPLETED_SUCCESS = 'MOVE_TO_PERMIT_DRAWINGS_COMPLETED_SUCCESS';
export const MOVE_TO_PERMIT_DRAWINGS_COMPLETED_FAILURE = 'MOVE_TO_PERMIT_DRAWINGS_COMPLETED_FAILURE';

export const RESUBMISSION_REQUESTED_REQUEST = 'RESUBMISSION_REQUESTED_REQUEST';
export const RESUBMISSION_REQUESTED_SUCCESS = 'RESUBMISSION_REQUESTED_SUCCESS';
export const RESUBMISSION_REQUESTED_FAILURE = 'RESUBMISSION_REQUESTED_FAILURE';

export const RESUBMISSION_RECEIVED_REQUEST = 'RESUBMISSION_RECEIVED_REQUEST';
export const RESUBMISSION_RECEIVED_SUCCESS = 'RESUBMISSION_RECEIVED_SUCCESS';
export const RESUBMISSION_RECEIVED_FAILURE = 'RESUBMISSION_RECEIVED_FAILURE';

export const APPROVE_DOCUMENTS_REQUEST = 'APPROVE_DOCUMENTS_REQUEST';
export const APPROVE_DOCUMENTS_SUCCESS = 'APPROVE_DOCUMENTS_SUCCESS';
export const APPROVE_DOCUMENTS_FAILURE = 'APPROVE_DOCUMENTS_FAILURE';

export const MOVE_TO_QA_COMPLETED_REQUEST = 'MOVE_TO_QA_COMPLETED_REQUEST';
export const MOVE_TO_QA_COMPLETED_SUCCESS = 'MOVE_TO_QA_COMPLETED_SUCCESS';
export const MOVE_TO_QA_COMPLETED_FAILURE = 'MOVE_TO_QA_COMPLETED_FAILURE';

export const INTERNAL_KICKBACK_REQUEST = 'INTERNAL_KICKBACK_REQUEST';
export const INTERNAL_KICKBACK_SUCCESS = 'INTERNAL_KICKBACK_SUCCESS';
export const INTERNAL_KICKBACK_FAILURE = 'INTERNAL_KICKBACK_FAILURE';

export const GET_INTERNAL_REVISION_DETAILS_REQUEST = 'GET_INTERNAL_REVISION_DETAILS_REQUEST';
export const GET_INTERNAL_REVISION_DETAILS_SUCCESS = 'GET_INTERNAL_REVISION_DETAILS_SUCCESS';
export const GET_INTERNAL_REVISION_DETAILS_FAILURE = 'GET_INTERNAL_REVISION_DETAILS_FAILURE';

export const GET_EXTERNAL_REVISION_DETAILS_REQUEST = 'GET_EXTERNAL_REVISION_DETAILS_REQUEST';
export const GET_EXTERNAL_REVISION_DETAILS_SUCCESS = 'GET_EXTERNAL_REVISION_DETAILS_SUCCESS';
export const GET_EXTERNAL_REVISION_DETAILS_FAILURE = 'GET_EXTERNAL_REVISION_DETAILS_FAILURE';

export const STAMPING_SUBMIT_REQUEST = 'STAMPING_SUBMIT_REQUEST';
export const STAMPING_SUBMIT_SUCCESS = 'STAMPING_SUBMIT_SUCCESS';
export const STAMPING_SUBMIT_FAILURE = 'STAMPING_SUBMIT_FAILURE';

export const SAVE_INTERNAL_NOTES_REQUEST = 'SAVE_INTERNAL_NOTES_REQUEST';
export const SAVE_INTERNAL_NOTES_SUCCESS = 'SAVE_INTERNAL_NOTES_SUCCESS';
export const SAVE_INTERNAL_NOTES_FAILURE = 'SAVE_INTERNAL_NOTES_FAILURE';

export const SAVE_INTERNAL_FILES_REQUEST = 'SAVE_INTERNAL_FILES_REQUEST';
export const SAVE_INTERNAL_FILES_SUCCESS = 'SAVE_INTERNAL_FILES_SUCCESS';
export const SAVE_INTERNAL_FILES_FAILURE = 'SAVE_INTERNAL_FILES_FAILURE';

export const EDIT_INTERNAL_NOTES_REQUEST = 'EDIT_INTERNAL_NOTES_REQUEST';
export const EDIT_INTERNAL_NOTES_SUCCESS = 'EDIT_INTERNAL_NOTES_SUCCESS';
export const EDIT_INTERNAL_NOTES_FAILURE = 'EDIT_INTERNAL_NOTES_FAILURE';

export const GET_INSTALLER_LIST_REQUEST = 'GET_INSTALLER_LIST_REQUEST';
export const GET_INSTALLER_LIST_SUCCESS = 'GET_INSTALLER_LIST_SUCCESS';
export const GET_INSTALLER_LIST_FAILURE = 'GET_INSTALLER_LIST_FAILURE';

export const SEND_ADMIN_STAMPING_REQUEST = 'SEND_ADMIN_STAMPING_REQUEST';
export const SEND_ADMIN_STAMPING_SUCCESS = 'SEND_ADMIN_STAMPING_SUCCESS';
export const SEND_ADMIN_STAMPING_FAILURE = 'SEND_ADMIN_STAMPING_FAILURE';

export const DOC_TYPE = {
  APPROVAL_DOC: 'APPROVAL_DOC',
  RESUBMISSION_DOC: 'RESUBMISSION_DOC',
  EXTERNAL_REVISION_DOC: 'EXTERNAL_REVISION_DOC',
  PERMIT_PACKAGE: 'PERMIT_PACKAGE',
  INTERNAL_DOC: 'INTERNAL_DOC'
};
