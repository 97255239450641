import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import React from 'react';
import { autofill } from 'redux-form';
import { theme } from 'highcharts';
import { useEffect, useState } from 'react';
import { OTHER_SITE_LINK_ERROR } from '../constants';
import { get } from '../../../utils/lodash';
import { getToken } from '../../../utils/helper';
import clockIcon from './images/clock.png';
import settingsIcon from './images/settings.png';
import pointerIcon from './images/pointer.png';
import docIcon from './images/doc.png';
import experienceIcon from './images/experience.png';
import headPhonesIcon from './images/headphones.png';
import paperIcon from './images/paper.png';
import docCheckedIcon from './images/checkedDoc.png';
import cycleIcon from './images/cycle.png';
import { enphasePermitting, footerPermitting } from '../../../components/Permitting/images';

const useStyles = makeStyles((theme) => ({
  overview: {
    marginTop: theme.spacing(2.5),
    margin: 'auto',
    width: '96%',
    border: '1px solid #EFEFEF',
    alignContent: 'center',
  },
  iconPhoto: {
    width: '20%',
    height: 'auto',
  },
  createButton: {
    backgroundColor: theme.palette.tertiary.buttonColor,
    color: theme.palette.tertiary.main,
  },
  permitAssistanceButton: {
    color: theme.palette.tertiary.buttonColor,
    borderColor: theme.palette.tertiary.buttonColor,
  },
  overviewText: {
    margin: theme.spacing(5),
  },
  headerPhoto: {
    width: '100%',
    height: 'auto',
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(5),
    paddingTop: 0,
  },
  headerPhotoOverlay: {
    position: 'absolute',
  },
  iconBox: {
    background: '#FAFAFA',
  },
  cardContent: {
    padding: theme.spacing(4),
  },
  cardGrid: {
    border: '1px solid #EFEFEF',
    maxWidth: '48%',
    margin: theme.spacing(1.5),
  },
  icons: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    background: '#FAFAFA',
  },
  iconText: {
    fontWeight: theme.spacing(62.5),
    fontSize: theme.spacing(1.7),
    margin: 'auto',
    textAlign: 'center',
  },
  features: {
    background: '#FAFAFA',
    border: '1px solid #E3E3E3',
    width: '96%',
    margin: 'auto',
    marginTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'space-between',
  },
  featureIcon: {
    display: 'flex',
    padding: theme.spacing(3),
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
  emailText: {
    paddingBottom: theme.spacing(5),
  },
  permitAssistanceButtonTopMargin: {
    marginTop: theme.spacing(2),
  },
  emailFooter: {
    textAlign: 'center',
    width: '96%',
    margin: 'auto',
    marginTop: theme.spacing(5),
  },
  createButtonText: {
    color: '#FFFFFF',
    top: theme.spacing(5),
    left: theme.spacing(5),
    fontWeight: theme.spacing(75),
    fontSize: theme.spacing(4),
  },
  heading: {
    paddingBotton: theme.spacing(1.5),
    fontWeight: theme.spacing(62.5),
    fontSize: theme.spacing(2.5),
  },
  subHeading: {
    paddingTop: theme.spacing(1.5),
    paddingBotton: theme.spacing(1.5),
    fontWeight: theme.spacing(62.5),
    fontSize: theme.spacing(2),
  },
  headerButtons: {
    margin: 'auto',
    width: '96%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  overlayText: {
    top: '30%',
    left: '3%',
    position: 'absolute',
  },
  createButtonPos: {
    marginTop: theme.spacing(5),
  },
  overlayTextFooter: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    textAlign: 'center',
  },
  photoDisplay: {
    position: 'relative',
    display: 'flex',
  },
  pageHeading: {
    fontWeight: theme.spacing(62.5),
    fontSize: theme.spacing(3),
    color: '#335976',
  },
  text: {
    paddingTop: theme.spacing(1.5),
    paddingBotton: theme.spacing(1.5),
    fontWeight: theme.spacing(50),
    fontSize: theme.spacing(1.6),
  },
  content: {
    background: 'white',
  },
}));

const overviewText =
  'Enphase Design and Permitting Services is your partner on your permitting journey. We provide committed turnaround and accurate permit documents. In addition to PV and storage, we handle revision requests, as-built drawings and EV plan sets. We support all 50 states and AHJs, so you can focus 100% on your customers. With over a decade of experience and support across various modules and equipment, we leverage the latest in technology to help design the right system from the start.';
const permitPlanSetText =
  'We have handled over a million system designs, so we’re always prepared when you need to move fast. Our comprehensive design covers all elements of the permit package: Title page, PV layout, Attachments & Structural details, Single Line and 3-Line diagrams, Wire and conduit sizing, Interconnection methods, Placards, Spec sheets and Compliance documents.';
const engineeringReviewAndStampsText =
  'Get it all done with us. We ensure your projects meet all code requirements and industry standards. Our licensed professional engineers verify all structural and electrical designs prior to stamping.';

const NewInstaller = (props) => {
  const classes = useStyles();
  const [openCreateNewRequest, setOpenCreateNewRequest] = useState(false);
  const handleClose = () => setOpenCreateNewRequest(false);
  const [linkSiteInProgress, setLinkSiteInProgress] = useState(false);
  const [linkSiteMessage, setLinkSiteMessage] = useState('');
  const CreateSystemFlow = props.createSystemFlow;

  return (
    <Box>
      <Box className={classes.content}>
        <Box className={classes.permitAssistanceButtonTopMargin} />
        <Box className={classes.headerButtons}>
          <Typography className={classes.pageHeading}>{'Permit Assistance'}</Typography>
          {props.installerAppCount !== 0 && (
            <Button onClick={props.listingPageView} variant="outlined" className={classes.permitAssistanceButton}>
              Permit Applications
            </Button>
          )}
        </Box>
        <Box className={classes.overview}>
          <Box className={classes.photoDisplay}>
            <img className={classes.headerPhoto} src={enphasePermitting} />
            <Box className={classes.overlayText}>
              <Typography className={classes.createButtonText}>{'Enphase Design and Permitting Services:'}</Typography>
              <Typography className={classes.createButtonText}>{'Your partner of choice'}</Typography>
              <Box className={classes.createButtonPos}>
                <Button
                  onClick={() => {
                    setOpenCreateNewRequest(true);
                  }}
                  variant="contained"
                  className={classes.createButton}
                >
                  Create a New Permit Request
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className={classes.overviewText}>
            <Typography className={classes.heading}>Overview</Typography>
            <Typography className={classes.text}>{overviewText}</Typography>
          </Box>
          <Box className={classes.card}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.cardGrid}>
                <Box className={classes.cardContent}>
                  <Typography className={classes.heading}>Features</Typography>
                  <Typography className={classes.subHeading}>Permit Plan sets and As-Built Drawings</Typography>
                  <Typography className={classes.text}>{permitPlanSetText}</Typography>
                  <Typography className={classes.subHeading}>Engineering Review and Stamps</Typography>
                  <Typography className={classes.text}>{engineeringReviewAndStampsText}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} className={classes.cardGrid}>
                <Box className={classes.cardContent}>
                  <Typography className={classes.heading}>Why Choose Enphase Permitting Services</Typography>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box className={classes.icons}>
                        <img className={classes.iconPhoto} src={clockIcon} />
                        <Typography className={classes.iconText}>Committed Turnaround Time</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className={classes.icons}>
                        <img className={classes.iconPhoto} src={settingsIcon} />
                        <Typography className={classes.iconText}>Support For All Equipment Brands</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className={classes.icons}>
                        <img className={classes.iconPhoto} src={pointerIcon} />
                        <Typography className={classes.iconText}>Service All 50 States & AHJs</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className={classes.icons}>
                        <img className={classes.iconPhoto} src={docIcon} />
                        <Typography className={classes.iconText}>2000+ Permits Handled A Week</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.features}>
          <Box className={classes.featureIcon}>
            <img className={classes.iconPhoto} src={experienceIcon} />
            <Typography className={classes.iconText}>10+ Years Experience</Typography>
          </Box>
          <Box className={classes.featureIcon}>
            <img className={classes.iconPhoto} src={headPhonesIcon} />
            <Typography className={classes.iconText}>Dedicated Operations Support</Typography>
          </Box>
          <Box className={classes.featureIcon}>
            <img className={classes.iconPhoto} src={paperIcon} />
            <Typography className={classes.iconText}>1M+ Designs Completed</Typography>
          </Box>
          <Box className={classes.featureIcon}>
            <img className={classes.iconPhoto} src={docCheckedIcon} />
            <Typography className={classes.iconText}>High Approval Rate Across AHJs</Typography>
          </Box>
          <Box className={classes.featureIcon}>
            <img className={classes.iconPhoto} src={cycleIcon} />
            <Typography className={classes.iconText}>Adaptable To Your Workflow</Typography>
          </Box>
        </Box>
        <Box className={classes.features}>
          <Box className={classes.photoDisplay}>
            <img className={classes.headerPhoto} src={footerPermitting} />
            <Box className={classes.overlayTextFooter}>
              <Button
                onClick={() => setOpenCreateNewRequest(true)}
                variant="contained"
                className={classes.createButton}
              >
                Create a New Permit Request
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.emailFooter}>
          <Typography className={classes.emailText}>
            You can also directly reach out to us by email{' '}
            <a href="mailto: designandpermit@enphase.com">designandpermit@enphase.com</a> or calling 877-797-4743.
          </Typography>
        </Box>
      </Box>
      <CreateSystemFlow
        viewLinkSiteDialog={openCreateNewRequest}
        linkSystem={props.linkSystemWithPermit}
        headerName="Permit Assistance"
        proceedButtonText="Proceed"
        onSiteChange={props.onSiteChange}
        enlmToken={getToken()}
        handleClose={handleClose}
        service="Permit"
        canLinkSystem={true}
        companyId={get(props.accountDetail, 'company_id')}
        projectType="RESIDENTIAL"
        linkSiteInProgress={props.linkSiteInProgress}
        linkSiteMessage={props.linkSiteMessage}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({ accountDetail: state.permitReducer.accountDetail });

const mapDispatchToProps = (dispatch) => ({
  getPermittingCountByInstaller: (payload) => dispatch(getPermittingCountByInstaller(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewInstaller);
