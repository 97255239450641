import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import scrollIntoView from 'scroll-into-view';
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TableBody,
  CircularProgress,
  Link,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  Select,
  FormControl,
  Badge,
} from '@material-ui/core';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { DateRangePickerComponent } from 'materialui-daterange-picker';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import Loader from '../../../components/Loader';
import { localeMoment as moment } from '../../../components/common/moment';
import Snackbar from '../../../components/SnakBar';
import NewInstaller from '../PermittingListing/NewInstaller';
import { get, isEmpty, debounce } from '../../../utils/lodash';
import { installDateList, OTHER_SITE_LINK_ERROR, REQUEST_LISTING_COLUMNS } from '../constants';
import {
  getPermittingListing,
  getSearchResult,
  resetSiteSearchResult,
  getApplicationBySite,
  getTemplates,
  getUserSelectionData,
  getPanelManufacturerList,
  getRackingMfs,
  addEmptyTemplate,
  removeEmptyTemplate,
  getSiteDetails,
  getAccountData,
  getPermittingCountByInstaller,
} from '../action';
import TemplateDialog from '../TemplateDialog';
import PageHeader from '../../../components/common/PageHeader';
import Battery from '../../../components/Permitting/images/battery';
import Solar from '../../../components/Permitting/images/solar';
import { setEnlightenManagerToken } from '../../Layouts/actions';
import { setToken, getToken, constructQueryString } from '../../../utils/helper';
import Plus from '../../../components/Permitting/images/Plus';
import { styles } from './styles';
import { INSTALLER_STATES, PERMIT_SERVICE_ROLES } from '../../../utils/constants';
import HelpSection from '../../../components/Permitting/HelpSection';
import settings from '../../../utils/settings';

class PermittingListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      sort_column: null,
      sort_order: null,
      openCreateNewRequest: false,
      width: window.innerWidth - (window.innerWidth < 600 ? 56 : 72),
      showTemplateDialog: false,
      isNewTemplateOpen: false,
      multipleApplicationsDialog: false,
      showInstallDateOption: false,
      installDateFilterText: '',
      isDateRangeOpen: false,
      selectSpecificDateOpen: false,
      isAllDaysBefore: false,
      isAllDaysAfter: false,
      enableButton: false,
      dateRange: {},
      enableSelectedButton: false,
      selectedDate: '',
      createdDateSelectValue: '',
      lastModifiedSelectValue: '',
      lastModifiedFilterText: '',
      isFromCreatedDate: false,
      isFromLastModifiedDate: false,
      linkSiteInProgress: false,
      linkSiteMessage: '',
      newInstallerPageView: false,
      installerAppCount: -1,
      isNewInstallerViewSet: false,
    };
    this.filterParams = {
      customerName: '',
      siteId: '',
      permitId: '',
      applicationId: '',
      systemName: '',
      zip: '',
      startDate: '',
      endDate: '',
      lastModifiedStartDate: '',
      lastModifiedEndDate: '',
      installerUserName: '',
    };
    this.selectedValue = 'All';
    const { search } = props.location;
    const { siteId = '' } = queryString.parse(search);
    this.isUpdate = false;
    this.siteId = siteId;
    this.isScrollToNewTemplate = false;
    this.newTemplateInterval = null;
    this.multiplePermitApplications = [];
    this.breadcrumbPath = 'dashboard';
    const params = get(this.props, 'history.location.search') || '';
    const { origin, ...remainingQueryObj } = queryString.parse(params);
    this.searchParam = constructQueryString(remainingQueryObj) || '';
  }

  handlePermitCountByInstallerSuccess = (count) => {
    if (count == 0) {
      this.setState({ newInstallerPageView: true, installerAppCount: count });
    }
    this.setState({ isNewInstallerViewSet: true });
  };

  handlePermitCountByInstallerFailure = () => {
    this.setState({ isNewInstallerViewSet: true });
  };

  componentDidMount() {
    this.props.getAccountData({
      successCbk: () => {
        // only installer can access this listing page
        // admin has the different listing page
        if (this.props.permitServiceRole && PERMIT_SERVICE_ROLES.INSTALLER !== this.props.permitServiceRole) {
          window.location = settings.enlightenUrl('manager/dashboard');
        }
      },
    });
    this.updateEnlightenManagerToken();
    this.props.getTemplates();
    this.props.getSearchResult({ searchValue: '_ALL_SITES_', searchType: 'all_stages' });
    this.props.getPermittingCountByInstaller({
      successCbk: this.handlePermitCountByInstallerSuccess,
      faliureCbk: this.handlePermitCountByInstallerFailure,
    });
    this.props.getPermittingListing({
      pageNum: 0,
      recordCount: 10,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      ...this.filterParams,
    });
    this.props.getUserSelectionData();
    this.props.getRackingMfs();
    this.props.getPanelManufacturerList();
    const pathName = get(this.props, 'match.path', window.location.pathname) || window.location.pathname;
    this.breadcrumbPath = pathName && pathName.includes('account') ? 'account' : 'dashboard';
    window.addEventListener('resize', this.handleWindowSizeWindow);
  }

  componentDidUpdate() {
    this.updateEnlightenManagerToken();
    if (this.state.isNewTemplateOpen) {
      this.newTemplateInterval = setInterval(() => {
        if (document.getElementById('newTemplate') && !this.isScrollToNewTemplate) {
          scrollIntoView(document.getElementById('newTemplate'));
          this.isScrollToNewTemplate = true;
          this.resetFunction();
        }
      }, 1000);
    }
  }

  resetFunction = () => {
    this.isScrollToNewTemplate = false;
    this.setState({ isNewTemplateOpen: false }, () => {
      clearInterval(this.newTemplateInterval);
    });
  };

  updateEnlightenManagerToken = () => {
    const { enlightenToken, enlightenManagerToken } = this.props;
    const token = getToken() || enlightenToken;
    if (!enlightenManagerToken && token) {
      this.props.setEnlightenManagerToken(token);
      setToken(token);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeWindow);
  }

  handleWindowSizeWindow = () => {
    const width = window.innerWidth - (window.innerWidth < 600 ? 56 : 72);
    if (width !== this.state.width) {
      this.setState({ width });
    }
  };

  handleChangeRowsPerPage(event) {
    if (event.target.value) {
      this.props.getPermittingListing({
        pageNum: 0,
        recordCount: event.target.value,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        ...this.filterParams,
      });
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    }
  }

  handleChangePage(event, newPage) {
    this.props.getPermittingListing({
      pageNum: newPage,
      recordCount: this.state.rowsPerPage,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      ...this.filterParams,
    });
    this.setState({ page: newPage });
  }

  sortData(column_id) {
    let sOrder = '';
    if (this.state.sort_column === column_id) {
      if (this.state.sort_order === 'asc') {
        sOrder = 'desc';
      } else {
        sOrder = 'asc';
      }
      this.props.getPermittingListing({
        pageNum: 0,
        recordCount: this.state.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: sOrder,
        ...this.filterParams,
      });
      this.setState({ sort_order: sOrder });
    } else {
      this.props.getPermittingListing({
        pageNum: 0,
        recordCount: this.state.rowsPerPage,
        sort_column: column_id,
        sort_order: 'asc',
        ...this.filterParams,
      });
      this.setState({ sort_column: column_id, sort_order: 'asc' });
    }
  }

  handleClose = () => this.setState({ openCreateNewRequest: false });
  handleTemplateDialogClose = () => {
    this.setState({ showTemplateDialog: false });
    this.props.getTemplates();
    this.props.removeEmptyTemplate();
  };

  handleMultipleApplicationsDialogClose = () => {
    this.setState({ multipleApplicationsDialog: false });
    this.removeSelectedSite();
  };

  handleListFilter = (event, search = {}) => {
    if (event) {
      let newValue = event.target.value;
      const searchKey = search.key;
      if (searchKey === 'applicationId') {
        this.selectedValue = newValue;
        this.filterParams['subStateId'] = '';
        if (newValue === 'All') {
          newValue = '';
        } else if (newValue === '8.1') {
          newValue = '8';
          this.filterParams['subStateId'] = '1';
        } else if (newValue === '9.1') {
          this.filterParams['subStateId'] = '1';
          newValue = '9';
        }
      }
      this.filterParams[searchKey] = newValue;
      this.handleFilter();
    }
  };

  fromIframe() {
    const params = get(this.props, 'history.location.search') || '';
    const { origin = 'self' } = queryString.parse(params);
    return origin;
  }

  linkSystemWithPermit = ({ siteId }) => {
    if (siteId) {
      this.props.getApplicationBySite({ siteId: siteId, cbk: (result) => this.navigateToPermit(siteId, result) });
    } else {
      const params = this.searchParam
        ? `${this.searchParam}&origin=${this.fromIframe()}`
        : `?origin=${this.fromIframe()}`;
      window.location = `/manager/${this.breadcrumbPath}/permitting-assistance/new${params}`;
    }
  };

  onSiteChange = ({ siteId, cbk }) => {
    if (siteId) {
      this.setState({ linkSiteInProgress: true });
      this.props.getSiteDetails({
        siteId,
        successCbk: (result) => {
          if (result && result.length > 0 && result[0].site_type === 3) {
            this.setState({ linkSiteInProgress: false, linkSiteMessage: OTHER_SITE_LINK_ERROR });
          } else {
            this.setState({ linkSiteInProgress: false, linkSiteMessage: '' });
            cbk();
          }
        },
        failureCbk: () =>
          this.setState({ linkSiteInProgress: false, linkSiteMessage: 'Something went wrong, Please try again' }),
      });
    }
  };

  navigateToPermit = (siteId, result) => {
    if (result.status === 'success' && (get(result, 'permit_applications', []) || []).length <= 1) {
      if (result.permit_applications && get(result.permit_applications[0], 'permit_id')) {
        window.location = `/manager/${this.breadcrumbPath}/${siteId}/permitting-assistance/${get(
          result.permit_applications[0],
          'permit_id'
        )}${this.searchParam}`;
      } else {
        const params = this.searchParam
          ? `${this.searchParam}&origin=${this.fromIframe()}`
          : `?origin=${this.fromIframe()}`;
        window.location = `/manager/${this.breadcrumbPath}/${siteId}/permitting-assistance/new${params}`;
      }
    } else {
      this.multiplePermitApplications = get(result, 'permit_applications', []) || [];
      this.setState({ multipleApplicationsDialog: true });
    }
  };

  redirectToPermitView(pObj) {
    let url = '';
    if (get(pObj, 'site_id')) {
      url = `${get(pObj, 'site_id')}/permitting-assistance/${get(pObj, 'permit_id')}`;
    } else {
      url = `permitting-assistance/${get(pObj, 'permit_id')}`;
    }
    const params = this.searchParam
      ? `${this.searchParam}&origin=${this.fromIframe()}`
      : `?origin=${this.fromIframe()}`;
    window.location = `/manager/${this.breadcrumbPath}/${url}${params}`;
  }

  addTemplateButtonClick = () => {
    this.props.addEmptyTemplate({
      name: '',
      roof_type: '',
      panel_manufacturer: '',
      panel_model: '',
      interconnection_strategy: '',
      interconnection_location: '',
      interconnection_strategy_enpower: null,
      racking_manufacturer: '',
      attachment_manufacturer: '',
      racking_model: '',
      attachment_model: '',
      attachment_type: '',
      max_spacing_between_attachments: '',
      empty: true,
    });
    this.setState({ isNewTemplateOpen: true });
  };

  handleFilter = debounce(() => {
    this.props.getPermittingListing({
      pageNum: 0,
      recordCount: this.state.rowsPerPage,
      sort_column: this.state.sort_column,
      sort_order: 'asc',
      ...this.filterParams,
    });
  }, 1000);

  handleInstallDateChange = (event, column) => {
    const isApplicationDate = column.key === 'applicationDate';
    let value = get(event, 'target.value', '');
    if (!isEmpty(value)) {
      isApplicationDate
        ? this.setState({ createdDateSelectValue: value })
        : this.setState({ lastModifiedSelectValue: value });
    } else {
      value = isApplicationDate ? this.state.createdDateSelectValue : this.state.lastModifiedSelectValue;
    }

    let showDatePicker = false;
    const selectedInstallDate = installDateList.find((installDate) => installDate.key === value);
    if (value === 'date_range') {
      showDatePicker = true;
      this.setState({ isDateRangeOpen: true });
      isApplicationDate ? this.setState({ isFromCreatedDate: true }) : this.setState({ isFromLastModifiedDate: true });
    } else if (['specific_date', 'all_dates_before', 'all_dates_after'].includes(value)) {
      showDatePicker = true;
      this.setState({ selectSpecificDateOpen: true });
      isApplicationDate ? this.setState({ isFromCreatedDate: true }) : this.setState({ isFromLastModifiedDate: true });
      if (value === 'all_dates_before') {
        this.setState({ isAllDaysBefore: true });
      } else if (value === 'all_dates_after') {
        this.setState({ isAllDaysAfter: true });
      } else {
        this.setState({ isAllDaysBefore: false, isAllDaysAfter: false });
      }
    }
    if (!showDatePicker) {
      const startSearchKey = column.key === 'applicationDate' ? 'startDate' : 'lastModifiedStartDate';
      const endSearchKey = column.key === 'applicationDate' ? 'endDate' : 'lastModifiedEndDate';
      const todayDate = new Date();
      const todayStartingDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
      if (value === 'today') {
        this.filterParams[startSearchKey] = todayStartingDate.getTime();
        this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
        this.handleFilter();
      } else if (value === 'past_7_days') {
        const weekEarlierDate = new Date(todayStartingDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        this.filterParams[startSearchKey] = weekEarlierDate.getTime();
        this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
        this.handleFilter();
      } else if (value === 'month_date') {
        const startMonthDate = new Date(moment().startOf('month'));
        this.filterParams[startSearchKey] = startMonthDate.getTime();
        this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
        this.handleFilter();
      } else if (value === 'year_date') {
        const startYearDate = new Date(moment().startOf('year'));
        this.filterParams[startSearchKey] = startYearDate.getTime();
        this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
        this.handleFilter();
      } else if (value === 'clear_dates') {
        value = '';
        this.filterParams[startSearchKey] = '';
        this.filterParams[endSearchKey] = '';
        this.handleFilter();
      }
      const installDateFilterText = value === '' ? '' : get(selectedInstallDate, 'text', '');
      isApplicationDate
        ? this.setState({ installDateFilterText })
        : this.setState({ lastModifiedFilterText: installDateFilterText });
    }
  };

  handleCloseDateRange = () => {
    this.setState({
      isDateRangeOpen: false,
      enableButton: false,
      isFromCreatedDate: false,
      isFromLastModifiedDate: false,
    });
  };

  toggle = () => this.setState({ isDateRangeOpen: !this.state.isDateRangeOpen });

  handleDateRangeChange = (range) => {
    this.setState({ dateRange: range });
    !this.state.enableButton && this.setState({ enableButton: true });
  };

  handleSaveDateRange = () => {
    this.setState({ isDateRangeOpen: false, enableButton: false });
    const { startDate, endDate } = this.state.dateRange;
    const modifiedStartDate = moment(startDate).format('M/D/YY');
    const modifiedEndDate = moment(endDate).format('M/D/YY');
    const installDateFilterText = `${modifiedStartDate} - ${modifiedEndDate}`;
    let startSearchKey;
    let endSearchkey;
    if (this.state.isFromCreatedDate) {
      this.setState({ installDateFilterText: installDateFilterText, isFromCreatedDate: false });
      startSearchKey = 'startDate';
      endSearchkey = 'endDate';
    }
    if (this.state.isFromLastModifiedDate) {
      startSearchKey = 'lastModifiedStartDate';
      endSearchkey = 'lastModifiedEndDate';
      this.setState({ lastModifiedFilterText: installDateFilterText, isFromLastModifiedDate: false });
    }

    this.filterParams[startSearchKey] = new Date(modifiedStartDate).getTime();
    this.filterParams[endSearchkey] = new Date(modifiedEndDate).getTime() + 86400000;
    this.handleFilter();
  };

  handleCloseSelectedDate = () => {
    this.setState({
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
      isAllDaysBefore: false,
      isAllDaysAfter: false,
      selectedDate: '',
      isFromCreatedDate: false,
      isFromLastModifiedDate: false,
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    !this.state.enableSelectedButton && this.setState({ enableSelectedButton: true });
  };

  handleSaveSelectedDate = () => {
    this.setState({
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
    });
    let filterText = `${moment(this.state.selectedDate).format('M/D/YY')}`;
    const filterDate = new Date(filterText);
    let installDateFilterText = filterText;
    let startSearchKey;
    let endSearchKey;
    if (this.state.isFromCreatedDate) {
      startSearchKey = 'startDate';
      endSearchKey = 'endDate';
    }
    if (this.state.isFromLastModifiedDate) {
      startSearchKey = 'lastModifiedStartDate';
      endSearchKey = 'lastModifiedEndDate';
    }
    if (this.state.isAllDaysBefore) {
      this.filterParams[startSearchKey] = '';
      this.filterParams[endSearchKey] = filterDate.getTime();
      this.handleFilter();
      installDateFilterText = ` - ${installDateFilterText}`;
    } else if (this.state.isAllDaysAfter) {
      this.filterParams[startSearchKey] = filterDate.getTime();
      this.filterParams[endSearchKey] = '';
      this.handleFilter();
      installDateFilterText = `${installDateFilterText} - `;
    } else {
      this.filterParams[startSearchKey] = filterDate.getTime();
      this.filterParams[endSearchKey] = filterDate.getTime() + 86400000;
      this.handleFilter();
    }
    this.setState({
      isAllDaysBefore: false,
      isAllDaysAfter: false,
    });
    if (this.state.isFromCreatedDate) {
      this.setState({ installDateFilterText, isFromCreatedDate: false });
    }
    if (this.state.isFromLastModifiedDate) {
      this.setState({ lastModifiedFilterText: installDateFilterText, isFromLastModifiedDate: false });
    }
  };

  listingPageView = () => {
    this.setState({ newInstallerPageView: false });
  };

  newInstallerView = () => {
    this.setState({ newInstallerPageView: true });
  };

  createNewRequest = () => {
    this.setState({ openCreateNewRequest: true });
  };

  renderDateList = () =>
    installDateList.map((installDate, index) => (
      <MenuItem key={index} value={installDate.key}>
        {installDate.text}
      </MenuItem>
    ));

  render() {
    const {
      classes,
      currentlySending,
      getPermittingRequetsInProgress,
      permittingRequests,
      totalElements,
      message,
      messageType,
      templates,
      enlightenManagerToken,
      createSystemFlow: CreateSystemFlow = () => <Fragment />,
    } = this.props;
    if (this.siteId || !enlightenManagerToken) {
      return <Loader loading />;
    }
    const { sort_column, sort_order, page, rowsPerPage, openCreateNewRequest } = this.state;

    return !this.state.isNewInstallerViewSet ? (
      <Loader loading={true} />
    ) : this.state.newInstallerPageView ? (
      <NewInstaller
        listingPageView={this.listingPageView}
        installerAppCount={this.state.installerAppCount}
        createNewRequest={this.createNewRequest}
        createSystemFlow={CreateSystemFlow}
        enlightenManagerToken={enlightenManagerToken}
        getApplicationBySite={this.props.getApplicationBySite}
        linkSystemWithPermit={this.linkSystemWithPermit}
        onSiteChange={this.onSiteChange}
        linkSiteInProgress={this.state.linkSiteInProgress}
        linkSiteMessage={this.state.linkSiteMessage}
      ></NewInstaller>
    ) : (
      <div className={classes.root} style={{ width: `${this.state.width}px` }}>
        <div className={classes.header}>
          <Typography component="div" className={classes.headerInfo}>
            <Typography variant="h5" component="h5">
              Permit Assistance
            </Typography>
            <Typography className={classes.termsText} component="div">
              {`Get Solar and Storage Permit Packages for your sites that you can submit to your local Authority for approval. `}
            </Typography>
          </Typography>

          <div className={classes.displayFlex}>
            <Typography
              onClick={() => this.setState({ showTemplateDialog: true })}
              className={classes.managePermitText}
            >
              Manage Permit Template
            </Typography>

            <Button
              size="small"
              color="primary"
              variant="outlined"
              className={classes.createNewRequest}
              disableRipple
              onClick={() => {
                this.setState({ openCreateNewRequest: true });
              }}
            >
              Create a New Request
            </Button>

            <HelpSection newInstallerView={this.newInstallerView} isNewinstallerViewEnabled={true} />
          </div>
        </div>
        {currentlySending === true ? (
          <Loader loading={currentlySending} />
        ) : (
          <Fragment>
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <Paper>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader style={{ borderCollapse: 'collapse' }}>
                      <TableHead className={classes.tableHeader}>
                        <TableRow className={classes.tableRow}>
                          {REQUEST_LISTING_COLUMNS.map((column, index) => {
                            return (
                              <TableCell key={index} className={classes.headerContainer}>
                                <span className={classes.headerText} key={index}>
                                  {column.sortable === true ? (
                                    <IconButton
                                      size="small"
                                      className={classes.headerButtonText}
                                      disableRipple={true}
                                      onClick={() => this.sortData(column.id)}
                                    >
                                      {column.label}
                                      {sort_column === column.id &&
                                        (sort_order === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      size="small"
                                      className={classes.headerButton}
                                      disabled={true}
                                      style={{ color: '#FFE785' }}
                                      disableRipple={true}
                                    >
                                      {column.label}
                                    </IconButton>
                                  )}
                                </span>
                                <span className={classes.searchTableCell} colSpan={REQUEST_LISTING_COLUMNS.length}>
                                  {column.hasSearch &&
                                    column.key !== 'applicationId' &&
                                    column.key !== 'applicationDate' &&
                                    column.key !== 'lastUpdatedDate' && (
                                      <TextField
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ classes: { input: classes.inputField } }}
                                        onChange={(e) => this.handleListFilter(e, column)}
                                        variant="outlined"
                                        size="small"
                                      />
                                    )}
                                  {column.hasSearch && column.key === 'applicationId' && (
                                    <FormControl variant="outlined">
                                      <Select
                                        className={classes.styleSelect}
                                        onChange={(e) => this.handleListFilter(e, column)}
                                        value={this.selectedValue}
                                      >
                                        <MenuItem value="All">All</MenuItem>
                                        {Object.values(INSTALLER_STATES).map((state) => (
                                          <MenuItem value={state.id}>{state.name}</MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )}
                                  {column.hasSearch && column.key === 'applicationDate' && (
                                    <Fragment>
                                      <Select
                                        variant="outlined"
                                        value={this.state.createdDateSelectValue}
                                        onClick={(e) => this.handleInstallDateChange(e, column)}
                                        renderValue={() => this.state.installDateFilterText}
                                      >
                                        {this.renderDateList()}
                                      </Select>
                                    </Fragment>
                                  )}
                                  {column.hasSearch && column.key === 'lastUpdatedDate' && (
                                    <Fragment>
                                      <Select
                                        variant="outlined"
                                        value={this.state.lastModifiedSelectValue}
                                        onClick={(e) => this.handleInstallDateChange(e, column)}
                                        renderValue={() => this.state.lastModifiedFilterText}
                                      >
                                        {this.renderDateList()}
                                      </Select>
                                    </Fragment>
                                  )}
                                </span>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      {getPermittingRequetsInProgress ? (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={REQUEST_LISTING_COLUMNS.length}
                              style={{ textAlign: 'center', border: 'none' }}
                            >
                              <br />
                              <br />
                              <br />
                              <CircularProgress size={15} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : isEmpty(permittingRequests) ? (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={REQUEST_LISTING_COLUMNS.length} style={{ textAlign: 'center' }}>
                              <Typography variant="caption">** No Requests Available **</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {permittingRequests.map((row, index) => {
                            const createdDate = get(row, 'application_date', '');
                            const lastModifedDate = get(row, 'last_modified_date', '');
                            const unreadMessagesCount = get(row, 'unread_messages_count', 0);
                            return (
                              <TableRow key={index}>
                                <TableCell className={classes.permitType}>
                                  {get(row, 'project_details.solar_system_size', '') ? <Solar /> : null}
                                  {get(row, 'project_details.storage_size', '') ? <Battery /> : null}
                                </TableCell>
                                <TableCell>
                                  {get(row, 'permit_id') && (
                                    <Link
                                      component="button"
                                      variant="body2"
                                      className={classes.linkColour}
                                      onClick={() => {
                                        this.redirectToPermitView(row);
                                      }}
                                    >
                                      {row.permit_id}
                                    </Link>
                                  )}
                                  {!get(row, 'permit_id') && 'NA'}
                                </TableCell>
                                <TableCell>{get(row, 'installer_user_name') || 'NA'}</TableCell>
                                <TableCell>{get(row, 'project_details.customer_name') || 'NA'}</TableCell>
                                <TableCell>{row.site_id || 'NA'}</TableCell>
                                <TableCell>{get(row, 'project_details.system_name') || 'NA'}</TableCell>
                                <TableCell>{get(row, 'project_details.zipcode') || 'NA'}</TableCell>
                                <TableCell>
                                  {unreadMessagesCount > 0 ? (
                                    <Badge
                                      badgeContent={
                                        unreadMessagesCount > 1 ? (
                                          <span style={{ fontSize: '10px' }}>
                                            {' '}
                                            {unreadMessagesCount + ' Messages'}{' '}
                                          </span>
                                        ) : (
                                          <span style={{ fontSize: '10px' }}> {unreadMessagesCount + ' Message'} </span>
                                        )
                                      }
                                      color="primary"
                                      className={clsx(
                                        classes.chatBadge,
                                        unreadMessagesCount === 1 && classes.slightlyLeft
                                      )}
                                      classes={{ badge: classes.badgeContent }}
                                    >
                                      {(get(row, 'installer_current_status.substate_name') || 'NA') === 'NA'
                                        ? get(row, 'installer_current_status.state_name') || 'NA'
                                        : get(row, 'installer_current_status.substate_name')}
                                    </Badge>
                                  ) : (
                                    <Fragment>
                                      {(get(row, 'installer_current_status.substate_name') || 'NA') === 'NA'
                                        ? get(row, 'installer_current_status.state_name') || 'NA'
                                        : get(row, 'installer_current_status.substate_name')}
                                    </Fragment>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {createdDate ? moment(createdDate).format('DD MMM YYYY hh:mm A') : 'NA'}
                                </TableCell>
                                <TableCell>
                                  {lastModifedDate ? moment(lastModifedDate).format('DD MMM YYYY hh:mm A') : 'NA'}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={(e, newPage) => this.handleChangePage(e, newPage)}
                    onChangeRowsPerPage={(e) => this.handleChangeRowsPerPage(e)}
                  />
                </Paper>
              </Grid>
            </Grid>
            <CreateSystemFlow
              viewLinkSiteDialog={openCreateNewRequest}
              linkSystem={this.linkSystemWithPermit}
              headerName="Permit Assistance"
              proceedButtonText="Proceed"
              onSiteChange={this.onSiteChange}
              enlmToken={getToken()}
              handleClose={this.handleClose}
              service="Permit"
              canLinkSystem={true}
              companyId={get(this.props.accountDetail, 'company_id')}
              projectType="RESIDENTIAL"
              linkSiteInProgress={this.state.linkSiteInProgress}
              linkSiteMessage={this.state.linkSiteMessage}
            />
            <Dialog open={this.state.isDateRangeOpen} onClose={this.handleCloseDateRange}>
              <DialogContent className={classes.dateRangeWrapper}>
                <DateRangePickerComponent
                  open={this.state.isDateRangeOpen}
                  definedRanges={[]}
                  toggle={this.toggle}
                  onChange={(range) => this.handleDateRangeChange(range)}
                  closeOnClickOutside
                  initialDateRange={this.state.dateRange}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!this.state.enableButton}
                  className={classes.okButton}
                  onClick={this.handleSaveDateRange}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.selectSpecificDateOpen} onClose={this.handleCloseSelectedDate}>
              <DialogContent className={classes.dateRangeWrapper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="static"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={this.state.selectedDate}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!this.state.enableSelectedButton}
                  className={classes.okButton}
                  onClick={this.handleSaveSelectedDate}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        )}

        {this.state.showTemplateDialog && (
          <Dialog
            open={this.state.showTemplateDialog}
            onClose={this.handleTemplateDialogClose}
            className={classes.dialogContainer}
            fullWidth
            maxWidth={templates.length > 0 ? 'xl' : 'sm'}
            classes={{ paper: clsx(templates.length > 0 && classes.dialogPaper) }}
          >
            <DialogTitle className={classes.dialogTemplateHeader} disableTypography>
              <div>
                <PageHeader text="Templates" />
                {templates.length > 0 && (
                  <Typography className={classes.styleTemplateText}>
                    Pre-fill your permit assistance application with pre-defined values from one of the below templates
                  </Typography>
                )}
              </div>
              <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleTemplateDialogClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            {templates.length > 0 && (
              <Button
                disableRipple
                disableElevation
                className={clsx(
                  classes.addTemplateButton,
                  this.props.disableAddTemplateButton && classes.disabledBorder
                )}
                onClick={this.addTemplateButtonClick}
                disabled={this.props.disableAddTemplateButton}
              >
                {' '}
                Add Template <Plus disabled={this.props.disableAddTemplateButton} />{' '}
              </Button>
            )}
            <DialogContent>
              {templates.length > 0 &&
                templates.map((template, index) => (
                  <TemplateDialog key={index} template={template} isFrom="Permit Listing" />
                ))}
              {templates.length === 0 && (
                <Typography className={classes.bottomMargin}>
                  You can use this button to manage your templates which can be used to quickly pre-fill multiple fields
                  in your applications. Templates will become available once your first application has been submitted.
                </Typography>
              )}
            </DialogContent>
          </Dialog>
        )}

        {this.state.multipleApplicationsDialog && (
          <Dialog
            open={this.state.multipleApplicationsDialog}
            onClose={this.handleMultipleApplicationsDialogClose}
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle onClose={this.handleMultipleApplicationsDialogClose}>
              <Typography className={clsx(classes.dialogHeader, classes.dialogTemplateHeader)}>
                Select Application
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={this.handleMultipleApplicationsDialogClose}
                >
                  <CloseIcon />
                </IconButton>
              </Typography>
            </DialogTitle>

            <DialogContent>
              <TableContainer className={classes.container}>
                <Table stickyHeader style={{ borderCollapse: 'collapse' }}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.headerContainer}>
                        <span className={clsx(classes.headerText, classes.headerTextSpanHeight, classes.leftPadding)}>
                          <IconButton
                            size="small"
                            className={classes.headerButton}
                            disabled={true}
                            style={{ color: '#FFE785' }}
                            disableRipple={true}
                          >
                            REQUEST ID
                          </IconButton>
                        </span>
                      </TableCell>
                      <TableCell className={classes.headerContainer}>
                        <span className={clsx(classes.headerText, classes.headerTextSpanHeight, classes.leftPadding)}>
                          <IconButton
                            size="small"
                            className={classes.headerButton}
                            disabled={true}
                            style={{ color: '#FFE785' }}
                            disableRipple={true}
                          >
                            PERMIT ASSISTANCE STATUS
                          </IconButton>
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.multiplePermitApplications.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {get(row, 'permit_id') && (
                              <Link
                                component="button"
                                variant="body2"
                                className={classes.linkColour}
                                onClick={() => {
                                  this.redirectToPermitView(row);
                                }}
                              >
                                {row.permit_id}
                              </Link>
                            )}
                            {!get(row, 'permit_id') && 'NA'}
                          </TableCell>

                          <TableCell>
                            {(get(row, 'installer_current_status.substate_name') || 'NA') === 'NA'
                              ? get(row, 'installer_current_status.state_name') || 'NA'
                              : get(row, 'installer_current_status.substate_name')}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </Dialog>
        )}

        {message && messageType && typeof message === 'string' && <Snackbar severity={messageType} message={message} />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPermittingListing: (payload) => dispatch(getPermittingListing(payload)),
  getSearchResult: (payload) => dispatch(getSearchResult(payload)),
  getApplicationBySite: (payload) => dispatch(getApplicationBySite(payload)),
  resetSiteSearchResult: () => dispatch(resetSiteSearchResult()),
  getTemplates: (payload) => dispatch(getTemplates(payload)),
  getUserSelectionData: () => dispatch(getUserSelectionData()),
  getPanelManufacturerList: (id) => dispatch(getPanelManufacturerList({ id })),
  getRackingMfs: (payload) => dispatch(getRackingMfs(payload)),
  setEnlightenManagerToken: (tokenVal) => dispatch(setEnlightenManagerToken(tokenVal)),
  addEmptyTemplate: (payload) => dispatch(addEmptyTemplate(payload)),
  removeEmptyTemplate: (payload) => dispatch(removeEmptyTemplate(payload)),
  getSiteDetails: (payload) => dispatch(getSiteDetails(payload)),
  getAccountData: (payload) => dispatch(getAccountData(payload)),
  getPermittingCountByInstaller: (payload) => dispatch(getPermittingCountByInstaller(payload)),
});

const mapStateToProps = (state) => ({
  totalElements: state.permitReducer.totalElements,
  permittingRequests: state.permitReducer.permittingRequests,
  getPermittingRequetsInProgress: state.permitReducer.getPermittingRequetsInProgress,
  message: state.permitReducer.message,
  messageType: state.permitReducer.messageType,
  searchResults: state.permitReducer.searchResults,
  siteSearchInProgress: state.permitReducer.siteSearchInProgress,
  templates: state.permitReducer.templates,
  enlightenManagerToken: state.appReducer.enlightenManagerToken,
  disableAddTemplateButton: state.permitReducer.disableAddTemplateButton,
  permitServiceRole: state.permitReducer.permitServiceRole,
  accountDetail: state.permitReducer.accountDetail,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PermittingListing));
