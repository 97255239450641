import {
  APPROVE_DOCUMENTS_REQUEST,
  GET_EXTERNAL_REVISION_DETAILS_REQUEST,
  GET_INTERNAL_REVISION_DETAILS_REQUEST,
  INTERNAL_KICKBACK_REQUEST,
  MOVE_TO_PERMIT_DRAWINGS_COMPLETED_REQUEST,
  MOVE_TO_QA_COMPLETED_REQUEST,
  MOVE_TO_UNDER_PROGRESS_REQUEST,
  RESUBMISSION_RECEIVED_REQUEST,
  RESUBMISSION_REQUESTED_REQUEST,
  STAMPING_SUBMIT_REQUEST,
  SAVE_INTERNAL_NOTES_REQUEST,
  SAVE_INTERNAL_FILES_REQUEST,
  EDIT_INTERNAL_NOTES_REQUEST,
  GET_INSTALLER_LIST_REQUEST,
  SEND_ADMIN_STAMPING_REQUEST
} from './constants';

export function moveToUnderProgress(payload) {
  return { type: MOVE_TO_UNDER_PROGRESS_REQUEST, payload };
}

export function moveToPermitDrawings(payload) {
  return { type: MOVE_TO_PERMIT_DRAWINGS_COMPLETED_REQUEST, payload };
}

export function moreInformationRequest(payload) {
  return { type: RESUBMISSION_REQUESTED_REQUEST, payload };
}

export function moreInformationSubmit(payload) {
  return { type: RESUBMISSION_RECEIVED_REQUEST, payload };
}

export function approveDocuments(payload) {
  return { type: APPROVE_DOCUMENTS_REQUEST, payload };
}

export function moveToQAComplete(payload) {
  return { type: MOVE_TO_QA_COMPLETED_REQUEST, payload };
}

export function internalKickback(payload) {
  return { type: INTERNAL_KICKBACK_REQUEST, payload };
}

export function getRevisionDetails(payload) {
  return { type: GET_INTERNAL_REVISION_DETAILS_REQUEST, payload };
}

export function getExternalRevisionDetails(payload) {
  return { type: GET_EXTERNAL_REVISION_DETAILS_REQUEST, payload };
}

export function stampingSubmit(payload) {
  return { type: STAMPING_SUBMIT_REQUEST, payload };
}

export function saveInternalNotes(payload) {
  return { type: SAVE_INTERNAL_NOTES_REQUEST, payload };
}

export function saveInternalFiles(payload) {
  return { type: SAVE_INTERNAL_FILES_REQUEST, payload };
}

export function editInternalNotes(payload) {
  return { type: EDIT_INTERNAL_NOTES_REQUEST, payload };
}
export function getInstallerList(payload) {
  return { type: GET_INSTALLER_LIST_REQUEST, payload };
}

export function stampingRequest(payload) {
  return { type: SEND_ADMIN_STAMPING_REQUEST, payload };
}
