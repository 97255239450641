import React from 'react';

const MailIcon = () => {
  return (
    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 0H1.5C1.10218 0 0.720643 0.158034 0.439339 0.439339C0.158034 0.720643 0 1.10218 0 1.5V13.5C0 13.8978 0.158034 14.2794 0.439339 14.5607C0.720643 14.842 1.10218 15 1.5 15H19.5C19.8978 15 20.2794 14.842 20.5607 14.5607C20.842 14.2794 21 13.8978 21 13.5V1.5C21 1.10218 20.842 0.720643 20.5607 0.439339C20.2794 0.158034 19.8978 0 19.5 0ZM17.849 1.5L10.5 6.588L3.151 1.5H17.849ZM1.5 13.5V2.179L10.076 8.116C10.2014 8.20296 10.3504 8.24938 10.503 8.249C10.6556 8.24938 10.8046 8.20296 10.93 8.116L19.503 2.181V13.5H1.5Z" fill="#4A4A4A" />
    </svg>
  );
};

export default MailIcon;
