import React from 'react';
import { Label } from '../../../../../../common/Label';
import { get, isEmpty } from '../../../../../../../utils/lodash';
import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import CustomPanel from '../../../../../../common/PanelTemplateWithMandatory';
import { NOMINAL_VOLTAGE } from '../../../../../../../containers/Admin/constants';
import { SingleSelectDropdown } from '../../../../../../common/SingleSelectDropdown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getNominalVoltageKey } from '../../../../../../../containers/Admin/helper';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  }
}));

const ElectricalDetailsSection = props => {
  const classes = useStyles();
  const {
    readOnly,
    errorData,
    sectionLabel,
    preventDefault,
    getErrorText,
    getHelperText,
    electricalDetails,
    handleElectricalDetails
  } = props;
  const {
    integrated_inverter_model = '',
    max_power_rating_stc = '',
    max_power_rating_ptc = '',
    maximum_continuous_output_power = '',
    max_ocpd_rating = '',
    module_efficiency_dc = '',
    peak_inverter_efficiency = '',
    energy_degradation = '',
    nominal_voltage_ac = '',
    voltage_phase = '',
    max_cont_current_ac = '',
    max_string_size = '',
    inverter_efficiency = ''
  } = electricalDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                className={classes.inputField}
                id="integrated_inverter_model"
                freeSolo={true}
                disabled={readOnly}
                options={[ 'Others' ]}
                inputValue={integrated_inverter_model}
                onInputChange={(e, value) => handleElectricalDetails(e, 'integrated_inverter_model', value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={!isEmpty(get(errorData, 'integrated_inverter_model'))}
                    helperText={get(errorData, 'integrated_inverter_model')}
                    label={<Label text={'Integrated Inverter Model'} isRequired />}
                  />
                )}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_power_rating_stc'}
                className={classes.inputField}
                label={<Label text={'Max Power Rating DC - STC (W)'} isRequired />}
                type="number"
                value={max_power_rating_stc || ''}
                onChange={e => handleElectricalDetails(e, 'max_power_rating_stc')}
                error={!isEmpty(get(errorData, 'max_power_rating_stc'))}
                helperText={get(errorData, 'max_power_rating_stc')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_power_rating_ptc'}
                className={classes.inputField}
                label={<Label text={'Max Power Rating (Pmax) - PTC (W)'} />}
                type="number"
                value={max_power_rating_ptc || ''}
                onChange={e => handleElectricalDetails(e, 'max_power_rating_ptc')}
                error={!isEmpty(get(errorData, 'max_power_rating_ptc'))}
                helperText={get(errorData, 'max_power_rating_ptc')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'maximum_continuous_output_power'}
                className={classes.inputField}
                label={<Label text={'Maximum Continuous Output Power (VA)'} isRequired />}
                type="number"
                value={maximum_continuous_output_power || ''}
                onChange={e => handleElectricalDetails(e, 'maximum_continuous_output_power')}
                error={!isEmpty(get(errorData, 'maximum_continuous_output_power'))}
                helperText={get(errorData, 'maximum_continuous_output_power')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_ocpd_rating'}
                className={classes.inputField}
                label={<Label text={'Max OCPD Rating (A)'} />}
                type="number"
                value={max_ocpd_rating || ''}
                onChange={e => handleElectricalDetails(e, 'max_ocpd_rating')}
                error={!isEmpty(get(errorData, 'max_ocpd_rating'))}
                helperText={get(errorData, 'max_ocpd_rating')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'module_efficiency_dc'}
                className={classes.inputField}
                label={<Label text={'Module DC Efficiency (%)'} />}
                type="number"
                value={module_efficiency_dc || ''}
                onChange={e => handleElectricalDetails(e, 'module_efficiency_dc')}
                error={!isEmpty(get(errorData, 'module_efficiency_dc'))}
                helperText={get(errorData, 'module_efficiency_dc')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'peak_inverter_efficiency'}
                className={classes.inputField}
                label={<Label text={'Inverter Peak Efficiency (%)'} isRequired />}
                type="number"
                value={peak_inverter_efficiency || ''}
                onChange={e => handleElectricalDetails(e, 'peak_inverter_efficiency')}
                error={!isEmpty(get(errorData, 'peak_inverter_efficiency'))}
                helperText={get(errorData, 'peak_inverter_efficiency')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'energy_degradation'}
                className={classes.inputField}
                label={
                  <Label
                    text={'Energy Degradation (%/year) (If not altered a default of 0.5% will be used)'}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={energy_degradation || ''}
                onChange={e => handleElectricalDetails(e, 'energy_degradation')}
                error={!isEmpty(get(errorData, 'energy_degradation'))}
                helperText={get(errorData, 'energy_degradation')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Nominal Voltage (AC)'} />}
                dropDownOptions={NOMINAL_VOLTAGE}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDetails}
                fieldId="nominal_voltage_ac"
                fieldValue={getNominalVoltageKey(nominal_voltage_ac, voltage_phase)}
                readOnly={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_cont_current_ac'}
                className={classes.inputField}
                label={<Label text={'Max Cont Current AC (A)'} isRequired />}
                type="number"
                value={max_cont_current_ac || ''}
                onChange={e => handleElectricalDetails(e, 'max_cont_current_ac')}
                error={!isEmpty(get(errorData, 'max_cont_current_ac'))}
                helperText={get(errorData, 'max_cont_current_ac')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_string_size'}
                className={classes.inputField}
                label={<Label text={'Max String Size'} isRequired />}
                type="number"
                value={max_string_size || ''}
                onKeyPress={e => preventDefault(e)}
                onChange={e => handleElectricalDetails(e, 'max_string_size')}
                error={!isEmpty(get(errorData, 'max_string_size'))}
                helperText={get(errorData, 'max_string_size')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'inverter_efficiency'}
                className={classes.inputField}
                label={<Label text={'Inverter CEC weighted efficiency (%)'} isRequired />}
                type="number"
                value={inverter_efficiency || ''}
                onChange={e => handleElectricalDetails(e, 'inverter_efficiency')}
                error={!isEmpty(get(errorData, 'inverter_efficiency'))}
                helperText={get(errorData, 'inverter_efficiency')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ElectricalDetailsSection;
