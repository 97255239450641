import settings from '../../../utils/settings';
import { isEqual } from '../../../utils/lodash';
import { getToken } from '../../../utils/helper';
import { take, put, call, takeEvery } from 'redux-saga/effects';
import { deleteMethod, get, post, putMethod } from '../../../utils/api';
import {
  GET_ALL_MODE_MAPPINGS_REQUEST,
  GET_ALL_MODE_MAPPINGS_SUCCESS,
  GET_ALL_MODE_MAPPINGS_FAILURE,
  CREATE_MODE_MAPPING_REQUEST,
  CREATE_MODE_MAPPING_SUCCESS,
  CREATE_MODE_MAPPING_FAILURE,
  GET_MODE_MAPPINGS_REQUEST,
  GET_MODE_MAPPINGS_SUCCESS,
  GET_MODE_MAPPINGS_FAILURE,
  GET_ALL_STORAGE_CONFIGURATIONS_REQUEST,
  GET_ALL_STORAGE_CONFIGURATIONS_SUCCESS,
  GET_ALL_STORAGE_CONFIGURATIONS_FAILURE,
  GET_ALL_BATTERY_BACKUPS_REQUEST,
  GET_ALL_BATTERY_BACKUPS_SUCCESS,
  GET_ALL_BATTERY_BACKUPS_FAILURE,
  DELETE_MODE_MAPPINGS_REQUEST,
  DELETE_MODE_MAPPINGS_SUCCESS,
  DELETE_MODE_MAPPINGS_FAILURE,
  DELETE_STORAGE_CONFIGURATION_REQUEST,
  DELETE_STORAGE_CONFIGURATION_SUCCESS,
  DELETE_STORAGE_CONFIGURATION_FAILURE
} from '../constants';

export function* getAllModeMappingFlow() {
  while (true) {
    const request = yield take(GET_ALL_MODE_MAPPINGS_REQUEST);
    const { successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/manufacturer_mode_mapping/`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_ALL_MODE_MAPPINGS_SUCCESS,
          modesList: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_ALL_MODE_MAPPINGS_FAILURE, error: error });
    }
  }
}

let createModeMappingSuccess = false;
export function* createModeMappingSuccessFlow() {
  if (!createModeMappingSuccess) {
    while (true) {
      const request = yield take(CREATE_MODE_MAPPING_SUCCESS);
      const response = request.payload.response;
      const { success, status, result, message } = response;
      try {
        createModeMappingSuccess = true;
        if (success && (status === 200 || status === 201)) {
          yield put({
            type: CREATE_MODE_MAPPING_SUCCESS,
            message,
            modeMapping: result,
          });
          createModeMappingSuccess = false;
        } else {
          yield put({ type: CREATE_MODE_MAPPING_FAILURE, message: response.description || response.messages });
          createModeMappingSuccess = false;
        }
      } catch (error) {
        yield put({ type: CREATE_MODE_MAPPING_FAILURE, message: error });
        createModeMappingSuccess = false;
      }
    }
  }
}

let createModeMappingRequest = false;
export function* createModeMappingFlow() {
  while (true) {
    const request = yield take(CREATE_MODE_MAPPING_REQUEST);
    const { requestBody, successCB, failureCB, modeId = 'new' } = request.payload;

    if (!createModeMappingRequest) {
      createModeMappingRequest = true;

      try {
        let response;
        const token = getToken();
        const requestOptions = {
          method: isEqual(modeId, 'new') ? post : putMethod,
          url: isEqual(modeId, 'new')
            ? settings.permittingCoreUrl(`permit/manufacturer_mode_mapping/new`)
            : settings.permittingCoreUrl(`permit/manufacturer_mode_mapping/${modeId}`),
        };

        response = yield call(requestOptions.method, {
          url: requestOptions.url,
          body: requestBody,
          withBearer: true,
        });
        const { success, status, result } = response;
        createModeMappingRequest = false;
        if (success && (status === 200 || status === 201)) {
          if (result.result.id) {
            successCB(result, response);
          }
        } else {
          failureCB(response);
        }
      } catch (error) {
        createModeMappingRequest = false;
        yield put({ type: CREATE_MODE_MAPPING_FAILURE, message: error });
      }
    }
  }
}

export function* getAllStorageConfigurationsFlow() {
  while (true) {
    const request = yield take(GET_ALL_STORAGE_CONFIGURATIONS_REQUEST);
    const {
      after = '',
      before = '',
      equipmentType,
      recordCount = 0,
      sort_column = 'modified_at',
      sort_order,
      model = '',
      manufacturer = '',
      type = '',
      modified_by = '',
      current_status = '',
      verified_by = '',
      from_modified_at = '',
      to_modified_at = '',
      from_verified_at = '',
      to_verified_at = '',
      filter_record = '',
      battery_group_id = '',
      successCb = () => { },
    } = request.payload;

    const desc = isEqual(sort_order, 'asc') ? false : isEqual(sort_order, 'desc') ? true : '';
    const sortColumn = isEqual(sort_column, 'current_status')
      ? 'status'
      : isEqual(sort_column, 'verified_at')
        ? 'verifiedAt'
        : isEqual(sort_column, 'modified_at')
          ? 'updatedAt'
          : sort_column;

    let defaultParams =
      `limit=${recordCount}&manufacturerId=${manufacturer}&sort=${sortColumn}&desc=${desc}&modelName=${model}&after=${after}&before=${before}` +
      `&fromModifiedAt=${from_modified_at}&toModifiedAt=${to_modified_at}&status=${current_status}&modifiedBy=${modified_by}&verifiedBy=${verified_by}` +
      `&fromVerifiedAt=${from_verified_at}&toVerifiedAt=${to_verified_at}&filterRecord=${filter_record}&batteryGroupId=${battery_group_id}`;

    defaultParams += `&type=${type}`;

    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/equipment/${equipmentType}?${defaultParams}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_ALL_STORAGE_CONFIGURATIONS_SUCCESS,
          storageConfigurationList: result['data'],
          pagingData: result['paging']['cursors'],
          totalElements: result['paging']['total'],
          message: '',
        });
        successCb();
      } else {
        yield put({ type: GET_ALL_STORAGE_CONFIGURATIONS_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_ALL_STORAGE_CONFIGURATIONS_FAILURE, message: error });
    }
  }
}

export function* getAllBatteryBackupsFlow() {
  while (true) {
    const request = yield take(GET_ALL_BATTERY_BACKUPS_REQUEST);
    const {
      after = '',
      before = '',
      equipmentType,
      recordCount = 0,
      sort_column = 'modified_at',
      sort_order,
      model = '',
      manufacturer = '',
      type = '',
      modified_by = '',
      current_status = '',
      verified_by = '',
      from_modified_at = '',
      to_modified_at = '',
      from_verified_at = '',
      to_verified_at = '',
      filter_record = '',
      battery_group_id = '',
      successCb = () => { },
    } = request.payload;

    const desc = isEqual(sort_order, 'asc') ? false : isEqual(sort_order, 'desc') ? true : '';
    const sortColumn = isEqual(sort_column, 'current_status')
      ? 'status'
      : isEqual(sort_column, 'verified_at')
        ? 'verifiedAt'
        : isEqual(sort_column, 'modified_at')
          ? 'updatedAt'
          : sort_column;

    let defaultParams =
      `limit=${recordCount}&manufacturerId=${manufacturer}&sort=${sortColumn}&desc=${desc}&modelName=${model}&after=${after}&before=${before}` +
      `&fromModifiedAt=${from_modified_at}&toModifiedAt=${to_modified_at}&status=${current_status}&modifiedBy=${modified_by}&verifiedBy=${verified_by}` +
      `&fromVerifiedAt=${from_verified_at}&toVerifiedAt=${to_verified_at}&filterRecord=${filter_record}&batteryGroupId=${battery_group_id}`;

    defaultParams += `&type=${type}`;

    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/equipment/${equipmentType}?${defaultParams}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_ALL_BATTERY_BACKUPS_SUCCESS,
          batteryBackupList: result['data'],
          pagingData: result['paging']['cursors'],
          totalElements: result['paging']['total'],
          message: '',
        });
        successCb();
      } else {
        yield put({ type: GET_ALL_BATTERY_BACKUPS_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_ALL_BATTERY_BACKUPS_FAILURE, message: error });
    }
  }
}

export function* getModeMappingFlow() {
  while (true) {
    const request = yield take(GET_MODE_MAPPINGS_REQUEST);
    const { modeId, successCB, failureCB } = request.payload;
    try {
      const response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/manufacturer_mode_mapping/${modeId}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_MODE_MAPPINGS_SUCCESS,
          modeMapping: result,
        });
        if (result.id) successCB(result);
      } else {
        yield put({ type: GET_MODE_MAPPINGS_FAILURE, message: result.message || result.messages });
        failureCB();
      }
    } catch (error) {
      yield put({ type: GET_ALL_MODE_MAPPINGS_FAILURE, message: error });
    }
  }
}

export function* deleteModeMappingFlow() {
  yield takeEvery(DELETE_MODE_MAPPINGS_REQUEST, deleteModeMappingFlowRequest);
}

export function* deleteModeMappingFlowRequest(request) {
  const { modeId, successCb } = request.payload;
  try {
    const response = yield call(deleteMethod, {
      url: settings.permittingCoreUrl(`permit/manufacturer_mode_mapping/${modeId}`),
      withBearer: true,
    });
    const { success, status } = response;
    if (success && status === 200) {
      yield put({
        type: DELETE_MODE_MAPPINGS_SUCCESS,
      });
    }
    successCb();
  } catch (error) {
    yield put({ type: DELETE_MODE_MAPPINGS_FAILURE, message: error });
  }
}

export function* deleteStorageConfigurationFlow() {
  yield takeEvery(DELETE_STORAGE_CONFIGURATION_REQUEST, deleteStorageConfigurationFlowRequest);
}

export function* deleteStorageConfigurationFlowRequest(request) {
  const { id, equipmentType, successCb } = request.payload;
  try {
    const response = yield call(deleteMethod, {
      url: settings.permittingCoreUrl(`permit/equipment/${equipmentType}/${id}`),
      withBearer: true,
    });
    const { success, status } = response;
    if (success && status === 200) {
      yield put({
        type: DELETE_STORAGE_CONFIGURATION_SUCCESS,
      });
    }
    successCb();
  } catch (error) {
    yield put({ type: DELETE_STORAGE_CONFIGURATION_FAILURE, message: error });
  }
}