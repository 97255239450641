import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { REQUIREMENT_OPTIONS, SUBMISSION_TRACKING_MODE_OPTIONS } from '../../../../containers/Admin/constants';
import { preventDefault, preventDefaultDecimal } from '../../../../containers/Admin/helper';
import { Label } from '../../../common/Label';
import { MultipleSelectDropdown } from '../../../common/MultipleSelectDropdown';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../common/SingleSelectDropdown';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
  conditionalLongLabel: {
    '@media (max-width: 1280px)': {
      transform: 'translate(0, -20px)',
    }
  },
}));

const PermitSubmissionTrackingSection = (props) => {
  const classes = useStyles();
  const {
    getErrorText,
    getHelperText,
    handleSubmissionTracking,
    permitSubmissionTracking,
    sectionLabel,
    readOnly,
    stateSelected,
    errorData,
  } = props;
  const {
    permit_submission_mode = [],
    copies = '',
    submission_through_solarapp_accepted = '',
    permit_fee_amount = '',
    permit_fee_payment_timeline = '',
    permit_fee_payment_mode = '',
    permit_approval_timelines = '',
    customer_signature_required = '',
    home_owner_auth_required = '',
    ppa_loan_agreement_accepted = '',
    insurance_certificate_required = '',
    completion_certificate_required = '',
    completion_affidavit_required = '',
    ca_sb379_compliance_required = '',
    site_visit_required = '',
    permit_tracking_mode = [],
    permit_tracking_details = '',
    permit_application_submittal_documents = '',
    permit_signature_requirements = '',
    permit_type_requirements = '',
    nem_approval_requirement = '',
    business_license_requirement = '',
    resubmission_procedure = '',
    historical_document_requirements = '',
  } = permitSubmissionTracking;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'Permit submission mode'} capitalizeLabelText={false} />}
                dropDownOptions={SUBMISSION_TRACKING_MODE_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="permit_submission_mode"
                fieldValue={permit_submission_mode ?? []}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id="copies"
                type="number"
                className={classes.inputField}
                label={
                  <Label
                    text={'No. of copies to be submitted (incase to be submitted in person)'}
                    capitalizeLabelText={false}
                    className={classes.conditionalLongLabel}
                  />
                }
                value={copies ?? ''}
                onChange={(e) => handleSubmissionTracking(e, 'copies')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefault(e)}
                error={!isEmpty(get(errorData, 'copies'))}
                helperText={get(errorData, 'copies')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Submission through SolarApp accepted'} capitalizeLabelText={false} />}
                dropDownOptions={REQUIREMENT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="submission_through_solarapp_accepted"
                fieldValue={submission_through_solarapp_accepted ?? ''}
                readOnly={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id="permit_fee_amount"
                type="number"
                className={classes.inputField}
                label={<Label text={'Permit fee amount'} capitalizeLabelText={false} />}
                value={permit_fee_amount ?? ''}
                onChange={(e) => handleSubmissionTracking(e, 'permit_fee_amount')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefaultDecimal(e)}
                error={!isEmpty(get(errorData, 'permit_fee_amount'))}
                helperText={get(errorData, 'permit_fee_amount')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id="permit_fee_payment_timeline"
                className={classes.inputField}
                label={<Label text={'Permit fee payment timeline'} capitalizeLabelText={false} />}
                value={permit_fee_payment_timeline || ''}
                onChange={(e) => handleSubmissionTracking(e, 'permit_fee_payment_timeline')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id="permit_fee_payment_mode"
                className={classes.inputField}
                label={<Label text={'Permit fee payment mode'} capitalizeLabelText={false} />}
                value={permit_fee_payment_mode || ''}
                onChange={(e) => handleSubmissionTracking(e, 'permit_fee_payment_mode')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id="permit_approval_timelines"
                className={classes.inputField}
                label={<Label text={'Permit approval timelines'} capitalizeLabelText={false} />}
                value={permit_approval_timelines || ''}
                onChange={(e) => handleSubmissionTracking(e, 'permit_approval_timelines')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Customer signature required'} capitalizeLabelText={false} />}
                dropDownOptions={REQUIREMENT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="customer_signature_required"
                fieldValue={customer_signature_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Home owner authorization required'} capitalizeLabelText={false} />}
                dropDownOptions={REQUIREMENT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="home_owner_auth_required"
                fieldValue={home_owner_auth_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'PPA loan agreement accepted'} capitalizeLabelText={false} />}
                dropDownOptions={REQUIREMENT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="ppa_loan_agreement_accepted"
                fieldValue={ppa_loan_agreement_accepted ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Certificate of insurance required'} capitalizeLabelText={false} />}
                dropDownOptions={REQUIREMENT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="insurance_certificate_required"
                fieldValue={insurance_certificate_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Certification of completion required'} capitalizeLabelText={false} />}
                dropDownOptions={REQUIREMENT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="completion_certificate_required"
                fieldValue={completion_certificate_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={
                  <Label text={'Certificate of completion affidavit required'} capitalizeLabelText={false} />
                }
                dropDownOptions={REQUIREMENT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="completion_affidavit_required"
                fieldValue={completion_affidavit_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'CA SB-379 compliance required'} capitalizeLabelText={false} />}
                dropDownOptions={REQUIREMENT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="ca_sb379_compliance_required"
                fieldValue={ca_sb379_compliance_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Site visit by electrician required'} capitalizeLabelText={false} />}
                dropDownOptions={REQUIREMENT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="site_visit_required"
                fieldValue={site_visit_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'Permit tracking mode'} capitalizeLabelText={false} />}
                dropDownOptions={SUBMISSION_TRACKING_MODE_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSubmissionTracking}
                fieldId="permit_tracking_mode"
                fieldValue={permit_tracking_mode ?? []}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="permit_tracking_details" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Permit tracking details"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={permit_tracking_details || ''}
                onChange={(e) => handleSubmissionTracking(e, 'permit_tracking_details')}
              />
            </Box>
            <Box id="permit_application_submittal_documents" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Permit application submittal documents"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={permit_application_submittal_documents || ''}
                onChange={(e) => handleSubmissionTracking(e, 'permit_application_submittal_documents')}
              />
            </Box>
            <Box id="permit_signature_requirements" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Permit signature requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={permit_signature_requirements || ''}
                onChange={(e) => handleSubmissionTracking(e, 'permit_signature_requirements')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="permit_type_requirements" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Permit type requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={permit_type_requirements || ''}
                onChange={(e) => handleSubmissionTracking(e, 'permit_type_requirements')}
              />
            </Box>
            <Box id="nem_approval_requirement" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="NEM approval requirement prior to permit submission"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={nem_approval_requirement || ''}
                onChange={(e) => handleSubmissionTracking(e, 'nem_approval_requirement')}
              />
            </Box>
            <Box id="business_license_requirement" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Business license requirement for permit submission"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={business_license_requirement || ''}
                onChange={(e) => handleSubmissionTracking(e, 'business_license_requirement')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="resubmission_procedure" className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Re-submission procedure after revision (different from initial procedure)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={resubmission_procedure || ''}
                onChange={(e) => handleSubmissionTracking(e, 'resubmission_procedure')}
              />
            </Box>
            <Box id="historical_document_requirements" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Historical approval / document requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={historical_document_requirements || ''}
                onChange={(e) => handleSubmissionTracking(e, 'historical_document_requirements')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default PermitSubmissionTrackingSection;
