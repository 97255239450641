import {
  GET_PERMITTING_REQUESTS_REQUEST,
  GET_PERMITTING_SUCCESS,
  GET_PERMITTING_STATISTICS_SUCCESS,
  GET_MSG_HISTORY_REQUEST,
  GET_MSG_HISTORY_SUCCESS,
  GET_PERMITTING_STATISTICS_REQUEST,
  GET_PERMITTING_REQUEST_DETAILS_REQUEST,
  GET_PERMITTING_REQUEST_DETAILS_SUCCESS,
  GET_ANALYTICS_REPORT_REQUEST,
  GET_ANALYTICS_REPORT_FAILURE,
  GET_SOLARGRAF_TOKEN,
  GET_SOLARGRAF_TOKEN_SUCCESS,
  GET_SOLARGRAF_USER,
  GET_SOLARGRAF_USER_SUCCESS,
  CREATE_SOLARGRAF_ACCOUNT,
  CREATE_SOLARGRAF_ACCOUNT_SUCCESS,
  CREATE_SOLARGRAF_ACCOUNT_FAILURE,
  UPDATE_STAGE_STATUS_SUCCESS,
  UPDATE_STAGE_STATUS_REQUEST,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_REQUEST_FAILURE,
  GET_NOTIFICATIONS_FAILURE,
  GET_PERMITTING_REQUESTS_FAILURE,
  GET_PERMITTING_STATISTICS_FAILURE,
  DOWNLOAD_DETAILS_REQUEST,
  DOWNLOAD_DETAILS_SUCCESS,
  DOWNLOAD_ATTACHMENTS_REQUEST,
  DOWNLOAD_ATTACHMENTS_SUCCESS,
  GET_SFDC_DATA_REQUEST,
  GET_SFDC_DATA_SUCCESS,
  GET_SFDC_DATA_FAILURE,
  CREATE_AHJ_REQUEST,
  CREATE_AHJ_SUCCESS,
  CREATE_AHJ_FAILURE,
  GET_AHJ_REQUEST,
  GET_AHJ_SUCCESS,
  GET_AHJ_FAILURE,
  GET_AHJ_CITY_REQUEST,
  GET_AHJ_CITY_SUCCESS,
  GET_AHJ_CITY_FAILURE,
  GET_AHJ_NAME_VALIDATION_REQUEST,
  GET_AHJ_NAME_VALIDATION_SUCCESS,
  GET_AHJ_NAME_VALIDATION_FAILURE,
  GET_ALL_AHJ_REQUEST,
  GET_ALL_AHJ_SUCCESS,
  GET_ALL_AHJ_FAILURE,
  GET_AHJ_GOVERNING_CODES_REQUEST,
  GET_AHJ_GOVERNING_CODES_SUCCESS,
  GET_AHJ_GOVERNING_CODES_FAILURE,
  GET_AHJ_DEFAULT_FIRESETBACKS_REQUEST,
  GET_AHJ_DEFAULT_FIRESETBACKS_SUCCESS,
  GET_AHJ_DEFAULT_FIRESETBACKS_FAILURE,
  GET_AHJ_DEFAULT_ALL_FIRESETBACKS_REQUEST,
  GET_AHJ_DEFAULT_ALL_FIRESETBACKS_SUCCESS,
  GET_AHJ_DEFAULT_ALL_FIRESETBACKS_FAILURE,
  GET_ALL_UTILITY_REQUEST,
  GET_ALL_UTILITY_SUCCESS,
  GET_ALL_UTILITY_FAILURE,
  GET_UTILITY_REQUEST,
  GET_UTILITY_SUCCESS,
  GET_UTILITY_FAILURE,
  CREATE_UTILITY_REQUEST,
  CREATE_UTILITY_SUCCESS,
  CREATE_UTILITY_FAILURE,
  GET_UTILITY_BY_GENABILITY_REQUEST,
  GET_UTILITY_BY_GENABILITY_SUCCESS,
  GET_UTILITY_BY_GENABILITY_FAILURE,
  GET_UTILITY_NAME_VALIDATION_REQUEST,
  GET_UTILITY_NAME_VALIDATION_SUCCESS,
  GET_UTILITY_NAME_VALIDATION_FAILURE,
  GET_AHJ_FILTERS_REQUEST,
  GET_UTILITY_FILTERS_REQUEST,
  GET_REVIEWERS_LIST_REQUEST,
  GET_REVIEWERS_LIST_SUCCESS,
  ASSIGN_REVIEWER_REQUEST,
  ASSIGN_REVIEWER_SUCCESS,
  UPDATE_REVIEWER_REQUEST,
  UPDATE_REVIEWER_SUCCESS,
  SET_APPLICATIONS_FILTERS,
  GET_EDITED_VERSION_DETAILS_REQUEST,
  GET_EDITED_VERSION_DETAILS_SUCCESS,
  GET_EDITED_VERSION_FAILURE,
  GET_EDITED_APPLICATION_DETAILS_SUCCESS,
  GET_EDITED_APPLICATION_DETAILS_REQUEST,
  GET_SG_PROJECT_MAP_REQUEST,
  GET_SG_PROJECT_MAP_SUCCESS,
  GET_SG_PROJECT_MAP_FAILURE,
  SET_SG_PROJECT_ID_REQUEST,
  SET_SG_PROJECT_ID_SUCCESS,
  SET_SG_PROJECT_ID_FAILURE,
  DOWNLOAD_PHOTO_REQUEST,
  DOWNLOAD_PHOTO_SUCCESS,
  GET_SOLARGRAF_COMPANIES_SUCCESS,
  SET_ADMIN_COMPANIES_SUCCESS,
  GET_ADMIN_COMPANIES_SUCCESS,
  GET_SG_APP_ADMINS_SUCCESS,
  GET_SG_APP_ADMINS_FAILURE,
  GET_EDITED_VERSION_DETAILS_FAILURE,
  GET_STATIC_MSP_UM_IMG_URL_REQUEST,
} from './constants';
import { GET_INSTALLER_LIST_SUCCESS } from './RequestDetails/constants';

let initialState = {
  error: '',
  cityDataLoading: false,
  currentlySending: false,
  getPermitRequetsInProgress: false,
  getPermittingStatisticsInProgress: false,
  getNotificationsInProgress: false,
  permitting_requests: [],
  permitting_statistics: [],
  totalElements: 0,
  message: '',
  messageTitle: '',
  messageType: '',
  msg_histories: [],
  permitting_application: {},
  showMessageDialog: false,
  notifications: [],
  accountManagerInfo: {},
  ahj: {},
  ahjCity: [],
  ahjList: [],
  ahjTotalElements: 0,
  getAllAhjInProgress: false,
  utilityList: [],
  getAllUtilityInProgress: false,
  utilityTotalElements: 0,
  utility: {},
  genabilityRequestOn: false,
  utilityByGenability: [],
  ahjFilters: {},
  utilityFilters: {},
  equipment: {},
  equipmentList: [],
  racksList: [],
  standoffsList: [],
  structuralAssemblyList: [],
  manufacturersList: [],
  applicationFilters: {},
  versionsList: [],
  sgUser: {},
  installersList: [],
  companiesList: [],
  selectedCompanies: [],
  sgAppAdmins: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PERMITTING_REQUESTS_REQUEST:
      return { ...state, getPermitRequetsInProgress: true };
    case GET_PERMITTING_STATISTICS_REQUEST:
      return { ...state, getPermittingStatisticsInProgress: true };
    case GET_MSG_HISTORY_REQUEST:
    case GET_PERMITTING_REQUEST_DETAILS_REQUEST:
    case GET_SOLARGRAF_TOKEN:
    case GET_SOLARGRAF_USER:
    case CREATE_SOLARGRAF_ACCOUNT:
    case UPDATE_STAGE_STATUS_REQUEST:
    case SEND_MESSAGE_REQUEST:
    case DOWNLOAD_DETAILS_REQUEST:
    case DOWNLOAD_ATTACHMENTS_REQUEST:
    case DOWNLOAD_PHOTO_REQUEST:
      return { ...state, currentlySending: true };
    case GET_NOTIFICATIONS_REQUEST:
      return { ...state, getNotificationsInProgress: true };
    case GET_REQUEST_FAILURE:
      return { ...state, currentlySending: false };
    case GET_NOTIFICATIONS_FAILURE:
      return { ...state, getNotificationsInProgress: false };
    case GET_PERMITTING_REQUESTS_FAILURE:
      return {
        ...state,
        getSGIPRequetsInProgress: false,
        messageType: 'error',
        message: action.message,
        currentlySending: false,
      };
    case CREATE_SOLARGRAF_ACCOUNT_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.message,
        currentlySending: false,
      };
    case GET_PERMITTING_STATISTICS_FAILURE:
      return { ...state, getPermittingStatisticsInProgress: false, messageType: 'error', message: action.message };
    case GET_PERMITTING_SUCCESS:
      return {
        ...state,
        permitting_requests: action.permitting_requests,
        totalElements: action.totalElements || 0,
        getPermitRequetsInProgress: false,
        messageType: 'success',
        message: action.message,
        currentlySending: false,
      };
    case GET_PERMITTING_STATISTICS_SUCCESS:
      return {
        ...state,
        permitting_statistics: action.permitting_statistics,
        messageType: 'success',
        message: action.message,
        getPermittingStatisticsInProgress: false,
      };
    case GET_MSG_HISTORY_SUCCESS:
      return {
        ...state,
        msg_histories: action.msg_histories,
        messageType: 'success',
        message: action.message,
        currentlySending: false,
      };
    case GET_PERMITTING_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        permitting_application: action.permitting_application,
        messageType: 'success',
        message: action.message,
        currentlySending: false,
        showMessageDialog: false,
      };
    case GET_SOLARGRAF_TOKEN_SUCCESS:
      return {
        ...state,
        solargraf_token: action.solargraf_token,
        messageType: 'success',
        message: action.message,
        currentlySending: false,
        showMessageDialog: false,
      };
    case GET_SOLARGRAF_USER_SUCCESS:
      return {
        ...state,
        sgUser: action.sgUser,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
      };
    case CREATE_SOLARGRAF_ACCOUNT_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        currentlySending: false,
        showMessageDialog: false,
      };
    case UPDATE_STAGE_STATUS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        messageTitle: action.messageTitle,
        currentlySending: false,
        showMessageDialog: action.showMessageDialog,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        messageTitle: action.messageTitle,
        currentlySending: false,
        showMessageDialog: action.showMessageDialog,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        getNotificationsInProgress: false,
        notifications: action.notifications,
      };
    case DOWNLOAD_DETAILS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        currentlySending: false,
        request_details: action.request_details,
      };
    case DOWNLOAD_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        currentlySending: false,
        request_details: action.request_details,
      };
    case GET_SFDC_DATA_REQUEST:
      return {
        ...state,
        currentlySending: true,
      };
    case GET_SFDC_DATA_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        accountManagerInfo: action.accountManagerInfo,
      };
    case GET_SFDC_DATA_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error,
      };
    case CREATE_AHJ_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case CREATE_AHJ_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
        ahj: action.ahj,
      };
    case CREATE_AHJ_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_AHJ_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_AHJ_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        ahj: action.ahj,
      };
    case GET_AHJ_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_AHJ_CITY_REQUEST:
      return {
        ...state,
        cityDataLoading: true,
        messageType: '',
        message: '',
      };
    case GET_AHJ_CITY_SUCCESS:
      return {
        ...state,
        cityDataLoading: false,
        ahjCityCountyDetails: action.ahjCityCountyDetails,
      };
    case GET_AHJ_CITY_FAILURE:
      return {
        ...state,
        cityDataLoading: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_AHJ_NAME_VALIDATION_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_AHJ_NAME_VALIDATION_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        isAHJNameUnique: action.isAHJNameUnique,
      };
    case GET_AHJ_NAME_VALIDATION_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_ALL_AHJ_REQUEST:
      return {
        ...state,
        getAllAhjInProgress: true,
        messageType: '',
        message: '',
      };
    case GET_ALL_AHJ_SUCCESS:
      return {
        ...state,
        ahjList: action.ahjList,
        ahjTotalElements: action.totalElements || 0,
        messageType: 'success',
        message: action.message,
        getAllAhjInProgress: false,
      };
    case GET_ALL_AHJ_FAILURE:
      return {
        ...state,
        getAllAhjInProgress: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_AHJ_GOVERNING_CODES_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_AHJ_GOVERNING_CODES_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        ahjGoverningCodesList: action.ahjGoverningCodesList,
      };
    case GET_AHJ_GOVERNING_CODES_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_AHJ_DEFAULT_FIRESETBACKS_REQUEST:
    case GET_AHJ_DEFAULT_ALL_FIRESETBACKS_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_AHJ_DEFAULT_FIRESETBACKS_SUCCESS:
    case GET_AHJ_DEFAULT_ALL_FIRESETBACKS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case GET_AHJ_DEFAULT_FIRESETBACKS_FAILURE:
    case GET_AHJ_DEFAULT_ALL_FIRESETBACKS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_ALL_UTILITY_REQUEST:
      return {
        ...state,
        getAllUtilityInProgress: true,
        messageType: '',
        message: '',
      };
    case GET_ALL_UTILITY_SUCCESS:
      return {
        ...state,
        utilityList: action.utilityList,
        utilityTotalElements: action.totalElements || 0,
        messageType: 'success',
        message: action.message,
        getAllUtilityInProgress: false,
      };
    case GET_ALL_UTILITY_FAILURE:
      return {
        ...state,
        getAllUtilityInProgress: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_UTILITY_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_UTILITY_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        utility: action.utility,
      };
    case GET_UTILITY_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case CREATE_UTILITY_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case CREATE_UTILITY_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
        utility: action.utility,
      };
    case CREATE_UTILITY_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_ANALYTICS_REPORT_REQUEST:
      return {
        ...state,
        messageType: '',
        message: '',
      };
    case GET_ANALYTICS_REPORT_FAILURE:
    case GET_SG_APP_ADMINS_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.message,
      };
    case GET_UTILITY_BY_GENABILITY_REQUEST:
      return {
        ...state,
        genabilityRequestOn: true,
        messageType: '',
        message: '',
      };
    case GET_UTILITY_BY_GENABILITY_SUCCESS:
      return {
        ...state,
        genabilityRequestOn:
          action.utilityByGenability.pageCount + action.utilityByGenability.pageStart >=
          action.utilityByGenability.count
            ? false
            : true,
        messageType: 'success',
        message: action.message,
        utilityCount: action.utilityByGenability.count,
        utilityByGenability: action.utilityByGenability.results,
      };
    case GET_UTILITY_BY_GENABILITY_FAILURE:
      return {
        ...state,
        genabilityRequestOn: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_UTILITY_NAME_VALIDATION_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_UTILITY_NAME_VALIDATION_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case GET_UTILITY_NAME_VALIDATION_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_AHJ_FILTERS_REQUEST:
      return {
        ...state,
        ahjFilters: action.payload,
      };
    case GET_UTILITY_FILTERS_REQUEST:
      return {
        ...state,
        utilityFilters: action.payload,
      };
    case GET_REVIEWERS_LIST_REQUEST:
    case ASSIGN_REVIEWER_REQUEST:
    case UPDATE_REVIEWER_REQUEST:
      return {
        ...state,
        currentlySending: true,
      };
    case GET_REVIEWERS_LIST_SUCCESS:
      return {
        ...state,
        reviewersList: action.reviewersList,
        currentlySending: false,
      };
    case ASSIGN_REVIEWER_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case SET_APPLICATIONS_FILTERS:
      return {
        ...state,
        applicationFilters: action.payload,
      };
    case GET_INSTALLER_LIST_SUCCESS:
      return {
        ...state,
        installersList: action.installersList,
        currentlySending: false,
      };
    case GET_EDITED_VERSION_DETAILS_SUCCESS:
      return {
        ...state,
        versionsList: action.versionsList,
        currentlySending: false,
      };
    case GET_EDITED_VERSION_DETAILS_FAILURE:
      return {
        ...state,
        versionsList: [],
        currentlySending: false,
      };
    case GET_EDITED_APPLICATION_DETAILS_REQUEST:
      return { ...state, currentlySending: false };
    case GET_EDITED_APPLICATION_DETAILS_SUCCESS:
      return {
        ...state,
        permitting_application: action.permit_application_history,
        messageType: 'success',
        message: action.message,
        currentlySending: false,
        showMessageDialog: false,
      };
    case GET_SG_PROJECT_MAP_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_SG_PROJECT_MAP_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case GET_SG_PROJECT_MAP_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case SET_SG_PROJECT_ID_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case SET_SG_PROJECT_ID_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case SET_SG_PROJECT_ID_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case DOWNLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        currentlySending: false,
        request_details: action.request_details,
      };
    case GET_SOLARGRAF_COMPANIES_SUCCESS:
      return {
        ...state,
        companiesList: action.companies,
      };
    case SET_ADMIN_COMPANIES_SUCCESS:
    case GET_ADMIN_COMPANIES_SUCCESS:
      return {
        ...state,
        selectedCompanies: action.selectedCompanies,
      };
    case GET_SG_APP_ADMINS_SUCCESS:
      return {
        ...state,
        sgAppAdmins: action.sgAppAdmins,
      };
    default:
      return state;
  }
}

export default reducer;
