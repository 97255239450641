import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { DOWNLOAD_DOCUMENTS_FILE_NAMES } from '../../../../utils/constants';
import { getS3URL } from '../../../../containers/Permitting/action';

import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { get, isEmpty, split, startCase } from 'lodash';
import { getSource } from '../../../../containers/Admin/RequestDetails/helper';
import PanelList from '../panelList';
import ProductList from '../productList';
import { SOURCE } from '../../../../utils/constants';
import { handlePopupBlocker } from '../../../../utils/helper';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import Icons from '../images';
import { SOLAR_INSTALLATION_TYPES } from '../../../../containers/Permitting/constants';
import { DisplayGrid } from '../../../common/DisplayGrid';
import { SYSTEM_DETAILS, SYSTEM_RACKING_DETAILS } from '../constants';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  fontBold: {
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    fontSize: '14px',
  },
  dataHeader: {
    color: '#246AB0',
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px !important',
    cursor: 'unset',
  },
  panelDetails: {
    flexDirection: 'column',
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0,
  },
  headingInfo: {
    color: 'darkgray !important',
  },
  dataSubHeader: {
    fontSize: '12px',
    color: 'grey',
  },
  updatedLabelWrapper: {
    pointerEvents: 'none',
    display: 'flex',
    maxWidth: 'initial',
  },
  imageWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  iconHolder: {
    display: 'inline-flex',
    marginRight: theme.spacing(1),
    flexDirection: 'column',
    width: theme.spacing(15),
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0.5, 1),
    },
  },
  imgHolder: {
    zIndex: '1',
    margin: theme.spacing(1),
  },
  imgContainer: {
    border: '1px solid #70707040',
    height: theme.spacing(10),
    width: theme.spacing(15),
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
  },
  downloadIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto',
  },
  iconCoverChat: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    padding: theme.spacing(1),
  },
});
const systemTypes = 'project_details.system_types';
const storageList = 'material_details.storage_list';
export class SystemDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSheetToolTip: false,
      showAttachmentSheetToolTip: false,
      totalEnergyCapacity: 0,
      totalPowerCapacity: 0,
    };
  }

  componentDidMount() {
    this.calculateEnergyAndPower();
  }

  componentDidUpdate(prvProp, preState) {
    const { applicationDetail, stateApplicationDetails } = this.props;
    if (
      getSource(stateApplicationDetails) === SOURCE.SOLARGRAF &&
      (JSON.stringify(get(prvProp.applicationDetail, systemTypes)) !==
        JSON.stringify(get(applicationDetail, systemTypes)) ||
        JSON.stringify(get(prvProp.applicationDetail, storageList)) !==
        JSON.stringify(get(applicationDetail, storageList)))
    ) {
      this.calculateEnergyAndPower();
    }
  }

  calculateEnergyAndPower = () => {
    const { applicationDetail, stateApplicationDetails } = this.props;
    let totalEnergyCapacity = 0;
    let totalPowerCapacity = 0;
    if (getSource(stateApplicationDetails) === SOURCE.SOLARGRAF) {
      const sysTypes = get(applicationDetail, systemTypes);
      if (!isEmpty(sysTypes) && sysTypes.find((data) => data.name === 'Storage')?.value) {
        const batteryList = get(applicationDetail, storageList);
        if (!isEmpty(batteryList)) {
          batteryList.forEach((battery) => {
            totalEnergyCapacity += battery.quantity * battery.capacity;
            totalPowerCapacity += battery.quantity * battery.power_capacity;
          });
          totalEnergyCapacity = totalEnergyCapacity ? Math.round(totalEnergyCapacity * 100) / 100 : 0;
          totalPowerCapacity = totalPowerCapacity ? Math.round(totalPowerCapacity * 100) / 100 : 0;
        }
      }
    }
    this.setState({ totalEnergyCapacity, totalPowerCapacity });
  };

  splitOtherValues(value) {
    let otherValue = !isEmpty(value) && value.startsWith('Other-') ? split(value, '-', 2)[1] : value;
    return otherValue;
  }
  download(file) {
    const { applicationDetail, downloadFile } = this.props;
    if (getSource(applicationDetail) === SOURCE.SOLARGRAF) {
      const myWindow = window.open(file, '_blank');
      handlePopupBlocker(myWindow);
    } else {
      downloadFile(file[0].file_name);
    }
  }
  downloadFileS3 = (fileName, downloadFileName) => {
    this.props.getS3URL({
      fileName: fileName,
      methodType: 'GET',
      downloadFileName: downloadFileName,
      successCb: preSignedS3Url => {
        const myWindow = window.open(preSignedS3Url, '_blank');
        handlePopupBlocker(myWindow);
      },
      failureCb: () => {
        return false;
      }
    });
  };
  fileIcon = (fName) => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
      fType = 'document';
    }
    const Icon = Icons[fType];
    return <Icon />;
  };
  render() {
    const {
      classes,
      accountManagerInfo,
      applicationDetail,
      stateApplicationDetails,
      handleUpdated,
      downloadFile,
      originalApplicationDetails,
    } = this.props;
    const purchased_services = get(applicationDetail, 'purchased_service_names') || [];
    const purchased_rework_services = get(applicationDetail, 'purchased_rework_service_names') || [];
    const allServices = [...purchased_services, ...purchased_rework_services];
    const projectType = get(applicationDetail, 'project_details.project_type', '-');
    const source = getSource(applicationDetail);
    return (
      <Fragment>
        <Grid container direction="row" spacing={2}>
          {SYSTEM_DETAILS.map((Item, index) => {
            return (
              <DisplayGrid
                originalApplicationDetails={originalApplicationDetails}
                handleUpdated={handleUpdated}
                appDetails={applicationDetail}
                stateApplicationDetails={stateApplicationDetails}
                item={Item}
                totalEnergyCapacity={this.state.totalEnergyCapacity}
                totalPowerCapacity={this.state.totalPowerCapacity}
              />
            );
          })}
        </Grid>
        {!isEmpty(applicationDetail.material_details.existing_arrays) && (
          <PanelList
            applicationDetail={applicationDetail}
            arrayList={applicationDetail.material_details.existing_arrays}
            title="Existing Panels and Array Details"
            downloadFile={downloadFile}
            toolTipId={'existing_array'}
            originalApplicationDetails={originalApplicationDetails}
          />
        )}
        {applicationDetail.project_details.system_type_v2 &&
          startCase(applicationDetail.project_details.system_type_v2['name']) != SOLAR_INSTALLATION_TYPES[1] ? ( // startCase is intentionally to equate, SG installation type "newInstallation" with ENLM Permit installation type "New Installation"
          <Typography classes={{ root: classes.heading }}>Existing Permit Package</Typography>
        ) : null}
        {!isEmpty(applicationDetail.project_details.documents) && (
          <Box>
            <Box className={classes.imageWrapper} key={'photoSmall'}>
              {applicationDetail.project_details.documents.map((img, i) => {
                return (
                  <Box className={classes.iconHolder} key={`document-${i}`}>
                    {img.loading && <CircularProgress />}
                    {!img.loading && (
                      <Box className={classes.imgContainer}>
                        <CloudDownloadOutlinedIcon
                          fontSize="small"
                          className={classes.downloadIcon}
                          onClick={() => this.downloadFileS3(img.file_name, DOWNLOAD_DOCUMENTS_FILE_NAMES.EXISTING_PERMIT_PACKAGE)}
                        />
                        <IconButton
                          onClick={() => this.downloadFileS3(img.file_name, DOWNLOAD_DOCUMENTS_FILE_NAMES.EXISTING_PERMIT_PACKAGE)}
                          className={classes.iconCoverChat}
                        >
                          {this.fileIcon(img.file_name)}
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
        {!isEmpty(applicationDetail.material_details.arrays) && (
          <PanelList
            applicationDetail={applicationDetail}
            arrayList={applicationDetail.material_details.arrays}
            title="New Panels and Array Details"
            downloadFile={downloadFile}
            toolTipId={'new_array'}
            originalApplicationDetails={originalApplicationDetails}
          />
        )}
        {!isEmpty(applicationDetail.material_details.inverters) && (
          <ProductList
            applicationDetail={applicationDetail}
            arrayList={applicationDetail.material_details.inverters}
            title="Inverters"
            toolTipId={'inverters'}
            originalApplicationDetails={originalApplicationDetails}
          />
        )}
        {!isEmpty(applicationDetail.material_details.storage_list) && (
          <ProductList
            applicationDetail={applicationDetail}
            arrayList={applicationDetail.material_details.storage_list}
            title="Storage"
            isStorage={true}
            toolTipId={'storage'}
            originalApplicationDetails={originalApplicationDetails}
          />
        )}
        {!isEmpty(applicationDetail.material_details.other_materials) && (
          <ProductList
            applicationDetail={applicationDetail}
            arrayList={applicationDetail.material_details.other_materials}
            title="Other Materials"
            toolTipId={'Other Materials'}
            originalApplicationDetails={originalApplicationDetails}
          />
        )}
        <br /> <br />
        {get(applicationDetail, 'material_details.racking_attachment_properties') ? (
          <Fragment>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={12}>
                <Accordion expanded={true}>
                  <AccordionSummary
                    aria-controls="sDetails-content"
                    id="sDetails"
                    classes={{ root: classes.panelHead }}
                  >
                    <Typography classes={{ root: classes.heading }}>Racking & Attachment Properties</Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: classes.panelDetails }} style={{ flexDirection: 'column' }}>
                    <Grid container direction="row" spacing={2}>
                      {SYSTEM_RACKING_DETAILS.map((Item, index) => {
                        return (
                          <DisplayGrid
                            appDetails={applicationDetail}
                            item={Item}
                            downloadFile={downloadFile}
                            originalApplicationDetails={originalApplicationDetails}
                            handleUpdated={handleUpdated}
                            stateApplicationDetails={stateApplicationDetails}
                          />
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getS3URL: payload => dispatch(getS3URL(payload))
});

const mapStateToProps = (state) => ({
  currentlySending: state.adminReducer.currentlySending,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SystemDetailsView));
