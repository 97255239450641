import {
  GET_PERMITTING_REQUESTS_REQUEST,
  GET_ANALYTICS_REPORT_REQUEST,
  GET_PERMITTING_STATISTICS_REQUEST,
  GET_MSG_HISTORY_REQUEST,
  GET_PERMITTING_REQUEST_DETAILS_REQUEST,
  GET_SOLARGRAF_TOKEN,
  CREATE_SOLARGRAF_ACCOUNT,
  UPDATE_STAGE_STATUS_REQUEST,
  SEND_MESSAGE_REQUEST,
  GET_NOTIFICATIONS_REQUEST,
  GET_PRESIGNED_URL,
  FILE_UPLOAD_TO_S3_REQUEST,
  GET_PDF_FILE_FROM_S3_REQUEST,
  DOWNLOAD_DETAILS_REQUEST,
  DOWNLOAD_ATTACHMENTS_REQUEST,
  GET_SFDC_DATA_REQUEST,
  CREATE_AHJ_REQUEST,
  GET_AHJ_REQUEST,
  GET_AHJ_CITY_REQUEST,
  GET_AHJ_NAME_VALIDATION_REQUEST,
  GET_ALL_AHJ_REQUEST,
  GET_AHJ_GOVERNING_CODES_REQUEST,
  GET_AHJ_DEFAULT_FIRESETBACKS_REQUEST,
  GET_ALL_UTILITY_REQUEST,
  GET_UTILITY_REQUEST,
  CREATE_UTILITY_REQUEST,
  GET_UTILITY_BY_GENABILITY_REQUEST,
  GET_UTILITY_NAME_VALIDATION_REQUEST,
  GET_AHJ_FILTERS_REQUEST,
  GET_UTILITY_FILTERS_REQUEST,
  GET_REVIEWERS_LIST_REQUEST,
  ASSIGN_REVIEWER_REQUEST,
  SET_APPLICATIONS_FILTERS,
  GET_EDITED_VERSION_DETAILS_REQUEST,
  GET_EDITED_APPLICATION_DETAILS_REQUEST,
  GET_SG_PROJECT_MAP_REQUEST,
  SET_SG_PROJECT_ID_REQUEST,
  DOWNLOAD_PHOTO_REQUEST,
  GET_SOLARGRAF_USER,
  GET_SOLARGRAF_COMPANIES_REQUEST,
  SET_ADMIN_COMPANIES_REQUEST,
  GET_ADMIN_COMPANIES_REQUEST,
  GET_SG_APP_ADMINS_REQUEST,
  CLONE_PROJECT_REQUEST,
  UPDATE_REVIEWER_REQUEST,
  GET_STATIC_MSP_UM_IMG_URL_REQUEST,
  GET_AHJ_DEFAULT_ALL_FIRESETBACKS_REQUEST,
} from './constants';

export function getAnalyticsReport(payload) {
  return { type: GET_ANALYTICS_REPORT_REQUEST, payload };
}

export function getPermittingPRequests(payload) {
  return { type: GET_PERMITTING_REQUESTS_REQUEST, payload };
}
export function getPermitStatistics(payload) {
  return { type: GET_PERMITTING_STATISTICS_REQUEST, payload };
}
export function getMessageHistoryForRequest(payload) {
  return { type: GET_MSG_HISTORY_REQUEST, payload };
}
export function getPermittingRequestDetails(payload) {
  return { type: GET_PERMITTING_REQUEST_DETAILS_REQUEST, payload };
}
export function getSolargrafToken(payload) {
  return { type: GET_SOLARGRAF_TOKEN, payload };
}
export function getSolargrafUser(payload) {
  return { type: GET_SOLARGRAF_USER, payload };
}
export function createSolargrafAccount(payload) {
  return { type: CREATE_SOLARGRAF_ACCOUNT, payload };
}
export function getReviewersList(payload) {
  return { type: GET_REVIEWERS_LIST_REQUEST, payload };
}
export function getSgProjectPermitMap(payload) {
  return { type: GET_SG_PROJECT_MAP_REQUEST, payload };
}
export function cloneProject(payload) {
  return { type: CLONE_PROJECT_REQUEST, payload };
}
export function updateSgProjectId(payload) {
  return { type: SET_SG_PROJECT_ID_REQUEST, payload };
}
export function assignReviewer(payload) {
  return { type: ASSIGN_REVIEWER_REQUEST, payload };
}
export function updateStageStatus(payload) {
  return { type: UPDATE_STAGE_STATUS_REQUEST, payload };
}
export function sendMessage(payload) {
  return { type: SEND_MESSAGE_REQUEST, payload };
}
export function getNotifications(payload) {
  return { type: GET_NOTIFICATIONS_REQUEST, payload };
}
export function getPresingedURL(payload) {
  return { type: GET_PRESIGNED_URL, payload };
}
export function uploadToS3(payload) {
  return { type: FILE_UPLOAD_TO_S3_REQUEST, payload };
}
export function getFileFromS3(payload) {
  return { type: GET_PDF_FILE_FROM_S3_REQUEST, payload };
}
export function downloadPermittingRequestDetails(payload) {
  return { type: DOWNLOAD_DETAILS_REQUEST, payload };
}
export function downloadAttachments(payload) {
  return { type: DOWNLOAD_ATTACHMENTS_REQUEST, payload };
}
export function updateStatusReviewer(payload) {
  return { type: UPDATE_REVIEWER_REQUEST, payload };
}
export function getSFDCdata(payload) {
  return { type: GET_SFDC_DATA_REQUEST, payload };
}

export function createAHJ(payload) {
  return { type: CREATE_AHJ_REQUEST, payload };
}

export function getAHJ(payload) {
  return { type: GET_AHJ_REQUEST, payload };
}

export function getAHJCity(payload) {
  return { type: GET_AHJ_CITY_REQUEST, payload };
}

export function getAHJNameValidation(payload) {
  return { type: GET_AHJ_NAME_VALIDATION_REQUEST, payload };
}

export function getAllAHJ(payload) {
  return { type: GET_ALL_AHJ_REQUEST, payload };
}

export function getAHJGoverningCodes(payload) {
  return { type: GET_AHJ_GOVERNING_CODES_REQUEST, payload };
}

export function getAHJDefaultFiresetbacks(payload) {
  return { type: GET_AHJ_DEFAULT_FIRESETBACKS_REQUEST, payload };
}

export function getAHJDefaultAllFiresetbacks(payload) {
  return { type: GET_AHJ_DEFAULT_ALL_FIRESETBACKS_REQUEST, payload };
}
export function getAllUtility(payload) {
  return { type: GET_ALL_UTILITY_REQUEST, payload };
}

export function getUtility(payload) {
  return { type: GET_UTILITY_REQUEST, payload };
}

export function createUtility(payload) {
  return { type: CREATE_UTILITY_REQUEST, payload };
}

export function getUtilityByGenability(payload) {
  return { type: GET_UTILITY_BY_GENABILITY_REQUEST, payload };
}

export function getUtilityNameValidation(payload) {
  return { type: GET_UTILITY_NAME_VALIDATION_REQUEST, payload };
}

export function setAHJFilters(payload) {
  return { type: GET_AHJ_FILTERS_REQUEST, payload };
}

export function setUtilityFilters(payload) {
  return { type: GET_UTILITY_FILTERS_REQUEST, payload };
}

export function saveApplicationFilters(payload) {
  return { type: SET_APPLICATIONS_FILTERS, payload };
}

export function getEditedVersion(payload) {
  return { type: GET_EDITED_VERSION_DETAILS_REQUEST, payload };
}

export function getStaticMspUmImgURL(payload) {
  return { type: GET_STATIC_MSP_UM_IMG_URL_REQUEST, payload };
}

export function getApplicationDetailsByVersion(payload) {
  return { type: GET_EDITED_APPLICATION_DETAILS_REQUEST, payload };
}

export function downloadPermittingRequestPhotos(payload) {
  return { type: DOWNLOAD_PHOTO_REQUEST, payload };
}

export function getSolargrafCompanies(payload) {
  return { type: GET_SOLARGRAF_COMPANIES_REQUEST, payload };
}

export function setAdminCompanies(payload) {
  return { type: SET_ADMIN_COMPANIES_REQUEST, payload };
}

export function getAdminCompanies(payload) {
  return { type: GET_ADMIN_COMPANIES_REQUEST, payload };
}

export function getSgAppAdmins(payload) {
  return { type: GET_SG_APP_ADMINS_REQUEST, payload };
}
