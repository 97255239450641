import React, { Fragment } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Select, withStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateRangePickerComponent } from 'materialui-daterange-picker';
import { DATE_FILTER_LIST } from '../../../../../containers/Admin/constants';

const styles = theme => ({
  dateRangeWrapper: {
    boxShadow: 'none',
    padding: '0px !important'
  },
  okButton: {
    color: '#fff'
  }
});

const DateFilter = props => {
  const {
    classes,
    dateValue,
    dateText,
    dateRange,
    enableButton,
    enableSelectedButton,
    selectedDate,
    selectSpecificDateOpen,
    isDateRangeOpen,
    handleSaveDateRange,
    handleFilterDateChange,
    handleCloseDateRange,
    handleDateRangeChange,
    handleCloseSelectedDate,
    handleSaveSelectedDate,
    handleDateChange,
    toggle
  } = props;

  return (
    <Fragment>
      <Select
        variant="outlined"
        value={dateValue}
        onClick={e => handleFilterDateChange(e)}
        renderValue={value => dateText}
      >
        {DATE_FILTER_LIST.map((filter, index) => (
          <MenuItem key={index} value={filter.key}>
            {filter.text}
          </MenuItem>
        ))}
      </Select>
      <Dialog open={isDateRangeOpen} onClose={handleCloseDateRange}>
        <DialogContent className={classes.dateRangeWrapper}>
          <DateRangePickerComponent
            open={isDateRangeOpen}
            definedRanges={[]}
            toggle={toggle}
            onChange={range => handleDateRangeChange(range)}
            closeOnClickOutside
            initialDateRange={dateRange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!enableButton}
            className={classes.okButton}
            onClick={handleSaveDateRange}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={selectSpecificDateOpen} onClose={handleCloseSelectedDate}>
        <DialogContent className={classes.dateRangeWrapper}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="static"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!enableSelectedButton}
            className={classes.okButton}
            onClick={handleSaveSelectedDate}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withStyles(styles)(DateFilter);
