import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  // Typography,
  Grid,
  Paper
  // ExpansionPanel,
  // ExpansionPanelSummary,
  // ExpansionPanelDetails
  // TextField,
  // Button,
  // Link,
  // CircularProgress,
  // Chip
} from '@material-ui/core';
// import HistoryIcon from '@material-ui/icons/History';
import { FILE_UPLOAD_SIZE_LIMIT } from '../../../containers/Admin/constants';
import { sendMessage, getPresingedURL, uploadToS3 } from '../../../containers/Admin/actions';
import PermitChats from '../../../containers/Permitting/Chats';
import moment from 'moment';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px',
    cursor: 'unset !important'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray !important'
  },
  dataSubHeader: {
    fontSize: '16px',
    color: 'grey'
  },
  fullWidth: {
    width: '100%'
  },
  permitChatWrapper: {
    height: '100%',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: theme.spacing(0.5)
  },
  permitChatContainer: {
    maxHeight: '600px',
    overflow: 'scroll',
    minHeight: '516px',
    height: '100%'
  },
  permitChatPaper: {
    height: '100%'
  }
});

export class SendMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mObj: { mSubject: '', mBody: '' },
      fileUploadStatus: 'idle',
      uploadError: false,
      uploadErrorMsg: '',
      uploadingFileName: ''
    };
  }

  msgContenUpdated(e, type) {
    let updatedMObj = this.state.mObj;
    if (type === 'subject') {
      updatedMObj.mSubject = e.target.value;
      if (this.state.uploadingFileName !== '') {
        let fName = `${this.props.appDetails.permit_id}_${e.target.value
          .replace(' ', '_')
          .toUpperCase()}_${moment().format('DDMMYYYY-hhmmss')}.pdf`;
        this.setState({ uploadingFileName: fName });
      }
    }
    if (type === 'body') {
      updatedMObj.mBody = e.target.value;
    }
    this.setState({ mObj: updatedMObj });
  }

  sendMessageToHO() {
    const { appDetails } = this.props;
    this.props.sendMessage({
      app_id: appDetails.permit_id,
      mObj: this.state.mObj,
      fileName: this.state.uploadingFileName
    });
  }

  handleFileUpload() {
    this.setState({ fileUploadStatus: 'in_progress' });
    var qPDF = document.getElementById('fileUpload-Message').files[0];
    let fTypeArray = qPDF.name.split('.');
    let fType = fTypeArray[fTypeArray.length - 1];

    let fSizeInMb = qPDF.size / 1024 / 1024;
    let file_name = `${this.props.appDetails.permit_id}_${moment().format('DDMMYYYY-hhmmss')}.${fType}`;
    if (fSizeInMb > FILE_UPLOAD_SIZE_LIMIT) {
      this.setState({
        uploadError: true,
        uploadErrorMsg: `Max file size should be ${FILE_UPLOAD_SIZE_LIMIT} Mb`,
        fileUploadStatus: 'idle'
      });
    } else {
      this.props.getPresingedURL({
        file_name: file_name,
        http_method: 'PUT',
        successCb: presignedURL => {
          this.props.uploadToS3({
            preSignedS3Url: presignedURL,
            fName: file_name,
            fileObj: qPDF,
            successCbS3: () => {
              this.setState({ fileUploadStatus: 'success', uploadingFileName: file_name });
            },
            failureCbS3: () => {
              this.setState({
                uploadError: true,
                uploadErrorMsg: 'We are facing some issues with file upload, please try later.',
                fileUploadStatus: 'idle'
              });
            }
          });
        },
        failureCb: () => {
          this.setState({
            uploadError: true,
            uploadErrorMsg: 'We are facing some issues with file upload, please try later.',
            fileUploadStatus: 'idle'
          });
        }
      });
    }
  }

  removeUploadedFile() {
    this.setState({ fileUploadStatus: 'in_progress' });
    this.props.getPresingedURL({
      file_name: this.state.uploadingFileName,
      http_method: 'DELETE',
      successCb: presignedURL => {
        this.setState({ fileUploadStatus: 'idle', uploadingFileName: '' });
      },
      failureCb: () => {
        this.setState({ fileUploadStatus: 'idle', uploadingFileName: '' });
      }
    });
  }

  render() {
    const { classes, appDetails, ...otherProps } = this.props;
    // const { classes, appDetails } = this.props;
    // const { mObj, fileUploadStatus, uploadingFileName, uploadError, uploadErrorMsg } = this.state;
    return (
      <Fragment>
        <Grid container direction="row" className={classes.permitChatWrapper}>
          <Grid item xs={12} className={classes.permitChatContainer}>
            <Paper className={classes.permitChatPaper}>
              <PermitChats isFromAdmin appDetails={appDetails} {...otherProps} />
            </Paper>
            {/* <ExpansionPanel expanded={true}>
              <ExpansionPanelSummary aria-controls="sDetails-content" id="sDetails" className={classes.panelHead}>
                <Typography className={classes.heading}>Send Message to the Applicant</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{ flexDirection: 'column', maxHeight: '516px', overflow: 'scroll', minHeight: '516px' }}
              >
                <TextField
                  required
                  label="Subject"
                  variant="outlined"
                  fullWidth={true}
                  value={mObj.mSubject}
                  onChange={e => this.msgContenUpdated(e, 'subject')}
                />
                <br />
                <TextField
                  required
                  label="Body Text"
                  variant="outlined"
                  multiline
                  rowsMax={10}
                  rows={10}
                  fullWidth={true}
                  value={mObj.mBody}
                  onChange={e => this.msgContenUpdated(e, 'body')}
                />
                <br />
                {fileUploadStatus === 'in_progress' ? (
                  <CircularProgress size={30} className={classes.fabProgress} />
                ) : fileUploadStatus === 'idle' ? (
                  <Fragment>
                    <Typography>Upload File</Typography>
                    <br />
                    <Button
                      variant="outlined"
                      component="label"
                      style={{ fontSize: '10px', textTransform: 'none', width: '26%' }}
                      color="primary"
                      size="small"
                    >
                      Choose File
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        id="fileUpload-Message"
                        onChange={e => this.handleFileUpload()}
                      />
                    </Button>
                    {uploadError && (
                      <Typography variant="caption" style={{ color: 'red' }}>
                        <br />
                        {uploadErrorMsg}
                      </Typography>
                    )}
                  </Fragment>
                ) : (
                  <Chip
                    label={uploadingFileName}
                    onDelete={() => this.removeUploadedFile()}
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ fontSize: '11px', color: 'grey' }}
                  />
                )}
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  size="small"
                  style={{ color: 'white' }}
                  disabled={mObj.mSubject === '' || mObj.mBody === '' || fileUploadStatus === 'in_progress'}
                  onClick={() => this.sendMessageToHO()}
                >
                  Send
                </Button>
                <br />
                <Button
                  aria-label="View Message History"
                  size="small"
                  style={{ fontSize: '10px', textTransform: 'none', background: '#EDEDED' }}
                >
                  <HistoryIcon /> &nbsp;<Link
                    style={{ textTransform: 'none', color: '#3685D1' }}
                    href={`/request/${appDetails.permit_id}/msg_history`}
                  >
                    View Message History
                  </Link>
                </Button>
                <br />
              </ExpansionPanelDetails>
            </ExpansionPanel> */}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  sendMessage: payload => dispatch(sendMessage(payload)),
  getPresingedURL: payload => dispatch(getPresingedURL(payload)),
  uploadToS3: payload => dispatch(uploadToS3(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SendMessage));
