import {
  GET_STRUCTURAL_USERS_REQUEST,
  GET_STRUCTURAL_FILE_DELETE_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_STANDOFF_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_RACK_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_FILTERS_REQUEST,
  GET_ALL_STRUCTURAL_ASSEMBLY_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_REQUEST,
  CREATE_STRUCTURAL_ASSEMBLY_REQUEST,
  CREATE_STRUCTURAL_ASSEMBLY_SUCCESS,
  GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_REQUEST,
  GET_STRUCTURAL_DELETE_REQUEST,
  GET_STRUCTURAL_DELETE_SUCCESS,
  GET_STRUCTURAL_DELETE_FAILURE,
  GET_ASSEMBLY_COM_REQUEST,
} from '../constants';

export function getAllUsers(payload) {
  return { type: GET_STRUCTURAL_USERS_REQUEST, payload };
}

export function getAllRoofTypes(payload) {
  return { type: GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_REQUEST, payload };
}

export function deleteStructuralUploadedFile(payload) {
  return { type: GET_STRUCTURAL_FILE_DELETE_REQUEST, payload };
}

export function getAllRacks(payload) {
  return { type: GET_STRUCTURAL_ASSEMBLY_RACK_REQUEST, payload };
}

export function getAllStandoffs(payload) {
  return { type: GET_STRUCTURAL_ASSEMBLY_STANDOFF_REQUEST, payload };
}

export function setStructuralAssemblyFilters(payload) {
  return { type: GET_STRUCTURAL_ASSEMBLY_FILTERS_REQUEST, payload };
}

export function getAllStructural(payload) {
  return { type: GET_ALL_STRUCTURAL_ASSEMBLY_REQUEST, payload };
}

export function getStructuralCom(payload) {
  return { type: GET_ASSEMBLY_COM_REQUEST, payload };
}

export function getStructural(payload) {
  return { type: GET_STRUCTURAL_ASSEMBLY_REQUEST, payload };
}

export function createStructural(payload) {
  return { type: CREATE_STRUCTURAL_ASSEMBLY_REQUEST, payload };
}

export function createStructuralSuccess(payload) {
  return { type: CREATE_STRUCTURAL_ASSEMBLY_SUCCESS, payload };
}

export function deleteStructural(payload) {
  return { type: GET_STRUCTURAL_DELETE_REQUEST, payload };
}

export function deleteStructuralSuccess(payload) {
  return { type: GET_STRUCTURAL_DELETE_SUCCESS, payload };
}

export function deleteStructuralFailure(payload) {
  return { type: GET_STRUCTURAL_DELETE_FAILURE, payload };
}
