import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { orange, grey, blue } from '@material-ui/core/colors';

let theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto'
  },
  palette: {
    primary: {
      main: '#F3731F',
      dark: '#335977',
      yellow: '#FFE785',
      faded: '#C9C9C9',
      lightBlack: '#5C5C5C',
      dataBlack: '#999999',
      white: '#FFFFFF',
      mainText: '#545456',
      subText: '#939598',
      grey: '#868686DE',
      link: '#008EEF',
      lightGray: '#B9B9B91A',
      chatText: 'rgba(243, 115, 33, 0.05)',
      chatNotes: '#f6f6f6',
      quoteText: 'rgba(243, 115, 33, 0.1)',
      quoteTextBorder: 'rgba(243, 115, 33, 0.5)',
      errorText: '#f44336',
      disabled: 'rgba(0, 0, 0, .12)',
      externalTag: 'rgb(243, 115, 31, 0.3)',
      internalTag: 'rgb(0, 142, 239, 0.3)',
      bodyHeader: '#43425D',
      ...orange
    },
    secondary: {
      main: '#01B4DE',
      link: '#2679f6',
      ...blue
    },
    tertiary: {
      main: '#FFFFFF',
      light: '#FAFAFA',
      dark: '#7E7E7E',
      background: '#F6F7FB',
      lighter: grey[100],
      buttonColor: '#F37321',
      ...grey
    }
  },
  overrides: {
    MuiStepIcon: {
      text: {
        fontWeight: 'bold',
        fill: '#FFFFFF'
      },
      root: {
        '&$active': {
          color: ''
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
