import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.white,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
    fontSize: 15,
    marginTop: theme.spacing(3),
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    padding: 0,
  },
  dialogContentWrapper: {
    border: '1px solid #7070704d',
    margin: theme.spacing(0, 2.5, 2.5),
    padding: theme.spacing(2),
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%',
    },
  },
  bodyHeader: {
    color: '#43425D',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  bodyInput: {
    width: '80%',
    minWidth: theme.spacing(50),
    marginBottom: theme.spacing(1.5),
  },
  textareInput: {
    width: '100%',
    '& div': {
      border: '1px solid rgba(0, 0, 0, 0.42)',
      borderBottom: 'none',
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      paddingTop: theme.spacing(2),
    },
    '& label': {
      paddingLeft: theme.spacing(1),
    },
  },
  input: {
    visibility: 'hidden',
    height: 0,
    width: 0,
  },
  chooseFile: {
    textTransform: 'capitalize',
  },
  imgHolder: {
    display: 'inline-block',
    marginRight: '27px',
    flexDirection: 'column',
    width: '123px',
    wordBreak: 'break-word',
    verticalAlign: 'top',
  },
  imgContainer: {
    border: '1px solid #70707040',
    height: theme.spacing(10),
    width: theme.spacing(15),
    display: 'flex',
    position: 'relative',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    opacity: 0.23,
  },
  image: {
    margin: 'auto',
    display: 'block',
    maxWidth: theme.spacing(15),
    maxHeight: theme.spacing(10),
  },
  comingSoon: {
    width: 'fit-content',
    height: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    textAlign: 'center',
    borderRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0),
    padding: theme.spacing(0, 0.5),
    fontSize: 10,
    fontWeight: 600,
    position: 'absolute',
    top: theme.spacing(0.25),
    left: '100%',
    '&::after': {
      content: '""',
      position: 'absolute',
      right: '100%',
      bottom: 0,
      width: 0,
      height: 0,
      borderTop: '6px solid transparent',
      borderRight: '6px solid #F3731F',
      borderBottom: '0px solid transparent',
    },
  },
  italic: {
    fontStyle: 'italic',
    padding: theme.spacing(0.75, 2),
  },
  info: {
    marginBottom: theme.spacing(1.5),
    color: '#00000099',
  },
  download: {
    width: '100%',
    maxWidth: '400px',
    '& label': {
      fontSize: 14,
    },
  },
  warningText: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  link: {
    color: theme.palette.primary.link,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
    fontSize: 14,
    margin: theme.spacing(1),
    display: 'block',
  },
  reworkReason: {
    display: 'block',
    width: '50%',
    marginBottom: theme.spacing(1),
    minWidth: theme.spacing(35),
  },
  reworkReasonSelect: {
    width: '100%',
  },
  errorText: {
    color: theme.palette.primary.errorText,
  },
}));
