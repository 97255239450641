import clsx from 'clsx';
import React, { useState } from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { preventDefault, getFileMetaData } from '../../../helper';
import history from '../../../../../routes/history';
import { withStyles } from '@material-ui/core/styles';
import SnakBar from '../../../../../components/SnakBar';
import PageHeader from '../../../../../components/common/PageHeader';
import Icons from '../../../../Permitting/Chats/images';
import uploadFileDetails from '../../../../../utils/uploadFileDetails';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Button,
  CircularProgress,
  Backdrop,
  Tooltip,
  Grid,
  TextField,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import CommentDialog from '../../../../../components/Admin/Automation/CommentDialog';
import { isEmpty, get, keysIn, hasIn, omit, isEqual, includes, inRange } from '../../../../../utils/lodash';
import CustomPanel from '../../../../../components/common/PanelTemplateWithMandatory';
import { getSGMaterials } from '../../../../Permitting/action';
import { Label } from '../../../../../components/common/Label';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BatteryConfigurations from '../../../../../components/Admin/Automation/BatteryConfiguration/index';
import {
  getAllManufacturers,
  getAllEquipment,
  createEquipment,
  createEquipmentSuccess,
  getEquipment,
} from '../../../Equipment/actions';
import { getAllBatteryBackups, getAllModeMappings } from '../../actions';
import { tr } from 'date-fns/locale';
import { error } from 'highcharts';
import getConfig from '../../../../../config/env';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backdrop: {
    zIndex: 11,
    color: theme.palette.primary.main,
  },
  btnStyle: { marginLeft: theme.spacing(0.5), color: 'white' },
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      // marginTop: theme.spacing(1),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
    '& .MuiInputBase-root.Mui-disabled:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  addBatteryConfigurationButton: {
    width: '152px',
    marginRight: theme.spacing(3),
    marginLeft: 'auto',
    height: '40px',
    border: '1px solid #F37320',
    borderRadius: '2px',
    opacity: 1,
    color: '#F37320',
    marginBottom: '6px',
    textTransform: 'none',
    background: '#FFFFFF',
    fontWeight: 600,
    '&:hover': { background: '#FFFFFF' },
    minWidth: 'max-content',
    maxWidth: 'max-content',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3, 2),
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1),
  },
  saveButton: {
    '&.Mui-disabled': {
      color: '#FFFFFF',
      backgroundColor: 'rgba(243, 115, 33, 1)',
      opacity: '0.6',
      size: theme.spacing(4),
    },
    width: '88px',
    height: '36px',
  },
  cancelButton: {
    width: '88px',
    height: '36px',
  },
});

class index extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.event = null;
    this.config = getConfig();
    this.state = {
      id: 'new',
      manufacturer: '',
      battery_group: '',
      configuration_name: '',
      batteryBackupAndQuantityList: [{ id: 1, battery_backup: '', quantity: '' }],
      errorData: {
        quantities: {},
      },
      recordSubmitted: false,
      // isNewBatteryConfigurationOpen: false,
      // width: window.innerWidth - (window.innerWidth < 600 ? 56 : 72),
      batteryConfigurations: [],
      manufacturersList: [],
      batteryBackupsList: [],
      batteryGroupsList: [],
      isBatteryOnlyImageToggleSet: false,
      isGridTiedBatteryImageToggleSet: false,
      fileUploadLogs: {
        indoorImagesSolarAndBattery: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
        indoorImagesBatteryOnly: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
        indoorImagesGridTiedBattery: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
        outdoorImagesSolarAndBattery: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
        outdoorImagesBatteryOnly: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
        outdoorImagesGridTiedBattery: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
        withoutBackgroundImagesSolarAndBattery: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
        withoutBackgroundImagesBatteryOnly: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
        withoutBackgroundImagesGridTiedBattery: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
      },
      indoorImages: {
        solarAndBattery: [],
        batteryOnly: [],
        gridTiedBattery: [],
      },
      outdoorImages: {
        solarAndBattery: [],
        batteryOnly: [],
        gridTiedBattery: [],
      },
      withoutBackgroundImages: {
        solarAndBattery: [],
        batteryOnly: [],
        gridTiedBattery: [],
      },
      created_at: '',
      modified_at: '',
      showDiscardChangesPopup: false,
    };
    this.timeout = null;
    this.filterParams = { ...this.filterParams };
  }

  MAX_ALLOWED_FILE_SIZE = 32;

  componentDidMount() {
    const { id } = this.props.match.params;
    const requestSource = 'storageConfiguration';
    if (id !== 'new') {
      this.props.getEquipment({
        id,
        requestSource,
        successCB: (newEquipment) => {
          this.handleGetSuccessCallback(newEquipment);
        },
        failureCB: () => {
          this.handleCancel();
        },
      });
    }
    if (isEmpty(this.state.manufacturersList)) {
      this.props.getAllModeMappings({
        successCb: () => {
          this.setState({ manufacturersList: this.props.modesList });
        },
      });
    }
  }

  componentDidUpdate() {}
  componentWillUnmount() {}

  handleWindowSizeWindow = () => {
    const width = window.innerWidth - (window.innerWidth < 600 ? 56 : 72);
    if (width !== this.state.width) {
      this.setState({ width });
    }
  };

  handleBatteryOnlyImageToggle = (e) => {
    this.setState({ isBatteryOnlyImageToggleSet: e.target.checked });
  };

  handleGridTiedBatteryImageToggle = (e) => {
    this.setState({ isGridTiedBatteryImageToggleSet: e.target.checked });
  };

  handleRemoveBattery = (id) => {
    const updatedbatteryBackupAndQuantityList = this.state.batteryBackupAndQuantityList.filter(
      (batteryBackupAndQuantity) => batteryBackupAndQuantity.id !== id
    );
    const { errorData } = this.state;
    delete errorData['quantities'][id];
    this.setState({ batteryBackupAndQuantityList: updatedbatteryBackupAndQuantityList, errorData: errorData });
  };

  handleAddBatteryButtonClick = () => {
    const newId = this.state.batteryBackupAndQuantityList.length + 1;
    const updatedBatteryBackupAndQuantityList = [
      ...this.state.batteryBackupAndQuantityList,
      { id: newId, battery_backup: '', quantity: '' },
    ];
    this.setState({ ...this.state, batteryBackupAndQuantityList: updatedBatteryBackupAndQuantityList });
  };

  handleDetails = async (e, key, value, id = 0) => {
    const { errorData } = this.state;
    if (isEqual(key, 'manufacturer')) {
      this.setState(
        {
          ...this.state,
          manufacturer: value,
          battery_group: '',
          batteryBackupAndQuantityList: [{ id: 1, battery_backup: '', quantity: '' }],
        },
        () => {
          const manufacturerName = this.state.manufacturer;
          const manufacturerObject = this.state.manufacturersList.filter((val) => val['name'] === manufacturerName);
          const manufacturerId = manufacturerObject.length > 0 ? manufacturerObject[0]['id'] : null;
          this.filterParams = { ...this.filterParams, manufacturer: manufacturerId, recordCount: 0, sort_column: '' };
          this.props.getAllEquipment({
            equipmentType: 'batteryGroups',
            ...this.filterParams,
            successCb: () => {
              this.setState({
                batteryGroupsList: this.props.equipmentList,
              });
            },
          });
        }
      );
    } else if (isEqual(key, 'battery_group')) {
      this.setState(
        {
          ...this.state,
          battery_group: value,
          batteryBackupAndQuantityList: [{ id: 1, battery_backup: '', quantity: '' }],
        },
        () => {
          const batteryGroupName = this.state.battery_group;
          const batteryGroupObject = this.state.batteryGroupsList.filter((val) => val['name'] === batteryGroupName);
          const battery_group_id = batteryGroupObject.length > 0 ? batteryGroupObject[0]['id'] : null;
          this.filterParams = { battery_group_id: battery_group_id, current_status: 'null,APPROVED' };
          this.props.getAllBatteryBackups({
            equipmentType: 'batteries',
            ...this.filterParams,
            successCb: () => {
              this.setState({
                batteryBackupsList: this.props.batteryBackupList,
                isServiceRoleFetched: true,
              });
            },
          });
        }
      );
    } else if (isEqual(key, 'configuration_name')) {
      this.setState({ ...this.state, configuration_name: e.target.value });
    } else {
      const updatedBatteryBackupAndQuantityList = this.state.batteryBackupAndQuantityList.map(
        (batteryBackupAndQuantity) => {
          if (batteryBackupAndQuantity.id === id) {
            if (isEqual(key, 'battery_backup')) {
              if (value) batteryBackupAndQuantity[key] = value;
            } else {
              batteryBackupAndQuantity[key] = e.target.value;
              if (
                batteryBackupAndQuantity[key] <= 0 ||
                batteryBackupAndQuantity[key].indexOf('.') !== -1 ||
                !Number(batteryBackupAndQuantity[key])
              )
                errorData['quantities'][id] = 'Quantity should be an integer value greater than 0';
              else delete errorData['quantities'][id];
            }
          }
          return batteryBackupAndQuantity;
        }
      );
      this.setState({ ...this.state, batteryBackupAndQuantityList: updatedBatteryBackupAndQuantityList, errorData });
    }
  };

  setDocumentUploadError(errorDetails = { error: false, msg: '' }, status, key = '') {
    let { fileUploadLogs } = this.state;
    fileUploadLogs[key].uploadError = {
      error: errorDetails.error,
      msg: errorDetails.msg,
    };
    if (status) {
      fileUploadLogs[key].uploadStatus = status;
    }
    this.setState({ fileUploadLogs: fileUploadLogs });
  }

  getFilePrefixName = (configuration_name) => {
    scrollIntoView(document.getElementById(keysIn(this.state.errorData)[0]));
    let filePrefixName = '';
    if (!isEmpty(configuration_name)) {
      filePrefixName = configuration_name;
    }
    return filePrefixName;
  };
  deleteFile = (key) => {
    // clear the message
    const { indoorImages, outdoorImages, withoutBackgroundImages, fileUploadLogs, errorData } = this.state;
    fileUploadLogs[key].uploadError = { error: false, msg: '' };
    this.setState({ fileUploadLogs: fileUploadLogs });

    if (isEqual(key, 'indoorImagesSolarAndBattery')) indoorImages.solarAndBattery = [];
    else if (isEqual(key, 'indoorImagesBatteryOnly')) indoorImages.batteryOnly = [];
    else if (isEqual(key, 'indoorImagesGridTiedBattery')) indoorImages.gridTiedBattery = [];
    else if (isEqual(key, 'outdoorImagesSolarAndBattery')) outdoorImages.solarAndBattery = [];
    else if (isEqual(key, 'outdoorImagesBatteryOnly')) outdoorImages.batteryOnly = [];
    else if (isEqual(key, 'outdoorImagesGridTiedBattery')) outdoorImages.gridTiedBattery = [];
    else if (isEqual(key, 'withoutBackgroundImagesSolarAndBattery')) withoutBackgroundImages.solarAndBattery = [];
    else if (isEqual(key, 'withoutBackgroundImagesBatteryOnly')) withoutBackgroundImages.batteryOnly = [];
    else withoutBackgroundImages.gridTiedBattery = [];

    this.setState({ indoorImages, outdoorImages, withoutBackgroundImages, errorData });
  };

  handleFileDownload = (key) => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' }, '', key);
    let { id, fileUploadLogs, indoorImages, outdoorImages, withoutBackgroundImages } = this.state;
    if (id === 'new') {
      fileUploadLogs[key].uploadError = {
        error: true,
        msg: 'Unable to preview the file. Please try after some time.',
      };
      this.setState({
        fileUploadLogs: fileUploadLogs,
      });
    } else {
      let selectedImage = isEqual(key, 'indoorImagesSolarAndBattery')
        ? this.state.indoorImages.solarAndBattery
        : isEqual(key, 'indoorImagesBatteryOnly')
        ? this.state.indoorImages.batteryOnly
        : isEqual(key, 'indoorImagesGridTiedBattery')
        ? this.state.indoorImages.gridTiedBattery
        : isEqual(key, 'outdoorImagesSolarAndBattery')
        ? this.state.outdoorImages.solarAndBattery
        : isEqual(key, 'outdoorImagesBatteryOnly')
        ? this.state.outdoorImages.batteryOnly
        : isEqual(key, 'outdoorImagesGridTiedBattery')
        ? this.state.outdoorImages.gridTiedBattery
        : isEqual(key, 'withoutBackgroundImagesSolarAndBattery')
        ? this.state.withoutBackgroundImages.solarAndBattery
        : isEqual(key, 'withoutBackgroundImagesBatteryOnly')
        ? this.state.withoutBackgroundImages.batteryOnly
        : this.state.withoutBackgroundImages.gridTiedBattery;
      if (selectedImage[0]['url']) {
        window.open(selectedImage[0]['url'], '_blank');
      } else {
        fileUploadLogs[key].uploadError = {
          error: true,
          msg: 'Unable to preview the file. Please try after some time.',
        };
        this.setState({
          fileUploadLogs: fileUploadLogs,
        });
      }
    }
  };

  handleFilesUpload = (event, key = '') => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' }, '', key);
    let { fileUploadLogs } = this.state;
    let files = isEqual(key, 'indoorImagesSolarAndBattery')
      ? this.state.indoorImages.solarAndBattery
      : isEqual(key, 'indoorImagesBatteryOnly')
      ? this.state.indoorImages.batteryOnly
      : isEqual(key, 'indoorImagesGridTiedBattery')
      ? this.state.indoorImages.gridTiedBattery
      : isEqual(key, 'outdoorImagesSolarAndBattery')
      ? this.state.outdoorImages.solarAndBattery
      : isEqual(key, 'outdoorImagesBatteryOnly')
      ? this.state.outdoorImages.batteryOnly
      : isEqual(key, 'outdoorImagesGridTiedBattery')
      ? this.state.outdoorImages.gridTiedBattery
      : isEqual(key, 'withoutBackgroundImagesSolarAndBattery')
      ? this.state.withoutBackgroundImages.solarAndBattery
      : isEqual(key, 'withoutBackgroundImagesBatteryOnly')
      ? this.state.withoutBackgroundImages.batteryOnly
      : this.state.withoutBackgroundImages.gridTiedBattery;

    const setStatus = (status) => {
      fileUploadLogs[key].uploadStatus = status;
      this.setState({ fileUploadLogs: fileUploadLogs });
    };

    const setFilesSize = (filesTotalSize) => {
      fileUploadLogs[key].size = filesTotalSize;
      this.setState({ fileUploadLogs: fileUploadLogs });
    };

    const details = {
      event,
      setStatus,
      setFilesSize,
      documentType: key,
      fileLocation: 'solargraf',
      namePrefix: this.getFilePrefixName(this.state.configuration_name),
      uploadFiles: files !== null ? files : [],
      uploadFilesSize: this.state.fileUploadLogs[key].size,
      maxFileUploadSize: this.MAX_ALLOWED_FILE_SIZE,
      setDocumentUploadError: (errorDetails, status) => this.setDocumentUploadError(errorDetails, status, key),
    };

    uploadFileDetails(details, (uploadFiles) => {
      const { indoorImages, outdoorImages, withoutBackgroundImages, errorData } = this.state;
      if (isEqual(key, 'indoorImagesSolarAndBattery')) indoorImages.solarAndBattery = uploadFiles;
      else if (isEqual(key, 'indoorImagesBatteryOnly')) indoorImages.batteryOnly = uploadFiles;
      else if (isEqual(key, 'indoorImagesGridTiedBattery')) indoorImages.gridTiedBattery = uploadFiles;
      else if (isEqual(key, 'outdoorImagesSolarAndBattery')) outdoorImages.solarAndBattery = uploadFiles;
      else if (isEqual(key, 'outdoorImagesBatteryOnly')) outdoorImages.batteryOnly = uploadFiles;
      else if (isEqual(key, 'outdoorImagesGridTiedBattery')) outdoorImages.gridTiedBattery = uploadFiles;
      else if (isEqual(key, 'withoutBackgroundImagesSolarAndBattery'))
        withoutBackgroundImages.solarAndBattery = uploadFiles;
      else if (isEqual(key, 'withoutBackgroundImagesBatteryOnly')) withoutBackgroundImages.batteryOnly = uploadFiles;
      else withoutBackgroundImages.gridTiedBattery = uploadFiles;
      delete errorData[key];
      this.setState({ indoorImages, outdoorImages, withoutBackgroundImages, errorData });
    });
  };

  fileIcon = (fName) => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
      fType = 'document';
    }
    const DocIcon = Icons[fType];
    return <DocIcon />;
  };

  getPayload = () => {
    const {
      id,
      manufacturer,
      battery_group,
      configuration_name,
      batteryBackupAndQuantityList,
      manufacturersList,
      batteryGroupsList,
      batteryBackupsList,
      indoorImages,
      outdoorImages,
      withoutBackgroundImages,
    } = this.state;

    const manufacturerObject = manufacturersList.filter((val) => val['name'] === manufacturer);

    const batteryGroupObject = batteryGroupsList.filter((val) => val['name'] === battery_group);
    const batteryBackupObjectList = batteryBackupAndQuantityList.map((batteryBackupAndQuantity) => {
      const battery_backup = batteryBackupsList.filter(
        (val) => val['name'] === batteryBackupAndQuantity.battery_backup
      );
      return { id: battery_backup[0].id, quantity: batteryBackupAndQuantity.quantity };
    });

    let indoorImagesSolarAndBatteryFileData = null,
      outdoorImagesSolarAndBatteryFileData = null,
      withoutBackgroundImagesSolarAndBatteryFileData = null;
    if (!isEmpty(indoorImages.solarAndBattery)) {
      indoorImagesSolarAndBatteryFileData = indoorImages.solarAndBattery[0]['fileData'];
    }
    if (!isEmpty(outdoorImages.solarAndBattery)) {
      outdoorImagesSolarAndBatteryFileData = outdoorImages.solarAndBattery[0]['fileData'];
    }
    if (!isEmpty(withoutBackgroundImages.solarAndBattery)) {
      withoutBackgroundImagesSolarAndBatteryFileData = withoutBackgroundImages.solarAndBattery[0]['fileData'];
    }

    let indoorImagesBatteryOnlyFileData = null,
      outdoorImagesBatteryOnlyFileData = null,
      withoutBackgroundImagesBatteryOnlyFileData = null,
      indoorImagesBatteryOnlyMetaData = null,
      outdoorImagesBatteryOnlyMetaData = null,
      withoutBackgroundImagesBatteryOnlyMetaData = null;

    if (!isEmpty(indoorImages.batteryOnly)) {
      indoorImagesBatteryOnlyFileData = indoorImages.batteryOnly[0]['fileData'];
      indoorImagesBatteryOnlyMetaData = indoorImages.batteryOnly[0];
      delete indoorImagesBatteryOnlyMetaData['fileData'];
    }
    if (!isEmpty(outdoorImages.batteryOnly)) {
      outdoorImagesBatteryOnlyFileData = outdoorImages.batteryOnly[0]['fileData'];
      outdoorImagesBatteryOnlyMetaData = outdoorImages.batteryOnly[0];
      delete outdoorImagesBatteryOnlyMetaData['fileData'];
    }
    if (!isEmpty(withoutBackgroundImages.batteryOnly)) {
      withoutBackgroundImagesBatteryOnlyFileData = withoutBackgroundImages.batteryOnly[0]['fileData'];
      withoutBackgroundImagesBatteryOnlyMetaData = withoutBackgroundImages.batteryOnly[0];
      delete withoutBackgroundImagesBatteryOnlyMetaData['fileData'];
    }

    let indoorImagesGridTiedBatteryFileData = null,
      outdoorImagesGridTiedBatteryFileData = null,
      withoutBackgroundImagesGridTiedBatteryFileData = null,
      indoorImagesGridTiedBatteryMetaData = null,
      outdoorImagesGridTiedBatteryMetaData = null,
      withoutBackgroundImagesGridTiedBatteryMetaData = null;
    if (!isEmpty(indoorImages.gridTiedBattery)) {
      indoorImagesGridTiedBatteryFileData = indoorImages.gridTiedBattery[0]['fileData'];
      indoorImagesGridTiedBatteryMetaData = indoorImages.gridTiedBattery[0];
      delete indoorImagesGridTiedBatteryMetaData['fileData'];
    }
    if (!isEmpty(outdoorImages.gridTiedBattery)) {
      outdoorImagesGridTiedBatteryFileData = outdoorImages.gridTiedBattery[0]['fileData'];
      outdoorImagesGridTiedBatteryMetaData = outdoorImages.gridTiedBattery[0];
      delete outdoorImagesGridTiedBatteryMetaData['fileData'];
    }
    if (!isEmpty(withoutBackgroundImages.gridTiedBattery)) {
      withoutBackgroundImagesGridTiedBatteryFileData = withoutBackgroundImages.gridTiedBattery[0]['fileData'];
      withoutBackgroundImagesGridTiedBatteryMetaData = withoutBackgroundImages.gridTiedBattery[0];
      delete withoutBackgroundImagesGridTiedBatteryMetaData['fileData'];
    }

    return {
      id,
      requestBody: {
        jsonPayload: {
          requestSource: 'storageConfiguration',
          model: configuration_name,
          Manufacturer: manufacturerObject.length > 0 ? manufacturerObject[0] : null,
          batteryGroupId: batteryGroupObject.length > 0 ? batteryGroupObject[0].id : null,
          batteryBackups: batteryBackupObjectList,
          indoorBatteryOnlyMetadata: indoorImagesBatteryOnlyMetaData,
          outdoorBatteryOnlyMetadata: outdoorImagesBatteryOnlyMetaData,
          withoutBackgroundBatteryOnlyMetadata: withoutBackgroundImagesBatteryOnlyMetaData,
          indoorGridTiedMetadata: indoorImagesGridTiedBatteryMetaData,
          outdoorGridTiedMetadata: outdoorImagesGridTiedBatteryMetaData,
          withoutBackgroundGridTiedMetadata: withoutBackgroundImagesGridTiedBatteryMetaData,
        },
        indoorSolarBattery: indoorImagesSolarAndBatteryFileData,
        outdoorSolarBattery: outdoorImagesSolarAndBatteryFileData,
        withoutBackgroundSolarBattery: withoutBackgroundImagesSolarAndBatteryFileData,
        indoorBatteryOnly: indoorImagesBatteryOnlyFileData,
        outdoorBatteryOnly: outdoorImagesBatteryOnlyFileData,
        withoutBackgroundBatteryOnly: withoutBackgroundImagesBatteryOnlyFileData,
        indoorGridTied: indoorImagesGridTiedBatteryFileData,
        outdoorGridTied: outdoorImagesGridTiedBatteryFileData,
        withoutBackgroundGridTied: withoutBackgroundImagesGridTiedBatteryFileData,
      },
      successCB: (equipment, response = {}) => {
        this.props.createEquipmentSuccess({ response });
        this.handleCreateSuccessCallback();
      },
      failureCB: (response) => {
        this.props.createEquipmentSuccess({ response });
      },
    };
  };

  handleCreateSuccessCallback = () => {
    history.push('/battery-configuration');
  };

  handleGetSuccessCallback = (storageConfiguration, _callback = () => {}) => {
    const {
      id = 'new',
      batteryGroupId,
      batteryBackups,
      indoor_solar_battery_url = '',
      indoor_battery_only_url = '',
      indoor_grid_tied_url = '',
      outdoor_solar_battery_url = '',
      outdoor_battery_only_url = '',
      outdoor_grid_tied_url = '',
      without_background_solar_battery_url = '',
      without_background_battery_only_url = '',
      without_background_grid_tied_url = '',
    } = storageConfiguration;

    let newIndoorImages = {
      solarAndBattery: !isEmpty(indoor_solar_battery_url) ? [{ url: indoor_solar_battery_url }] : [],
      batteryOnly: !isEmpty(indoor_battery_only_url) ? [{ url: indoor_battery_only_url }] : [],
      gridTiedBattery: !isEmpty(indoor_grid_tied_url) ? [{ url: indoor_grid_tied_url }] : [],
    };
    let newOutdoorImages = {
      solarAndBattery: !isEmpty(outdoor_solar_battery_url) ? [{ url: outdoor_solar_battery_url }] : [],
      batteryOnly: !isEmpty(outdoor_battery_only_url) ? [{ url: outdoor_battery_only_url }] : [],
      gridTiedBattery: !isEmpty(outdoor_grid_tied_url) ? [{ url: outdoor_grid_tied_url }] : [],
    };
    let newWithoutBackgroundImages = {
      solarAndBattery: !isEmpty(without_background_solar_battery_url)
        ? [{ url: without_background_solar_battery_url }]
        : [],
      batteryOnly: !isEmpty(without_background_battery_only_url) ? [{ url: without_background_battery_only_url }] : [],
      gridTiedBattery: !isEmpty(without_background_grid_tied_url) ? [{ url: without_background_grid_tied_url }] : [],
    };

    this.filterParams = {
      ...this.filterParams,
      manufacturer: storageConfiguration.Manufacturer.id,
      recordCount: 0,
      sort_column: '',
    };
    this.props.getAllEquipment({
      equipmentType: 'batteryGroups',
      ...this.filterParams,
      successCb: () => {
        this.setState(
          {
            ...this.state,
            batteryGroupsList: this.props.equipmentList,
          },
          () => {
            const selectedBatteryGroupObject = this.state.batteryGroupsList.filter(
              (batteryGroup) => batteryGroup['id'] === batteryGroupId
            );
            this.setState({
              ...this.state,
              battery_group: selectedBatteryGroupObject.length > 0 ? selectedBatteryGroupObject[0]['name'] : '',
            });
          }
        );
      },
    });

    this.filterParams = { battery_group_id: batteryGroupId, current_status: 'null,APPROVED' };
    this.props.getAllBatteryBackups({
      equipmentType: 'batteries',
      ...this.filterParams,
      successCb: () => {
        this.setState(
          {
            batteryBackupsList: this.props.batteryBackupList,
            isServiceRoleFetched: true,
          },
          () => {
            const { batteryBackupsList } = this.state;
            const selectedBatteryBackupAndQuantityList = batteryBackups.map((batterybackup, index) => {
              const battery = batteryBackupsList.filter((battery_backup) => battery_backup.id === batterybackup.id);
              return {
                id: index + 1,
                battery_backup: battery.length > 0 ? battery[0]['name'] : '',
                quantity: String(batterybackup.quantity),
              };
            });
            this.setState({
              ...this.state,
              batteryBackupAndQuantityList: selectedBatteryBackupAndQuantityList,
            });
          }
        );
      },
    });
    let batteryOnlyToggle = false;
    let gridTiedBatteryToggle = false;
    if (!isEmpty(newIndoorImages.solarAndBattery)) {
      getFileMetaData(newIndoorImages.solarAndBattery[0], (meta_data) => {
        newIndoorImages = {
          ...newIndoorImages,
          solarAndBattery: [{ ...meta_data, material_id: this.state.id }],
        };
      });
    }
    if (!isEmpty(newOutdoorImages.solarAndBattery)) {
      getFileMetaData(newOutdoorImages.solarAndBattery[0], (meta_data) => {
        newOutdoorImages = {
          ...newOutdoorImages,
          solarAndBattery: [{ ...meta_data, material_id: this.state.id }],
        };
      });
    }
    if (!isEmpty(newWithoutBackgroundImages.solarAndBattery)) {
      getFileMetaData(newWithoutBackgroundImages.solarAndBattery[0], (meta_data) => {
        newWithoutBackgroundImages = {
          ...newWithoutBackgroundImages,
          solarAndBattery: [{ ...meta_data, material_id: this.state.id }],
        };
      });
    }
    if (!isEmpty(newIndoorImages.batteryOnly)) {
      batteryOnlyToggle = true;
      getFileMetaData(newIndoorImages.batteryOnly[0], (meta_data) => {
        newIndoorImages = {
          ...newIndoorImages,
          batteryOnly: [{ ...meta_data, material_id: this.state.id }],
        };
      });
    }
    if (!isEmpty(newOutdoorImages.batteryOnly)) {
      batteryOnlyToggle = true;
      getFileMetaData(newOutdoorImages.batteryOnly[0], (meta_data) => {
        newOutdoorImages = {
          ...newOutdoorImages,
          batteryOnly: [{ ...meta_data, material_id: this.state.id }],
        };
      });
    }
    if (!isEmpty(newWithoutBackgroundImages.batteryOnly)) {
      batteryOnlyToggle = true;
      getFileMetaData(newWithoutBackgroundImages.batteryOnly[0], (meta_data) => {
        newWithoutBackgroundImages = {
          ...newWithoutBackgroundImages,
          batteryOnly: [{ ...meta_data, material_id: this.state.id }],
        };
      });
    }
    if (!isEmpty(newIndoorImages.gridTiedBattery)) {
      gridTiedBatteryToggle = true;
      getFileMetaData(newIndoorImages.gridTiedBattery[0], (meta_data) => {
        newIndoorImages = {
          ...newIndoorImages,
          gridTiedBattery: [{ ...meta_data, material_id: this.state.id }],
        };
      });
    }
    if (!isEmpty(newOutdoorImages.gridTiedBattery)) {
      gridTiedBatteryToggle = true;
      getFileMetaData(newOutdoorImages.gridTiedBattery[0], (meta_data) => {
        newOutdoorImages = {
          ...newOutdoorImages,
          gridTiedBattery: [{ ...meta_data, material_id: this.state.id }],
        };
      });
    }
    if (!isEmpty(newWithoutBackgroundImages.gridTiedBattery)) {
      gridTiedBatteryToggle = true;
      getFileMetaData(newWithoutBackgroundImages.gridTiedBattery[0], (meta_data) => {
        newWithoutBackgroundImages = {
          ...newWithoutBackgroundImages,
          gridTiedBattery: [{ ...meta_data, material_id: this.state.id }],
        };
      });
    }

    this.setState({
      id,
      configuration_name: storageConfiguration.name,
      manufacturer: storageConfiguration?.Manufacturer?.name ? storageConfiguration['Manufacturer']['name'] : '',
      indoorImages: newIndoorImages,
      outdoorImages: newOutdoorImages,
      withoutBackgroundImages: newWithoutBackgroundImages,
      isBatteryOnlyImageToggleSet: batteryOnlyToggle,
      isGridTiedBatteryImageToggleSet: gridTiedBatteryToggle,
    });
  };

  handleCancel() {
    history.push('/battery-configuration');
  }

  isEditAvailable() {
    const { emailId, permitServiceRole } = this.props;
    return includes(this.config.batteryEnabledUsers, emailId) && includes(['service_admin'], permitServiceRole);
  }

  handleSubmit = () => {
    const payload = this.getPayload();
    return this.props.createEquipment(payload);
  };

  render() {
    const {
      classes,
      message,
      messageType,
      currentlySending,
      getAllEquipmentInProgress,
      getAllBatteryBackupInProgress,
    } = this.props;
    const {
      id,
      manufacturer,
      battery_group,
      configuration_name,
      batteryBackupAndQuantityList,
      manufacturersList,
      batteryBackupsList,
      batteryGroupsList,
      fileUploadLogs,
      errorData,
      indoorImages,
      outdoorImages,
      withoutBackgroundImages,
      showDiscardChangesPopup,
    } = this.state;

    const pageHeaderText = isEqual(id, 'new')
      ? 'Add New Battery Configuration'
      : configuration_name || 'Battery Configuration';

    return (
      <Box className={classes.root}>
        <Dialog
          open={showDiscardChangesPopup}
          onClose={() => this.setState({ ...this.state, showDiscardChangesPopup: false })}
        >
          <DialogTitle className={classes.dialogHeader} disableTypography>
            <Typography variant="h6">Warning</Typography>
            <CloseIcon
              fontSize="small"
              className={classes.close}
              onClick={() => this.setState({ ...this.state, showDiscardChangesPopup: false })}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to discard your changes and exit this page?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCancel()} color="primary">
              Ok
            </Button>
            <Button onClick={() => this.setState({ ...this.state, showDiscardChangesPopup: false })} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Box className={classes.actionButtons}>
          <PageHeader text={pageHeaderText} />
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={() => this.setState({ ...this.state, showDiscardChangesPopup: true })}
              variant="outlined"
              color="default"
              className={classes.cancelButton}
            >
              {'Discard'}
            </Button>
            {this.isEditAvailable() && (
              <Button
                variant="contained"
                color="primary"
                className={classes.saveButton}
                disabled={
                  isEmpty(manufacturer) ||
                  isEmpty(battery_group) ||
                  isEmpty(configuration_name) ||
                  batteryBackupAndQuantityList.some(item => isEmpty(item.battery_backup) || isEmpty(item.quantity)) ||
                  !isEmpty(get(errorData, 'quantities')) ||
                  isEmpty(indoorImages.solarAndBattery[0]) ||
                  !isEmpty(get(errorData, 'indoorImagesSolarAndBattery')) ||
                  isEmpty(outdoorImages.solarAndBattery[0]) ||
                  !isEmpty(get(errorData, 'outdoorImagesSolarAndBattery')) ||
                  isEmpty(withoutBackgroundImages.solarAndBattery[0]) ||
                  !isEmpty(get(errorData, 'withoutBackgroundImagesSolarAndBattery'))
                }
                onClick={() => this.handleSubmit()}
              >
                {'Save'}
              </Button>
            )}
          </DialogActions>
        </Box>
        <BatteryConfigurations
          id={id}
          edit={!isEqual(id, 'new')}
          manufacturer={manufacturer}
          battery_group={battery_group}
          configuration_name={configuration_name}
          batteryBackupAndQuantityList={batteryBackupAndQuantityList}
          manufacturersList={manufacturersList}
          batteryGroupsList={batteryGroupsList}
          batteryBackupsList={batteryBackupsList}
          handleDetails={this.handleDetails}
          handleAddBatteryButtonClick={this.handleAddBatteryButtonClick}
          handleRemoveBattery={this.handleRemoveBattery}
          handleIndoorImageToggleChange={this.handleIndoorImageToggleChange}
          isBatteryOnlyImageToggleSet={this.state.isBatteryOnlyImageToggleSet}
          handleBatteryOnlyImageToggle={this.handleBatteryOnlyImageToggle}
          isGridTiedBatteryImageToggleSet={this.state.isGridTiedBatteryImageToggleSet}
          handleGridTiedBatteryImageToggle={this.handleGridTiedBatteryImageToggle}
          indoorImages={indoorImages}
          outdoorImages={outdoorImages}
          withoutBackgroundImages={withoutBackgroundImages}
          fileIcon={this.fileIcon}
          fileUploadLogs={fileUploadLogs}
          deleteFile={this.deleteFile}
          errorData={errorData}
          handleFilesUpload={this.handleFilesUpload}
          handleSubmit={this.handleSubmit}
          handleFileDownload={this.handleFileDownload}
        />
        {(currentlySending || getAllEquipmentInProgress || getAllBatteryBackupInProgress) && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {message && messageType === 'success' && <SnakBar message={message} severity={messageType} />}
        {message && messageType === 'error' && <SnakBar message={message} severity={messageType} />}
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  // getSGMaterials: (payload) => dispatch(getSGMaterials(payload)),
  getAllManufacturers: (payload) => dispatch(getAllManufacturers(payload)),
  getAllEquipment: (payload) => dispatch(getAllEquipment(payload)),
  getAllBatteryBackups: (payload) => dispatch(getAllBatteryBackups(payload)),
  createEquipment: (payload) => dispatch(createEquipment(payload)),
  createEquipmentSuccess: (payload) => dispatch(createEquipmentSuccess(payload)),
  getAllModeMappings: (payload) => dispatch(getAllModeMappings(payload)),
  getEquipment: (payload) => dispatch(getEquipment(payload)),
});

const mapStateToProps = (state) => ({
  // sgMaterials: state.permitReducer.sgMaterials,
  modesList: state.batteryConfigurationReducer.modesList,
  batteryConfigurations: state.batteryConfigurationReducer.batteryConfigurations,
  manufacturersList: state.equipmentReducer.manufacturersList,
  equipmentList: state.equipmentReducer.equipmentList,
  getAllEquipmentInProgress: state.equipmentReducer.getAllEquipmentInProgress,
  batteryBackupList: state.batteryConfigurationReducer.batteryBackupList,
  getAllBatteryBackupInProgress: state.batteryConfigurationReducer.getAllBatteryBackupInProgress,
  batteryBackupTotalElements: state.batteryConfigurationReducer.batteryBackupTotalElements,
  equipment: state.equipmentReducer.equipment,
  emailId: state.appReducer.emailId,
  permitServiceRole: state.appReducer.permitServiceRole,
  currentlySending: state.equipmentReducer.currentlySending,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
