import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import { FIRESETBACKS_ALERT } from '../../../../containers/Admin/constants';
import { get, isEmpty } from '../../../../utils/lodash';
import HelpTextIcon from '../../../common/HelpTextIcon';
import { Label } from '../../../common/Label';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';
import { TooltipGoverningCodes, TooltipOtherCodes } from './Tooltip';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  alert: {
    justifyContent: 'center',
    width: 'auto',
    marginTop: theme.spacing(1)
  },
  govCodeTextInfoWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  firesetbackTooltipHeading: {
    fontWeight: 'bold'
  },
  firesetbackTooltipWrapper: {
    padding: theme.spacing(1),
    width: theme.spacing(61, '!important'),
    backgroundColor: theme.palette.tertiary.lighter
  },
  checkboxLabel: {
    margin: theme.spacing(4, 0, -1, -0.3)
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  },
}));

const HTMLFiresetbackValue = props => {
  const classes = useStyles();
  const { code = [] } = props;
  return code && code[0] ? (
    <Box className={classes.firesetbackTooltipWrapper}>
      <Typography className={classes.firesetbackTooltipHeading}>Default firesetback values:</Typography>
      <Typography>Max array span (ft): {getValue(code[0].max_array_span)}</Typography>
      <Typography>Ground mount perimeter (ft): {getValue(code[0].ground_mount_perimeter)}</Typography>
      <Typography>FSB - service drops (in): {getValue(code[0].service_drops)}</Typography>
      <Typography>FSB - egress (in): {getValue(code[0].egress)}</Typography>
      <Typography>FSB - obstruction (in): {getValue(code[0].obstruction)}</Typography>
      <Typography>FSB - ridge MP side (in): {getValue(code[0].ridge_mp)}</Typography>
      <Typography>
        Ridge (both sides) home with no sprinklers (in) [PV ≤ 33% Roof]: {getValue(code[0].ridge_both_sides_lpv)}
      </Typography>
      <Typography>
        {'Ridge (both sides) home with no sprinklers (in) [PV > 33% Roof]: '} {getValue(code[0].ridge_both_sides_hpv)}
      </Typography>
      <Typography>
        Ridge (both sides) home with sprinklers (in) [PV ≤ 66% Roof]: {getValue(code[0].ridge_home_with_sprinklers)}
      </Typography>
      <Typography>
        {'Ridge (both sides) home with sprinklers (in) [PV > 66% Roof]: '}
        {getValue(code[0].ridge_home_with_sprinklers_high)}
      </Typography>
      <Typography>Gable (in): {getValue(code[0].gable)}</Typography>
      <Typography>Eave (in): {getValue(code[0].eave)}</Typography>
      <Typography>Rake (in): {getValue(code[0].rake)}</Typography>
      <Typography>Flat roof perimeter (in): {getValue(code[0].flat_roof)}</Typography>
      <Typography>Path - between adjacent arrays (in): {getValue(code[0].path_adjacent_array)}</Typography>
      <Typography>1 Path - ridge-to-eave (in): {getValue(code[0].path_ridge_to_eave)}</Typography>
      <Typography>
        2 Paths - ridge-to-eave on single ridge home (in): {getValue(code[0].paths_on_single_ridge_home)}
      </Typography>
      <Typography>
        2 Paths - ridge-to-eave on street/driveway side and ridge-to-eave on separate roof plane (in):{' '}
        {getValue(code[0].paths_on_separate_roof_plane)}
      </Typography>
      <Typography>
        18 inch FSBs on each side if adjacent MPs sharing a hip/valley (flush if no adjacent MP):{' '}
        {getValue(code[0].fsb_each_side) === 'true' ? 'Yes' : 'No'}
      </Typography>
    </Box>
  ) : (
    <Typography>No fire setbacks value defined</Typography>
  );
};

const getGoverningCodeFullName = code => {
  if (code) {
    return code.short_name ? '[' + code.short_name + '] ' + code.long_name : code.long_name;
  } else {
    return '';
  }
};

const getValue = value => {
  return !value || value === '' ? 'NA' : value;
};

const GoverningCodesSection = props => {
  const classes = useStyles();
  const {
    errorData,
    handleGoverningCodes,
    handleGetGoverningCodes,
    state,
    stateparams,
    governingCodes,
    ahjGoverningCodesList,
    noRequirementsCheck,
    sectionLabel,
    readOnly,
    stateSelected,
    handleDisplayOtherCodesCheckbox,
    handleDisplayNECCheckbox,
    checkBoxOtherCodes,
    checkBoxNEC,
    isNEC
  } = props;

  const { nec, ibc, ifc, irc, other_codes = [] } = governingCodes;

  const [showIRCWarning, setShowIRCWarning] = useState(false);
  const [showIFCWarning, setShowIFCWarning] = useState(false);
  const [firesetbacksAlert, setFiresetbacksAlert] = useState('');

  useEffect(
    () => {
      const { firesetbacks_defined: ircSetbacksDefined } = irc;
      const { firesetbacks_defined: ifcSetbacksDefined } = ifc;

      setShowIRCWarning(!ircSetbacksDefined);
      ircSetbacksDefined && setShowIFCWarning(isEmpty(irc.firesetbacks) && !ifcSetbacksDefined);
    },
    [irc, ifc]
  );

  useEffect(
    () => {
      if (showIRCWarning) {
        setFiresetbacksAlert(FIRESETBACKS_ALERT.replace('CODES', irc.long_name));
      } else if (showIFCWarning) {
        setFiresetbacksAlert(FIRESETBACKS_ALERT.replace('CODES', ifc.long_name));
      }
    },
    [irc, ifc, showIRCWarning, showIFCWarning]
  );

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        {isEmpty(state) && (
          <Alert severity="warning" className={classes.alert}>
            State selection is required under basic details section
          </Alert>
        )}
        {/* {(showIRCWarning || showIFCWarning) && (
          <Alert severity="error" className={classes.alert}>
            {firesetbacksAlert}
          </Alert>
        )} */}
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                className={classes.inputField}
                id="nec"
                disableClearable={true}
                getOptionLabel={option => (typeof option === 'string' ? option : getGoverningCodeFullName(option))}
                getOptionSelected={(option, value) => getGoverningCodeFullName(option) === value}
                options={ahjGoverningCodesList['nec']}
                value={nec}
                disabled={!stateSelected || readOnly}
                noOptionsText={isEmpty(state) ? 'Please select the State' : 'Please wait'}
                onOpen={e => handleGetGoverningCodes(e, 'nec')}
                onChange={(e, value) => handleGoverningCodes(e, 'nec', value)}
                renderInput={params => (
                  nec.long_name === '' ? (
                    <TextField
                      {...params}
                      error={!isEmpty(get(errorData, 'nec'))}
                      helperText={get(errorData, 'nec')}
                      label={<Label text={'Electrical code'} isRequired={!noRequirementsCheck} capitalizeLabelText={false} />}
                    />
                  ) : (
                    <Tooltip title={getGoverningCodeFullName(nec)} position="top" arrow>
                    <TextField
                      {...params}
                      error={!isEmpty(get(errorData, 'nec'))}
                      helperText={get(errorData, 'nec')}
                      label={<Label text={'Electrical code'} isRequired={!noRequirementsCheck} capitalizeLabelText={false} />}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (<Fragment />),
                      }}
                    />
                    </Tooltip>
                  )
                )}
              />
              <Autocomplete
                className={classes.inputField}
                id="irc"
                disableClearable={true}
                getOptionLabel={option => (typeof option === 'string' ? option : getGoverningCodeFullName(option))}
                getOptionSelected={(option, value) => getGoverningCodeFullName(option) === value}
                options={ahjGoverningCodesList['irc']}
                value={irc}
                disabled={!stateSelected || readOnly}
                noOptionsText={isEmpty(state) ? 'Please select the State' : 'Please wait'}
                onOpen={e => handleGetGoverningCodes(e, 'irc')}
                onChange={(e, value) => handleGoverningCodes(e, 'irc', value)}
                renderInput={params =>
                  irc.long_name === '' ? (
                    <TextField
                      {...params}
                      error={!isEmpty(get(errorData, 'irc'))}
                      helperText={get(errorData, 'irc')}
                      label={<Label text={'Residential code'} capitalizeLabelText={false} />}
                    />                   
                  ) : (
                    <Tooltip title={getGoverningCodeFullName(irc)} arrow placement="top">
                    <TextField
                      {...params}
                      error={!isEmpty(get(errorData, 'irc'))}
                      helperText={get(errorData, 'irc') || get(irc, 'associated_code') ? "Associated code :" + get(irc, 'associated_code') : ""}
                      label={<Label text={'Residential code'} capitalizeLabelText={false} />}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <Fragment>
                            <InputAdornment position="start">
                              <HelpTextIcon
                                message={<HTMLFiresetbackValue code={stateparams.ircFireSetBacks} />}
                                position="top"
                                disabled
                              />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                    </Tooltip>
                  )}
              />
              <FormControlLabel
                className={classes.checkboxLabel}
                control={<Checkbox className={classes.inputCheckbox} />}
                label="Display other codes on permit plan set"
                disabled={!stateSelected || readOnly}
                checked={checkBoxOtherCodes}
                value={checkBoxOtherCodes}
                onChange={e => handleDisplayOtherCodesCheckbox(e, 'displayOtherCodesCheck')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              {nec.national_electrical_code === '' ?
                (<TextField
                  className={classes.inputField}
                  id="national_electrical_code"
                  label={<Label text={'National electrical code'} isRequired={!noRequirementsCheck} capitalizeLabelText={false} />}
                  value={nec.national_electrical_code}
                  disabled
                />
                ) : (
                  <Tooltip title={nec.national_electrical_code} arrow>
                  <TextField
                    className={classes.inputField}
                    id="national_electrical_code"
                    label={<Label text={'National electrical code'} isRequired={!noRequirementsCheck} capitalizeLabelText={false} />}
                    value={nec.national_electrical_code}
                    disabled
                    InputProps={{
                      startAdornment: (<Fragment />),
                    }}
                  />
                  </Tooltip>
                )
              }
              <Autocomplete
                className={classes.inputField}
                id="ifc"
                disableClearable={true}
                getOptionLabel={option => (typeof option === 'string' ? option : getGoverningCodeFullName(option))}
                getOptionSelected={(option, value) => getGoverningCodeFullName(option) === value}
                options={ahjGoverningCodesList['ifc']}
                value={ifc}
                disabled={!stateSelected || readOnly}
                noOptionsText={isEmpty(state) ? 'Please select the State' : 'Please wait'}
                onOpen={e => handleGetGoverningCodes(e, 'ifc')}
                onChange={(e, value) => handleGoverningCodes(e, 'ifc', value)}
                renderInput={params =>
                  ifc.long_name === '' ? (
                    <TextField
                      {...params}
                      error={!isEmpty(get(errorData, 'ifc'))}
                      helperText={get(errorData, 'ifc')}
                      label={<Label text={'Fire code'} capitalizeLabelText={false} />}
                    />
                  ) : (
                    <Tooltip title={getGoverningCodeFullName(ifc)} arrow placement="top">
                    <TextField
                      {...params}
                      error={!isEmpty(get(errorData, 'ifc'))}
                      helperText={get(errorData, 'ifc') || get(ifc, 'associated_code') ? "Associated code :" + get(ifc, 'associated_code') : ""}
                      label={<Label text={'Fire code'} capitalizeLabelText={false} />}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <Fragment>
                            <InputAdornment position="start">
                              <HelpTextIcon message={<HTMLFiresetbackValue code={stateparams.ifcFireSetBacks} />} position="top" />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                    </Tooltip>
                  )}
              />
              <FormControlLabel
                className={classes.checkboxLabel}
                control={<Checkbox className={classes.inputCheckbox} />}
                label="Display national electrical code field on permit plan set along with electrical code"
                disabled={!stateSelected || readOnly || isNEC}
                checked={checkBoxNEC}
                value={checkBoxNEC}
                onChange={e => handleDisplayNECCheckbox(e, 'displayNationalElectricalCodeCheck')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                className={classes.inputField}
                id="ibc"
                disableClearable={true}
                getOptionLabel={option => (typeof option === 'string' ? option : getGoverningCodeFullName(option))}
                getOptionSelected={(option, value) => getGoverningCodeFullName(option) === value}
                options={ahjGoverningCodesList['ibc']}
                value={ibc}
                disabled={!stateSelected || readOnly}
                noOptionsText={isEmpty(state) ? 'Please select the State' : 'Please wait'}
                onOpen={e => handleGetGoverningCodes(e, 'ibc')}
                onChange={(e, value) => handleGoverningCodes(e, 'ibc', value)}
                renderInput={params =>
                  ibc.long_name === '' ? (
                    <TextField
                      {...params}
                      error={!isEmpty(get(errorData, 'ibc'))}
                      helperText={get(errorData, 'ibc')}
                      label={<Label text={'Building code'} capitalizeLabelText={false} />}
                    />
                  ) : (
                    <Tooltip title={getGoverningCodeFullName(ibc)} arrow>
                    <TextField
                      {...params}
                      error={!isEmpty(get(errorData, 'ibc'))}
                      helperText={get(errorData, 'ibc') || get(ibc, 'associated_code') ? "Associated code :" + get(ibc, 'associated_code') : ""}
                      label={<Label text={'Building code'} capitalizeLabelText={false} />}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (<Fragment />),
                      }}
                    />
                    </Tooltip>
                  )}
              />
              <Autocomplete
                className={classes.inputField}
                multiple
                options={ahjGoverningCodesList['other_codes']}
                getOptionLabel={option => (typeof option === 'string' ? option : getGoverningCodeFullName(option))}
                getOptionSelected={(option, value) => getGoverningCodeFullName(option) === value}
                value={other_codes}
                disabled={readOnly || !stateSelected}
                noOptionsText={isEmpty(state) ? 'Please select the State' : 'Type something for suggestions'}
                onChange={(e, value) => handleGoverningCodes(e, 'other_codes', value)}
                renderInput={params =>
                  <TextField
                    {...params}
                    label={<Label text={'Other codes'} capitalizeLabelText={false} />}
                  />
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) =>
                    <TooltipOtherCodes
                      getTagProps={getTagProps}
                      index={index}
                      title={getGoverningCodeFullName(option)}
                      label={getGoverningCodeFullName(option)}
                    />
                  )
                }
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default GoverningCodesSection;
