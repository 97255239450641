import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import PageHeader from '../../common/PageHeader';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { updateFeedback } from '../../../containers/Permitting/action';
import { get, cloneDeep } from 'lodash';
const useStyles = makeStyles(theme => ({
  rateButton: {
    borderRadius: 0,
    height: theme.spacing(4.25),
    width: theme.spacing(4.25),
    marginRight: theme.spacing(2),
    outlineWidth: 0,
    backgroundColor: 'white',
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3.25),
      width: theme.spacing(3.25),
      marginRight: theme.spacing(1.5)
    }
  },
  displayFlex: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  displayButtonFlex: {
    display: 'flex',
    flexDirection: 'column'
  },
  poorText: {
    paddingLeft: theme.spacing(0.5),
    color: '#EF212C',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.2)
    }
  },
  greatText: {
    paddingLeft: theme.spacing(0.5),
    color: '#3C9C46',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.2)
    }
  },
  backgroundRed: {
    backgroundColor: '#E43E3D',
    borderColor: '#E43E3D !important'
  },
  backgroundOrange: {
    backgroundColor: '#EF874C',
    borderColor: '#EF874C !important'
  },
  backgroundYellow: {
    backgroundColor: '#F8C43C',
    borderColor: '#F8C43C !important'
  },
  backgroundGreen: {
    backgroundColor: '#5BAF2A',
    borderColor: '#5BAF2A !important'
  },
  borderRed: {
    border: '1px solid #E43E3D'
  },
  borderOrange: {
    border: '1px solid #EF874C'
  },
  borderYellow: {
    border: '1px solid #F8C43C'
  },
  borderGreen: {
    border: '1px solid #5BAF2A'
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buttonWrapper: {
    justifyContent: 'start',
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  button: {
    color: theme.palette.primary.white,
    height: theme.spacing(5),
    borderRadius: 0,
    width: theme.spacing(14.5)
  },
  fontSize: {
    fontSize: theme.spacing(3)
  },
  contentColor: {
    color: '#4B4B4B',
    fontSize: theme.spacing(2)
  },
  cancelButton: {
    color: '#919191'
  }
}));
function AlertDialog(props) {
  const { content, isOpen, updateFeedback, permitId, applicationDetail, isFrom = '', closeDialog = () => {} } = props;
  const classes = useStyles();
  const [ open, setOpen ] = useState(true);
  const [ rating, setRating ] = useState(0);
  const [ feedback, setFeedback ] = useState('');
  const [ clicked, setClicked ] = useState(false);
  const [ submitDisabled, setSubmitDisabled ] = useState(true);
  const [ feedbackSubmitted, setFeedbackSubmitted ] = useState(false);
  let applicationState = get(applicationDetail, 'third_party_current_status.state_id', 0);

  const map = [
    {
      colourClass: 'backgroundRed',
      borderClass: 'borderRed',
      text: 'Poor',
      classNames: ''
    },
    {
      colourClass: 'backgroundRed',
      borderClass: 'borderRed',
      text: '',
      classNames: ''
    },
    {
      colourClass: 'backgroundRed',
      borderClass: 'borderRed',
      text: '',
      classNames: ''
    },
    {
      colourClass: 'backgroundOrange',
      borderClass: 'borderOrange',
      text: '',
      classNames: ''
    },
    {
      colourClass: 'backgroundOrange',
      borderClass: 'borderOrange',
      text: '',
      classNames: ''
    },
    {
      colourClass: 'backgroundOrange',
      borderClass: 'borderOrange',
      text: '',
      classNames: ''
    },
    {
      colourClass: 'backgroundOrange',
      borderClass: 'borderOrange',
      text: '',
      classNames: ''
    },
    {
      colourClass: 'backgroundYellow',
      borderClass: 'borderYellow',
      text: '',
      classNames: ''
    },
    {
      colourClass: 'backgroundYellow',
      borderClass: 'borderYellow',
      text: '',
      classNames: ''
    },
    {
      colourClass: 'backgroundGreen',
      borderClass: 'borderGreen',
      text: '',
      classNames: ''
    },
    {
      colourClass: 'backgroundGreen',
      borderClass: 'borderGreen',
      text: 'Great',
      classNames: ''
    }
  ];
  const [ ratingArray, setRatingArray ] = useState(map);

  const handleClose = () => {
    if (isFrom === 'Payment Success') {
      props.resetData();
      props.handleReset();
      setOpen(false);
    }
    closeDialog();
  };

  const handleMouseOver = e => {
    if (!clicked) {
      const idx = e.target.value;
      let array = cloneDeep(ratingArray);
      array[idx].classNames = classes[array[idx].colourClass];
      setRatingArray([ ...array ]);
    }
  };
  const handleMouseLeave = e => {
    if (!clicked) {
      const idx = e.target.value;
      let array = cloneDeep(ratingArray);
      array[idx].classNames = '';
      setRatingArray([ ...array ]);
    }
  };

  const feedbackChange = e => {
    setFeedback(e.target.value);
  };

  const handleSubmit = () => {
    updateFeedback({ rating, feedback, id: permitId, applicationState });
    setFeedbackSubmitted(true);
    if (isFrom === 'Payment Success') {
      setOpen(false);
    }
    closeDialog();
    if (isFrom === 'Permit Status') {
      props.removeShareFeedbackButton();
    }
  };

  const handleFeedbackSubmittedClose = () => {
    setFeedbackSubmitted(false);
    if (isFrom === 'Payment Success') {
      props.resetData();
      props.handleReset();
    }
  };

  const buttonClick = e => {
    setClicked(true);
    setRating(e.target.value);
    setSubmitDisabled(false);

    const idx = e.target.value;
    let array = cloneDeep(ratingArray);
    for (let ind in array) {
      array[ind].classNames = '';
    }
    for (let ind = 0; ind <= idx; ind++) {
      array[ind].classNames = classes[array[idx].colourClass];
    }
    setRatingArray([ ...array ]);
  };

  return (
    <div>
      <Dialog open={open && isOpen} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogHeader} disableTypography>
          <PageHeader className={classes.fontSize} text={'Feedback'} />
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.contentColor}>{content} </DialogContentText>
          <div className={classes.displayFlex}>
            {ratingArray.map((ele, idx) => {
              return (
                <div key={idx} className={classes.displayButtonFlex}>
                  <button
                    id={idx}
                    onMouseOver={e => handleMouseOver(e)}
                    onMouseLeave={e => handleMouseLeave(e)}
                    className={clsx(classes.rateButton, classes[ele.borderClass], ele.classNames)}
                    value={idx}
                    onClick={e => buttonClick(e)}
                  >
                    {idx}
                  </button>
                  <Typography className={idx === 0 ? classes.poorText : classes.greatText}>{ele.text}</Typography>
                </div>
              );
            })}
          </div>

          <DialogContentText className={classes.contentColor}>
            If you would like to share specific comments please enter them below
          </DialogContentText>

          <TextField
            value={feedback}
            onChange={e => feedbackChange(e)}
            multiline
            rows={4}
            rowsMax={16}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions className={classes.buttonWrapper}>
          <Button
            disabled={submitDisabled}
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disableElevation
            className={classes.button}
          >
            Submit
          </Button>
          <Button onClick={handleClose} className={classes.cancelButton}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {feedbackSubmitted && (
        <div>
          <Dialog open={feedbackSubmitted} onClose={handleFeedbackSubmittedClose} fullWidth maxWidth="xs">
            <DialogTitle className={classes.dialogHeader} disableTypography>
              <PageHeader className={classes.fontSize} text={'Feedback Submitted!'} />
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleFeedbackSubmittedClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.contentColor}>Thanks for your feedback.</DialogContentText>
            </DialogContent>
            <DialogActions className={classes.buttonWrapper}>
              <Button
                onClick={handleFeedbackSubmittedClose}
                color="primary"
                variant="contained"
                disableElevation
                className={classes.button}
              >
                Done
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = state => ({
  permitId: state.permitReducer.permitId,
  applicationDetail: state.permitReducer.applicationDetail
});
const mapDispatchToProps = dispatch => ({
  updateFeedback: payload => dispatch(updateFeedback(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
