import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Box, Typography, TextField, Grid, Link, FormHelperText } from '@material-ui/core';
import PanelTemplate from '../../common/PanelTemplate';
import { Label, useLabelStyles } from '../../common/Label';
import { clone, get, isEmpty } from '../../../utils/lodash';
import { connect } from 'react-redux';
import {
  PROJECT_TYPES,
  PV_TO_ESS_LINK,
  FEATURE_FLAG_TYPE,
  FEATURE_FLAGS,
} from '../../../containers/Permitting/constants';
import Array from '../Array';
import ConfirmationDialog from '../ConfirmationDialog';
import { getPanelManufacturerList } from '../../../containers/Permitting/action';
import { isPermitSetAvailable } from '../../../utils/helper';
import { STANDING_STEAM_ROOF_TYPES } from '../Array/constants';
import { isFeatureEnabled } from '../../../containers/Permitting/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2.5),
  },
  panelWrapper: {
    padding: theme.spacing(2),
  },
  addNewArray: {
    fontSize: 14,
    color: '#008EEF',
    display: 'inline-block',
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
  },
  solarSizeLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
    textTransform: 'none',
  },
  otherInfo: {
    width: '45%',
    minWidth: theme.spacing(30),
    margin: theme.spacing(0, 1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 'auto',
    },
  },
  greyBackgroundText: {
    background: '#f1f1f1',
    fontWeight: 'bold',
    maxWidth: '288px',
    padding: '5px',
    marginBottom: theme.spacing(2.5),
  },
  deleteArray: {
    display: 'flex',
  },
  deleteArrayText: {
    opacity: 0.9,
    fontSize: 14,
    marginRight: theme.spacing(1),
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    '&:first-child>div': {
      marginTop: 0,
    },
  },
  link: {
    color: theme.palette.primary.link,
    cursor: 'pointer',
  },
}));

const Arrays = (props) => {
  const {
    arrayList,
    onAddArray,
    onRemoveArray,
    userSelectionData,
    handleArrayChange,
    errors,
    isPermitSetAvailable,
    otherUserSelectionData,
    panelManufactureList,
    mostUsedValues,
    permitId,
    getS3URL,
    putToS3,
    isExistingArray,
    applicationDetail,
    featureList,
    isInputAutocompleteEnabled,
  } = props;
  const {
    array_installation_type: otherInstallationOptions = [],
    array_roof_type: otherRoofOptions = [],
    array_panel_manufacturer: otherPanelManufacturerOptions = [],
  } = otherUserSelectionData;
  const modifiedPanelManufactureList = [...panelManufactureList, { id: 'other', name: 'Other' }];
  const {
    structural_type: structuralType = [],
    structural_type_standing_seam: structuralTypeStandingSeam = [],
    structural_number: structuralNumber = [],
    installation_type: installationOptions = [],
    roof_type: roofOptions = [],
  } = userSelectionData;

  const classes = useStyles();
  const labelClass = useLabelStyles();
  const [tempFeet, setTempFeet] = useState({});
  const [changedRows, setChangedRows] = useState([]);
  const [deleteArrayIndex, setDeleteArrayIndex] = useState(null);
  const getPanelModel = (label, index) => {
    let result = panelManufactureList.find((obj) => obj.name === label);
    if (result) {
      props.getPanelManufacturerList({
        id: result.id,
        successCbk: (panelModelList) => {
          const data = clone(arrayList);
          data[index]['panelModelList'] = panelModelList;
          handleArrayChange(data, 'panelModelList', index, true, true);
        },
      });
    } else {
      const data = clone(arrayList);
      data[index]['panelModelList'] = [];
      handleArrayChange(data, 'panelModelList', index, true, true);
    }
  };

  const handleChange = (value, key, index, skipValidation = false, autoPopulated = false) => {
    if (['azimuth', 'pitch_deg', 'quantity'].includes(key)) {
      changedRows.push(index);
      setChangedRows(changedRows);
    }
    const data = clone(arrayList);
    data[index][key] = value;
    handleArrayChange(data, key, index, skipValidation, autoPopulated);
  };
  const preventDefault = (event, key) => {
    if (event.which !== 8 && event.which !== 0 && (event.which < 48 || event.which > 57)) {
      event.preventDefault();
    }
  };
  const fireUpload = (file, index, key, tag = '') => {
    if (file) {
      const data = clone(arrayList);
      const docs = clone(data[index][key] || []);
      const length = docs.length;
      const date = Date.now();
      const extension = file.name.match(/((\.[a-z]+)|(\.[A-Z]+))$/g)[0];
      const name = `${permitId}_${date}${extension}`;
      const currentVersion = get(applicationDetail, 'version.current_version', 'NEW');
      docs.push({ loading: true, original_file_name: '', tag });
      handleChange(docs, key, index);
      getS3URL({
        fileName: name,
        methodType: 'PUT',
        successCb: (preSignedS3Url) => {
          putToS3({
            fileObj: file,
            preSignedS3Url: preSignedS3Url,
            fName: name,
            successCbS3: () => {
              docs.splice(length, 1);
              docs.push({
                file_name: name,
                original_file_name: file.name,
                tag,
                file_size: file.size / 1024 / 1024,
                versions: [currentVersion],
              });
              handleChange(docs, key, index);
            },
            failureCbS3: () => {
              docs.splice(length, 1);
              handleChange(docs, key, index);
            },
          });
        },
        failureCb: () => {},
      });
    }
  };
  const inchesOptions = () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  return (
    <Box>
      {arrayList.map((array, index) => {
        return (
          <Array
            array={array}
            index={index}
            errors={errors}
            inchesOptions={inchesOptions}
            preventDefault={preventDefault}
            handleChange={handleChange}
            tempFeet={tempFeet}
            setTempFeet={setTempFeet}
            setDeleteArrayIndex={setDeleteArrayIndex}
            changedRows={changedRows}
            isPermitSetAvailable={isPermitSetAvailable}
            labelClass={labelClass}
            structuralType={structuralType}
            structuralTypeStandingSeam={structuralTypeStandingSeam}
            roofOptions={roofOptions}
            structuralNumber={structuralNumber}
            installationOptions={installationOptions}
            otherInstallationOptions={otherInstallationOptions}
            otherRoofOptions={otherRoofOptions}
            modifiedPanelManufactureList={
              isInputAutocompleteEnabled ? [...panelManufactureList] : modifiedPanelManufactureList
            }
            otherPanelManufacturerOptions={otherPanelManufacturerOptions}
            mostUsedValues={mostUsedValues}
            getPanelModel={getPanelModel}
            fireUpload={fireUpload}
            isExistingArray={isExistingArray}
            isInputAutocompleteEnabled={isInputAutocompleteEnabled}
          />
        );
      })}
      {deleteArrayIndex !== null && (
        <ConfirmationDialog
          open={deleteArrayIndex !== null}
          handleClose={() => setDeleteArrayIndex(null)}
          handleProceed={() => {
            onRemoveArray(deleteArrayIndex);
            setDeleteArrayIndex(null);
          }}
          withTitle={false}
          withButtons={true}
          withLink={false}
          dialogText={`Do you want to remove Array #${deleteArrayIndex + 1} details ?`}
        />
      )}
      <Typography component="div" onClick={() => onAddArray(arrayList)} className={classes.addNewArray}>
        + Add New Array
      </Typography>
    </Box>
  );
};

function PanelArrays(props) {
  const {
    materialDetails,
    userSelectionData,
    handleChange,
    handleProjectDetailsChange,
    applicationDetail,
    showExistingPanelArray,
    isExistingArray,
    projectDetails,
    paymentStatus,
    featureList,
  } = props;
  const permitFilesAvailable = isPermitSetAvailable(applicationDetail);
  const arrayKey = isExistingArray ? 'existing_arrays' : 'arrays';
  const { arrays = [], existing_arrays = [], errors = {}, panels_array_other_information = '' } = materialDetails;

  const { solar_system_size = '' } = projectDetails;
  const systemTypeId = get(projectDetails, 'system_type_v2.id');
  const projectType = get(projectDetails, 'project_type', '');

  useEffect(() => {
    if (Number(systemTypeId) === 3 && existing_arrays && existing_arrays.length === 0) {
      const data = [arrayDetails()];
      handleChange(data, 'existing_arrays', { isArrayAdd: true }, true, true);
    } else if (Number(systemTypeId) !== 3) {
      handleChange([], 'existing_arrays', { isArrayRemove: true }, true, true);
    }
  }, [systemTypeId]);

  useEffect(() => {
    setSolarSize();
  }, [arrays, existing_arrays]);

  const classes = useStyles();
  const arrayDetails = (
    quantity = '',
    pitch_deg = '',
    azimuth = '',
    structural_number = '',
    other_structural_number = '',
    structural_type = '',
    structural_at = '',
    max_rafter_span = '',
    panel_manufacturer = '',
    panel_model = '',
    panel_wattage = '',
    installation_type = '',
    roof_type = '',
    roof_pitch = ''
  ) => {
    return {
      quantity: quantity,
      pitch_deg: pitch_deg,
      azimuth: azimuth,
      max_rafter_span,
      structural_number: structural_number,
      other_structural_number: other_structural_number,
      structural_type: structural_type,
      structural_at: structural_at,
      panel_manufacturer,
      panel_model,
      panel_wattage,
      installation_type,
      roof_type,
      roof_pitch,
    };
  };

  const handleAddArray = (arrayList) => {
    const lastIndex = arrayList.length - 1;
    const data = [
      ...(isExistingArray ? existing_arrays : arrays),
      arrayDetails(
        arrayList[lastIndex]['quantity'],
        arrayList[lastIndex]['pitch_deg'],
        arrayList[lastIndex]['azimuth'],
        arrayList[lastIndex]['structural_number'],
        arrayList[lastIndex]['other_structural_number'],
        arrayList[lastIndex]['structural_type'],
        arrayList[lastIndex]['structural_at'],
        arrayList[lastIndex]['max_rafter_span'],
        arrayList[lastIndex]['panel_manufacturer'],
        arrayList[lastIndex]['panel_model'],
        arrayList[lastIndex]['panel_wattage'],
        arrayList[lastIndex]['installation_type'],
        arrayList[lastIndex]['roof_type'],
        arrayList[lastIndex]['roof_pitch']
      ),
    ];
    handleChange(data, arrayKey, { isArrayAdd: true });
  };

  const handleRemoveArray = (index) => {
    const data = clone(isExistingArray ? existing_arrays : arrays);
    data.splice(index, 1);
    handleChange(data, arrayKey, { isArrayRemove: true, arrayIndex: index });
  };

  const handleArrayChange = (arrays, key, arrayIndex, skipValidation, autoPopulated) => {
    handleChange(arrays, arrayKey, { additionalKey: key, arrayIndex }, skipValidation, autoPopulated);
  };

  const setSolarSize = () => {
    let solarSize = 0;
    arrays &&
      arrays.map((array) => {
        if (array.quantity && !isEmpty(array.panel_model)) {
          solarSize +=
            parseInt(array.quantity ? array.quantity : 0) * parseInt(array.panel_wattage ? array.panel_wattage : 0);
        }
      });
    existing_arrays &&
      existing_arrays.map((array) => {
        if (array.quantity && !isEmpty(array.panel_model)) {
          solarSize +=
            parseInt(array.quantity ? array.quantity : 0) * parseInt(array.panel_wattage ? array.panel_wattage : 0);
        }
      });
    solarSize = solarSize / 1000;
    handleProjectDetailsChange('solar_system_size', solarSize, true);
  };

  const panelArrayClass = clsx(
    classes.panelWrapper,
    ((paymentStatus && projectType === PROJECT_TYPES.COMMERCIAL) || permitFilesAvailable) && classes.disableForm
  );
  const header = isExistingArray ? 'Panels & Array' : showExistingPanelArray ? '' : 'Panels & Array';

  return (
    <div className={classes.root}>
      <PanelTemplate header={header} className={classes.panelContainer}>
        <Box className={panelArrayClass}>
          {showExistingPanelArray && (
            <Typography className={classes.greyBackgroundText}>
              {isExistingArray ? '1. Existing Panels Details' : '2. New Panels Details'}
            </Typography>
          )}
          <Arrays
            arrayList={isExistingArray ? existing_arrays : arrays}
            userSelectionData={userSelectionData}
            onAddArray={handleAddArray}
            onRemoveArray={handleRemoveArray}
            handleArrayChange={handleArrayChange}
            errors={errors}
            isPermitSetAvailable={permitFilesAvailable}
            isExistingArray={isExistingArray}
            applicationDetail={applicationDetail}
            {...props}
          />
          {!isExistingArray && (
            <Fragment>
              <Grid className={classes.inputWrapper}>
                <TextField
                  disabled
                  id="solar_system_size"
                  className={classes.otherInfo}
                  label={<Label className={classes.solarSizeLabel} text="Solar System Size (kWdc)" isRequired />}
                  value={solar_system_size || ''}
                  error={get(errors, 'solar_system_size.error')}
                  helperText={
                    get(errors, 'solar_system_size.error') ? (
                      <FormHelperText>
                        <span>{get(errors, 'solar_system_size.errorMessage')} Please email to </span>
                        <Link className={classes.link} href="mailto:designandpermit@enphaseenergy.com">
                          designandpermit@enphaseenergy.com
                        </Link>
                        <span> for further assistance and we will respond to you with the quotation.</span>
                      </FormHelperText>
                    ) : (
                      ''
                    )
                  }
                />
                <TextField
                  onChange={(e) => handleChange(e.target.value, 'panels_array_other_information')}
                  className={classes.otherInfo}
                  label={<Label className={classes.labelColor} text="Notes/ Other Information" />}
                  value={panels_array_other_information || ''}
                />
              </Grid>
            </Fragment>
          )}
        </Box>
      </PanelTemplate>
    </div>
  );
}

const mapStateToProps = (state) => ({
  otherUserSelectionData: state.permitReducer.otherUserSelectionData,
  mostUsedValues: state.permitReducer.mostUsedValues,
});

const mapDispatchToProps = (dispatch) => ({
  getPanelManufacturerList: (payload) => dispatch(getPanelManufacturerList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelArrays);
