import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
  customLabel: {
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
        alignItems: 'center',
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
        alignItems: 'center',
      },
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottom: '1px solid rgb(0, 0, 0, 0.25)',
    },
  },
  label: {
    display: 'flex',
    fontWeight: 500,
    color: '#43425D',
    fontSize: 14,
  },
  labelCapitalize: {
    textTransform: 'capitalize',
  },
  important: {
    color: 'red',
    marginTop: '-3px',
  },
});

export const useLabelStyles = makeStyles(styles);

export const Label = (props) => {
  const classes = useLabelStyles();
  const { isRequired = false, children, text, className = '', capitalizeLabelText = true } = props;
  const labelClassName = className
    ? capitalizeLabelText
      ? clsx(classes.label, className, classes.labelCapitalize)
      : clsx(classes.label, className)
    : capitalizeLabelText
    ? clsx(classes.label, classes.labelCapitalize)
    : clsx(classes.label);
  return (
    <Typography component="label" className={labelClassName}>
      {text}
      {children && children}
      {isRequired && (
        <Typography className={classes.important} component="span">
          *
        </Typography>
      )}
    </Typography>
  );
};

export const labelStyles = styles;
