import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  TextField,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  useMediaQuery,
  InputAdornment,
  Slide,
  Snackbar,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import Fade from '@material-ui/core/Fade';
import { localeMoment as moment } from '../../../components/common/moment';
import { isEmpty, find, get, isEqual } from '../../../utils/lodash';
import { handlePopupBlocker, isAdmin, isInstaller, isSolargrafApplication } from '../../../utils/helper';
import ChatSvg from '../../../components/Permitting/images/ChatSvg';
import Icons from './images';
import { Reply } from '@material-ui/icons';
import { useStyles } from './styles';
import scrollIntoView from 'scroll-into-view';
import { MESSAGE_TYPES, INSTALLER_STATES } from '../../../utils/constants';

const FILE_UPLOAD_SIZE_LIMIT = 100;
let files = [];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ScrollDialog(props) {
  const [messageBody, setMessageBody] = useState('');
  const [messageSendFailed, setMessageSendFailed] = useState(false);
  const {
    messages = [],
    currentUserEmail = '',
    currentUserName = '',
    sendMessage,
    appId,
    fetchAllChats,
    getPresingedURL,
    uploadToS3,
    handleClose,
    updateChatStatus,
    unreadMessageCount,
    isFromAdmin = false,
    isFromApplicationStatus = false,
    appDetails,
    permitServiceRole,
    setChatAutomatedMessagesDisabled,
    isChatAutomatedMessagesDisabled,
    messageAcknowledgedBy
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const totalUnreadMessages = appDetails.unread_messages_count + appDetails.admin_unread_messages_count;

  const [fileUploadStatus, setFileUploadStatus] = useState('idle');
  const [uploadingFileName, setUploadingFileName] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState('');
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const [chatInfoEnabled, setChatInfoEnabled] = useState(false);
  const [sendMessageCount, setSendMessageCount] = useState(0);
  const [totalMessageCount, setTotalMessageCount] = useState(-1);
  const [quoteMessage, setQuoteMessage] = useState(null);
  const [displayChatPopups, setDisplayChatPopups] = useState(false);
  const [isCheckBoxClicked, setIsCheckBoxClicked] = useState(get(appDetails, 'admin_unread_messages_count', 0) === 0
    || get(appDetails, 'admin_unread_messages_count', 0) === null);
  const [adminMessage, setAdminMessage] = useState(false);
  const [adminMessageSender, setAdminMessageSender] = useState('');
  const [isMessageAcknowledged, setIsMessageAcknowledged] = useState(totalUnreadMessages === 0 && messages.length > 0);

  const moreInfoRequestPopup = `This project is currently on hold as we are waiting for more information from you. 
  Please help to provide the requested information using “Provide more Information” button under Permit Status details section`;

  const permitSetAvailablePopup = `Permit Plan Set is now available. You can download it from “Download Permit Files“ option under “Actions” tab 
  For any corrections, Please raise the rework request using “Submit Request for Correction“ option under “Actions” tab
  For ordering stamps, Please use “Order Stamps“ option under “Actions” tab. 
  If you have already ordered stamps, Please approve by selecting “Send Permit Package for Stamping“ option under “Actions” tab`;

  const reworkSetAvailablePopup = `Rework Plan Set is now available. You can download it from “Download Permit Files“ option under “Actions” tab
  For any more corrections, Please raise the rework request using “Submit Request for Correction“ option under “Actions” tab
  For ordering stamps, Please use “Order Stamps“ option under “Actions” tab. 
  If you have already ordered stamps, Please approve by selecting “Send Permit Package for Stamping“ option under “Actions” tab`;

  const scrollToBottom = () => {
    if (document.getElementById('messageUnorderedList')) {
      document.getElementById('messageUnorderedList').scrollTop = document.getElementById(
        'messageUnorderedList'
      ).scrollHeight;
    }
  };

  const handleChatPopupDisabled = () => {
    if (isChatAutomatedMessagesDisabled) {
      setDisplayChatPopups(false);
      return true;
    }
    else if (messages.length > 0) {
      let lastStateUpdatedDate = new Date(appDetails.installer_current_status.last_updated_date);
      for (const message of messages) {
        if (message.send_by_user_type && message.send_by_user_type === 'installer') {
          let messageDate = new Date(message.message_date);
          if (messageDate > lastStateUpdatedDate) {
            setDisplayChatPopups(false);
            return true;
          }
        }
      }
    }
    return false;
  }

  useEffect(
    () => {
      handleChatPopupDisabled();
    },
    [messages, isChatAutomatedMessagesDisabled, setChatAutomatedMessagesDisabled]
  );

  useEffect(
    () => {
      if (messages.length > 0) {
        const isMessageListUpdated = totalMessageCount !== messages.length;
        const lastMessage = messages[messages.length - 1];
        if (isMessageListUpdated) scrollToBottom();
        if (appDetails.unread_messages_count && !isSolargrafApplication(appDetails) && isInstaller(permitServiceRole)) updateChatStatus({ applicationId: appId, lastMessageId: lastMessage['id'] });
        setTotalMessageCount(messages.length);
      }
      const interval = setInterval(() => {
        fetchAllChats();
      }, 10000);
      return () => clearInterval(interval);
    },
    [fetchAllChats]
  );

  const handleSendMessageFailure = () => {
    setMessageSendFailed(true);
  }

  const handleSendMessageFailurePopupClose = () => {
    setMessageSendFailed(false);
  }

  const handleSendMessageSuccess = () => {
    setMessageSendFailed(false);
    setMessageBody('');
  }

  const handleMessageSend = () => {
    setChatInfoEnabled(true);
    setSendMessageCount(sendMessageCount + 1);
    const body = {
      permit_id: appId,
      subject: 'Others',
      body_content: messageBody.trim(),
      file_names: isEmpty(uploadingFileName) ? [] : [uploadingFileName],
      files,
      document_requested: false,
      return_msg_history: true,
      quote_message_id: quoteMessage ? quoteMessage.id : null
    };
    sendMessage({ body, successCb: () => handleSendMessageSuccess(), failureCb: () => handleSendMessageFailure() });
    setIsCheckBoxClicked(true);

    setQuoteMessage(null);
    resetFileUploadStatus();
  };

  const handleMessageBody = event => {
    setMessageBody(event.target.value);
  };

  const handleFileUpload = event => {
    setFileUploadStatus('in_progress');
    let qPDF = event.target.files[0];
    let fTypeArray = qPDF.name.split('.');
    let fType = fTypeArray[fTypeArray.length - 1];

    let fSizeInMb = qPDF.size / 1024 / 1024;
    let file_name = `${appId}_${'OTHERS'}_${moment().format('DDMMYYYY-hhmmss')}.${fType}`;
    if (fSizeInMb > FILE_UPLOAD_SIZE_LIMIT) {
      setUploadError(true);
      setUploadErrorMsg(`Max file size should be ${FILE_UPLOAD_SIZE_LIMIT} Mb`);
      setFileUploadStatus('idle');
    } else {
      getPresingedURL({
        fileName: file_name,
        methodType: 'PUT',
        successCb: presignedURL => {
          uploadToS3({
            preSignedS3Url: presignedURL,
            fName: file_name,
            fileObj: qPDF,
            isDownload: true,
            successCbS3: () => {
              setFileUploadStatus('success');
              setUploadingFileName(file_name);
              files = [{ file_name: file_name, original_file_name: qPDF.name, file_size: qPDF.size, versions: [appDetails?.version?.current_version, ...isFromAdmin ? ['External'] : []] }];
            },
            failureCbS3: () => {
              setUploadError(true);
              setUploadErrorMsg('We are facing some issues with file upload, please try later.');
              setFileUploadStatus('idle');
            }
          });
        },
        failureCb: () => {
          setUploadError(true);
          setUploadErrorMsg('We are facing some issues with file upload, please try later.');
          setFileUploadStatus('idle');
        }
      });
    }
  };

  const resetFileUploadStatus = () => {
    setFileUploadStatus('idle');
    setUploadingFileName('');
    files = [];
    setUploadError(false);
  };

  const getSignedUrl = fName => {
    return new Promise((resolve, reject) => {
      getPresingedURL({
        fileName: fName,
        methodType: 'GET',
        successCb: presignedURL => resolve(presignedURL),
        failureCb: () => resolve(false)
      });
    });
  };

  const handleChatPopupClose = e => {
    setChatAutomatedMessagesDisabled(true);
    setDisplayChatPopups(false);
  }

  const handleCheckboxClick = e => {
    setIsCheckBoxClicked(true);
    setIsMessageAcknowledged(true);
    if (messages != null && messages.length > 0) {
      updateChatStatus({ applicationId: appId, lastMessageId: messages[messages.length - 1]['id'] });
    }
  }

  const updateDownloadedFiles = params => {
    const { fileName, signedUrl = null, downloadStatus } = params;
    let existingDownloadedFiles = downloadedFiles;
    let fileObj = find(existingDownloadedFiles, { fileName });
    if (fileObj) {
      if (signedUrl) fileObj['signedUrl'] = signedUrl;
      if (fileName) fileObj['fileName'] = fileName;
      if (downloadStatus) fileObj['downloadStatus'] = downloadStatus;
    } else {
      existingDownloadedFiles.push({ fileName, signedUrl, downloadStatus });
    }
    setDownloadedFiles(existingDownloadedFiles);
    fetchAllChats();
  };

  const downloadFile = (fileUrl, fileName) => {
    const myWindow = window.open(fileUrl, '_blank');
    handlePopupBlocker(myWindow);
  };

  const downloadCloudFile = async fileName => {
    const preSignedUrl = await getSignedUrl(fileName).then(res => {
      updateDownloadedFiles({ fileName, signedUrl: res, downloadStatus: 'downloaded' });
      return res;
    });
    downloadFile(preSignedUrl, fileName);
  };

  const fileIcon = fName => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
      fType = 'document';
    }
    const Icon = Icons[fType];
    return <Icon />;
  };

  const renderMessageAttachment = message => {
    const { file_names = [] } = message;
    if (isEmpty(file_names)) return null;
    return file_names.map((fileName, index) => {
      const fileState = find(downloadedFiles, ['fileName', fileName]);
      const signedUrl = get(fileState, 'signedUrl');
      const isDownloading = isEqual(get(fileState, 'downloadStatus'), 'downloading');
      return (
        <Box className={classes.listItemAttachment} key={index}>
          {isDownloading && <CircularProgress size={20} className={classes.fabProgress} />}
          {signedUrl ? (
            <IconButton
              onClick={() => downloadFile(signedUrl, fileName)}
              className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}
            >
              {fileIcon(fileName)}
            </IconButton>
          ) : (
            <CloudDownloadOutlinedIcon
              fontSize="large"
              onClick={() => downloadCloudFile(fileName)}
              title={fileName}
              className={classes.listItemAttachmentIcon}
            />
          )}
          <ListItemText primary={fileName} disableTypography className={classes.fileName} />
        </Box>
      );
    });
  };

  const getSenderInfo = (type, section = 'label') => {
    const senderTypes = [
      { type: 'enphase_admin', label: 'From Enphase', isAdmin: true },
      { type: 'permit_admin', label: 'From Enphase', isAdmin: true },
      { type: 'installer', label: 'From Installer', isAdmin: false },
      { type: 'default', label: 'From Unknown', isAdmin: false }
    ];
    const item = find(senderTypes, { type }) || find(senderTypes, { type: 'default' });
    return item[section];
  };

  const isSnackbarEnabled = () => {
    return chatInfoEnabled && sendMessageCount === 1;
  };

  const renderBodyContent = (messageBody, messageType) => {
    if (isEmpty(messageBody)) return (
      <Fragment>
        {messageType === MESSAGE_TYPES.RESUBMISSION_REQUEST && <Typography variant="body2">Version : More Information Requested</Typography>}
        {messageType === MESSAGE_TYPES.RESUBMISSION_SUBMIT && <Typography variant="body2">Version : More Information Submitted</Typography>}
      </Fragment>
    );
    const lines = messageBody.split('\n');
    return (
      <Fragment>
        {lines.map(line => line ? <Typography variant="body2">{line}</Typography> : <br />)}
        {messageType === MESSAGE_TYPES.RESUBMISSION_REQUEST && <Typography variant="body2"><br /><span>Version : More Information Requested</span></Typography>}
        {messageType === MESSAGE_TYPES.RESUBMISSION_SUBMIT && <Typography variant="body2"><br /><span>Version : More Information Submitted</span></Typography>}
      </Fragment>
    )
  }

  const getMessageById = (id) => {
    return messages.find(message => message.id === id);
  }

  const renderMessageContent = () => {
    const snackbarEnabled = isSnackbarEnabled();
    return (
      <DialogContent
        className={clsx(classes.dialogContent, snackbarEnabled ? classes.snackbarPadding : {})}
        id="messageUnorderedList"
      >
        <Snackbar
          open={snackbarEnabled}
          TransitionComponent={Fade}
          message="You can close this window after sending your message. We will notify you via email when there is a response available to your query."
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={
            <IconButton aria-label="close" color="inherit" onClick={() => setChatInfoEnabled(false)}>
              <CloseIcon />
            </IconButton>
          }
          onClose={() => setChatInfoEnabled(false)}
          autoHideDuration={120000}
          className={classes.snackbar}
        />
        <Box className={classes.chatNotes}>
          {`This section can be used to query regarding your Permit application & keep track of your conversations.`}
        </Box>
        <List className={classes.chatList}>
          {messages.map((message, index) => {
            const messageByCurrentUser = isFromApplicationStatus ? message.send_by_user_type === 'installer' : message.send_by_user_type !== 'installer';
            const messageHasContent = (message.body_content || message.file_names) ? true : false;
            const listItemStyle = messageByCurrentUser
              ? clsx(classes.listItem, classes.listItemRight)
              : clsx(classes.listItem, classes.listItemLeft);
            return (
              <ListItem
                button
                className={messageHasContent ? listItemStyle : classes.hide}
                key={index}
                disableGutters
                id={message.id}
              >
                <Box
                  className={
                    messageByCurrentUser ? (
                      clsx(classes.listItemHeaderRow, classes.senderListItemHeaderRow)
                    ) : (
                      classes.listItemHeaderRow
                    )
                  }
                >
                  {!messageByCurrentUser && (
                    <ListItemText
                      secondary={getSenderInfo(message.send_by_user_type)}
                      disableTypography
                      className={
                        getSenderInfo(message.send_by_user_type, 'isAdmin') ? (
                          clsx(classes.litstItemHeader, classes.listItemAdminHeader)
                        ) : (
                          classes.litstItemHeader
                        )
                      }
                    />
                  )}
                  <ListItemText
                    secondary={
                      <span display='flex'>
                        {(!isSolargrafApplication(appDetails) && (isAdmin(permitServiceRole) || isInstaller(permitServiceRole))) && (
                          <span className={classes.replyIcon} onClick={() => {
                            setQuoteMessage(message);
                            scrollIntoView(document.getElementById('standard-start-adornment'));
                          }}><Reply /></span>
                        )}
                        <span className={classes.messageDate}>{moment(message.message_date).format('DD MMM h:mm A')}</span>
                      </span>
                    }
                    disableTypography
                    className={clsx(classes.litstItemHeader, classes.dateText)}
                  />
                </Box>
                {!isEmpty(message.quote_message_id) && (
                  <Box className={classes.quoteSection} onClick={() => scrollIntoView(document.getElementById(message.quote_message_id))}>
                    {renderBodyContent(getQuoteMessageLabel(getMessageById(message.quote_message_id)), message.message_type)}
                  </Box>
                )}
                {!isEmpty(message.file_names) && renderMessageAttachment(message)}
                <Box className={classes.listItemAttachment}>
                  <ListItemText primary={renderBodyContent(message.body_content, message.message_type)} disableTypography className={classes.contentText} />
                </Box>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    );
  };

  const getQuoteMessageLabel = (message) => {
    return !isEmpty(message.body_content) ? message.body_content.substr(0, 70) :
      message.file_names ? message.file_names[0] : message.message_type === MESSAGE_TYPES.RESUBMISSION_SUBMIT ? 'Version : More Information Submitted' : '';
  }

  const chatPopupMessageForApplicationState = (stateId) => {
    switch (stateId) {
      case INSTALLER_STATES.MORE_INFO_REQUESTED.id:
        return moreInfoRequestPopup;
      case INSTALLER_STATES.PERMIT_SET_AVAILABLE.id:
        return permitSetAvailablePopup;
      case INSTALLER_STATES.REWORK_SUBMITTED.id:
        return reworkSetAvailablePopup;
      default:
        setDisplayChatPopups(false);
        return "";
    }
  }

  const handleClickOnChatBox = () => {
    var chatPopupDisabled = handleChatPopupDisabled();
    if (!displayChatPopups && !chatPopupDisabled) {
      setDisplayChatPopups(true);
    }
  }
  const installerName = appDetails.installer_user_name || '';

  const isAdminMessage = (message) => {
    return getSenderInfo(message.send_by_user_type, 'isAdmin');
  }

  const handleAdminMessage = () => {
    if (messages.length > 0) {
      for (let i = messages.length - 1; i >= 0; i--) {
        if (isAdminMessage(messages[i])) {
          setAdminMessage(true);
          setAdminMessageSender(messages[i].sender_name);
          return true;
        }
      }
    }
    return false;
  }

  useEffect(
    () => {
      handleAdminMessage();
    },
    [messages]
  );

  const renderFooterContent = () => {
    const disableChatInput =
      isSolargrafApplication(appDetails) || (!isAdmin(permitServiceRole) && !isInstaller(permitServiceRole));
    return (
      <DialogActions className={classes.dialogActions}>

        {displayChatPopups && !isAdmin(permitServiceRole) && <Box className={classes.chatDisclaimer}>
          <Box className={classes.chatPopupHeader}>
            <Typography className={classes.chatPopupText}>{`Hi ${installerName},`}</Typography>
            <Button className={classes.closeChatPopupButton} onClick={handleChatPopupClose}><CloseIcon style={{ fontSize: 15 }}></CloseIcon></Button>
          </Box>
          <Typography className={classes.chatPopupText}>{chatPopupMessageForApplicationState(appDetails.installer_current_status.state_id)}</Typography>
        </Box>}

        {messageSendFailed && <Box className={classes.chatAlerts}>
          <Box className={classes.chatAlertsText}>
            {`Your last message could not be sent. Please try again.`}
          </Box>
          <Box onClick={() => handleSendMessageFailurePopupClose()}>
            <CloseIcon className={classes.chatCrossButton} />
          </Box>
        </Box>}

        <Box onClick={handleClickOnChatBox} className={classes.messageTextFieldRow}>
          <TextField
            size="small"
            variant="outlined"
            id="standard-start-adornment"
            className={fileUploadStatus === 'success' ? classes.messageAttachmentSection : classes.messageSection}
            onChange={event => handleMessageBody(event)}
            placeholder="Type message"
            value={messageBody}
            autoFocus={!(isFromAdmin || isFromApplicationStatus)}
            multiline
            maxRows={5}
            disabled={disableChatInput}
            InputProps={{
              className: clsx(fileUploadStatus === 'success' && classes.attachmentSectionRoot),
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={clsx(fileUploadStatus === 'success' && classes.inputAdornment)}
                >
                  {uploadError && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {uploadErrorMsg}
                    </Typography>
                  )}
                  {fileUploadStatus === 'in_progress' && <CircularProgress size={20} className={classes.fabProgress} />}
                  {!isEmpty(quoteMessage) && (
                    <Chip
                      label={getQuoteMessageLabel(quoteMessage)}
                      onDelete={() => setQuoteMessage(null)}
                      variant="outlined"
                      size="small"
                      color="primary"
                      className={clsx(classes.selectedAttachment, classes.quoteMessage)}
                    />
                  )}
                  {fileUploadStatus === 'success' ? (
                    <Chip
                      label={uploadingFileName}
                      onDelete={() => resetFileUploadStatus()}
                      variant="outlined"
                      size="small"
                      className={classes.selectedAttachment}
                    />
                  ) : (
                    <IconButton
                      variant="outlined"
                      component="label"
                      style={{ fontSize: '10px', textTransform: 'none', width: '26%' }}
                      size="small"
                      disabled={fileUploadStatus === 'in_progress' || disableChatInput}
                      onClick={() => resetFileUploadStatus()}
                    >
                      <AttachmentOutlinedIcon />
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        id="fileUpload"
                        onChange={e => handleFileUpload(e)}
                        value=""
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
          />
          {fileUploadStatus !== 'in_progress' &&
            (messageBody || fileUploadStatus === 'success') && (
              <Box className={classes.iconWrapper}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => handleMessageSend()}
                  aria-label="close"
                  size="small"
                  style={{ marginLeft: 4 }}
                >
                  <SendIcon color="primary" />
                </IconButton>
              </Box>
            )}
        </Box>
        {isAdmin(permitServiceRole) &&
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={isCheckBoxClicked}
                color="primary"
                disabled={isCheckBoxClicked}
                onChange={handleCheckboxClick}
              />}
              label={adminMessage ? `Message Acknowledge (last message acknowledged by ${adminMessageSender})` : "Message Acknowledge"}
            />
          </FormGroup>
        }
      </DialogActions>
    );
  };
  if (isFromAdmin || isFromApplicationStatus) {
    return (
      <Box className={classes.chatBoxWrapper}>
        <DialogTitle className={classes.chatBoxTitleWrapper} disableTypography>
          <div className={classes.displayFlex}>
            <ChatSvg />
            <Typography className={classes.chatBoxTitle}>{`Communicate with ${isFromAdmin ? 'Installer' : 'Enphase'}`}</Typography>
          </div>
        </DialogTitle>
        {renderMessageContent()}
        {renderFooterContent()}
      </Box>
    );
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={() => { }}
      TransitionComponent={Transition}
      scroll={'paper'}
      className={classes.mainDialog}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <div className={classes.displayFlex}>
          <ChatSvg />
          <Typography className={classes.leftPadding}>Communicate with Enphase</Typography>
        </div>
        <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close" size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {renderMessageContent()}
      {renderFooterContent()}
    </Dialog>
  );
}
