import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { getS3URL } from '../../containers/Permitting/action';
import { Buffer } from 'buffer';

const PermitFileDownload = (props) => {
  const [status, setStatus] = useState('Downloading...');

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const fileId = params.get('id');
    props.getS3URL({
      fileName: fileId,
      methodType: 'GET',
      publicFile: true,
      successCb: async (preSignedS3Url) => {
        const url = await fetch(preSignedS3Url)
          .then((response) => response.blob())
          .then((blob) => URL.createObjectURL(blob));
        const a = document.createElement('a');
        a.href = url;
        a.download = Buffer.from(fileId, 'base64').toString('utf8');
        a.click();
        window.close();
      },
      failureCb: () => {
        setStatus('Unable to download permit file. Please try again');
      },
    });
  }, []);

  return <Fragment>{status}</Fragment>;
};

const mapDispatchToProps = (dispatch) => ({
  getS3URL: (payload) => dispatch(getS3URL(payload)),
});

export default connect(null, mapDispatchToProps)(PermitFileDownload);
