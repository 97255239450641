export const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiIconButton-root': {
      borderRadius: 0
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& button': {
        display: 'flex',
        marginLeft: 'auto'
      }
    }
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.yellow,
    border: 'none',
    padding: theme.spacing(2)
  },
  container: {
    maxHeight: 440,
    minHeight: 440
  },
  headerButton: {
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: '12px'
  },
  acceptButton: {
    color: theme.palette.primary.white
  },
  rejectButton: {
    color: '#919191'
  },
  dialogHeader: {
    color: '#335977',
    fontSize: '29px',
    fontWeight: 'Bold'
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    opacity: 0.23
  },
  content: {
    border: '1px solid #7070704D',
    margin: '0 20px',
    padding: '15px'
  },
  info: {
    marginTop: '25px'
  },
  buttonWrapper: {
    justifyContent: 'flex-start',
    padding: theme.spacing(2.5)
  },
  linkColour: {
    color: '#3B86FF'
  },
  termsText: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.87)'
  },
  link: {
    color: '#F3731F !important',
    cursor: 'pointer'
  },
  headerInfo: {
    //width: 'calc(100% - 400px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    searchTableCell: {
      display: 'flex',
      alignItems: 'center',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      border: '1px solid #70707080',
      borderRadius: theme.spacing(0.75)
    }
  },
  tableRow: {
    padding: theme.spacing(1.5)
  },

  headerContainer: {
    padding: 0
  },
  headerText: {
    color: '#FFE785',
    fontSize: 13,
    fontWeight: 'bold',
    backgroundColor: '#335977',
    position: 'sticky',
    display: 'flex',
    height: '65px'
  },
  headerTextSpanHeight: {
    height: '50px'
  },
  searchTableCell: {
    display: 'flex',
    height: theme.spacing(4.25),
    padding: theme.spacing(1.5),
    backgroundColor: '#fff'
  },
  inputField: {
    height: theme.spacing(1.5)
  },
  styleSelect: {
    height: theme.spacing(4.25)
  },
  headerButtonText: {
    height: theme.spacing(4),
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: '12px',
    width: 'max-content',
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(1.5)
  },
  createNewRequest: {
    fontSize: 15,
    borderRadius: 0,
    textTransform: 'none',
    height: theme.spacing(4.75),
    padding: theme.spacing(0, 3),
    border: '1px solid #F3731F',
    fontWeight: 600,
    whiteSpace: 'nowrap'
  },
  chatBadge: {
    top: '3px',
    minWidth: theme.spacing(22)
  },
  badgeContent: {
    color: 'white',
    borderRadius: '3px',
    top: theme.spacing(-1),
    right: theme.spacing(2)
  },
  managePermitText: {
    fontSize: theme.spacing(2),
    color: theme.palette.secondary.link,
    paddingRight: theme.spacing(5),
    cursor: 'pointer'
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%'
    }
  },
  dialogTemplateHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 0
  },
  closeButton: {
    padding: 0
  },
  dialogPaper: {
    height: '80vh'
  },
  styleTemplateText: {
    color: '#696969',
    fontSize: '14px'
  },
  addTemplateButton: {
    width: '152px',
    marginRight: theme.spacing(3),
    marginLeft: 'auto',
    height: '40px',
    border: '1px solid #F37320',
    borderRadius: '2px',
    opacity: 1,
    color: '#F37320',
    marginBottom: '6px',
    textTransform: 'none',
    background: '#FFFFFF',
    fontWeight: 600,
    '&:hover': { background: '#FFFFFF' }
  },
  disabledBorder: {
    border: '1px solid #00000042',
    pointerEvents: 'none'
  },
  permitType: {
    textAlign: 'center'
  },
  leftPadding: {
    paddingLeft: theme.spacing(2)
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
    fontSize: '15px'
  },
  slightlyLeft: {
    '& .MuiBadge-badge': {
      right: theme.spacing(1.6)
    }
  },
  relativePosition: {
    '& .MuiInputLabel-formControl': {
      position: 'relative'
    }
  },
  dateRangeWrapper: {
    boxShadow: 'none',
    padding: '0px !important'
  },
  okButton: {
    color: '#fff'
  }
});