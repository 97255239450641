import React, { useState } from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { AddCircleOutline } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Box,
  Typography,
  FormControl,
  makeStyles,
  DialogContentText,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEqual, get, isEmpty } from '../../../../utils/lodash';

import { Label } from '../../../../components/common/Label';
import { getStructuralCom } from '../actions';
import { withStyles } from '@material-ui/styles';
import { captureRejectionSymbol } from 'events';
import Loader from '../../../../components/Loader';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  dialogWidth: {
    minWidth: '40%',
    maxWidth: '40%',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 'unset',
  },
  inputFieldInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3, 1),
  },
  inputFieldHeading: {
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
  },
  inputFieldHint: {
    fontSize: theme.spacing(1.25),
  },
  autocompleteField: {
    padding: theme.spacing(0, 3),
  },
  installerTag: {
    color: theme.palette.primary.white,
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },
  installerTagArrow: {
    color: 'black',
  },
  installerTagTooltip: {
    position: 'relative',
    top: theme.spacing(1),
    background: 'black',
  },
  deleteIcon: {
    fill: theme.palette.primary.white,
  },
  information: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1, 3),
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  informationText: {
    margin: 'unset',
    fontSize: theme.spacing(1.5),
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      // marginTop: theme.spacing(1),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
    '& .MuiInputBase-root.Mui-disabled:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  radioWrapper: {
    minWidth: theme.spacing(30),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  radioTitle: {
    marginBottom: theme.spacing(1),
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    position: 'relative',
  },
  showButton: {
    marginLeft: 'auto',
    minWidth: '150px',
    height: 'auto',
    padding: theme.spacing(0.25),
    marginBottom: theme.spacing(-1.5),
    flexDirection: 'column',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3, 2),
  },
  cancelButton: {
    borderColor: 'black',
  },
  saveButton: {
    color: 'white',
    boxShadow: 'none',
  },
  uploadDataField: {
    width: '44%',
    margin: theme.spacing(5, 1, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  uploadDataLabel: {
    margin: theme.spacing(1, 0),
  },
  filesListWrapper: {
    border: `1px solid #bbb`,
    borderRadius: theme.spacing(0.5),
    maxHeight: theme.spacing(10),
    marginRight: theme.spacing(-1),
  },
  fileAttachment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75),
  },
  fileNameIconWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemAttachmentIcon: {
    pointerEvents: 'none',
  },
  iconCoverChat: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
    '& .MuiIconButton-label': {
      height: '100%',
      '& svg': {
        height: '100%',
      },
    },
  },
  popupCloseBtn: {
    color: '#335977',
    align: 'right',
    float: 'right',
    minWidth: theme.spacing(2.5),
  },
  errorText: {
    fontSize: theme.spacing(1.75),
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0.3),
  },
  hidden: {
    display: 'none',
  },
}));

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiIconButton-root': {
      borderRadius: 0,
    },
    '& .highcharts-credits': {
      display: 'none',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    zIndex: 9999,
  },
});

const EditStructuralAssembly = (props) => {
  const {
    id,
    handleClose,
    handleShowEditStructuralPopup,
    userSelectionData,
    racksList,
    standoffsList,
    rooftypesList,
    handleSubmit,
    handleCancel,
    basicDetails,
    deleteFile,
    handleFilesUpload,
    fileUploadLogs,
    handleFileDownload,
    handleDuplicateModel,
    errorData,
    fileIcon,
    readOnly,
    handleBasicDetails,
    editBasicDetails,
    stateRacksDetails,
    stateStandoffDetails,
    stateRooftypesDetails,
  } = props;
  const {
    rack_details = editBasicDetails.edit_rack_details,
    standoff_details = editBasicDetails.edit_standoff_details,
    roof_material = editBasicDetails.edit_roof_material,
  } = basicDetails;
  basicDetails.assembly = editBasicDetails.edit_assembly;

  const [assemblyConfirmation, setAssemblyConfirmation] = useState(false);
  const [assemblySelectedFileIndex, setAssemblySelectedFileIndex] = useState(null);
  const [assemblyFileEdited, setAssemblyFileEdited] = useState(false);
  const [exsistingCombination, setExsistingCombination] = useState(false);
  const [currentlySending, setCurrentlySending] = useState(false);

  function getHandleChange(e, key, value) {
    const { handleBasicDetails, stateRacksDetails, stateStandoffDetails, basicDetails, stateRooftypesDetails } = props;
    handleBasicDetails(e, key, value);
  };

  function getTruncatedFileName(originalFileName) {
    let fileName = originalFileName.split('.')[0];
    let fileExtention = originalFileName.split('.')[1];
    let turncatedFilename = fileName.length > 20 ? fileName.substring(0, 25) + '...' + fileExtention : originalFileName;
    return turncatedFilename;
  }

  function handleDuplicateValue(e, key, value) {
    const { id, handleBasicDetails, stateRacksDetails, stateStandoffDetails, basicDetails, stateRooftypesDetails } = props;
    let roofMaterail = key === 'roof_material' ? value : roof_material;
    let standOffMaterail = key === 'standoff_details' ? value : standoff_details;
    let rackMaterail = key === 'rack_details' ? value : rack_details;

    if (roofMaterail && standOffMaterail && rackMaterail) {
      setCurrentlySending(true);
      let filterParams = {
        rack_details: stateRacksDetails.filter((obj) => rackMaterail == obj['name']).map((item) => item['rack_id']),
        standoff_details: stateStandoffDetails
          .filter((obj) => standOffMaterail == obj['name'])
          .map((item) => item['standoff_id']),
        roof_material: stateRooftypesDetails.filter((obj) => roofMaterail == obj['name']).map((item) => item['key']),
      };
      if (!isEmpty(filterParams.rack_details) && !isEmpty(filterParams.standoff_details) && !isEmpty(filterParams.roof_material)) {
        const structuralType = 'structuralAssembly';
        props.getStructuralCom({
          structuralType,
          ...filterParams,
          successCb: (result, data) => {
            setCurrentlySending(false);
            let idList = data.filter((obj) => id == obj['id']).map((item) => item['id']);
            if (result > 0 && idList.length === 0) {
              setExsistingCombination(true);
            } else {
              setExsistingCombination(false);
            }
          },
          failureCb: (result) => {
            setCurrentlySending(false);
          },
        });
      } else {
        setCurrentlySending(false);
      }
    }
  }

  const classes = useStyles();

  const dialogHeaderText = isEqual(id, 'new') ? 'Add New Assembly' : 'Edit Assembly';

  return (
    <Box className={classes.subRoot}>
      <Dialog
        open={assemblyConfirmation}
        onClose={() => {
          setAssemblyConfirmation(false);
          setAssemblyFileEdited(false);
        }}
      >
        <DialogTitle className={classes.dialogHeader} disableTypography>
          <Typography variant="h6">Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>On delete, it can’t be retrieved again.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteFile(assemblySelectedFileIndex);
              setAssemblyConfirmation(false);
              setAssemblyFileEdited(true);
            }}
            color="primary"
          >
            Ok
          </Button>
          <Button
            onClick={() => {
              setAssemblyConfirmation(false);
              setAssemblyFileEdited(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open
        onClose={handleShowEditStructuralPopup}
        aria-labelledby="customized-dialog-title"
        classes={{ paper: classes.dialogWidth }}
        disableEscapeKeyDown
      >
        <DialogTitle id="customized-dialog-title">
          <Box className={classes.dialogTitle}>
            <b>{dialogHeaderText}</b>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <FormControl fullWidth>
            <Autocomplete
              id="roof_material"
              inputValue={roof_material}
              options={rooftypesList}
              disableClearable
              getOptionLabel={(option) => (typeof option === 'string' ? option : option['name'])}
              onInputChange={(e, value) => getHandleChange(e, 'roof_material', value)}
              onChange={(e, value) => handleDuplicateValue(e, 'roof_material', value)}
              renderInput={(params) => <TextField {...params} label={<Label text={'Roof Material'} />} />}
              defaultValue={isEmpty(editBasicDetails.edit_roof_material) ? '' : editBasicDetails.edit_roof_material}
            />
            <Autocomplete
              id="standoff_details"
              inputValue={standoff_details}
              options={standoffsList}
              disableClearable
              getOptionLabel={(option) => (typeof option === 'string' ? option : option['name'])}
              onInputChange={(e, value) => getHandleChange(e, 'standoff_details', value)}
              onChange={(e, value) => handleDuplicateValue(e, 'standoff_details', value)}
              renderInput={(params) => (
                <TextField {...params} label={<Label text={'Attachments'} />} className={classes.inputField} />
              )}
              defaultValue={
                isEmpty(editBasicDetails.edit_standoff_details) ? '' : editBasicDetails.edit_standoff_details
              }
            />
            <Autocomplete
              id="rack_details"
              inputValue={rack_details}
              options={racksList}
              disableClearable
              getOptionLabel={(option) => (typeof option === 'string' ? option : option['name'])}
              onInputChange={(e, value) => getHandleChange(e, 'rack_details', value)}
              onChange={(e, value) => handleDuplicateValue(e, 'rack_details', value)}
              renderInput={(params) => (
                <TextField {...params} label={<Label text={'Rails'} />} className={classes.inputField} />
              )}
              defaultValue={isEmpty(editBasicDetails.edit_rack_details) ? '' : editBasicDetails.edit_rack_details}
            />
            
            <br />
            <Box id="assembly">
              <Label text="Assembly" className={classes.uploadDataLabel} />
              {basicDetails.assembly !== null && basicDetails.assembly.length > 0 && !assemblyFileEdited && (
                <Box className={classes.filesListWrapper}>
                  {basicDetails.assembly
                    .slice()
                    .reverse()
                    .map((file, i) => (
                      <Box
                        key={file.display_file_name + i}
                        id={file.display_file_name}
                        className={classes.fileAttachment}
                      >
                        <Box
                          className={classes.fileNameIconWrapper}
                          disabled={true}
                        >
                          <IconButton className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}>
                            {fileIcon(!isEmpty(file.display_file_name) ? file.display_file_name : '')}
                          </IconButton>
                          <Box>
                            <Typography>{!isEmpty(file.display_file_name) ? getTruncatedFileName(file.display_file_name) : ""}</Typography>
                          </Box>
                        </Box>
                        <IconButton className={classes.popupCloseBtn}>
                          <CloseIcon
                            onClick={() => {
                              setAssemblySelectedFileIndex(i);
                              setAssemblyConfirmation(true);
                              setAssemblyFileEdited(false);
                            }}
                            className={classes.popupCloseIcon}
                          />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
              )}
              {basicDetails.assembly !== null && basicDetails.assembly.length > 0 && assemblyFileEdited && (
                <Box className={classes.filesListWrapper}>
                  {basicDetails.assembly
                    .slice()
                    .reverse()
                    .map((file, i) => (
                      <Box
                        key={file.display_file_name + i}
                        id={file.display_file_name}
                        className={classes.fileAttachment}
                      >
                        <Box
                          className={classes.fileNameIconWrapper}
                          disabled={true}
                        >
                          <IconButton className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}>
                            {fileIcon(!isEmpty(file.display_file_name) ? file.display_file_name : '')}
                          </IconButton>
                          <Box>
                            <Typography>{file.display_file_name}</Typography>
                          </Box>
                        </Box>
                        <IconButton className={classes.popupCloseBtn}>
                          <CloseIcon
                            onClick={() => {
                              setAssemblySelectedFileIndex(i);
                              setAssemblyConfirmation(true);
                              setAssemblyFileEdited(false);
                            }}
                            className={classes.popupCloseIcon}
                          />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
              )}
              {fileUploadLogs['assembly']['uploadError'].msg !== '' && (
                <Box>
                  <Typography className={classes.errorText}>{fileUploadLogs['assembly']['uploadError'].msg}</Typography>
                </Box>
              )}
              {!isEmpty(get(errorData, 'assembly')) && (
                <Box>
                  <Typography className={classes.errorText}>{get(errorData, 'assembly')}</Typography>
                </Box>
              )}
              <Button color="primary" component="label" disabled={basicDetails.assembly.length === 1}>
                <AddCircleOutline />
                <input
                  type="file"
                  accept=".dwg"
                  className={classes.hidden}
                  id="assembly_file"
                  name="assembly_file"
                  onClick={(e) => {
                    e.target.value = '';
                  }}
                  onChange={(e) => handleFilesUpload(e)}
                />
                Add document
              </Button>
              {exsistingCombination &&
                !(
                  roof_material === editBasicDetails.edit_roof_material &&
                  rack_details === editBasicDetails.edit_rack_details &&
                  standoff_details === editBasicDetails.edit_standoff_details
                ) && (
                  <Box>
                    <Typography className={classes.errorText}>
                      Structural Assembly already exists for this combination. Please select a new one
                    </Typography>
                  </Box>
                )}
              {currentlySending == true ? (
                <div className={classes.loader}>
                  <CircularProgress />
                </div>
              ) : null}
            </Box>
            {/* </div> */}
          </FormControl>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleShowEditStructuralPopup}
            variant="outlined"
            color="default"
            className={classes.cancelButton}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.saveButton}
            disabled={
              !(
                basicDetails.roof_material &&
                basicDetails.rack_details &&
                basicDetails.standoff_details &&
                basicDetails.assembly[0] &&
                !(
                  exsistingCombination &&
                  !(
                    roof_material === editBasicDetails.edit_roof_material &&
                    rack_details === editBasicDetails.edit_rack_details &&
                    standoff_details === editBasicDetails.edit_standoff_details
                  )
                ) &&
                !currentlySending &&
                isEmpty(get(errorData, 'assembly'))
              )
            }
            onClick={handleSubmit}
          >
            {'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getStructuralCom: (payload) => dispatch(getStructuralCom(payload)),
});
const mapStateToProps = (state) => ({
  racksList1: state.structuralAssemblyReducer.racksList,
  standoffsList1: state.structuralAssemblyReducer.standoffsList,
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditStructuralAssembly));
