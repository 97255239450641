import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  header: {
    color: '#335977',
    fontSize: '22px',
    fontWeight: 'Bold'
  },
  smallHeader: {
    color: '#335977',
    fontSize: '20px',
    fontWeight: 'Bold'
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    opacity: 0.23,
    cursor: 'pointer'
  },
  content: {
    border: '1px solid #7070704D',
    margin: '0 20px 20px 20px',
    padding: '15px'
  },
  dropDown: {
    width: '300px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(1)
    }
  },
  dragArea: {
    margin: theme.spacing(0, 0, 2.5, 0),
    backgroundColor: '#B9B9B91A',
    width: theme.spacing(65.375),
    height: theme.spacing(44.875),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      height: 'auto'
    }
  },
  input: {
    visibility: 'hidden',
    height: 0
  },
  err: {
    color: theme.palette.primary.errorText,
    fontSize: theme.spacing(1.75)
  },
  acceptButton: {
    color: theme.palette.primary.white,
    textTransform: 'none'
  },
  imgContainer: {
    border: '1px solid #70707040',
    height: theme.spacing(10),
    width: theme.spacing(15),
    display: 'flex',
    position: 'relative'
  },
  previewImage: {
    margin: 'auto',
    display: 'block',
    maxWidth: theme.spacing(15),
    maxHeight: theme.spacing(10)
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400
  },
  imageTypeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  imageTypeOptions: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  selectedFileWrapper: {
    maxWidth: theme.spacing(40),
    maxheight: theme.spacing(15)
  },
  commentText: {
    fontSize: theme.spacing(1.75),
    marginBottom: theme.spacing(1.5)
  },
  commentField: {
    marginBottom: theme.spacing(1.5)
  },
  removeFile: {
    cursor: 'pointer',
    opacity: 0.4,
    position: 'relative',
    top: theme.spacing(0.8),
    left: theme.spacing(0.8),
    fontSize: 18
  },
  smallArea: {
    height: theme.spacing(30)
  },
  displayFlex: {
    display: 'flex'
  },
  typeRow: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  boxPadding: {
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25)
  },
  assignText: {
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    letterSpacing: 0,
    color: '#335977',
    opacity: 1,
    paddingTop: theme.spacing(1.875)
  },
  assignButton: {
    marginTop: theme.spacing(2.375),
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(2.4),
    textTransform: 'none',
    width: theme.spacing(35),
    height: theme.spacing(3),
    background: '#C1C1C1 0% 0% no-repeat padding-box',
    '&:hover': {
      background: '#C1C1C1 0% 0% no-repeat padding-box'
    },
    borderRadius: theme.spacing(0.5),
    opacity: 1,
    color: 'white'
  },
  assignButtonBackground: {
    background: '#F37320 0% 0% no-repeat padding-box',
    '&:hover': {
      background: '#F37320 0% 0% no-repeat padding-box'
    }
  },
  popoverName: {
    fontSize: theme.spacing(1.6),
    color: '#333333',
    opacity: 1
  },
  popoverEmail: {
    fontSize: theme.spacing(1.5),
    color: '#333333',
    opacity: 0.7
  },
  fileButton: {
    fontSize: theme.spacing(2.25)
  },
  listItem: {
    position: 'relative',
    padding: theme.spacing(1)
  },
  listItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    maxWidth: theme.spacing(50)
  },
  switch: {
    marginLeft: 0,
    width: '53%',
    justifyContent: 'space-between'
  },
  selectWrapper: {
    width: '50%'
  },
  selectInputText: {
    '& input': {
      textOverflow: 'unset'
    }
  },
  deviationCommentText: {
    fontSize: theme.spacing(1.75),
    marginBottom: theme.spacing(1.5),
    fontWeight: 'bold'
  },
}));
