import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Backdrop, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import PageHeader from '../../../../components/common/PageHeader';
import { isEmpty, get, keysIn, hasIn, omit, isEqual, toInteger, includes, inRange } from '../../../../utils/lodash';
import history from '../../../../routes/history';
import scrollIntoView from 'scroll-into-view';
import { createUtility, getUtility, getUtilityNameValidation, getUtilityByGenability } from '../../actions';
import SnakBar from '../../../../components/SnakBar';
import { APPLICATION_STATE_TRANSITIONS, REJECT_DIALOG_WARNING, statesAbbr, VIEW_ONLY_ROLES } from '../../constants';
import { getS3URL, putToS3 } from '../../../Permitting/action';
import Icons from '../../../../containers/Permitting/Chats/images';
import uploadFileDetails from '../../../../utils/uploadFileDetails';
import BasicDetailsSection from '../../../../components/Admin/Automation/Utility/BasicDetailsSection';
import ReferenceInformationSectionOldUI from '../../../../components/Admin/Automation/ReferenceInformationSection/oldUI';
import NotesSectionOldUI from '../../../../components/Admin/Automation/NotesSection/oldUI';
import RequiredPlanSetNotesSectionOldUI from '../../../../components/Admin/Automation/RequiredPlanSetNotesSection/oldUI';
import OtherDetailsSection from '../../../../components/Admin/Automation/Utility/OtherDetailsSection';
import CommentDialog from '../../../../components/Admin/Automation/CommentDialog';
import CommentPopupBox from '../../../../components/Admin/Automation/Comment';
import RecordDetailsSectionOldUI from '../../../../components/Admin/Automation/Equipment/RecordDetailsSection/oldUI';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  backdrop: {
    zIndex: 11,
    color: theme.palette.primary.main
  }
});

class index extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.event = null;
    this.commentDialogHeader = '';
    this.commentDialogSecondaryHeader = '';
    this.commentDialogAddSecondaryHeader = '';
    this.state = {
      id: '',
      isUtilityNameUnique: true,
      basicDetails: {
        name: '',
        state: '',
        created_by: '',
        modified_by: '',
        verified_by: '',
        created_at: '',
        modified_at: '',
        verified_at: ''
      },
      utilityList: [],
      referenceInformation: { reference: '', reference_notes: '', reference_files: [] },
      referenceFilesUploadError: {
        error: false,
        msg: ''
      },
      referenceFilesSize: 0,
      referenceFilesUploadStatus: 'idle',
      utilityNotes: {
        designer_instructions_general: '',
        designer_instructions_site_and_roof_plan: '',
        designer_instructions_electrical: '',
        miscellaneous_notes: ''
      },
      requiredPlanNotes: {
        site_and_roof_plan: '',
        electrical: '',
        placards: ''
      },
      otherDetails: { pv_meter: '', gas_meter_clearance: '', line_diagram_required: false },
      currentStatus: '',
      errorData: {},
      comment: '',
      commentsList: [],
      openCommentDialog: false,
      recordSubmitted: false,
      readOnly: this.isReadOnly()
    };
  }

  MAX_ALLOWED_FILE_SIZE = 500;

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ id });
    if (id !== 'new')
      setTimeout(() => {
        this.props.getUtility({
          id,
          successCB: newUtility => {
            this.handleGetSuccessCallback(newUtility);
            const stateAbbreviation = statesAbbr[newUtility.state];
            this.props.getUtilityByGenability({
              pageCount: 100,
              pageStart: 0,
              search: stateAbbreviation,
              successCb: () => {
                this.setState({ utilityList: this.props.utilityByGenability }, () => {
                  this.handleUtilityList(Math.floor(this.props.utilityCount / 100), 0, stateAbbreviation);
                });
              }
            });
          },
          failureCB: () => {
            this.handleCancel();
          }
        });
      },1000);
  }

  handleSubmit = async applicationStatus => {
    const error = this.state.errorData;
    if (applicationStatus === 'EDIT') {
      return this.setState({ readOnly: false }, () => scrollIntoView(document.getElementById('name')));
    }
    if (applicationStatus === 'INCOMPLETE') {
      const nameField = this.state.basicDetails.name;
      if (!isEmpty(nameField) && !('name' in error)) {
        this.props.createUtility(this.getPayload('INCOMPLETE'));
        return history.push('/utility');
      }
    }
    if (applicationStatus === 'PENDING') {
      this.setState({ recordSubmitted: true });
    }
    await this.validateFields('all');
    const { errorData } = this.state;
    if (isEmpty(errorData)) {
      this.event = applicationStatus;
      this.commentDialogHeader = this.getCommentDialog(applicationStatus).headerText;
      this.commentDialogSecondaryHeader = this.getCommentDialog(applicationStatus).secondaryHeaderText;
      this.setState({ openCommentDialog: true });
    }
    const errorKeys = keysIn(errorData);
    scrollIntoView(document.getElementById(errorKeys.includes('name') ? 'name' : errorKeys[0]));
  };

  getCommentDialog = applicationStatus => {
    const commentDialog = {
      headerText: '',
      secondaryHeaderText: ''
    };
    const applicationStatusObject = APPLICATION_STATE_TRANSITIONS.find(state => state.value === applicationStatus);
    commentDialog.headerText = applicationStatusObject.title;
    commentDialog.secondaryHeaderText = applicationStatusObject.key;
    return commentDialog;
  };

  handleProceed = () => {
    this.setState({ openCommentDialog: false }, () => {
      const payload = this.getPayload(this.event);
      this.props.createUtility(payload);
    });
  };

  handleAddComment = comment => {
    this.setState({ comment: comment });
  };

  handleCommentDialogClose = () => {
    this.setState({ openCommentDialog: false, comment: '' });
  };

  handleCreateSuccessCallback = newUtility => {
    history.push('/utility');
    this.handleGetSuccessCallback(newUtility, () => {
      history.push('/utility/' + get(newUtility, 'id'));
    });
    scrollIntoView(document.getElementById('name'));
  };

  handleGetSuccessCallback = (utility, callback = () => {}) => {
    const {
      reference_information,
      utility_notes,
      required_plan_notes,
      other_details,
      name = '',
      state = '',
      id = 'new',
      created_by = '',
      modified_by = '',
      verified_by = '',
      created_at = '',
      modified_at = '',
      verified_at = '',
      comments_list = [],
      current_status = ''
    } = utility;
    const { reference = '', reference_notes = '', reference_files = [] } = reference_information || {};
    const {
      designer_instructions_general = '',
      designer_instructions_site_and_roof_plan = '',
      designer_instructions_electrical = '',
      miscellaneous_notes = ''
    } =
      utility_notes || {};
    const { site_and_roof_plan = '', electrical = '' } = required_plan_notes || {};
    const { pv_meter = '', gas_meter_clearance = '', line_diagram_required = false } = other_details;

    this.setState(
      {
        id,
        basicDetails: {
          name,
          state,
          created_by,
          modified_by,
          verified_by,
          created_at,
          modified_at,
          verified_at
        },
        referenceInformation: { reference, reference_notes, reference_files },
        utilityNotes: {
          designer_instructions_general,
          designer_instructions_site_and_roof_plan,
          designer_instructions_electrical,
          miscellaneous_notes
        },
        requiredPlanNotes: {
          site_and_roof_plan,
          electrical
        },
        otherDetails: { pv_meter, gas_meter_clearance, line_diagram_required },
        currentStatus: current_status,
        commentsList: comments_list,
        readOnly: this.isReadOnly()
      },
      callback
    );
  };

  fileIcon = (fName) => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
      fType = 'document';
    }
    const DocIcon = Icons[fType];
    return <DocIcon />;
  };

  getPayload = applicationStatus => {
    const { referenceInformation, utilityNotes, requiredPlanNotes, otherDetails, id, comment } = this.state;

    /* in case of rejection we don't want to send anything other than comment and status.*/
    if (applicationStatus === 'REJECTED') {
      return {
        id,
        requestBody: {
          ...this.props.utility,
          current_status: applicationStatus,
          comment: comment
        },
        successCB: utility => this.handleCreateSuccessCallback(utility)
      };
    } else {
      return {
        id,
        requestBody: {
          ...this.state.basicDetails,
          reference_information: referenceInformation,
          utility_notes: utilityNotes,
          required_plan_notes: requiredPlanNotes,
          other_details: otherDetails,
          current_status: applicationStatus,
          comment: comment
        },
        successCB: utility => this.handleCreateSuccessCallback(utility)
      };
    }
  };

  validateFields = async section => {
    const { errorData, basicDetails, otherDetails, isUtilityNameUnique, id } = this.state;
    const omittedFields = [];
    // All basic details validations goes here
    if (section === 'basic_details' || section === 'all') {
      const basicDetailsRequiredFields = [ 'name', 'state' ];
      basicDetailsRequiredFields.forEach(ele => {
        if (isEqual(ele, 'state') && isEmpty(basicDetails[ele])) errorData['state'] = 'Required';
        else if (isEqual(ele, 'name') && isEmpty(basicDetails[ele])) errorData['name'] = 'Required';
        else if (isEqual(ele, 'name') && !isEmpty(basicDetails['name']) && !isUtilityNameUnique) {
          errorData['name'] = 'Utility already exists';
        } else omittedFields.push(ele);
      });
    }
    // All Other Details section validations goes here
    if (section === 'otherDetails' || section === 'all') {
      const otherDataValidationField = 'gas_meter_clearance';
      if (!inRange(otherDetails[otherDataValidationField], 0, 1001))
        errorData[otherDataValidationField] = 'Value should be between 0-1000';
      else omittedFields.push(otherDataValidationField);
    }
    const newErrorData = omit(errorData, omittedFields);
    console.log('newErrorData...', newErrorData);
    console.log('omittedFields...', omittedFields);
    return section === 'all' ? this.setState({ errorData: newErrorData }) : newErrorData;
  };

  handleCancel() {
    history.push('/utility');
  }

  handleUtilityList = (remainingAPICalls = 0, prevPageStart = 0, state) => {
    if (remainingAPICalls > 0) {
      const pageStart = prevPageStart + 100;
      this.props.getUtilityByGenability({
        pageCount: 100,
        pageStart: pageStart,
        search: state,
        successCb: () => {
          this.setState(
            prevState => ({
              utilityList: [ ...prevState.utilityList, ...this.props.utilityByGenability ]
            }),
            () => {
              this.handleUtilityList(remainingAPICalls - 1, pageStart, state);
            }
          );
        }
      });
    } else return;
  };

  handleBasicDetails = async (e, key, value) => {
    const basicDetails = this.state.basicDetails;
    basicDetails[key] = value;
    if (isEqual(key, 'state')) {
      const stateAbbreviation = statesAbbr[value];
      this.props.getUtilityByGenability({
        pageCount: 100,
        pageStart: 0,
        search: stateAbbreviation,
        successCb: () => {
          basicDetails.name = '';
          this.setState({ utilityList: this.props.utilityByGenability }, () => {
            this.handleUtilityList(Math.floor(this.props.utilityCount / 100), 0, stateAbbreviation);
          });
        }
      });
    } else if (isEqual(key, 'name')) {
      const utilityName = basicDetails.name;
      const state = basicDetails.state;
      this.setState({ basicDetails }, () => {
        this.props.getUtilityNameValidation({
          utilityName,
          state,
          successCb: result => {
            this.setState({ isUtilityNameUnique: result }, () => {
              this.validateFields('all');
            });
          }
        });
      });
    }
    const errorData = await this.validateFields('basic_details');
    this.setState({ ...this.state, basicDetails, errorData });
  };

  handleReferenceInformation = (e, key) => {
    const { referenceInformation } = this.state;
    referenceInformation[key] = e.target.value;
    const errorData = this.validateFields('referenceInformation');
    this.setState({ ...this.state, referenceInformation, errorData });
  };

  handleUtilityNotes = (e, key = false) => {
    const { utilityNotes } = this.state;
    utilityNotes[key] = e.target.value;
    const errorData = this.validateFields('notes');
    this.setState({ ...this.state, utilityNotes, errorData });
  };

  handleRequiredPlanNotes = (e, key = false) => {
    const { requiredPlanNotes } = this.state;
    requiredPlanNotes[key] = e.target.value;
    const errorData = this.validateFields('requiredPlanNotes');
    this.setState({ ...this.state, requiredPlanNotes, errorData });
  };

  handleOtherDetails = async (e, key) => {
    const otherDetails = this.state.otherDetails;
    const fieldValue = e.target.value;
    switch (get(e, 'target.type')) {
      case 'number':
        otherDetails[key] = isEmpty(fieldValue) ? null : toInteger(fieldValue);
        break;
      case 'checkbox':
        otherDetails[key] = e.target.checked;
        break;
      default:
        otherDetails[key] = fieldValue;
        break;
    }
    const errorData = await this.validateFields('otherDetails');
    this.setState({ ...this.state, otherDetails, errorData });
  };

  setDocumentUploadError(errorDetails = { error: false, msg: '' }, status) {
    this.setState({ referenceFilesUploadError: { error: errorDetails.error, msg: errorDetails.msg } });
    status && this.setState({ referenceFilesUploadStatus: status });
  }

  getFilePrefixName = basicDetails => {
    this.validateFields('basic_details');
    scrollIntoView(document.getElementById(keysIn(this.state.errorData)[0]));
    let filePrefixName = '';
    if (!isEmpty(basicDetails.state)) {
      filePrefixName = basicDetails.state;
    }
    if (!isEmpty(basicDetails.name)) {
      filePrefixName += '_' + basicDetails.name;
    }
    return filePrefixName;
  };

  deleteFile = (ind, cb) => {
    // clear the message
    this.setState({ referenceFilesUploadError: { error: false, msg: '' } });
    let reversedRefFiles = this.state.referenceInformation.reference_files.slice().reverse();
    const fileName = reversedRefFiles[ind].file_name;
    const methodType = 'DELETE';
    this.props.getS3URL({
      fileName,
      methodType,
      isPermitAuto: true,
      successCb: result => cb(result),
      failureCb: () => {}
    });
    reversedRefFiles.splice(ind, 1);
    const updatedRefInfo = {
      ...this.state.referenceInformation,
      reference_files: reversedRefFiles.reverse()
    };
    this.setState({ referenceInformation: updatedRefInfo });
  };

  handleFilesUpload = event => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' });

    let referenceFiles = this.state.referenceInformation.reference_files;

    const setStatus = status => {
      this.setState({ referenceFilesUploadStatus: status });
    };

    const setFilesSize = filesTotalSize => {
      this.setState({ referenceFilesSize: filesTotalSize });
    };

    const details = {
      event,
      setStatus,
      setFilesSize,
      documentType: 'referenceFiles',
      namePrefix: this.getFilePrefixName(this.state.basicDetails),
      uploadFiles: referenceFiles !== null ? referenceFiles : [],
      uploadFilesSize: this.state.referenceFilesSize,
      maxFileUploadSize: this.MAX_ALLOWED_FILE_SIZE,
      getS3URL: this.props.getS3URL,
      putToS3: this.props.putToS3,
      setDocumentUploadError: (errorDetails, status) => this.setDocumentUploadError(errorDetails, status)
    };
    if (!hasIn(this.state.errorData, 'state')) {
      uploadFileDetails(details, uploadFiles => {
        const { referenceInformation } = this.state;
        referenceInformation.reference_files = uploadFiles;
        this.setState({ referenceInformation });
      });
    }
  };

  handleFileDownload = file => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' });
    this.props.getS3URL({
      fileName: file.file_name,
      methodType: 'GET',
      isPermitAuto: true,
      successCb: preSignedS3Url => {
        window.open(preSignedS3Url, '_blank');
      },
      failureCb: () => {
        this.setState({
          referenceFilesUploadError: { error: true, msg: 'Unable to preview the file. Please try after some time.' }
        });
      }
    });
  };

  // Available Permit service Roles => :application_user, :application_admin, :db_user, :db_admin, :db_view, :service_admin, :no_access
  isButtonVisible(buttonName) {
    const { readOnly } = this.state;
    const { permitServiceRole, utility } = this.props;
    const { current_status = '' } = utility;
    let isVisible = false;
    const dbAdminWithPending =
      includes([ 'PENDING' ], current_status) && includes([ 'db_admin', 'service_admin' ], permitServiceRole);
    switch (buttonName) {
      case 'edit':
        isVisible = readOnly && !includes(VIEW_ONLY_ROLES, permitServiceRole);
        break;
      case 'approve':
      case 'reject':
        isVisible =
          includes([ 'PENDING' ], current_status) && includes([ 'db_admin', 'service_admin' ], permitServiceRole);
        break;
      case 'submit':
        isVisible =
          !dbAdminWithPending && !readOnly && includes([ 'db_admin', 'db_user', 'service_admin' ], permitServiceRole);
        break;
      case 'save':
        isVisible =
          includes([ null, '', 'INCOMPLETE' ], current_status) &&
          includes([ 'db_admin', 'db_user', 'service_admin' ], permitServiceRole);
        break;
      case 'outdated':
        isVisible =
          includes([ 'APPROVED' ], current_status) && includes([ 'db_admin', 'service_admin' ], permitServiceRole);
        break;
      default:
        break;
    }
    return isVisible;
  }

  isReadOnly() {
    const { permitServiceRole, utility } = this.props;
    const { current_status = '' } = utility;
    return (
      includes([ 'APPROVED', 'PENDING', 'OUTDATED' ], current_status) &&
      includes([ 'db_user', 'db_admin', 'service_admin' ], permitServiceRole)
    );
  }

  getErrorText = fieldName => {
    return this.state.recordSubmitted ? hasIn(this.state.errorData, fieldName) : false;
  };

  getHelperText = fieldName => {
    return this.state.recordSubmitted ? get(this.state.errorData, fieldName) : '';
  };

  render() {
    const { classes, currentlySending, messageType, message, genabilityRequestOn } = this.props;
    const {
      id,
      basicDetails,
      utilityList,
      referenceInformation,
      utilityNotes,
      requiredPlanNotes,
      otherDetails,
      currentStatus,
      commentsList,
      readOnly
    } = this.state;
    const { name = '', state = '' } = basicDetails;
    const pageHeaderText = isEqual(id, 'new') ? 'Add new Utility' : name || '';
    return (
      <Box className={classes.root}>
        <Box className={classes.header}>
          <PageHeader text={pageHeaderText} />
          <CommentPopupBox comments={commentsList} applicationStatus={currentStatus} />
        </Box>

        <BasicDetailsSection
          readOnly={readOnly}
          sectionLabel={'Basic Details'}
          errorData={this.state.errorData}
          basicDetails={basicDetails}
          utilityList={utilityList}
          loading={genabilityRequestOn}
          handleBasicDetails={this.handleBasicDetails}
        />

        <ReferenceInformationSectionOldUI
          readOnly={readOnly}
          errorData={this.state.errorData}
          basicDetails={this.state.basicDetails}
          getErrorText={this.getErrorText}
          sectionLabel={'Reference Information'}
          referenceInformation={referenceInformation}
          handleReferenceInformation={this.handleReferenceInformation}
          referenceFilesUploadError={this.state.referenceFilesUploadError}
          handleFilesUpload={e => this.handleFilesUpload(e)}
          handleFileDownload={this.handleFileDownload}
          fileIcon={this.fileIcon}
          deleteFile={this.deleteFile}
        />

        <NotesSectionOldUI
          readOnly={readOnly}
          handleNotes={this.handleUtilityNotes}
          notes={utilityNotes}
          stateSelected={!isEmpty(state)}
          sectionLabel={'Utility Notes'}
        />

        <RequiredPlanSetNotesSectionOldUI
          readOnly={readOnly}
          handleRequiredPlanNotes={this.handleRequiredPlanNotes}
          requiredPlanNotes={requiredPlanNotes}
          stateSelected={!isEmpty(state)}
          sectionLabel={'Required Plan-Set Utility Notes'}
        />

        <OtherDetailsSection
          readOnly={readOnly}
          errorData={this.state.errorData}
          getErrorText={this.getErrorText}
          getHelperText={this.getHelperText}
          handleOtherDetails={this.handleOtherDetails}
          otherDetails={otherDetails}
          stateSelected={!isEmpty(state)}
          sectionLabel={'Other Details'}
        />

        {!includes([ null, '', 'INCOMPLETE' ], currentStatus) && <RecordDetailsSectionOldUI recordDetails={basicDetails} />}

        <Box className={classes.actionButtons}>
          <Box>
            <Button id="companyCancel" variant="outlined" color="primary" onClick={() => this.handleCancel()}>
              {'Back'}
            </Button>
          </Box>
          <Box>
            {APPLICATION_STATE_TRANSITIONS.map(
              transition =>
                this.isButtonVisible(transition.key) && (
                  <Tooltip
                    key={'tooltip' + transition.key}
                    title="Any changes made by you will be lost"
                    arrow
                    disableHoverListener={transition.key !== 'reject'}
                    disableFocusListener={transition.key !== 'reject'}
                    disableTouchListener={transition.key !== 'reject'}
                    placement="top"
                  >
                    <Button
                      id={transition.key}
                      className={`${transition.key}Button`}
                      variant="contained"
                      color="primary"
                      disabled={!readOnly && transition.key === 'outdated'}
                      onClick={() => this.handleSubmit(transition.value)}
                      key={transition.key}
                      style={{ marginLeft: 4, color: 'white' }}
                    >
                      {transition.title}
                    </Button>
                  </Tooltip>
                )
            )}
            <CommentDialog
              open={this.state.openCommentDialog}
              onClose={this.handleCommentDialogClose}
              dialogHeader={`${this.commentDialogHeader} Record`}
              secondaryHeaderText={`Do you want to ${this.commentDialogSecondaryHeader} this record?`}
              additionalSecondaryHeaderText={this.event === 'REJECTED' && REJECT_DIALOG_WARNING}
              handleProceed={this.handleProceed}
              setComment={e => this.handleAddComment(e)}
            />
          </Box>
        </Box>
        {(genabilityRequestOn || currentlySending || this.state.referenceFilesUploadStatus !== 'idle') && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {message && messageType === 'success' && <SnakBar message={message} severity={messageType} />}
        {message && messageType === 'error' && <SnakBar message={message} severity={messageType} />}
      </Box>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  putToS3: payload => dispatch(putToS3(payload)),
  getS3URL: payload => dispatch(getS3URL(payload)),
  getUtility: payload => dispatch(getUtility(payload)),
  createUtility: payload => dispatch(createUtility(payload)),
  getUtilityByGenability: payload => dispatch(getUtilityByGenability(payload)),
  getUtilityNameValidation: payload => dispatch(getUtilityNameValidation(payload))
});

const mapStateToProps = state => ({
  message: state.adminReducer.message,
  utility: state.adminReducer.utility,
  messageType: state.adminReducer.messageType,
  utilityCount: state.adminReducer.utilityCount,
  permitServiceRole: state.appReducer.permitServiceRole,
  currentlySending: state.adminReducer.currentlySending,
  utilityByGenability: state.adminReducer.utilityByGenability,
  genabilityRequestOn: state.adminReducer.genabilityRequestOn
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
