import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Grid } from '@material-ui/core';
import { get } from 'lodash';
import { capitalizeFirstLetter } from '../../../utils/helper';
import { SOURCE } from '../../../utils/constants';
import { getSource } from '../../../containers/Admin/RequestDetails/helper';
import settings from '../../../utils/settings'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold',
    overflowWrap: 'break-word'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px',
    cursor: 'unset !important'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray !important'
  },
  dataSubHeader: {
    fontSize: '16px',
    color: 'grey'
  }
});

export class ApplicantDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes, appDetails, accountManagerInfo, totalEnergyCapacity } = this.props;
    const purchased_services = get(appDetails, 'purchased_service_names') || [];
    const purchased_rework_services = get(appDetails, 'purchased_rework_service_names') || [];
    const allServices = [ ...purchased_services, ...purchased_rework_services ];
    const projectType = get(appDetails, 'project_details.project_type', '-');
    const source = getSource(appDetails);

    return (
      <Fragment>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography className={classes.dataSubHeader}>Homeowner Name</Typography>
            <Typography className={classes.fontBold}>
              {get(appDetails, 'project_details.customer_name') || '--'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography className={classes.dataSubHeader}>City</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'project_details.city') || '--'}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography className={classes.dataSubHeader}>State</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'project_details.state') || '--'}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography className={classes.dataSubHeader}>Installer Company (code)</Typography>
            <Typography className={classes.fontBold}>
              {(get(appDetails, 'installer_name') || get(appDetails, 'site_details.installer_name') || 'NA') +
                (' (' +
                  (get(appDetails, 'installer_id') || get(appDetails, 'site_details.installer_id') || '--') +
                  ')')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography className={classes.dataSubHeader}>Site Address</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'project_details.full_address') || '--'}</Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography className={classes.dataSubHeader}>System Size (solar)</Typography>
            <Typography className={classes.fontBold}>
              {get(appDetails, 'project_details.solar_system_size') &&
                appDetails.project_details.solar_system_size + ' kW'}
              {!get(appDetails, 'project_details.solar_system_size') && '--'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography className={classes.dataSubHeader}>Storage Size</Typography>
            {source === SOURCE.ENLIGHTEN_PERMIT &&
              <Typography className={classes.fontBold}>
                {get(appDetails, 'project_details.storage_size') && appDetails.project_details.storage_size + ' kWh'}
                {!get(appDetails, 'project_details.storage_size') && '--'}
              </Typography>}
            {source === SOURCE.SOLARGRAF && <Typography className={classes.fontBold}>
              {totalEnergyCapacity ? totalEnergyCapacity + ' kWh' : '--'}
            </Typography>}

          </Grid>
          <Grid item xs={12} md={3}>
            <Typography className={classes.dataSubHeader}>Utility Name</Typography>
            <Typography className={classes.fontBold}>
              {get(appDetails, 'project_details.utility_provider') || '--'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography className={classes.dataSubHeader}>Installer Name</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'installer_user_name') || '--'}</Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography className={classes.dataSubHeader}>Installer Email</Typography>
            <Typography className={classes.fontBold}>{get(appDetails, 'email') || '--'}</Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography className={classes.dataSubHeader}>Required Services</Typography>
            <Typography className={classes.fontBold}>{allServices.join(', ')}</Typography>
          </Grid>
          {source === SOURCE.ENLIGHTEN_PERMIT && (
            <Fragment>
              <Grid item xs={12} md={2}>
                <Typography className={classes.dataSubHeader}>Account Manager Name</Typography>
                <Typography className={classes.fontBold}>{get(accountManagerInfo, 'name', '-')}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography className={classes.dataSubHeader}>Account Manger Email</Typography>
                <Typography className={classes.fontBold}>{get(accountManagerInfo, 'email', '-')}</Typography>
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12} md={2}>
            <Typography className={classes.dataSubHeader}>Project Type</Typography>
            <Typography className={classes.fontBold}>
              {projectType ? projectType.charAt(0) + projectType.slice(1).toLowerCase() : 'NA'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography className={classes.dataSubHeader}>Source</Typography>
            <Typography className={classes.fontBold}>{capitalizeFirstLetter(source === SOURCE.SOLARGRAF ? SOURCE.SOLARGRAF : 'ENLIGHTEN')}</Typography>
          </Grid>
          {source === SOURCE.SOLARGRAF && (
            <Grid item xs={12} md={4}>
              <Typography className={classes.dataSubHeader}>Solargraf Permit Id</Typography>
              <Typography className={classes.fontBold}>
                {get(appDetails, 'sg_attributes.sg_project_id_permit') ?
                  <a href={settings.getSolargrafUrl(`projects/${get(appDetails, 'sg_attributes.sg_project_id_permit')}/permit`)} target="_blank">
                    {get(appDetails, 'sg_attributes.permit_id')}
                  </a> : get(appDetails, 'sg_attributes.permit_id')}
              </Typography>
            </Grid>
          )}
          {source === SOURCE.SOLARGRAF && (
            <Grid item xs={12} md={4}>
                <Typography className={classes.dataSubHeader}>Solargraf Permit Type</Typography>
                <Typography className={classes.fontBold}>
                  {get(appDetails, 'permit_only_project') == true ? 'Permit without SG Design' : "-"}
                </Typography>
              </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicantDetailsView));
