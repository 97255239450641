import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Paper,
  IconButton,
  Typography,
  Link
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isEqual, isEmpty, get } from 'lodash';
import { postTemplate, getTemplates } from '../../../containers/Permitting/action';
import PageHeader from '../../common/PageHeader';
import FeedbackDialog from '../FeedbackDialog';
import { STORE_PAYMENT_ERROR_MESSAGE } from '../../../containers/Permitting/constants';

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.white
  },
  buttonWrapper: {
    justifyContent: 'start',
    marginBottom: theme.spacing(1.5),
    fontSize: 15
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  closeButton: {
    padding: 0
  },
  dialogContentWrapper: {
    border: '1px solid #7070704d',
    margin: theme.spacing(0, 2.5, 2.5),
    padding: theme.spacing(1)
  },
  congratsText: {
    color: '#6E9D03DE',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  infoText: {
    color: '#484848DE',
    fontSize: 15,
    marginBottom: theme.spacing(2)
  },
  applicationId: {
    color: '#484848DE',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: theme.spacing(5)
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  paperStyle: {
    backgroundColor: '#F5F5F5',
    padding: theme.spacing(2)
  },
  colorText: {
    color: '#2680EB',
    cursor: 'pointer'
  },
  skipButton: {
    color: '#919191'
  },
  rightPadding: {
    paddingRight: theme.spacing(1)
  },
  link: {
    color: '#008EEF',
    cursor: 'pointer'
  }
}));

function SuccessDialog(props) {
  const classes = useStyles();
  const {
    showDialog,
    toggleDialog,
    permitId,
    handleReset,
    resetData,
    dialogStatus,
    purchasedServices,
    selectedServices,
    selectedTemplate,
    materialDetails,
    postTemplate,
    projectDetails,
    applicationDetail,
    paymentType = ''
  } = props;

  const currSelectedValues = {
    roof_type: materialDetails.roof_type,
    panel_manufacturer: materialDetails.panel_manufacturer,
    panel_model: materialDetails.panel_model,
    interconnection_strategy: get(materialDetails.interconnection_properties, 'interconnection_strategy', ''),
    interconnection_location: get(materialDetails.interconnection_properties, 'interconnection_location', ''),
    //interconnection_strategy_enpower: materialDetails.interconnection_properties.interconnection_strategy_enpower,
    racking_manufacturer: get(materialDetails.racking_attachment_properties, 'racking_manufacturer', ''),
    attachment_manufacturer: get(materialDetails.racking_attachment_properties, 'attachment_manufacturer', ''),
    racking_model: get(materialDetails.racking_attachment_properties, 'racking_model', ''),
    attachment_model: get(materialDetails.racking_attachment_properties, 'attachment_model', ''),
    attachment_type: get(materialDetails.racking_attachment_properties, 'attachment_type', ''),
    max_spacing_between_attachments: get(materialDetails.racking_attachment_properties, 'attachment_spacing', '')
  };

  let currSelectedTemplate = {};
  if (!isEmpty(selectedTemplate)) {
    currSelectedTemplate = {
      roof_type: selectedTemplate.roof_type,
      panel_manufacturer: selectedTemplate.panel_manufacturer,
      panel_model: selectedTemplate.panel_model,
      interconnection_strategy: selectedTemplate.interconnection_strategy,
      interconnection_location: selectedTemplate.interconnection_location,
      //interconnection_strategy_enpower: selectedTemplate.interconnection_strategy_enpower,
      racking_manufacturer: selectedTemplate.racking_manufacturer,
      attachment_manufacturer: selectedTemplate.attachment_manufacturer,
      racking_model: selectedTemplate.racking_model,
      attachment_model: selectedTemplate.attachment_model,
      attachment_type: selectedTemplate.attachment_type,
      max_spacing_between_attachments: selectedTemplate.max_spacing_between_attachments
    };
  }
  const isExistingSolarPanels = get(projectDetails, 'preexisting_solar_permit', '') === 'existing';
  const paymentDetails = get(applicationDetail, 'payment_details', []) || [];
  const newTemplateRequired =
    !isExistingSolarPanels && !isEqual(currSelectedTemplate, currSelectedValues) && paymentDetails.length <= 1 && (dialogStatus === 'success');
  const [ templateName, setTemplateName ] = useState('');
  const [ emptyTemplateName, setEmptyTemplateName ] = useState(true);
  const [ templateNameLengthError, setTemplateNameLengthError ] = useState(false);
  const [ saveButtonClicked, setSaveButtonClicked ] = useState(false);
  const [ showFeedbackDialog, setFeedbackDialog ] = useState(false);
  const isAddOnServicesPurchase = paymentDetails.length > 1;
  const applicationStatus = get(applicationDetail, 'installer_current_status', {});
  let header = '';
  let bodyText = '';
  if (dialogStatus === 'success') {
    if (paymentType === 'rework') {
      header = 'Rework Request Submitted';
      bodyText = 'Congratulations! Your Re-work request has been submitted.'
    } else if (isAddOnServicesPurchase || paymentType === 'storage') {
      header = 'Request Submitted';
      bodyText = 'Congratulations! Your request for additional services has been submitted.';
    } else {
      header = 'Application Submitted';
      bodyText = 'Congrats! Your application has been submitted.';
    }
  } else {
    header = 'Payment Failed'
  }
  const onSuccessClick = () => {
    toggleDialog(false, '');
    if (!isAddOnServicesPurchase) {
      setFeedbackDialog(true);
    } else {
      resetData();
      handleReset();
    }
  };
  const onFailureClick = () => {
    toggleDialog(false, '');
    handleReset();
  };

  const currValues = {
    name: templateName,
    roof_type: materialDetails.roof_type,
    panel_manufacturer: materialDetails.panel_manufacturer,
    panel_model: materialDetails.panel_model,
    interconnection_strategy: get(materialDetails.interconnection_properties, 'interconnection_strategy', ''),
    interconnection_location: get(materialDetails.interconnection_properties, 'interconnection_location', ''),
    //interconnection_strategy_enpower: materialDetails.interconnection_properties.interconnection_strategy_enpower,
    racking_manufacturer: get(materialDetails.racking_attachment_properties, 'racking_manufacturer', ''),
    attachment_manufacturer: get(materialDetails.racking_attachment_properties, 'attachment_manufacturer', ''),
    racking_model: get(materialDetails.racking_attachment_properties, 'racking_model', ''),
    attachment_model: get(materialDetails.racking_attachment_properties, 'attachment_model', ''),
    attachment_type: get(materialDetails.racking_attachment_properties, 'attachment_type', ''),
    max_spacing_between_attachments: get(materialDetails.racking_attachment_properties, 'attachment_spacing', ''),
    is_selected: false
  };

  const onSaveButtonClick = () => {
    setSaveButtonClicked(true);
    if (!(emptyTemplateName || templateNameLengthError)) {
      createNewTemplate();
      toggleDialog(false, '');
      if (!isAddOnServicesPurchase) {
        setFeedbackDialog(true);
      } else {
        resetData();
        handleReset();
      }
    }
  };

  const createNewTemplate = () => {
    postTemplate({
      template: currValues,
      successCbk: () => getTemplates()
    });
  };

  const onSkipButtonClick = () => {
    toggleDialog(false, '');
    if (!isAddOnServicesPurchase && dialogStatus === 'success') {
      setFeedbackDialog(true);
    } else {
      resetData();
      handleReset();
    }
  };

  const onButtonClick = dialogStatus === 'success' ? onSuccessClick : onFailureClick;
  const isUpdated = isAddOnServicesPurchase ? 'updated' : '';
  let numberofDays = selectedServices.includes('Physical Stamps') ? '4 business days.' : '2 business days.';
  if (isAddOnServicesPurchase && selectedServices.length === 2 && selectedServices.includes('Structural Review')) {
    numberofDays = '1 business day.';
  }

  const templateNameChange = e => {
    if (e.target.value === '') {
      setEmptyTemplateName(true);
    } else {
      setEmptyTemplateName(false);
    }

    if (e.target.value.length > 50) {
      setTemplateNameLengthError(true);
    } else {
      setTemplateNameLengthError(false);
    }

    setTemplateName(e.target.value);
  };

  return (
    <div>
      {showDialog && !isEmpty(dialogStatus) && (
        <Dialog
          open={showDialog && !isEmpty(dialogStatus)}
          onClose={() => toggleDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className={classes.dialogHeader} disableTypography>
            <PageHeader text={header} />
            <IconButton aria-label="close" className={classes.closeButton} onClick={onSkipButtonClick}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContentWrapper}>
            {dialogStatus === 'success' ? (
              <Fragment>
                <Fragment>
                  <DialogContentText className={classes.congratsText}>{bodyText}</DialogContentText>
                  <DialogContentText className={classes.infoText}>
                    {`Our team will verify the details and share the ${isUpdated} permit package within ${numberofDays}`}
                  </DialogContentText>
                  <Typography className={classes.applicationId}>{`Request Id: ${permitId}`}</Typography>
                </Fragment>
                {newTemplateRequired && (
                  <Fragment>
                    <Paper className={classes.paperStyle} variant="outlined">
                      <DialogContentText>
                        We have stored your application details in a template which can be used to prefill your future
                        permit applications. If you want to save the template, please provide the template name.
                      </DialogContentText>
                      <br />
                      <TextField
                        error={(emptyTemplateName || templateNameLengthError) && saveButtonClicked}
                        id="outlined-error"
                        label="Template Name"
                        value={templateName}
                        onChange={e => {
                          templateNameChange(e);
                        }}
                        helperText={
                          (emptyTemplateName && saveButtonClicked && 'Please provide a template name. ') ||
                          (templateNameLengthError &&
                            saveButtonClicked &&
                            'The template name should not have more than 50 characters. ')
                        }
                      />
                      <br />
                    </Paper>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <DialogContentText className={classes.infoText}>
                {STORE_PAYMENT_ERROR_MESSAGE}{' '}
                <Link onClick={() => {
                  window.open('https://support.enphase.com/s/contact-us', '_blank');
                  toggleDialog(false);
                }} className={classes.link}>Enphase Customer support</Link>
              </DialogContentText>
            )}
            <DialogActions className={classes.buttonWrapper}>
              {!newTemplateRequired && (
                <Button
                  onClick={onButtonClick}
                  color="primary"
                  variant="contained"
                  disableElevation
                  className={classes.button}
                >
                  Ok
                </Button>
              )}
              {newTemplateRequired && (
                <Fragment>
                  <div className={classes.displayFlex}>
                    <div className={classes.rightPadding}>
                      <Button className={classes.skipButton} onClick={onSkipButtonClick} disableElevation>
                        Skip
                      </Button>
                    </div>
                    <Button
                      onClick={onSaveButtonClick}
                      color="primary"
                      variant="contained"
                      disableElevation
                      className={classes.button}
                    >
                      Save
                    </Button>
                  </div>
                </Fragment>
              )}
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
      {showFeedbackDialog && (
        <FeedbackDialog
          isOpen={showFeedbackDialog}
          content="On a scale of 0 to 10, how likely are you to recommend our Permit Assistance Service to a friend or colleague?"
          resetData={resetData}
          handleReset={handleReset}
          isFrom="Payment Success"
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  purchasedServices: state.permitReducer.purchasedServices,
  selectedServices: state.permitReducer.selectedServices,
  selectedTemplate: state.permitReducer.selectedTemplate,
  materialDetails: state.permitReducer.materialDetails,
  templates: state.permitReducer.templates,
  projectDetails: state.permitReducer.projectDetails,
  applicationDetail: state.permitReducer.applicationDetail
});
const mapDispatchToProps = dispatch => ({
  postTemplate: payload => dispatch(postTemplate(payload)),
  getTemplates: () => dispatch(getTemplates())
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessDialog);
