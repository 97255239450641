import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { get, isEmpty, isEqual, isNaN, split, startCase } from 'lodash';
import { SOURCE, DOWNLOAD_DOCUMENTS_FILE_NAMES, DOWNLOAD_FILE_NAME_FLAG } from '../../../utils/constants';
import { getSource } from '../../../containers/Admin/RequestDetails/helper';
import { handlePopupBlocker, formatString, downloadFileSG } from '../../../utils/helper';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { LightTooltip } from '../../Admin/applicationView/projectDetailsView';
import Icons from '../../Admin/applicationView/images';
import ViewIcon from '../../Admin/applicationView/images/viewIcon';
import { TagLabel } from '../TagLabel';
import imgIcon from '../../Admin/applicationView/images/img-icon.png';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  fontBold: {
    fontWeight: 'bold',
    overflowWrap: 'anywhere',
    fontSize: '14px',
  },
  dataHeader: {
    color: '#246AB0',
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px',
    height: '40px',
    cursor: 'unset',
  },
  panelDetails: {
    flexDirection: 'column',
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0,
  },
  headingInfo: {
    color: 'darkgray',
  },
  dataSubHeader: {
    fontSize: '12px',
    color: 'grey',
    display: 'flex',
  },
  imageWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  iconHolder: {
    display: 'inline-flex',
    marginRight: theme.spacing(1),
    flexDirection: 'column',
    width: '123px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0.5, 1),
    },
  },
  imgHolder: {
    zIndex: '1',
    margin: theme.spacing(1),
  },

  image: {
    margin: 'auto',
    display: 'block',
    maxWidth: theme.spacing(15),
    maxHeight: theme.spacing(10),
  },
  downloadIcon: {
    left: '86%',
    position: 'absolute',
  },
  updatedLabel: {
    background: '#FF7141 0% 0% no-repeat padding-box',
    letterSpacing: '0 !important',
    lineHeight: '0 !important',
    color: '#ffffff',
    textTransform: 'uppercase',
    height: theme.spacing(2.0),
    fontSize: '10px !important',
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    whiteSpace: 'nowrap',
  },
});

export const useLabelStyles = makeStyles(styles);

export const DisplayGrid = (props) => {
  const classes = useLabelStyles();
  const {
    appDetails,
    item,
    params,
    title,
    handleUpdated,
    downloadFile,
    originalApplicationDetails,
    stateApplicationDetails,
    applicationDetail,  //used only for installer Details
  } = props;
  const purchased_services = get(appDetails, 'purchased_service_names') || [];
  const purchased_rework_services = get(appDetails, 'purchased_rework_service_names') || [];
  const allServices = [...purchased_services, ...purchased_rework_services];
  const systemTypes = 'project_details.system_types';
  const storageList = 'material_details.storage_list';
  const mspUpgradeSelected = 'material_details.electrical_properties.msp_upgrade';
  const [state, setState] = React.useState({ showSheetToolTip: false, showAttachmentSheetToolTip: false });
  const splitOtherValues = (value) => {
    let otherValue = !isEmpty(value) && value.startsWith('Other-') ? split(value, '-', 2)[1] : value;
    return otherValue;
  };

  const getStorageDiff = (key) => {
    const { appDetails, item, originalApplicationDetails, stateApplicationDetails } = props;
    const storageValue =
      !isEmpty(get(appDetails, key)) &&
      get(appDetails, key).map((row) => {
        return row.name == 'Storage' ? (row.value ? 'True' : 'False') : '';
      });
    const orgStorageValue =
      !isEmpty(get(originalApplicationDetails, key)) &&
      get(originalApplicationDetails, key).map((row) => {
        return row.name == 'Storage' ? (row.value ? 'True' : 'False') : '';
      });
    return isEqual(storageValue, orgStorageValue);
  };

  const calculateEnergyAndPower = (fieldValue) => {
    const { originalApplicationDetails, stateApplicationDetails } = props;
    let totalEnergyCapacity = 0;
    let totalPowerCapacity = 0;
    if (getSource(stateApplicationDetails) === SOURCE.SOLARGRAF) {
      const sysTypes = get(originalApplicationDetails, systemTypes);
      if (!isEmpty(sysTypes) && sysTypes.find((data) => data.name === 'Storage')?.value) {
        const batteryList = get(originalApplicationDetails, storageList);
        if (!isEmpty(batteryList)) {
          batteryList.forEach((battery) => {
            totalEnergyCapacity += battery.quantity * battery.capacity;
            totalPowerCapacity += battery.quantity * battery.power_capacity;
          });
          totalEnergyCapacity = totalEnergyCapacity ? Math.round(totalEnergyCapacity * 100) / 100 : 0;
          totalPowerCapacity = totalPowerCapacity ? Math.round(totalPowerCapacity * 100) / 100 : 0;
        }
      }
    }
    if (fieldValue === 'totalEnergyCapacity') {
      return totalEnergyCapacity;
    } else {
      return totalPowerCapacity;
    }
  };

  const checkIfLabelVisible = (isNewPanel) => {
    const currentMSP = get(appDetails, mspUpgradeSelected);
    const initialMSP = get(originalApplicationDetails, mspUpgradeSelected);
    if (isEqual(currentMSP, initialMSP)) {
      if (isNewPanel && currentMSP) {
        return true;
      } else if (!isNewPanel && !currentMSP) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  };

  const getMSPFieldsDiff = (type, currentMSP, key) => {
    if (type === 'solar_ready' && currentMSP) {
      return <TagLabel add  applicationDetail={appDetails}/>;
    }
    if (currentMSP && get(originalApplicationDetails, key)) {
      // If MSP Upgrade toggled to Yes
      if (type === 'msp_rating_new') {
        return <TagLabel add  applicationDetail={appDetails}/>;
      } else {
        return <TagLabel edit applicationDetail={appDetails}/>;
      }
    } else if (!currentMSP && get(originalApplicationDetails, key)) {
      // If MSP Upgrade toggled to No
      if (type === 'msp_rating_new') {
        return <TagLabel edit applicationDetail={appDetails}/>;
      } else {
        return <TagLabel add  applicationDetail={appDetails}/>;
      }
    }
  };

  const handleUpdatedFunction = (key = '', SGValue = false, type = '') => {
    const { appDetails, item, originalApplicationDetails, stateApplicationDetails } = props;

    const currentMSP = get(appDetails, mspUpgradeSelected);
    const initialMSP = get(originalApplicationDetails, mspUpgradeSelected);

    if (key === '') {
      if (SGValue && getSource(stateApplicationDetails) === SOURCE.SOLARGRAF) {
        key = item.SGid;
      } else {
        key = item.id;
      }
    }
    if (item.showModified && type === ' Storage') {
      if (getStorageDiff(key)) {
        return <TagLabel edit applicationDetail={appDetails}/>;
      }
    } else if (
      item.showModified &&
      (type.startsWith('msp_rating_') || type === 'solar_ready') &&
      !isEqual(currentMSP, initialMSP)
    ) {
      return getMSPFieldsDiff(type, currentMSP, key);
    } else if (item.showModified && !isEqual(get(appDetails, key), get(originalApplicationDetails, key))) {
      if (!isEmpty(get(originalApplicationDetails, key)) || type === 'msp_upgrade' || (initialMSP && type === 'solar_ready')) {
        return <TagLabel edit applicationDetail={appDetails}/>;
      } else {
        return <TagLabel add  applicationDetail={appDetails}/>;
      }
    }
  };

  const handleUpdateFunctionInstaller = ( key, SGValue = false ) => {
    const { item, originalApplicationDetails,applicationDetail,stateApplicationDetails } = props;

    if (key === '') {
      if (SGValue && getSource(stateApplicationDetails) === SOURCE.SOLARGRAF) {
        key = item.SGid;
      } else {
        key = item.id;
      }
    }

    if (item.showModified && !isEqual(get(applicationDetail, key), get(originalApplicationDetails, key))) {
      if (get(originalApplicationDetails, key)) {
        return <TagLabel edit applicationDetail={applicationDetail}/>;
      } else {
        return <TagLabel add  applicationDetail={applicationDetail}/>;
      }
    }
  }
  const download = (file, downloadFileName) => {
    const { appDetails, downloadFile, stateApplicationDetails } = props;
    if (getSource(stateApplicationDetails) === SOURCE.SOLARGRAF) {
      downloadFileSG(file, downloadFileName);
    } else {
      downloadFile(file[0].file_name);
    }
  };

  const downloadLogo = (linkUrl, fileName) => {
    if(DOWNLOAD_FILE_NAME_FLAG && getSource(stateApplicationDetails) === SOURCE.SOLARGRAF)
    {
      downloadFileSG(linkUrl, fileName);
    } else {
      downloadFileENL(linkUrl, fileName);
    }
  };

  const downloadFileENL = (fileName, downloadFileName) => {
    const myWindow = window.open(fileName, '_blank');
    handlePopupBlocker(myWindow);
  };

  const fileIcon = (fName) => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
      fType = 'document';
    }
    const Icon = Icons[fType];
    return <Icon />;
  };

  switch (item.type) {
    case 'text':
      return (
        <Grid item xs={12} md={3}>
          <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
            {item.label} {handleUpdatedFunction(item.id)}
          </Typography>
          <Typography className={classes.fontBold}>
            {item.id === 'project_details.system_type_v2.name'
              ? startCase(get(appDetails, item.id)) || '--'
              : get(appDetails, item.id) || '--'}
          </Typography>
        </Grid>
      );
    case 'textInstaller':
      return (
        <Grid item xs={12} md={3}>
          <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
            {item.label} {handleUpdateFunctionInstaller(item.id)}
          </Typography>
          <Typography className={classes.fontBold}>
              { get(applicationDetail, item.id) || '--'}
          </Typography>
        </Grid>
      );
    case 'PhoneNumber':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label} {handleUpdateFunctionInstaller('', true)}
          </Typography>
          <Typography className={classes.fontBold}>
            {getSource(stateApplicationDetails) === SOURCE.ENLIGHTEN_PERMIT
              ? get(applicationDetail, item.id)
              : get(applicationDetail, item.SGid) || '--'}
          </Typography>
        </Grid>
      );
    case 'otherText':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label} {handleUpdatedFunction(item.id)}
          </Typography>
          <Typography className={classes.fontBold}>{splitOtherValues(get(appDetails, item.id)) || '--'}</Typography>
        </Grid>
      );
    case 'otherTextWithModel':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label} {handleUpdatedFunction(item.id)}
          </Typography>
          <Typography className={classes.fontBold}>
            {splitOtherValues(get(appDetails, item.id)) || '--'}
            {!isEmpty(get(appDetails, item.attachmentId)) ? (
              <LightTooltip title="View Spec Sheet" open={state.showSheetToolTip} placement="right">
                <IconButton
                  onClick={() => download(get(appDetails, item.attachmentId), formatString(
                    splitOtherValues(get(appDetails, item.manufacturer)) + "_" + splitOtherValues(get(appDetails, item.id))))}
                  onMouseEnter={() => setState({ ...state, showSheetToolTip: true })}
                  onMouseLeave={() => setState({ ...state, showSheetToolTip: false })}
                  aria-label="info"
                  className={classes.iconButton}
                >
                  <ViewIcon />
                </IconButton>
              </LightTooltip>
            ) : null}
          </Typography>
        </Grid>
      );
    case 'textLabel':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label} {handleUpdatedFunction(item.id)}
          </Typography>
          <Typography className={classes.fontBold}>
            {get(appDetails, item.id) || '--'}
            {item.valueLabel}
          </Typography>
        </Grid>
      );
    case 'boolean':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label} {handleUpdatedFunction(item.id)}
          </Typography>
          <Typography className={classes.fontBold}>
            {get(appDetails, item.id) !== null ? (get(appDetails, item.id) === true ? 'Yes' : 'No') : '--'}
          </Typography>
        </Grid>
      );
    case 'installerCode':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label}
            {handleUpdatedFunction(item.id)}
          </Typography>
          <Typography className={classes.fontBold}>
            {(get(appDetails, 'installer_name') || get(appDetails, 'site_details.installer_name') || 'NA') +
              (' (' + (get(appDetails, 'installer_id') || get(appDetails, 'site_details.installer_id') || '--') + ')')}
          </Typography>
        </Grid>
      );
    case 'logo':
      return (
        <Grid item xs={12} md={2}>
          <Typography className={classes.dataSubHeader}>{item.label}</Typography>
          <Box className={classes.imageWrapper} key={'photoSmall'}>
            {(get(appDetails, item.sgAttribute) && getSource(stateApplicationDetails) === SOURCE.SOLARGRAF) ||
            (get(appDetails, item.id) && getSource(stateApplicationDetails) === SOURCE.ENLIGHTEN_PERMIT) ? (
              <Box className={clsx(classes.imgHolder, classes.iconHolder)} key={`Photos--1`}>
                <Box>
                  <CloudDownloadOutlinedIcon
                    fontSize="small"
                    className={classes.downloadIcon}
                    onClick={() => {const url = getSource(stateApplicationDetails) === SOURCE.SOLARGRAF
                      ? get(appDetails, item.sgAttribute)
                      : getSource(stateApplicationDetails) === SOURCE.ENLIGHTEN_PERMIT
                      ? get(appDetails, item.id)
                      : '';
                      downloadLogo(url, DOWNLOAD_DOCUMENTS_FILE_NAMES.INSTALLLER_COMPANY_LOGO);
                    }}
                  />
                  <img
                    className={classes.image}
                    alt={'Image'}
                    src={
                      getSource(stateApplicationDetails) === SOURCE.SOLARGRAF
                        ? get(appDetails, item.sgAttribute)
                        : getSource(stateApplicationDetails) === SOURCE.ENLIGHTEN_PERMIT
                        ? get(appDetails, item.id)
                        : imgIcon
                    }
                  />
                </Box>
              </Box>
            ) : null}
          </Box>
        </Grid>
      );
    case 'services':
      const original_purchased_services = get(originalApplicationDetails, 'purchased_service_names') || [];
      const original_purchased_rework_services =
        get(originalApplicationDetails, 'purchased_rework_service_names') || [];
      const original_allServices = [...original_purchased_services, ...original_purchased_rework_services];
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label}
            {!isEqual(original_allServices, allServices) ? <TagLabel edit applicationDetail={appDetails}/> : null}
          </Typography>
          <Typography className={classes.fontBold}>{!isEmpty(allServices) ? allServices.join(', ') : '--'}</Typography>
        </Grid>
      );
    case 'physicalStamps':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label} {handleUpdatedFunction(item.id)}
          </Typography>
          <Typography className={classes.fontBold}>
            {(allServices.includes('Physical Stamps') && get(appDetails, item.id)) || '--'}
          </Typography>
        </Grid>
      );
    case 'systemType':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label} {handleUpdatedFunction('project_details.system_types', '', 'Storage')}
          </Typography>
          <Typography className={classes.fontBold}>
            {!isEmpty(appDetails.project_details.system_types) &&
              appDetails.project_details.system_types.map((row) => {
                return row.name == 'Storage' ? (row.value ? 'True' : 'False') : '';
              })}
          </Typography>
        </Grid>
      );
    case 'backUpselected':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label} {handleUpdatedFunction('project_details.home_backup_option', '', 'Storage')}
          </Typography>
          <Typography className={classes.fontBold}>
            {appDetails.project_details.system_types.map((row) => {
              return row.name == 'Storage'
                ? row.value
                  ? appDetails.project_details.home_backup_option !== null
                    ? appDetails.project_details.home_backup_option === 'full' ? 'Whole Backup'
                      : appDetails.project_details.home_backup_option === 'gridTied' ? 'Grid Tied'
                      : appDetails.project_details.home_backup_option === 'partial' ? 'Partial Backup' : "--"
                    : '--'
                  : '--'
                : '';
            })}
          </Typography>
        </Grid>
      );
    case 'MSP_Upgrade':
      return (
        <Fragment>
          <Grid item xs={12} md={3}>
            <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
              MSP Upgrade {handleUpdatedFunction(mspUpgradeSelected, '', 'msp_upgrade')}
            </Typography>
            <Typography className={classes.fontBold}>
              {get(appDetails, mspUpgradeSelected) != null
                ? get(appDetails, mspUpgradeSelected) === true
                  ? 'Yes'
                  : 'No'
                : '--'}
            </Typography>
          </Grid>
          <Fragment>
            {get(appDetails, mspUpgradeSelected) ? (
              <Grid item xs={12} md={3}>
                <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
                  Solar Ready{' '}
                  {handleUpdatedFunction('material_details.electrical_properties.solar_ready', '', 'solar_ready')}
                </Typography>
                <Typography className={classes.fontBold}>
                  {get(appDetails, 'material_details.electrical_properties.solar_ready') != null
                    ? get(appDetails, 'material_details.electrical_properties.solar_ready') === true
                      ? 'Yes'
                      : 'No'
                    : '--'}
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12} md={3}>
              <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
                Main Bus Rating (New Panel) (A){' '}
                {checkIfLabelVisible(true) &&
                  handleUpdatedFunction('material_details.electrical_properties.main_bus_rating', '', 'msp_rating_new')}
              </Typography>
              <Typography className={classes.fontBold}>
                {get(appDetails, mspUpgradeSelected)
                  ? splitOtherValues(get(appDetails, 'material_details.electrical_properties.main_bus_rating')) || '--'
                  : '--'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
                Main Breaker Rating (New Panel) (A){' '}
                {checkIfLabelVisible(true) &&
                  handleUpdatedFunction(
                    'material_details.electrical_properties.main_breaker_rating',
                    '',
                    'msp_rating_new'
                  )}
              </Typography>
              <Typography className={classes.fontBold}>
                {get(appDetails, mspUpgradeSelected)
                  ? splitOtherValues(get(appDetails, 'material_details.electrical_properties.main_breaker_rating')) ||
                    '--'
                  : '--'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
                Main Bus Rating (A){' '}
                {checkIfLabelVisible(false) &&
                  handleUpdatedFunction('material_details.electrical_properties.main_bus_rating', '', 'msp_rating_old')}
              </Typography>
              <Typography className={classes.fontBold}>
                {get(appDetails, mspUpgradeSelected)
                  ? '--'
                  : splitOtherValues(get(appDetails, 'material_details.electrical_properties.main_bus_rating')) || '--'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
                Main Breaker Rating (A){' '}
                {checkIfLabelVisible(false) &&
                  handleUpdatedFunction(
                    'material_details.electrical_properties.main_breaker_rating',
                    '',
                    'msp_rating_old'
                  )}
              </Typography>
              <Typography className={classes.fontBold}>
                {get(appDetails, mspUpgradeSelected)
                  ? '--'
                  : splitOtherValues(get(appDetails, 'material_details.electrical_properties.main_breaker_rating')) ||
                    '--'}
              </Typography>
            </Grid>
          </Fragment>
        </Fragment>
      );
    case 'ahj':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label}
            {handleUpdatedFunction(item.id)}
          </Typography>
          {props.ahjId ? (
            <Typography className={classes.fontBold}>
              <a href={`/ahj/${props.ahjId}`} target="_blank">
                {get(appDetails, item.id) || '--'}
              </a>
            </Typography>
          ) : (
            <Typography className={classes.fontBold}>{get(appDetails, item.id) || '--'}</Typography>
          )}
        </Grid>
      );
    case 'utility':
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label}
            {handleUpdatedFunction(item.id)}
          </Typography>
          {props.utilityProviderId ? (
            <Typography className={classes.fontBold}>
              <a href={`/utility/${props.utilityProviderId}`} target="_blank">
                {get(appDetails, item.id) || '--'}
              </a>
            </Typography>
          ) : (
            <Typography className={classes.fontBold}>{get(appDetails, item.id) || '--'}</Typography>
          )}
        </Grid>
      );
    case 'backupExistingSubpanel':
      return (
        <Grid item xs={12} md={3}>
          {get(appDetails, 'project_details.home_backup_option') === 'partial' ? (
            <Box>
              <Typography className={classes.dataSubHeader}>
                {item.label} {handleUpdatedFunction(item.id)}
              </Typography>
              <Typography className={classes.fontBold}>
                {get(appDetails, item.id) !== null ? (get(appDetails, item.id) === true ? 'Yes' : 'No') : '--'}
              </Typography>
            </Box>
          ) : (
            <Fragment />
          )}
        </Grid>
      );
    case 'additionalInfo':
      const comments = get(appDetails, item.id) ? get(appDetails, item.id).split('\n') : [];
      const Original_comments = get(originalApplicationDetails, item.id)
        ? get(originalApplicationDetails, item.id).split('\n')
        : [];
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label}
            {item.showModified && !isEqual(Original_comments, comments) ? <TagLabel edit applicationDetail={appDetails}/> : null}
          </Typography>
          {comments.map((comment) => (
            <Typography className={classes.fontBold}>{comment || ''}</Typography>
          ))}
        </Grid>
      );
    case 'storageEnergy':
      const totalEnergyCapacity = props.totalEnergyCapacity;
      const ortotalEnergyCapacity = calculateEnergyAndPower('totalEnergyCapacity');
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label}
            {item.showModified &&
            getSource(stateApplicationDetails) === SOURCE.SOLARGRAF &&
            !isEqual(totalEnergyCapacity, ortotalEnergyCapacity) ? (
              !isEqual(ortotalEnergyCapacity, 0) ? (
                <TagLabel edit applicationDetail={appDetails}/>
              ) : (
                <TagLabel add applicationDetail={appDetails}/>
              )
            ) : (
              handleUpdatedFunction(item.id)
            )}
          </Typography>
          <Typography className={classes.fontBold}>
            {getSource(stateApplicationDetails) === SOURCE.SOLARGRAF
              ? props.totalEnergyCapacity || '--'
              : get(appDetails, item.id) || '--'}
          </Typography>
        </Grid>
      );
    case 'storagePower':
      const totalPowerCapacity = props.totalPowerCapacity;
      const ortotalPowerCapacity = calculateEnergyAndPower('totalPowerCapacity');
      return (
        <Grid item xs={12} md={3}>
          <Typography className={classes.dataSubHeader}>
            {item.label}
            {item.showModified &&
            getSource(stateApplicationDetails) === SOURCE.SOLARGRAF &&
            !isEqual(totalPowerCapacity, ortotalPowerCapacity) ? (
              !isEqual(ortotalPowerCapacity, 0) ? (
                <TagLabel edit applicationDetail={appDetails}/>
              ) : (
                <TagLabel add applicationDetail={appDetails}/>
              )
            ) : (
              handleUpdatedFunction(item.id)
            )}
          </Typography>
          <Typography className={classes.fontBold}>
            {getSource(stateApplicationDetails) === SOURCE.SOLARGRAF
              ? totalPowerCapacity || '--'
              : get(appDetails, item.id) || '--'}
          </Typography>
        </Grid>
      );
    default:
      return null;
  }
};

export const labelStyles = styles;
