import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'fixed',
    width: '100%',
    zIndex: 9999
  }
}));

const Loader = ({ loading }) => {
  const classes = useStyles();
  if (loading)
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  return null;
};

export default Loader;
