import React from 'react';

const JPEGIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46.683" height="53.225" viewBox="0 0 46.683 53.225">
      <defs>
        <filter id="a" x="0" y="0" width="46.683" height="53.225" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="b" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-50.843 -6.167)">
        <g transform="translate(59.843 12.167)">
          <g transform="translate(0 0)">
            <g filter="url(#a)" transform="matrix(1, 0, 0, 1, -9, -6)">
              <path
                fill="#cbcbcb"
                d="M107.119,54.392H79.25a.406.406,0,0,1-.407-.406V19.573a.406.406,0,0,1,.407-.406h27.869a.406.406,0,0,1,.406.406V53.985A.406.406,0,0,1,107.119,54.392Zm-27.463-.813h27.056V19.98H79.656Z"
                transform="translate(-69.84 -13.17)"
              />
            </g>
            <g transform="translate(2.319 2.785)">
              <path
                fill="#219ae6"
                d="M130.184,63.765H108.848a1.36,1.36,0,0,1-1.354-1.363v-7.47a1.36,1.36,0,0,1,1.354-1.362h21.337a1.359,1.359,0,0,1,1.353,1.362V62.4A1.36,1.36,0,0,1,130.184,63.765Zm-21.2-.563c-.3,0-.881-.463-.881-.16l.2-.64a.546.546,0,0,0,.541.55h21.337c.3,0-.007.2.582-.073l-.45.289c0-.3-8.4.117-8.7.117Z"
                transform="translate(-107.494 -53.569)"
              />
            </g>
            <g transform="translate(7.223 5.51)">
              <path
                fill="#fff"
                d="M169.054,92.28h-.982v-.872h.982Zm3.065-4.972h.994v3.478a1.5,1.5,0,0,1-.473,1.148,1.7,1.7,0,0,1-1.172.418,1.818,1.818,0,0,1-1.219-.382,1.3,1.3,0,0,1-.438-1.112l.012-.024h.959a.7.7,0,0,0,.189.562.668.668,0,0,0,.5.179.633.633,0,0,0,.462-.2.848.848,0,0,0,.19-.586V87.307Zm2.887,3.167v1.8h-.994V87.307h1.964a1.94,1.94,0,0,1,1.338.43,1.614,1.614,0,0,1,0,2.307,1.94,1.94,0,0,1-1.338.43h-.97Zm0-.765h.97a.856.856,0,0,0,.628-.227.912.912,0,0,0,0-1.171.817.817,0,0,0-.628-.239h-.97Zm7.3,1.888a1.933,1.933,0,0,1-.687.526,2.763,2.763,0,0,1-1.171.227,2,2,0,0,1-1.479-.574,1.963,1.963,0,0,1-.568-1.47v-1.04a2.033,2.033,0,0,1,.544-1.47,1.916,1.916,0,0,1,1.42-.561,1.953,1.953,0,0,1,1.385.442,1.6,1.6,0,0,1,.485,1.159l-.012.024H181.3a.94.94,0,0,0-.26-.621.836.836,0,0,0-.651-.239.862.862,0,0,0-.722.358,1.387,1.387,0,0,0-.284.908v1.04a1.422,1.422,0,0,0,.284.921.948.948,0,0,0,.781.347,1.8,1.8,0,0,0,.556-.072.808.808,0,0,0,.32-.167v-.92h-.876v-.693h1.858V91.6Z"
                transform="translate(-168.072 -87.236)"
              />
            </g>
            <g transform="translate(2.841 18.359)">
              <path
                fill="#cbcbcb"
                d="M116.611,251.346a2.7,2.7,0,1,1,2.679-2.7A2.693,2.693,0,0,1,116.611,251.346Zm0-4.578a1.883,1.883,0,1,0,1.866,1.877A1.873,1.873,0,0,0,116.611,246.768Z"
                transform="translate(-113.932 -245.955)"
              />
            </g>
            <g transform="translate(2.935 19.064)">
              <path
                fill="#cbcbcb"
                d="M137.873,267.875H115.507a.407.407,0,0,1-.314-.664l7.408-9.024a.406.406,0,0,1,.555-.069l3.2,2.364,6.511-5.713a.406.406,0,0,1,.58.044l4.733,5.654a.408.408,0,0,1,.095.261v6.741A.407.407,0,0,1,137.873,267.875Zm-21.507-.813h21.1v-6.187l-4.372-5.221-6.444,5.654a.407.407,0,0,1-.509.022L122.984,259Z"
                transform="translate(-115.1 -254.668)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default JPEGIcon;
