import { Box, Grid, TextField, makeStyles } from '@material-ui/core';
import React from 'react';
import { get, isEmpty } from '../../../../utils/lodash';
import HelpTextIcon from '../../../common/HelpTextIcon';
import { Label } from '../../../common/Label';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  checkboxLabel: {
    margin: theme.spacing(4, 0, -1, -0.3)
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  }
}));

const PermitOfficeSection = props => {
  const classes = useStyles();
  const { errorData, handlePermitOffice, permitOffice, sectionLabel, readOnly, stateSelected } = props;
  const {
    permit_office_contact_name = '',
    permit_office_designation = '',
    permit_office_contact_phone = '',
    permit_office_contact_email = '',
    permit_office_contact_address = '',
    permit_office_contact_zip = '',
    permit_office_working_days = '',
    permit_office_operational_hours = '',
  } = permitOffice;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel
        header={sectionLabel}
        icon={
          <HelpTextIcon
            message="When EV permit details needed field checked in basic details section, ensure that all the fields in this section is filled and updated correctly."
            position="right"
            btnClassName={classes.button}
          />
        }
      >
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Contact name'} capitalizeLabelText={false} />}
                value={permit_office_contact_name}
                onChange={e => handlePermitOffice(e, 'permit_office_contact_name')}
                error={!isEmpty(get(errorData, 'permit_office_contact_name'))}
                helperText={get(errorData, 'permit_office_contact_name')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Designation'} capitalizeLabelText={false} />}
                value={permit_office_designation}
                onChange={e => handlePermitOffice(e, 'permit_office_designation')}
                error={!isEmpty(get(errorData, 'permit_office_designation'))}
                helperText={get(errorData, 'permit_office_designation')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                id={'permit_office_contact_phone'}
                label={<Label text={'Contact phone number with extension'} capitalizeLabelText={false} />}
                placeholder={'e.g. +1 xxx xxx xxxx'}
                value={permit_office_contact_phone}
                onChange={e => handlePermitOffice(e, 'permit_office_contact_phone')}
                error={!isEmpty(get(errorData, 'permit_office_contact_phone'))}
                helperText={get(errorData, 'permit_office_contact_phone')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Contact email address'} capitalizeLabelText={false} />}
                id={'permit_office_contact_email'}
                value={permit_office_contact_email}
                onChange={e => handlePermitOffice(e, 'permit_office_contact_email')}
                error={!isEmpty(get(errorData, 'permit_office_contact_email'))}
                helperText={get(errorData, 'permit_office_contact_email')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Office address line'} capitalizeLabelText={false} />}
                value={permit_office_contact_address}
                onChange={e => handlePermitOffice(e, 'permit_office_contact_address')}
                error={!isEmpty(get(errorData, 'permit_office_contact_address'))}
                helperText={get(errorData, 'permit_office_contact_address')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Office zip'} capitalizeLabelText={false} />}
                id={'permit_office_contact_zip'}
                value={permit_office_contact_zip}
                placeholder={'e.g. xxxxx or xxxxx-xxxx'}
                onChange={e => handlePermitOffice(e, 'permit_office_contact_zip')}
                error={!isEmpty(get(errorData, 'permit_office_contact_zip'))}
                helperText={get(errorData, 'permit_office_contact_zip')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Office working days'} capitalizeLabelText={false} />}
                value={permit_office_working_days}
                onChange={e => handlePermitOffice(e, 'permit_office_working_days')}
                error={!isEmpty(get(errorData, 'permit_office_working_days'))}
                helperText={get(errorData, 'permit_office_working_days')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Office hours of operation'} capitalizeLabelText={false} />}
                value={permit_office_operational_hours}
                onChange={e => handlePermitOffice(e, 'permit_office_operational_hours')}
                error={!isEmpty(get(errorData, 'permit_office_operational_hours'))}
                helperText={get(errorData, 'permit_office_operational_hours')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default PermitOfficeSection;
