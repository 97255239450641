import clsx from 'clsx';
import React from 'react';
import { FormControl, FormHelperText, InputLabel, ListItemText, makeStyles, MenuItem, Select } from '@material-ui/core';
import { Label } from '../Label';

const useStyles = makeStyles((theme) => ({
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  inputFieldWithValue: {
    '& .MuiInputBase-input': {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const SingleSelectDropdown = (props) => {
  const classes = useStyles();
  const { dropDownLabel, dropDownOptions, getErrorText, getHelperText, handleField, fieldId, fieldValue, readOnly } =
    props;
  return (
    <FormControl
      id={fieldId}
      className={
        fieldValue === '' || fieldValue === null
          ? classes.inputField
          : clsx(classes.inputField, classes.inputFieldWithValue)
      }
      error={getErrorText(fieldId)}
    >
      <InputLabel>
        <Label text={dropDownLabel} capitalizeLabelText={false} />
      </InputLabel>
      <Select MenuProps={MenuProps} value={fieldValue} onChange={(e) => handleField(e, fieldId)} disabled={readOnly}>
        {dropDownOptions.map((data, index) => {
          return (
            <MenuItem key={index} value={data.value} style={{ whiteSpace: 'normal' }}>
              <ListItemText primary={data.title} />
            </MenuItem>
          );
        })}
      </Select>
      {getErrorText(fieldId) && <FormHelperText>{getHelperText(fieldId)}</FormHelperText>}
    </FormControl>
  );
};

export default SingleSelectDropdown;
