import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  TextField,
  Typography,
  Divider,
  // Tooltip,
  // IconButton,
  // Avatar
} from '@material-ui/core';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import { withStyles } from '@material-ui/core/styles';
// import HelpIcon from '../images/helpIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Label, useLabelStyles } from '../../common/Label';
import PanelTemplate from '../../common/PanelTemplate';
import { get, clone } from '../../../utils/lodash';
import { connect } from 'react-redux';
import { isPermitSetAvailable } from '../../../utils/helper';
// import SupplyTapImage from '../images/Supply Tap.png';
// import SupplyBreakerImage from '../images/Supply Breaker - Supply Bus.png';
// import SubPanelDmImage from '../images/Sub Panel DM.png';
// import LoadBreakerImage from '../images/Load Breaker.png';
// import ProtectedLoadTapImage from '../images/Protected Load Tap.png';

// const LightTooltip = withStyles(theme => ({
//   tooltip: {
//     backgroundColor: 'white',
//     color: '#111111',
//     borderRadius: 0,
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//     padding: theme.spacing(1),
//     minWidth: theme.spacing(97),
//     pointerEvents: 'auto',
//     fontWeight: 'normal',
//     [theme.breakpoints.down('xs')]: {
//       minWidth: theme.spacing(40)
//     }
//   },
//   arrow: {
//     color: 'white',
//     fontSize: 14
//   }
// }))(Tooltip);

const useStyles = makeStyles(theme => ({
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none'
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(0, 2, 5)
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      paddingTop: 0
    }
  },
  inputStorageWrapper: {
    paddingBottom: theme.spacing(2)
  },
  inputField: {
    width: '45%',
    minWidth: theme.spacing(30),
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(3),
      minWidth: 'auto'
    }
  },
  formWrapper: {
    '& .MuiInputLabel-shrink': {
      width: '150%'
    }
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  recentStyle: {
    color: theme.palette.primary.grey,
    marginLeft: theme.spacing(2)
  },
  dividerStyle: {
    background: theme.palette.primary.grey,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  iconButton: {
    padding: theme.spacing(0, 0.5)
  },
  tooltipContainer: {
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(60),
      overflowY: 'scroll'
    }
  },
  tooltipImage: {
    height: theme.spacing(25),
    width: theme.spacing(45),
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(20),
      width: theme.spacing(37)
    }
  },
  tooltipLargeImage: {
    height: theme.spacing(25),
    width: theme.spacing(60),
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(16),
      width: theme.spacing(38)
    }
  },
  imageContainer: {
    padding: theme.spacing(2)
  },
  tooltipText: {
    color: theme.palette.primary.main
  },
  imageRow: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  }
}));

// const InterconnectionStrategyTooltip = props => {
//   const { classes, isMobile } = props;
//   let dividerVariant = 'vertical',
//     flexItem = true;
//   if (isMobile) {
//     dividerVariant = 'horizontal';
//     flexItem = false;
//   }
//   return (
//     <Box className={classes.tooltipContainer}>
//       <Box className={classes.imageRow}>
//         <Box className={classes.imageContainer}>
//           <Typography className={classes.tooltipText}>Supply Tap</Typography>
//           <Avatar variant="square" alt="enphase logo" src={SupplyTapImage} className={classes.tooltipImage} />
//         </Box>
//         <Divider orientation={dividerVariant} flexItem={flexItem} />
//         <Box className={classes.imageContainer}>
//           <Typography className={classes.tooltipText}>Supply Breaker - Supply Bus</Typography>
//           <Avatar variant="square" alt="enphase logo" src={SupplyBreakerImage} className={classes.tooltipImage} />
//         </Box>
//       </Box>
//       <Divider orientation="horizontal" />
//       <Box className={classes.imageRow}>
//         <Box className={classes.imageContainer}>
//           <Typography className={classes.tooltipText}>Sub Panel DM</Typography>
//           <Avatar variant="square" alt="enphase logo" src={SubPanelDmImage} className={classes.tooltipImage} />
//         </Box>
//         <Divider orientation={dividerVariant} flexItem={flexItem} />
//         <Box className={classes.imageContainer}>
//           <Typography className={classes.tooltipText}>Load Breaker</Typography>
//           <Avatar variant="square" alt="enphase logo" src={LoadBreakerImage} className={classes.tooltipImage} />
//         </Box>
//       </Box>
//       <Divider orientation="horizontal" />
//       <Box className={classes.imageRow}>
//         <Box className={classes.imageContainer}>
//           <Typography className={classes.tooltipText}>Protected Load Tap</Typography>
//           <Avatar
//             variant="square"
//             alt="enphase logo"
//             src={ProtectedLoadTapImage}
//             className={classes.tooltipLargeImage}
//           />
//         </Box>
//       </Box>
//     </Box>
//   );
// };

const SelectDropdown = props => {
  const classes = useStyles();
  // const [ isTooltipOpen, setIstooltipOpen ] = useState(false);
  const {
    id,
    className,
    value = '',
    handleChange,
    optionList = [],
    labelText,
    helperText,
    error = false,
    isPermitSetAvailable,
    showRecent = false,
    mostUsedList = [],
    capitalizeLabelText = true,
    // isMobile
  } = props;
  const modifiedOptionList = optionList;
  if (value && !modifiedOptionList.includes(value)) {
    if (isPermitSetAvailable) {
      modifiedOptionList.push(value);
    } else {
      handleChange('', id, true);
    }
  }
  const recentList = mostUsedList.filter(value => {
    if (value.length > 0 && modifiedOptionList.includes(value)) {
      return value;
    } else {
      return null;
    }
  });
  const showRecentDropdown = showRecent && recentList.length > 0 && modifiedOptionList.length > 5;
  const filtererModifiedOptionList =
    modifiedOptionList.length > 5
      ? modifiedOptionList.filter(value => recentList.indexOf(value) === -1)
      : modifiedOptionList;
  // const tooltipPlacement = isMobile ? 'top' : 'right-start';
  return (
    <FormControl id={id} className={clsx(className, classes.formWrapper)} error={error}>
      {labelText && (
        <InputLabel id={`${id}-label`}>
          <Label className={classes.labelColor} text={labelText} capitalizeLabelText={capitalizeLabelText} isRequired>
            {/* {id === 'interconnection_strategy' && (
              <ClickAwayListener onClickAway={e => setIstooltipOpen(false)}>
                <LightTooltip
                  open={isTooltipOpen}
                  title={<InterconnectionStrategyTooltip isMobile={isMobile} classes={classes} />}
                  placement={tooltipPlacement}
                  arrow
                >
                  <IconButton
                    onClick={e => setIstooltipOpen(true)}
                    onMouseEnter={e => setIstooltipOpen(true)}
                    onMouseLeave={e => setIstooltipOpen(false)}
                    aria-label="info"
                    className={classes.iconButton}
                  >
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </ClickAwayListener>
            )} */}
          </Label>
        </InputLabel>
      )}

      <Select
        id={`${id}-select`}
        labelId={`${id}-label`}
        value={value || ''}
        IconComponent={props => <ExpandMoreIcon className="MuiSelect-icon" />}
        onChange={e => handleChange(e.target.value, id)}
      >
        {showRecentDropdown && <Typography className={classes.recentStyle}>Recently Used</Typography>}
        {showRecentDropdown &&
          recentList.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        {showRecentDropdown && <Divider variant="middle" classes={{ root: classes.dividerStyle }} />}

        {filtererModifiedOptionList &&
          filtererModifiedOptionList.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const Interconnection = props => {
  const classes = useStyles();
  const labelClass = useLabelStyles();
  const {
    materialDetails,
    userSelectionData,
    handleChange,
    projectDetails,
    applicationDetail,
    isFrom,
    mostUsedValues,
    isMobile
  } = props;
  const isFromProjectDetails = isFrom === 'project-details';
  const permitFilesAvailable = isPermitSetAvailable(applicationDetail);
  const state = get(projectDetails, 'state', '');
  const isStorage = get(projectDetails, 'system_types', [])[1]['value'];
  const interconnectionStrategyOption =
    state === 'CA'
      ? get(userSelectionData, 'interconnection_strategy_ca', [])
      : get(userSelectionData, 'interconnection_strategy', []);
  const modifiedInterconnectionStrategyOption = interconnectionStrategyOption.filter(value => {
    if (!isStorage && value === 'Interconnection in Enpower') {
      return null;
    } else return value;
  });
  const interconnectionLocationOption = get(userSelectionData, 'interconnection_location', []);
  //const interconnectionStrategyEnpowerOption = get(userSelectionData, 'interconnection_strategy_enpower', []);
  const interconnectionStrategy = get(materialDetails, 'interconnection_properties.interconnection_strategy', '');
  const interconnectionLocation = get(materialDetails, 'interconnection_properties.interconnection_location', '');
  // const interconnectionStrategyEnpower = get(
  //   materialDetails,
  //   'interconnection_properties.interconnection_strategy_enpower',
  //   ''
  // );
  const otherInformation = get(materialDetails, 'interconnection_properties.other_information', '');
  //const storageSize = get(projectDetails, 'storage_size', '');
  const errors = get(materialDetails, 'errors', {});

  const handleInterconnectionChange = (value, key, skipValidation = false) => {
    const data = clone(get(materialDetails, 'interconnection_properties', {})) || {};
    data[key] = value;
    handleChange(data, 'interconnection_properties', { additionalKey: key }, skipValidation);
  };

  return (
    <PanelTemplate header="Interconnection" className={classes.panelContainer}>
      <Box className={clsx(!isFromProjectDetails && classes.root, permitFilesAvailable && classes.disableForm)}>
        {!isFromProjectDetails && (
          <Box className={classes.inputWrapper}>
            <SelectDropdown
              id="interconnection_strategy"
              labelText="Interconnection (Tie-in) Method"
              capitalizeLabelText={false}
              className={clsx(classes.inputField, labelClass.customLabel)}
              value={interconnectionStrategy}
              handleChange={handleInterconnectionChange}
              optionList={modifiedInterconnectionStrategyOption}
              isPermitSetAvailable={permitFilesAvailable}
              error={get(errors, 'interconnection_strategy.error')}
              helperText={get(errors, 'interconnection_strategy.errorMessage')}
              showRecent={true}
              mostUsedList={mostUsedValues['interconnection_strategy']}
              isMobile={isMobile}
            />
            <SelectDropdown
              id="interconnection_location"
              labelText="Interconnection Location"
              className={clsx(classes.inputField, labelClass.customLabel)}
              value={interconnectionLocation}
              handleChange={handleInterconnectionChange}
              optionList={interconnectionLocationOption}
              isPermitSetAvailable={permitFilesAvailable}
              error={get(errors, 'interconnection_location.error')}
              helperText={get(errors, 'interconnection_location.errorMessage')}
              showRecent={true}
              mostUsedList={mostUsedValues['interconnection_location']}
            />
          </Box>
        )}
        <Box className={clsx(!isFromProjectDetails ? classes.inputWrapper : classes.inputStorageWrapper)}>
          {/* {(storageSize || isFromProjectDetails) && (
          <SelectDropdown
            id="interconnection_strategy_enpower"
            labelText="Interconnection Strategy (Enpower)"
            className={clsx(classes.inputField, labelClass.customLabel)}
            value={interconnectionStrategyEnpower}
            handleChange={handleInterconnectionChange}
            optionList={interconnectionStrategyEnpowerOption}
            paymentStatus={paymentStatus}
            error={get(errors, 'interconnection_strategy_enpower.error')}
            helperText={get(errors, 'interconnection_strategy_enpower.errorMessage')}
            showRecent={true}
            mostUsedList={mostUsedValues['interconnection_strategy_enpower']}
          />
          )} */}
          <TextField
            id="other_information"
            onChange={e => handleInterconnectionChange(e.target.value, 'other_information')}
            className={clsx(classes.inputField, labelClass.customLabel)}
            label={<Label className={classes.labelColor} text="Notes/ Other Information" />}
            value={otherInformation}
          />
        </Box>
      </Box>
    </PanelTemplate>
  );
};

const mapStateToProps = state => ({
  mostUsedValues: state.permitReducer.mostUsedValues
});

export default connect(mapStateToProps)(Interconnection);
