import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Button, Typography } from '@material-ui/core';
import queryString from 'query-string';
import { PAYMENT_METHOD } from '../constants';
import { containsStatus } from '../../../utils/helper';
import { isFeatureEnabled } from '../../../containers/Permitting/helper';
import { FEATURE_FLAG_TYPE, FEATURE_FLAGS } from '../../../containers/Permitting/constants';
import { INSTALLER_STATES } from '../../../utils/constants';

const styles = (theme) => ({
  proceedButtonWrapper: {
    minWidth: theme.spacing(15),
    whiteSpace: 'nowrap',
  },
  saveandCloseButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  saveAndCloseVisible: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  saveandCloseButtonWrapper: {
    marginRight: theme.spacing(3),
  },
  backButton: {
    marginRight: theme.spacing(1),
    borderRadius: 0,
    minWidth: theme.spacing(15.5),
  },
  stepperAction: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    display: 'flex',
    borderTop: '1px solid #D1D1D1',
    width: 'auto',
    padding: theme.spacing(1.5, 3),
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    zIndex: 1,
    background: 'white',
    minWidth: 'calc(100% - 48px)',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      padding: theme.spacing(1.5, 2, 5),
    },
  },
  submitButton: {
    color: '#FFFFFF !important',
    borderRadius: 0,
    minWidth: theme.spacing(15.5),
    width: '100%',
    padding: theme.spacing(0.75),
  },
  forwardArrow: {
    marginLeft: theme.spacing(0.75),
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  },
  info: {
    fontSize: 14,
    color: '#999999',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      width: '100%',
      textAlign: 'center',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  singleButton: {
    justifyContent: 'flex-end',
  },
  infoSpacing: {
    padding: theme.spacing(0, 2.5),
  },
  singleInfo: {
    display: 'flex',
    margin: 'auto',
  },
  additionalButtonWrapper: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  additionSaveCloseWrapper: {
    width: '100%',
    marginRight: 0,
    marginTop: theme.spacing(2),
  },
  requiredBackButton: {
    minWidth: theme.spacing(10),
  },
  enlightenStepperAction: {
    left: 'auto',
    right: 0,
    minWidth: 'calc(100% - 329px)',
    [theme.breakpoints.between(0, 767)]: {
      left: 0,
      minWidth: 'calc(100% - 48px)',
    },
  },
  noLeftPane: {
    minWidth: '100%',
  },
  nextButton: {
    position: 'relative',
    right: theme.spacing(1),
  },
});

class StepperFooter extends Component {
  render() {
    const {
      classes,
      activeStep,
      steps,
      handleNext,
      handleSaveandClose,
      handleBack,
      paymentStatus,
      enableButton = false,
      readOnly = false,
      isMobile,
      isFromPackage,
      isServiceSelected,
      applicationDetail,
      enableSubmit = false,
      showBackButton = true,
      featureList,
      materialDetails,
      enableProceedButton = true
    } = this.props;
    const isDirectSubmitEnabled = isFeatureEnabled(featureList, FEATURE_FLAGS.DIRECT_SUBMIT, FEATURE_FLAG_TYPE.company);
    const disableButton = activeStep === steps.length - 1 ? !enableButton : materialDetails?.documents.findIndex((image) => image.loading) >= 0;
    const disableNextButton = materialDetails?.documents.findIndex((image) => image.loading) >= 0;
    const nextText =
      activeStep === steps.length - 1
        ? !isDirectSubmitEnabled
          ? 'Proceed to Payment'
          : 'Submit Application'
        : 'Proceed';
    const submitButtonText =
      activeStep === steps.length - 1 && isServiceSelected
        ? !isDirectSubmitEnabled
          ? 'Proceed to Payment'
          : 'Submit Application'
        : 'Submit';
    const { search } = window.location;
    const { origin } = queryString.parse(search);
    const proceedButton = () => {
      return (
        <div className={classes.proceedButtonWrapper}>
          {!(readOnly && activeStep === steps.length - 1) && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext(true, false)}
              className={classes.submitButton}
              disabled={disableButton || !enableProceedButton}
              disableElevation
            >
              {nextText}
              <ArrowForwardIosIcon className={classes.forwardArrow} />
            </Button>
          )}
        </div>
      );
    };
    const nextButton = () => {
      return (
        <div className={classes.proceedButtonWrapper}>
          {!(readOnly && activeStep === steps.length - 1) && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext(false, false)}
              className={clsx(classes.submitButton, classes.nextButton)}
              disableElevation
              disabled={disableNextButton}
            >
              Next
              <ArrowForwardIosIcon className={classes.forwardArrow} />
            </Button>
          )}
        </div>
      );
    };
    const submitButton = () => {
      return (
        <div className={classes.proceedButtonWrapper}>
          {!readOnly && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext(false, true)}
              className={classes.submitButton}
              disableElevation
              disabled={false}
            >
              {submitButtonText}
              <ArrowForwardIosIcon className={classes.forwardArrow} />
            </Button>
          )}
        </div>
      );
    };
    const isInsideSaveCloseWrapper = paymentStatus || (isMobile && activeStep === 0) || !isMobile;
    const showProceedButton =
      !paymentStatus ||
      (containsStatus(applicationDetail?.installer_statuses, INSTALLER_STATES.PERMIT_SET_AVAILABLE.id) &&
        activeStep === steps.length - 1);
    const showSubmitButton =
      activeStep === steps.length - 1 &&
      paymentStatus &&
      !containsStatus(applicationDetail?.installer_statuses, INSTALLER_STATES.PERMIT_SET_AVAILABLE.id);
    const showSaveAndClose = !paymentStatus && activeStep < steps.length - 1;
    return (
      <div
        className={clsx(
          classes.stepperAction,
          isFromPackage && classes.enlightenStepperAction,
          origin === 'iframe' && classes.noLeftPane
        )}
      >
        <div
          className={clsx(
            classes.buttonWrapper,
            activeStep === 0 && !paymentStatus && classes.singleButton,
            !paymentStatus && classes.additionalButtonWrapper
          )}
        >
          <div>
            {activeStep !== 0 && showBackButton && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleBack()}
                className={clsx(
                  classes.backButton,
                  activeStep === 2 && !paymentStatus && isMobile && classes.requiredBackButton
                )}
                disableElevation
              >
                Back
              </Button>
            )}
            {paymentStatus && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleBack(true)}
                className={clsx(classes.backButton, classes.requiredBackButton)}
                disableElevation
              >
                Go Back to Application Details
              </Button>
            )}
          </div>
          {!isInsideSaveCloseWrapper && proceedButton()}
          {!paymentStatus && !isMobile && (
            <Typography className={clsx(classes.info, classes.infoSpacing, activeStep === 0 && classes.singleInfo)}>
              Clicking on proceed will save your application progress
            </Typography>
          )}
          {(isInsideSaveCloseWrapper || showSaveAndClose) && (
            <div className={clsx(classes.saveandCloseButton, showSaveAndClose && classes.saveAndCloseVisible)}>
              {showSaveAndClose && origin !== 'iframe' && (
                <div
                  className={clsx(
                    classes.saveandCloseButtonWrapper,
                    !isInsideSaveCloseWrapper && classes.additionSaveCloseWrapper
                  )}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveandClose()}
                    className={classes.submitButton}
                    disabled={disableButton}
                    disableElevation
                  >
                    {`Save & Close`}
                  </Button>
                </div>
              )}
              {paymentStatus && activeStep < steps.length - 1 && nextButton()}
              {showProceedButton && proceedButton()}
              {showSubmitButton && submitButton()}
            </div>
          )}
        </div>
        {!paymentStatus && isMobile && (
          <Typography className={classes.info}>Clicking on proceed will save your application progress</Typography>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paymentStatus: state.permitReducer.paymentStatus,
  isFromPackage: state.permitReducer.isFromPackage,
  applicationDetail: state.permitReducer.applicationDetail,
  enableSubmit: state.permitReducer.enableSubmit,
  featureList: state.permitReducer.featureList,
});

export default connect(mapStateToProps)(withStyles(styles)(StepperFooter));
