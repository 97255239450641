import React from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

export const DatePicker = props => {
  const { value, minDate = '', maxDate = '', format, placeholderText, setDate } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable
        value={value ? moment(value) : null}
        placeholder={placeholderText}
        format={format}
        minDate={minDate !== '' ? minDate : undefined}
        maxDate={maxDate !== '' ? maxDate : undefined}
        onChange={date => setDate(date)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
