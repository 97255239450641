import React, { createRef, useState, useLayoutEffect, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box
} from '@material-ui/core';
import { Label } from '../../common/Label';
import CloseIcon from '@material-ui/icons/Close';
import { isEqual } from 'lodash';

const useStyles = makeStyles(theme => ({
  header: {
    color: '#335977',
    fontSize: '29px',
    fontWeight: 'Bold'
  },
  smallHeader: {
    color: '#335977',
    fontSize: '20px',
    fontWeight: 'Bold'
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    opacity: 0.23,
    cursor: 'pointer'
  },
  content: {
    border: '1px solid #7070704D',
    margin: '0 20px 20px 20px',
    padding: '15px'
  },
  dropDown: {
    width: '300px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(1)
    }
  },
  dragArea: {
    margin: '20px 0',
    backgroundColor: '#B9B9B91A',
    width: '523px',
    height: '359px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      height: 'auto'
    }
  },
  input: {
    visibility: 'hidden',
    height: 0
  },
  err: {
    color: 'red'
  },
  acceptButton: {
    color: theme.palette.primary.white,
    textTransform: 'none'
  },
  italic: {
    fontStyle: 'italic'
  },
  imgContainer: {
    border: '1px solid #70707040',
    height: theme.spacing(10),
    width: theme.spacing(15),
    display: 'flex',
    position: 'relative'
  },
  previewImage: {
    margin: 'auto',
    display: 'block',
    maxWidth: theme.spacing(15),
    maxHeight: theme.spacing(10)
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400
  },
  imageTypeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  imageTypeOptions: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}));

let fileData = null;

function FileUploadDialog(props) {
  const {
    type,
    addedPhotos,
    fireUpload,
    photoTags,
    open,
    imageKey,
    photoUploadTooltip = () => {},
    acceptableFileFormat = '',
    otherDialog = false,
    title = '',
    imageSectionKey = '',
    photoType = ''
  } = props;
  const classes = useStyles();

  const fileRef = createRef();
  const dropRef = createRef();
  const [ selectedPhoto, setSelectedPhoto ] = useState('');
  const [ fileName, setFileName ] = useState('');
  const [ previewImage, setPreviewImage ] = useState('');
  const [ errMsg, setErrMsg ] = useState('');
  const [ fileSelected, setFileSelected ] = useState(false);
  const selectFile = event => {
    if (event.target.files && event.target.files[0]) {
      setFileSelected(true);
      fileData = event.target.files[0];
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
      setFileName(fileData.name);
    }
  };
  const addFile = () => {
    if (type === 'Photo' && !otherDialog && !selectedPhoto) {
      setErrMsg('Please select file type to continue...');
      return;
    }
    if (!fileData) {
      setErrMsg('Please select file to continue...');
      return;
    }
    if (fileData.size / 1024 / 1024 > 100) {
      setErrMsg('File size is greater that 100MB...');
      return;
    }
    if (type === 'Photo' && otherDialog) {
      fireUpload(fileData, imageKey, imageSectionKey);
    } else {
      fireUpload(fileData, imageKey, selectedPhoto);
    }
    close();
  };

  useLayoutEffect(
    () => {
      setTimeout(() => {
        const div = dropRef.current;
        if (div) {
          if (open) {
            div.addEventListener('dragenter', handleDrag);
            div.addEventListener('dragleave', handleDrag);
            div.addEventListener('dragover', handleDrag);
            div.addEventListener('drop', handleDrop);
          } else {
            div.removeEventListener('dragenter', handleDrag);
            div.removeEventListener('dragleave', handleDrag);
            div.removeEventListener('dragover', handleDrag);
            div.removeEventListener('drop', handleDrop);
          }
        }
      }, 1);
    },
    [ dropRef, open ]
  );

  useEffect(() => {
    if (photoTags && photoTags.length === 1) {
      setSelectedPhoto(photoTags[0]);
    }
  }, [ photoTags ]);

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      fileData = e.dataTransfer.files[0];
      setPreviewImage(URL.createObjectURL(e.dataTransfer.files[0]));
      setFileName(fileData.name);
      setFileSelected(true);
    }
  };

  const addedTagDisable = () => {
    return isEqual(photoType, 'Attic Space - Show existing roof rafter/truss for each');
  };

  const close = () => {
    setErrMsg('');
    setFileName('');
    setSelectedPhoto('');
    fileData = null;
    props.setOpen(false);
    setPreviewImage('');
    setFileSelected(false);
  };
  const inputProps = acceptableFileFormat
    ? { accept: acceptableFileFormat }
    : type === 'Photo' || type === 'Location Image' ? { accept: 'image/*' } : {};
  return (
    <Dialog
      onClose={() => props.setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableBackdropClick
    >
      <DialogTitle id="customized-dialog-title">
        <Typography className={otherDialog ? classes.smallHeader : classes.header}>
          Upload {otherDialog ? title : type}
          <CloseIcon onClick={close} className={classes.close} size="large" />
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {type === 'Photo' &&
        !otherDialog && (
          <Box className={classes.imageTypeWrapper}>
            <FormControl className={classes.dropDown}>
              <InputLabel id="photo-select-label">
                <Label className={classes.labelColor} text="Image Type" isRequired />
              </InputLabel>
              <Select
                labelId="photo-select-label"
                onChange={e => setSelectedPhoto(e.target.value)}
                value={selectedPhoto}
                IconComponent={() => <ExpandMoreIcon className="MuiSelect-icon" />}
                error={!!errMsg && !selectedPhoto}
              >
                {photoTags.map((photo, i) => (
                  <MenuItem
                    value={photo}
                    key={`photo-${i}`}
                  >
                    <span className={classes.imageTypeOptions}>{photo}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!addedTagDisable() && photoUploadTooltip(true)}
          </Box>
        )}
        <div className={classes.dragArea} ref={dropRef}>
          <Typography gutterBottom>Drop file here to upload</Typography>
          <Typography gutterBottom>Or</Typography>
          <Button onClick={() => fileRef.current.click()} color="primary" variant="outlined">
            Select File
          </Button>
          <input type="file" onChange={selectFile} ref={fileRef} className={classes.input} {...inputProps} />
          <br />
          {(imageKey === 'photos' || imageKey === 'location_image_file_name') &&
          previewImage && (
            <Box className={classes.imgContainer}>
              <img className={classes.previewImage} alt={fileName || ''} src={previewImage} />
            </Box>
          )}
          <br />
          {fileName && (
            <Typography color="textSecondary" variant="caption" gutterBottom>
              {fileName}
            </Typography>
          )}
          {((type === 'Photo' && !selectedPhoto) || type !== 'Photo' || !fileData) &&
          errMsg && (
            <Typography className={classes.err} gutterBottom>
              {errMsg}
            </Typography>
          )}
          <Typography color="textSecondary" variant="caption" className={classes.italic}>
            Maximum upload file size :100MB
          </Typography>
        </div>
        <Button
          variant="contained"
          onClick={addFile}
          color="primary"
          disabled={!fileSelected}
          className={classes.acceptButton}
        >
          Proceed
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default FileUploadDialog;
