import {
  CREATE_PROJECT_DETAILS_REQUEST,
  GET_PERMIT_LISTING_REQUEST,
  GET_PERMIT_COUNT_BY_INSTALLER_REQUEST,
  SITE_SEARCH_REQUEST,
  GET_MATERIAL_DETAILS_REQUEST,
  CREATE_MATERIAL_DETAILS_REQUEST,
  GET_USER_SELECTION_REQUEST,
  GET_OTHER_USER_SELECTION_REQUEST,
  GET_PROJECT_DETAILS_REQUEST,
  GET_PANEL_MANUFACTURER_LIST_REQUEST,
  UPDATE_PAYMENT_DETAILS_REQUEST,
  GET_SERVICES_REQUEST,
  SITE_DETAILS_REQUEST,
  UPDATE_ADDRESS,
  GET_SELECTED_SERVICES_REQUEST,
  UPDATE_SELECTED_SERVICES_REQUEST,
  GET_PERMIT_BY_ID_REQUEST,
  SET_PAYMET_STATUS_REQUEST,
  GET_S3_URL_REQUEST,
  PUT_S3_URL_REQUEST,
  PROMOCODE_REQUEST,
  GET_NOTIFICATION_REQUEST,
  SET_MESSAGE_REQUEST,
  RESET_SITE_SEARCH_RESULT_REQUEST,
  SHOW_HIDE_LOADER,
  GET_RACKING_MFS_REQUEST,
  GET_AB_FEATURES_REQUEST,
  GET_UTILITY_PROVIDERS_REQUEST,
  SET_PHOTOS,
  GET_CHAT_INFO_REQUEST,
  UPDATE_CHAT_STATUS_REQUEST,
  GET_REWORK_SERVICES_REQUEST,
  UPDATE_REWORK_SERVICES_REQUEST,
  PERMIT_DIRECT_SUBMIT_REQUEST,
  GET_SELECTED_REWORK_SERVICES_REQUEST,
  GET_APPLICATION_REQUEST,
  PUT_APPLICATION_REQUEST,
  SET_CLOSE_SAVE,
  SET_ERROR,
  SET_PAYMENT_LOADER,
  SET_ACTIVE_STEP,
  ACCOUNT_DETAIL_REQUEST,
  GET_TEMPLATE_REQUEST,
  SET_SELECTED_TEMPLATE,
  POST_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_REQUEST,
  PUT_TEMPLATE_TOGGLE_REQUEST,
  PUT_TEMPLATE_REQUEST,
  SET_VIEW_TEMPLATE,
  PAYMENT_UPGRADE_TO_STORAGE,
  UPDATE_FEEDBACK_REQUEST,
  GET_FEEDBACK_REQUEST,
  GET_MOST_USED_VALUES_REQUEST,
  SET_APPLY_TEMPLATE,
  SET_UPDATED_FROM_TEMPLATE,
  ADD_EMPTY_TEMPLATE,
  REMOVE_EMPTY_TEMPLATE,
  CREATE_EMAIL_NPS_FEEDBACK_REQUEST,
  SEND_STAMPING_REQUEST,
  GET_ARRAY_BUILDER_DETAILS_REQUEST,
  SET_V2_SOLAR_UI,
  SET_IS_FROM_PACKAGE,
  GET_CART_URL_REQUEST,
  IS_URL_VALID_REQUEST,
  GET_SG_MATERIALS_REQUEST,
  GET_COMPANY_INFO_REQUEST,
  ADDITIONAL_STORAGE_REQUEST,
  MORE_INFO_SUBMIT_REQUEST,
  DRAFT_APPLICATION_REQUEST,
  REWORK_REQUEST,
  FILES_DOWNLOADED_REQUEST,
  ENABLE_SUBMIT,
  UPDATE_COORDINATES,
  GET_AHJ_RECOMMENDATIONS_REQUEST,
  GET_AHJID_BYNAME_REQUEST,
  GET_UTILITYID_BYNAME_REQUEST,
  ORDER_STAMPS,
  UPDATE_COUNTY,
  SET_CHAT_AUTOMATED_MESSAGES_REQUEST,
} from './constants';

export function createProjectDetails(payload) {
  return { type: CREATE_PROJECT_DETAILS_REQUEST, payload };
}
export function updateAddress(payload) {
  return { type: UPDATE_ADDRESS, payload };
}

export function getProjectDetails(payload) {
  return { type: GET_PROJECT_DETAILS_REQUEST, payload };
}

export function getPermittingListing(payload) {
  return { type: GET_PERMIT_LISTING_REQUEST, payload };
}

export function getPermittingCountByInstaller(payload) {
  return { type: GET_PERMIT_COUNT_BY_INSTALLER_REQUEST, payload };
}

export function getTemplates(payload) {
  return { type: GET_TEMPLATE_REQUEST, payload };
}
export function setSelectedTemplate(payload) {
  return { type: SET_SELECTED_TEMPLATE, payload };
}
export function postTemplate(payload) {
  return { type: POST_TEMPLATE_REQUEST, payload };
}
export function deleteTemplate(payload) {
  return { type: DELETE_TEMPLATE_REQUEST, payload };
}

export function toggleTemplate(payload) {
  return { type: PUT_TEMPLATE_TOGGLE_REQUEST, payload };
}
export function updateTemplate(payload) {
  return { type: PUT_TEMPLATE_REQUEST, payload };
}
export function setViewTemplate(payload) {
  return { type: SET_VIEW_TEMPLATE, payload };
}

export function getPermittingById(payload) {
  return { type: GET_PERMIT_BY_ID_REQUEST, payload };
}

export function setPaymentStatus(payload) {
  return { type: SET_PAYMET_STATUS_REQUEST, payload };
}

export function getSearchResult(payload) {
  return { type: SITE_SEARCH_REQUEST, payload };
}

export function getMaterialDetails(payload) {
  return { type: GET_MATERIAL_DETAILS_REQUEST, payload };
}

export function updateMaterialDetails(payload) {
  return { type: CREATE_MATERIAL_DETAILS_REQUEST, payload };
}

export function getPanelManufacturerList(payload) {
  return { type: GET_PANEL_MANUFACTURER_LIST_REQUEST, payload };
}

export function getS3URL(payload) {
  return { type: GET_S3_URL_REQUEST, payload };
}

export function putToS3(payload) {
  return { type: PUT_S3_URL_REQUEST, payload };
}

export function getUserSelectionData() {
  return { type: GET_USER_SELECTION_REQUEST };
}

export function getOtherUserSelectionData() {
  return { type: GET_OTHER_USER_SELECTION_REQUEST };
}

export function updatePermitPayemntDetails(payload) {
  return { type: UPDATE_PAYMENT_DETAILS_REQUEST, payload };
}

export function getSiteDetails(payload) {
  return { type: SITE_DETAILS_REQUEST, payload };
}

export function getAllServices(id, successCbk) {
  return { type: GET_SERVICES_REQUEST, id, successCbk };
}

export function getSelectedServices(payload) {
  return { type: GET_SELECTED_SERVICES_REQUEST, payload };
}

export function updateSelectedServices(payload) {
  return { type: UPDATE_SELECTED_SERVICES_REQUEST, payload };
}

export function applyPromocode(payload) {
  return { type: PROMOCODE_REQUEST, payload };
}

export function getMessageList(payload) {
  return { type: GET_NOTIFICATION_REQUEST, payload };
}

export function sendMessage(payload) {
  return { type: SET_MESSAGE_REQUEST, payload };
}

export function resetSiteSearchResult() {
  return { type: RESET_SITE_SEARCH_RESULT_REQUEST };
}

export function showHideLoader(payload) {
  return { type: SHOW_HIDE_LOADER, payload };
}

export function getRackingMfs(payload) {
  return { type: GET_RACKING_MFS_REQUEST, payload };
}

export function getABFeatures(payload) {
  return { type: GET_AB_FEATURES_REQUEST, payload };
}

export function getUtilityProviders(payload) {
  return { type: GET_UTILITY_PROVIDERS_REQUEST, payload };
}

export function setPhotos(payload) {
  return { type: SET_PHOTOS, payload };
}

export function getChatInfo(payload) {
  return { type: GET_CHAT_INFO_REQUEST, payload };
}

export function updateChatStatus(payload) {
  return { type: UPDATE_CHAT_STATUS_REQUEST, payload };
}

export function getReworkServices(id) {
  return { type: GET_REWORK_SERVICES_REQUEST, id };
}

export function setPaymentUpgradeToStorage(payload) {
  return { type: PAYMENT_UPGRADE_TO_STORAGE, payload };
}

export function updateReworkServices(payload) {
  return { type: UPDATE_REWORK_SERVICES_REQUEST, payload };
}

export function permitDirectSubmit(payload) {
  return { type: PERMIT_DIRECT_SUBMIT_REQUEST, payload };
}

export function getSelectedReworkServices(payload) {
  return { type: GET_SELECTED_REWORK_SERVICES_REQUEST, payload };
}

export function getApplicationBySite(payload) {
  return { type: GET_APPLICATION_REQUEST, payload };
}

export function putApplicationBySite(payload) {
  return { type: PUT_APPLICATION_REQUEST, payload };
}

export function setIsSaveandClose(isSaveandClose) {
  return { type: SET_CLOSE_SAVE, isSaveandClose };
}

export function setError(payload) {
  return { type: SET_ERROR, payload };
}

export function setPaymentLoader(payload) {
  return { type: SET_PAYMENT_LOADER, payload };
}

export function setActiveStep(activeStep) {
  return { type: SET_ACTIVE_STEP, activeStep };
}

export function getAccountData(payload) {
  return { type: ACCOUNT_DETAIL_REQUEST, payload };
}

export function getCompanyInfo(companyId) {
  return { type: GET_COMPANY_INFO_REQUEST, companyId: companyId };
}

export function updateFeedback(payload) {
  return { type: UPDATE_FEEDBACK_REQUEST, payload };
}

export function getFeedback(payload) {
  return { type: GET_FEEDBACK_REQUEST, payload };
}
export function getMostUsedValues() {
  return { type: GET_MOST_USED_VALUES_REQUEST };
}

export function setApplyTemplate(payload) {
  return { type: SET_APPLY_TEMPLATE, payload };
}

export function addEmptyTemplate(payload) {
  return { type: ADD_EMPTY_TEMPLATE, payload };
}

export function removeEmptyTemplate(payload) {
  return { type: REMOVE_EMPTY_TEMPLATE, payload };
}

export function createEmailNpsFeedback(payload) {
  return { type: CREATE_EMAIL_NPS_FEEDBACK_REQUEST, payload };
}

export function sendStampingRequest(payload) {
  return { type: SEND_STAMPING_REQUEST, payload };
}

export function getArrayBuilderDetails(payload) {
  return { type: GET_ARRAY_BUILDER_DETAILS_REQUEST, payload };
}

export function setV2SolarUI(payload) {
  return { type: SET_V2_SOLAR_UI, payload };
}

// if "isFromPackage" is "true" -> Permitting running inside an application (ENLM)
// if "isFromPackage" is "false" -> Permitting running independently
export function setIsFromPackage(isFromPackage) {
  return { type: SET_IS_FROM_PACKAGE, isFromPackage };
}

export function addToCartAndGetUrl(payload) {
  return { type: GET_CART_URL_REQUEST, payload };
}

export function checkUrlValidity(payload) {
  return { type: IS_URL_VALID_REQUEST, payload };
}

export function getSGMaterials(payload) {
  return { type: GET_SG_MATERIALS_REQUEST, payload };
}

export function postAdditionalStorageData(payload) {
  return { type: ADDITIONAL_STORAGE_REQUEST, payload };
}

export function moreInfoSubmit(payload) {
  return { type: MORE_INFO_SUBMIT_REQUEST, payload };
}

export function saveDraftApplication(payload) {
  return { type: DRAFT_APPLICATION_REQUEST, payload };
}

export function reworkRequest(payload) {
  return { type: REWORK_REQUEST, payload };
}

export function filesDownloaded(payload) {
  return { type: FILES_DOWNLOADED_REQUEST, payload };
}

export function setEnableSubmit(enableSubmit) {
  return { type: ENABLE_SUBMIT, enableSubmit };
}

export function updateCoordinates() {
  return { type: UPDATE_COORDINATES }
}
export function getAHJRecommendations(payload) {
  return { type: GET_AHJ_RECOMMENDATIONS_REQUEST, payload };
}

export function getAhjIdByName(payload) {
  return { type: GET_AHJID_BYNAME_REQUEST, payload };
}

export function getUtilityIdByName(payload) {
  return { type: GET_UTILITYID_BYNAME_REQUEST, payload };
}

export function updateCounty(payload) {
  return { type: UPDATE_COUNTY, payload };
}

export function setChatAutomatedMessagesDisabled(payload) {
  return { type: SET_CHAT_AUTOMATED_MESSAGES_REQUEST, payload };
}
export function toOrderStamps(payload) {
  return { type: ORDER_STAMPS, payload };
}
