import { call, put, take } from "redux-saga/effects";
import { get, putMethod, post } from "../../../utils/api";
import settings from "../../../utils/settings";
import { APPROVE_DOCUMENTS_FAILURE, APPROVE_DOCUMENTS_REQUEST, APPROVE_DOCUMENTS_SUCCESS, GET_EXTERNAL_REVISION_DETAILS_FAILURE, GET_EXTERNAL_REVISION_DETAILS_REQUEST, GET_EXTERNAL_REVISION_DETAILS_SUCCESS, GET_INTERNAL_REVISION_DETAILS_FAILURE, GET_INTERNAL_REVISION_DETAILS_REQUEST, GET_INTERNAL_REVISION_DETAILS_SUCCESS, INTERNAL_KICKBACK_FAILURE, INTERNAL_KICKBACK_REQUEST, INTERNAL_KICKBACK_SUCCESS, MOVE_TO_PERMIT_DRAWINGS_COMPLETED_FAILURE, MOVE_TO_PERMIT_DRAWINGS_COMPLETED_REQUEST, MOVE_TO_PERMIT_DRAWINGS_COMPLETED_SUCCESS, MOVE_TO_QA_COMPLETED_FAILURE, MOVE_TO_QA_COMPLETED_REQUEST, MOVE_TO_QA_COMPLETED_SUCCESS, MOVE_TO_UNDER_PROGRESS_FAILURE, MOVE_TO_UNDER_PROGRESS_REQUEST, MOVE_TO_UNDER_PROGRESS_SUCCESS, RESUBMISSION_RECEIVED_FAILURE, RESUBMISSION_RECEIVED_REQUEST, RESUBMISSION_RECEIVED_SUCCESS, RESUBMISSION_REQUESTED_FAILURE, RESUBMISSION_REQUESTED_REQUEST, RESUBMISSION_REQUESTED_SUCCESS, STAMPING_SUBMIT_FAILURE, STAMPING_SUBMIT_REQUEST, STAMPING_SUBMIT_SUCCESS, SAVE_INTERNAL_NOTES_REQUEST, SAVE_INTERNAL_NOTES_SUCCESS, SAVE_INTERNAL_NOTES_FAILURE, SAVE_INTERNAL_FILES_REQUEST, SAVE_INTERNAL_FILES_SUCCESS, SAVE_INTERNAL_FILES_FAILURE, EDIT_INTERNAL_NOTES_REQUEST, EDIT_INTERNAL_NOTES_SUCCESS, EDIT_INTERNAL_NOTES_FAILURE, GET_INSTALLER_LIST_REQUEST, GET_INSTALLER_LIST_SUCCESS, GET_INSTALLER_LIST_FAILURE, SEND_ADMIN_STAMPING_REQUEST, SEND_ADMIN_STAMPING_SUCCESS, SEND_ADMIN_STAMPING_FAILURE } from "./constants";

export function* moveToUnderProgressFlow() {
  while (true) {
    const request = yield take(MOVE_TO_UNDER_PROGRESS_REQUEST);
    const { permitId, successCbk } = request.payload;
    try {
      const response = yield call(putMethod, {
        url: settings.permittingCoreUrl(`permit/admin/${permitId}/underProgress`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: MOVE_TO_UNDER_PROGRESS_SUCCESS });
        successCbk();
      } else {
        yield put({ type: MOVE_TO_UNDER_PROGRESS_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: MOVE_TO_UNDER_PROGRESS_FAILURE, message: error });
    }
  }
}

let permitDrawings = false;
export function* moveToPermitDrawingsCompletedFlow() {
  while (true) {
    const request = yield take(MOVE_TO_PERMIT_DRAWINGS_COMPLETED_REQUEST);
    const { permitId, files, successCbk } = request.payload;
    if (!permitDrawings) {
      permitDrawings = true;
      try {
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/permitDrawings`),
          body: files,
          withBearer: true
        });
        const { success, status, result } = response;
        permitDrawings = false;
        if (success && status === 200) {
          yield put({ type: MOVE_TO_PERMIT_DRAWINGS_COMPLETED_SUCCESS });
          successCbk();
        } else {
          yield put({ type: MOVE_TO_PERMIT_DRAWINGS_COMPLETED_FAILURE, message: result.message || result.messages });
        }
      } catch (error) {
        permitDrawings = false;
        yield put({ type: MOVE_TO_PERMIT_DRAWINGS_COMPLETED_FAILURE, message: error });
      }
    }
  }
}

let resubmissionRequest = false;
export function* moreInformationRequestedFlow() {
  while (true) {
    const request = yield take(RESUBMISSION_REQUESTED_REQUEST);
    const { permitId, files, message, successCbk, failureCbk } = request.payload;
    if (!resubmissionRequest) {
      resubmissionRequest = true;
      try {
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/moreInfoRequest`),
          body: {
            files: files,
            message: message
          },
          withBearer: true
        });
        const { success, status, result } = response;
        resubmissionRequest = false;
        if (success && status === 200) {
          yield put({ type: RESUBMISSION_REQUESTED_SUCCESS });
          successCbk();
        } else {
          yield put({ type: RESUBMISSION_REQUESTED_FAILURE, message: result.message || result.messages });
          failureCbk();
        }
      } catch (error) {
        permitDrawings = false;
        yield put({ type: RESUBMISSION_REQUESTED_FAILURE, message: error });
        failureCbk();
      }
    }
  }
}

let resubmissionReceived = false;
export function* moreInfoSubmitFlow() {
  while (true) {
    const request = yield take(RESUBMISSION_RECEIVED_REQUEST);
    const { permitId, successCbk } = request.payload;
    if (!resubmissionReceived) {
      resubmissionReceived = true;
      try {
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/moreInfoSubmit`),
          withBearer: true
        });
        const { success, status, result } = response;
        resubmissionReceived = false;
        if (success && status === 200) {
          yield put({ type: RESUBMISSION_RECEIVED_SUCCESS });
          successCbk();
        } else {
          yield put({ type: RESUBMISSION_RECEIVED_FAILURE, message: result.message || result.messages });
        }
      } catch (error) {
        resubmissionReceived = false;
        yield put({ type: RESUBMISSION_RECEIVED_FAILURE, message: error });
      }
    }
  }
}

let approveDocuments = false;
export function* approveDocumentsFlow() {
  while (true) {
    const request = yield take(APPROVE_DOCUMENTS_REQUEST);
    const { permitId, comment, successCbk } = request.payload;
    if (!approveDocuments) {
      approveDocuments = true;
      try {
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/approveDocuments`),
          body: { comment },
          withBearer: true
        });
        const { success, status, result } = response;
        approveDocuments = false;
        if (success && status === 200) {
          yield put({ type: APPROVE_DOCUMENTS_SUCCESS });
          successCbk();
        } else {
          yield put({ type: APPROVE_DOCUMENTS_FAILURE, message: result.message || result.messages });
        }
      } catch (error) {
        approveDocuments = false;
        yield put({ type: APPROVE_DOCUMENTS_FAILURE, message: error });
      }
    }
  }
}

let qaCompleted = false;
export function* moveToQACompletedFlow() {
  while (true) {
    const request = yield take(MOVE_TO_QA_COMPLETED_REQUEST);
    const { permitId, files, successCbk, failureCbk } = request.payload;
    if (!qaCompleted) {
      qaCompleted = true;
      try {
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/qaCompleted`),
          body: files,
          withBearer: true
        });
        const { success, status, result } = response;
        qaCompleted = false;
        if (success && status === 200) {
          yield put({ type: MOVE_TO_QA_COMPLETED_SUCCESS });
          successCbk();
        } else {
          yield put({ type: MOVE_TO_QA_COMPLETED_FAILURE, message: result.message || result.messages });
          failureCbk();
        }
      } catch (error) {
        qaCompleted = false;
        yield put({ type: MOVE_TO_QA_COMPLETED_FAILURE, message: error });
        failureCbk();
      }
    }
  }
}

let internalKickback = false;
export function* internalKickbackFlow() {
  while (true) {
    const request = yield take(INTERNAL_KICKBACK_REQUEST);
    const { permitId, kickbackDetails, successCbk } = request.payload;
    if (!internalKickback) {
      internalKickback = true;
      try {
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/correction`),
          body: kickbackDetails,
          withBearer: true
        });
        const { success, status, result } = response;
        internalKickback = false;
        if (success && status === 200) {
          yield put({ type: INTERNAL_KICKBACK_SUCCESS });
          successCbk();
        } else {
          yield put({ type: INTERNAL_KICKBACK_FAILURE, message: result.message || result.messages });
        }
      } catch (error) {
        internalKickback = false;
        yield put({ type: INTERNAL_KICKBACK_FAILURE, message: error });
      }
    }
  }
}

let revisionNotes = false;
export function* getRevisionNotesFlow() {
  while (true) {
    const request = yield take(GET_INTERNAL_REVISION_DETAILS_REQUEST);
    const { permitId, version, successCbk } = request.payload;
    if (!revisionNotes) {
      revisionNotes = true;
      try {
        const response = yield call(get, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/kickback?version=${version}`),
          withBearer: true
        });
        const { success, status, result } = response;
        revisionNotes = false;
        if (success && status === 200) {
          yield put({ type: GET_INTERNAL_REVISION_DETAILS_SUCCESS });
          successCbk(result?.result);
        } else {
          yield put({ type: GET_INTERNAL_REVISION_DETAILS_FAILURE, message: result.message || result.messages });
        }
      } catch (error) {
        revisionNotes = false;
        yield put({ type: GET_INTERNAL_REVISION_DETAILS_FAILURE, message: error });
      }
    }
  }
}

let externalRevisionNotes = false;
export function* getExternalRevisionNotesFlow() {
  while (true) {
    const request = yield take(GET_EXTERNAL_REVISION_DETAILS_REQUEST);
    const { permitId, version, successCbk } = request.payload;
    if (!externalRevisionNotes) {
      externalRevisionNotes = true;
      try {
        const response = yield call(get, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/externalKickback?version=${version}`),
          withBearer: true
        });
        const { success, status, result } = response;
        externalRevisionNotes = false;
        if (success && status === 200) {
          yield put({ type: GET_EXTERNAL_REVISION_DETAILS_SUCCESS });
          successCbk(result?.result);
        } else {
          yield put({ type: GET_EXTERNAL_REVISION_DETAILS_FAILURE, message: result.message || result.messages });
        }
      } catch (error) {
        externalRevisionNotes = false;
        yield put({ type: GET_EXTERNAL_REVISION_DETAILS_FAILURE, message: error });
      }
    }
  }
}

let stampingSubmit = false;
export function* stampingSubmitFlow() {
  while (true) {
    const request = yield take(STAMPING_SUBMIT_REQUEST);
    const { permitId, files, successCbk } = request.payload;
    if (!stampingSubmit) {
      stampingSubmit = true;
      try {
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/stampingSubmit`),
          body: files,
          withBearer: true
        });
        const { success, status, result } = response;
        stampingSubmit = false;
        if (success && status === 200) {
          yield put({ type: STAMPING_SUBMIT_SUCCESS });
          successCbk();
        } else {
          yield put({ type: STAMPING_SUBMIT_FAILURE, message: result.message || result.messages });
        }
      } catch (error) {
        stampingSubmit = false;
        yield put({ type: STAMPING_SUBMIT_FAILURE, message: error });
      }
    }
  }
}

export function* saveInternalNotesFlow() {
  while (true) {
    const request = yield take(SAVE_INTERNAL_NOTES_REQUEST);
    const { permitId, internalNotes, successCbk } = request.payload;
    try {
      const response = yield call(post, {
        url: settings.permittingCoreUrl(`permit/admin/${permitId}/internalNotes`),
        body: internalNotes,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: SAVE_INTERNAL_NOTES_SUCCESS });
        successCbk();
      } else {
        yield put({ type: SAVE_INTERNAL_NOTES_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: SAVE_INTERNAL_NOTES_FAILURE, message: error });
    }
  }
}

export function* saveInternalFilesFlow() {
  while (true) {
    const request = yield take(SAVE_INTERNAL_FILES_REQUEST);
    const { permitId, files, successCbk } = request.payload;
    try {
      const response = yield call(post, {
        url: settings.permittingCoreUrl(`permit/admin/${permitId}/internalFiles`),
        body: files,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: SAVE_INTERNAL_FILES_SUCCESS });
        successCbk();
      } else {
        yield put({ type: SAVE_INTERNAL_FILES_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: SAVE_INTERNAL_FILES_FAILURE, message: error });
    }
  }
}

export function* editInternalNotesFlow() {
  while (true) {
    const request = yield take(EDIT_INTERNAL_NOTES_REQUEST);
    const { permitId, internalNotes, successCbk } = request.payload;
    try {
      const response = yield call(putMethod, {
        url: settings.permittingCoreUrl(`permit/admin/${permitId}/internalNotes/${internalNotes.id}`),
        body: internalNotes,
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: EDIT_INTERNAL_NOTES_SUCCESS });
        successCbk();
      } else {
        yield put({ type: EDIT_INTERNAL_NOTES_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: EDIT_INTERNAL_NOTES_FAILURE, message: error });
    }
  }
}

export function* getInstallerListFlow() {
  while (true) {
    const request = yield take(GET_INSTALLER_LIST_REQUEST);
    const { successCb, appType } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/installersList?appType=${appType}`),
        withBearer: true
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_INSTALLER_LIST_SUCCESS,
          installersList: result
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_INSTALLER_LIST_FAILURE, message: error });
    }
  }
}

let stampingRequestSent = false;
export function* sendStampingRequestAdminFlow() {
  if (!stampingRequestSent) {
    while (true) {
      const request = yield take(SEND_ADMIN_STAMPING_REQUEST);
      const { permitId, message, successCbk } = request.payload;
      try {
        stampingRequestSent = true;
        const response = yield call(putMethod, {
          url: settings.permittingCoreUrl(`permit/admin/${permitId}/stampingRequestSubmit`),
          body: {
            message: message
          },
          withBearer: true
        });
        const { success, status, result } = response;
        stampingRequestSent = false;
        if (success && status === 200) {
          yield put({ type: SEND_ADMIN_STAMPING_SUCCESS });
          successCbk();
        } else {
          yield put({ type: SEND_ADMIN_STAMPING_FAILURE, message: result.message || result.messages });
        }
        stampingRequestSent = false;
      } catch (error) {
        yield put({ type: SEND_ADMIN_STAMPING_FAILURE, message: error });
        stampingRequestSent = false;
      }
    }
  }
}
