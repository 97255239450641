import { withStyles } from '@material-ui/core/styles';
import { Box, Badge } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const styles = theme => ({
  approvalStatusBox: {
    width: theme.spacing(18),
    padding: theme.spacing(2)
  },
  chatBadge: {
    top: theme.spacing(0.375),
    marginBottom: theme.spacing(1),
    '& .MuiBadge-badge': {
      top: theme.spacing(-1)
    }
  },
  slightlyLeft: {
    '& .MuiBadge-badge': {
      right: theme.spacing(-4.2)
    }
  },
  messageStyle: {
    fontSize: theme.spacing(1.1)
  }
});

const ApprovalStatusColumn = props => {
  const { unreadComments, rowStatus, classes } = props;

  return (
    <Box className={classes.approvalStatusBox}>
      {unreadComments === 0 && <Badge>{rowStatus}</Badge>}
      {unreadComments > 0 && (
        <Badge
          badgeContent={
            unreadComments > 1 ? (
              <span className={classes.messageStyle}> {unreadComments + ' Messages'} </span>
            ) : (
              <span className={classes.messageStyle}> {unreadComments + ' Message'} </span>
            )
          }
          color="primary"
          className={clsx(classes.chatBadge, unreadComments > 0 && classes.slightlyLeft)}
        >
          {rowStatus}
        </Badge>
      )}
    </Box>
  );
};

export default withStyles(styles)(ApprovalStatusColumn);
