import React from 'react';

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.667"
      height="14"
      viewBox="0 0 18.667 14"
      style={{ overflow: 'visible ', paddingLeft: '8px' }}
    >
      <path
        id="checked"
        d="M0,72.039l6.392,6.4L18.667,66.181,16.9,64.443,6.392,74.943,1.738,70.288Z"
        transform="translate(0 -64.443)"
        fill="#fff"
      />
    </svg>
  );
};

export default EditIcon;
