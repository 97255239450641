import React from 'react';
import { Label } from '../../../../../common/Label';
import { get, isEmpty } from '../../../../../../utils/lodash';
import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import CustomPanel from '../../../../../common/PanelTemplateWithMandatory';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  }
}));

const ElectricalDetailsSection = props => {
  const classes = useStyles();
  const { readOnly, errorData, sectionLabel, electricalDetails, handleElectricalDetails } = props;
  const {
    max_output_current = '',
    max_input_short_circuit_current = '',
    max_output_voltage = '',
    min_input_voltage = '',
    max_input_voltage = '',
    input_power_rating = '',
    max_nominal_power_per_string = '',
    max_efficiency = '',
    cec_weighted_efficiency = '',
    min_string_length = '',
  } = electricalDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_output_current'}
                className={classes.inputField}
                label={<Label text={'Maximum Output Current (A)'} isRequired />}
                type="number"
                value={max_output_current || ''}
                onChange={e => handleElectricalDetails(e, 'max_output_current')}
                error={!isEmpty(get(errorData, 'max_output_current'))}
                helperText={get(errorData, 'max_output_current')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_input_short_circuit_current'}
                className={classes.inputField}
                label={<Label text={'Maximum Input Short Circuit Current (Isc) (A)'} isRequired />}
                type="number"
                value={max_input_short_circuit_current || ''}
                onChange={e => handleElectricalDetails(e, 'max_input_short_circuit_current')}
                error={!isEmpty(get(errorData, 'max_input_short_circuit_current'))}
                helperText={get(errorData, 'max_input_short_circuit_current')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_output_voltage'}
                className={classes.inputField}
                label={<Label text={'Maximum Output Voltage (V)'} isRequired />}
                type="number"
                value={max_output_voltage || ''}
                onChange={e => handleElectricalDetails(e, 'max_output_voltage')}
                error={!isEmpty(get(errorData, 'max_output_voltage'))}
                helperText={get(errorData, 'max_output_voltage')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'min_input_voltage'}
                className={classes.inputField}
                label={<Label text={'Minimum Input Voltage (V)'} isRequired />}
                type="number"
                value={min_input_voltage || ''}
                onChange={e => handleElectricalDetails(e, 'min_input_voltage')}
                error={!isEmpty(get(errorData, 'min_input_voltage'))}
                helperText={get(errorData, 'min_input_voltage')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_input_voltage'}
                className={classes.inputField}
                label={<Label text={'Maximum Input Voltage (V)'} isRequired />}
                type="number"
                value={max_input_voltage || ''}
                onChange={e => handleElectricalDetails(e, 'max_input_voltage')}
                error={!isEmpty(get(errorData, 'max_input_voltage'))}
                helperText={get(errorData, 'max_input_voltage')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'input_power_rating'}
                className={classes.inputField}
                label={<Label text={'Input Power Rating (W)'} isRequired />}
                type="number"
                value={input_power_rating || ''}
                onChange={e => handleElectricalDetails(e, 'input_power_rating')}
                error={!isEmpty(get(errorData, 'input_power_rating'))}
                helperText={get(errorData, 'input_power_rating')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_nominal_power_per_string'}
                className={classes.inputField}
                label={<Label text={'Maximum Nominal Power Per String (W)'} />}
                type="number"
                value={max_nominal_power_per_string || ''}
                onChange={e => handleElectricalDetails(e, 'max_nominal_power_per_string')}
                error={!isEmpty(get(errorData, 'max_nominal_power_per_string'))}
                helperText={get(errorData, 'max_nominal_power_per_string')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_efficiency'}
                className={classes.inputField}
                label={<Label text={'Maximum Efficiency (%)'} isRequired />}
                type="number"
                value={max_efficiency || ''}
                onChange={e => handleElectricalDetails(e, 'max_efficiency')}
                error={!isEmpty(get(errorData, 'max_efficiency'))}
                helperText={get(errorData, 'max_efficiency')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
          <Box className={classes.addressWrapper}>
              <TextField
                id={'cec_weighted_efficiency'}
                className={classes.inputField}
                label={<Label text={'CEC Weighted Efficiency (%)'} />}
                type="number"
                value={cec_weighted_efficiency || ''}
                onChange={e => handleElectricalDetails(e, 'cec_weighted_efficiency')}
                error={!isEmpty(get(errorData, 'cec_weighted_efficiency'))}
                helperText={get(errorData, 'cec_weighted_efficiency')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'min_string_length'}
                className={classes.inputField}
                label={<Label text={'Minimum String Length'} />}
                type="number"
                value={min_string_length || ''}
                onChange={e => handleElectricalDetails(e, 'min_string_length')}
                error={!isEmpty(get(errorData, 'min_string_length'))}
                helperText={get(errorData, 'min_string_length')}
                disabled={readOnly}
              />
            </Box>
            </Grid>
          </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ElectricalDetailsSection;
