import React from 'react';
import { Label } from '../../../../../../common/Label';
import { get, isEmpty } from '../../../../../../../utils/lodash';
import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import CustomPanel from '../../../../../../common/PanelTemplateWithRadioButton';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  }
}));

const ElectricalDetailsSection = props => {
  const classes = useStyles();
  const { unit, errorData, preventDefault, handlePhysicalDetails, physicalDetails, sectionLabel, readOnly } = props;
  const { weight = '', width = '', height = '', depth = '', number_of_dc_inputs = '' } = physicalDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel
        header={sectionLabel}
        radioDetails={{
          handleFunction: handlePhysicalDetails,
          value: unit
        }}
      >
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'weight'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Total Weight (Installed, with Mounting) ${unit === 'imperial' ? '(lb)' : '(kg)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={weight || ''}
                onChange={e => handlePhysicalDetails(e, 'weight')}
                error={!isEmpty(get(errorData, 'weight'))}
                helperText={get(errorData, 'weight')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'width'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Width ${unit === 'imperial' ? '(in)' : '(mm)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={width || ''}
                onChange={e => handlePhysicalDetails(e, 'width')}
                error={!isEmpty(get(errorData, 'width'))}
                helperText={get(errorData, 'width')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'height'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Height ${unit === 'imperial' ? '(in)' : '(mm)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={height || ''}
                onChange={e => handlePhysicalDetails(e, 'height')}
                error={!isEmpty(get(errorData, 'height'))}
                helperText={get(errorData, 'height')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'depth'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Depth ${unit === 'imperial' ? '(in)' : '(mm)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={depth || ''}
                onChange={e => handlePhysicalDetails(e, 'depth')}
                error={!isEmpty(get(errorData, 'depth'))}
                helperText={get(errorData, 'depth')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'number_of_dc_inputs'}
                className={classes.inputField}
                label={<Label text={'Number of DC inputs'} capitalizeLabelText={false} isRequired />}
                type="number"
                value={number_of_dc_inputs || ''}
                onKeyPress={e => preventDefault(e)}
                onChange={e => handlePhysicalDetails(e, 'number_of_dc_inputs')}
                error={!isEmpty(get(errorData, 'number_of_dc_inputs'))}
                helperText={get(errorData, 'number_of_dc_inputs')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ElectricalDetailsSection;
