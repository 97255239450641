import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { default as React, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { preventDefault } from '../../../containers/Admin/helper';
import {
  ELECTRICAL_TAGS,
  FEATURE_FLAG_TYPE,
  FEATURE_FLAGS,
  MSP_UM_MARKER,
} from '../../../containers/Permitting/constants';
import { isPermitSetAvailable } from '../../../utils/helper';
import { clone, get, isEmpty, isEqual, split, throttle } from '../../../utils/lodash';
import { Label, useLabelStyles } from '../../common/Label';
import PanelTemplate from '../../common/PanelTemplate';
import FileUploadDialog from '../FileUploadDialog';
import PhotoUploadSection from '../UploadFile/uploadPhoto';
import MSP from './images/msp.png';
import MSP_IMG from './images/mspPointer.png';
import UM from './images/um.png';
import UM_IMG from './images/umPointer.png';

// Feature Flag helper
import { isFeatureEnabled } from '../../../containers/Permitting/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2.5),
  },
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  electricalContainer: {
    padding: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
  },
  itemsHolder: {
    position: 'relative',
    '&>div': {
      margin: '24px 8px 0px 8px',
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
  mapArea: {
    width: '100%',
    height: theme.spacing(40),
    backgroundColor: '#0001',
    textAlign: 'center',
    '& img': {
      width: '100%',
      height: '100%',
    },
    '&&': {
      marginTop: 0,
    },
    [theme.breakpoints.down('md')]: {
      '& .gmnoprint .gm-style-mtc div:nth-child(2)': {
        top: '0px !important',
        left: '100% !important',
        width: '68px !important',
        marginLeft: '1px !important',
        '& label': {
          fontSize: 14,
        },
        '& div': {
          padding: '6px 8px 7px 0px !important',
        },
      },
      '& .gmnoprint .gm-style-mtc div:nth-child(1)': {
        padding: '4px !important',
      },
    },
  },
  breakerSpace: {
    paddingTop: theme.spacing(2),
  },
  spinner: {
    width: '100%',
    height: '401px',
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    backgroundColor: '#0000005e',
    '& div': {
      margin: 'auto',
    },
  },
  hide: {
    height: 0,
    visibility: 'collapse',
  },
  arrayInputField: {
    width: '100%',
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  searchArea: {
    position: 'relative',
    height: 0,
    left: '189px',
    top: '10px',
    zIndex: 1,
    marginTop: 0,
    '&>div': {
      backgroundColor: '#fff',
      borderRadius: 2,
    },
    '& input': {
      padding: '10px 0 !important',
    },
    [theme.breakpoints.between(0, 1700)]: {
      top: theme.spacing(6.5),
      left: theme.spacing(1.25),
      width: 'calc(100% - 20px) !important',
    },
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  inline: {
    marginTop: '0 !important',
    display: 'grid',
    gridRowGap: theme.spacing(2),
    gridColumnGap: theme.spacing(3),
    gridTemplateColumns: 'repeat( auto-fit, minmax(100%, 1fr))',
    '&>div': {
      padding: 0,
      '&>div': {
        width: '100%',
      },
    },
  },
  mspUpgrade: {
    paddingTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'space-between',
  },
  solarReady: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  fileUpload: {
    fontSize: 14,
    color: '#008EEF',
    cursor: 'pointer',
  },
  important: {
    '& span': {
      color: 'red',
      display: 'inline',
    },
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
  },
  otherSelect: {
    paddingRight: theme.spacing(2),
  },
  otherInput: {
    width: '100%',
  },
  formWrapper: {
    '& .MuiInputLabel-shrink': {
      width: '150%',
    },
  },
  mapItemWrapper: {
    maxWidth: 'calc(45% + 28px)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  newGrid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputItemWrapper: {
    maxWidth: '49%',
    padding: '12px 0 12px 12px !important',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
      paddingRight: '28px !important',
    },
  },
  gridContainer: {
    justifyContent: 'space-between',
  },
  dropdownSectionHeaderStyle: {
    color: theme.palette.primary.grey,
    marginLeft: theme.spacing(2),
  },
  dividerStyle: {
    background: theme.palette.primary.grey,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  otherInfo: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 'auto',
    },
  },
  otherGrid: {
    width: '60%',
    [theme.breakpoints.between(0, 1430)]: {
      width: '100%',
    },
  },
  normalGrid: {
    width: '100%',
    display: 'flex',
  },
  displayFlex: {
    display: 'flex',
  },
  imagePhotoBorder: {
    marginTop: theme.spacing(1),
  },
  orSpanStyle: {
    padding: theme.spacing(3, 3, 0, 3),
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2, 0, 1),
    },
  },
  gridItem: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1)
  },
  gridPhotoItem: {
    //marginBottom: theme.spacing(1)
  },
  fontSize14: {
    fontSize: 14,
    paddingTop: theme.spacing(3),
  },
  photoImgSection: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  imgageBox: {
    padding: theme.spacing(5),
  },
  fontSize20: {
    fontSize: 28,
    paddingTop: theme.spacing(1.5),
  },
  flexInGrid: {
    display: 'flex',
  },
  radioStoreWrapper: {
    paddingLeft: theme.spacing(2),
    justifyContent: 'end',
  },
  photoUploadSection: {
    marginTop: theme.spacing(2),
  },
  close: {
    opacity: 0.8,
    cursor: 'pointer',
    fontSize: theme.spacing(2),
    position: 'relative',
    top: theme.spacing(0.5),
  },
  photosArray: {
    paddingRight: theme.spacing(1),
  },
  paddingTop2: {
    paddingTop: theme.spacing(2),
  },
  paddingTop3: {
    paddingTop: theme.spacing(2.5),
  },
  dropdown: {
    maxWidth: theme.spacing(50),
  },
  infoText: {
    fontSize: 14,
    opacity: 0.8,
  },
  withoutFormControl: {
    minWidth: '45%',
    width: '100%',
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  umPinIcon: {
    position: 'absolute',
    top: theme.spacing(1.25),
    right: theme.spacing(7.5),
    zIndex: 1,
  },
  mspPinIcon: {
    position: 'absolute',
    top: theme.spacing(1.25),
    right: theme.spacing(13.5),
    zIndex: 1,
  },
  highlightImg: {
    border: '1px solid white',
  },
  numberField: {
    width: '93%',
    margin: theme.spacing(1, 0, 0, 0),
  },
  subText: {
    fontSize: theme.spacing(1.75),
    color: theme.palette.primary.dataBlack,
  },
}));

const autocompleteService = { current: null };
const mapRef = { current: null };
const mapObjRef = { current: null };
let marker = null;
let umMarker = null;
let lastDragLoc = { lat: null, lng: null };
const geocoder = window.google && new window.google.maps.Geocoder();

export const SelectDropdown = (props) => {
  const {
    id,
    className,
    value = '',
    handleChange,
    optionList = [],
    otherOptionList = [],
    labelText,
    isRequired = true,
    helperText,
    error = false,
    classes,
    labelClass,
    isPermitSetAvailable,
    showRecent = false,
    mostUsedList = [],
    otherGrid = false,
  } = props;
  let { isOtherSelected = false, otherSelectedValue = '' } = props;
  const selectValue = value || '';
  const modifiedOptionList = optionList;
  if (selectValue && !modifiedOptionList.includes(selectValue) && !otherOptionList.includes(selectValue)) {
    if (isPermitSetAvailable) {
      modifiedOptionList.push(selectValue);
    } else {
      isOtherSelected = true;
      otherSelectedValue = split(selectValue, '-', 2)[1];
    }
  }
  const recentList = mostUsedList.filter((value) => {
    if (value.length > 0 && modifiedOptionList.includes(value)) {
      return value;
    } else {
      return null;
    }
  });
  const showRecentDropdown = showRecent && recentList.length > 0 && modifiedOptionList.length > 5;
  const showOtherSection = otherOptionList.length > 0;
  const filtererModifiedOptionList =
    modifiedOptionList.length > 5
      ? modifiedOptionList.filter((value) => recentList.indexOf(value) === -1)
      : modifiedOptionList;
  return (
    <Grid className={clsx(otherGrid ? classes.otherGrid : classes.normalGrid)}>
      <Grid
        item
        xs={isOtherSelected && !otherGrid ? 6 : 12}
        sm={isOtherSelected && !otherGrid ? 6 : 12}
        className={clsx(isOtherSelected && classes.otherSelect)}
      >
        <FormControl id={id} className={clsx(className, classes.formWrapper)} error={error}>
          {labelText && (
            <InputLabel id={`${id}-label`}>
              <Label className={classes.labelColor} text={labelText} isRequired={isRequired} />
            </InputLabel>
          )}
          <Select
            id={`${id}-select`}
            labelId={`${id}-label`}
            value={isOtherSelected ? 'Other' : selectValue.toString()}
            IconComponent={(props) => <ExpandMoreIcon className="MuiSelect-icon" />}
            onChange={(e) => handleChange(e.target.value, id, false)}
            className={classes.dropdown}
          >
            {showRecentDropdown && (
              <Typography className={classes.dropdownSectionHeaderStyle}>Recently Used</Typography>
            )}
            {showRecentDropdown &&
              recentList.map((value, index) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ))}
            {showRecentDropdown && <Divider variant="middle" classes={{ root: classes.dividerStyle }} />}
            {!isRequired && <MenuItem value={''}>Please Select</MenuItem>}
            {filtererModifiedOptionList &&
              filtererModifiedOptionList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            {showOtherSection && <Divider variant="middle" classes={{ root: classes.dividerStyle }} />}
            {showOtherSection && <Typography className={classes.dropdownSectionHeaderStyle}>Other Values</Typography>}
            {showOtherSection &&
              otherOptionList.map((option) => (
                <MenuItem key={option} value={option}>
                  {split(option, '-', 2)[1]}
                </MenuItem>
              ))}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </Grid>
      {isOtherSelected && (
        <Grid item xs={!otherGrid ? 6 : 11} sm={!otherGrid ? 6 : 11}>
          <TextField
            id={`other-${id}`}
            className={clsx(labelClass.customLabel, classes.otherInput)}
            label={<Label className={classes.labelColor} text={`Provide value`} />}
            value={otherSelectedValue}
            onChange={(e) => handleChange(e.target.value, id, true)}
            inputProps={{
              maxLength: 500,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

const UploadPhotoSection = (props) => {
  const {
    id,
    classes,
    photos,
    photosEmpty,
    keyValue = '',
    deletePhotos,
    handleFileDialogOpen,
    isRequired,
    error,
    errorText = '',
    getS3URL,
  } = props;

  const canUpload = photos.length < 1;
  let content;
  switch (keyValue) {
    case 'utility_meter':
      content = 'Utility Meter Photo';
      break;
    case 'sub_panel':
      content = 'Existing Open Sub Panel Photo';
      break;
    case 'sub_panel_sticker':
      content = 'Sub Panel Sticker Photo';
      break;
    case 'service_panel_without_dead_front':
      content = 'Main Service Panel Without Dead Front';
      break;
    case 'service_panel_sticker':
      content = 'Main Service Panel Sticker Photo';
      break;
    default:
      content = 'Main Service Panel With Dead Front';
      break;
  }
  return (
    <PhotoUploadSection
      content={content}
      canUpload={canUpload}
      id={id}
      keyValue={keyValue}
      photos={photos}
      error={error}
      errorText={errorText}
      handleFileDialogOpen={handleFileDialogOpen}
      deletePhotos={deletePhotos}
      isRequired={isRequired}
      getS3URL={getS3URL}
    />
  );
};

function ElectricalProperties(props) {
  const {
    materialDetails,
    userSelectionData,
    otherUserSelectionData,
    handleChange,
    projectDetails,
    getImgLink,
    deletePhotos,
    fireUpload,
    applicationDetail,
    mostUsedValues,
    featureList = [],
  } = props;

  const isOptionalPhotoUploadEnabled = isFeatureEnabled(
    featureList,
    FEATURE_FLAGS.OPTIONAL_PHOTO_UPLOAD,
    FEATURE_FLAG_TYPE.company
  );

  const permitFilesAvailable = isPermitSetAvailable(applicationDetail);
  const partialStorageBackup = get(materialDetails, 'projectDetails.home_backup_option', '') === 'partial';
  const { electrical_properties, errors = {} } = materialDetails;
  const {
    service_type,
    utility_meter_location,
    feeder_type,
    main_bus_rating,
    main_breaker_rating,
    main_breaker_location,
    existing_grounding,
    existing_subpanel,
    subpanel_breaker_rating,
    subpanel_bus_rating,
    msp_upgrade,
    solar_ready,
    spare_breaker_count,
    bus_rating,
    main_breaker,
    msp_location,
    location_image_file_name,
    coordinates,
    um_coordinates,
    other_information,
  } = electrical_properties;
  const {
    service_type: serviceTypeOptions = [],
    utility_meter_location: utilityMeterLocationOptions = [],
    feader_type: feederTypeOptions = [],
    bus_rating: busRatingOptions = [],
    main_breaker_rating: mainBreakerRatingOptions = [],
    main_breaker_location: mainBreakerLocationOptions = [],
    existing_grounding: existingGroundingOptions = [],
    subpanel_bus_rating: subpanelBusRatingOptions = [],
    subpanel_breaker_rating: subpanelBreakerRatingOptions = [],
    generator_manufacturer: generatorManufacturerOptions = ['Other'],
    generator_model: generatorModelOptions = ['Other'],
  } = userSelectionData;
  const {
    service_type: otherServiceTypeOptions = [],
    main_bus_rating: otherBusRatingOptions = [],
    main_breaker_rating: otherMainBreakerRatingOptions = [],
    existing_grounding: otherExistingGroundingOptions = [],
    subpanel_bus_rating: otherSubpanelBusRatingOptions = [],
    subpanel_breaker_rating: otherSubpanelBreakerRatingOptions = [],
    bus_rating: otherMSPBusRatingOptions = [],
    main_breaker: otherMSPBreakerRatingOptions = [],
    generator_manufacturer: otherGeneratorManufacturerOptions = [],
    generator_model: otherGeneratorModelOptions = [],
  } = otherUserSelectionData;
  const classes = useStyles();
  const labelClass = useLabelStyles();
  const [errMsg, updateErrMsg] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [isLocationEdit, toggleIsLocationEdited] = useState(false);
  const [pointerSelected, setPointerSelected] = useState({
    mspPointer: false,
    umPointer: false,
  });
  const [value, setValue] = useState(null);
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [isMapInitialized, setIsMapInitialized] = useState(false);

  const [otherServiceTypeEnabled, setOtherServiceTypeEnabled] = useState(false);
  const [otherServiceType, setOtherServiceType] = useState('');

  const [otherFeederTypeEnabled, setOtherFeederTypeEnabled] = useState(false);
  const [otherFeederType, setOtherFeederType] = useState('');

  const [otherMainBusRatingEnabled, setOtherMainBusRatingEnabled] = useState(false);
  const [otherMainBusRating, setOtherMainBusRating] = useState('');

  const [otherMainBreakerRatingEnabled, setOtherMainBreakerRatingEnabled] = useState(false);
  const [otherMainBreakerRating, setOtherMainBreakerRating] = useState('');

  const [otherExistingGroundingEnabled, setOtherExistingGroundingEnabled] = useState(false);
  const [otherExistingGrounding, setOtherExistingGrounding] = useState('');

  const [otherSubpanelBreakerRatingEnabled, setOtherSubpanelBreakerRatingEnabled] = useState(false);
  const [otherSubpanelBreakerRating, setOtherSubpanelBreakerRating] = useState('');

  const [otherSubpanelBusRatingEnabled, setOtherSubpanelBusRatingEnabled] = useState(false);
  const [otherSubpanelBusRating, setOtherSubpanelBusRating] = useState('');

  const [otherBusRatingEnabled, setOtherBusRatingEnabled] = useState(false);
  const [otherBusRating, setOtherBusRating] = useState('');

  const [otherMainBreakerEnabled, setOtherMainBreakerEnabled] = useState(false);
  const [otherMainBreaker, setOtherMainRating] = useState('');

  const [otherGeneratorManufacturerEnabled, setOtherGeneratorManufacturerEnabled] = useState(false);
  const [otherGeneratorManufacturer, setOtherGeneratorManufacturer] = useState('');

  const [otherGeneratorModelEnabled, setOtherGeneratorModelEnabled] = useState(false);
  const [otherGeneratorModel, setOtherGeneratorModel] = useState('');

  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [imageSectionKey, setImageSectionKey] = useState('');

  const serviceTypeTag = ELECTRICAL_TAGS.msp_type;
  const utilityMeterTag = ELECTRICAL_TAGS.utility_meter;
  const subPanelTag = ELECTRICAL_TAGS.subpanel_breaker_rating;
  const servicePanelStickerTag = ELECTRICAL_TAGS.service_panel_sticker;
  const servicePanelWithoutDeadFrontTag = ELECTRICAL_TAGS.service_panel_without_dead_front;

  let serviceTypePhotos = [],
    utilityMeterPhotos = [],
    subPanelPhotos = [],
    subPanelStickerPhotos = [],
    servicePanelStickerPhotos = [],
    servicePanelWithoutDeadFrontPhotos = [];

  for (let index in materialDetails.photos) {
    let photo = materialDetails.photos[index];
    photo['ind'] = index;
    if (serviceTypeTag === photo.tag) {
      serviceTypePhotos.push(photo);
    }
    if (utilityMeterTag === photo.tag) {
      utilityMeterPhotos.push(photo);
    }
    if (subPanelTag === photo.tag) {
      subPanelPhotos.push(photo);
    }
    if (servicePanelWithoutDeadFrontTag === photo.tag) {
      servicePanelWithoutDeadFrontPhotos.push(photo);
    }
    if (servicePanelStickerTag === photo.tag) {
      servicePanelStickerPhotos.push(photo);
    }
  }

  const serviceTypePhotosEmpty = serviceTypePhotos.length === 0;
  const utilityMeterPhotosEmpty = utilityMeterPhotos.length === 0;
  const subPanelPhotosEmpty = subPanelPhotos.length === 0;
  const subPanelStickerPhotosEmpty = subPanelPhotos.length === 0;
  const servicePanelStickerPhotosEmpty = subPanelPhotos.length === 0;
  const servicePanelWithoutDeadFrontPhotosEmpty = subPanelPhotos.length === 0;

  const handleElectricalChangeChange = (value, key, fromOtherOption = false, skipValidation = false) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'service_type':
        if (value === 'Other') {
          setOtherServiceTypeEnabled(true);
        } else if (!fromOtherOption) {
          setOtherServiceTypeEnabled(false);
        }
        break;
      case 'feeder_type':
        if (value === 'Other') {
          setOtherFeederTypeEnabled(true);
        } else if (!fromOtherOption) {
          setOtherFeederTypeEnabled(false);
        }
        break;
      case 'main_bus_rating':
        if (value === 'Other') {
          setOtherMainBusRatingEnabled(true);
        } else if (!fromOtherOption) {
          setOtherMainBusRatingEnabled(false);
        }
        break;
      case 'main_breaker_rating':
        if (value === 'Other') {
          setOtherMainBreakerRatingEnabled(true);
        } else if (!fromOtherOption) {
          setOtherMainBreakerRatingEnabled(false);
        }
        break;

      case 'existing_grounding':
        if (value === 'Other') {
          setOtherExistingGroundingEnabled(true);
        } else if (!fromOtherOption) {
          setOtherExistingGroundingEnabled(false);
        }
        break;

      case 'subpanel_breaker_rating':
        if (value === 'Other') {
          setOtherSubpanelBreakerRatingEnabled(true);
        } else if (!fromOtherOption) {
          setOtherSubpanelBreakerRatingEnabled(false);
        }
        break;

      case 'subpanel_bus_rating':
        if (value === 'Other') {
          setOtherSubpanelBusRatingEnabled(true);
        } else if (!fromOtherOption) {
          setOtherSubpanelBusRatingEnabled(false);
        }
        break;

      case 'bus_rating':
        if (value === 'Other') {
          setOtherBusRatingEnabled(true);
        } else if (!fromOtherOption) {
          setOtherBusRatingEnabled(false);
        }
        break;

      case 'main_breaker':
        if (value === 'Other') {
          setOtherMainBreakerEnabled(true);
        } else if (!fromOtherOption) {
          setOtherMainBreakerEnabled(false);
        }
        break;

      // case 'generator_manufacturer':
      //   if (value === 'Other') {
      //     setOtherGeneratorManufacturerEnabled(true);
      //   } else if (!fromOtherOption) {
      //     setOtherGeneratorManufacturerEnabled(false);
      //   }
      //   break;

      // case 'generator_model':
      //   if (value === 'Other') {
      //     setOtherGeneratorModelEnabled(true);
      //   } else if (!fromOtherOption) {
      //     setOtherGeneratorModelEnabled(false);
      //   }
      //   break;
    }
    if (fromOtherOption) value = `Other-${value}`;
    const data = clone(electrical_properties);
    data[key] = value;
    handleChange(data, 'electrical_properties', { additionalKey: key }, skipValidation);
  };

  useEffect(() => {
    if (otherServiceTypeEnabled) {
      const otherAttachments = split(service_type, '-', 2)[1];
      setOtherServiceType(otherAttachments);
    }

    const isOtherFeederValue = isEqual(split(feeder_type, '-', 1)[0], 'Other');
    if (isOtherFeederValue) {
      const otherAttachments = split(feeder_type, '-', 2)[1];
      setOtherFeederType(otherAttachments);
      setOtherFeederTypeEnabled(true);
    }

    if (otherMainBusRatingEnabled) {
      const otherAttachments = split(main_bus_rating, '-', 2)[1];
      setOtherMainBusRating(otherAttachments);
    }

    if (otherMainBreakerRatingEnabled) {
      const otherAttachments = split(main_breaker_rating, '-', 2)[1];
      setOtherMainBreakerRating(otherAttachments);
    }

    if (otherExistingGroundingEnabled) {
      const otherAttachments = split(existing_grounding, '-', 2)[1];
      setOtherExistingGrounding(otherAttachments);
    }

    if (otherSubpanelBreakerRatingEnabled) {
      const otherAttachments = split(subpanel_breaker_rating, '-', 2)[1];
      setOtherSubpanelBreakerRating(otherAttachments);
    }

    if (otherSubpanelBusRatingEnabled) {
      const otherAttachments = split(subpanel_bus_rating, '-', 2)[1];
      setOtherSubpanelBusRating(otherAttachments);
    }

    if (otherBusRatingEnabled) {
      const otherAttachments = split(bus_rating, '-', 2)[1];
      setOtherBusRating(otherAttachments);
    }

    if (otherMainBreakerEnabled) {
      const otherAttachments = split(main_breaker, '-', 2)[1];
      setOtherMainRating(otherAttachments);
    }

    // if (otherGeneratorManufacturerEnabled) {
    //   const otherAttachments = split(generator_manufacturer, '-', 2)[1];
    //   setOtherGeneratorManufacturer(otherAttachments);
    // }

    // if (otherGeneratorModelEnabled) {
    //   const otherAttachments = split(generator_model, '-', 2)[1];
    //   setOtherGeneratorModel(otherAttachments);
    // }
  }, [
    service_type,
    feeder_type,
    main_bus_rating,
    main_breaker_rating,
    existing_grounding,
    subpanel_breaker_rating,
    subpanel_bus_rating,
    bus_rating,
    main_breaker,
  ]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );
  const handlePropertiesChange = useCallback(
    (value, key) => {
      handleChange({ ...electrical_properties, [key]: value }, 'electrical_properties', { additionalKey: key });
    },
    [electrical_properties, handleChange]
  );

  useEffect(() => {
    if (!imgUrl && location_image_file_name) {
      getImgLink(location_image_file_name, (res) => setImgUrl(res), 'GET');
    }
  }, [imgUrl, location_image_file_name, getImgLink]);

  const uploadImage = (file) => {
    if (file) {
      const extension = file.name.match(/(\.[a-z]+)$/g)[0];
      const name = `${props.permitId}_location_image${extension}`;
      handlePropertiesChange(name, 'location_image_file_name');
      props.getS3URL({
        fileName: name,
        methodType: 'PUT',
        successCb: (preSignedS3Url) =>
          props.putToS3({
            fileObj: file,
            preSignedS3Url: preSignedS3Url,
            fName: name,
            successCbS3: () => getImgLink(name, (res) => setImgUrl(res), 'GET'),
            failureCbS3: () => handlePropertiesChange('', 'location_image_file_name'),
          }),
        failureCb: () => handlePropertiesChange('', 'location_image_file_name'),
      });
    }
  };

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (searchValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch({ input: searchValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, searchValue, fetch]);

  const isEmptyLocation = (latLng) => {
    return isEmpty(latLng) || latLng.lat === null || latLng.lng === null;
  };

  // Population of map on first address population
  useEffect(() => {
    const { address } = projectDetails;
    if (address) {
      // no location provided -> to set map based on address
      const updateItems = electrical_properties;
      fetch({ input: address }, (res) => {
        if (res && res[0]) {
          geocoder.geocode({ placeId: res[0].place_id }, (results) => {
            if (results && results[0]) {
              const addressLatLng = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              };
              lastDragLoc = addressLatLng;
              mapObjRef.current.setCenter(addressLatLng);
              mapObjRef.current.setZoom(21);

              updateItems.msp_location = address;
              handleChange(updateItems, electrical_properties);
              setValue(address);
            }
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      // location already provided
      !isEmptyLocation(coordinates) ||
      !isEmptyLocation(um_coordinates)
    ) {
      if (marker === null || umMarker === null) {
        initializeMap();
      }
      marker.setMap(mapObjRef.current);
      umMarker.setMap(mapObjRef.current);
      if (!isEmptyLocation(coordinates)) {
        marker.setPosition(coordinates);
      }
      if (!isEmptyLocation(um_coordinates)) {
        umMarker.setPosition(um_coordinates);
      }
    }
  }, [marker, umMarker]);

  // Initalzation of map on componentDidMount
  useEffect(() => {
    if (!isMapInitialized) {
      initializeMap();
      setIsMapInitialized(true);
    }
  }, []);

  // to initiale the map and markers
  const initializeMap = () => {
    let active = true;
    if (mapRef.current && window.google && window.google.maps) {
      const location = new window.google.maps.LatLng(lastDragLoc.lat || 36.964, lastDragLoc.lng || -122.015);
      marker = new window.google.maps.Marker({
        draggable: true,
        icon: MSP_IMG,
      });
      umMarker = new window.google.maps.Marker({
        draggable: true,
        icon: UM_IMG,
      });
      mapObjRef.current = new window.google.maps.Map(mapRef.current, {
        center: location,
        zoom: coordinates.lat ? 21 : 7,
        mapTypeId: 'satellite',
      });
      mapObjRef.current.setTilt(0);
      geocoder.geocode({ location }, (results) => active && results[0] && setValue(results[0].formatted_address));
    } else {
      active && updateErrMsg('Unable to load map. Please refresh page.');
    }
    return () => {
      active = false;
    };
  };

  // Listener to map drag events
  useEffect(() => {
    let mspDragEventRef = '';
    let umDragEventRef = '';
    if (mapObjRef.current) {
      mspDragEventRef = window.google.maps.event.addListener(marker, 'dragend', (event) => {
        const latLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        handlePropertiesChange(latLng, 'coordinates');
        setPointerSelected({ mspPointer: false, umPointer: false });
      });
      umDragEventRef = window.google.maps.event.addListener(umMarker, 'dragend', (event) => {
        const latLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        handlePropertiesChange(latLng, 'um_coordinates');
        setPointerSelected({ mspPointer: false, umPointer: false });
      });
    }
    return () => {
      window.google.maps.event.removeListener(mspDragEventRef);
      window.google.maps.event.removeListener(umDragEventRef);
    };
  }, [handlePropertiesChange]);

  const toggleClick = () => {
    if (imgUrl) {
      handleChange(
        {
          ...electrical_properties,
          location_image_file_name: '',
        },
        'electrical_properties'
      );
      setImgUrl('');
    } else {
      setOpen(true);
    }
  };

  const handleFileDialogOpen = (key) => {
    switch (key) {
      case 'msp_type':
        setDialogTitle('Photo of Main Service Panel With Dead Front');
        setImageSectionKey(serviceTypeTag);
        break;
      case 'utility_meter':
        setDialogTitle('Photo of the Utility meter');
        setImageSectionKey(utilityMeterTag);
        break;
      case 'sub_panel':
        setDialogTitle('Photo of SubPanel');
        setImageSectionKey(subPanelTag);
        break;
      case 'service_panel_without_dead_front':
        setDialogTitle('Photo of Main Service Panel Without Dead Front');
        setImageSectionKey(servicePanelWithoutDeadFrontTag);
        break;
      case 'service_panel_sticker':
        setDialogTitle('Photo of Main Service Panel Sticker');
        setImageSectionKey(servicePanelStickerTag);
        break;
    }
    setOpenFileDialog(true);
  };

  const togglePointer = (e) => {
    if (e.target.id === 'msp' && isEmptyLocation(coordinates)) {
      setPointerSelected((prev) => {
        return { mspPointer: true, umPointer: false };
      });
      marker.setPosition(lastDragLoc);
      marker.setMap(mapObjRef.current);
      handlePropertiesChange(lastDragLoc, 'coordinates');
    } else if (e.target.id === 'um' && isEmptyLocation(um_coordinates)) {
      setPointerSelected((prev) => {
        return { mspPointer: false, umPointer: true };
      });
      umMarker.setPosition(lastDragLoc);
      umMarker.setMap(mapObjRef.current);
      handlePropertiesChange(lastDragLoc, 'um_coordinates');
    }
  };

  const getErrorText = (key) => (get(errors, `${key}.error`) ? get(errors, `${key}.errorMessage`) : '');

  return (
    <div className={classes.root} id="electrical_properties">
      <PanelTemplate header="Electrical Properties" className={classes.panelContainer}>
        <Box className={clsx(classes.electricalContainer, permitFilesAvailable && classes.disableForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
                  <SelectDropdown
                    id="service_type"
                    labelText="Service Type"
                    className={clsx(classes.arrayInputField, labelClass.customLabel)}
                    value={service_type}
                    handleChange={handleElectricalChangeChange}
                    optionList={serviceTypeOptions}
                    otherOptionList={otherServiceTypeOptions}
                    isOtherSelected={otherServiceTypeEnabled}
                    otherSelectedValue={otherServiceType}
                    error={get(errors, `service_type.error`)}
                    helperText={getErrorText('service_type')}
                    classes={classes}
                    labelClass={labelClass}
                    isPermitSetAvailable={permitFilesAvailable}
                    showRecent={true}
                    mostUsedList={mostUsedValues['service_type']}
                    isRequired={serviceTypePhotosEmpty}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
                  <SelectDropdown
                    id="utility_meter_location"
                    labelText="Utility Meter Location"
                    className={clsx(classes.arrayInputField, labelClass.customLabel)}
                    value={utility_meter_location}
                    handleChange={handlePropertiesChange}
                    optionList={utilityMeterLocationOptions}
                    error={get(errors, `utility_meter_location.error`)}
                    helperText={getErrorText('utility_meter_location')}
                    classes={classes}
                    labelClass={labelClass}
                    isPermitSetAvailable={permitFilesAvailable}
                    showRecent={true}
                    mostUsedList={mostUsedValues['utility_meter_location']}
                    isRequired={utilityMeterPhotosEmpty}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
                  <SelectDropdown
                    id="feeder_type"
                    labelText="Utility Meter Entrance" //PMT-822
                    className={clsx(classes.arrayInputField, labelClass.customLabel)}
                    value={feeder_type}
                    handleChange={handleElectricalChangeChange}
                    optionList={feederTypeOptions}
                    isOtherSelected={otherFeederTypeEnabled}
                    otherSelectedValue={otherFeederType}
                    error={get(errors, `feeder_type.error`)}
                    helperText={getErrorText('feeder_type')}
                    classes={classes}
                    labelClass={labelClass}
                    isPermitSetAvailable={permitFilesAvailable}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
                  <Box className={classes.mspUpgrade}>
                    <FormControl component="div">
                      <FormLabel component="legend">MSP Upgrade?</FormLabel>
                      <RadioGroup
                        value={msp_upgrade}
                        aria-label="MSP Upgrade?"
                        name="customized-radios"
                        row
                        onChange={(e) =>
                          handlePropertiesChange(e.target.value === 'true' ? true : false, 'msp_upgrade')
                        }
                      >
                        <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                        <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                      </RadioGroup>
                    </FormControl>

                    {msp_upgrade && (
                      <Box className={classes.solarReady}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={solar_ready}
                              onChange={(e) => handlePropertiesChange(e.target.checked, 'solar_ready')}
                              color="primary"
                              name="solarReady"
                            />
                          }
                          label="Solar Ready"
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
                {/* <SelectDropdown
                  id="existing_generator"
                  labelText="Do you have existing Generator?"
                  className={clsx(classes.arrayInputField, labelClass.customLabel)}
                  value={existing_generator}
                  handleChange={handleElectricalChangeChange}
                  optionList={[ 'Yes', 'No' ]}
                  isRequired={true}
                  classes={classes}
                  labelClass={labelClass}
                  isPermitSetAvailable={permitFilesAvailable}
                  error={get(errors, `existing_generator.error`)}
                  helperText={getErrorText('existing_generator')}
                />

                {existing_generator === 'Yes' && (
                  <Box display="flex" className={classes.inline}>
                    <Box pr={2}>
                      <Autocomplete
                        className={classes.inputField}
                        id="generator_manufacturer"
                        options={mostUsedValues['generator_manufacturer']}
                        value={generator_manufacturer}
                        onChange={(e, value) => handleElectricalChangeChange(value, 'generator_manufacturer', false)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={get(errors, `generator_manufacturer.error`)}
                            helperText={getErrorText('generator_manufacturer')}
                            label={<Label text={'Generator Manufacturer'} isRequired />}
                            onChange={e =>
                              handleElectricalChangeChange(e.target.value, 'generator_manufacturer', false)}
                          />
                        )}
                      />
                    </Box>
                    <Box pl={2}>
                      <Autocomplete
                        className={classes.inputField}
                        id="generator_model"
                        options={mostUsedValues['generator_model']}
                        value={generator_model}
                        onChange={(e, value) => handleElectricalChangeChange(value, 'generator_model', false)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={get(errors, `generator_model.error`)}
                            helperText={getErrorText('generator_model')}
                            label={<Label text={'Generator Model'} isRequired />}
                            onChange={e => handleElectricalChangeChange(e.target.value, 'generator_model', false)}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )} */}
              </Grid>
              <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
                <SelectDropdown
                  id="main_breaker_rating"
                  labelText={msp_upgrade ? 'Main Breaker Rating (New Panel)' : 'Main Breaker Rating'}
                  className={clsx(classes.arrayInputField, labelClass.customLabel)}
                  value={main_breaker_rating}
                  handleChange={handleElectricalChangeChange}
                  optionList={mainBreakerRatingOptions}
                  otherOptionList={otherMainBreakerRatingOptions}
                  isOtherSelected={otherMainBreakerRatingEnabled}
                  otherSelectedValue={otherMainBreakerRating}
                  error={get(errors, `main_breaker_rating.error`)}
                  helperText={getErrorText('main_breaker_rating')}
                  classes={classes}
                  labelClass={labelClass}
                  isPermitSetAvailable={permitFilesAvailable}
                  showRecent={true}
                  mostUsedList={mostUsedValues['main_breaker_rating']}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
              <Box display="flex" flexDirection="column" justifyContent="space-between" ml={1} mr={1}>
                <Box>
                  <Typography className={classes.important}>
                    {MSP_UM_MARKER.FIELD_TEXT}
                    <Typography component="span">*</Typography>
                  </Typography>
                  <Typography component="div" className={classes.subText}>
                    {MSP_UM_MARKER.INFO_TEXT}
                  </Typography>

                  <Typography component="div" onClick={toggleClick} className={classes.fileUpload}>
                    {imgUrl ? 'Use map' : 'Upload image'}
                  </Typography>
                </Box>

                {get(errors, `coordinates.error`) && !location_image_file_name && (
                  <Typography color="error">{getErrorText('coordinates')}</Typography>
                )}
                {get(errors, `um_coordinates.error`) && !location_image_file_name && (
                  <Typography color="error">{getErrorText('um_coordinates')}</Typography>
                )}
              </Box>

              <div className={clsx(classes.mapArea, !imgUrl && classes.hide)}>
                <img alt="site location" width={60} height={60} src={imgUrl} />
              </div>

              <Box className={classes.itemsHolder}>
                <Box className={clsx(!!imgUrl && classes.hide)} id="msp_location">
                  <img
                    className={
                      pointerSelected.mspPointer ? clsx(classes.highlightImg, classes.mspPinIcon) : classes.mspPinIcon
                    }
                    id="msp"
                    alt="msp-pointer"
                    src={MSP}
                    onClick={(e) => togglePointer(e)}
                  />
                  <img
                    className={
                      pointerSelected.umPointer ? clsx(classes.highlightImg, classes.umPinIcon) : classes.umPinIcon
                    }
                    id="um"
                    alt="utility-meter-pointer"
                    src={UM}
                    onClick={(e) => togglePointer(e)}
                  />
                  <FileUploadDialog
                    type={'Location Image'}
                    imageKey={'location_image_file_name'}
                    fireUpload={uploadImage}
                    open={open}
                    setOpen={setOpen}
                  />
                  {!imgUrl && location_image_file_name && (
                    <div className={classes.spinner}>
                      <CircularProgress />
                    </div>
                  )}
                  <div ref={mapRef} className={classes.mapArea}>
                    {errMsg}
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                  <SelectDropdown
                    id="main_bus_rating"
                    labelText={msp_upgrade ? 'Main Bus Rating (New Panel)' : 'Main Bus Rating'}
                    className={clsx(classes.arrayInputField, labelClass.customLabel)}
                    value={main_bus_rating}
                    handleChange={handleElectricalChangeChange}
                    optionList={busRatingOptions}
                    otherOptionList={otherBusRatingOptions}
                    isOtherSelected={otherMainBusRatingEnabled}
                    otherSelectedValue={otherMainBusRating}
                    error={get(errors, `main_bus_rating.error`)}
                    helperText={getErrorText('main_bus_rating')}
                    classes={classes}
                    labelClass={labelClass}
                    isPermitSetAvailable={permitFilesAvailable}
                    showRecent={true}
                    mostUsedList={mostUsedValues['main_bus_rating']}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                  <SelectDropdown
                    id="main_breaker_location"
                    labelText="Feed Location"
                    className={clsx(classes.arrayInputField, labelClass.customLabel)}
                    value={main_breaker_location}
                    handleChange={handleElectricalChangeChange}
                    optionList={mainBreakerLocationOptions}
                    isOtherSelected={false}
                    isRequired={false}
                    classes={classes}
                    labelClass={labelClass}
                    isPermitSetAvailable={permitFilesAvailable}
                    showRecent={true}
                    mostUsedList={mostUsedValues['main_breaker_location']}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                  <TextField
                    id="spare_breaker_count"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    className={clsx(classes.otherInfo, classes.dropdown)}
                    label={<Label className={classes.labelColor} text="Spare Breaker Count" />}
                    value={spare_breaker_count}
                    onKeyPress={(e) => preventDefault(e)}
                    onChange={(e) => handleElectricalChangeChange(e.target.value, 'spare_breaker_count')}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
                  <TextField
                    onChange={(e) => handleElectricalChangeChange(e.target.value, 'other_information')}
                    className={clsx(classes.otherInfo, classes.dropdown)}
                    label={<Label className={classes.labelColor} text="Notes/ Other Information" />}
                    value={other_information || ''}
                    inputProps={{
                      maxLength: 1024,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.photoUploadSection}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} className={classes.gridPhotoItem}>
                  <UploadPhotoSection
                    id="msp_type"
                    classes={classes}
                    deletePhotos={deletePhotos}
                    keyValue="msp_type"
                    photos={serviceTypePhotos}
                    photosEmpty={serviceTypePhotosEmpty}
                    handleFileDialogOpen={handleFileDialogOpen}
                    isRequired={!isOptionalPhotoUploadEnabled}
                    error={get(errors, `msp_type.error`)}
                    errorText={getErrorText('msp_type')}
                    getS3URL={props.getS3URL}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.gridPhotoItem}>
                  <UploadPhotoSection
                    id="service_panel_without_dead_front"
                    classes={classes}
                    deletePhotos={deletePhotos}
                    keyValue="service_panel_without_dead_front"
                    photos={servicePanelWithoutDeadFrontPhotos}
                    photosEmpty={servicePanelWithoutDeadFrontPhotosEmpty}
                    handleFileDialogOpen={handleFileDialogOpen}
                    isRequired={!isOptionalPhotoUploadEnabled}
                    error={get(errors, `service_panel_without_dead_front.error`)}
                    errorText={getErrorText('service_panel_without_dead_front')}
                    getS3URL={props.getS3URL}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.gridPhotoItem}>
                  <UploadPhotoSection
                    id="utility_meter"
                    classes={classes}
                    deletePhotos={deletePhotos}
                    keyValue="utility_meter"
                    photos={utilityMeterPhotos}
                    photosEmpty={utilityMeterPhotosEmpty}
                    handleFileDialogOpen={handleFileDialogOpen}
                    isRequired={!isOptionalPhotoUploadEnabled}
                    error={get(errors, `utility_meter.error`)}
                    errorText={getErrorText('utility_meter')}
                    getS3URL={props.getS3URL}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.gridPhotoItem}>
                  <UploadPhotoSection
                    id="service_panel_sticker"
                    classes={classes}
                    deletePhotos={deletePhotos}
                    keyValue="service_panel_sticker"
                    photos={servicePanelStickerPhotos}
                    photosEmpty={servicePanelStickerPhotosEmpty}
                    handleFileDialogOpen={handleFileDialogOpen}
                    isRequired={false}
                    getS3URL={props.getS3URL}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </PanelTemplate>
      <FileUploadDialog
        type="Photo"
        title={dialogTitle}
        otherDialog={true}
        imageSectionKey={imageSectionKey}
        imageKey="photos"
        fireUpload={fireUpload}
        open={openFileDialog}
        setOpen={() => setOpenFileDialog(!openFileDialog)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  mostUsedValues: state.permitReducer.mostUsedValues,
  otherUserSelectionData: state.permitReducer.otherUserSelectionData,
});

export default connect(mapStateToProps)(ElectricalProperties);
