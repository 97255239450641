import {
  MOVE_TO_PERMIT_DRAWINGS_COMPLETED_FAILURE,
  MOVE_TO_PERMIT_DRAWINGS_COMPLETED_REQUEST,
  MOVE_TO_PERMIT_DRAWINGS_COMPLETED_SUCCESS,
  MOVE_TO_UNDER_PROGRESS_FAILURE,
  MOVE_TO_UNDER_PROGRESS_REQUEST,
  MOVE_TO_UNDER_PROGRESS_SUCCESS,
  RESUBMISSION_RECEIVED_FAILURE,
  RESUBMISSION_RECEIVED_REQUEST,
  RESUBMISSION_RECEIVED_SUCCESS,
  RESUBMISSION_REQUESTED_FAILURE,
  RESUBMISSION_REQUESTED_REQUEST,
  RESUBMISSION_REQUESTED_SUCCESS,
  APPROVE_DOCUMENTS_REQUEST,
  APPROVE_DOCUMENTS_SUCCESS,
  APPROVE_DOCUMENTS_FAILURE,
  MOVE_TO_QA_COMPLETED_REQUEST,
  MOVE_TO_QA_COMPLETED_SUCCESS,
  MOVE_TO_QA_COMPLETED_FAILURE,
  INTERNAL_KICKBACK_REQUEST,
  INTERNAL_KICKBACK_SUCCESS,
  INTERNAL_KICKBACK_FAILURE,
  GET_INTERNAL_REVISION_DETAILS_REQUEST,
  GET_INTERNAL_REVISION_DETAILS_SUCCESS,
  GET_INTERNAL_REVISION_DETAILS_FAILURE,
  GET_EXTERNAL_REVISION_DETAILS_REQUEST,
  GET_EXTERNAL_REVISION_DETAILS_SUCCESS,
  GET_EXTERNAL_REVISION_DETAILS_FAILURE,
  STAMPING_SUBMIT_REQUEST,
  STAMPING_SUBMIT_SUCCESS,
  STAMPING_SUBMIT_FAILURE,
  SAVE_INTERNAL_NOTES_REQUEST,
  SAVE_INTERNAL_NOTES_SUCCESS,
  SAVE_INTERNAL_NOTES_FAILURE,
  SAVE_INTERNAL_FILES_REQUEST,
  SAVE_INTERNAL_FILES_SUCCESS,
  SAVE_INTERNAL_FILES_FAILURE,
  EDIT_INTERNAL_NOTES_REQUEST,
  EDIT_INTERNAL_NOTES_SUCCESS,
  EDIT_INTERNAL_NOTES_FAILURE,
  SEND_ADMIN_STAMPING_REQUEST,
  SEND_ADMIN_STAMPING_SUCCESS,
  SEND_ADMIN_STAMPING_FAILURE
} from './constants';

let initialState = {
  apiCallInProgress: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case MOVE_TO_UNDER_PROGRESS_REQUEST:
    case MOVE_TO_PERMIT_DRAWINGS_COMPLETED_REQUEST:
    case RESUBMISSION_REQUESTED_REQUEST:
    case RESUBMISSION_RECEIVED_REQUEST:
    case APPROVE_DOCUMENTS_REQUEST:
    case MOVE_TO_QA_COMPLETED_REQUEST:
    case INTERNAL_KICKBACK_REQUEST:
    case GET_INTERNAL_REVISION_DETAILS_REQUEST:
    case GET_EXTERNAL_REVISION_DETAILS_REQUEST:
    case STAMPING_SUBMIT_REQUEST:
    case SAVE_INTERNAL_NOTES_REQUEST:
    case SAVE_INTERNAL_FILES_REQUEST:
    case EDIT_INTERNAL_NOTES_REQUEST:
      return {
        ...state,
        apiCallInProgress: true,
      };
    case MOVE_TO_UNDER_PROGRESS_SUCCESS:
    case MOVE_TO_PERMIT_DRAWINGS_COMPLETED_SUCCESS:
    case RESUBMISSION_REQUESTED_SUCCESS:
    case RESUBMISSION_RECEIVED_SUCCESS:
    case APPROVE_DOCUMENTS_SUCCESS:
    case MOVE_TO_QA_COMPLETED_SUCCESS:
    case INTERNAL_KICKBACK_SUCCESS:
    case GET_INTERNAL_REVISION_DETAILS_SUCCESS:
    case GET_EXTERNAL_REVISION_DETAILS_SUCCESS:
    case STAMPING_SUBMIT_SUCCESS:
    case SAVE_INTERNAL_NOTES_SUCCESS:
    case SAVE_INTERNAL_FILES_SUCCESS:
    case EDIT_INTERNAL_NOTES_SUCCESS:
      return {
        ...state,
        apiCallInProgress: false,
      };
    case MOVE_TO_UNDER_PROGRESS_FAILURE:
    case MOVE_TO_PERMIT_DRAWINGS_COMPLETED_FAILURE:
    case RESUBMISSION_REQUESTED_FAILURE:
    case RESUBMISSION_RECEIVED_FAILURE:
    case APPROVE_DOCUMENTS_FAILURE:
    case MOVE_TO_QA_COMPLETED_FAILURE:
    case INTERNAL_KICKBACK_FAILURE:
    case GET_INTERNAL_REVISION_DETAILS_FAILURE:
    case GET_EXTERNAL_REVISION_DETAILS_FAILURE:
    case STAMPING_SUBMIT_FAILURE:
    case SAVE_INTERNAL_NOTES_FAILURE:
    case SAVE_INTERNAL_FILES_FAILURE:
    case EDIT_INTERNAL_NOTES_FAILURE:
    case SEND_ADMIN_STAMPING_REQUEST:
      return {
        ...state,
        apiCallInProgress: false
      };
    case SEND_ADMIN_STAMPING_SUCCESS:
      return {
        ...state,
        currentlySending: false
      };
    case SEND_ADMIN_STAMPING_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.error
      };
    default:
      return state;
  }
}

export default reducer;
