import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { AHJ_ELECTRICAL_PE, AHJ_STRUCTURAL_PE, REQUIREMENT_OPTIONS } from '../../../../containers/Admin/constants';
import { Label } from '../../../common/Label';
import { MultipleSelectDropdown } from '../../../common/MultipleSelectDropdown';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../common/SingleSelectDropdown';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  rowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
}));

const PVCommercialPermitDetailsSection = (props) => {
  const classes = useStyles();
  const { handlePVCommercialPermitDetails, pvCommercialPermitDetails, panelLabel, readOnly, stateSelected } = props;
  const {
    structural_peservices = [],
    structural_stamp_notes = '',
    electrical_peservices = [],
    electrical_stamp_notes = '',
  } = pvCommercialPermitDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={panelLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.rowWrapper}`}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'Structural PE services'} capitalizeLabelText={false} />}
                dropDownOptions={AHJ_STRUCTURAL_PE}
                handleField={handlePVCommercialPermitDetails}
                fieldId="structural_peservices"
                fieldValue={structural_peservices ?? []}
                readOnly={readOnly}
                stateSelected={stateSelected}
              />
            </Box>

            <Box className={classes.addressWrapper}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'Electrical PE services'} capitalizeLabelText={false} />}
                dropDownOptions={AHJ_ELECTRICAL_PE}
                handleField={handlePVCommercialPermitDetails}
                fieldId="electrical_peservices"
                fieldValue={electrical_peservices ?? []}
                readOnly={readOnly}
                stateSelected={stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.rowWrapper}`}>
              <TextField
                label="Structural stamp notes"
                className={clsx(classes.inputField, classes.dataSourceField)}
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={structural_stamp_notes ?? ''}
                onChange={(e) => handlePVCommercialPermitDetails(e, 'structural_stamp_notes')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                label="Electrical stamp notes"
                className={clsx(classes.inputField, classes.dataSourceField)}
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={electrical_stamp_notes ?? ''}
                onChange={(e) => handlePVCommercialPermitDetails(e, 'electrical_stamp_notes')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default PVCommercialPermitDetailsSection;
