import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Box,
  Divider,
  Typography,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEqual } from '../../../../utils/lodash';

import OutlinedButton from '../../../common/OutlinedButton';
import ContainedButton from '../../../common/ContainedButton';
import { PERMIT_SERVICE_ROLES, SOURCE } from '../../../../utils/constants';
import { COMPANY_DELIMITER } from '../../../../containers/Admin/constants';

import { companyObjectToString } from '../../../../containers/Admin/helper';

const useStyles = makeStyles(theme => ({
  dialogWidth: {
    minWidth: '45%',
    maxWidth: '45%',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 'unset',
  },
  inputFieldInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3, 1),
  },
  inputFieldHeading: {
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
  },
  inputFieldHint: {
    fontSize: theme.spacing(1.25),
  },
  autocompleteField: {
    padding: theme.spacing(0, 3),
  },
  installerTag: {
    color: theme.palette.primary.white,
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },
  installerTagArrow: {
    color: 'black',
  },
  installerTagTooltip: {
    position: 'relative',
    top: theme.spacing(1),
    background: 'black',
  },
  deleteIcon: {
    fill: theme.palette.primary.white,
  },
  information: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1, 3),
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  informationText: {
    margin: 'unset',
    fontSize: theme.spacing(1.5),
  },
}));

const InstallerListDialog = (props) => {
  const { options, handleChange, selectedCompanies, handleSave, handleClose } = props;

  const [selectedInstallers, setSelectedInstallers] = useState(selectedCompanies);
  const [inputValue, setInputValue] = useState('');
  const [disableSave, setDisableSave] = useState(true);

  useEffect(
    () => {
      setDisableSave(isEqual(selectedCompanies, selectedInstallers));
    },
    [selectedInstallers]
  );

  const source = SOURCE.SOLARGRAF;

  const getTagLabel = (value) => value.split(COMPANY_DELIMITER)[0].trim()

  const getTooltipLabel = (value) => {
    // remove '(' and ')'
    const prefix = COMPANY_DELIMITER.replace('(', '');
    const suffix = value.split(COMPANY_DELIMITER)[1].replace(')', '');
    return `${prefix} ${suffix}`;
  }

  const classes = useStyles();

  return (
    <Dialog
      open
      onClose={handleClose}
      classes={{ paper: classes.dialogWidth }}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <b>My Installer List</b>
          <FormControl>
            <RadioGroup row value={source}>
              <FormControlLabel
                value={SOURCE.ENLIGHTEN_PERMIT}
                control={
                  <Radio
                    size='small'
                    color='primary'
                    disabled={isEqual(source, SOURCE.SOLARGRAF)}
                  />
                }
                label="Enlighten"
              />
              <FormControlLabel
                value={SOURCE.SOLARGRAF}
                control={
                  <Radio
                    size='small'
                    color='primary'
                    disabled={isEqual(source, SOURCE.ENLIGHTEN_PERMIT)}
                  />
                }
                label="Solargraf"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>
        <Divider />
        <Box className={classes.inputFieldInfo}>
          <Typography
            component={'span'}
            classes={{ root: classes.inputFieldHeading }}
          >
            Installer list
          </Typography>
          <Typography
            component={'span'}
            classes={{ root: classes.inputFieldHint }}
          >
            Select installer name from the list
          </Typography>
        </Box>
        <Autocomplete
          multiple
          classes={{ root: classes.autocompleteField }}
          size='small'
          options={options.map(companyObjectToString('name', 'id'))}
          renderOption={(option, { selected }) => (
            <li>
              <Checkbox color='primary' checked={selected} />
              {option}
            </li>
          )}
          disableCloseOnSelect
          onChange={(e, value) => setSelectedInstallers(value)}
          defaultValue={selectedCompanies}
          renderTags={(value, getTagProps) =>
            value.map((option, index) =>
              <Tooltip
                arrow
                placement="top"
                title={getTooltipLabel(option)}
                classes={{
                  tooltip: classes.installerTagTooltip,
                  arrow: classes.installerTagArrow,
                }}>
                <Chip
                  {...getTagProps({ index })}
                  size='small'
                  label={getTagLabel(option)}
                  classes={{ root: classes.installerTag, deleteIcon: classes.deleteIcon }}
                />
              </Tooltip>
            )
          }
          inputValue={inputValue}
          renderInput={params => (
            <TextField
              {...params}
              variant='outlined'
              onChange={e => {
                handleChange(e);
                setInputValue(e.target.value);
              }}

            />)
          }
          onClose={() => { setInputValue('') }}
        >
        </Autocomplete>
        <Box className={classes.information}>
          <Typography paragraph classes={{ root: classes.informationText }}>
            <b>Application Dashboard listing page contains permit applications from: </b><br />
            Installers, part of 'My Installers' list, and <br />
            Installers, not part of any admin's 'My Installers' list<br /><br />
            <b>Note:</b> You will receieve email notifications for above permit applications only
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <OutlinedButton text={'Cancel'} handleClick={handleClose} />
        <ContainedButton
          text={'Save'}
          disabled={disableSave}
          handleClick={() => handleSave(selectedInstallers)}
        />
      </DialogActions>
    </Dialog>
  );
}

export default InstallerListDialog;
