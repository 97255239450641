import React from 'react';

const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.101" height="20" viewBox="0 0 20.101 20">
      <defs />
      <g transform="translate(0 -0.261)">
        <path
          fill="#008eef"
          d="M18.552,49.775a.5.5,0,0,0-.5.5v4.445a1.5,1.5,0,0,1-1.5,1.5H2.5a1.5,1.5,0,0,1-1.5-1.5V41.675a1.5,1.5,0,0,1,1.5-1.5H6.948a.5.5,0,1,0,0-1H2.5a2.506,2.506,0,0,0-2.5,2.5V54.721a2.506,2.506,0,0,0,2.5,2.5H16.55a2.506,2.506,0,0,0,2.5-2.5V50.276A.5.5,0,0,0,18.552,49.775Zm0,0"
          transform="translate(0 -36.963)"
        />
        <path
          fill="#008eef"
          d="M121.628.921a2.253,2.253,0,0,0-3.186,0l-8.931,8.931a.5.5,0,0,0-.128.22l-1.174,4.24a.5.5,0,0,0,.616.616l4.24-1.175a.5.5,0,0,0,.22-.128l8.931-8.932a2.255,2.255,0,0,0,0-3.186ZM110.6,10.177l7.31-7.31,2.357,2.357-7.31,7.31Zm-.471.945,1.883,1.884-2.605.722Zm11.377-7.136-.531.531-2.358-2.358.531-.531a1.251,1.251,0,0,1,1.77,0l.588.587A1.253,1.253,0,0,1,121.508,3.986Zm0,0"
          transform="translate(-102.774 0)"
        />
      </g>
    </svg>
  );
};

export default EditIcon;
