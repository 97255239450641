import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Label } from '../../common/Label';
import PanelTemplate from '../../common/PanelTemplate';
import { DEFAULT_SYSTEM_TYPES, PROJECT_TYPES, SOLAR_INSTALLATION_TYPES } from '../../../containers/Permitting/constants';
import HelpIcon from '../images/helpIcon';
import { LightTooltip } from '../../../containers/Permitting/ProjectDetails';
import { clone, get, isEmpty } from 'lodash';
import FileUploadDialog from '../FileUploadDialog';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2.5)
  },
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none'
  },
  systemTypeWrapper: {
    width: '100%',
    display: 'flex',
    margin: theme.spacing(3, 2, 0),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      minWidth: 'auto',
      display: 'block'
    }
  },
  inputField: {
    width: '100%',
    minWidth: theme.spacing(30)
  },
  solarTypeLabel: {
    fontSize: 16
  },
  systemTypeLabel: {
    marginBottom: theme.spacing(0.5),
    fontSize: 12
  },
  solarSelect: {
    minWidth: theme.spacing(40),
    width: '95%',
    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(30),
    }
  },
  radioWrapper: {
    backgroundColor: '#EFEFEF',
    paddingLeft: theme.spacing(2),
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  firstRadioLabel: {
    marginRight: theme.spacing(3)
  },
  radioLabel: {
    height: theme.spacing(4.75),
    '& .MuiRadio-root': {
      padding: theme.spacing(0, 1)
    },
    '& span': {
      fontSize: 12
    },
    '& svg': {
      width: theme.spacing(2),
      height: theme.spacing(2)
    }
  },
  radioStoreWrapper: {
    paddingLeft: theme.spacing(2),
    justifyContent: 'end'
  },
  addNew: {
    fontSize: 12,
    color: '#008EEF',
    display: 'inline-block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer'
  },
  button: {
    color: theme.palette.primary.white,
    marginLeft: theme.spacing(1.5),
    position: 'relative',
    top: theme.spacing(0.3)
  },
  close: {
    opacity: 0.8,
    cursor: 'pointer',
    fontSize: theme.spacing(2),
    position: 'relative',
    top: theme.spacing(0.5)
  },
  loader: {
    marginTop: theme.spacing(1)
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none'
  }
}));

const SystemDetails = ({
  materialDetails,
  handleProjectDetailsChange,
  handleProjectDetailsChanges,
  permitId,
  getS3URL,
  putToS3,
  siteDetails,
  paymentStatus,
  storageIncluded,
  isPermitSetAvailable
}) => {
  const classes = useStyles();
  const [ openFileUpload , setOpenFileUpload ] = useState(false);

  const solarInstallationTypes = Object.keys(SOLAR_INSTALLATION_TYPES).map(key => ({
    id: key,
    name: SOLAR_INSTALLATION_TYPES[key]
  }));
  const projectDetails = materialDetails && materialDetails.projectDetails;
  const isStorageChecked = projectDetails && projectDetails.system_types &&
    projectDetails.system_types.length > 1 && projectDetails.system_types[1].value;
  const existingDocs = (materialDetails && materialDetails.projectDetails && materialDetails.projectDetails.documents) || [];
  const noSolar = Number(get(materialDetails, 'projectDetails.system_type_v2.id')) !== 1;
  const isCommercial = get(materialDetails, 'projectDetails.project_type', '') === PROJECT_TYPES.COMMERCIAL;

  // Default selection of solar type
  // select no change if site details stage > 3 otherwise select new installation
  useEffect(() => {
    if (materialDetails && Number(get(materialDetails, 'projectDetails.system_type_v2.id')) === 0) {
      if (siteDetails && siteDetails.stage >= 3) {
        handleSolarTypeChange(2, true);
      } else {
        handleSolarTypeChange(1, true);
      }
    }
    if (
      materialDetails && get(materialDetails, 'projectDetails.system_types[1].value') &&
      get(materialDetails, 'projectDetails.home_backup_option') === null
    ) {
      handleProjectDetailsChange('home_backup_option', 'full', true);
    }
  }, [ materialDetails, siteDetails ]);

  const handleSolarTypeChange = (typeId, autoPopulated = false) => {
    let systemTypes = [];
    // 2 : No Change
    if (Number(typeId) === 2) {
      systemTypes = projectDetails && projectDetails.system_types ? projectDetails.system_types : DEFAULT_SYSTEM_TYPES;
      systemTypes[0].value = false;
      handleStorageCheckboxChange(true);
    } else {
      systemTypes = projectDetails && projectDetails.system_types ? projectDetails.system_types : DEFAULT_SYSTEM_TYPES;
      systemTypes[0].value = true;
    }
    handleProjectDetailsChanges(
      [ 'system_type_v2', 'system_types' ],
      [ { id: typeId, name: SOLAR_INSTALLATION_TYPES[typeId] }, systemTypes ],
      autoPopulated
    );
  };

  const handleBackupChange = (value) => {
    handleProjectDetailsChange('home_backup_option', value);
  };

  const handleStorageCheckboxChange = (value) => {
    const systemTypes = projectDetails && projectDetails.system_types ? projectDetails.system_types : DEFAULT_SYSTEM_TYPES;
    if (systemTypes[0].value || value) {
      systemTypes[1].value = value;
      const backup = value ? projectDetails?.home_backup_option : null;
      if (paymentStatus) {
        const openPopup = !storageIncluded && value;
        handleProjectDetailsChanges(
          ['system_types', 'openStoragePopup', 'home_backup_option'],
          [ systemTypes, openPopup, backup ]
        );
      } else {
        handleProjectDetailsChanges(
          ['system_types', 'home_backup_option'],
          [ systemTypes, backup ]
        );
      }
    }
  }

  const fireUpload = (file) => {
    if (file) {
      const docs = clone(get(materialDetails, 'projectDetails.documents', []) || []);
      const index = docs.length;
      const date = Date.now();
      const tag = 'Existing Permit Package';
      const array = file.name.match(/((\.[a-z]+)|(\.[A-Z]+))$/g);
      const extension = !isEmpty(array) ? array[0] : '';
      const name = `${permitId}_${date}${extension}`;
      docs.push({ loading: true, original_file_name: '' });
      handleProjectDetailsChange('documents', docs);
      getS3URL({
        fileName: name,
        methodType: 'PUT',
        successCb: preSignedS3Url => {
          putToS3({
            fileObj: file,
            preSignedS3Url: preSignedS3Url,
            fName: name,
            successCbS3: () => {
              docs.splice(index, 1);
              docs.push({
                file_name: name,
                original_file_name: file.name,
                tag,
                file_size: file.size / 1024 / 1024
              });
              handleProjectDetailsChange('documents', docs);
            },
            failureCbS3: () => {
              docs.splice(index, 1);
              handleProjectDetailsChange('documents', docs);
            }
          });
        },
        failureCb: () => {}
      });
    }
  };

  const deleteFile = (ind) => {
    const docs = clone(get(materialDetails, 'projectDetails.documents', []) || []);
    if (docs.length > ind) {
      docs.splice(ind, 1);
      handleProjectDetailsChange('documents', docs);
    }
  };

  const mainClass = clsx(classes.systemTypeWrapper, isPermitSetAvailable && classes.disableForm);
  return (
    <div className={classes.root}>
      <PanelTemplate header={'System Details'} className={classes.panelContainer}>
        <Box className={mainClass}>
          <Box className={classes.inputField}>
            <FormHelperText>
              <Label
                className={clsx(classes.systemTypeLabel, classes.typeLabel)}
                text="System Type"
                isRequired
              />
            </FormHelperText>
            <InputLabel id={`solar-type`}>
              <Label className={classes.solarTypeLabel} text={'PV'} />
            </InputLabel>
            <Select
              id={'solar_type'}
              value={(projectDetails.system_type_v2 && projectDetails.system_type_v2.id) || 1}
              className={classes.solarSelect}
              onChange={e => handleSolarTypeChange(e.target.value)}
            >
              {solarInstallationTypes.map(type => {
                return <MenuItem disabled={isCommercial && Number(type.id) === 2} value={type.id}>{type.name}</MenuItem>;
              })}
            </Select>
            {noSolar && (
              <div className={classes.existingPackageContainer}>
                {existingDocs.map((doc, i) => {
                  return (
                    <Box key={`existing-permit-file-${i}`}>
                      {doc.loading && <CircularProgress className={classes.loader} size={20} />}
                      {!doc.loading && (
                        <Box className={classes.displayFlex}>
                          <Typography variant="caption" display="block">
                            {doc.original_file_name}
                            <span className={classes.radioStoreWrapper}>
                              <CloseIcon onClick={() => deleteFile(i)} className={classes.close} />
                            </span>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
                <Typography onClick={() => setOpenFileUpload(true)}>
                  <Typography className={classes.addNew}>Upload Existing Permit Package</Typography>
                  <LightTooltip
                    title="Refers to the approved permit package which was created during the installation of existing panels. 
                    Details from this document will be used in creating the new permit package."
                    placement="top"
                    arrow
                  >
                    <span className={classes.button}>
                      <HelpIcon />
                    </span>
                  </LightTooltip>
                </Typography>
              </div>
            )}
          </Box>
          {!isCommercial && (
            <Box className={classes.inputField}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={isStorageChecked} onChange={e => handleStorageCheckboxChange(e.target.checked)} />}
                label="New Energy Storage System (ESS)"
              />
              {isStorageChecked && (
                <RadioGroup
                  className={classes.radioWrapper}
                  value={(projectDetails && projectDetails.home_backup_option)}
                  aria-label={'home_backup'}
                  name={'home_backup'}
                  row
                  onChange={e => handleBackupChange(e.target.value)}
                >
                  <FormControlLabel
                    className={clsx(classes.radioLabel, classes.firstRadioLabel)}
                    value={'full'}
                    control={<Radio color="primary" />}
                    label={'Whole Home Backup'}
                  />
                  <FormControlLabel
                    className={classes.radioLabel}
                    value={'partial'}
                    control={<Radio color="primary" />}
                    label={'Partial Home Backup'}
                  />
                  <FormControlLabel
                    className={clsx(classes.radioLabel, classes.firstRadioLabel)}
                    value={'gridTied'}
                    control={<Radio color="primary" />}
                    label={'Grid Tied'}
                  />
                </RadioGroup>
              )}
            </Box>
          )}
        </Box>
        <FileUploadDialog
          type="Document"
          imageKey="documents"
          fireUpload={fireUpload}
          open={openFileUpload}
          setOpen={() => setOpenFileUpload(!openFileUpload)}
        />
      </PanelTemplate>
    </div>
  );
};

export default SystemDetails;
