import { Avatar, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { Close, SystemUpdateAlt } from '@material-ui/icons';
import { clone, isEmpty } from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { CORRECTION_ISSUES_TYPE, ER_ISSUES_TYPE } from '../../../containers/Admin/constants';
import { addDocs, getIconPath } from '../../../containers/Admin/helper';
import { downloadFile, getFile } from '../../../utils/helper';

const useStyles = makeStyles((theme) => ({
  adminActions: {
    marginTop: theme.spacing(1),
    justifyContent: 'center'
  },
  link: {
    color: '#F3731F !important',
    cursor: 'pointer',
    paddingTop: theme.spacing(1.5)
  },
  displayFlex: {
    display: 'flex'
  },
  revisionHeading: {
    fontSize: 18,
    paddingBottom: theme.spacing(1)
  },
  typeRow: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  fileButton: {
    fontSize: theme.spacing(2.25)
  },
  listItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    maxWidth: theme.spacing(50)
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    opacity: 0.23,
    cursor: 'pointer'
  },
  dialog: {
    minWidth: theme.spacing(35)
  }
}));

// This is used for showing External and Internal Revision details
const RevisionDetails = ({
  details,
  open,
  handleClose,
  isExternal,
  application,
  getPresingedURL
}) => {
  const classes = useStyles();
  const [ installerDocs, setInstallerDocs ] = useState([]);
  const [ adminDocs, setAdminDocs ] = useState([]);
  const [ erReason, setErReason ] = useState('');
  const [ erComment, setErComment ] = useState('');
  const [ fileDownloadInProgress, setFileDownloadInProgress ] = useState(false);

  useEffect(() => {
    let docs = [];
    addDocs((application?.installer_documents || []).filter(doc => doc.versions && doc.versions.includes(details?.version)), docs, 'installer_docs');
    setInstallerDocs(docs);
    docs = [];
    addDocs(details?.files || [], docs, 'admin_docs');
    setAdminDocs(docs);
  }, [ application, isExternal ]);

  useEffect(() => {
    setErComment(details?.comment);
    setErReason(details?.kickback_reason);
  }, [ details ]);
  

  const kickbackDetails = [];
  for (const i in details) {
    if (!isExternal && details[i]?.value) {
      kickbackDetails.push({ name: i, value: details[i]?.value, comment: details[i]?.comment });
    } else if (isExternal && !isEmpty(details[i]?.comment)) {
      kickbackDetails.push({ name: i, value: details[i]?.enphase_fault, comment: details[i]?.comment });
    }
  }

  const getKickBackName = (key) => {
    return isExternal ? ER_ISSUES_TYPE.find(type => type.key === key)?.name: CORRECTION_ISSUES_TYPE.find(type => type.key === key)?.name;
  };

  const downloadFiles = async (doc, isAdminDoc) => {
    const aDocs = isAdminDoc ? clone(adminDocs) : clone(installerDocs);
    let idxOfDownloadingDoc = 0;
    for (let i = 0; i < aDocs.length; i++) {
      if (aDocs[i].id === doc.id) {
        aDocs[i].downloadInProgress = true;
        idxOfDownloadingDoc = i;
      }
    }
    if (isAdminDoc) {
      setAdminDocs(aDocs);
    } else {
      setInstallerDocs(aDocs);
    }
    setFileDownloadInProgress(true);
    const fName = doc.file_name;
    const fUrl = await getFile(fName, getPresingedURL);
    downloadFile(fUrl, fName);
    aDocs[idxOfDownloadingDoc].downloadInProgress = false;
    if (isAdminDoc) {
      setAdminDocs(aDocs);
    } else {
      setInstallerDocs(aDocs);
    }
    setFileDownloadInProgress(false);
  }

  const FilesSection = ({ docs, isAdminDoc }) => {
    return (
      <List>
        {docs && docs.map((doc, idx) => {
          return (
            <ListItem key={idx} title={doc.name} className={classes.listItem}>
              <ListItemAvatar>
                <Avatar src={getIconPath(doc)} variant="square" />
              </ListItemAvatar>
              <ListItemText
                primary={doc.file_name}
                classes={{ primary: classes.listItemText }}
                secondary={doc.date && `Uploaded ${doc.user_info ? 'by ' + doc.user_info.name : ''} on ${moment(doc.date).format('DD MMM YYYY')}`}
                secondaryTypographyProps={{ style: { fontSize: 12 } }}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="download"
                  onClick={() => downloadFiles(doc, isAdminDoc)}
                  disabled={doc.downloadInProgress || fileDownloadInProgress}
                >
                  {doc.downloadInProgress === true ? (
                    <CircularProgress size={30} />
                  ) : (
                    <SystemUpdateAlt className={classes.fileButton} />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }

  const RevisionDetails = ({ details, leftKey, rightKey, additionalKey }) => {
    return (
      <Box>
        {isExternal && <Typography className={classes.revisionHeading}>Admin Revision Details<br /></Typography>}
        <Box className={classes.displayFlex}>
          <Grid md={5}>{leftKey}</Grid>
          <Grid md={additionalKey ? 5 : 7}>{rightKey}</Grid>
          {additionalKey && <Grid md={2}>{additionalKey}</Grid>}
        </Box>
        <br /><Divider /><br />
        {details.map(detail => {
          return (
            <Fragment>
              <Box className={classes.typeRow}>
                <Grid md={5}><b>{getKickBackName(detail.name)}</b></Grid>
                <Grid md={additionalKey ? 5 : 7}>
                  <Typography>{detail.comment}</Typography>
                </Grid>
                {additionalKey && <Grid md={2}>{detail.value ? 'Yes' : 'No'}</Grid>}
              </Box>
              <Divider /><br />
            </Fragment>
          );
        })}
      </Box>
    );
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={'lg'}
        classes={{paper: classes.dialog}}
        id="revisionDetails"
      >
        <DialogTitle id="customized-dialog-title" onClose={() => {}}>
          {!isExternal && `Internal `}Revision Notes
          <Close onClick={handleClose} className={classes.close} size="large" />
        </DialogTitle>
        <DialogContent>
          {isExternal && <Typography className={classes.revisionHeading}>{`Installer Revision Details`}</Typography>}
          {isExternal && (
            <Fragment>
              <Typography variant="body2">Who has requested revision? <span><b>{erReason}</b></span><br /><br /></Typography>
              <Typography variant="body2" color="textSecondary">Revision Comment</Typography>
              <Typography variant="body2">{erComment}</Typography><br />
            </Fragment>   
          )}
          <Box>
            {!isExternal && <RevisionDetails details={kickbackDetails} leftKey={'Issue'} rightKey={'Comment'} />}
            <br />
            <Typography>Uploaded Files</Typography><br />
            <FilesSection docs={isExternal ? installerDocs : adminDocs} isAdminDoc={!isExternal} />
            {isExternal && (
              <Box>
                <RevisionDetails details={kickbackDetails} leftKey={'Entity Requesting Revision'} rightKey={'Revision Comment'} additionalKey={'Enphase Fault'} />
                <Typography>Uploaded Files</Typography><br />
                <FilesSection docs={adminDocs} isAdminDoc />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RevisionDetails;
