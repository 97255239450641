import React from 'react';
import { Box, Checkbox, FormControlLabel, Grid, makeStyles, TextField } from '@material-ui/core';
import CustomPanel from '../../../../common/PanelTemplateWithMandatory';
import { Label } from '../../../../common/Label';
import { get, isEmpty } from '../../../../../utils/lodash';
import { SingleSelectDropdown } from '../../../../common/SingleSelectDropdown';
import { PV_METER_OPTIONS } from '../../../../../containers/Admin/constants';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  checkboxLabel: {
    margin: theme.spacing(4, 0, -1, -0.3)
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  }
}));

const OtherDetailsSection = props => {
  const classes = useStyles();
  const {
    errorData,
    getErrorText,
    getHelperText,
    handleOtherDetails,
    otherDetails,
    sectionLabel,
    readOnly,
    stateSelected
  } = props;
  const { pv_meter = '', gas_meter_clearance = '', line_diagram_required = false } = otherDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'PV Meter requirement'} />}
                dropDownOptions={PV_METER_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleOtherDetails}
                fieldId="pv_meter"
                fieldValue={pv_meter}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'Gas Meter Clearance (inches)'} />}
                id={'gas_meter_clearance'}
                type="number"
                value={gas_meter_clearance || ''}
                onChange={e => handleOtherDetails(e, 'gas_meter_clearance')}
                error={!isEmpty(get(errorData, 'gas_meter_clearance'))}
                helperText={get(errorData, 'gas_meter_clearance')}
                disabled={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <FormControlLabel
                className={classes.checkboxLabel}
                disabled={!stateSelected || readOnly}
                checked={line_diagram_required}
                control={<Checkbox className={classes.inputCheckbox} />}
                label="3 Line Diagram required"
                value={line_diagram_required}
                onChange={e => handleOtherDetails(e, 'line_diagram_required')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default OtherDetailsSection;
