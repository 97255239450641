import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  makeStyles,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
} from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  unsetMargin: {
    marginTop: 'unset !important',
    marginBottom: 'unset !important',
  },
  paper: {
    width: '100%',
    '&::before': {
      backgroundColor: 'unset',
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  menuTitle: {
    padding: theme.spacing(0, 1.5, 0, 1),
    minHeight: 'unset !important',
  },
  radioGroupContainer: {
    padding: theme.spacing(0, 0, 0, 5),
  },
  dropdownMenuItem: {
    padding: theme.spacing(0),
    backgroundColor: 'unset !important',
  },
}));

const ListMenuItem = (props) => {
  const classes = useStyles();
  const { title, expanded, onClick, options, handleChange, selectedValue, divider = false } = props;
  const [selectedOption, setSelectedOption] = useState((options && options[0]?.value) || '');

  let selOption;
  useEffect(
    () => {
      selOption = options.find(option => option.value === selectedValue);
      if (selOption) {
        setSelectedOption(selOption.value);
      }
    },
    [selectedValue]
  );

  return (
    <MenuItem
      classes={{ root: classes.dropdownMenuItem }}
      disableRipple
      disableGutters
      divider={divider}
    >
      <Accordion
        elevation={0}
        classes={{ root: clsx(classes.paper, classes.unsetMargin) }}
        expanded={expanded}
        onClick={onClick}
        onChange={(event, exp) => {
          exp && handleChange(event, selOption ? selOption : ((options && options[0]?.value) || ''))
        }}
      >
        <AccordionSummary
          classes={{
            root: classes.menuTitle,
            content: classes.unsetMargin,
            expanded: classes.unsetMargin,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Box className={classes.flexContainer}>
            <Checkbox
              color="primary"
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              checked={expanded}
            />
            <Typography variant="subtitle2">{title}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.radioGroupContainer }}>
          <FormControl>
            <RadioGroup value={selectedOption} onChange={handleChange}>
              {options && options.map((option) => {
                return (
                  <FormControlLabel
                    id={option.label}
                    label={option.label}
                    value={option.value}
                    control={<Radio size='small' color='primary' />}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </MenuItem>
  );
}

export default ListMenuItem;