import clsx from 'clsx';
import React from 'react';
import { Box, Button, Grid, Typography, makeStyles, IconButton, Switch } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty, get } from '../../../../utils/lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(2, 0, 1, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
    '& .MuiInputBase-root.Mui-disabled:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  addBatteryConfigurationButton: {
    width: '152px',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    height: '40px',
    border: '1px solid #F37320',
    borderRadius: '2px',
    opacity: 1,
    color: '#F37320',
    textTransform: 'none',
    background: '#FFFFFF',
    fontWeight: 600,
    '&:hover': { background: '#FFFFFF' },
    minWidth: 'max-content',
    maxWidth: 'max-content',
    '&.Mui-disabled': {
      background: '#eaeaea',
      color: '#FFFFFF',
      border: '1px solid #FFFFFF',
    },
  },
  filesListWrapper: {
    border: `1px solid #bbb`,
    borderRadius: theme.spacing(0.5),
    maxHeight: theme.spacing(10),
  },
  fileAttachment: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75),
    cursor: 'pointer',
  },
  fileNameIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto',
  },
  iconCoverChat: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
    '& .MuiIconButton-label': {
      height: '100%',
      '& svg': {
        height: '100%',
      },
    },
  },
  popupCloseBtn: {
    color: '#335977',
    align: 'right',
    float: 'right',
    minWidth: theme.spacing(2.5),
  },
  container: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  toggleSwitch: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    paddingLeft: '0px',
    marginLeft: '-9px',
  },
  disabledSwitch: {
    opacity: '0.5',
  },
  toggleLabel: {
    paddingBottom: '6px',
    fontSize: '14px',
  },
  errorText: {
    fontSize: theme.spacing(1.75),
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0.3),
  },
}));

const GridTiedBatteryImage = (props) => {
  const classes = useStyles();
  const {
    indoorImages,
    outdoorImages,
    withoutBackgroundImages,
    fileIcon,
    deleteFile,
    handleFilesUpload,
    isGridTiedBatteryImageToggleSet,
    handleGridTiedBatteryImageToggle,
    fileUploadLogs,
    errorData,
    handleFileDownload,
  } = props;

  return (
    <Box className={classes.root}>
      <Box className={classes.toggleSwitch}>
        <Switch
          checked={isGridTiedBatteryImageToggleSet}
          color="primary"
          onChange={(e) => handleGridTiedBatteryImageToggle(e)}
        />
        <Typography className={classes.toggleLabel}>Grid Tied Battery Image</Typography>
      </Box>
      {isGridTiedBatteryImageToggleSet && (
        <>
          <Grid className={classes.inputWrapper}>
            <Box id="indoorImagesGridTiedBattery" className={classes.addressWrapper}>
              <Grid container className={classes.container}>
                <Grid item>
                  <Typography className={classes.inputField} style={{ marginBottom: 0 }}>
                    Indoor Image
                  </Typography>
                </Grid>
                {
                  <Grid item>
                    <Button
                      color="primary"
                      component="label"
                      className={clsx(classes.addBatteryConfigurationButton)}
                      disabled={indoorImages.gridTiedBattery.length === 1}
                    >
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        id="indoorImagesGridTiedBattery"
                        name="indoorImagesGridTiedBattery"
                        onClick={(e) => {
                          e.target.value = '';
                        }}
                        onChange={(e) => handleFilesUpload(e, 'indoorImagesGridTiedBattery')}
                      />
                      CHOOSE FILE
                    </Button>
                  </Grid>
                }
              </Grid>
              {fileUploadLogs['indoorImagesGridTiedBattery']['uploadError'].msg !== '' && (
                <Box>
                  <Typography className={classes.errorText}>
                    {fileUploadLogs['indoorImagesGridTiedBattery']['uploadError'].msg}
                  </Typography>
                </Box>
              )}
              {!isEmpty(get(errorData, 'indoorImagesGridTiedBattery')) && (
                <Box>
                  <Typography className={classes.errorText}>{get(errorData, 'indoorImagesGridTiedBattery')}</Typography>
                </Box>
              )}
              {indoorImages.GridTiedBattery !== null && indoorImages.gridTiedBattery.length > 0 && (
                <Box id="indoorImageGridTiedBattery" className={classes.filesListWrapper}>
                  {indoorImages.gridTiedBattery
                    .slice()
                    .reverse()
                    .map((file, i) => (
                      <Box
                        key={file.display_file_name + i}
                        id={file.display_file_name}
                        className={classes.fileAttachment}
                        onClick={() => handleFileDownload('indoorImagesGridTiedBattery')}
                      >
                        <Box className={classes.fileNameIconWrapper} disabled={true}>
                          <IconButton className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}>
                            {fileIcon(!isEmpty(file.display_file_name) ? file.display_file_name : '')}
                          </IconButton>
                          <Box>
                            <Typography>{file.display_file_name}</Typography>
                          </Box>
                        </Box>
                        <IconButton
                          className={classes.popupCloseBtn}
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteFile('indoorImagesGridTiedBattery');
                          }}
                        >
                          <CloseIcon className={classes.popupCloseIcon} />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
              )}
            </Box>
            <Box id="outdoorImagesGridTiedBattery" className={classes.addressWrapper}>
              <Grid container className={classes.container}>
                <Grid item>
                  <Typography className={classes.inputField} style={{ marginBottom: 0 }}>
                    Outdoor Image
                  </Typography>
                </Grid>
                {
                  <Grid item>
                    <Button
                      color="primary"
                      component="label"
                      className={clsx(classes.addBatteryConfigurationButton)}
                      disabled={outdoorImages.gridTiedBattery.length === 1}
                    >
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        id="outdoorImagesGridTiedBattery"
                        name="outdoorImagesGridTiedBattery"
                        onClick={(e) => {
                          e.target.value = '';
                        }}
                        onChange={(e) => handleFilesUpload(e, 'outdoorImagesGridTiedBattery')}
                      />
                      CHOOSE FILE
                    </Button>
                  </Grid>
                }
              </Grid>
              {fileUploadLogs['outdoorImagesGridTiedBattery']['uploadError'].msg !== '' && (
                <Box>
                  <Typography className={classes.errorText}>
                    {fileUploadLogs['outdoorImagesGridTiedBattery']['uploadError'].msg}
                  </Typography>
                </Box>
              )}
              {!isEmpty(get(errorData, 'outdoorImagesGridTiedBattery')) && (
                <Box>
                  <Typography className={classes.errorText}>
                    {get(errorData, 'outdoorImagesGridTiedBattery')}
                  </Typography>
                </Box>
              )}
              {outdoorImages.gridTiedBattery !== null && outdoorImages.gridTiedBattery.length > 0 && (
                <Box id="outdoorImageGridTiedBattery" className={classes.filesListWrapper}>
                  {outdoorImages.gridTiedBattery
                    .slice()
                    .reverse()
                    .map((file, i) => (
                      <Box
                        key={file.display_file_name + i}
                        id={file.display_file_name}
                        className={classes.fileAttachment}
                        onClick={() => handleFileDownload('outdoorImagesGridTiedBattery')}
                      >
                        <Box className={classes.fileNameIconWrapper} disabled={true}>
                          <IconButton className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}>
                            {fileIcon(!isEmpty(file.display_file_name) ? file.display_file_name : '')}
                          </IconButton>
                          <Box>
                            <Typography>{file.display_file_name}</Typography>
                          </Box>
                        </Box>
                        <IconButton
                          className={classes.popupCloseBtn}
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteFile('outdoorImagesGridTiedBattery');
                          }}
                        >
                          <CloseIcon className={classes.popupCloseIcon} />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
              )}
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="withoutBackgroundImagesGridTiedBattery" className={classes.addressWrapper}>
              <Grid container className={classes.container}>
                <Grid item>
                  <Typography className={classes.inputField} style={{ marginBottom: 0 }}>
                    Image Without Background
                  </Typography>
                </Grid>
                {
                  <Grid item>
                    <Button
                      color="primary"
                      component="label"
                      className={clsx(classes.addBatteryConfigurationButton)}
                      disabled={withoutBackgroundImages.gridTiedBattery.length === 1}
                    >
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        id="withoutBackgroundImagesGridTiedBattery"
                        name="withoutBackgroundImagesGridTiedBattery"
                        onClick={(e) => {
                          e.target.value = '';
                        }}
                        onChange={(e) => handleFilesUpload(e, 'withoutBackgroundImagesGridTiedBattery')}
                      />
                      CHOOSE FILE
                    </Button>
                  </Grid>
                }
              </Grid>
              {fileUploadLogs['withoutBackgroundImagesGridTiedBattery']['uploadError'].msg !== '' && (
                <Box>
                  <Typography className={classes.errorText}>
                    {fileUploadLogs['withoutBackgroundImagesGridTiedBattery']['uploadError'].msg}
                  </Typography>
                </Box>
              )}
              {!isEmpty(get(errorData, 'withoutBackgroundImagesGridTiedBattery')) && (
                <Box>
                  <Typography className={classes.errorText}>
                    {get(errorData, 'withoutBackgroundImagesGridTiedBattery')}
                  </Typography>
                </Box>
              )}
              {withoutBackgroundImages.gridTiedBattery !== null &&
                withoutBackgroundImages.gridTiedBattery.length > 0 && (
                  <Box id="withoutBackgroundImageGridTiedBattery" className={classes.filesListWrapper}>
                    {withoutBackgroundImages.gridTiedBattery
                      .slice()
                      .reverse()
                      .map((file, i) => (
                        <Box
                          key={file.display_file_name + i}
                          id={file.display_file_name}
                          className={classes.fileAttachment}
                          onClick={() => handleFileDownload('withoutBackgroundImagesGridTiedBattery')}
                        >
                          <Box className={classes.fileNameIconWrapper} disabled={true}>
                            <IconButton className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}>
                              {fileIcon(!isEmpty(file.display_file_name) ? file.display_file_name : '')}
                            </IconButton>
                            <Box>
                              <Typography>{file.display_file_name}</Typography>
                            </Box>
                          </Box>
                          <IconButton
                            className={classes.popupCloseBtn}
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteFile('withoutBackgroundImagesGridTiedBattery');
                            }}
                          >
                            <CloseIcon className={classes.popupCloseIcon} />
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                )}
            </Box>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default GridTiedBatteryImage;
