import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
  IconButton
} from '@material-ui/core';
import ViewIcon from '../images/viewIcon';
import { get, isEmpty } from 'lodash';
import { TagLabel } from '../../../common/TagLabel';

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#111111',
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: theme.spacing(1),
    minWidth: theme.spacing(15),
    maxWidth: 350,
    pointerEvents: 'auto',
    fontWeight: 'normal'
  },
  arrow: {
    color: 'white',
    fontSize: 14
  }
}))(Tooltip);

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    fontSize: theme.spacing(1.8),
    display: 'flex'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px',
    height: '40px',
    cursor: 'unset'
  },
  panelDetails: {
    flexDirection: 'column',
    boxShadow: '1px -1px 4px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray'
  },
  dataSubHeader: {
    fontSize: theme.spacing(1.5),
    color: 'grey',
    display: 'flex'
  }
});

export class EquipmentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSheetToolTip: false
    };
  }

  componentDidMount() {}

  getModifiedtag(arrayItem, name) {
    const { originalApplicationDetails, applicationDetail } = this.props;
    let eqipmentList = get(originalApplicationDetails, 'material_details.equipment_location');

    if (isEmpty(eqipmentList) && !isEmpty(arrayItem)) {
      return <TagLabel add applicationDetail={applicationDetail} />;
    } else if (!isEmpty(arrayItem)) {
      if (eqipmentList[0][name] != arrayItem[0][name]) {
        return <TagLabel edit applicationDetail={applicationDetail} />;
      }
    }
  }

  render() {
    const { classes, arrayList, title, downloadFile } = this.props;
    return (
      <Fragment>
        {!isEmpty(arrayList) ? (
          <Fragment>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography className={classes.fontBold}>{title}</Typography>
                <br />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={12}>
                <ExpansionPanel expanded={true}>
                  <ExpansionPanelDetails className={classes.panelDetails}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Typography className={classes.dataSubHeader}>
                          Storage Location{this.getModifiedtag(arrayList, 'location')}
                        </Typography>
                        <Typography className={classes.fontBold}>{arrayList[0].location || '--'}</Typography>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </Grid>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentList));
