import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import HelpTextIcon from '../../../common/HelpTextIcon';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  dataSourceField: {
    marginTop: theme.spacing(6)
  }
}));

const RequiredPlanSetNotesSection = props => {
  const classes = useStyles();
  const { readOnly, stateSelected, sectionLabel, requiredPlanNotes, handleRequiredPlanNotes } = props;
  const {
    cover_sheet = '',
    site_and_roof_plan = '',
    mounting_detail = '',
    coastal_overlay_area = '',
    ground_mount = '',
    trenching = '',
    electrical = '',
    storage = '',
    placards = '',
    elevation = '',
    wiring = '',
    garage = '',
  } = requiredPlanNotes;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel
        header={sectionLabel}
        icon={
          <HelpTextIcon
            message="Notes added here will be appended in the final permit package"
            position="right"
            btnClassName={classes.button}
          />
        }
      >
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box id="cover_sheet" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (cover sheet notes)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={cover_sheet}
                onChange={e => handleRequiredPlanNotes(e, 'cover_sheet')}
              />
            </Box>
            <Box id="site_and_roof_plan" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (site and roof plan)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={site_and_roof_plan}
                onChange={e => handleRequiredPlanNotes(e, 'site_and_roof_plan')}
              />
            </Box>
            <Box id="mounting_detail" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (mounting details)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={mounting_detail}
                onChange={e => handleRequiredPlanNotes(e, 'mounting_detail')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="coastal_overlay_area" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (coastal overlay area specific notes when roof is flat)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={coastal_overlay_area}
                onChange={e => handleRequiredPlanNotes(e, 'coastal_overlay_area')}
              />
            </Box>
            <Box id="ground_mount" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (ground mount)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={ground_mount}
                onChange={e => handleRequiredPlanNotes(e, 'ground_mount')}
              />
            </Box>
            <Box id="trenching" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (trenching)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={trenching}
                onChange={e => handleRequiredPlanNotes(e, 'trenching')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="electrical" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (electrical)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={electrical}
                onChange={e => handleRequiredPlanNotes(e, 'electrical')}
              />
            </Box>
            <Box id="storage" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (storage)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={storage}
                onChange={e => handleRequiredPlanNotes(e, 'storage')}
              />
            </Box>
            <Box id="placards" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (placards)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={placards}
                onChange={e => handleRequiredPlanNotes(e, 'placards')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="elevation" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (battery elevation and building elevation)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={elevation}
                onChange={e => handleRequiredPlanNotes(e, 'elevation')}
              />
            </Box>
            <Box id="wiring" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (wiring calculation)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={wiring}
                onChange={e => handleRequiredPlanNotes(e, 'wiring')}
              />
            </Box>
            <Box id="garage" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (garage plan)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={garage}
                onChange={e => handleRequiredPlanNotes(e, 'garage')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default RequiredPlanSetNotesSection;
