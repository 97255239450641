import React from 'react';
import { get, hasIn, isEmpty } from 'lodash';
import { Label } from '../../../common/Label';
import { Box, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = theme => ({
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  }
});

export const CountyField = props => {
  const {
    id,
    value,
    errorData,
    labelText = '',
    disabled = false,
    onChange,
    countyList,
    basicDetails,
    classes,
    isRequired = false
  } = props;
  return (
    <Box className={classes.addressWrapper}>
      <Autocomplete
        id={id}
        value={value}
        onChange={onChange}
        className={classes.inputField}
        disabled={disabled}
        options={
          isEmpty(labelText) ? !isEmpty(basicDetails['state']) ? (
            countyList
          ) : (
            []
          ) : !isEmpty(basicDetails['city']) ? (
            countyList
          ) : (
            []
          )
        }
        renderInput={params =>
          <TextField {...params}
            label={<Label text={'County ' + labelText} isRequired={isRequired} />}
            error={hasIn(errorData, 'county')}
            helperText={get(errorData, 'county')}
          />
        }
      />
    </Box>
  );
};

export default withStyles(styles)(CountyField);
