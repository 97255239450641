import { get } from 'lodash';
import React, { Fragment } from 'react';
// eslint-disable-next-line
import ElectricalProperties from '../../../components/Permitting/ElectricalProperties';
import EquipmentLocation from '../../../components/Permitting/equipmentLocation';
import Generator from '../../../components/Permitting/Generator';
import Interconnection from '../../../components/Permitting/Interconnection';
import SubPanel from '../../../components/Permitting/SubPanel/subpanel';
import Subpanels from '../../../components/Permitting/SubPanel/index';

const Properties = ({
  solarNotPurchased,
  handleMspLocationChange,
  deleteFile,
  deletePhotos,
  fireUpload,
  getS3URL,
  putToS3,
  permitId,
  materialDetails,
  projectDetails,
  userSelectionData,
  handleChange,
  paymentStatus,
  applicationDetail,
  isMobile,
  subPanelList,
  featureList,
}) => {
  const storageSelected = get(projectDetails, 'system_types[1].value', false);
  return (
    <Fragment>
      {solarNotPurchased && (
        <Interconnection
          materialDetails={materialDetails}
          projectDetails={projectDetails}
          userSelectionData={userSelectionData}
          handleChange={handleChange}
          paymentStatus={paymentStatus}
          applicationDetail={applicationDetail}
          isMobile={isMobile}
        />
      )}
      {storageSelected && (
        <EquipmentLocation
          materialDetails={materialDetails}
          projectDetails={projectDetails}
          userSelectionData={userSelectionData}
          handleChange={handleChange}
          paymentStatus={paymentStatus}
          applicationDetail={applicationDetail}
          isMobile={isMobile}
        />
      )}
      {solarNotPurchased && (
        <div>
          <ElectricalProperties
            materialDetails={materialDetails}
            projectDetails={projectDetails}
            userSelectionData={userSelectionData}
            handleChange={handleChange}
            paymentStatus={paymentStatus}
            applicationDetail={applicationDetail}
            isMobile={isMobile}
            handleMspLocationChange={handleMspLocationChange}
            permitId={permitId}
            getImgLink={deleteFile}
            deletePhotos={deletePhotos}
            fireUpload={fireUpload}
            getS3URL={getS3URL}
            putToS3={putToS3}
            featureList={featureList}
          />
          {solarNotPurchased && (
            <Subpanels
              materialDetails={materialDetails}
              projectDetails={projectDetails}
              userSelectionData={userSelectionData}
              handleChange={handleChange}
              paymentStatus={paymentStatus}
              deletePhotos={deletePhotos}
              applicationDetail={applicationDetail}
              isMobile={isMobile}
              permitId={permitId}
              fireUpload={fireUpload}
              subPanelList={subPanelList}
              getS3URL={getS3URL}
              putToS3={putToS3}
            />
          )}
          <Generator
            materialDetails={materialDetails}
            projectDetails={projectDetails}
            userSelectionData={userSelectionData}
            handleChange={handleChange}
            paymentStatus={paymentStatus}
            applicationDetail={applicationDetail}
            isMobile={isMobile}
            handleMspLocationChange={handleMspLocationChange}
            permitId={permitId}
            getImgLink={deleteFile}
            deletePhotos={deletePhotos}
            fireUpload={fireUpload}
            getS3URL={getS3URL}
            putToS3={putToS3}
          />
        </div>
      )}
    </Fragment>
  );
};

export default Properties;
