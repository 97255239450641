import React, { useState, createRef, Fragment } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty, clone } from '../../../utils/lodash';
import PageHeader from '../../common/PageHeader';
import { useStyles } from './styles';
import { FilesSection } from './AtticFilesUpload';
import SnakBar from '../../SnakBar';
import { DOC_TYPE } from '../../../containers/Admin/RequestDetails/constants';
import { ExpandMore } from '@material-ui/icons';
import { REWORK_REQUEST_REASONS, MAXIMUM_UPLOAD_FILE_SIZE } from '../../../containers/Permitting/constants';

let fileData = null;
let updatingIndex = 0;
let filesDetail = {};
let timeout = null;

const ReworkDialog = props => {
  const classes = useStyles();
  const {
    showDialog,
    messageList = [],
    permitId,
    getS3URL,
    putToS3,
    showHideLoader,
    reworkRequest = () => { },
    version,
    handleSuccess = () => { },
    closeDialog = () => { }
  } = props;
  const [ bodyText, changeBodyText ] = useState('');
  const [ reworkReason, setReworkReason ] = useState('');
  const [ reworkReasonError, setReworkReasonError ] = useState(false);
  const [ files, setFiles ] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const fileRef = createRef();

  const clearErrorMessage = () => {
    timeout = setTimeout(() => {
      setErrorMessage('');
    }, 4000);
  };

  const selectFile = event => {
    if (event.target.files.length > 0) {
      let filesSize = 0;
      let filesLength = 0;
      const filesKeys = Object.keys(files);
      if (files) {
        filesKeys.forEach(key => (filesSize += files[key].fileSize));
        filesLength = filesKeys.length;
      }
      for (const element of event.target.files) {
        filesSize += element.size;
        filesLength += 1;
      }
      filesSize = filesSize / 1024 / 1024;
      if (filesLength > 20) {
        clearTimeout(timeout);
        setErrorMessage('Max 20 files are allowed');
        clearErrorMessage();
      } else if (filesSize > 20) {
        clearTimeout(timeout);
        setErrorMessage("File size can't be more than 20 mb");
        clearErrorMessage();
      } else {
        for (const element of event.target.files) {
          const imageUrl = URL.createObjectURL(element);
          filesDetail[element.name] = {
            loading: true,
            fileSize: element.size,
            previewImageUrl: imageUrl,
            name: element.name,
            tag: '',
            added_date: new Date()
          };
        }
        setFiles({ ...filesDetail });
        fileData = event.target.files;
        updatingIndex = 0;
        uploadFile(event.target.files[0]);
      }
    }
  };

  const uploadFile = file => {
    const name = `${permitId}_message_${messageList.length + 1}_${file.name}`;
    getS3URL({
      fileName: name,
      methodType: 'PUT',
      successCb: preSignedS3Url => {
        putToS3Data(file, preSignedS3Url, name);
      },
      failureCb: () => {}
    });
  };

  const putToS3Data = (file, preSignedS3Url, name) => {
    putToS3({
      fileObj: file,
      preSignedS3Url: preSignedS3Url,
      fName: name,
      successCbS3: () => {
        filesDetail[file.name] = { ...filesDetail[file.name], loading: false, fileName: name };
        setFiles({ ...filesDetail });
        if (updatingIndex < fileData.length - 1) {
          uploadFile(fileData[updatingIndex + 1]);
          updatingIndex += 1;
        }
      },
      failureCbS3: () => {}
    });
  };

  const canProceed = () => {
    if (isEmpty(reworkReason)) {
      setReworkReasonError(true);
      return false;
    } else {
      setReworkReasonError(false);
    }
    return true;
  }

  const handleSubmit = () => {
    if (!canProceed()) {
      return;
    }
    handleSendMessage();
  };

  const getReworkRequestFiles = (files) => {
    const nextER = "ER" + Number(version?.external_revision + 1);
    const reworkRequestFiles = [];
    for (const key in files) {
      const file = files[key];
      reworkRequestFiles.push({
        file_name: file.fileName,
        original_file_name: file.name,
        file_size: file.fileSize / 1024 / 1024,
        loading: false,
        comment: bodyText,
        versions: [ nextER ],
        doc_type: DOC_TYPE.EXTERNAL_REVISION_DOC
      })
    }
    return reworkRequestFiles;
  }

  const handleSendMessage = () => {
    showHideLoader(true);
    const reworkRequestFiles = getReworkRequestFiles(files);
    reworkRequest({
      permitId,
      files: reworkRequestFiles,
      message: bodyText,
      reworkReason: reworkReason,
      successCbk: () => {
        showHideLoader(false);
        handleClose(true);
      }
    });
  };

  const handleClose = (submit = false) => {
    changeBodyText('');
    setFiles({});
    fileData = null;
    filesDetail = {};
    updatingIndex = 0;
    setErrorMessage('');
    submit ? handleSuccess() : closeDialog();
  };

  const removeAddedDoc = key => {
    const fileNames = clone(files);
    const { [key]: removeObj, ...remainingFiles } = fileNames;
    setFiles(remainingFiles);
    filesDetail = remainingFiles;
    // reset the value of the fileRef so that same file can be re uploaded
    fileRef.current.value = '';
  };

  const findLoadingImage = Object.keys(files).findIndex(key => files[key].loading === true);
  const isFieldsNotEmpty = !isEmpty(bodyText) && !isEmpty(reworkReason);
  const disableSendButton = findLoadingImage >= 0 || (!isFieldsNotEmpty && findLoadingImage < 0);
  const disableChooseFileButton = findLoadingImage >= 0 || (files && Object.keys(files).length === 20);

  return (
    <Fragment>
      <Dialog
        open={showDialog}
        onClose={() => handleClose()}
        className={classes.dialogContainer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.dialogHeader} disableTypography>
          <PageHeader text='Re-work Request' />

          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContentWrapper}>
          {/* Rework request details */}
          <DialogContentText component="div">
            <Typography className={classes.bodyHeader} component="div">
              Request Details
            </Typography>
            <FormControl required id={'Rework-Select-Form'} className={classes.reworkReason}>
              <InputLabel id={`Rework-Select-label`}>
                Reason for Rework Request
              </InputLabel>
              <Select
                id={`Rework-Select`}
                value={reworkReason}
                placeholder="Select"
                IconComponent={() => <ExpandMore className="MuiSelect-icon" />}
                className={classes.reworkReasonSelect}
                onChange={e => setReworkReason(e.target.value)}
              >
                {REWORK_REQUEST_REASONS.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
              {reworkReasonError && (
                <FormHelperText id="component-helper-text" className={classes.errorText}>
                  This field is required
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              id="body"
              className={clsx(classes.bodyInput, classes.textareInput)}
              label="Type your message here"
              value={bodyText}
              onChange={e => changeBodyText(e.target.value)}
              required={true}
              multiline={true}
              rows={5}
              helperText="Max Allowed charactors : 1000"
              inputProps={{ maxLength: 1000 }}
            />
          </DialogContentText>

          {/* File Upload section */}
          <DialogContentText component="div">
            <Typography className={classes.bodyHeader} component="div">
              Upload File
            </Typography>
            <Button
              className={classes.chooseFile}
              onClick={() => {
                fileRef.current.click();
              }}
              color="primary"
              variant="outlined"
              size="small"
              disabled={disableChooseFileButton}
            >
              Choose File
            </Button>
            <input type="file" onChange={selectFile} ref={fileRef} className={classes.input} multiple />
            <Typography color="textSecondary" variant="caption" className={classes.italic}>
              {`Maximum upload file size: ${MAXIMUM_UPLOAD_FILE_SIZE}`}
            </Typography>
            <br />
          </DialogContentText>

          {/* Uploaded Files */}
          <DialogContentText component="div">
            <FilesSection files={files} classes={classes} removeAddedDoc={removeAddedDoc} />
          </DialogContentText>

          <DialogActions className={classes.buttonWrapper}>
            <Button
              onClick={() => handleClose()}
              color="primary" variant="outlined" disableElevation
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              color="primary"
              variant="contained"
              disableElevation
              className={classes.button}
              disabled={disableSendButton}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogContent>

      </Dialog>
      {errorMessage && <SnakBar message={errorMessage} severity="error" />}
    </Fragment>
  );
};

export default ReworkDialog;
