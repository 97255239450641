import React from 'react';
import { Label } from '../../../../../common/Label';
import { get, isEmpty } from '../../../../../../utils/lodash';
import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import CustomPanel from '../../../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../../../common/SingleSelectDropdown';
import { BATTERY_TYPE, BATTERY_CHEMISTRY_TYPE } from '../../../../../../containers/Admin/constants';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
}));

const ElectricalDetailsSection = (props) => {
  const classes = useStyles();
  const { readOnly, errorData, sectionLabel, electricalDetails, handleElectricalDetails, getErrorText, getHelperText } =
    props;
  const {
    battery_type = '',
    battery_chemistry_type = '',
    peak_output_power = '',
    rated_output_power = '',
    power_rating_charge = '',
    peak_output_current = '',
    rated_output_current = '',
    total_energy_capacity = '',
    usable_energy = '',
    min_voltage_dc = '',
    max_voltage_dc = '',
    ocpd = '',
    round_trip_efficiency = '',
    depth_of_discharge = '',
  } = electricalDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Type'} />}
                dropDownOptions={BATTERY_TYPE}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDetails}
                fieldId="battery_type"
                fieldValue={battery_type}
                readOnly={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Battery Chemistry Type'} />}
                dropDownOptions={BATTERY_CHEMISTRY_TYPE}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDetails}
                fieldId="battery_chemistry_type"
                fieldValue={battery_chemistry_type}
                readOnly={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'peak_output_power'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Peak Output Power (for 10 secs)
                      ${battery_type === 'ac' ? '(kVA)' : '(kW)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={peak_output_power || ''}
                onChange={(e) => handleElectricalDetails(e, 'peak_output_power')}
                error={!isEmpty(get(errorData, 'peak_output_power'))}
                helperText={get(errorData, 'peak_output_power')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'rated_output_power'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Rated Continuous Output Power (Discharge)
                      ${battery_type === 'ac' ? '(kVA)' : '(kW)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={rated_output_power || ''}
                onChange={(e) => handleElectricalDetails(e, 'rated_output_power')}
                error={!isEmpty(get(errorData, 'rated_output_power'))}
                helperText={get(errorData, 'rated_output_power')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'power_rating_charge'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Rated Continuous Output Power(Charge)
                      ${battery_type === 'ac' ? '(kVA)' : '(kW)'}`}
                    capitalizeLabelText={false}
                  />
                }
                type="number"
                value={power_rating_charge || ''}
                onChange={(e) => handleElectricalDetails(e, 'power_rating_charge')}
                error={!isEmpty(get(errorData, 'power_rating_charge'))}
                helperText={get(errorData, 'power_rating_charge')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'peak_output_current'}
                className={classes.inputField}
                label={<Label text={'Peak Output Current (for 10 secs) (A)'} />}
                type="number"
                value={peak_output_current || ''}
                onChange={(e) => handleElectricalDetails(e, 'peak_output_current')}
                error={!isEmpty(get(errorData, 'peak_output_current'))}
                helperText={get(errorData, 'peak_output_current')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'rated_output_current'}
                className={classes.inputField}
                label={<Label text={'Rated Continuous Output Current (A)'} />}
                type="number"
                value={rated_output_current || ''}
                onChange={(e) => handleElectricalDetails(e, 'rated_output_current')}
                error={!isEmpty(get(errorData, 'rated_output_current'))}
                helperText={get(errorData, 'rated_output_current')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'total_energy_capacity'}
                className={classes.inputField}
                label={<Label text={'Total Energy Capacity (kWh)'} capitalizeLabelText={false} isRequired />}
                type="number"
                value={total_energy_capacity || ''}
                onChange={(e) => handleElectricalDetails(e, 'total_energy_capacity')}
                error={!isEmpty(get(errorData, 'total_energy_capacity'))}
                helperText={get(errorData, 'total_energy_capacity')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'usable_energy'}
                className={classes.inputField}
                label={<Label text={'Usable Energy (kWh)'} capitalizeLabelText={false} isRequired />}
                type="number"
                value={usable_energy || ''}
                onChange={(e) => handleElectricalDetails(e, 'usable_energy')}
                error={!isEmpty(get(errorData, 'usable_energy'))}
                helperText={get(errorData, 'usable_energy')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'min_voltage_dc'}
                className={classes.inputField}
                label={<Label text={'Minimum DC Voltage (V)'} isRequired />}
                type="number"
                value={min_voltage_dc || ''}
                onChange={(e) => handleElectricalDetails(e, 'min_voltage_dc')}
                error={!isEmpty(get(errorData, 'min_voltage_dc'))}
                helperText={get(errorData, 'min_voltage_dc')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_voltage_dc'}
                className={classes.inputField}
                label={<Label text={'Maximum DC Voltage (V)'} isRequired />}
                type="number"
                value={max_voltage_dc || ''}
                onChange={(e) => handleElectricalDetails(e, 'max_voltage_dc')}
                error={!isEmpty(get(errorData, 'max_voltage_dc'))}
                helperText={get(errorData, 'max_voltage_dc')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'ocpd'}
                className={classes.inputField}
                label={<Label text={'OCPD (A)'} />}
                type="number"
                value={ocpd || ''}
                onChange={(e) => handleElectricalDetails(e, 'ocpd')}
                error={!isEmpty(get(errorData, 'ocpd'))}
                helperText={get(errorData, 'ocpd')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'round_trip_efficiency'}
                className={classes.inputField}
                label={<Label text={'Round Trip Efficiency (%)'} />}
                type="number"
                value={round_trip_efficiency || ''}
                onChange={(e) => handleElectricalDetails(e, 'round_trip_efficiency')}
                error={!isEmpty(get(errorData, 'round_trip_efficiency'))}
                helperText={get(errorData, 'round_trip_efficiency')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'depth_of_discharge'}
                className={classes.inputField}
                label={<Label text={'Depth of Discharge (%)'} isRequired />}
                type="number"
                value={depth_of_discharge || ''}
                onChange={(e) => handleElectricalDetails(e, 'depth_of_discharge')}
                error={!isEmpty(get(errorData, 'depth_of_discharge'))}
                helperText={get(errorData, 'depth_of_discharge')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ElectricalDetailsSection;
