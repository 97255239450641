import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FileUploadDialog from '../FileUploadDialog';
import CloseIcon from '@material-ui/icons/Close';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  TextField,
  Typography,
  Divider,
  Grid,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Label, useLabelStyles } from '../../common/Label';
import { get, clone, isEmpty } from '../../../utils/lodash';
import { connect } from 'react-redux';
import { isPermitSetAvailable } from '../../../utils/helper';
import UploadPhoto from '../UploadFile/uploadPhoto';
import { ELECTRICAL_TAGS, SUBPANEL_TAGS } from '../../../containers/Permitting/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(0, 2, 0),
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      paddingTop: 0,
    },
  },
  displayFlex: {
    display: 'flex',
  },
  subpanelPhotoSection: {
    marginLeft: theme.spacing(1),
  },
  subpanelPhotoSectionRight: {
    marginLeft: theme.spacing(1.5),
  },
  subpanelPhotos: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  inputStorageWrapper: {
    paddingBottom: theme.spacing(2),
  },
  array: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    background: '#F7F7F7 0% 0% no-repeat padding-box',
  },
  arrayBox: {
    marginRight: theme.spacing(4),
  },
  otherInfo: {
    width: '90%',
    minWidth: theme.spacing(20),
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(3),
      minWidth: 'auto',
    },
  },
  inputField: {
    width: '90%',
    minWidth: theme.spacing(20),
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(3),
      minWidth: 'auto',
    },
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formWrapper: {
    '& .MuiInputLabel-shrink': {
      width: '150%',
    },
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
  },
  subpanelHeading: {
    fontWeight: 'bold',
    fontSize: 17,
    paddingLeft: theme.spacing(2),
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  imageCloseIcon: {
    fontSize: 18,
  },
  recentStyle: {
    color: theme.palette.primary.grey,
    marginLeft: theme.spacing(2),
  },
  dividerStyle: {
    background: theme.palette.primary.grey,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconButton: {
    padding: theme.spacing(0, 0.5),
  },
  tooltipContainer: {
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(60),
      overflowY: 'scroll',
    },
  },
  tooltipImage: {
    height: theme.spacing(25),
    width: theme.spacing(45),
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(20),
      width: theme.spacing(37),
    },
  },
  tooltipLargeImage: {
    height: theme.spacing(25),
    width: theme.spacing(60),
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(16),
      width: theme.spacing(38),
    },
  },
  imageContainer: {
    padding: theme.spacing(2),
  },
  tooltipText: {
    color: theme.palette.primary.main,
  },
  imageRow: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

const SelectDropdown = (props) => {
  const classes = useStyles();
  const {
    id,
    className,
    value = '',
    handleChange,
    optionList = [],
    labelText,
    errorText,
    error = false,
    isPermitSetAvailable,
    showRecent = false,
    isRequired = false,
    mostUsedList = [],
    capitalizeLabelText = true,
  } = props;
  const modifiedOptionList = optionList;
  if (value && !modifiedOptionList.includes(value)) {
    if (isPermitSetAvailable) {
      modifiedOptionList.push(value);
    } else {
      handleChange('', id, true);
    }
  }
  const recentList = mostUsedList.filter((value) => {
    if (value.length > 0 && modifiedOptionList.includes(value)) {
      return value;
    } else {
      return null;
    }
  });
  const showRecentDropdown = showRecent && recentList.length > 0 && modifiedOptionList.length > 5;
  const filtererModifiedOptionList =
    modifiedOptionList.length > 5
      ? modifiedOptionList.filter((value) => recentList.indexOf(value) === -1)
      : modifiedOptionList;
  return (
    <FormControl id={id} className={clsx(className, classes.formWrapper)} error={error}>
      {labelText && (
        <InputLabel id={`${id}-label`}>
          <Label
            className={classes.labelColor}
            text={labelText}
            capitalizeLabelText={capitalizeLabelText}
            isRequired={isRequired}
          />
        </InputLabel>
      )}

      <Select
        id={`${id}-select`}
        labelId={`${id}-label`}
        value={value || ''}
        IconComponent={(props) => <ExpandMoreIcon className="MuiSelect-icon" />}
        onChange={(e) => handleChange(e.target.value, id)}
      >
        {showRecentDropdown && <Typography className={classes.recentStyle}>Recently Used</Typography>}
        {showRecentDropdown &&
          recentList.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        {showRecentDropdown && <Divider variant="middle" classes={{ root: classes.dividerStyle }} />}

        {filtererModifiedOptionList &&
          filtererModifiedOptionList.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </Select>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

const Subpanel = (props) => {
  const classes = useStyles();
  const labelClass = useLabelStyles();
  const {
    materialDetails,
    userSelectionData,
    handleChange,
    projectDetails,
    applicationDetail,
    isFrom,
    mostUsedValues,
    isMobile,
    subpanel,
    subpanelList,
    index,
    handleRemoveSubpanel,
    handleSubpanelChange,
    handleExistingSubpanelChange,
    isExistingSubpanel,
    getS3URL,
    putToS3,
    permitId,
    backupExistingSubpanel,
  } = props;

  const {
    service_type: serviceTypeOptions = [],
    bus_breaker_rating_subpanel: busBreakerRatingOptions = [],
    breaker_location_subpanel: breakerLocationOptions = [],
  } = userSelectionData;

  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [imageSectionKey, setImageSectionKey] = useState('');

  const isFromProjectDetails = isFrom === 'project-details';
  const permitFilesAvailable = isPermitSetAvailable(applicationDetail);
  const state = get(projectDetails, 'state', '');
  const isStorage = get(projectDetails, 'system_types', [])[1]['value'];
  const isPartialHomeStorage = get(projectDetails, 'home_backup_option') === 'partial';
  const busRating = get(subpanel, 'busRating', '');
  const breakerRating = get(subpanel, 'breakerRating', '');
  const serviceType = get(subpanel, 'serviceType', '');
  const breakerLocation = get(subpanel, 'breakerLocation', '');
  const spareBreakerCount = get(subpanel, 'spareBreakerCount', '');
  const existingOpenSubpanelPhoto = get(subpanel, 'existingOpenSubpanelPhoto', '');
  const subpanelStickerPhoto = get(subpanel, 'subpanelStickerPhoto', '');

  const errors = get(materialDetails, 'errors', {});
  const subpanelErrors = get(errors, 'subpanels', []);
  const subpanelExistingErrors = get(errors, 'subpanelExisting', {});

  const getError = (key) => {
    if (isExistingSubpanel) {
      return get(subpanelExistingErrors, `${key}.error`);
    } else {
      const subpanelError = subpanelErrors[index] || {};
      return get(subpanelError, `${key}.error`);
    }
  };
  const getErrorText = (key) => {
    if (isExistingSubpanel) {
      return get(subpanelExistingErrors, `${key}.error`) ? get(subpanelExistingErrors, `${key}.errorMessage`) : '';
    } else {
      const subpanelError = subpanelErrors[index] || {};
      return get(subpanelError, `${key}.error`) ? get(subpanelError, `${key}.errorMessage`) : '';
    }
  };

  let existingOpenSubpanelPhotos = [],
    subpanelStickerPhotos = [];

  const subPanelStickerTag = ELECTRICAL_TAGS.subpanel_sticker;
  const existingOpenSubpanelTag = ELECTRICAL_TAGS.existing_open_subpanel;
  const electricalProperties = materialDetails.electrical_properties;

  if (!isEmpty(existingOpenSubpanelPhoto)) {
    existingOpenSubpanelPhotos = existingOpenSubpanelPhoto;
  }
  if (!isEmpty(subpanelStickerPhoto)) {
    subpanelStickerPhotos = subpanelStickerPhoto;
  }

  const handSubpanelChangeValue = (key, value) => {
    if (isExistingSubpanel) {
      handleExistingSubpanelChange(key, value);
    } else {
      handleSubpanelChange(key, value, index);
    }
  };

  const deletePhotos = (index, tag) => {
    handSubpanelChangeValue(tag, []);
  };

  const fireUpload = (file, key, tag = '') => {
    if (file) {
      const data = clone(subpanel);
      let docs = [];
      if (tag === subPanelStickerTag) {
        docs = clone(data['subpanelStickerPhoto'] || []);
      } else if (tag === existingOpenSubpanelTag) {
        docs = clone(data['existingOpenSubpanelPhoto'] || []);
      }
      const length = docs.length;
      const date = Date.now();
      const extension = file.name.match(/((\.[a-z]+)|(\.[A-Z]+))$/g)[0];
      const name = `${permitId}_subpanel_${date}${extension}`;
      docs.push({ loading: true, original_file_name: '', tag });
      handSubpanelChangeValue(tag, docs);
      getS3URL({
        fileName: name,
        methodType: 'PUT',
        successCb: (preSignedS3Url) => {
          putToS3({
            fileObj: file,
            preSignedS3Url: preSignedS3Url,
            fName: name,
            successCbS3: () => {
              docs.splice(length, 1);
              docs.push({
                file_name: name,
                original_file_name: file.name,
                tag,
                file_size: file.size / 1024 / 1024,
              });
              handSubpanelChangeValue(tag, docs);
            },
            failureCbS3: () => {
              docs.splice(length, 1);
              handSubpanelChangeValue(tag, docs);
            },
          });
        },
        failureCb: () => {},
      });
    }
  };

  const handleFileDialogOpen = (key) => {
    switch (key) {
      case Object.keys(SUBPANEL_TAGS)[0]:
        setDialogTitle('Photo of Existing Open Subpanel');
        setImageSectionKey(existingOpenSubpanelTag);
        break;
      case Object.keys(SUBPANEL_TAGS)[1]:
        setDialogTitle('Photo of Sub Panel Sticker');
        setImageSectionKey(subPanelStickerTag);
        break;
    }
    setOpenFileDialog(true);
  };

  const subpanelStickerPhotosEmpty = subpanelStickerPhotos.length === 0;
  const existingOpenSubpanelPhotosEmpty = existingOpenSubpanelPhotos.length === 0;

  const UploadPhotoSection = (props) => {
    const {
      id,
      classes,
      photos,
      photosEmpty,
      keyValue = '',
      deletePhotos,
      handleFileDialogOpen,
      isRequired,
      error,
      errorText = '',
      getS3URL,
    } = props;

    const canUpload = photos.length < 1;
    let content;
    switch (keyValue) {
      case 'existing_open_subpanel':
        content = 'Existing Open Subpanel Photo';
        break;
      case 'subpanel_sticker':
        content = 'Sub Panel Sticker Photo';
        break;
    }
    return (
      <Box className={classes.photoUploadSubpanel}>
        <UploadPhoto
          content={content}
          canUpload={canUpload}
          id={id}
          keyValue={keyValue}
          photos={photos}
          error={error}
          errorText={errorText}
          handleFileDialogOpen={handleFileDialogOpen}
          deletePhotos={deletePhotos}
          isRequired={isRequired}
          getS3URL={getS3URL}
        />
      </Box>
    );
  };
  const isPartialHomeBackup = get(projectDetails, 'home_backup_option', null) === 'partial';
  const subpanelNumber = isExistingSubpanel
    ? 1
    : isPartialHomeBackup && backupExistingSubpanel === 'true'
    ? index + 2
    : index + 1;
  const subpanelIdSuffix = isExistingSubpanel ? '_subpanel_existing' : `_subpanel_${index}`;

  return (
    <Box>
      <Box className={classes.array}>
        <Box className={classes.closeIcon}>
          <Typography className={classes.subpanelHeading}>{`Sub Panel ${subpanelNumber}`}</Typography>
          {!isExistingSubpanel && (
            <IconButton>
              <CloseIcon onClick={() => handleRemoveSubpanel()} className={classes.imageCloseIcon} />
            </IconButton>
          )}
        </Box>
        <Box className={clsx(!isFromProjectDetails && classes.root, permitFilesAvailable && classes.disableForm)}>
          {!isFromProjectDetails && (
            <Box className={classes.arrayBox}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box id={`busRating${subpanelIdSuffix}`}>
                    <SelectDropdown
                      labelText="Bus Rating"
                      capitalizeLabelText={false}
                      className={clsx(classes.inputField, labelClass.customLabel)}
                      value={busRating}
                      isRequired={true}
                      handleChange={(value, key) => handSubpanelChangeValue('bus_rating', value)}
                      optionList={busBreakerRatingOptions}
                      isPermitSetAvailable={permitFilesAvailable}
                      isMobile={isMobile}
                      error={getError('busRating')}
                      errorText={getErrorText('busRating')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box id={`breakerRating${subpanelIdSuffix}`}>
                    <SelectDropdown
                      labelText="Breaker Rating"
                      className={clsx(classes.inputField, labelClass.customLabel)}
                      value={breakerRating}
                      isRequired={true}
                      handleChange={(value, key) => handSubpanelChangeValue('breaker_rating', value)}
                      optionList={busBreakerRatingOptions}
                      isPermitSetAvailable={permitFilesAvailable}
                      error={getError('breakerRating')}
                      errorText={getErrorText('breakerRating')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box id={`serviceType${subpanelIdSuffix}`}>
                    <SelectDropdown
                      labelText="Service Type"
                      className={clsx(classes.inputField, labelClass.customLabel)}
                      value={serviceType}
                      handleChange={(value, key) => handSubpanelChangeValue('service_type', value)}
                      optionList={serviceTypeOptions}
                      isPermitSetAvailable={permitFilesAvailable}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box id={`breakerLocation${subpanelIdSuffix}`}>
                    <SelectDropdown
                      labelText="Feed Location"
                      className={clsx(classes.inputField, labelClass.customLabel)}
                      value={breakerLocation}
                      handleChange={(value, key) => handSubpanelChangeValue('breaker_location', value)}
                      optionList={breakerLocationOptions}
                      isPermitSetAvailable={permitFilesAvailable}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box id={`spareBreakerCount${subpanelIdSuffix}`}>
                    <TextField
                      onChange={(e) => handSubpanelChangeValue('spare_breaker_count', e.target.value)}
                      className={clsx(classes.otherInfo, classes.dropdown)}
                      label={<Label className={classes.labelColor} text="Spare Breaker Count" />}
                      value={spareBreakerCount}
                      type="number"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className={classes.subpanelPhotos}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box
                        id={`${SUBPANEL_TAGS.existing_open_subpanel}${subpanelIdSuffix}`}
                        className={classes.subpanelPhotoSection}
                      >
                        <UploadPhotoSection
                          classes={classes}
                          deletePhotos={deletePhotos}
                          keyValue="existing_open_subpanel"
                          isRequired={true}
                          photos={existingOpenSubpanelPhotos}
                          photosEmpty={existingOpenSubpanelPhotosEmpty}
                          handleFileDialogOpen={handleFileDialogOpen}
                          error={getError('existingOpenSubpanelPhoto')}
                          errorText={getErrorText('existingOpenSubpanelPhoto')}
                          getS3URL={getS3URL}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box
                        id={`${SUBPANEL_TAGS.subpanel_sticker}${subpanelIdSuffix}`}
                        className={classes.subpanelPhotoSectionRight}
                      >
                        <UploadPhotoSection
                          classes={classes}
                          deletePhotos={deletePhotos}
                          keyValue="subpanel_sticker"
                          photos={subpanelStickerPhotos}
                          photosEmpty={subpanelStickerPhotosEmpty}
                          handleFileDialogOpen={handleFileDialogOpen}
                          getS3URL={getS3URL}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
      <FileUploadDialog
        type="Photo"
        title={dialogTitle}
        otherDialog={true}
        imageSectionKey={imageSectionKey}
        imageKey="photos"
        fireUpload={fireUpload}
        open={openFileDialog}
        setOpen={() => setOpenFileDialog(!openFileDialog)}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  mostUsedValues: state.permitReducer.mostUsedValues,
});

export default connect(mapStateToProps)(Subpanel);
