import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  mainPaper: {
    padding: theme.spacing(0.75),
    marginTop: theme.spacing(2)
  },
  paperHeader: {
    background: '#f1f1f1',
    color: '#EA6318',
    padding: theme.spacing(1)
  },
  boxShadow: {
    boxShadow: '0px 0px 8px #00000029'
  },
  boldFont: {
    fontWeight: 'bold'
  }
});

class PanelTemplate extends Component {
  render() {
    const {
      children,
      classes,
      header,
      boxShadow = false,
      bold = false,
      className = {},
      headerClassName = {}
    } = this.props;
    const additionalProps = boxShadow ? {} : { variant: 'outlined' };
    const classNames = clsx(classes.mainPaper, className, boxShadow && classes.boxShadow);
    return (
      <Paper square className={classNames} {...additionalProps}>
        {header && (
          <Typography
            component="div"
            role="tabpanel"
            className={clsx(classes.paperHeader, headerClassName, bold && classes.boldFont)}
          >
            {header}
          </Typography>
        )}
        {children}
      </Paper>
    );
  }
}

export default withStyles(styles)(PanelTemplate);
