import { isEmpty } from 'lodash';

const noInverter = {
  error: true,
  errorMessage: 'Please add Inverter'
};

const noStorage = {
  error: true,
  errorMessage: 'Please add Battery'
};

const fieldRequired = {
  error: true,
  errorMessage: 'This field is Required'
};

const quantityError = {
  error: true,
  errorMessage: 'The value should be greater than 0'
}

const objectFields = [ 'manufacturer', 'model' ];

export const validateProducts = (products, productKey, additionalDetails = {}, isStorageIncluded = false) => {
  const isInverter = productKey === 'inverters';
  const isStorage = productKey === 'storage_list';
  const isOtherMaterials = productKey === 'other_materials';
  const errors = { [productKey]: [] };
  const { additionalKey = '', isProductAdd = false, isProductRemove = false } = additionalDetails;
  if (isStorage && !isStorageIncluded) {
    return errors;
  }
  if (products && products.length === 0) {
    errors[productKey + '-footer'] = isInverter ? noInverter : noStorage;
    return;
  }
  products && products.forEach((product, index) => {
    if (!errors[productKey][index]) {
      errors[productKey][index] = {};
    }
    for (const key in product) {
      if (isProductAdd || isProductRemove || (!isEmpty(additionalKey) && key !== additionalKey)) continue;
      if (objectFields.includes(key)) {
        if (isEmpty(product[key]) || (isEmpty(product[key].name || isEmpty(product[key].id)))) {
          errors[productKey][index][key] = fieldRequired;
        }
      }
      if (key === 'quantity' && (product.quantity === '' || product.quantity === null || isNaN(Number(product.quantity)) || Number(product.quantity) <= 0)) {
        errors[productKey][index]['quantity'] = quantityError;
      }
      if (key === 'type' && (isInverter || isOtherMaterials) && isEmpty(product.type)) {
        errors[productKey][index]['type'] = fieldRequired;
      }
      if (isStorage && product['model'].id === 'other') {
        if (!product.capacity || isNaN(Number(product.capacity)) || Number(product.capacity) <= 0) {
          errors[productKey][index]['capacity'] = quantityError;
        } else if (!product.power_capacity || isNaN(Number(product.power_capacity)) || Number(product.power_capacity) <= 0) {
          errors[productKey][index]['power_capacity'] = quantityError;
        }
      }
    }
  });
  return errors;
}