import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { isEqual, get, isEmpty, debounce,difference } from '../../../utils/lodash';
import {
  initialState,
  initialFilterParams,
  unreadMessageCountFilterList,
  MY_LIST_OPTIONS,
  OTHER_LIST_OPTIONS,
  DropdownMenuProps,
  MY_LIST,
  OTHERS_LIST,
  CHANGE_TYPE,
  LIST,
  EXPANDED_OPTION,
  DEFAULT_ROW_PER_PAGE,
  initAppType,
} from './constants';
import Loader from '../../../components/Loader';
import {
  VERSION_LIST,
  PROJECT_TYPE_LIST,
  REQUEST_STATUSES,
  ADMINS,
  USERS,
  DB_ROLES,
  APPLICATION_TYPES,
  SG_ENL_APP_USERS,
  SOLARGRAF_ONLY_APP_USERS,
  ENLIGHTEN_ONLY_APP_USERS,
  INSTALLER_REQUEST_IDS,
  ADMIN_REQUEST_IDS,
} from './../constants';
import clsx from 'clsx';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  Link,
  CircularProgress,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Popover,
  Checkbox,
  ListItemText,
  Switch,
  Tooltip,
  Badge,
} from '@material-ui/core';
import { DateRangePickerComponent } from 'materialui-daterange-picker';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {
  assignReviewer,
  getPermittingPRequests,
  getReviewersList,
  getPermitStatistics,
  getAnalyticsReport,
  saveApplicationFilters,
  getSolargrafCompanies,
  setAdminCompanies,
  getAdminCompanies,
  getSgAppAdmins,
} from '../actions';
import { DATE_FILTER_LIST, REQUEST_LISTING_COLUMNS } from '../constants';
import Snackbar from '../../../components/SnakBar';
import moment from 'moment';
import { KeyboardArrowUp, KeyboardArrowDown, Edit, FindInPage, Warning } from '@material-ui/icons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  getAssignedTo,
  getPieChartDataArray,
  selectMultipleRender,
  getColor,
  companyObjectToString,
  companyStringToObject,
} from '../helper';
import { forwardTo, isSolargrafApplication } from '../../../utils/helper';
import ApplicationsDropdown from '../../../components/Admin/Automation/ApplicationsDropdown';
import { styles } from './styles';
import AssignPopup from '../../../components/Admin/StatusDashboard/AssignPopup';
import DownloadReportPopup from '../../../components/Admin/StatusDashboard/DownloadReportPopup';
import { APPLICATION_STATES, PERMIT_SERVICE_ROLES, SOURCE } from '../../../utils/constants';
import ExternalRevision from '../../../components/Admin/StatusDashboard/ExternalRevision';
import { getExternalRevisionDetails, getInstallerList } from '../RequestDetails/actions';
import settings from '../../../utils/settings';
import OutlinedButton from '../../../components/common/OutlinedButton';
import getConfig from '../../../config/env';
import MultipleSelectAutoComplete from '../../../components/common/MultipleSelectAutoComplete';
import InstallerListDialog from '../../../components/Admin/StatusDashboard/InstallerListDialog';
import ListMenuItem from '../../../components/Admin/StatusDashboard/ListMenuItem';
import ContainedButton from '../../../components/common/ContainedButton';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
    },
  },
  ...DropdownMenuProps,
};
export class StatusDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      companiesList: this.props.companiesList || [],
      sgAppAdmins: this.props.sgAppAdmins || [],
    };
    this.config = getConfig();
    this.filterParams = {
      ...initialFilterParams,
    };
    this.appType = initAppType;
    this.selectedValue = 'All';
    this.setChartData = false;
    this.selectedInstallerValue = 'All';
    this.previousExpandedOption = EXPANDED_OPTION.MY_LIST;
    this.rowsPerPage = DEFAULT_ROW_PER_PAGE;
    this.pageNum = 0;
    this.previousListValue = '';
    this.applicationsApiResponse = false;
  }

  handleChangeRowsPerPage(event) {
    if (event.target.value) {
      this.pageNum = 0;
      this.props.getPermittingPRequests({
        pageNum: this.pageNum,
        recordCount: event.target.value,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        appType: this.appType,
        ...this.filterParams,
      });
      this.setState({ rowsPerPage: event.target.value, page: this.pageNum }, () => {
        this.rowsPerPage = event.target.value;
        this.saveSearchFilters();
      });
    }
  }

  handleChangePage(event, newPage) {
    this.props.getPermittingPRequests({
      pageNum: newPage,
      recordCount: this.rowsPerPage,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      appType: this.appType,
      ...this.filterParams,
    });
    this.setState({ page: newPage }, () => {
      this.pageNum = newPage;
      this.saveSearchFilters();
    });
  }

  sortData(column_id) {
    let sOrder = '';
    if (this.state.sort_column === column_id) {
      if (this.state.sort_order === 'asc') {
        sOrder = 'desc';
      } else {
        sOrder = 'asc';
      }
      this.props.getPermittingPRequests({
        pageNum: this.pageNum,
        recordCount: this.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: sOrder,
        appType: this.appType,
        ...this.filterParams,
      });
      this.setState({ sort_order: sOrder }, () => {
        this.saveSearchFilters();
      });
    } else {
      this.props.getPermittingPRequests({
        pageNum: this.pageNum,
        recordCount: this.rowsPerPage,
        sort_column: column_id,
        sort_order: 'asc',
        appType: this.appType,
        ...this.filterParams,
      });
      this.setState({ sort_column: column_id, sort_order: 'asc' }, () => {
        this.saveSearchFilters();
      });
    }
  }

  handleListFilter = (event, search = {}, value = '') => {
    let newValue = event.target.value;
    const searchKey = search.key;
    const installerRequestIds = INSTALLER_REQUEST_IDS;
    const adminRequestIds = ADMIN_REQUEST_IDS;
    let { selectedInstallerValue,selectedStatus } = this.state;
    
    if (searchKey === 'installerIds') {
      const inputLength = value.length;
      const indexOfAll = value.indexOf('All');
      if (value.includes('All')) {
        selectedInstallerValue = indexOfAll === inputLength - 1 ? ['All'] : value.filter((item) => item !== 'All');
      } else {
        selectedInstallerValue = value;
      }
      let installerIDs;
      this.setState({ selectedInstaller: selectedInstallerValue }, () => {
        installerIDs = this.props.installersList
          .filter((obj) => selectedInstallerValue.includes(obj['name']))
          .map((item) => item['id']);
        this.filterParams[searchKey] = installerIDs;
        this.handleFilter();
      });
    }

    if (searchKey === 'status') {
      const inputLength = newValue.length;
      const indexOfAll = newValue.indexOf(0);
      if (newValue.includes(0) || isEmpty(newValue)) {
        newValue = indexOfAll === inputLength - 1 ? [0] : newValue.filter((item) => item !== 0);
      }
      let isInstallerRequest = installerRequestIds.every(item => newValue.includes(item));
      if(selectedStatus.includes(-1) && !newValue.includes(-1)) {
        newValue = difference(newValue,installerRequestIds);
      } else if(!selectedStatus.includes(-1) && newValue.includes(-1)) {
        newValue = [...newValue,...installerRequestIds];
      }else if(isInstallerRequest && !newValue.includes(-1)){
         newValue.push(-1);
      } else if(!isInstallerRequest && newValue.includes(-1) && inputLength > 1) {
        const indexOfInstallerRequest = newValue.indexOf(-1);
        newValue.splice(indexOfInstallerRequest,1);
      }

      let isAdminRequest = adminRequestIds.every(item => newValue.includes(item));
      if(selectedStatus.includes(-2) && !newValue.includes(-2)) {
        newValue = difference(newValue,adminRequestIds);
      } else if(!selectedStatus.includes(-2) && newValue.includes(-2)) {
        newValue = [...newValue,...adminRequestIds];
      }else if(isAdminRequest && !newValue.includes(-2)){
         newValue.push(-2)
      } else if(!isAdminRequest && newValue.includes(-2) && inputLength > 1) {
        const indexOfAdminRequest = newValue.indexOf(-2);
        newValue.splice(indexOfAdminRequest,1);
      }

      this.setState({ selectedStatus: newValue }, () => {
        this.filterParams[searchKey] = newValue.filter((item) => item !== 0);
        this.handleFilter();
      });
    }
    if (searchKey === 'version') {
      const inputLength = newValue.length;
      const indexOfAll = newValue.indexOf('ALL');
      if (newValue.includes('ALL') || isEmpty(newValue)) {
        newValue = indexOfAll === inputLength - 1 ? ['ALL'] : newValue.filter((item) => item !== 'ALL');
      }
      this.setState({ selectedVersion: newValue }, () => {
        this.filterParams[searchKey] = newValue.filter((item) => item !== 'ALL');
        this.handleFilter();
      });
    }
    if (searchKey === 'projectType') {
      const inputLength = newValue.length;
      const indexOfAll = newValue.indexOf('ALL');
      if (newValue.includes('ALL') || isEmpty(newValue)) {
        newValue = indexOfAll === inputLength - 1 ? ['ALL'] : newValue.filter((item) => item !== 'ALL');
      }
      this.setState({ selectedProjectType: newValue }, () => {
        this.filterParams[searchKey] = newValue.filter((item) => item !== 'ALL');
        this.handleFilter();
      });
    }
    if (!['status', 'projectType', 'version', 'installerIds'].includes(searchKey)) {
      var filterValues = this.state.filterValues;
      filterValues[searchKey] = newValue;
      this.setState({ filterValues }, () => {
        this.filterParams[searchKey] = newValue;
        this.handleFilter();
      });
    }
  };

  resetFilters = (cb = () => {}) => {
    this.setState({ ...initialState, filterValues: {} }, () => {
      this.filterParams = { ...initialFilterParams};
      this.state.filterValues['permitId'] = '';
      this.state.filterValues['customerName'] = '';
      this.state.filterValues['siteId'] = '';
      this.state.filterValues['state'] = '';
      this.state.filterValues['projectName'] = '';
      this.state.filterValues['city'] = '';
      this.rowsPerPage = DEFAULT_ROW_PER_PAGE;
      this.setState({
        installersList: [{ id: null, name: 'All' }, ...this.props.installersList],
      });
      localStorage.removeItem('filters');
      cb();
    });
  };

  setInitialFilters = () => {
    const searchFilters = !isEmpty(localStorage.getItem('filters'))
      ? JSON.parse(localStorage.getItem('filters'))
      : this.props.searchFilters;

    if (!isEmpty(searchFilters)) {
      this.filterParams = searchFilters.filterParams;
      this.previousListValue = searchFilters.previousListValue;
      this.previousExpandedOption = searchFilters.previousExpandedOption;
      this.rowsPerPage = searchFilters.rowsPerPage;
      this.pageNum = searchFilters.page;

      this.getAdminDashboardData();
      this.setState(
        {
          ...searchFilters,
        },
        () => {
          this.props.getPermittingPRequests({
            pageNum: this.pageNum,
            recordCount: this.rowsPerPage,
            sort_column: this.state.sort_column,
            sort_order: this.state.sort_order,
            appType: this.appType,
            ...searchFilters.filterParams,
          });
        }
      );
    } else {
      this.getAdminDashboardData();
    }
  };

  saveSearchFilters = (obj = { appTypeSelected: false }) => {
    const state = this.state;
    const filterParams = this.filterParams;
    const searchFilters = {
      filterParams: filterParams,
      page: this.pageNum,
      rowsPerPage: this.rowsPerPage,
      sort_column: state.sort_column,
      sort_order: state.sort_order,
      installDateFilterText: state.installDateFilterText,
      dateRange: state.dateRange,
      selectedDate: state.selectedDate,
      createdDateSelectValue: state.createdDateSelectValue,
      lastModifiedSelectValue: state.lastModifiedSelectValue,
      lastModifiedFilterText: state.lastModifiedFilterText,
      selectedStatus: state.selectedStatus,
      selectedVersion: state.selectedVersion,
      selectedProjectType: state.selectedProjectType,
      showAllRecords: state.showAllRecords,
      filterValues: state.filterValues, // for storing the text input field values in state
      selectedInstaller: state.selectedInstaller,
      globalFilter: state.globalFilter,
      expandedOption: state.expandedOption,
      dropdownValue: state.dropdownValue,
      selectedAdmin: state.selectedAdmin,
      assignTo: state.assignTo,
      previousListValue: this.previousListValue,
      previousExpandedOption: this.previousExpandedOption,
    };
    if (!obj.appTypeSelected) {
      localStorage.setItem('filters', JSON.stringify(searchFilters));
    } else {
      localStorage.setItem('appType', this.appType);
    }
  };

  getAdminDashboardData = () => {
    if (!isEmpty(this.props.permitServiceRole) && ADMINS.includes(this.props.permitServiceRole)) {
      if (
        this.props.permitServiceRole === PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_ADMIN ||
        this.props.permitServiceRole === PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_USER
      ) {
        this.appType = SOURCE.SOLARGRAF;
      }
      this.props.getPermitStatistics({ appType: this.appType });
      this.props.getReviewersList({ appType: this.appType });
    }
  };

  loadAdminDashboard = (obj = { permitRoleModified: false }) => {
    if (obj.permitRoleModified && !isEmpty(localStorage.getItem('appType'))) {
      if (
        (localStorage.getItem('appType') === SOURCE.ENLIGHTEN_PERMIT &&
          ![...ENLIGHTEN_ONLY_APP_USERS, ...SG_ENL_APP_USERS].includes(this.props.permitServiceRole)) ||
        (localStorage.getItem('appType') === SOURCE.SOLARGRAF &&
          ![...SOLARGRAF_ONLY_APP_USERS, ...SG_ENL_APP_USERS].includes(this.props.permitServiceRole))
      ) {
        localStorage.removeItem('appType');
      }
    }
    this.appType = !isEmpty(localStorage.getItem('appType')) ? localStorage.getItem('appType') : initAppType;
    if (!isEmpty(this.props.permitServiceRole) && DB_ROLES.includes(this.props.permitServiceRole)) {
      forwardTo('ahj');
    } else if (
      !isEmpty(this.props.permitServiceRole) &&
      PERMIT_SERVICE_ROLES.INSTALLER === this.props.permitServiceRole
    ) {
      window.location = settings.enlightenUrl('manager/dashboard');
    }
    if (isEmpty(this.state.installersList) && !isEmpty(this.props.permitServiceRole)) {
      if (
        this.props.permitServiceRole === PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_ADMIN ||
        this.props.permitServiceRole === PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_USER
      ) {
        this.appType = SOURCE.SOLARGRAF;
      }
      if (
        isEmpty(this.state.companiesList) &&
        [PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_ADMIN].includes(this.props.permitServiceRole)
      ) {
        this.loadInstallers();
        this.loadSelectedInstallers();
        this.loadSolargrafApplicationAdmins();
      }
      this.getInstallerListFunction(this.appType);
    }
    if (USERS.includes(this.props.permitServiceRole)) {
      this.hideChartForAppUsers();
    }
  };

  // to get initial list of solargraf companies
  loadInstallers() {
    this.props.getSolargrafCompanies({
      successCb: (result) => this.setState({ companiesList: result }),
    });
  }

  // to get list of companies selected by the admin
  loadSelectedInstallers() {
    this.props.getAdminCompanies();
  }

  loadSolargrafApplicationAdmins() {
    this.props.getSgAppAdmins({
      successCb: (result) => this.setState({ sgAppAdmins: result }),
    });
  }

  componentDidMount() {
    this.loadAdminDashboard();
    this.setInitialFilters();
    this.props.getPermittingPRequests({
      pageNum: this.pageNum,
      recordCount: this.rowsPerPage,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      appType: this.appType,
      ...this.filterParams,
      successCb: () => {
        this.applicationsApiResponse = true;
      },
    });
  }

  componentDidUpdate(prevProps) {
    const { permitting_statistics } = this.props;
    if (prevProps.permitServiceRole !== this.props.permitServiceRole) {
      this.loadAdminDashboard({ permitRoleModified: true });
      this.getAdminDashboardData();
        this.props.getPermittingPRequests({
          pageNum: this.pageNum,
          recordCount: this.rowsPerPage,
          sort_column: this.state.sort_column,
          sort_order: this.state.sort_order,
          appType: this.appType,
          ...this.filterParams,
          successCb: () => {
            this.applicationsApiResponse = true;
          },
        });
    }
    if (
      this.applicationsApiResponse &&
      this.props.totalElements === 0 &&
      isEmpty(this.props.selectedCompanies) &&
      [PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_ADMIN].includes(this.props.permitServiceRole) &&
      !this.state.filterValues
    ) {
      this.applicationsApiResponse = false;
      this.setState({ expandedOption: EXPANDED_OPTION.OTHERS_LIST }, () => {
        this.handleOptionsChange(null, 'others');
        this.previousExpandedOption = this.state.expandedOption;
        this.previousListValue = this.filterParams[LIST];
        this.handleFilter();
      });
    }
    if (this.props.permitting_statistics !== prevProps.permitting_statistics) {
      const count = permitting_statistics
        .map((st) => {
          if (
            st.stage_name.toLowerCase() === APPLICATION_STATES.PERMIT_FILES_DOWNLOADED.name.toLowerCase() ||
            st.stage_name.toLowerCase() === APPLICATION_STATES.STAMPING_SUBMITTED.name.toLowerCase()
          ) {
            return 0;
          }
          return st.total;
        })
        .reduce((a, b) => a + b, 0);
      if (!this.setChartData && permitting_statistics.length > 0) {
        this.setState({
          chartData: {
            series: [
              {
                data: getPieChartDataArray(this.props.permitting_statistics),
              },
            ],
            subtitle: {
              text: `<b>${count}</b><br><span style="font-size:12px">${'Application'}</span></br><br><span style="font-size:12px">${'Under Progress'}</span></br>`,
              align: 'center',
              verticalAlign: 'middle',
              y: -2,
              style: {
                fontSize: `12px`,
                color: '#525252',
                fontFamily: 'Roboto',
              },
            },
            title: {
              text: '',
            },
            chart: {
              type: 'pie',
              height: '57%',
              marginTop: '-10',
            },
            xAxis: {},
            tooltip: {
              enabled: true,
              formatter: function () {
                var formatted = '<span style="font-size: 0.75em;">' + this.point.name + ': ' + this.y + '</span>';
                return formatted;
              },
            },
            legend: {
              enabled: false,
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: `100%`,
                innerSize: '50%',
                dataLabels: {
                  enabled: false,
                  padding: 2,
                  crop: false,
                  overflow: 'none',
                  connectorWidth: 0,
                  style: {
                    fontWeight: 'normal',
                  },
                  distance: `2`,
                  useHTML: true,
                },
                showInLegend: true,
              },
            },
          },
        });
        this.setChartData = true;
      }
    }
  }

  getInstallerListFunction(appType) {
    this.props.getInstallerList({
      appType: appType,
      successCb: () => {
        this.setState({
          installersList: [{ id: null, name: 'All' }, ...this.props.installersList],
        });
      },
    });
  }

  handleFilter = debounce((obj = { appTypeSelected: false }) => {
    this.pageNum = 0;
    this.props.getPermittingPRequests({
      pageNum: this.pageNum,
      recordCount: this.rowsPerPage,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      appType: this.appType,
      ...this.filterParams,
    });
    this.saveSearchFilters(obj);
  }, 1000);

  handleInstallDateChange = (event, column) => {
    const isApplicationDate = column.key === 'applicationDate';
    let value = get(event, 'target.value', '');
    if (!isEmpty(value)) {
      isApplicationDate
        ? this.setState({ createdDateSelectValue: value })
        : this.setState({ lastModifiedSelectValue: value });
    } else {
      value = isApplicationDate ? this.state.createdDateSelectValue : this.state.lastModifiedSelectValue;
    }

    let showDatePicker = false;
    const selectedInstallDate = DATE_FILTER_LIST.find((installDate) => installDate.key === value);
    if (value === 'date_range') {
      showDatePicker = true;
      this.setState({ isDateRangeOpen: true });
      isApplicationDate ? this.setState({ isFromCreatedDate: true }) : this.setState({ isFromLastModifiedDate: true });
    } else if (['specific_date', 'all_dates_before', 'all_dates_after'].includes(value)) {
      showDatePicker = true;
      this.setState({ selectSpecificDateOpen: true });
      isApplicationDate ? this.setState({ isFromCreatedDate: true }) : this.setState({ isFromLastModifiedDate: true });
      if (value === 'all_dates_before') {
        this.setState({ isAllDaysBefore: true });
      } else if (value === 'all_dates_after') {
        this.setState({ isAllDaysAfter: true });
      } else {
        this.setState({ isAllDaysBefore: false, isAllDaysAfter: false });
      }
    }
    if (!showDatePicker) {
      const startSearchKey = column.key === 'applicationDate' ? 'startDate' : 'lastModifiedStartDate';
      const endSearchKey = column.key === 'applicationDate' ? 'endDate' : 'lastModifiedEndDate';
      const todayDate = new Date();
      const todayStartingDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
      if (value === 'today') {
        this.filterParams[startSearchKey] = todayStartingDate.getTime();
        this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
        this.handleFilter();
      } else if (value === 'past_7_days') {
        const weekEarlierDate = new Date(todayStartingDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        this.filterParams[startSearchKey] = weekEarlierDate.getTime();
        this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
        this.handleFilter();
      } else if (value === 'month_date') {
        const startMonthDate = new Date(moment().startOf('month'));
        this.filterParams[startSearchKey] = startMonthDate.getTime();
        this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
        this.handleFilter();
      } else if (value === 'year_date') {
        const startYearDate = new Date(moment().startOf('year'));
        this.filterParams[startSearchKey] = startYearDate.getTime();
        this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
        this.handleFilter();
      } else if (value === 'clear_dates') {
        value = '';
        this.filterParams[startSearchKey] = '';
        this.filterParams[endSearchKey] = '';
        this.handleFilter();
      }
      const installDateFilterText = value === '' ? '' : get(selectedInstallDate, 'text', '');
      isApplicationDate
        ? this.setState({ installDateFilterText })
        : this.setState({ lastModifiedFilterText: installDateFilterText });
    }
  };

  handleAssignedToSearch = (e) => {
    if (e.target.value) {
      this.setState({ assignTo: e.target.value }, () => {
        this.filterParams = {
          ...this.filterParams,
          assignTo: e.target.value === 'all' ? '' : e.target.value,
        };
        this.handleFilter();
      });
    }
  };

  handleCloseDateRange = () => {
    this.setState({
      isDateRangeOpen: false,
      enableButton: false,
      isFromCreatedDate: false,
      isFromLastModifiedDate: false,
    });
  };

  toggle = () => this.setState({ isDateRangeOpen: !this.state.isDateRangeOpen });

  handleDateRangeChange = (range) => {
    this.setState({ dateRange: range });
    !this.state.enableButton && this.setState({ enableButton: true });
  };

  handleSaveDateRange = () => {
    this.setState({ isDateRangeOpen: false, enableButton: false });
    const { startDate, endDate } = this.state.dateRange;
    const modifiedStartDate = moment(startDate).format('M/D/YY');
    const modifiedEndDate = moment(endDate).format('M/D/YY');
    const installDateFilterText = `${modifiedStartDate} - ${modifiedEndDate}`;
    let startSearchKey;
    let endSearchkey;
    if (this.state.isFromCreatedDate) {
      this.setState({
        installDateFilterText: installDateFilterText,
        isFromCreatedDate: false,
      });
      startSearchKey = 'startDate';
      endSearchkey = 'endDate';
    }
    if (this.state.isFromLastModifiedDate) {
      startSearchKey = 'lastModifiedStartDate';
      endSearchkey = 'lastModifiedEndDate';
      this.setState({
        lastModifiedFilterText: installDateFilterText,
        isFromLastModifiedDate: false,
      });
    }

    this.filterParams[startSearchKey] = new Date(modifiedStartDate).getTime();
    this.filterParams[endSearchkey] = new Date(modifiedEndDate).getTime() + 86400000;
    this.handleFilter();
  };

  handleCloseSelectedDate = () => {
    this.setState({
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
      isAllDaysBefore: false,
      isAllDaysAfter: false,
      selectedDate: '',
      isFromCreatedDate: false,
      isFromLastModifiedDate: false,
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    !this.state.enableSelectedButton && this.setState({ enableSelectedButton: true });
  };

  handleHideChart = () => {
    this.setState({
      hideChart: !this.state.hideChart,
      overallApplicationsTableSize: this.state.overallApplicationsTableSize === 9 ? 12 : 9,
      hideChartButtonName:
        this.state.hideChartButtonName === 'Hide Chart' ? 'Overall Application Status' : 'Hide Chart',
    });
  };

  toggleDownloadPopup = () => {
    this.setState({ isDownloadPopupVisible: !this.state.isDownloadPopupVisible });
  };

  handleSaveSelectedDate = () => {
    this.setState({
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
    });
    let filterText = `${moment(this.state.selectedDate).format('M/D/YY')}`;
    const filterDate = new Date(filterText);
    let installDateFilterText = filterText;
    let startSearchKey;
    let endSearchKey;
    if (this.state.isFromCreatedDate) {
      startSearchKey = 'startDate';
      endSearchKey = 'endDate';
    }
    if (this.state.isFromLastModifiedDate) {
      startSearchKey = 'lastModifiedStartDate';
      endSearchKey = 'lastModifiedEndDate';
    }
    if (this.state.isAllDaysBefore) {
      this.filterParams[startSearchKey] = '';
      this.filterParams[endSearchKey] = filterDate.getTime();
      this.handleFilter();
      installDateFilterText = ` - ${installDateFilterText}`;
    } else if (this.state.isAllDaysAfter) {
      this.filterParams[startSearchKey] = filterDate.getTime();
      this.filterParams[endSearchKey] = '';
      this.handleFilter();
      installDateFilterText = `${installDateFilterText} - `;
    } else {
      this.filterParams[startSearchKey] = filterDate.getTime();
      this.filterParams[endSearchKey] = filterDate.getTime() + 86400000;
      this.handleFilter();
    }
    this.setState({
      isAllDaysBefore: false,
      isAllDaysAfter: false,
    });
    if (this.state.isFromCreatedDate) {
      this.setState({ installDateFilterText, isFromCreatedDate: false });
    }
    if (this.state.isFromLastModifiedDate) {
      this.setState({
        lastModifiedFilterText: installDateFilterText,
        isFromLastModifiedDate: false,
      });
    }
  };

  handlePopoverOpen = (event, app, isExternalRevision, assignedTo) => {
    this.setState({
      selectedPermitId: get(app, 'permit_id'),
      anchorEl: (!isExternalRevision || assignedTo) && event.currentTarget,
      selectedApplication: app,
      showExternalRevisionPopup: isExternalRevision && !assignedTo,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, selectedPermitId: '' });
  };

  assignButtonCall = (designerEmail, reviewerEmail) => {
    this.props.assignReviewer({
      permitId: this.state.selectedPermitId,
      designerEmail: designerEmail,
      reviewerEmail: reviewerEmail,
      successCbk: () =>
        this.props.getPermittingPRequests({
          pageNum: this.pageNum,
          recordCount: this.rowsPerPage,
          sort_column: this.state.sort_column,
          sort_order: this.state.sort_order,
          appType: this.appType,
          ...this.filterParams,
        }),
    });
    this.handlePopoverClose();
  };

  handleShowAllRecordToggle = (e) => {
    const value = e.target.checked ? 'All' : '';
    this.setState({ showAllRecords: value }, () => {
      this.filterParams['showAllRecords'] = value;
      this.handleFilter();
    });
  };

  handleGlobalFilter = (e) => {
    this.setState({ globalFilter: e.target.value }, () => {
      this.filterParams['globalFilter'] = this.state.globalFilter;
      this.pageNum = 0;
      this.props.getPermittingPRequests({
        pageNum: this.pageNum,
        recordCount: this.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        appType: this.appType,
        ...this.filterParams,
      });
      this.saveSearchFilters();
    });
  };

  hideChartForAppUsers() {
    if (USERS.includes(this.props.permitServiceRole)) {
      this.setState({
        hideChart: true,
        overallApplicationsTableSize: 12,
        hideChartButtonName: 'Overall Application Status',
      });
    }
  }

  renderMultipleSelection = (column, selectedItemList) => {
    const { classes,permitServiceRole } = this.props;
    const isAdmin = ADMINS.includes(permitServiceRole);
    let statuses = REQUEST_STATUSES;
    if(!isAdmin){
      statuses = REQUEST_STATUSES.filter((item) =>  item.value != -1 && item.value != -2 )
    }
    const itemList = {
      version: VERSION_LIST,
      projectType: PROJECT_TYPE_LIST,
      status: statuses,
    };
    return (
      <Fragment>
        {column.hasSearch && (
          <FormControl variant="outlined">
            <Select
              multiple
              onChange={(e) => this.handleListFilter(e, column)}
              value={selectedItemList}
              className={classes.styleSelect}
              classes={{ root: classes.selectInput }}
              renderValue={(selected) => selectMultipleRender(selected, itemList[column.key])}
              MenuProps={MenuProps}
            >
              {itemList[column.key].map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    <Checkbox color="primary" checked={selectedItemList.indexOf(item.value) > -1} />
                    <ListItemText primary={item.title} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </Fragment>
    );
  };

  // to get list of companies matching the search key entered by the admin
  handleCompaniesSearch = debounce((event) => {
    if (event.target.value) {
      this.props.getSolargrafCompanies({
        filter: event.target.value,
        successCb: (result) => this.setState({ companiesList: result }),
      });
    }
  }, 800);

  saveAdminCompanies = (data) => {
    this.handleInstallerDialogClose();
    const companies = data.map(companyStringToObject(SOURCE.SOLARGRAF));
    this.props.setAdminCompanies({
      previous_selection: this.props.selectedCompanies,
      current_selection: companies,
      successCb: () => {
        if (this.state.expandedOption === EXPANDED_OPTION.MY_LIST) {
          this.pageNum = 0;
          this.props.getPermittingPRequests({
            pageNum: this.pageNum,
            recordCount: this.rowsPerPage,
            sort_column: this.state.sort_column,
            sort_order: this.state.sort_order,
            appType: this.appType,
            ...this.filterParams,
          });
        }
      },
    });
  };

  toggleAdminListDropdown = (open, event = null) => {
    !open && event && event.stopPropagation();
    this.setState({ showDropdown: open });
  };

  handleOptionsChange = (e, value, type = CHANGE_TYPE.OPTIONS_CHANGE) => {
    const { expandedOption, sgAppAdmins } = this.state;
    let admin;

    if (
      (type === CHANGE_TYPE.OPTIONS_CHANGE && expandedOption === EXPANDED_OPTION.OTHERS_LIST) ||
      (type === CHANGE_TYPE.OPTIONS_RESET && this.previousExpandedOption === EXPANDED_OPTION.OTHERS_LIST)
    ) {
      admin = sgAppAdmins.find((sgAdmin) => sgAdmin.id == value);
    }

    this.setState({ selectedAdmin: admin ? `${admin.first_name} ${admin.last_name}` : '' }, () => {
      this.updateSelectValue(type);
    });
    this.filterParams[LIST] = value;
  };

  updateSelectValue = (type = CHANGE_TYPE.OPTIONS_CHANGE) => {
    const { expandedOption, selectedAdmin } = this.state;
    let value = MY_LIST;

    if (
      (type === CHANGE_TYPE.OPTIONS_CHANGE && expandedOption === EXPANDED_OPTION.OTHERS_LIST) ||
      (type === CHANGE_TYPE.OPTIONS_RESET && this.previousExpandedOption === EXPANDED_OPTION.OTHERS_LIST)
    ) {
      value = OTHERS_LIST;
    }

    if (!isEmpty(selectedAdmin)) {
      value += ` / ${selectedAdmin}`;
    }
    this.setState({ dropdownValue: value });
  };

  renderSelectValue = (value) => {
    return this.state.dropdownValue;
  };

  handleApplicationsFilter = (event, proceed) => {
    if (proceed) {
      this.previousExpandedOption = this.state.expandedOption;
      this.previousListValue = this.filterParams[LIST];
      this.handleFilter();
    } else {
      this.handleOptionsChange(null, this.previousListValue, CHANGE_TYPE.OPTIONS_RESET);
      this.setState({ expandedOption: this.previousExpandedOption });
    }
    this.toggleAdminListDropdown(false, event);
  };

  handleInstallerDialogClose = () => {
    this.setState({ openInstallerListDialog: false });
    this.loadInstallers();
  };

  render() {
    const {
      classes,
      permitting_requests,
      totalElements,
      currentlySending,
      message,
      messageType,
      permitServiceRole,
      getPermitRequetsInProgress,
      getPermittingStatisticsInProgress,
      permitting_statistics,
      selectedCompanies,
    } = this.props;

    const installersList = this.state.installersList.map((item) => item['name']);
    const {
      page,
      rowsPerPage,
      sort_column,
      sort_order,
      showAllRecords,
      globalFilter,
      openInstallerListDialog,
      companiesList,
      showDropdown,
      expandedOption,
      sgAppAdmins,
    } = this.state;

    return (
      <div className={classes.root}>
        <Box className={classes.headerWrapper} style={{ paddingRight: '10px' }}>
          <Box className={classes.headerWrapper}>
            <Typography variant="h5" component="h5" className={classes.header}>
              {this.config.enable_sg_flow &&
                [
                  PERMIT_SERVICE_ROLES.APPLICATION_ADMIN,
                  PERMIT_SERVICE_ROLES.SERVICE_ADMIN,
                  PERMIT_SERVICE_ROLES.APPLICATION_USER,
                ].includes(permitServiceRole) && (
                  <ApplicationsDropdown
                    pageHeader={this.appType}
                    disabledOption={DB_ROLES.includes(permitServiceRole)}
                    optionsList={APPLICATION_TYPES}
                    onChange={(value) => {
                      this.appType = value;
                      this.resetFilters(this.handleFilter({ appTypeSelected: true }));

                      if ([PERMIT_SERVICE_ROLES.APPLICATION_USER].includes(permitServiceRole)) {
                        this.getInstallerListFunction(value);
                      }

                      if (
                        [PERMIT_SERVICE_ROLES.APPLICATION_ADMIN, PERMIT_SERVICE_ROLES.SERVICE_ADMIN].includes(
                          permitServiceRole
                        )
                      ) {
                        this.props.getPermitStatistics({ appType: value });
                        this.props.getReviewersList({ appType: value });
                        this.getInstallerListFunction(value);
                        this.setChartData = false;
                      }
                      this.hideChartForAppUsers();
                    }}
                  />
                )}
            </Typography>
            {[PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_ADMIN].includes(permitServiceRole) && (
              <FormControl size="medium">
                <Select
                  classes={{ root: classes.adminListDropdown }}
                  defaultValue={MY_LIST}
                  open={showDropdown}
                  onClick={() => this.toggleAdminListDropdown(true, null)}
                  onClose={(e) => e.preventDefault()}
                  renderValue={this.renderSelectValue}
                  MenuProps={DropdownMenuProps}
                >
                  <ListMenuItem
                    title={MY_LIST}
                    value={MY_LIST}
                    selectedValue={this.filterParams[LIST]}
                    expanded={expandedOption === EXPANDED_OPTION.MY_LIST}
                    onClick={() => this.setState({ expandedOption: EXPANDED_OPTION.MY_LIST })}
                    options={MY_LIST_OPTIONS}
                    handleChange={this.handleOptionsChange}
                  />
                  <ListMenuItem
                    title={OTHERS_LIST}
                    value={OTHERS_LIST}
                    selectedValue={this.filterParams[LIST]}
                    expanded={expandedOption === EXPANDED_OPTION.OTHERS_LIST}
                    onClick={() => this.setState({ expandedOption: EXPANDED_OPTION.OTHERS_LIST })}
                    options={[...OTHER_LIST_OPTIONS].concat(
                      sgAppAdmins.map((admin) => {
                        return {
                          label: `${admin.first_name} ${admin.last_name}`,
                          value: `${admin.id}`,
                        };
                      })
                    )}
                    handleChange={this.handleOptionsChange}
                    divider
                  />
                  <MenuItem classes={{ root: classes.adminListDropdownMenuItem }} disableRipple disableGutters>
                    <Box className={classes.adminListDropdownButtons}>
                      <ContainedButton
                        size="small"
                        text="Done"
                        handleClick={(event) => this.handleApplicationsFilter(event, true)}
                      />
                      <Button
                        color="primary"
                        classes={{ root: classes.adminListCancelButton }}
                        onClick={(event) => this.handleApplicationsFilter(event, false)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>

          <Grid container direction="row" spacing={1} justifyContent={'flex-end'}>
            {[PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_ADMIN].includes(permitServiceRole) && (
              <Grid item>
                <Typography
                  onClick={() => this.setState({ openInstallerListDialog: true })}
                  className={classes.installerListText}
                >
                  Installer List
                </Typography>
              </Grid>
            )}
            <Grid item>
              <OutlinedButton
                text={'Clear Filter'}
                handleClick={() => {
                  this.resetFilters(this.handleFilter());
                }}
                style={classes.onStatusDashboard}
                statusDashboardBtnStyle={classes.onStatusDashboard}
              />
            </Grid>
            <Grid item>
              {' '}
              <FormControl className={classes.globalFilter}>
                <Select
                  SelectDisplayProps={{ style: { paddingTop: 3, paddingBottom: 3 } }}
                  style={{ width: 300 }}
                  variant="outlined"
                  value={globalFilter}
                  onChange={(e) => this.handleGlobalFilter(e)}
                >
                  {unreadMessageCountFilterList.map((data, index) => {
                    return (
                      <MenuItem key={index} value={data.value}>
                        <ListItemText primary={data.title} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {USERS.includes(permitServiceRole) && (
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Typography className={classes.selectText}>My Applications</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={showAllRecords}
                      color="primary"
                      onChange={(e) => this.handleShowAllRecordToggle(e)}
                    />
                  </Grid>
                  <Grid>
                    <Typography className={classes.selectText}>All Applications</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {ADMINS.includes(permitServiceRole) && (
              <Grid item>
                <OutlinedButton
                  text={this.state.hideChartButtonName}
                  handleClick={() => this.handleHideChart()}
                  style={classes.onStatusDashboard}
                  statusDashboardBtnStyle={classes.onStatusDashboard}
                />
                <OutlinedButton
                  text={`Download Report`}
                  handleClick={() => this.toggleDownloadPopup()}
                  statusDashboardBtnStyle={classes.onStatusDashboard}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        {currentlySending === true ? (
          <Loader loading={currentlySending} />
        ) : (
          <Fragment>
            <Grid container direction="row">
              <Grid item xs={12} md={this.state.overallApplicationsTableSize} style={{ paddingRight: '10px' }}>
                <Paper>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader>
                      <TableHead className={classes.tableHeader}>
                        <TableRow className={classes.tableRow}>
                          {REQUEST_LISTING_COLUMNS.map((column, index) => {
                            if (!column.userTypes.includes(permitServiceRole)) {
                              return <Fragment key={column.key + index}></Fragment>;
                            }
                            if (
                              (this.appType === SOURCE.ENLIGHTEN_PERMIT && column.hideForENLApps) ||
                              (this.appType === SOURCE.SOLARGRAF && column.hideForSGApps)
                            ) {
                              return <Fragment key={column.key + index}></Fragment>;
                            }
                            return (
                              <TableCell
                                key={column.key + index}
                                className={classes.headerContainer}
                                style={{ minWidth: column.minWidth + 'px' }}
                              >
                                <TableCell className={classes.headerText} key={index}>
                                  {column.sortable === true ? (
                                    <IconButton
                                      size="small"
                                      className={classes.headerButtonText}
                                      disableRipple={true}
                                      onClick={() => this.sortData(column.id)}
                                    >
                                      {column.label}
                                      {sort_column === column.id &&
                                        (sort_order === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      size="small"
                                      className={classes.headerButton}
                                      disabled={true}
                                      style={{ color: '#FFE785' }}
                                      disableRipple={true}
                                    >
                                      {column.label}
                                    </IconButton>
                                  )}
                                </TableCell>
                                <TableCell className={classes.searchTableCell} colSpan={REQUEST_LISTING_COLUMNS.length}>
                                  {column.hasSearch &&
                                    ![
                                      'assignedTo',
                                      'status',
                                      'applicationDate',
                                      'version',
                                      'projectType',
                                      'lastModifiedDate',
                                      'installerIds',
                                    ].includes(column.key) && (
                                      <TextField
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                          classes: {
                                            input: classes.inputField,
                                          },
                                        }}
                                        onChange={(e) => this.handleListFilter(e, column)}
                                        value={this.state.filterValues[column.key]}
                                        variant="outlined"
                                        size="small"
                                      />
                                    )}

                                  {column.hasSearch && column.key === 'installerIds' && (
                                    <MultipleSelectAutoComplete
                                      options={installersList}
                                      selectedValue={this.state.selectedInstaller}
                                      onChangeFunction={(e, value) => this.handleListFilter(e, column, value)}
                                      displayOptionBox={classes.displayOptionBox}
                                      displayOptionText={classes.displayOptionText}
                                      column={column}
                                    />
                                  )}
                                  {column.hasSearch && column.key === 'applicationDate' && (
                                    <Fragment>
                                      <Select
                                        variant="outlined"
                                        value={this.state.createdDateSelectValue}
                                        onClick={(e) => this.handleInstallDateChange(e, column)}
                                        renderValue={(value) => this.state.installDateFilterText}
                                      >
                                        {DATE_FILTER_LIST.map((installDate, index) => (
                                          <MenuItem key={index} value={installDate.key}>
                                            {installDate.text}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Fragment>
                                  )}
                                  {column.hasSearch &&
                                    column.key === 'status' &&
                                    this.renderMultipleSelection(column, this.state.selectedStatus)}
                                  {column.hasSearch && column.key === 'lastModifiedDate' && (
                                    <Fragment>
                                      <Select
                                        variant="outlined"
                                        value={this.state.lastModifiedSelectValue}
                                        onClick={(e) => this.handleInstallDateChange(e, column)}
                                        renderValue={(value) => this.state.lastModifiedFilterText}
                                      >
                                        {DATE_FILTER_LIST.map((installDate, index) => (
                                          <MenuItem key={index} value={installDate.key}>
                                            {installDate.text}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Fragment>
                                  )}
                                  {ADMINS.includes(permitServiceRole) &&
                                    column.hasSearch &&
                                    column.key === 'assignedTo' && (
                                      <Fragment>
                                        <Select
                                          variant="outlined"
                                          value={this.state.assignTo}
                                          renderValue={(value) => {
                                            const _assignTo =
                                              this.props.reviewersList &&
                                              this.props.reviewersList.find((reviewer) => reviewer['email'] === value);
                                            return _assignTo && _assignTo['first_name'] + ' ' + _assignTo['last_name'];
                                          }}
                                          onClick={(e) => this.handleAssignedToSearch(e, column)}
                                        >
                                          <MenuItem key={'all'} value={'all'}>
                                            All
                                          </MenuItem>
                                          {get(this.props, 'reviewersList', []).map((reviewer, index) => (
                                            <MenuItem key={index} value={reviewer['email']}>
                                              {reviewer.first_name + ' ' + reviewer.last_name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Fragment>
                                    )}
                                  {column.key === 'version' &&
                                    this.renderMultipleSelection(column, this.state.selectedVersion)}
                                  {column.key === 'projectType' &&
                                    this.renderMultipleSelection(column, this.state.selectedProjectType)}
                                </TableCell>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      {getPermitRequetsInProgress === true ? (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={REQUEST_LISTING_COLUMNS.length}
                              style={{ textAlign: 'center', border: 'none' }}
                            >
                              <br />
                              <br />
                              <br />
                              <CircularProgress size={15} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : isEqual(permitting_requests, []) === true ? (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={REQUEST_LISTING_COLUMNS.length} style={{ textAlign: 'center' }}>
                              <Typography variant="caption">** No Applications Available **</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {permitting_requests.map((row, index) => {
                            const assignedTo = getAssignedTo(row)?.name;
                            const assignedDesigner = row?.current_designer?.user_info;
                            const assignedReviewer = row?.current_reviewer?.user_info;
                            const adminUnreadMessagesCount = get(row, 'admin_unread_messages_count', 0);
                            const isExternalRevision =
                              row?.version?.current_version.includes('ER') &&
                              get(row, 'third_party_current_status.state_id') ===
                                APPLICATION_STATES.NEW_APPLICATION_RECEIVED.id;
                            let className = '';
                            if (
                              (USERS.includes(permitServiceRole) &&
                                [
                                  APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.id,
                                  APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED.id,
                                ].includes(row?.third_party_current_status?.state_id)) ||
                              (ADMINS.includes(permitServiceRole) &&
                                APPLICATION_STATES.NEW_APPLICATION_RECEIVED.id ===
                                  row?.third_party_current_status?.state_id)
                            ) {
                              className = classes.cellFontWeight;
                            }
                            return (
                              <TableRow key={index} className={className}>
                                <TableCell>
                                  {get(row, 'permit_id') && (
                                    <Link href={`/request/${get(row, 'permit_id')}`} variant="body2">
                                      {row.permit_id}
                                    </Link>
                                  )}
                                  {!get(row, 'permit_id') && 'NA'}
                                </TableCell>
                                <TableCell>
                                  {get(row, 'installer_name') || get(row, 'site_details.installer_name') || 'NA'}
                                </TableCell>
                                <TableCell>
                                  {isSolargrafApplication(row)
                                    ? get(row, 'project_details.system_name') || 'NA'
                                    : get(row, 'site_id') || 'NA'}
                                </TableCell>
                                <TableCell>{get(row, 'project_details.customer_name') || 'NA'}</TableCell>
                                <TableCell>{get(row, 'project_details.city') || 'NA'}</TableCell>
                                <TableCell>{get(row, 'project_details.state') || 'NA'}</TableCell>
                                <TableCell>
                                  {adminUnreadMessagesCount > 0 ? (
                                    <Badge
                                      badgeContent={
                                        <span style={{ fontSize: '10px' }}>
                                          {adminUnreadMessagesCount + ' Message'}
                                          {adminUnreadMessagesCount > 1 ? 's' : ''}
                                        </span>
                                      }
                                      color="primary"
                                      className={clsx(
                                        classes.chatBadge,
                                        adminUnreadMessagesCount === 1 && classes.slightlyLeft
                                      )}
                                      classes={{ badge: classes.badgeContent }}
                                    >
                                      {(get(row, 'third_party_current_status.substate_name') || 'NA') === 'NA'
                                        ? get(row, 'third_party_current_status.state_name') || 'NA'
                                        : get(row, 'third_party_current_status.substate_name')}
                                    </Badge>
                                  ) : (
                                    <Fragment>
                                      {(get(row, 'third_party_current_status.substate_name') || 'NA') === 'NA'
                                        ? get(row, 'third_party_current_status.state_name')
                                        : get(row, 'third_party_current_status.substate_name')}
                                    </Fragment>
                                  )}
                                </TableCell>
                                <TableCell>{get(row, 'version.current_version') || 'NA'}</TableCell>
                                <TableCell>
                                  {get(row, 'project_details.project_type')
                                    ? get(row, 'project_details.project_type').slice(0, 1)
                                    : 'NA'}
                                </TableCell>
                                {ADMINS.includes(permitServiceRole) && (
                                  <TableCell>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      className={classes.editIconBox}
                                    >
                                      <Typography
                                        className={clsx(!assignedTo && classes.boldContent, classes.fontTableSize)}
                                      >
                                        {assignedTo || 'NA'}
                                      </Typography>
                                      <Box>
                                        <IconButton
                                          onClick={(e) =>
                                            this.handlePopoverOpen(e, row, isExternalRevision, assignedTo)
                                          }
                                          className={classes.editIcon}
                                        >
                                          {isExternalRevision && !assignedTo ? (
                                            <FindInPage fontSize="small" />
                                          ) : (
                                            <Edit fontSize="small" />
                                          )}
                                        </IconButton>
                                        {(assignedReviewer || assignedDesigner) &&
                                          (!assignedReviewer || !assignedDesigner) && (
                                            <Tooltip
                                              title={`${
                                                !assignedDesigner ? 'Designer' : 'Reviewer'
                                              } is yet not asssigned`}
                                            >
                                              <Warning className={classes.warningIcon} color="primary" />
                                            </Tooltip>
                                          )}
                                      </Box>
                                    </Box>
                                  </TableCell>
                                )}
                                <Popover
                                  id="assign-popover"
                                  open={Boolean(this.state.anchorEl)}
                                  elevation={1}
                                  anchorEl={this.state.anchorEl}
                                  anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                  }}
                                  onClose={this.handlePopoverClose}
                                  disableRestoreFocus
                                >
                                  <AssignPopup
                                    classes={classes}
                                    handlePopoverClose={this.handlePopoverClose}
                                    assignButtonCall={this.assignButtonCall}
                                    reviewersList={this.props.reviewersList}
                                    currentStateId={get(
                                      this.state.selectedApplication,
                                      'third_party_current_status.state_id',
                                      0
                                    )}
                                    emailId={this.props.emailId}
                                    firstName={this.props.firstName}
                                    lastName={this.props.lastName}
                                    appDetails={this.state.selectedApplication}
                                  />
                                </Popover>
                                <TableCell>
                                  {get(row, 'application_date') !== null
                                    ? moment(get(row, 'application_date')).format('DD MMM YYYY hh:mm A')
                                    : 'NA'}{' '}
                                </TableCell>
                                <TableCell>
                                  {get(row, 'last_modified_date') !== null
                                    ? moment(get(row, 'last_modified_date')).format('DD MMM YYYY hh:mm A')
                                    : 'NA'}{' '}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={this.rowsPerPage}
                    page={this.pageNum}
                    onChangePage={(e, newPage) => this.handleChangePage(e, newPage)}
                    onChangeRowsPerPage={(e) => this.handleChangeRowsPerPage(e)}
                  />
                </Paper>
              </Grid>
              {this.state.hideChart === false && (
                <Grid item xs={12} md={3}>
                  <Paper style={{ height: '100%' }}>
                    <Grid container direction="row">
                      <Grid item xs={12}>
                        <Typography variant="h6" className={classes.dHeader}>
                          Overall Application Status
                        </Typography>
                        <br />
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xs={12}>
                        {getPermittingStatisticsInProgress === true ? (
                          <div style={{ textAlign: 'center', paddingTop: '20vh' }}>
                            <CircularProgress size={15} />
                          </div>
                        ) : (
                          <HighchartsReact highcharts={Highcharts} options={this.state.chartData} />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xs={12}>
                        <Box className={classes.legendWrapper}>
                          {permitting_statistics.map((option, index) => {
                            const { stage_name, total } = option;
                            return (
                              <Box className={classes.styleLegendWrapper} key={stage_name}>
                                <Box
                                  className={clsx(classes.styleLegendColorBox)}
                                  bgcolor={`${getColor(stage_name)} !important`}
                                />
                                <Box className={classes.styleLegendContent}>{`${stage_name} : ${total}`}</Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
              <Box>
                <DownloadReportPopup
                  classes={classes}
                  getAnalyticsReport={this.props.getAnalyticsReport}
                  isDownloadPopupVisible={this.state.isDownloadPopupVisible}
                  toggleDownloadPopup={this.toggleDownloadPopup}
                />
              </Box>
              {openInstallerListDialog && (
                <InstallerListDialog
                  options={companiesList}
                  selectedCompanies={selectedCompanies.map(companyObjectToString('installer_name', 'installer_id'))}
                  handleChange={(e) => {
                    e.persist();
                    this.handleCompaniesSearch(e);
                  }}
                  handleClose={this.handleInstallerDialogClose}
                  handleSave={this.saveAdminCompanies}
                />
              )}
            </Grid>
            <Dialog open={this.state.isDateRangeOpen} onClose={this.handleCloseDateRange}>
              <DialogContent className={classes.dateRangeWrapper}>
                <DateRangePickerComponent
                  open={this.state.isDateRangeOpen}
                  definedRanges={[]}
                  toggle={this.toggle}
                  onChange={(range) => this.handleDateRangeChange(range)}
                  closeOnClickOutside
                  initialDateRange={this.state.dateRange}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!this.state.enableButton}
                  className={classes.okButton}
                  onClick={this.handleSaveDateRange}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.selectSpecificDateOpen} onClose={this.handleCloseSelectedDate}>
              <DialogContent className={classes.dateRangeWrapper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="static"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={this.state.selectedDate}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!this.state.enableSelectedButton}
                  className={classes.okButton}
                  onClick={this.handleSaveSelectedDate}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        )}
        {message && messageType && <Snackbar severity={messageType} message={message} />}
        <ExternalRevision
          showExternalRevisionPopup={this.state.showExternalRevisionPopup}
          handleShowExternalRevisionPopup={() =>
            this.setState({ showExternalRevisionPopup: !this.state.showExternalRevisionPopup })
          }
          reviewersList={this.props.reviewersList}
          selectedApplication={this.state.selectedApplication}
          getExternalRevisionDetails={this.props.getExternalRevisionDetails}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAnalyticsReport: (payload) => dispatch(getAnalyticsReport(payload)),
  getPermittingPRequests: (payload) => dispatch(getPermittingPRequests(payload)),
  getPermitStatistics: (payload) => dispatch(getPermitStatistics(payload)),
  getReviewersList: (payload) => dispatch(getReviewersList(payload)),
  assignReviewer: (payload) => dispatch(assignReviewer(payload)),
  getExternalRevisionDetails: (payload) => dispatch(getExternalRevisionDetails(payload)),
  setSearchFilters: (payload) => dispatch(saveApplicationFilters(payload)),
  getInstallerList: (payload) => dispatch(getInstallerList(payload)),
  getSolargrafCompanies: (payload) => dispatch(getSolargrafCompanies(payload)),
  setAdminCompanies: (payload) => dispatch(setAdminCompanies(payload)),
  getAdminCompanies: (payload) => dispatch(getAdminCompanies(payload)),
  getSgAppAdmins: (payload) => dispatch(getSgAppAdmins(payload)),
});

const mapStateToProps = (state) => ({
  permitting_requests: state.adminReducer.permitting_requests,
  totalElements: state.adminReducer.totalElements,
  currentlySending: state.adminReducer.currentlySending,
  getPermittingStatisticsInProgress: state.adminReducer.getPermittingStatisticsInProgress,
  getPermitRequetsInProgress: state.adminReducer.getPermitRequetsInProgress,
  permitting_statistics: state.adminReducer.permitting_statistics,
  message: state.adminReducer.message,
  messageType: state.adminReducer.messageType,
  permitServiceRole: state.appReducer.permitServiceRole,
  reviewersList: state.adminReducer.reviewersList,
  emailId: state.appReducer.emailId,
  firstName: state.appReducer.firstName,
  lastName: state.appReducer.lastName,
  searchFilters: state.adminReducer.applicationFilters,
  installersList: state.adminReducer.installersList,
  companiesList: state.adminReducer.companiesList,
  selectedCompanies: state.adminReducer.selectedCompanies,
  sgAppAdmins: state.adminReducer.sgAppAdmins,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusDashboard));
