import React from 'react';

function Plus(props) {
  const { disabled = false } = props;
  const fillColor = disabled ? '#00000042' : '#f37320';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" style={{ paddingLeft: '8px' }}>
      <defs />
      <path
        fill={fillColor}
        d="M17.1,8.1H9.9V.9A.9.9,0,0,0,8.1.9V8.1H.9a.9.9,0,0,0,0,1.8H8.1v7.2a.9.9,0,1,0,1.8,0V9.9h7.2a.9.9,0,1,0,0-1.8Zm0,0"
      />
    </svg>
  );
}

export default Plus;
