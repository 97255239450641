import clsx from 'clsx';
import React from 'react';
import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';
import { Label } from '../../../common/Label';
import { SingleSelectDropdown } from '../../../common/SingleSelectDropdown';
import { MultipleSelectDropdown } from '../../../common/MultipleSelectDropdown';
import {
  EV_PERMIT_SERVICES,
  EV_CONSTRUCTION_SERVICES,
  EV_APPLICATION_PERMIT,
  EV_APPLICATION_SIGNATURE
} from '../../../../containers/Admin/constants';
import HelpTextIcon from '../../../common/HelpTextIcon';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  dataSourceField: {
    marginTop: theme.spacing(6)
  }
}));

const EVPermitDetailsSection = props => {
  const classes = useStyles();
  const {
    getErrorText,
    getHelperText,
    handleEVPermitDetails,
    evPermitDetails,
    panelLabel,
    readOnly,
    stateSelected
  } = props;
  const {
    ev_permit_peservices = [],
    ev_construction_doc_peservices = '',
    ev_application_permits_required = [],
    ev_application_doc_signature = [],
    ev_permit_requirement_notes = ''
  } = evPermitDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel
        header={panelLabel}
        icon={
          <HelpTextIcon
            message="When EV permit details needed field checked in basic details section, ensure that all the fields in this section is filled and updated correctly."
            position="right"
            btnClassName={classes.button}
          />
        }
      >
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'EV permit PE services'} capitalizeLabelText={false} />}
                dropDownOptions={EV_PERMIT_SERVICES}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleEVPermitDetails}
                fieldId="ev_permit_peservices"
                fieldValue={ev_permit_peservices ?? []}
                readOnly={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'EV construction doc PE services'} capitalizeLabelText={false} />}
                dropDownOptions={EV_CONSTRUCTION_SERVICES}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleEVPermitDetails}
                fieldId="ev_construction_doc_peservices"
                fieldValue={ev_construction_doc_peservices}
                readOnly={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'EV application permits required'} capitalizeLabelText={false} />}
                dropDownOptions={EV_APPLICATION_PERMIT}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleEVPermitDetails}
                fieldId="ev_application_permits_required"
                fieldValue={ev_application_permits_required ?? []}
                readOnly={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'EV application doc signatures'} capitalizeLabelText={false} />}
                dropDownOptions={EV_APPLICATION_SIGNATURE}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleEVPermitDetails}
                fieldId="ev_application_doc_signature"
                fieldValue={ev_application_doc_signature ?? []}
                readOnly={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="EV permit requirement notes"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={ev_permit_requirement_notes}
                onChange={e => handleEVPermitDetails(e, 'ev_permit_requirement_notes')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default EVPermitDetailsSection;
