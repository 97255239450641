import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { AddCircleOutline } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Box,
  Typography,
  FormControl,
  makeStyles,
  DialogContentText,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';


import { withStyles } from '@material-ui/styles';
import { isEqual, get, isEmpty } from 'lodash';
import { getStructuralCom } from '../../../StructuralAssembly/actions';
import { Label } from '../../../../../components/common/Label';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  dialogWidth: {
    minWidth: '40%',
    maxWidth: '40%',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 'unset',
  },
  inputFieldInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3, 1),
  },
  inputFieldHeading: {
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
  },
  inputFieldHint: {
    fontSize: theme.spacing(1.25),
  },
  autocompleteField: {
    padding: theme.spacing(0, 3),
  },
  installerTag: {
    color: theme.palette.primary.white,
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },
  installerTagArrow: {
    color: 'black',
  },
  installerTagTooltip: {
    position: 'relative',
    top: theme.spacing(1),
    background: 'black',
  },
  deleteIcon: {
    fill: theme.palette.primary.white,
  },
  information: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1, 3),
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  informationText: {
    margin: 'unset',
    fontSize: theme.spacing(1.5),
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      // marginTop: theme.spacing(1),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  radioWrapper: {
    minWidth: theme.spacing(30),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  radioTitle: {
    marginBottom: theme.spacing(1),
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    position: 'relative',
  },
  showButton: {
    marginLeft: 'auto',
    minWidth: '150px',
    height: 'auto',
    padding: theme.spacing(0.25),
    marginBottom: theme.spacing(-1.5),
    flexDirection: 'column',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3, 2),
  },
  cancelButton: {
    borderColor: 'black',
  },
  saveButton: {
    color: 'white',
    boxShadow: 'none',
  },
  uploadDataField: {
    width: '44%',
    margin: theme.spacing(5, 1, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  uploadDataLabel: {
    margin: theme.spacing(1, 0),
  },
  filesListWrapper: {
    border: `1px solid #bbb`,
    borderRadius: theme.spacing(0.5),
    maxHeight: theme.spacing(10),
    marginRight: theme.spacing(-1),
  },
  fileAttachment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75),
  },
  fileNameIconWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  iconCoverChat: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
    '& .MuiIconButton-label': {
      height: '100%',
      '& svg': {
        height: '100%',
      },
    },
  },
  popupCloseBtn: {
    color: '#335977',
    align: 'right',
    float: 'right',
    minWidth: theme.spacing(2.5),
  },
  errorText: {
    fontSize: theme.spacing(1.75),
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0.3),
  },
  hidden: {
    display: 'none',
  },
}));

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiIconButton-root': {
      borderRadius: 0,
    },
    '& .highcharts-credits': {
      display: 'none',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    zIndex: 9999
  }
});
const BatteryGroupInfo = (props) => {
  const {
    id,
    handleShowBatteryGroupPopup,
    handleSubmit,
    basicDetails,
    errorData,
    manufacturersList,
    editValues = false,
    editBasicDetails } = props;
  const {
    manufacturer_name = !isEmpty(get(editBasicDetails, 'edit_manufacturer_name')) && editValues ? get(editBasicDetails, 'edit_manufacturer_name') : "",
    name = !isEmpty(get(editBasicDetails, 'edit_name')) && editValues ? get(editBasicDetails, 'edit_name') : ""
  } = basicDetails;

  const [userInput, setUserInput] = useState(false);
  function getHandleChange(e, key, value) {
    const { handleBasicDetails, manufacturersListObject } = props;
    if (key === 'name') {
      value = e.target.value;
    }
    if (key === 'manufacturer_name' && userInput) {
      let manufacturerObject = manufacturersListObject.filter((val) => val['name'] === value);
      if (isEmpty(manufacturerObject)) {
        manufacturerObject = [{ name: value }]
      }
      handleBasicDetails(e, 'manufacturerObject', manufacturerObject);
    } else {
      handleBasicDetails(e, key, value);
    }
  };

  const classes = useStyles();

  const dialogHeaderText = isEqual(id, 'new') ? 'Add New Battery Group' : 'Edit Battery Group';

  return (
    <Box className={classes.subRoot}>
      <Dialog
        open
        onClose={handleShowBatteryGroupPopup}
        aria-labelledby="customized-dialog-title"
        classes={{ paper: classes.dialogWidth }}
        disableEscapeKeyDown
      >
        <DialogTitle id="customized-dialog-title">
          <Box className={classes.dialogTitle}>
            <b>{dialogHeaderText}</b>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <FormControl fullWidth>
            <Autocomplete
              id="manufacturer_name"
              className={clsx(classes.inputField, classes.inputFieldTextDropdown)}
              inputValue={manufacturer_name}
              freeSolo={true}
              options={manufacturersList}
              getOptionLabel={option => (option.name ? option.name : '')}
              renderOption={option => <React.Fragment key={option.id}>{option.name}</React.Fragment>}
              disableClearable
              onInputChange={(e, value) => getHandleChange(e, 'manufacturer_name', value)}
              renderInput={(params) => <TextField {...params} label={<Label text={'Manufacturer'} onChange={setUserInput(true)} />} />}
              defaultValue={get(editBasicDetails, 'edit_manufacturer_name')}
              disabled={editValues}
            />

            <TextField
              id={'name'}
              className={clsx(classes.inputField, classes.inputFieldTextDropdown)}
              label={<Label text={'Battery Group Name'} />}
              value={name || ''}
              placeholder="Battery Group Name"
              onChange={(e, value) => getHandleChange(e, 'name', value)}
              error={!isEmpty(get(errorData, 'name'))}
              helperText={get(errorData, 'name')}
              defaultValue={!isEmpty(get(editBasicDetails, 'name')) && editValues ? get(editBasicDetails, 'name') : ""} />
            <br />
          </FormControl>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleShowBatteryGroupPopup}
            variant="outlined"
            color="default"
            className={classes.cancelButton}
          >
            {'Cancel'}
          </Button>
          {!editValues && <Button
            variant="contained"
            color="primary"
            className={classes.saveButton}
            disabled={
              !(
                basicDetails.manufacturer_name &&
                basicDetails.name
              )
            }
            onClick={handleSubmit}
          >
            {!editValues ? 'Add' : 'Update'}
          </Button>
          }
          {editValues &&
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButton}
              disabled={
                !(
                  basicDetails.manufacturer_name &&
                  basicDetails.name
                )
              }
              onClick={handleSubmit}
            >
              {'Update'}
            </Button>}
        </DialogActions>
      </Dialog>
    </Box >
  );
};

const mapDispatchToProps = (dispatch) => ({
  getStructuralCom: (payload) => dispatch(getStructuralCom(payload)),
});

const mapStateToProps = (state) => ({
  racksList1: state.structuralAssemblyReducer.racksList,
  standoffsList1: state.structuralAssemblyReducer.standoffsList,
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BatteryGroupInfo));
