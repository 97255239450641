import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Grid, ExpansionPanel, ExpansionPanelDetails, Tooltip } from '@material-ui/core';
import { getS3URL } from '../../../../containers/Permitting/action';
import { handlePopupBlocker } from '../../../../utils/helper';
import { getSource } from '../../../../containers/Admin/RequestDetails/helper';
import { SOURCE } from '../../../../utils/constants';
import { get, isEmpty, isEqual, split } from 'lodash';
import { TagLabel } from '../../../common/TagLabel';

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#111111',
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: theme.spacing(1),
    minWidth: theme.spacing(15),
    maxWidth: 350,
    pointerEvents: 'auto',
    fontWeight: 'normal'
  },
  arrow: {
    color: 'white',
    fontSize: 14
  }
}))(Tooltip);

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    fontSize: '14px'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px',
    height: '40px',
    cursor: 'unset'
  },
  panelDetails: {
    flexDirection: 'column',
    boxShadow: '1px -1px 4px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray'
  },
  dataSubHeader: {
    fontSize: '12px',
    color: 'grey',
    display: 'flex'
  }
});

export class SubPanelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSheetToolTip: false
    };
  }

  componentDidMount() {}

  splitOtherValues(value) {
    let otherValue = !isEmpty(value) && value.startsWith('Other-') ? split(value, '-', 2)[1] : value;
    return otherValue;
  }

  downloadFile = fileName => {
    this.props.getS3URL({
      fileName: fileName,
      methodType: 'GET',
      successCb: preSignedS3Url => {
        const myWindow = window.open(preSignedS3Url, '_blank');
        handlePopupBlocker(myWindow);
      },
      failureCb: () => {
        return false;
      }
    });
  };

  getModifiedtag(arrayItem, name) {
    const { originalApplicationDetails, isArray, applicationDetail,stateApplicationDetails } = this.props;
    let compareArray = [];
    let combinedArray = [];
    if (isEmpty(originalApplicationDetails)) {
      return null;
    }
    let subpanelExistingArray = get(originalApplicationDetails, 'material_details.electrical_properties.subpanelExisting');
    let subpanelArray = get(originalApplicationDetails, 'material_details.electrical_properties.subpanels') || [];
    if(get(subpanelExistingArray,"id")) {
      combinedArray.push(subpanelExistingArray)
    }
    if(subpanelArray.length > 0){
      for(var i=0;i<subpanelArray.length;i++){
        combinedArray.push(subpanelArray[i]);
      }
    }
    compareArray = combinedArray.filter(
      s => s.id === arrayItem.id
    );
    if (isEmpty(compareArray)) {
      if (arrayItem[name]) return <TagLabel add applicationDetail={applicationDetail} />;
    } else {
      if (name === 'breakerLocation') {
        name = getSource(stateApplicationDetails) === SOURCE.SOLARGRAF ? 'feedLocation' : 'breakerLocation';
      }
      if (!isEqual(compareArray[0][name], arrayItem[name])) {
        if (!compareArray[0][name]) {
          return <TagLabel add applicationDetail={applicationDetail} />;
        } else {
          return <TagLabel edit applicationDetail={applicationDetail} />;
        }
      }
    }
  }

  getSubpanelDetails = item => {
    const { classes, applicationDetail, stateApplicationDetails } = this.props;
    let serviceType =
      getSource(stateApplicationDetails) === SOURCE.SOLARGRAF
        ? `${item.voltage || ''},${item.phase || ''}`
        : item.serviceType || '';
    if (serviceType === ',') {
      serviceType = '--';
    }
    const breakerLocation =
      getSource(stateApplicationDetails) === SOURCE.SOLARGRAF ? item.feedLocation || '--' : item.breakerLocation || '--';
    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelDetails className={classes.panelDetails} style={{ flexDirection: 'column' }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography className={classes.dataSubHeader}>
                Bus Rating {this.getModifiedtag(item, 'busRating')}
              </Typography>
              <Typography className={classes.fontBold}>{item.busRating || '--'}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.dataSubHeader}>
                Breaker Rating {this.getModifiedtag(item, 'breakerRating')}
              </Typography>
              <Typography className={classes.fontBold}>{item.breakerRating || '--'}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.dataSubHeader}>
                Service Type {this.getModifiedtag(item, 'serviceType')}
              </Typography>
              <Typography className={classes.fontBold}>{serviceType}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.dataSubHeader}>
                Feed Location {this.getModifiedtag(item, 'breakerLocation')}
              </Typography>
              <Typography className={classes.fontBold}>{breakerLocation}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.dataSubHeader}>
                Spare Breaker Count {this.getModifiedtag(item, 'spareBreakerCount')}
              </Typography>
              <Typography className={classes.fontBold}>{item.spareBreakerCount || '--'}</Typography>
            </Grid>
            {getSource(stateApplicationDetails) === SOURCE.SOLARGRAF && (
              <Grid item xs={12} md={3}>
                <Typography className={classes.dataSubHeader}>Make {this.getModifiedtag(item, 'make')}</Typography>
                <Typography className={classes.fontBold}>{item.make || '--'}</Typography>
              </Grid>
            )}
            {getSource(stateApplicationDetails) === SOURCE.SOLARGRAF && (
              <Grid item xs={12} md={3}>
                <Typography className={classes.dataSubHeader}>Model {this.getModifiedtag(item, 'model')}</Typography>
                <Typography className={classes.fontBold}>{item.model || '--'}</Typography>
              </Grid>
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    const { classes, arrayList, title, isArray = false } = this.props;
    return (
      <Fragment>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography className={classes.fontBold}>{title}</Typography>
          </Grid>
        </Grid>

        <br />
        {!isArray ? (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={12}>
              {this.getSubpanelDetails(arrayList)}
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={12}>
              {arrayList &&
                arrayList.map(arrayItem => {
                  return this.getSubpanelDetails(arrayItem);
                })}
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getS3URL: payload => dispatch(getS3URL(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SubPanelList));
