import getConfig from '../config/env';

class Settings {
  constructor(config) {
    this.config = config;
  }

  getConfig() {
    return this.config;
  }

  getApiUrl(action) {
    console.log('Dynamic Permit Api url:', this.config.api);
    return `${this.config.api}/${action}`;
  }

  permittingCoreUrl(url) {
    // process.env.PROXY_ENV would be null in remote envs
    if (process.env.PROXY_ENV === 'dev') return `${this.config.permitting_core_url}/${url}`;
    else return `${this.config.permitting_core_url}/api/v1/${url}`;
  }

  enlightenUrl(url) {
    return `${this.config.enlighten_url}/${url}`;
  }

  digiCoreUrl(url) {
    return `${this.config.digital_core_url}/api/v1/${url}`;
  }

  permittingUrl(url) {
    //process.env.PROXY_ENV would be null in remote envs
    if (process.env.PROXY_ENV === 'dev') return `${this.config.permitting_url}/permit/${url}`;
    else return `${this.config.permitting_url}/api/v1/permit/${url}`;
  }

  getSolargrafUrl(url) {
    return `${this.config.solargraf_url}/${url}`;
  }

  getSolargrafAPIUrl(url) {
    return `${this.config.solargraf_api_url}/${url}`;
  }

  getDrsUrl(url) {
    return `${this.config.drs_url}/api/v1/${url}`;
  }

  getBOMUrl(url) {
    return `${this.config.bom_api_url}/api/v1/${url}`;
  }

  getCookieName() {
    return `${this.config.enlighten_token}`;
  }
}

export default new Settings(getConfig());
