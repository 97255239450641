import React from 'react';

function Add(props) {
  const { disabled } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <defs />
      <path
        fill={disabled ? '#919191' : '#016ae3'}
        d="M14.385,0H1.308A1.308,1.308,0,0,0,0,1.308V14.385a1.308,1.308,0,0,0,1.308,1.308H14.385a1.308,1.308,0,0,0,1.308-1.308V1.308A1.308,1.308,0,0,0,14.385,0ZM13.077,13.077H2.615V2.615H13.077V13.077Z"
      />
      <g transform="translate(18.308)">
        <path
          fill={disabled ? '#919191' : '#016ae3'}
          d="M253.318,0H240.241a1.308,1.308,0,0,0-1.308,1.308V14.385a1.308,1.308,0,0,0,1.308,1.308h13.077a1.308,1.308,0,0,0,1.308-1.308V1.308A1.308,1.308,0,0,0,253.318,0ZM252.01,13.077H241.548V2.615H252.01Z"
          transform="translate(-238.933)"
        />
      </g>
      <g transform="translate(0 18.308)">
        <path
          fill={disabled ? '#919191' : '#016ae3'}
          d="M14.385,238.933H1.308A1.308,1.308,0,0,0,0,240.241v13.077a1.308,1.308,0,0,0,1.308,1.308H14.385a1.308,1.308,0,0,0,1.308-1.308V240.241A1.308,1.308,0,0,0,14.385,238.933ZM13.077,252.01H2.615V241.548H13.077V252.01Z"
          transform="translate(0 -238.933)"
        />
      </g>
      <g transform="translate(18.308 18.308)">
        <path
          fill={disabled ? '#919191' : '#016ae3'}
          d="M253.318,245.471h-5.231v-5.231a1.308,1.308,0,0,0-2.615,0v5.231h-5.231a1.308,1.308,0,1,0,0,2.615h5.231v5.231a1.308,1.308,0,0,0,2.615,0v-5.231h5.231a1.308,1.308,0,1,0,0-2.615Z"
          transform="translate(-238.933 -238.933)"
        />
      </g>
    </svg>
  );
}

export default Add;
