import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { filter, isEmpty, split } from 'lodash';
import React, { Fragment } from 'react';
import { Label } from '../../common/Label';

const isValueAvailable = (val, options, isMaterialManufacturer) => {
  if (!options || options.length === 0) {
    return false;
  }
  const value = isMaterialManufacturer
    ? options.find(option => option.name === val.name)
    : options.find(option => option === val);
  return value !== undefined;
};

const SelectDropdown = props => {
  const {
    id,
    classes,
    className,
    handleChange,
    labelText,
    labelClass,
    helperText,
    pwattErrorText,
    roofPitchErrorText,
    isPermitSetAvailable,
    value = '',
    isRequired = true,
    error = false,
    showRecent = false,
    optionList = [],
    otherOptionList = [],
    mostUsedList = [],
    panel_wattage = '',
    roof_pitch = '',
    capacity = '',
    power_capacity = '',
    capacityErrorText = '',
    powerCapacityErrorText = '',
    isStorage = false,
    disableDropdown = false,
    exceptOtherField = false,
    preventDefault = () => { }
  } = props;
  const isMaterialManufacturer = id === 'manufacturer' || id === 'model';
  const isPanel = id === 'panel_manufacturer' || id === 'panel_model';

  let dropdownValue =
    isMaterialManufacturer && value ? value.name : value || typeof value === 'number' ? value.toString() : value;
  let otherValue = !isEmpty(dropdownValue) && dropdownValue.startsWith('Other-') ? split(dropdownValue, '-', 2)[1] : '';

  const isOtherSelected =
    !isEmpty(dropdownValue) && (dropdownValue.startsWith('Other-') || dropdownValue.toLowerCase() === 'other');
  const modifiedOptionList = optionList;
  const valueAvailableInOption =
    isValueAvailable(value, otherOptionList, isMaterialManufacturer) ||
    isValueAvailable(value, modifiedOptionList, isMaterialManufacturer);

  if (value && !valueAvailableInOption) {
    if (isPermitSetAvailable) {
      isPanel ? modifiedOptionList.push({ name: value }) : modifiedOptionList.push(value);
    } else if (isMaterialManufacturer) {
      dropdownValue = '';
    } else {
      otherValue = split(value, '-', 2)[1];
    }
  }
  const filterOptionList =
    id === 'panel_manufacturer' ? modifiedOptionList.map(value => value.name) : modifiedOptionList;
  let recentList = mostUsedList.filter(value => {
    if (
      value.length > 0 &&
      (filterOptionList.includes(value) ||
        (filterOptionList && filterOptionList.map(option => option.name).includes(value)))
    ) {
      return value;
    } else {
      return null;
    }
  });
  recentList = recentList.filter((item, ind) => recentList.indexOf(item) === ind);
  const showOtherSection = otherOptionList.length > 0;
  const showRecentDropdown = showRecent && recentList.length > 0 && filterOptionList.length > 5;
  const filtererModifiedOptionList =
    filterOptionList.length > 5
      ? filterOptionList.filter(value => recentList.indexOf(value.name ? value.name : value) === -1)
      : filterOptionList;
  return (
    <Fragment>
      <FormControl id={id} className={className} error={error}>
        {labelText && (
          <InputLabel id={`${id}-label`}>
            <Label className={classes.labelColor} text={labelText} isRequired={isRequired} />
          </InputLabel>
        )}

        <Select
          id={`${id}-select`}
          labelId={`${id}-label`}
          value={isOtherSelected ? 'Other' : dropdownValue || ''}
          IconComponent={props => <ExpandMoreIcon className="MuiSelect-icon" />}
          onChange={e => handleChange(e.target.value, id)}
          disabled={disableDropdown}
          classes={{ select: classes.selectMenu }}
        >
          {showRecentDropdown && <Typography className={classes.dropdownSectionHeaderStyle}>Recently Used</Typography>}
          {showRecentDropdown &&
            recentList.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          {showRecentDropdown && <Divider variant="middle" classes={{ root: classes.dividerStyle }} />}
          {!isRequired && <MenuItem value={''}>Please Select</MenuItem>}
          {filtererModifiedOptionList &&
            filtererModifiedOptionList.map(option => {
              const value = typeof option === 'object' ? option.name : option;
              return (
                <MenuItem key={option.id ? option.id : option} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          {showOtherSection && <Divider variant="middle" classes={{ root: classes.dividerStyle }} />}
          {showOtherSection && <Typography className={classes.dropdownSectionHeaderStyle}>Other Values</Typography>}
          {showOtherSection &&
            otherOptionList.map(option => (
              <MenuItem key={option} value={option}>
                {split(option, '-', 2)[1]}
              </MenuItem>
            ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>

      {/* for other selction implementation */}
      {isOtherSelected &&
        !exceptOtherField && (
          <TextField
            id={`other-${id}`}
            className={clsx(classes.inputField, labelClass.customLabel, classes.otherInput)}
            label={<Label className={classes.labelColor} text={`Other ${labelText}`} />}
            value={otherValue}
            onChange={e => handleChange(e.target.value, id, true)}
          />
        )}

      {/* for other panel model, user will need to enter panel wattage */}
      {isOtherSelected &&
        id === 'panel_model' && (
          <Fragment>
            <TextField
              type="number"
              id={`pwatt-${id}`}
              InputProps={{ inputProps: { min: 1 } }}
              onKeyPress={e => preventDefault(e)}
              className={clsx(classes.inputField, labelClass.customLabel, classes.otherInput)}
              label={<Label className={classes.labelColor} text={`Panel Wattage`} isRequired />}
              value={panel_wattage}
              onChange={e => handleChange(e.target.value, 'panel_wattage', false)}
              error={!isEmpty(pwattErrorText)}
              helperText={pwattErrorText}
            />
          </Fragment>
        )}

      {/* roof_pitch = null, if installation type = flush roof
          roof_pitch = 0, if installation type = ground mount / flat roof
          roof_pitch = user to fill, if installation type is other than above ones */}
      {roof_pitch !== null &&
        id === 'installation_type' && (
          <Fragment>
            <TextField
              type="number"
              id={`roof_pitch`}
              className={clsx(classes.inputField, labelClass.customLabel, classes.otherInput)}
              label={<Label className={classes.labelColor} text={`Roof Pitch (Deg)`} isRequired />}
              value={roof_pitch}
              onChange={e => handleChange(e.target.value, 'roof_pitch', false)}
              error={!isEmpty(roofPitchErrorText)}
              helperText={roofPitchErrorText}
              disabled={dropdownValue === 'Ground Mount' || dropdownValue === 'Flat Roof'}
            />
          </Fragment>
        )}

      {isStorage &&
        id === 'model' &&
        isOtherSelected && (
          <Fragment>
            <TextField
              type="number"
              id={`capacity`}
              className={clsx(classes.inputField, labelClass.customLabel, classes.otherInput)}
              label={<Label className={classes.storageLabel} text={`Energy Capacity (kWh)`} isRequired />}
              value={capacity}
              onChange={e => handleChange(e.target.value, 'capacity', false)}
              error={!isEmpty(capacityErrorText)}
              helperText={capacityErrorText}
            />
            <TextField
              type="number"
              id={`power_capacity`}
              className={clsx(classes.inputField, labelClass.customLabel, classes.otherInput)}
              label={<Label className={classes.storageLabel} text={`Power Capacity (kWac)`} isRequired />}
              // from backend, value will come in Wac
              value={power_capacity ? power_capacity / 1000 : power_capacity}
              onChange={e => handleChange(e.target.value, 'power_capacity', false)}
              error={!isEmpty(powerCapacityErrorText)}
              helperText={powerCapacityErrorText}
            />
          </Fragment>
        )}
    </Fragment>
  );
};

export default SelectDropdown;
