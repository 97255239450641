import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { isEqual, get, isEmpty, includes } from '../../../../utils/lodash';
import Loader from '../../../../components/Loader';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  Link,
  CircularProgress,
  IconButton,
  TextField,
  Button,
  Box,
  Switch,
  FormControl,
  Select,
  Checkbox,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import { getAllUtility, setUtilityFilters } from '../../actions';
import {
  APPROVAL_STATUS_LIST,
  UTILITY_LISTING_COLUMNS,
  DATE_FILTER_LIST,
  VIEW_ONLY_ROLES,
  SERVICES_LIST,
} from '../../constants';
import Snackbar from '../../../../components/SnakBar';
import moment from 'moment';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import DateFilter from '../../../../components/Admin/Automation/List/DateFilter';
import { selectMultipleRender } from '../../helper';
import ApprovalStatusColumn from '../../../../components/Admin/Automation/ApprovalStatusColumn';
import { getStatus } from '../../../../utils/helper';
import ApplicationsDropdown from '../../../../components/Admin/Automation/ApplicationsDropdown';
import OutlinedButton from '../../../../components/common/OutlinedButton';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiIconButton-root': {
      borderRadius: 0,
    },
    '& .highcharts-credits': {
      display: 'none',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  button: {
    color: theme.palette.primary.main,
    fontSize: 10,
  },
  container: {
    maxHeight: '70vh',
    minHeight: '65vh',
  },
  headerButton: {
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: '16px',
  },
  tableRow: {
    padding: theme.spacing(1.5),
  },
  tableHeader: {
    backgroundColor: '#335977',
  },
  headerContainer: {
    color: '#FFE785',
    fontSize: 13,
    fontWeight: 'bold',
    position: 'sticky',
    padding: 0,
    border: 'none',
    backgroundColor: '#fff',
  },
  headerText: {
    color: '#FFE785',
    fontSize: 13,
    fontWeight: 'bold',
    backgroundColor: '#335977',
    position: 'sticky',
    display: 'flex',
  },
  searchTableCell: {
    display: 'flex',
    height: theme.spacing(4.25),
    padding: theme.spacing(1.5),
    backgroundColor: '#fff',
  },
  inputField: {
    height: theme.spacing(1.75),
  },
  headerButtonText: {
    height: theme.spacing(4),
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: '16px',
    width: 'max-content',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  styleSelect: {
    height: theme.spacing(4.25),
  },
  headerSideElements: {
    display: 'flex',
  },
  selectContainer: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  selectText: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.7),
  },
  filterStyleSelect: {
    height: theme.spacing(4.25),
    width: theme.spacing(17),
  },
  approvalStatus: {
    padding: theme.spacing(0, 2, 0, 0),
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  inputCheckboxForm: {
    width: theme.spacing(17),
  },
  utilityName: { textAlign: 'left !important' },
  clearFilterButton: {
    fontWeight: 400,
    height: theme.spacing(4.5),
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.6),
    marginRight: theme.spacing(3),

  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export class StatusDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 50,
      sort_column: '',
      sort_order: '',
      modifiedDate: '',
      modifiedDateValue: '',
      modifiedDateText: '',
      isFromModifiedDate: false,
      approvedDate: '',
      approvedDateValue: '',
      approvedDateText: '',
      selectedDate: '',
      isFromApprovedDate: false,
      isDateRangeOpen: false,
      enableButton: false,
      dateRange: {},
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
      filterRecord: 'personal',
      isRecordsToggleSet: false,
      isServiceRoleFetched: false,
      selectedStatus: ['All'],
      filterValues: {},
    };
    this.timeout = null;
    this.filterParams = { ...this.filterParams, filter_record: '' };
    this.selectedValue = 0;
    this.setChartData = false;
  }

  handleChangeRowsPerPage(event) {
    if (event.target.value) {
      this.props.getAllUtility({
        pageNum: 0,
        recordCount: event.target.value,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        ...this.filterParams,
      });
      this.setState({ rowsPerPage: event.target.value, page: 0 }, () => {
        this.saveSearchFilters();
      });
    }
  }

  handleChangePage(event, newPage) {
    this.props.getAllUtility({
      pageNum: newPage,
      recordCount: this.state.rowsPerPage,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      ...this.filterParams,
    });
    this.setState({ page: newPage }, () => {
      this.saveSearchFilters();
    });
  }

  sortData(column_id) {
    let sOrder = '';
    if (this.state.sort_column === column_id) {
      if (this.state.sort_order === 'asc') {
        sOrder = 'desc';
      } else {
        sOrder = 'asc';
      }
      this.props.getAllUtility({
        pageNum: 0,
        recordCount: this.state.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: sOrder,
        ...this.filterParams,
      });
      this.setState({ sort_order: sOrder }, () => {
        this.saveSearchFilters();
      });
    } else {
      this.props.getAllUtility({
        pageNum: 0,
        recordCount: this.state.rowsPerPage,
        sort_column: column_id,
        sort_order: 'asc',
        ...this.filterParams,
      });
      this.setState({ sort_column: column_id, sort_order: 'asc' }, () => {
        this.saveSearchFilters();
      });
    }
  }

  handleFilterDateChange = (event, column) => {
    const isModifiedDate = column.key === 'modified_at';
    let value = get(event, 'target.value', '');
    if (!isEmpty(value)) {
      isModifiedDate ? this.setState({ modifiedDateValue: value }) : this.setState({ approvedDateValue: value });
    } else {
      value = isModifiedDate ? this.state.modifiedDateValue : this.state.approvedDateValue;
    }
    let showDatePicker = false;
    const selectedDateFilter = DATE_FILTER_LIST.find((dateFilter) => dateFilter.key === value);
    if (value === 'date_range') {
      showDatePicker = true;
      this.setState({ isDateRangeOpen: true });
      isModifiedDate ? this.setState({ isFromModifiedDate: true }) : this.setState({ isFromApprovedDate: true });
    } else if (['specific_date', 'all_dates_before', 'all_dates_after'].includes(value)) {
      showDatePicker = true;
      this.setState({ selectSpecificDateOpen: true });
      isModifiedDate ? this.setState({ isFromModifiedDate: true }) : this.setState({ isFromApprovedDate: true });
      if (value === 'all_dates_before') {
        this.setState({ isAllDaysBefore: true });
      } else if (value === 'all_dates_after') {
        this.setState({ isAllDaysAfter: true });
      } else {
        this.setState({ isAllDaysBefore: false, isAllDaysAfter: false });
      }
    }
    if (!showDatePicker) {
      const startSearchKey = column.key === 'modified_at' ? 'from_modified_at' : 'from_verified_at';
      const endSearchKey = column.key === 'modified_at' ? 'to_modified_at' : 'to_verified_at';
      const todayDate = new Date();
      const todayStartingDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
      switch (value) {
        case 'today':
          this.filterParams[startSearchKey] = todayStartingDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'past_7_days':
          const weekEarlierDate = new Date(todayStartingDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          this.filterParams[startSearchKey] = weekEarlierDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'month_date':
          const startMonthDate = new Date(moment().startOf('month'));
          this.filterParams[startSearchKey] = startMonthDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'year_date':
          const startYearDate = new Date(moment().startOf('year'));
          this.filterParams[startSearchKey] = startYearDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'clear_dates':
          value = '';
          this.filterParams[startSearchKey] = '';
          this.filterParams[endSearchKey] = '';
          break;
        default:
          break;
      }
      this.handleFilter();
      const dateText = value === '' ? '' : get(selectedDateFilter, 'text', '');
      isModifiedDate ? this.setState({ modifiedDateText: dateText }) : this.setState({ approvedDateText: dateText });
    }
  };

  handleCloseDateRange = () => {
    this.setState({
      isDateRangeOpen: false,
      enableButton: false,
      isFromModifiedDate: false,
      isFromApprovedDate: false,
    });
  };

  handleDateRangeChange = (range) => {
    this.setState({ dateRange: range });
    !this.state.enableButton && this.setState({ enableButton: true });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    !this.state.enableSelectedButton && this.setState({ enableSelectedButton: true });
  };

  handleSaveDateRange = () => {
    this.setState({ isDateRangeOpen: false, enableButton: false });
    const { startDate, endDate } = this.state.dateRange;
    const modifiedStartDate = moment(startDate).format('M/D/YY');
    const modifiedEndDate = moment(endDate).format('M/D/YY');
    const dateFilterText = `${modifiedStartDate} - ${modifiedEndDate}`;
    let startSearchKey;
    let endSearchkey;
    if (this.state.isFromModifiedDate) {
      startSearchKey = 'from_modified_at';
      endSearchkey = 'to_modified_at';
      this.setState({ modifiedDateText: dateFilterText, isFromModifiedDate: false });
    }
    if (this.state.isFromApprovedDate) {
      startSearchKey = 'from_modified_at';
      endSearchkey = 'to_verified_at';
      this.setState({ approvedDateText: dateFilterText, isFromApprovedDate: false });
    }
    this.filterParams[startSearchKey] = new Date(modifiedStartDate).getTime();
    this.filterParams[endSearchkey] = new Date(modifiedEndDate).getTime() + 86400000;
    this.handleFilter();
  };

  handleSaveSelectedDate = () => {
    this.setState({
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
    });
    let filterText = `${moment(this.state.selectedDate).format('M/D/YY')}`;
    const filterDate = new Date(filterText);
    let dateFilterText = filterText;
    let startSearchKey;
    let endSearchKey;
    if (this.state.isFromModifiedDate) {
      startSearchKey = 'from_modified_at';
      endSearchKey = 'to_modified_at';
    }
    if (this.state.isFromApprovedDate) {
      startSearchKey = 'from_verified_at';
      endSearchKey = 'to_verified_at';
    }
    if (this.state.isAllDaysBefore) {
      this.filterParams[startSearchKey] = '';
      this.filterParams[endSearchKey] = filterDate.getTime();
      dateFilterText = ` - ${dateFilterText}`;
    } else if (this.state.isAllDaysAfter) {
      this.filterParams[startSearchKey] = filterDate.getTime();
      this.filterParams[endSearchKey] = '';
      dateFilterText = `${dateFilterText} - `;
    } else {
      this.filterParams[startSearchKey] = filterDate.getTime();
      this.filterParams[endSearchKey] = filterDate.getTime() + 86400000;
    }
    this.handleFilter();
    this.setState({
      isAllDaysBefore: false,
      isAllDaysAfter: false,
    });
    if (this.state.isFromModifiedDate) {
      this.setState({ modifiedDateText: dateFilterText, isFromApprovedDate: false });
    }
    if (this.state.isFromApprovedDate) {
      this.setState({ approvedDateText: dateFilterText, isFromModifiedDate: false });
    }
  };

  getDateValueAndText = (column) => {
    const stateDateValueName = column.key === 'modified_at' ? 'modifiedDateValue' : 'approvedDateValue';
    const stateDateTextName = column.key === 'modified_at' ? 'modifiedDateText' : 'approvedDateText';
    return {
      dateValue: get(this.state, stateDateValueName),
      dateText: get(this.state, stateDateTextName),
    };
  };

  handleUserTypeChange = (e) => {
    const value = e.target.checked === true ? 'personal' : 'All';
    this.setState({ isRecordsToggleSet: e.target.checked });
    this.setState(
      { filterRecord: String(value) },
      () => (this.filterParams['filter_record'] = value === 'All' ? '' : this.state.filterRecord)
    );
    this.handleFilter();
  };

  handleSetRecordsToggle() {
    this.setState({ isRecordsToggleSet: this.props.permitServiceRole === 'db_user' ? true : false }, () => {
      this.filterParams['filter_record'] = this.state.isRecordsToggleSet === true ? 'personal' : '';
      this.handleFilter();
    });
  }

  toggle = () => this.setState({ isDateRangeOpen: !this.state.isDateRangeOpen });

  setInitialFilters(initalFilters) {
    const searchFilters = !isEmpty(localStorage.getItem('utilityFilter'))
      ? JSON.parse(localStorage.getItem('utilityFilter'))
      : initalFilters;

    if (!isEmpty(searchFilters)) {
      this.filterParams = searchFilters.filterParams || searchFilters.filter_params;
      this.rowsPerPage = searchFilters.rowsPerPage;
      this.pageNum = searchFilters.page;
      this.setState(searchFilters,
        () => {
          this.handleFilter();
        }
        );
    }
  }

  handleFilter = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getAllUtility({
        pageNum: 0,
        recordCount: this.state.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        ...this.filterParams,
        successCb: () => {
          this.setState({ isServiceRoleFetched: true });
          this.saveSearchFilters();
        },
      });
    }, 1000);
  };

  getUtilityFilterValues() {
    const {
      isRecordsToggleSet: is_records_toggle_set,
      page,
      rowsPerPage: rows_per_page,
      sort_column,
      sort_order,
      modifiedDate: modified_date,
      modifiedDateValue: modified_date_value,
      modifiedDateText: modified_date_text,
      isFromModifiedDate: is_from_modified_date,
      approvedDate: approved_date,
      approvedDateValue: approved_date_value,
      approvedDateText: approved_date_text,
      selectedDate: selected_date,
      isFromApprovedDate: is_from_approved_date,
      dateRange: date_range,
      selectedStatus: selected_status,
    } = this.state;

    return {
      filter_params: {
        ...this.filterParams,
      },
      other_filters: {
        is_records_toggle_set,
        page,
        rows_per_page,
        sort_column,
        sort_order,
        modified_date,
        modified_date_value,
        modified_date_text,
        is_from_modified_date,
        approved_date,
        approved_date_value,
        approved_date_text,
        selected_date,
        is_from_approved_date,
        date_range,
        selected_status,
      },
    };
  }

  handleListFilter = (e, search = {}) => {
    const searchKey = search.key;
    let { selectedValue } = this.state;
    let inputValue = e.target.value;
    const filterValues = this.state.filterValues;
    filterValues[searchKey] = inputValue;
    this.setState({ filterValues: filterValues });
    if (searchKey === 'current_status') {
      const inputLenth = inputValue.length;
      const indexOfAll = inputValue.indexOf('All');
      if (includes(inputValue, 'All')) {
        selectedValue = indexOfAll === inputLenth - 1 ? ['All'] : inputValue.filter((item) => item !== 'All');
      } else {
        selectedValue = inputValue;
      }
      this.setState({ selectedStatus: selectedValue }, () => {
        this.filterParams[searchKey] = this.state.selectedStatus;
        this.handleFilter();
      });
    } else {
      this.filterParams[searchKey] = inputValue;
      this.handleFilter();
    }
  };

  componentDidMount() {
    if (!isEmpty(this.props.permitServiceRole)) {
      if(!isEmpty(localStorage.getItem('utilityFilter'))){
        this.filterParams = JSON.parse(localStorage.getItem('utilityFilter')).filterParams;
      }
      if (!isEmpty(this.props.utilityFilters.filter_params)) {
        this.filterParams = this.props.utilityFilters.filter_params
      }
      let intilaUtilityFilters = {};
      if(!isEmpty(localStorage.getItem('utilityFilter'))) {
        intilaUtilityFilters = JSON.parse(localStorage.getItem('utilityFilter'));
      } else {
        intilaUtilityFilters =  isEmpty(this.props.utilityFilters)
                                  ? this.getUtilityFilterValues()
                                  : this.props.utilityFilters;
      }
      this.setInitialFilters(intilaUtilityFilters);
    }
  }

  saveSearchFilters = () => {
    const state = this.state;
    const filterParams = this.filterParams;
    const searchFilters = {
      filterParams: filterParams,
      selectedStatus: state.selectedStatus,
      page: state.page,
      rowsPerPage: state.rowsPerPage,
      sort_column: state.sort_column,
      sort_order: state.sort_order,
      modifiedDate: state.modifiedDate,
      modifiedDateValue: state.modifiedDateValue,
      modifiedDateText: state.modifiedDateText,
      isFromModifiedDate: state.isFromModifiedDate,
      approvedDate: state.approvedDate,
      approvedDateValue: state.approvedDateValue,
      approvedDateText: state.approvedDateText,
      selectedDate: state.selectedDate,
      isFromApprovedDate: state.isFromApprovedDate,
      dateRange: state.dateRange,
      isRecordsToggleSet: state.isRecordsToggleSet,
      filterValues : state.filterValues
    };
    localStorage.setItem('utilityFilter', JSON.stringify(searchFilters));
    this.props.setUtilityFilters(searchFilters);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.permitServiceRole !== this.props.permitServiceRole) {
      this.handleSetRecordsToggle();
    }
  }

  render() {
    const {
      classes,
      currentlySending,
      message,
      messageType,
      getAllUtilityInProgress,
      utilityList,
      utilityTotalElements,
      permitServiceRole,
    } = this.props;
    const { page, rowsPerPage, sort_column, sort_order, isRecordsToggleSet } = this.state;
    return (
      <div className={classes.root}>
        <Box className={classes.headerWrapper}>
          <Typography variant="h5" component="h5" className={classes.header}></Typography>
          <Box className={classes.headerSideElements}>
          <OutlinedButton
                text={'Clear Filter'}
                handleClick={() => {
                  localStorage.removeItem('utilityFilter');
                  location.reload();
                }}
                style={classes.clearFilterButton}
                statusDashboardBtnStyle={classes.clearFilterButton}
              />
            <Typography className={classes.selectText}>All Records</Typography>
            <Switch
              checked={isRecordsToggleSet}
              color="primary"
              onChange={(e) => this.handleUserTypeChange(e)}
              disabled={includes(VIEW_ONLY_ROLES, permitServiceRole)}
            />
            <Typography className={classes.selectText}>My Records</Typography>
            <Box className={classes.selectContainer} />
            {!includes(VIEW_ONLY_ROLES, permitServiceRole) && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => this.props.history.push('/utility/new')}
              >
                Create a New Record
              </Button>
            )}
          </Box>
        </Box>
        {currentlySending === true ? (
          <Loader loading={currentlySending} />
        ) : (
          <Fragment>
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <Paper>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader>
                      <TableHead className={classes.tableHeader}>
                        <TableRow className={classes.tableRow}>
                          {UTILITY_LISTING_COLUMNS.map((column, index) => {
                            return (
                              <TableCell className={classes.headerContainer} key={`utility_columns_${index}`}>
                                <TableCell className={classes.headerText} key={index}>
                                  {column.sortable === true ? (
                                    <IconButton
                                      size="small"
                                      className={classes.headerButtonText}
                                      disableRipple={true}
                                      onClick={() => this.sortData(column.id)}
                                    >
                                      {column.label}
                                      {sort_column === column.id &&
                                        (sort_order === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      size="small"
                                      className={classes.headerButton}
                                      disabled={true}
                                      style={{ color: '#FFE785' }}
                                      disableRipple={true}
                                    >
                                      {column.label}
                                    </IconButton>
                                  )}
                                </TableCell>
                                <TableCell className={classes.searchTableCell} colSpan={UTILITY_LISTING_COLUMNS.length}>
                                  {column.hasSearch &&
                                    column.key !== 'current_status' &&
                                    column.key != 'modified_at' &&
                                    column.key != 'verified_at' && (
                                      <TextField
                                        value={this.state.filterValues[column.key]}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ classes: { input: classes.inputField } }}
                                        onChange={(e) => this.handleListFilter(e, column)}
                                        variant="outlined"
                                        size="small"
                                      />
                                    )}
                                  {(column.key === 'modified_at' || column.key === 'verified_at') && (
                                    <DateFilter
                                      dateValue={this.getDateValueAndText(column).dateValue}
                                      dateText={this.getDateValueAndText(column).dateText}
                                      dateRange={this.state.dateRange}
                                      enableButton={this.state.enableButton}
                                      enableSelectedButton={this.state.enableSelectedButton}
                                      selectedDate={this.state.selectedDate}
                                      selectSpecificDateOpen={this.state.selectSpecificDateOpen}
                                      isDateRangeOpen={this.state.isDateRangeOpen}
                                      handleSaveDateRange={() => this.handleSaveDateRange()}
                                      handleFilterDateChange={(e) => this.handleFilterDateChange(e, column)}
                                      handleCloseDateRange={() => this.handleCloseDateRange()}
                                      handleDateRangeChange={(range) => this.handleDateRangeChange(range)}
                                      handleCloseSelectedDate={() => this.handleCloseSelectedDate()}
                                      handleSaveSelectedDate={() => this.handleSaveSelectedDate()}
                                      handleDateChange={(date) => this.handleDateChange(date)}
                                      toggle={() => this.toggle()}
                                    />
                                  )}
                                  {column.hasSearch && column.key === 'current_status' && (
                                    <FormControl className={classes.inputCheckboxForm}>
                                      <Select
                                        variant="outlined"
                                        MenuProps={MenuProps}
                                        onChange={(e) => this.handleListFilter(e, column)}
                                        value={this.state.selectedStatus}
                                        className={classes.styleSelect}
                                        renderValue={(selected) => selectMultipleRender(selected, APPROVAL_STATUS_LIST)}
                                        multiple
                                      >
                                        {APPROVAL_STATUS_LIST.map((data, index) => {
                                          return (
                                            <MenuItem key={index} value={data.value}>
                                              <Checkbox
                                                className={classes.inputCheckbox}
                                                checked={this.state.selectedStatus.indexOf(data.value) > -1}
                                              />
                                              <ListItemText primary={data.title} />
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  )}
                                </TableCell>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      {getAllUtilityInProgress === true || !this.state.isServiceRoleFetched ? (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan={UTILITY_LISTING_COLUMNS.length}
                              style={{ textAlign: 'center', border: 'none' }}
                            >
                              <br />
                              <br />
                              <br />
                              <CircularProgress size={15} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : isEqual(utilityList, []) === true ? (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={UTILITY_LISTING_COLUMNS.length} style={{ textAlign: 'center' }}>
                              <Typography variant="caption">** No Applications Available **</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {utilityList.map((row, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>
                                  {get(row, 'name') && (
                                    <Link href={`/utility/${get(row, 'id')}`} variant="body2">
                                      {row.name}
                                    </Link>
                                  )}
                                </TableCell>
                                <TableCell>{get(row, 'state') || 'NA'}</TableCell>
                                <TableCell>{get(row, 'modified_by') || 'NA'}</TableCell>
                                <TableCell>
                                  {get(row, 'modified_at') !== null
                                    ? moment(get(row, 'modified_at')).format('DD MMM YYYY hh:mm A')
                                    : 'NA'}
                                </TableCell>
                                <TableCell className={classes.approvalStatus}>
                                  <ApprovalStatusColumn
                                    unreadComments={row.unread_comments_count}
                                    rowStatus={getStatus(row, 'current_status')}
                                  />
                                </TableCell>
                                <TableCell>{get(row, 'verified_by') || 'NA'}</TableCell>
                                <TableCell>
                                  {get(row, 'verified_at') !== null
                                    ? moment(get(row, 'verified_at')).format('DD MMM YYYY hh:mm A')
                                    : 'NA'}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={utilityTotalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => this.handleChangePage(e, newPage)}
                    onRowsPerPageChange={(e) => this.handleChangeRowsPerPage(e)}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Fragment>
        )}
        {message && messageType && <Snackbar severity={messageType} message={message} />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllUtility: (payload) => dispatch(getAllUtility(payload)),
  setUtilityFilters: (payload) => dispatch(setUtilityFilters(payload)),
});

const mapStateToProps = (state) => ({
  currentlySending: state.adminReducer.currentlySending,
  getAllUtilityInProgress: state.adminReducer.getAllUtilityInProgress,
  message: state.adminReducer.message,
  messageType: state.adminReducer.messageType,
  utilityList: state.adminReducer.utilityList,
  utilityTotalElements: state.adminReducer.utilityTotalElements,
  permitServiceRole: state.appReducer.permitServiceRole,
  utilityFilters: state.adminReducer.utilityFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusDashboard));
