import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, IconButton } from '@material-ui/core';
import ApplicationDetails from '../../../components/Permitting/ApplicationStatus/ApplicationDetails';
import StatusDetails from '../../../components/Permitting/ApplicationStatus/StatusDetails';
import Actions from '../../../components/Permitting/ApplicationStatus/Actions';
import SuccessDialog from '../../../components/Permitting/SuccessDialog';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import SnakBar from '../../../components/SnakBar';
import PermitChats from '../Chats';
import ChatSvg from '../../../components/Permitting/images/ChatSvg';
import PermitAlert from '../../../components/Permitting/Alert';
const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(3),
    margin: theme.spacing(2.5),
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },
  permitChatWrapper: {
    height: '100%',
    border: '1px solid #7070701f',
  },
  permitChatContainer: {
    maxHeight: theme.spacing(74),
    overflow: 'scroll',
    minHeight: theme.spacing(64.5),
    height: '100%',
  },
  permitChatPaper: {
    height: '100%',
    boxShadow: 'none',
    borderRadius: 0,
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
  chatSvgWrapper: {
    marginLeft: 'auto',
    backgroundColor: '#F37320',
    height: '70px',
    width: '70px',
    border: '1px solid #F37320 ',
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unreadMessageWrapper: {
    color: '#F37320',
    backgroundColor: 'white',
    border: '1px solid #F37320 ',
    borderRadius: 100,
    marginBottom: '40px',
    height: '30px',
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '-20px',
  },
});

export class ApplicationStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permitChatDialog: false,
      showDialog: false,
      dialogStatus: '',
      showReworkSuccessMessage: false,
      showErrorMessage: false,
    };
    this.successDialogOpen = false;
  }

  componentDidUpdate() {
    if (!isEmpty(this.props.materialDetails) && !this.successDialogOpen && this.props.storePaymentSuccess === 1) {
      this.successDialogOpen = true;
      this.toggleDialog(true, 'success');
    }
  }

  openPermitChatDialog = () => {
    this.setState({ permitChatDialog: true });
  };

  closePermitChatDialog = () => {
    this.setState({ permitChatDialog: false });
  };

  toggleDialog = (showDialog, dialogStatus = '') => {
    this.setState({ showDialog, dialogStatus });
  };

  render() {
    const {
      classes,
      projectDetails,
      viewPermitApplication,
      isAddServicesClicked,
      getSelectedReworkServices,
      isMobile,
      isPermitChatButton,
      isSmallScreen,
      unreadMessageCount,
      breadcrumbPath,
      searchParam,
      fileDownloadStatus,
      applicationDetail,
      ...other
    } = this.props;

    return (
      <div className={classes.root}>
        <PermitAlert
          permitId={this.props.permitId || 'New'}
          customerName={this.props.applicationDetail.project_details.customer_name}
        />
        <ApplicationDetails />
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={3} className={classes.topMargin}>
            <StatusDetails isSmallScreen={isSmallScreen} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} className={classes.topMargin}>
            <Actions
              permitId={this.props.match.params.permit_id}
              viewPermitApplication={viewPermitApplication}
              isAddServicesClicked={isAddServicesClicked}
              getSelectedReworkServices={getSelectedReworkServices}
              isMobile={isMobile}
              breadcrumbPath={breadcrumbPath}
              searchParam={searchParam}
              fileDownloadStatus={fileDownloadStatus}
            />
          </Grid>
          {!isPermitChatButton && (
            <Grid item xs={12} sm={12} md={6} lg={5} className={classes.topMargin}>
              <Grid container direction="row" className={classes.permitChatWrapper}>
                <Grid item xs={12} className={classes.permitChatContainer}>
                  <Paper className={classes.permitChatPaper}>
                    <PermitChats isFromApplicationStatus appDetails={applicationDetail} {...other} />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isPermitChatButton && !isSmallScreen && (
            <Fragment>
              <div className={classes.chatSvgWrapper}>
                <IconButton disableRipple onClick={() => this.openPermitChatDialog()}>
                  <ChatSvg />
                </IconButton>
              </div>

              {Number(unreadMessageCount) > 0 && (
                <div className={classes.unreadMessageWrapper}>{unreadMessageCount}</div>
              )}
            </Fragment>
          )}
          {this.state.permitChatDialog && (
            <PermitChats
              appDetails={applicationDetail}
              isFromApplicationStatusButton
              {...other}
              handleCloseDialog={this.closePermitChatDialog}
            />
          )}
        </Grid>
        {this.successDialogOpen && (
          <SuccessDialog
            showDialog={this.state.showDialog}
            dialogStatus={this.state.dialogStatus}
            toggleDialog={this.toggleDialog}
            permitId={this.props.permitId}
            handleReset={() => this.props.handleReset()}
            resetData={() => {}}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  materialDetails: state.permitReducer.materialDetails,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicationStatus));
