import React from 'react';

const Battery = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="18.908" height="13.058" viewBox="0 0 18.908 13.058">
        <g transform="translate(-758.487 -418.81)">
          <g transform="translate(758.487 418.81)">
            <g transform="translate(0 0)">
              <g transform="translate(0 0.399)">
                <path
                  fill="#fff"
                  d="M17.561,12.641H.758A.259.259,0,0,1,.5,12.383V1.6a.259.259,0,0,1,.258-.259h2.29V.758A.259.259,0,0,1,3.306.5H5.67a.259.259,0,0,1,.258.258v.585h6.462V.758A.259.259,0,0,1,12.649.5h2.364a.259.259,0,0,1,.258.258v.585h2.29a.259.259,0,0,1,.258.259v10.78A.259.259,0,0,1,17.561,12.641Z"
                  transform="translate(-0.242 -0.242)"
                />
                <path
                  fill="#454545"
                  d="M17.319,12.141h0V1.361H14.772V.517H12.407v.844H5.428V.517H3.065v.844H.517v10.78h16.8m0,.517H.517A.517.517,0,0,1,0,12.141V1.361A.517.517,0,0,1,.517.844H2.548V.517A.517.517,0,0,1,3.065,0H5.428a.517.517,0,0,1,.517.517V.844h5.946V.517A.517.517,0,0,1,12.407,0h2.364a.517.517,0,0,1,.517.517V.844h2.031a.517.517,0,0,1,.517.517v10.78A.517.517,0,0,1,17.319,12.658Z"
                  transform="translate(0 0)"
                />
              </g>
              <g transform="translate(3.452 0)">
                <text
                  fill="#454545"
                  fontSize="5px"
                  fontFamily="Roboto-Medium, Roboto"
                  fontWeight="500px"
                  transform="translate(0 6.609)"
                >
                  <tspan x="0" y="0">
                    +
                  </tspan>
                </text>
                <text
                  fill="#454545"
                  fontSize="6px"
                  fontFamily="Roboto-Medium, Roboto"
                  fontWeight="500"
                  transform="translate(9.781 6)"
                >
                  <tspan x="0" y="0">
                    -
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Battery;
