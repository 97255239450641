import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appReducer from './containers/Layouts/reducer';
import adminReducer from './containers/Admin/reducer';
import equipmentReducer from './containers/Admin/Equipment/reducer';
import applicationReducer from './containers/Admin/RequestDetails/reducer';
import permitReducer from './containers/Permitting/reducer';
import structuralAssemblyReducer from './containers/Admin/StructuralAssembly/reducer';
import batteryConfigurationReducer from './containers/Admin/BatteryConfiguration/reducer';

export default combineReducers({
  form: formReducer,
  appReducer,
  adminReducer,
  equipmentReducer,
  permitReducer,
  applicationReducer,
  structuralAssemblyReducer,
  batteryConfigurationReducer,
});
