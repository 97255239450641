import React from 'react';
import { Label } from '../../../../../../common/Label';
import { get, isEmpty } from '../../../../../../../utils/lodash';
import { CELL_TYPE } from '../../../../../../../containers/Admin/constants';
import CustomPanel from '../../../../../../common/PanelTemplateWithRadioButton';
import { SingleSelectDropdown } from '../../../../../../common/SingleSelectDropdown';
import { Box, Grid, makeStyles, TextField, FormControlLabel, Checkbox } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  checkboxLabel: {
    margin: theme.spacing(4, 0, -1, -0.3)
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  }
}));

const PhysicalDetailsSection = props => {
  const classes = useStyles();
  const {
    unit,
    errorData,
    handlePhysicalDetails,
    physicalDetails,
    sectionLabel,
    readOnly,
    getErrorText,
    getHelperText
  } = props;

  const {
    width = '',
    length = '',
    thickness = '',
    weight = '',
    cell_type = '',
    black_on_black_panel = false
  } = physicalDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel
        header={sectionLabel}
        radioDetails={{
          handleFunction: handlePhysicalDetails,
          value: unit
        }}
      >
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'width'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Width ${unit === 'imperial' ? '(in)' : '(mm)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={width || ''}
                onChange={e => handlePhysicalDetails(e, 'width')}
                error={!isEmpty(get(errorData, 'width'))}
                helperText={get(errorData, 'width')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'length'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Length ${unit === 'imperial' ? '(in)' : '(mm)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={length || ''}
                onChange={e => handlePhysicalDetails(e, 'length')}
                error={!isEmpty(get(errorData, 'length'))}
                helperText={get(errorData, 'length')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'thickness'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Thickness ${unit === 'imperial' ? '(in)' : '(mm)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={thickness || ''}
                onChange={e => handlePhysicalDetails(e, 'thickness')}
                error={!isEmpty(get(errorData, 'thickness'))}
                helperText={get(errorData, 'thickness')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'weight'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Weight ${unit === 'imperial' ? '(lb)' : '(kg)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={weight || ''}
                onChange={e => handlePhysicalDetails(e, 'weight')}
                error={!isEmpty(get(errorData, 'weight'))}
                helperText={get(errorData, 'weight')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.checkboxWrapper}>
              <FormControlLabel
                checked={black_on_black_panel}
                className={classes.checkboxLabel}
                control={<Checkbox className={classes.inputCheckbox} />}
                label="Black on Black Panel"
                onChange={e => handlePhysicalDetails(e, 'black_on_black_panel')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Cell type'} isRequired />}
                dropDownOptions={CELL_TYPE}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handlePhysicalDetails}
                fieldId="cell_type"
                fieldValue={cell_type}
                readOnly={readOnly}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default PhysicalDetailsSection;
