import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { preventDefault, preventDefaultDecimal } from '../../../../../containers/Admin/helper';
import { Label } from '../../../../common/Label';
import CustomPanel from '../../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../../common/SingleSelectDropdown';
import {
  BUILDING_ELEVATION_OPTIONS,
  CONDUIT_LOCATION_OPTIONS,
  FLOOD_MAP_OPTIONS,
  YES_NO_OPTIONS,
  ROOF_ACCESS_POINT_OPTIONS,
} from '../constants';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
}));

const SiteAndRoofPlanSection = (props) => {
  const classes = useStyles();
  const {
    getErrorText,
    getHelperText,
    handleSiteAndRoofPlan,
    siteAndRoofPlan,
    sectionLabel,
    readOnly,
    stateSelected,
    errorData,
  } = props;
  const {
    min_allowable_tilt = '',
    max_allowable_tilt = '',
    max_allowable_tilt_for_metal_roof = '',
    max_span_for_landscape = '',
    max_span_for_portrait = '',
    conduit_locations = '',
    roof_access_point_required = '',
    building_elevation_required = '',
    flood_map_required = '',
    rafter_framing_details_required = '',
    fire_setbacks_requirements = '',
    flat_roof_requirements = '',
    ground_mount_requirements = '',
    additional_requirements_site_and_roof_plan = '',
  } = siteAndRoofPlan;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id="min_allowable_tilt"
                type="number"
                className={classes.inputField}
                label={<Label text={'Min allowable tilt (°)'} capitalizeLabelText={false} />}
                value={min_allowable_tilt ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'min_allowable_tilt')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefaultDecimal(e)}
                error={!isEmpty(get(errorData, 'min_allowable_tilt'))}
                helperText={get(errorData, 'min_allowable_tilt')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id="max_allowable_tilt"
                type="number"
                className={classes.inputField}
                label={<Label text={'Max allowable tilt (°)'} capitalizeLabelText={false} />}
                value={max_allowable_tilt ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'max_allowable_tilt')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefaultDecimal(e)}
                error={!isEmpty(get(errorData, 'max_allowable_tilt'))}
                helperText={get(errorData, 'max_allowable_tilt')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id="max_allowable_tilt_for_metal_roof"
                type="number"
                className={classes.inputField}
                label={<Label text={'Max allowable tilt for metal roof (°)'} capitalizeLabelText={false} />}
                value={max_allowable_tilt_for_metal_roof ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'max_allowable_tilt_for_metal_roof')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefaultDecimal(e)}
                error={!isEmpty(get(errorData, 'max_allowable_tilt_for_metal_roof'))}
                helperText={get(errorData, 'max_allowable_tilt_for_metal_roof')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id="max_span_for_landscape"
                type="number"
                className={classes.inputField}
                label={<Label text={'Max span for landscape (in)'} capitalizeLabelText={false} />}
                value={max_span_for_landscape ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'max_span_for_landscape')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefault(e)}
                error={!isEmpty(get(errorData, 'max_span_for_landscape'))}
                helperText={get(errorData, 'max_span_for_landscape')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id="max_span_for_portrait"
                type="number"
                className={classes.inputField}
                label={<Label text={'Max span for portrait (in)'} capitalizeLabelText={false} />}
                value={max_span_for_portrait ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'max_span_for_portrait')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefault(e)}
                error={!isEmpty(get(errorData, 'max_span_for_portrait'))}
                helperText={get(errorData, 'max_span_for_portrait')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Conduit locations'} capitalizeLabelText={false} />}
                dropDownOptions={CONDUIT_LOCATION_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSiteAndRoofPlan}
                fieldId="conduit_locations"
                fieldValue={conduit_locations ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Roof access point required'} capitalizeLabelText={false} />}
                dropDownOptions={ROOF_ACCESS_POINT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSiteAndRoofPlan}
                fieldId="roof_access_point_required"
                fieldValue={roof_access_point_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Building elevation required'} capitalizeLabelText={false} />}
                dropDownOptions={BUILDING_ELEVATION_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSiteAndRoofPlan}
                fieldId="building_elevation_required"
                fieldValue={building_elevation_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Flood map required'} capitalizeLabelText={false} />}
                dropDownOptions={FLOOD_MAP_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSiteAndRoofPlan}
                fieldId="flood_map_required"
                fieldValue={flood_map_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <SingleSelectDropdown
                dropDownLabel={
                  <Label text={'Rafter framing details (attic view) required'} capitalizeLabelText={false} />
                }
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSiteAndRoofPlan}
                fieldId="rafter_framing_details_required"
                fieldValue={rafter_framing_details_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="fire_setbacks_requirements" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Fire setbacks/pathways requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={fire_setbacks_requirements ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'fire_setbacks_requirements')}
              />
            </Box>
            <Box id="flat_roof_requirements" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Flat roof requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={flat_roof_requirements ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'flat_roof_requirements')}
              />
            </Box>
            <Box id="ground_mount_requirements" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Ground mount requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={ground_mount_requirements ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'ground_mount_requirements')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box
              id="additional_requirements_site_and_roof_plan"
              className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}
            >
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Additional requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={additional_requirements_site_and_roof_plan ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'additional_requirements_site_and_roof_plan')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default SiteAndRoofPlanSection;
