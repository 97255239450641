import React from 'react';

const Document = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="64"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <circle fill="#00C8C8" cx="256" cy="256" r="256" />
      <path
        fill="#1CADB5"
        d="M295.437,508.976C398.259,493.077,480.856,415.99,504.89,316.09L403.168,214.369l-13.698,9.426
	l-80.84-80.84L119.555,333.094L295.437,508.976z"
      />
      <polygon fill="#FFD400" points="253.09,87.414 119.555,87.414 119.555,333.094 308.631,333.094 308.631,142.955 " />
      <polygon fill="#FFB000" points="308.631,142.955 253.09,87.414 207.952,87.414 207.952,333.094 308.631,333.094 " />
      <polygon fill="#FF432E" points="253.09,142.955 308.631,142.955 253.09,87.414 " />
      <polygon
        fill="#FFFFFF"
        points="347.627,158.828 214.093,158.828 214.093,404.508 403.168,404.508 403.168,214.369 "
      />
      <polygon
        fill="#F2F6FC"
        points="403.168,214.369 347.627,158.828 307.952,158.828 307.952,404.508 403.168,404.508 "
      />
      <g>
        <polygon fill="#DCE1EB" points="347.627,214.369 403.168,214.369 347.627,158.828 	" />
        <rect x="239.5" y="240.37" fill="#DCE1EB" width="138.26" height="12.408" />
      </g>
      <rect x="307.95" y="240.37" fill="#CDD2E1" width="69.809" height="12.408" />
      <rect x="239.5" y="269.61" fill="#DCE1EB" width="138.26" height="12.408" />
      <rect x="307.95" y="269.61" fill="#CDD2E1" width="69.809" height="12.408" />
      <rect x="239.5" y="298.86" fill="#DCE1EB" width="138.26" height="12.408" />
      <rect x="307.95" y="298.86" fill="#CDD2E1" width="69.809" height="12.408" />
      <rect x="239.5" y="328.11" fill="#DCE1EB" width="138.26" height="12.408" />
      <rect x="307.95" y="328.11" fill="#CDD2E1" width="69.809" height="12.408" />
    </svg>
  );
};

export default Document;
