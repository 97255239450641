import jpeg from './jpegIcon';
import png from './pngIcon';
import document from './document';
import getConfig from '../../../config/env';

const getImgUrl = (filename) => {
  const config = getConfig();
  return config.resource_cdn_url + 'ui_imgs/' + filename;
};

export const existingOpenSubpanelPhoto = getImgUrl('Existing-open-subpanel-photo.jpg');
export const mspWithDeadFrontPhoto = getImgUrl('MSP-with-dead-front.jpg');
export const mspWithoutDeadFront = getImgUrl('MSP-without-Dead-front.jpg');
export const utilityMeterPhoto = getImgUrl('Utility-meter-photos.jpg');
export const npsBackgrdImg = getImgUrl('hero--xxl.jpg');
export const mspPanelStickerPhoto = getImgUrl('MSP-Panel-sticker-photo.jpeg');
export const subPanelStickerPhoto = getImgUrl('Sub-Panel-sticker-photo.jpg');
export const trenchingRoute = getImgUrl('Trenching route.png');
export const atsPhoto = getImgUrl('Automatic Transfer Switch (ATS) photo.gif');
export const enphasePermitting = getImgUrl('enphasePermitting.png');
export const footerPermitting = getImgUrl('footer.png');

export default { png, jpeg, document };
