import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  TextField,
  Box,
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from '../../../utils/lodash';
import { getSearchResult, getSiteDetails, putApplicationBySite } from '../../../containers/Permitting/action';
import { SITE_LINK_ERROR } from '../../../containers/Permitting/constants';

const styles = theme => ({
  linkWithExistingSystem: {
    fontSize: 15,
    borderRadius: 3,
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    textTransform: 'none',
    height: theme.spacing(4.75),
    padding: theme.spacing(0, 3),
    border: '1px solid #F3731F',
    fontWeight: 600,
    whiteSpace: 'nowrap'
  },
  linkWithExistingSystemDialogHeader: {
    color: '#335977',
    fontSize: '29px',
    fontWeight: 'Bold'
  },
  linkWithExistingSystemDialogClose: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    opacity: 0.23
  },
  linkWithExistingSystemDialogcontent: {
    border: '1px solid #7070704D',
    margin: '0 20px',
    padding: theme.spacing(3)
  },
  linkWithExisitngSystemDialogButtonWrapper: {
    justifyContent: 'flex-start',
    padding: theme.spacing(2.5)
  },
  linkWithExistingSystemDialogRejectButton: {
    color: '#919191'
  },
  linkWithExistingSystemEditIconText: {
    color: '#919191',
    fontSize: 15,
    marginLeft: theme.spacing(3)
  },
  siteIdText: {
    fontSize: 15,
    marginLeft: theme.spacing(1)
  },
  systemIdWrapper: {
    paddingBottom: theme.spacing(2)
  },
  alreadyLinkedMessage: {
    color: theme.palette.primary.main
  },
});

class SiteLinkDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openLinkWithExistingSystem: false,
      selectedSiteValue: this.props.siteId || '',
      searchValue: '',
      isSiteIdAssigned: false,
      linkSiteInProgress: false,
      linkSiteMessage: '',
      siteVerification: false
    };
    this.timeout = null;
  }

  componentDidMount() {
    this.props.getSearchResult({ searchValue: '_ALL_SITES_', searchType: 'all_stages' });
  }

  handleSearch = event => {
    if (event) {
      const newValue = event.target.value;
      clearTimeout(this.timeout);
      this.setState({ searchValue: newValue });
      if (newValue && newValue.length > 0) {
        this.timeout = setTimeout(() => {
          this.props.getSearchResult({ searchValue: newValue, searchType: 'all_stages' });
        }, 1000);
      } else {
        this.props.getSearchResult({ searchValue: '_ALL_SITES_', searchType: 'all_stages' });
        this.setState({ searchValue: '' });
      }
    }
  };

  handleSelectedSiteValue = (e, selectedSite) => {
    clearTimeout(this.timeout);
    if (selectedSite) {
      this.setState({ searchValue: selectedSite.label, selectedSiteValue: selectedSite.id, siteVerification: true });
      this.onSiteChange({ siteId: selectedSite.id, cbk: () => this.setState({ siteVerification: false }) });
    }
  };

  onSiteChange = ({ siteId, cbk }) => {
    if (siteId) {
      const { projectType } = this.props;
      this.setState({ linkSiteInProgress: true });
      this.props.getSiteDetails({
        siteId,
        withStore: false,
        successCbk: (result) => {
          if (result && result.length > 0) {
            const siteType = projectType === 'RESIDENTIAL' ? 1 : projectType === 'COMMERCIAL' ? 2 : 3;
            if (siteType !== result[0].site_type) {
              this.setState({ linkSiteInProgress: false, linkSiteMessage: SITE_LINK_ERROR(result[0].site_type, projectType) });
            } else {
              this.setState({ linkSiteInProgress: false, linkSiteMessage: '' });
              cbk();
            }
          } else {
            this.setState({ linkSiteInProgress: false, linkSiteMessage: '' });
            cbk();
          }
        },
        failureCbk: () => this.setState({ linkSiteInProgress: false, linkSiteMessage: 'Something went wrong, Please try again' })
      });
    }
  }

  handleLinkWithExistingSystemDialogClose = () => this.setState({ openLinkWithExistingSystem: false, searchValue: '' });

  linkWithExistingSystem = () => {
    this.props.putApplicationBySite({
      permitId: this.props.permitId,
      siteId: this.state.selectedSiteValue,
      successCbk: () => {
        this.setState({ openLinkWithExistingSystem: false, isSiteIdAssigned: true });
      }
    });
  };

  render() {
    const { classes, permitId } = this.props;
    return (
      <div>
        <Dialog
          onClose={this.handleLinkWithExistingSystemDialogClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.openLinkWithExistingSystem}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleLinkWithExistingSystemDialogClose}>
            <Typography className={classes.linkWithExistingSystemDialogHeader}>
              Link With Existing System
              <CloseIcon
                onClick={this.handleLinkWithExistingSystemDialogClose}
                className={classes.linkWithExistingSystemDialogClose}
                size="large"
              />
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.linkWithExistingSystemDialogcontent}>
            <Typography style={{ fontWeight: 'bold' }}>
              Link Permit Assistance Request with a system already created in Enlighten
            </Typography>
            <Autocomplete
              autoComplete
              getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
              filterOptions={x => x}
              options={this.props.searchResults.total_count ? this.props.searchResults.result : []}
              value={this.state.searchValue}
              inputValue={this.state.searchValue}
              getOptionSelected={(option, value) => option.label === value}
              onChange={this.handleSelectedSiteValue}
              onInputChange={this.handleSearch}
              loading={this.props.siteSearchInProgress}
              closeIcon={isEmpty(this.state.searchValue) ? <Fragment /> : <CloseIcon fontSize="small" />}
              renderInput={params => (
                <TextField
                  className={clsx(this.state.width < 600 && classes.relativePosition)}
                  {...params}
                  required
                  label="Please enter the system name"
                />
              )}
              renderOption={option => (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body1" color="textPrimary">
                      {option.id} - {option.label}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            />
            <br />
            {this.state.linkSiteInProgress && <CircularProgress size={15} />}
            {this.state.linkSiteMessage && this.state.linkSiteMessage.length > 0 && (
              <Typography variant="caption" className={classes.alreadyLinkedMessage}>
                {this.state.linkSiteMessage} <br />
              </Typography>
            )}
          </DialogContent>
          <DialogActions className={classes.linkWithExisitngSystemDialogButtonWrapper}>
            <Button
              onClick={this.handleLinkWithExistingSystemDialogClose}
              className={classes.linkWithExistingSystemDialogRejectButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              disabled={!this.state.selectedSiteValue || this.state.siteVerification}
              onClick={() => this.linkWithExistingSystem()}
            >
              Link
            </Button>
          </DialogActions>
        </Dialog>
        {!this.props.siteId &&
        !this.state.isSiteIdAssigned &&
        !this.props.isPermitSetAvailable &&
        !isEmpty(permitId) && (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            className={classes.linkWithExistingSystem}
            disableRipple
            onClick={() => this.setState({ openLinkWithExistingSystem: true })}
          >
            Link With Existing System
          </Button>
        )}
        {(this.props.siteId || this.state.isSiteIdAssigned) &&
        !this.props.isPermitSetAvailable &&
        !isEmpty(permitId) && (
          <Box className={classes.systemIdWrapper} display="flex" alignItems="center">
            <Typography className={classes.linkWithExistingSystemEditIconText}>System Id:</Typography>
            <Typography className={classes.siteIdText}>{this.state.selectedSiteValue}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.permitReducer.searchResults,
  permitId: state.permitReducer.permitId,
  siteSearchInProgress: state.permitReducer.siteSearchInProgress
});

const mapDispatchToProps = dispatch => ({
  getSearchResult: payload => dispatch(getSearchResult(payload)),
  putApplicationBySite: payload => dispatch(putApplicationBySite(payload)),
  getSiteDetails: payload => dispatch(getSiteDetails(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(SiteLinkDialog)));
