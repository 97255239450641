import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../../components/Loader';
import {
  sendMessage,
  getMessageList,
  getS3URL,
  putToS3,
  getAccountData,
  updateChatStatus,
  setChatAutomatedMessagesDisabled,
} from '../action';
import ChatBox from './ChatBox';
import { get } from '../../../utils/lodash';
import { setToken, getToken } from '../../../utils/helper';
import {
  setEnlightenManagerToken,
  getAccountDtails,
} from '../../Layouts/actions';
import settings from '../../../utils/settings';

const styles = theme => ({});

export class MessageHistory extends React.Component {
  componentDidMount() {
    this.updateEnlightenManagerToken();
    this.props.getAccountData();
    this.props.getAccountDetails();
    this.fecthAllChats();
  }

  componentDidUpdate() {
    this.updateEnlightenManagerToken();
  }

  updateEnlightenManagerToken = () => {
    const { enlightenToken, enlightenManagerToken } = this.props;
    const token = getToken() || enlightenToken;
    if (!enlightenManagerToken && token) {
      this.props.setEnlightenManagerToken(token);
      setToken(token);
    }
  };

  fecthAllChats() {
    const permit_id =
      get(this.props, 'match.params.permit_id') ||
      get(this.props, 'match.params.request_id') ||
      '';
    this.props.getMessageHistoryForRequest({
      permitId: permit_id,
      failureCB: () => {
        setToken('');
        window.location.replace(settings.enlightenUrl('logout'));
      }
    });
  }

  handleClose() {
    if (this.props.isFromApplicationStatusButton) {
      this.props.handleCloseDialog();
    } else {
      window.history.back();
    }
  }

  render() {
    const {
      messageList,
      sendMessage,
      getPresingedURL,
      uploadToS3,
      updateChatStatus,
      unreadMessageCount,
      messageAcknowledgedBy,
      accountLoading,
      accountDetail,
      isFromAdmin = false,
      enlightenManagerToken,
      isFromApplicationStatus = false,
      appDetails,
      firstName,
      lastName,
      permitServiceRole,
      chatAutomatedMessagesDisabled,
      setIsChatAutomatedMessagesDisabled,
    } = this.props;
    const permit_id =
      get(this.props, 'match.params.permit_id') ||
      get(this.props, 'match.params.request_id') ||
      '';
    if (accountLoading || !enlightenManagerToken) {
      return <Loader loading />;
    }
    const currentUserEmail = get(accountDetail, 'email', '') || '';
    const currentUserName = firstName + " " + lastName;
    return (
      <ChatBox
        messages={messageList}
        currentUserEmail={currentUserEmail}
        currentUserName={currentUserName}
        sendMessage={sendMessage}
        appId={permit_id}
        fetchAllChats={this.fecthAllChats.bind(this)}
        getPresingedURL={getPresingedURL}
        uploadToS3={uploadToS3}
        handleClose={this.handleClose.bind(this)}
        updateChatStatus={updateChatStatus}
        unreadMessageCount={unreadMessageCount}
        messageAcknowledgedBy={messageAcknowledgedBy}
        isFromAdmin={isFromAdmin}
        isFromApplicationStatus={isFromApplicationStatus}
        appDetails={appDetails}
        permitServiceRole={permitServiceRole}
        setChatAutomatedMessagesDisabled={setIsChatAutomatedMessagesDisabled}
        isChatAutomatedMessagesDisabled={chatAutomatedMessagesDisabled}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMessageHistoryForRequest: payload => dispatch(getMessageList(payload)),
  sendMessage: payload => dispatch(sendMessage(payload)),
  getPresingedURL: payload => dispatch(getS3URL(payload)),
  uploadToS3: payload => dispatch(putToS3(payload)),
  updateChatStatus: payload => dispatch(updateChatStatus(payload)),
  getAccountData: () => dispatch(getAccountData()),
  getAccountDetails: () => dispatch(getAccountDtails()),
  setEnlightenManagerToken: tokenVal =>
    dispatch(setEnlightenManagerToken(tokenVal)),
  setIsChatAutomatedMessagesDisabled: isAutomatedMessagesDisabled =>
    dispatch(setChatAutomatedMessagesDisabled(isAutomatedMessagesDisabled)),
});

const mapStateToProps = state => ({
  messageList: state.permitReducer.messageList,
  unreadMessageCount: state.permitReducer.unreadMessageCount,
  messageAcknowledgedBy: state.permitReducer.messageAcknowledgedBy,
  accountDetail: state.permitReducer.accountDetail,
  firstName: state.appReducer.firstName,
  lastName: state.appReducer.lastName,
  accountLoading: state.permitReducer.accountLoading,
  enlightenManagerToken: state.appReducer.enlightenManagerToken,
  permitServiceRole: state.appReducer.permitServiceRole,
  chatAutomatedMessagesDisabled:
    state.permitReducer.isChatAutomatedMessagesDisabled,
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(MessageHistory)
);
