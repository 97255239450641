import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Link,
  Button
} from '@material-ui/core';
import { localeMoment as moment } from '../../../common/moment';
import { cloneDeep, get, isEmpty } from 'lodash';
import { INSTALLER_STATES } from '../../../../utils/constants';
import CorrectionDialog from '../CorrectionDialog';
import { getPresingedURL, uploadToS3 } from '../../../../containers/Admin/actions';
import { moreInfoSubmit } from '../../../../containers/Permitting/action';
import { addNextInstallerStatuses } from '../../../../containers/Permitting/helper';

const styles = theme => ({
  heading: {
    fontWeight: 'bold',
    color: '#F37320',
    background: '#FAFAFA'
  },
  panelHead: {
    minHeight: 'auto !important',
    padding: theme.spacing(1, 2),
    background: '#FAFAFA',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    }
  },
  panelWrapper: {
    maxHeight: '530px',
    minHeight: '530px',
    overflow: 'scroll'
  },
  columnFlex: {
    flexDirection: 'column'
  },
  stepperWrapper: {
    width: '100%',
    padding: theme.spacing(1.25, 0)
  },
  statusLabel: {
    fontWeight: 'bold',
    fontSize: 14,
    textTransform: 'capitalize'
  },
  updatedDate: {
    fontSize: '12px'
  },
  textColor: {
    color: 'green'
  },
  expansionPanel: {
    boxShadow: 'none',
    borderRadius: '0px !important',
    border: '1px solid #7070701f'
  },
  panelHeadContent: {
    margin: '0px !important'
  },
  link: {
    textTransform: 'none',
    color: theme.palette.primary.link
  }
});

const StatusDetails = props => {
  const { classes, applicationDetail, isSmallScreen, getUrl, upload, resubmission } = props;
  const [ fileUploadInProgress, setFileUploadInProgress ] = useState(false);
  let allSelectedFiles = [];

  let modifiedInstallerStatuses = [];
  let moreInfoRequested = false;
  if (applicationDetail) {
    modifiedInstallerStatuses = cloneDeep(applicationDetail.installer_statuses || []);
  }
  if (
    modifiedInstallerStatuses.length > 0 &&
    modifiedInstallerStatuses[modifiedInstallerStatuses.length - 1].state_id === INSTALLER_STATES.MORE_INFO_REQUESTED.id
  ) {
    modifiedInstallerStatuses[modifiedInstallerStatuses.length - 1].moreInfoRequested = true;
    moreInfoRequested = true;
  }

  const activeStep = applicationDetail && applicationDetail.installer_statuses ? applicationDetail.installer_statuses.length : 0;
  modifiedInstallerStatuses = addNextInstallerStatuses(modifiedInstallerStatuses);
  const [ openDialog, setOpenDialog ] = useState(moreInfoRequested);

  useEffect(() => {
    if (props.isAddOnServicesClicked) {
      props.jumpToServiceScreen();
      props.isAddServicesClicked(false);
    }
  }, [ props.isAddOnServicesClicked ]);
  
  function getStatusLabel(state) {
    if (state.state_id === 8 || state.state_id === 9) {
      if (get(state, 'substate_id', 0) === 1) {
        return state.substate_name;
      } else {
        return state.state_name;
      }
    } else {
      return state.state_name;
    }
  }

  const callS3AndUpload = (files, comment, versions, cbk) => {
    if (files.length === 0) {
      cbk(allSelectedFiles);
      return;
    }
    const file = files[0];
    const typeArray = file.name.split('.');
    const fileType = typeArray ? typeArray[typeArray.length - 1] : '';
    const fileTag = 'RESUBMISSION_DOC';
    const fileName = `${applicationDetail?.permit_id}_${fileTag}_${moment().format('DDMMYYYY-hhmmss')}.${fileType}`;
    getUrl({
      file_name: fileName,
      http_method: 'PUT',
      successCb: presignedUrl => {
        upload({
          fName: fileName,
          fileObj: file,
          preSignedS3Url: presignedUrl,
          successCbS3: () => {
            allSelectedFiles.push({
              file_name: fileName,
              original_file_name: file.name,
              file_size: file.size / 1024 / 1024,
              loading: false,
              comment: comment,
              versions: versions
            });
            callS3AndUpload(files.slice(1, files.length), comment, versions, cbk);
          }
        });
      }
    });
  };

  const fireUpload = (files, comment) => {
    const versions = [applicationDetail?.version?.current_version];
    setFileUploadInProgress(true);
    callS3AndUpload(files, comment, versions, () => {
      resubmission({
        files: allSelectedFiles,
        permitId: applicationDetail?.permit_id,
        comment: comment,
        successCbk: () => window.location.reload()
      });
    });
  }

  return (
    <Fragment>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Accordion expanded={true} className={classes.expansionPanel}>
            <AccordionSummary
              aria-controls="sDetails-content"
              id="sDetails"
              className={classes.panelHead}
              classes={{ content: classes.panelHeadContent }}
            >
              <Typography className={classes.heading}>Permit Status</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.columnFlex}>
              <div className={clsx(!isSmallScreen && classes.panelWrapper)}>
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  className={classes.stepperWrapper}
                >
                  {modifiedInstallerStatuses.map(status => (
                    <Step key={status.state_id} expanded={true}>
                      <StepLabel>
                        <Typography className={classes.statusLabel}>{getStatusLabel(status)}</Typography>
                      </StepLabel>
                      <StepContent>
                        {!isEmpty(status.last_updated_date) && (
                          <Typography className={classes.updatedDate}>
                            <span className={classes.textColor}>{'Updated On : '}</span>
                            {moment(status.last_updated_date).format('DD MMM YYYY')}
                          </Typography>
                        )}
                        {status.moreInfoRequested && (
                          <Button disableRipple size="small" onClick={() => setOpenDialog(true)}>
                            <Link variant="body2" className={classes.link}>
                              Provide More Information
                            </Link>
                          </Button>
                        )}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <CorrectionDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title="Provide More Information"
        buttonText="Submit More Information"
        appDetails={applicationDetail}
        fireUpload={fireUpload}
        fileUploadInProgress={fileUploadInProgress}
        getPresingedURL={props.getUrl}
        messageList={props.messageList}
      />
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  upload: payload => dispatch(uploadToS3(payload)),
  getUrl: payload => dispatch(getPresingedURL(payload)),
  resubmission: payload => dispatch(moreInfoSubmit(payload))
});

const mapStateToProps = state => ({
  applicationDetail: state.permitReducer.applicationDetail,
  messageList: state.permitReducer.messageList
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusDetails));
