import React from 'react';
import CountyField from '../CountyField';
import { Label } from '../../../common/Label';
import HelpTextIcon from '../../../common/HelpTextIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get, hasIn, isEmpty, includes } from '../../../../utils/lodash';
import { statesList } from '../../../../containers/Admin/constants';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';
import { Box, Checkbox, FormControlLabel, Grid, makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  checkboxLabel: {
    margin: theme.spacing(4, 0, -1, -0.3),
  },
  cityCheckboxLabel: {
    margin: theme.spacing(0.5, 0, -1, -0.3),
    '& .MuiFormControlLabel-label': {
      fontSize: theme.spacing(1.75),
      fontWeight: 400,
      color: theme.palette.primary.lightBlack,
    },
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
}));

const BasicDetailsSectionOldUI = (props) => {
  const classes = useStyles();
  const {
    sectionLabel,
    errorData,
    basicDetails,
    checkBoxValue,
    ahjCityCountyDetails,
    handleBasicDetails,
    handleClearCouties,
    readOnly,
    showCityCheckbox,
    handleCityCheckbox,
    countyRequired,
  } = props;
  const cities = ahjCityCountyDetails.cities;
  const { name = '', city = '', counties = [], state = '' } = basicDetails;
  const { county = '', county2 = '', county3 = '', county4 = '', county5 = '' } = counties;
  const getCityCountyMapValue = (city, key) => {
    return city[key];
  };

  return (
    <Box className={classes.subToot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={classes.inputField}
                label={<Label text={'AHJ (auto-filled)'} />}
                value={name}
                id="name"
                error={!countyRequired && hasIn(errorData, 'name')}
                helperText={!countyRequired && get(errorData, 'name')}
                disabled
              />
              {showCityCheckbox && (
                <FormControlLabel
                  checked={countyRequired}
                  className={classes.cityCheckboxLabel}
                  control={<Checkbox className={classes.inputCheckbox} />}
                  label="Create another AHJ record with same city name but different County"
                  onChange={handleCityCheckbox}
                  disabled={isEmpty(state) || readOnly}
                />
              )}
            </Box>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                className={classes.inputField}
                id="state"
                disableClearable={true}
                disabled={readOnly}
                options={statesList}
                value={state}
                onChange={(e, value) => handleBasicDetails(e, 'state', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!isEmpty(get(errorData, 'state'))}
                    helperText={get(errorData, 'state')}
                    label={<Label text={'State'} isRequired />}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                className={classes.inputField}
                id="city"
                disabled={isEmpty(state) || readOnly}
                value={city}
                options={!isEmpty(basicDetails['state']) ? cities : []}
                renderOption={(option) => <React.Fragment>{option.city_name}</React.Fragment>}
                getOptionLabel={(option) => (option.city_name ? option.city_name : '')}
                getOptionDisabled={(option) => option.is_city_ahj_exists}
                onChange={(e, value) => handleBasicDetails(e, 'city', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<Label text={'City'} />}
                    helperText={
                      !isEmpty(ahjCityCountyDetails.cities) &&
                      (city.township
                        ? !isEmpty(city)
                          ? `You have selected a township. ${
                              city.city_name
                            } township belongs to ${getCityCountyMapValue(city, 'county_name')} county`
                          : 'You have selected a township.'
                        : !isEmpty(city)
                        ? `${city.city_name} city belongs to ${getCityCountyMapValue(city, 'county_name')} county`
                        : undefined)
                    }
                  />
                )}
                onInputChange={(e, value) => handleClearCouties(e, value)}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <CountyField
                id="county"
                isRequired={countyRequired}
                labelText=""
                value={county}
                disabled={isEmpty(state) || readOnly}
                basicDetails={basicDetails}
                countyList={ahjCityCountyDetails.counties}
                onChange={(e, value) => handleBasicDetails(e, 'county', value)}
                errorData={errorData}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <CountyField
                id="county2"
                labelText="2"
                value={county2}
                disabled={isEmpty(city) || readOnly}
                basicDetails={basicDetails}
                countyList={ahjCityCountyDetails.counties}
                onChange={(e, value) => handleBasicDetails(e, 'county2', value)}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <CountyField
                id="county3"
                labelText="3"
                value={county3}
                disabled={isEmpty(city) || readOnly}
                basicDetails={basicDetails}
                countyList={ahjCityCountyDetails.counties}
                onChange={(e, value) => handleBasicDetails(e, 'county3', value)}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <CountyField
                id="county4"
                labelText="4"
                value={county4}
                disabled={isEmpty(city) || readOnly}
                basicDetails={basicDetails}
                countyList={ahjCityCountyDetails.counties}
                onChange={(e, value) => handleBasicDetails(e, 'county4', value)}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <CountyField
                id="county5"
                labelText="5"
                value={county5}
                disabled={isEmpty(city) || readOnly}
                basicDetails={basicDetails}
                countyList={ahjCityCountyDetails.counties}
                onChange={(e, value) => handleBasicDetails(e, 'county5', value)}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <Box className={classes.checkboxWrapper}>
                <FormControlLabel
                  checked={checkBoxValue}
                  className={classes.checkboxLabel}
                  control={<Checkbox className={classes.inputCheckbox} />}
                  label="EV Permit Details Needed"
                  onChange={(e) => handleBasicDetails(e, 'evPermitDetailsCheck')}
                  disabled={isEmpty(state) || readOnly}
                />
                <HelpTextIcon
                  message="When checked, ensure that all the fields under Permit Office and EV Permit Details sections should be filled completely."
                  position="top"
                  btnClassName={classes.button}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default BasicDetailsSectionOldUI;
