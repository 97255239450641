import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { assignReviewer, getPresingedURL, uploadToS3 } from '../../../../containers/Admin/actions';
import { DOC_TYPE } from '../../../../containers/Admin/RequestDetails/constants';
import { ACCEPTABLE_FILE_FORMAT } from '../../../../containers/Permitting/constants';
import FileUploadDialog from '../../FileUploadDialog';

let allSelectedFiles = [];
const ExternalRevision = ({
  showExternalRevisionPopup,
  handleShowExternalRevisionPopup,
  selectedApplication,
  reviewersList,
  getPresingedURL,
  uploadToS3,
  assignReviewer,
  emailId,
  firstName,
  lastName,
  getExternalRevisionDetails,
}) => {
  const [uploadingFiles, setUploadingFiles] = useState(false);

  const callS3AndUpload = (files, comment, versions, cbk) => {
    if (files.length === 0) {
      cbk(allSelectedFiles);
      return;
    }
    const file = files[0];
    const typeArray = file.name.split('.');
    const fileType = typeArray ? typeArray[typeArray.length - 1] : '';
    const fileTag = 'PERMIT_DOCS';
    const fileName = `${selectedApplication.permit_id}_${fileTag}_${moment().format('DDMMYYYY-hhmmss')}.${fileType}`;
    getPresingedURL({
      file_name: fileName,
      http_method: 'PUT',
      successCb: (presignedUrl) => {
        uploadToS3({
          fName: fileName,
          fileObj: file,
          preSignedS3Url: presignedUrl,
          successCbS3: () => {
            allSelectedFiles.push({
              file_name: fileName,
              original_file_name: file.name,
              file_size: file.size / 1024 / 1024,
              loading: false,
              comment: comment,
              doc_type: DOC_TYPE.EXTERNAL_REVISION_DOC,
              versions: versions,
            });
            callS3AndUpload(files.slice(1, files.length), comment, versions, cbk);
          },
        });
      },
    });
  };

  const fireUpload = (files, comment, externalKickbackDetails, designerEmail, reviewerEmail) => {
    setUploadingFiles(true);
    const versions = [selectedApplication?.version?.current_version, 'Internal'];
    callS3AndUpload(files, comment, versions, () => {
      externalKickbackDetails.permit_id = selectedApplication?.permit_id;
      externalKickbackDetails.files = allSelectedFiles;
      externalKickbackDetails.version = selectedApplication?.version?.current_version;
      assignReviewer({
        permitId: selectedApplication?.permit_id,
        designerEmail: designerEmail,
        reviewerEmail: reviewerEmail,
        details: externalKickbackDetails,
        successCbk: () => window.location.reload(),
      });
    });
  };

  return (
    <Fragment>
      <FileUploadDialog
        fileDialogInfo={{ title: 'Revision Notes', revisionDialog: true, buttonText: 'Submit and Assign to Designer' }}
        fireUpload={fireUpload}
        open={showExternalRevisionPopup}
        setOpen={handleShowExternalRevisionPopup}
        acceptableFileFormat={ACCEPTABLE_FILE_FORMAT}
        appDetails={selectedApplication}
        fileUploadInProgress={uploadingFiles}
        reviewersList={reviewersList}
        emailId={emailId}
        firstName={firstName}
        lastName={lastName}
        getExternalRevisionDetails={getExternalRevisionDetails}
        getPresingedURL={getPresingedURL}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  emailId: state.appReducer.emailId,
  firstName: state.appReducer.firstName,
  lastName: state.appReducer.lastName,
});

const mapDispatchToProps = (dispatch) => ({
  getPresingedURL: (payload) => dispatch(getPresingedURL(payload)),
  uploadToS3: (payload) => dispatch(uploadToS3(payload)),
  assignReviewer: (payload) => dispatch(assignReviewer(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalRevision);
