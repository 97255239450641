import {
  GET_ALL_MODE_MAPPINGS_REQUEST,
  GET_ALL_MODE_MAPPINGS_SUCCESS,
  GET_ALL_MODE_MAPPINGS_FAILURE,
  CREATE_MODE_MAPPING_REQUEST,
  CREATE_MODE_MAPPING_SUCCESS,
  CREATE_MODE_MAPPING_FAILURE,
  GET_MODE_MAPPINGS_REQUEST,
  GET_MODE_MAPPINGS_SUCCESS,
  GET_MODE_MAPPINGS_FAILURE,
  GET_ALL_STORAGE_CONFIGURATIONS_REQUEST,
  GET_ALL_STORAGE_CONFIGURATIONS_SUCCESS,
  GET_ALL_STORAGE_CONFIGURATIONS_FAILURE,
  GET_ALL_BATTERY_BACKUPS_REQUEST,
  GET_ALL_BATTERY_BACKUPS_SUCCESS,
  GET_ALL_BATTERY_BACKUPS_FAILURE,
  DELETE_MODE_MAPPINGS_REQUEST,
  DELETE_MODE_MAPPINGS_SUCCESS,
  DELETE_MODE_MAPPINGS_FAILURE,
  DELETE_STORAGE_CONFIGURATION_REQUEST,
  DELETE_STORAGE_CONFIGURATION_SUCCESS,
  DELETE_STORAGE_CONFIGURATION_FAILURE
} from '../constants';

const initialState = {
  error: '',
  currentlySending: false,
  totalElements: 0,
  message: '',
  messageTitle: '',
  messageType: '',
  modeMapping: {},
  modesList: [],
  storageConfigurationList: [],
  batteryBackupList: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_MODE_MAPPINGS_REQUEST:
      return {
        ...state,
        getAllModeInProgress: true,
        messageType: '',
        message: '',
      };
    case GET_ALL_MODE_MAPPINGS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        getAllModeInProgress: false,
        modesList: action.modesList,
      };
    case GET_ALL_MODE_MAPPINGS_FAILURE:
      return {
        ...state,
        getAllModeInProgress: false,
        messageType: 'error',
        message: action.message,
      };
    case CREATE_MODE_MAPPING_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case CREATE_MODE_MAPPING_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
        modeMapping: action.modeMapping,
      };
    case CREATE_MODE_MAPPING_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_MODE_MAPPINGS_REQUEST:
      return {
        ...state,
        currentlySending: false,
        messageType: '',
        message: '',
      };
    case GET_MODE_MAPPINGS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        modeMapping: action.modeMapping,
      };
    case GET_MODE_MAPPINGS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_ALL_STORAGE_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        getAllStorageConfigurationInProgress: true,
        messageType: '',
        message: ''
      };
    case GET_ALL_STORAGE_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        pagingData: action.pagingData,
        getAllStorageConfigurationInProgress: false,
        storageConfigurationList: action.storageConfigurationList,
        storageConfigurationTotalElements: action.totalElements || 0
      };
    case GET_ALL_STORAGE_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        getAllStorageConfigurationInProgress: false,
        messageType: 'error',
        message: action.message
      };
    case GET_ALL_BATTERY_BACKUPS_REQUEST:
      return {
        ...state,
        getAllBatteryBackupInProgress: true,
        messageType: '',
        message: ''
      };
    case GET_ALL_BATTERY_BACKUPS_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        pagingData: action.pagingData,
        getAllBatteryBackupInProgress: false,
        batteryBackupList: action.batteryBackupList,
        batteryBackupTotalElements: action.totalElements || 0
      };
    case GET_ALL_BATTERY_BACKUPS_FAILURE:
      return {
        ...state,
        getAllBatteryBackupInProgress: false,
        messageType: 'error',
        message: action.message
      };
    case DELETE_MODE_MAPPINGS_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case DELETE_MODE_MAPPINGS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
      };
    case DELETE_MODE_MAPPINGS_FAILURE:
      return {
        ...state,
        currentlySending: false,
      };
    case DELETE_STORAGE_CONFIGURATION_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case DELETE_STORAGE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
      };
    case DELETE_STORAGE_CONFIGURATION_FAILURE:
      return {
        ...state,
        currentlySending: false,
      };
    default:
      return state;
  }
}

export default reducer;
