import { Box, IconButton, Link, makeStyles, Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import OutlinedButton from '../../../common/OutlinedButton';
import SnakBar from '../../../SnakBar';

const useStyles = makeStyles((theme) => ({
  linkList: {
    paddingBottom: theme.spacing(3),
  },
  urlText: {
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: '#524C4C',
    opacity: '1',
    paddingBottom: '12px',
  },
  urlBoxWrapper: {
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    border: '1px solid #D3D3D3',
    opacity: 1,
    width: '73%',
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    overflowWrap: 'anywhere',
  },
  link: {
    fontSize: '14px',
    letterSpacing: '-0.14px',
    color: '#4B4B4B',
    opacity: 1,
  },
  button: {
    position: 'relative',
    bottom: theme.spacing(0.45)
  }
}));

const Links = ({ appDetails }) => {
  const classes = useStyles();
  const [ showCopyMessage, setShowCopyMessage ] = useState(false);
  const [ filesUrl, setFilesUrl ] = useState([]);

  useEffect(() => {
    if (showCopyMessage) {
      setTimeout(() => {
        setShowCopyMessage(false);
      }, 3000);
    }
  }, [ showCopyMessage ]);

  useEffect(() => {
    if (!isEmpty(appDetails)) {
      let urls = [];
      if (get(appDetails, 'material_details.document_link', '')) {
        urls.push({ name: 'Documents Link', value: get(appDetails, 'material_details.document_link', '')});
      }
      if (get(appDetails, 'material_details.photo_link', '')) {
        urls.push({ name: 'Photos Link', value: get(appDetails, 'material_details.photo_link', '')});
      }
      if (get(appDetails, 'attic_images_link', '')) {
        urls.push({ name: 'Attic Images Link', value: get(appDetails, 'attic_images_link', '')});
      }
      setFilesUrl(urls);
    }
  }, [ appDetails ]);
  return (
    <div>
    {filesUrl.map((url, idx) => {
      return (
        <Box key={idx} className={classes.linkList}>
          <Typography className={classes.urlText}>{url.name}</Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" className={classes.urlBoxWrapper}>
              <IconButton><Link /></IconButton>
              <Typography className={classes.link}>
                {url.value}
              </Typography>
            </Box>
            <Box className={classes.button}>
              <OutlinedButton
                text={'Copy'}
                smaller={true}
                handleClick={() => {
                  navigator.clipboard.writeText(url.value);
                  setShowCopyMessage(true);
                }}
              />
            </Box>
          </Box>
        </Box>
      );
    })}
    {showCopyMessage && <SnakBar message="Copied" severity="success" />}
    </div>
  )
}

export default Links;
