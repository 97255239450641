import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Paper, StepConnector } from '@material-ui/core';
import queryString from 'query-string';
import { submit } from 'redux-form';
import { isEqual, get } from '../../../utils/lodash';
import history from '../../../routes/history';
import MaterialDetails from '../MaterialDetails';
import RequiredServices from '../RequiredServices';
import ProjectDetails from '../ProjectDetails';
import { getProjectDetails, setIsSaveandClose, setActiveStep, saveDraftApplication, toOrderStamps } from '../action';
import { PAYMENT_METHOD, STEPS } from '../constants';
import ConfirmationDialog from '../../../components/Permitting/ConfirmationDialog';
import clsx from 'clsx';

const styles = (theme, props) => ({
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepperContent: {
    minHeight: 500,
  },
  stepperBody: {
    minHeight: 'inherit',
  },
  stepperIcon: {
    color: (props) => (props.paymentStatus ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.38)'),
  },
  stepperIconActive: {
    color: theme.palette.primary.main,
  },
  stepperIconCompleted: {
    color: theme.palette.primary.main,
  },
  stepConnectorLine: {
    borderTopStyle: 'dotted',
    borderColor: theme.palette.primary.main,
  },
  stepConnectorLineDisabled: {
    borderTopStyle: 'dotted',
    borderColor: 'rgba(0, 0, 0, 0.38)',
  },
  stepper: {
    backgroundColor: '#FBFBFB',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  disableClick: {
    pointerEvents: 'none',
  },
  stepperIconDisabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
});

class index extends Component {
  state = {
    activeStep: 0,
    steps: STEPS.map((step) => step.name),
    isSubmit: false,
    isSave: false,
    isSaveAndClose: false,
    openConfirmationDialog: false,
  };
  statedUpdated = false;

  componentDidMount() {
    this.handlePermitData(this.props);
    const { sourceId, step_completed } = this.props;
  }

  setActiveStep = (activeStep) => {
    if (activeStep < STEPS.length) {
      this.setState({ activeStep });
      this.props.setActiveStep(activeStep);
    }
  };

  handleReset = () => {
    window.location.reload();
  };

  handleBack = (goToApplicationDetails = false) => {
    if (this.state.activeStep === 0 || goToApplicationDetails) {
      this.props.toOrderStamps(false);
      if (this.props.enableSubmit) {
        this.setState({ openConfirmationDialog: true });
      } else {
        this.props.viewPermitApplication(false, true);
      }
    } else {
      this.setActiveStep(this.state.activeStep - 1, this.handlePermitData(this.props));
    }
  };

  handlePermitData(props) {
    const { activeStep } = this.state;
    const { permitId } = props;
    if (activeStep === 0) {
      if (permitId && permitId !== 'new') props.getProjectDetails({ permitId });
    }
  }

  handleNext = (isSave, isSubmit) => {
    this.setState({ isSave, isSubmit }, () => {
      if (this.state.activeStep === 0) {
        this.props.submitForm('ProjectDetailsForm');
      } else {
        this.state.activeStep < this.state.steps.length - 1 && this.setActiveStep(this.state.activeStep + 1);
      }
    });
  };

  /**
   * To handle Physical Stamp Inputs
   * @param {boolean} projectDetails ProjectDetails of the application
   * @param {boolean} materialDetails MaterialDetails of the application
   */
  handleSubmit = (projectDetails, materialDetails, requiredServices, cbk, inOrderStamps) => {
    this.props.saveDraftApplication({
      draftApplication: {
        project_details: projectDetails,
        material_details: materialDetails,
        services_details: requiredServices,
        in_order_stamps: inOrderStamps,
      },
      permitId: this.props.permitId,
      successCbk: () => {
        if (cbk) {
          cbk();
        } else {
          window.location.reload();
        }
      },
    });
  };

  handleSaveandClose = async () => {
    this.setState({ isSave: true, isSaveAndClose: true }, () => {
      this.props.setIsSaveandClose(true);
      if (this.state.activeStep === 0) {
        this.props.submitForm('ProjectDetailsForm');
      }
    });
  };

  jumpToPage = (pageNumber) => {
    this.setActiveStep(pageNumber);
  };

  getStepContent = (stepIndex) => {
    const { steps, activeStep } = this.state;
    const { search } = window.location;
    const { origin } = queryString.parse(search);
    const props = {
      activeStep,
      steps,
      handleNext: this.handleNext,
      handleBack: this.handleBack,
      handleSubmit: this.handleSubmit,
      handleSaveandClose: this.handleSaveandClose,
      jumpToPage: this.jumpToPage,
      isMobile: this.props.isMobile,
      viewPermitApplication: this.props.viewPermitApplication,
      isSmallerThanLargeScreen: this.props.isSmallerThanLargeScreen,
      permitIdProp: this.props.permitId,
      siteId: this.props.sourceId || this.props.siteId,
      origin: origin,
      isSubmit: this.state.isSubmit,
      isSave: this.state.isSave,
      isSaveAndClose: this.state.isSaveAndClose,
      handleCustomerNameChange: this.props.handleCustomerNameChange,
    };
    switch (stepIndex) {
      case 0:
        return <ProjectDetails {...props} />;
      case 1:
      case 2:
      case 3:
        return <MaterialDetails {...props} />;
      case 4:
        return <RequiredServices {...props} />;
      default:
        return 'Unknown stepIndex';
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { search } = window.location;
    const { origin } = queryString.parse(search);
    const { breadcrumbPath, searchParam = '' } = this.props;
    if (
      this.props.isSaveandClose &&
      this.state.activeStep === 0 &&
      !isEqual(nextProps.updated_at, this.props.updated_at)
    ) {
      if (origin === 'iframe') {
        window.top.location.reload();
      } else {
        window.location = `/manager/${breadcrumbPath}/permitting-assistance${searchParam}`;
      }
    }
    if (nextProps.permitId && !isEqual(nextProps.permitId, this.props.permitId)) this.handlePermitData(nextProps);
  }

  componentDidUpdate(prevProps) {
    const {
      permitId,
      defaultPermitId,
      isSaveandClose,
      sourceId,
      breadcrumbPath,
      searchParam = '',
      applicationDetail,
    } = this.props;
    const { search } = window.location;
    const { origin } = queryString.parse(search);
    if (isSaveandClose && this.state.activeStep !== 0) {
      if (origin === 'iframe') {
        window.top.location.reload();
      } else {
        window.location = `/manager/${breadcrumbPath}/permitting-assistance${searchParam}`;
      }
    } else {
      if (permitId !== defaultPermitId && prevProps.defaultPermitId !== defaultPermitId) {
        const forwardPathname = origin ? `${defaultPermitId}?origin=${origin}` : defaultPermitId;
        //if new/ is in the url remove the / after new
        //TODO: Need to check from where the / is coming in the url. Need to check enlm_ui_common code once.
        const loc = history.location;
        const pathName = loc.pathname;
        if (pathName.includes('new/')) {
          loc.pathname = pathName.replace('new/', 'new');
        }
        history.replace(loc);
        history.push(forwardPathname);
      }
    }
  }

  render() {
    const { classes, paymentStatus, step_completed, disabled } = this.props;
    const { steps, activeStep } = this.state;
    return (
      <div className={classes.root}>
        <div ref={this.props.reference} style={{ display: 'none' }} onClick={() => this.jumpToPage(4)} />
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={clsx(classes.stepper, disabled && classes.disableClick)}
          connector={
            <StepConnector
              classes={{ line: disabled ? classes.stepConnectorLineDisabled : classes.stepConnectorLine }}
            />
          }
        >
          {steps.map((label, index) => (
            <Step key={label} completed={!paymentStatus && index + 1 <= step_completed}>
              <StepLabel
                className={classes.pointerCursor}
                disabled={!paymentStatus && index + 1 > step_completed}
                onClick={() => {
                  if (paymentStatus || index + 1 <= step_completed) {
                    this.jumpToPage(index);
                  }
                }}
                StepIconProps={{
                  classes: {
                    root: disabled ? classes.stepperIconDisabled : classes.stepperIcon,
                    active: classes.stepperIconActive,
                    completed: classes.stepperIconCompleted,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <div className={classes.stepperContent}>
            <Paper elevation={3} className={classes.stepperBody}>
              {this.getStepContent(activeStep)}
            </Paper>
          </div>
        </div>
        <ConfirmationDialog
          open={this.state.openConfirmationDialog}
          handleClose={() => this.setState({ openConfirmationDialog: false })}
          handleProceed={() => this.props.viewPermitApplication(false, true)}
          withTitle={false}
          withButtons={true}
          withLink={false}
          dialogText={`Are you sure you want to discard the changes ?`}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  submitForm: (formName) => dispatch(submit(formName)),
  getProjectDetails: (payload) => dispatch(getProjectDetails(payload)),
  setIsSaveandClose: (payload) => dispatch(setIsSaveandClose(payload)),
  setActiveStep: (payload) => dispatch(setActiveStep(payload)),
  saveDraftApplication: (payload) => dispatch(saveDraftApplication(payload)),
  toOrderStamps: (payload) => dispatch(toOrderStamps(payload)),
});

const mapStateToProps = (state) => ({
  step_completed: state.permitReducer.step_completed,
  updated_at: state.permitReducer.updated_at,
  defaultPermitId: state.permitReducer.permitId,
  paymentStatus: state.permitReducer.paymentStatus,
  isSaveandClose: state.permitReducer.isSaveandClose,
  applicationDetail: state.permitReducer.applicationDetail,
  siteId: state.permitReducer.siteId,
  enableSubmit: state.permitReducer.enableSubmit,
  inOrderStamps: state.permitReducer.inOrderStamps,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
