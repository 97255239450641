import React, { Fragment } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Grid } from '@material-ui/core';
import { get, isEmpty, isEqual, split } from 'lodash';
import EquipmentList from '../equipmentList';
import { ELECTRICAL_DETAILS } from '../constants';
import { DisplayGrid } from '../../../common/DisplayGrid';
import SubPanelList from '../subPanelList';
import { TagLabel } from '../../../common/TagLabel';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    fontSize: theme.spacing(1.75)
  },
  subFontBold: {
    display: 'flex',
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    fontSize: theme.spacing(1.75)
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px',
    height: '40px',
    cursor: 'unset'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray'
  },
  dataSubHeader: {
    fontSize: '12px',
    color: 'grey'
  },
  updatedLabelWrapper: {
    pointerEvents: 'none',
    display: 'flex',
    maxWidth: 'initial'
  },
  padding4: {
    paddingTop: theme.spacing(3.125),
    paddingBottom: theme.spacing(0.625)
  }
});

export class ElectricalDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  splitOtherValues(value) {
    let otherValue = !isEmpty(value) && value.startsWith('Other-') ? split(value, '-', 2)[1] : value;
    return otherValue;
  }

  getModifiedTag = key => {
    const { applicationDetail, originalApplicationDetails } = this.props;
    const compareKey = 'material_details.electrical_properties.existing_generator';
    if (key !== compareKey) {
      if (get(applicationDetail, compareKey) === 'Yes' && get(originalApplicationDetails, compareKey) !== 'Yes') {
        return <TagLabel add applicationDetail={applicationDetail} />;
      } else {
        if (!isEqual(get(applicationDetail, key), get(originalApplicationDetails, key))) {
          return <TagLabel edit applicationDetail={applicationDetail} />;
        }
      }
    }
    if (!isEqual(get(applicationDetail, key), get(originalApplicationDetails, key))) {
      return <TagLabel edit applicationDetail={applicationDetail} />;
    }
  };
  getDeletedtag() {
    const { originalApplicationDetails, applicationDetail } = this.props;
    let combinedArray = [];
    let combinedArrayOrg = [];
    let id = 'material_details.electrical_properties.subpanels';
    let exsitingId = 'material_details.electrical_properties.subpanelExisting';

    let subpanelExistingArrayOrg = get(originalApplicationDetails, exsitingId);
    let subpanelArrayOrg = get(originalApplicationDetails, id) || [];
    let subpanelExistingArray = get(applicationDetail, exsitingId);
    let subpanelArray = get(applicationDetail, id) || [];

    if(get(subpanelExistingArray,"id")) {
      combinedArray.push(subpanelExistingArray)
    }
    if(subpanelArray.length > 0){
      for(var i=0;i<subpanelArray.length;i++){
        combinedArray.push(subpanelArray[i]);
      }
    }

    if(get(subpanelExistingArrayOrg,"id")) {
      combinedArrayOrg.push(subpanelExistingArrayOrg)
    }
    if(subpanelArrayOrg.length > 0){
      for(let i=0;i<subpanelArrayOrg.length;i++){
        combinedArrayOrg.push(subpanelArrayOrg[i]);
      }
    }

    let deletedValue = 0;

    if (isEmpty(combinedArray) && !isEmpty(combinedArrayOrg)) {
      return <TagLabel edit applicationDetail={applicationDetail} />;
    } else {
        for(let o=0;o<combinedArray.length;o++){
          let combinedArrayFinal = combinedArrayOrg.filter(s => s.id === combinedArray[o].id);
          if (isEmpty(combinedArrayFinal)) {
            deletedValue += 1;
          }
        } 
    }
    if (deletedValue > 0) {
      return <TagLabel edit applicationDetail={applicationDetail} />;
    }
  }
  render() {
    const {
      classes,
      applicationDetail,
      stateApplicationDetails,
      handleUpdated,
      originalApplicationDetails
    } = this.props;
    return (
      <Fragment>
        {!isEmpty(applicationDetail.material_details.equipment_location) ? (
          <EquipmentList
            arrayList={applicationDetail.material_details.equipment_location || []}
            appDetails={applicationDetail}
            originalApplicationDetails={originalApplicationDetails}
            title="Equipment Location"
          />
        ) : null}
        <Grid container direction="row" spacing={2} className={classes.padding4}>
          <Grid item xs={12} md={3}>
            <Typography className={classes.fontBold}>Generator Details</Typography>
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
              Existing Generator{this.getModifiedTag('material_details.electrical_properties.existing_generator')}
            </Typography>
            <Typography className={classes.fontBold}>
              {get(applicationDetail, 'material_details.electrical_properties.existing_generator') || '--'}
            </Typography>
          </Grid>
          {get(applicationDetail, 'material_details.electrical_properties.existing_generator') === 'Yes' ? (
            <Fragment>
              <Grid item xs={12} md={4}>
                <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
                  Generator Connection{this.getModifiedTag(
                    'material_details.electrical_properties.generator_connection'
                  )}
                </Typography>
                <Typography className={classes.fontBold}>
                  {this.splitOtherValues(
                    applicationDetail.material_details.electrical_properties.generator_connection
                  ) || '--'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
                  Type{this.getModifiedTag('material_details.electrical_properties.generator_type')}
                </Typography>
                <Typography className={classes.fontBold}>
                  {this.splitOtherValues(applicationDetail.material_details.electrical_properties.generator_type) ||
                    '--'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
                  Generator Manufacturer{this.getModifiedTag(
                    'material_details.electrical_properties.generator_manufacturer'
                  )}
                </Typography>
                <Typography className={classes.fontBold}>
                  {this.splitOtherValues(
                    applicationDetail.material_details.electrical_properties.generator_manufacturer
                  ) || '--'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
                  Generator Model{this.getModifiedTag('material_details.electrical_properties.generator_model')}
                </Typography>
                <Typography className={classes.fontBold}>
                  {this.splitOtherValues(applicationDetail.material_details.electrical_properties.generator_model) ||
                    '--'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography component="div" className={clsx(classes.dataSubHeader, classes.updatedLabelWrapper)}>
                  Generator Quantity{this.getModifiedTag('material_details.electrical_properties.generator_quantity')}
                </Typography>
                <Typography className={classes.fontBold}>
                  {this.splitOtherValues(applicationDetail.material_details.electrical_properties.generator_quantity) ||
                    '--'}
                </Typography>
              </Grid>
            </Fragment>
          ) : null}
        </Grid>
        <br />
        <br />
        <Grid container direction="row" spacing={2} className={classes.padding4}>
          <Grid item xs={12} md={3}>
            <Typography className={classes.fontBold}>Electrical Properties</Typography>
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          {ELECTRICAL_DETAILS.map((Item, index) => {
            return (
              <DisplayGrid
                appDetails={applicationDetail}
                item={Item}
                originalApplicationDetails={originalApplicationDetails}
                handleUpdated={handleUpdated}
                stateApplicationDetails={stateApplicationDetails}
              />
            );
          })}
        </Grid>

        {!isEmpty(applicationDetail.material_details.electrical_properties.subpanels) ||
        (get(applicationDetail, 'project_details.home_backup_option') === 'partial' &&
          get(applicationDetail, 'material_details.electrical_properties.backupExistingSubpanel') === true) ? (
          <Grid container direction="row" spacing={2} className={classes.padding4}>
            <Grid item xs={12} md={6}>
              <Typography className={classes.subFontBold}>Sub Panels {this.getDeletedtag()}</Typography>
            </Grid>
          </Grid>
        ) : null}
        {get(applicationDetail, 'project_details.home_backup_option') === 'partial' &&
        get(applicationDetail, 'material_details.electrical_properties.backupExistingSubpanel') === true ? (
          <Fragment>
            {get(applicationDetail, 'material_details.electrical_properties.subpanelExisting') ? (
              <SubPanelList
                arrayList={applicationDetail.material_details.electrical_properties.subpanelExisting}
                isArray={false}
                applicationDetail={applicationDetail}
                originalApplicationDetails={originalApplicationDetails}
                stateApplicationDetails={stateApplicationDetails}
              />
            ) : null}
          </Fragment>
        ) : null}

        {!isEmpty(applicationDetail.material_details.electrical_properties.subpanels) ? (
          <SubPanelList
            applicationDetail={applicationDetail}
            arrayList={applicationDetail.material_details.electrical_properties.subpanels}
            isArray={true}
            originalApplicationDetails={originalApplicationDetails}
            stateApplicationDetails={stateApplicationDetails}
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ElectricalDetailsView));
