import { withStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Loader from '../../../components/Loader';
import getConfig from '../../../config/env';
import clsx from 'clsx';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@material-ui/core';
import SGUserDialog from '../../../components/Admin/Automation/SGUserDialog';
import ApplicantDetails from '../../../components/Admin/RequestDetails/applicantDetails';
import ApplicationDocuments from '../../../components/Admin/RequestDetails/applicationDocuments';
import SendMessage from '../../../components/Admin/RequestDetails/sendMessage';
import StatusDetails from '../../../components/Admin/RequestDetails/statusDetails';
import Snackbar from '../../../components/SnakBar';

import { Edit, FindInPage } from '@material-ui/icons';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { get, isEqual, isEmpty, camelCase } from 'lodash';
import moment from 'moment';
import ApplicationView from '../../../components/Admin/applicationView';
import AssignPopup from '../../../components/Admin/StatusDashboard/AssignPopup';
import { ContainedButton } from '../../../components/common/ContainedButton';
import OutlinedButton from '../../../components/common/OutlinedButton';
import { APPLICATION_STATES, SOURCE } from '../../../utils/constants';
import settings from '../../../utils/settings';
import { downloadZipFromUrl } from '../../../utils/helper';
import { isFeatureEnabled } from '../../../containers/Permitting/helper';
import { FEATURE_FLAG_TYPE, FEATURE_FLAGS } from '../../../containers/Permitting/constants';
import {
  assignReviewer,
  cloneProject,
  createSolargrafAccount,
  downloadPermittingRequestDetails,
  downloadAttachments,
  getApplicationDetailsByVersion,
  getEditedVersion,
  getPermittingRequestDetails,
  getPresingedURL,
  getReviewersList,
  getSFDCdata,
  getSgProjectPermitMap,
  getSolargrafToken,
  getSolargrafUser,
  updateSgProjectId,
} from '../actions';
import { ADMINS } from '../constants';
import { getAssignedTo } from '../helper';
import { getExternalRevisionDetails, moveToUnderProgress } from './actions';
import { getSource, reloadToMoveToInProgress } from './helper';
import ExternalRevision from '../../../components/Admin/StatusDashboard/ExternalRevision';
import { STANDING_STEAM } from '../../../components/Permitting/Array/constants';
import { getABFeatures } from '../../Permitting/action';

// Utils helper
import { downloadFile, getFile, isSolargrafApplication, inchToMeter } from '../../../utils/helper';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  dataHeader: {
    color: '#3B86FF',
  },
  acceptButton: {
    color: theme.palette.primary.white,
  },
  topActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editIconBox: {
    '& .MuiIconButton-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  boxPadding: {
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
  },
  assignText: {
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    color: '#335977',
    opacity: 1,
    paddingTop: theme.spacing(1.875),
    paddingBottom: theme.spacing(2.125),
  },
});

export class RequestDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileDownloading: false,
      isPermitViewApplication: false,
      isEditPermitViewApplication: false,
      selectedVersion: '',
      selectedVersionId: '',
      versionsList: [],
      applicationDetails: {},
      originalApplicationDetails: {},
      openSgUserDialog: false,
      currentVersion: '',
      anchorEl: null,
      showExternalRevisionPopup: false,
      totalEnergyCapacity: 0,
      toggleImageView: 0,
    };
    this.config = getConfig();
    this.permitId = '';
    this.assignedTo = null;
    this.isExternalRevision = false;
  }
  componentDidMount() {
    const { request_id } = this.props.match.params;
    this.permitId = request_id;
    this.props.getABFeatures();
    this.props.getPermittingRequestDetails({
      application_id: request_id,
      successCbk: (application) => {
        const source = getSource(application);
        source === SOURCE.ENLIGHTEN_PERMIT && this.props.getSFDCdata({ permitId: request_id });
        this.getUpdatedVersionList();
        this.assignedTo = getAssignedTo(application)?.name;
        const version = application?.version?.current_version.includes('ER');
        this.isExternalRevision =
          version &&
          get(application, 'third_party_current_status.state_id') === APPLICATION_STATES.NEW_APPLICATION_RECEIVED.id;

        this.props.getReviewersList({ appType: source });
        this.calculateEnergyAndPower();
        this.setState({
          applicationDetails: application,
          selectedVersion: 'LATEST',
          selectedVersionId: 'LATEST',
          currentVersion: get(application, 'version.current_version'),
        });
      },
    });
  }

  calculateEnergyAndPower = () => {
    const { permitting_application } = this.props;
    let totalEnergyCapacity = 0;
    if (
      getSource(permitting_application) === SOURCE.SOLARGRAF &&
      !isEmpty(permitting_application.project_details.system_types) &&
      permitting_application.project_details.system_types.find((data) => data.name == 'Storage')?.value
    ) {
      if (
        permitting_application.material_details.storage_list &&
        permitting_application.material_details.storage_list.length > 0
      ) {
        permitting_application.material_details.storage_list.map((data) => {
          totalEnergyCapacity = totalEnergyCapacity + data.quantity * data.capacity;
        });
      }
    }
    totalEnergyCapacity = totalEnergyCapacity ? Math.round(totalEnergyCapacity * 100) / 100 : 0;
    this.setState({ totalEnergyCapacity });
  };

  getUpdatedVersionList() {
    const { request_id } = this.props.match.params;
    const { permitting_application } = this.props;
    this.props.getEditedVersion({
      application_id: request_id,
      successCb: () => {
        this.setState({
          versionsList: [...this.props.versionsList],
        });
        //Version list is all versions fetched for the application.
        this.props.versionsList.forEach((app) => {
          if (app.version == 'NEW' && app.moreInfoRequest === false && app.orderStamp === false) {
            this.props.getApplicationDetailsByVersion({
              application_id: app.id,
              type: 'ORIGINAL',
              successCb: (originalApplication) => {
                this.setState({
                  originalApplicationDetails: originalApplication,
                  selectedVersion: 'LATEST',
                  selectedVersionId: 'LATEST',
                });
              },
              failureCb: () => {
                this.setState({
                  originalApplicationDetails: permitting_application,
                  selectedVersion: 'LATEST',
                  selectedVersionId: 'LATEST',
                });
              },
            });
          }
        });
      },
      failureCb: () => {
        if (
          get(this.props.permitting_application, 'version.current_version') &&
          get(this.props.permitting_application, 'version.current_version').includes('ER') &&
          getSource(this.props.permitting_application) === SOURCE.ENLIGHTEN_PERMIT
        ) {
          this.setState({
            versionsList: [],
            currentVersion: 'NEW',
            originalApplicationDetails: permitting_application,
          });
        } else {
          this.setState({ versionsList: [], originalApplicationDetails: permitting_application });
        }
      },
    });
  }
  updateApplicationId() {
    this.setState({
      viewUpdateAppId: true,
      application_number: this.props.sgip_application.application_number || '',
    });
  }
  hideUpdateAppId() {
    this.setState({ viewUpdateAppId: false });
  }

  updateAppId() {
    this.setState({ viewUpdateAppId: false });
    this.props.updateAppId({
      app_id: this.props.sgip_application.application_id,
      app_number: this.state.application_number,
    });
  }

  viewPermitApplication = (viewApplication) => {
    const { request_id } = this.props.match.params;
    this.props.getPermittingRequestDetails({
      application_id: request_id,
      successCbk: (application) => {
        this.setState({
          // this can be used when we are comparing with previous Version
          // originalApplicationDetails:this.state.originalApplicationDetails,
          applicationDetails: application,
          isPermitViewApplication: viewApplication,
          selectedVersion: 'LATEST',
          selectedVersionId: 'LATEST',
        });
      },
    });
  };

  downloadAllFiles = (applicationId, versionId) => {
    const { permitting_application } = this.props;
    var customerName = get(permitting_application, 'project_details.customer_name');
    var dateTimeCreated, versionName;

    if (versionId === 'LATEST') {
      dateTimeCreated = moment(get(permitting_application, 'version.modified_date')).format('DD-MMM-YYYY_h-mm-ss');
      versionName = this.state.currentVersion;
      if (this.state.applicationDetails.version.order_stamp) {
        versionName = `${versionName}_stamps_only`;
      }
      if (this.state.applicationDetails.version.more_info_request) {
        versionName = `${versionName}_more_information`;
      }
    } else {
      this.state.versionsList.forEach((item) => {
        if (item.id == versionId) {
          dateTimeCreated = moment(item.date).format('DD-MMM-YYYY_h-mm-ss');
          versionName = item.version;
          if (item.orderStamp) {
            versionName = `${versionName}_stamps_only`;
          }
          if (item.moreInfoRequest) {
            versionName = `${versionName}_more_information`;
          }
        }
      });
    }
    var zipFileName = `${applicationId}_${customerName}_${versionName}_${dateTimeCreated}.zip`;
    this.props.downloadAttachments({
      application_id: applicationId,
      version: versionId,
      successCb: async (result) => {
        downloadZipFromUrl(result['result'], zipFileName);
      },
    });
  };

  editPermitApplication = (editApplication) => {
    this.setState({ isEditPermitViewApplication: editApplication });
  };

  setApplicationNumber(event) {
    this.setState({ application_number: event.target.value });
  }

  hideSuccessDialog() {
    const { request_id } = this.props.match.params;
    this.props.getPermittingRequestDetails({ application_id: request_id });
  }

  downloadDetails() {
    const { request_id } = this.props.match.params;
    const { permitting_application, emailId, getPreSignedUrl } = this.props;
    if (getSource(permitting_application) === SOURCE.SOLARGRAF) {
      this.setState({ fileDownloading: true });
      getFile(get(permitting_application, 'sg_attributes.file_name'), getPreSignedUrl).then((url) =>
        downloadFile(url, get(permitting_application, 'sg_attributes.file_name'), () => {
          this.setState({ fileDownloading: false });
          this.props.moveToUnderProgress({
            permitId: permitting_application.permit_id,
            successCbk: () => {
              this.setState({ fileDownloading: false });
              reloadToMoveToInProgress(permitting_application, emailId);
            },
          });
        })
      );
    } else {
      this.props.downloadPermittingRequestDetails({
        application_id: request_id,
        successCb: async (result) => {
          const url = await fetch(result.zipFileUrl)
            .then((response) => response.blob())
            .then((blob) => URL.createObjectURL(blob));
          const a = document.createElement('a');
          a.href = url;
          a.download = `${request_id}.zip`;
          a.click();
          reloadToMoveToInProgress(permitting_application, emailId);
        },
      });
    }
  }

  openSolargrafDesignTool() {
    this.props.getSolargrafToken({
      successCB: (tokenObj) => {
        this.props.getSgUser({
          successCB: (sgUser) => {
            //Do not allow user to proceed if his company_id is not solargraf_enphase_company_id.
            if (sgUser.company_id !== this.config.solargraf_enphase_company_id) {
              this.setState({ openSgUserDialog: true });
            } else {
              this.handleGetSolargrafTokenSuccessCallback(tokenObj.token);
            }
          },
          failureCB: () => {},
          token: tokenObj.token,
        });
      },
      failureCB: () => {
        this.handleGetSolargrafTokenFailureCallback();
      },
    });
  }

  openSolargraf(token, queryParams) {
    window.open(settings.getSolargrafUrl(`enlm/login/?solargrafToken=${token}` + queryParams), '_blank');
  }

  getEncodedValue(value) {
    if (value !== null) {
      return encodeURIComponent(value);
    } else {
      return '';
    }
  }

  parseRafterDetails = (panelArray) => {
    const type = panelArray['structural_type'];
    if (!isEqual(type, camelCase(STANDING_STEAM))) {
      const [width, height] = panelArray['structural_number'].split('x').map(inchToMeter);
      return {
        width,
        height,
        type,
        roofMaterial: camelCase(panelArray['roof_type']),
        maxSpan: inchToMeter(panelArray['max_rafter_span']),
        separation: inchToMeter(panelArray['structural_at']),
      };
    }
  };

  // Either get sg_project_id from permit or get it from solargraf or create a new project.
  // If we do not add projectId in queryParams, then it will automatically redirect to new project screen.
  handleGetSolargrafTokenSuccessCallback(token) {
    const application = this.props.permitting_application;
    const {
      project_details = {},
      material_details = {},
      permit_id = '',
      source = '',
      installer_name = '',
      installer_phone = '',
      installer_address = '',
      installer_logo = '',
      email = '',
    } = application;
    const {
      full_address = '',
      project_type = '',
      utility_provider = '',
      customer_name = '',
      installer_license_no = '',
      system_name = '',
      electrical_license_no = '',
      user_email = '',
      user_phone = '',
    } = project_details;
    const { installer_contact_phone } = material_details;
    const installerPhone = isSolargrafApplication(application) ? installer_phone : installer_contact_phone;

    let queryParams =
      `&screen=project&address=${this.getEncodedValue(full_address)}&permitId=${this.getEncodedValue(
        permit_id
      )}&source=${this.getEncodedValue(source)}` +
      `&projectType=${this.getEncodedValue(project_type.toLowerCase())}&utilityProvider=${this.getEncodedValue(
        utility_provider
      )}&name=${this.getEncodedValue(customer_name)}&email=${this.getEncodedValue(
        user_email
      )}&phone=${this.getEncodedValue(user_phone)}` +
      `&installerName=${this.getEncodedValue(installer_name)}&installerEmail=${this.getEncodedValue(
        email
      )}&installerPhone=${this.getEncodedValue(installerPhone)}&installerLogo=${this.getEncodedValue(
        installer_logo
      )}&installerAddress=${this.getEncodedValue(installer_address)}` +
      `&projectName=${this.getEncodedValue(system_name)}&installerLicenseNumber=${this.getEncodedValue(
        installer_license_no
      )}&electricalLicenseNumber=${this.getEncodedValue(electrical_license_no)}`;

    // Get projectId from application
    if (get(application, 'sg_attributes.sg_project_id')) {
      queryParams += `&projectId=${get(application, 'sg_attributes.sg_project_id')}`;
      this.openSolargraf(token, queryParams);
    } else {
      // Get projectId from EnlmPermits relation from solargraf
      this.props.getSgProjectPermitMap({
        permitId: permit_id,
        sgToken: token,
        successCb: (result) => {
          if (result.projectId) {
            queryParams += `&projectId=${result.projectId}`;

            // Update projectId in permit if it is fetched from solargraf
            this.props.updateSgProjectId({
              permitId: permit_id,
              sgProjectId: result.projectId,
            });
          }
          this.openSolargraf(token, queryParams);
        },
        failureCb: () => {
          // If it is a SG app, then clone the same project which installer has created to the current user SG account
          // only if its not permit only and 3D designs (design from new design tool) are clonnable (Cloning of 2D designs are not yet supported)
          if (
            isSolargrafApplication(application) &&
            get(application, 'permit_only_project') !== true &&
            get(application, 'sg_attributes.project_drawing_version') === '3D'
          ) {
            this.props.cloneProject({
              permitId: get(application, 'sg_attributes.permit_id'),
              applicationId: permit_id,
              source: source,
              installer: {
                installer_name: installer_name,
                installer_phone: installerPhone,
                installer_logo: installer_logo,
                installer_address: installer_address,
                installer_license_number: installer_license_no,
                electrical_license_number: electrical_license_no,
                installer_email: email,
              },
              rafterDetails: { ...this.parseRafterDetails(application.material_details.arrays[0]) },
              sgToken: token,
              successCb: (result) => {
                // Open the draw screen, once the project is cloned
                queryParams = `&projectId=${result.id}`;
                queryParams += `&proposalId=${result.proposalId}`;
                queryParams += `&screen=proposal_draw`;
                queryParams += `&planSetFlow=true`;
                this.openSolargraf(token, queryParams);
              },
              failureCb: () => this.openSolargraf(token, queryParams),
            });
          } else {
            this.openSolargraf(token, queryParams);
          }
        },
      });
    }
  }

  handleGetSolargrafTokenFailureCallback() {
    this.props.createSolargrafAccount({
      successCB: () => {
        this.props.getSolargrafToken({
          successCB: (tokenObj) => {
            this.handleGetSolargrafTokenSuccessCallback(tokenObj.token);
          },
        });
      },
    });
  }

  handleVersionChange(id) {
    let version = '';
    this.state.versionsList.forEach((app) => {
      if (app.id == id) {
        version = app.version;
      }
    });
    if (id == 'LATEST') {
      this.setState({
        selectedVersion: 'LATEST',
        selectedVersionId: 'LATEST',
        toggleImageView: this.state.toggleImageView + 1,
      });
      const { request_id } = this.props.match.params;
      this.props.getPermittingRequestDetails({
        application_id: request_id,
        successCbk: (application) => {
          this.setState({ applicationDetails: application });
        },
      });
    } else {
      this.setState({
        selectedVersion: version,
        selectedVersionId: id,
        toggleImageView: this.state.toggleImageView + 1,
      });
      const { request_id } = this.props.match.params;
      this.props.getApplicationDetailsByVersion({
        application_id: id,
        successCb: () => {},
        failureCb: () => {},
      });
    }
  }

  handlePopoverOpen = (event, app, isExternalRevision, assignedTo) => {
    this.setState({
      anchorEl: (!isExternalRevision || assignedTo) && event.currentTarget,
      selectedApplication: this.props.permitting_application,
      showExternalRevisionPopup: isExternalRevision && !assignedTo,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  assignButtonCall = (designerEmail, reviewerEmail) => {
    this.props.assignReviewer({
      permitId: this.permitId,
      designerEmail: designerEmail,
      reviewerEmail: reviewerEmail,
      successCbk: () => {
        this.props.getPermittingRequestDetails({
          application_id: this.permitId,
          successCbk: (application) => {
            this.assignedTo = getAssignedTo(application)?.name;
            this.setState({ applicationDetails: application });
          },
        });
      },
    });
    this.handlePopoverClose();
  };

  getlatestVersionName = (appDetails) => {
    let latestVersionName = `Installer_${this.state.currentVersion}`;
    if (appDetails.version.order_stamp) {
      latestVersionName = `${latestVersionName}_stamps_only`;
    }
    if (appDetails.version.more_info_request) {
      latestVersionName = `${latestVersionName}_more_information`;
    }
    latestVersionName = `${latestVersionName}${
      get(appDetails, 'version.modified_date')
        ? '_' + moment(get(appDetails, 'version.modified_date')).format('DD_MMM_YYYY_h:mm:ss')
        : null
    }`;

    return latestVersionName;
  };

  render() {
    const { classes, ...otherProps } = this.props;
    const { currentlySending, message, emailId, messageType, messageTitle, permitting_application, showMessageDialog } =
      otherProps;
    const hideDownloadCsvPdf = isFeatureEnabled(
      this.props.featureList,
      FEATURE_FLAGS.PERMIT_HIDE_DOWNLOAD_CSV_PDF,
      FEATURE_FLAG_TYPE.company
    );
    if (!this.state.isPermitViewApplication) {
      return (
        <div className={classes.root}>
          <ExternalRevision
            showExternalRevisionPopup={this.state.showExternalRevisionPopup}
            handleShowExternalRevisionPopup={() =>
              this.setState({ showExternalRevisionPopup: !this.state.showExternalRevisionPopup })
            }
            reviewersList={this.props.reviewersList}
            selectedApplication={this.props.permitting_application}
            getExternalRevisionDetails={this.props.getExternalRevisionDetails}
          />
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" component="h5" className={classes.header}>
                {'Permit Application Details'}
              </Typography>
            </Grid>
            <Grid item>
              <Box>
                {ADMINS.includes(this.props.permitServiceRole) && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.editIconBox}
                  >
                    <Typography className={clsx(!this.assignedTo && classes.boldContent, classes.fontTableSize)}>
                      Assigned to: {this.assignedTo || 'NA'}
                    </Typography>
                    <Box>
                      <IconButton
                        onClick={(e) =>
                          this.handlePopoverOpen(e, permitting_application, this.isExternalRevision, this.assignedTo)
                        }
                        className={classes.editIcon}
                      >
                        {this.isExternalRevision && !this.assignedTo ? (
                          <FindInPage fontSize="small" />
                        ) : (
                          <Edit fontSize="small" />
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                )}
                <Popover
                  id="assign-popover"
                  open={Boolean(this.state.anchorEl)}
                  elevation={1}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={this.handlePopoverClose}
                  disableRestoreFocus
                >
                  <AssignPopup
                    classes={classes}
                    handlePopoverClose={this.handlePopoverClose}
                    assignButtonCall={this.assignButtonCall}
                    reviewersList={this.props.reviewersList}
                    currentStateId={get(this.props.permitting_application, 'third_party_current_status.state_id', 0)}
                    emailId={this.props.emailId}
                    firstName={this.props.firstName}
                    lastName={this.props.lastName}
                    appDetails={this.props.permitting_application}
                  />
                </Popover>
              </Box>
            </Grid>
          </Grid>
          {isEqual(permitting_application, {}) || currentlySending === true || this.state.fileDownloading ? (
            <Loader loading={currentlySending || this.state.fileDownloading} />
          ) : (
            <Fragment>
              <Grid container direction="row">
                <Grid item xs={12} md={4}>
                  <Typography className={classes.fontBold}>
                    Application Id -{' '}
                    <span className={classes.dataHeader}>{get(permitting_application, 'permit_id') || 'NA'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={5} alignItems="center" className={classes.topActionButtons}>
                  {!hideDownloadCsvPdf && (
                    <>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => this.downloadDetails()}
                        style={{ fontWeight: 'bold' }}
                        className={classes.dataHeader}
                      >
                        Download Details
                      </Link>
                      &nbsp;&nbsp;
                      <SystemUpdateAltIcon style={{ fontSize: '20px' }} className={classes.dataHeader} />
                    </>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.config.enable_sg_design_flow && emailId.includes('@enphaseenergy.com') && (
                    <ContainedButton
                      handleClick={() => this.openSolargrafDesignTool()}
                      text="Create&nbsp;roof&nbsp;Layout"
                    />
                  )}
                  &nbsp;&nbsp;
                  <SGUserDialog open={this.state.openSgUserDialog} user={this.props.sgUser} />
                  <ContainedButton handleClick={() => this.viewPermitApplication(true)} text="View" />
                  &nbsp;&nbsp;
                </Grid>
              </Grid>
              <br />
              <ApplicantDetails
                appDetails={permitting_application}
                totalEnergyCapacity={this.state.totalEnergyCapacity}
              />
              <br />
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12} md={3}>
                  <StatusDetails appDetails={permitting_application} />
                </Grid>
                <Grid item xs={12} md={5}>
                  <ApplicationDocuments appDetails={permitting_application} />
                </Grid>
                {/* <Grid item xs={12} md={3}>
                <Notifcations appDetails={permitting_application} />
              </Grid> */}
                <Grid item xs={12} md={4}>
                  <SendMessage appDetails={permitting_application} {...otherProps} />
                </Grid>
              </Grid>
              <Dialog
                onClose={() => this.hideSuccessDialog()}
                aria-labelledby="customized-dialog-title"
                open={showMessageDialog}
                maxWidth={'md'}
                id="successMsg"
              >
                <DialogTitle id="customized-dialog-title" onClose={() => this.hideSuccessDialog()}>
                  {messageTitle}
                </DialogTitle>
                <DialogContent>
                  <Typography>{message}</Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.hideSuccessDialog()}
                    color="primary"
                    variant="contained"
                    className={classes.acceptButton}
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Fragment>
          )}
          {message && messageType && messageType === 'error' && <Snackbar severity={messageType} message={message} />}
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h5" className={classes.header}>
          {'Site Assessment Form'}
        </Typography>
        {isEqual(permitting_application, {}) || currentlySending === true || this.state.fileDownloading ? (
          <Loader loading={currentlySending || this.state.fileDownloading} />
        ) : (
          <Fragment>
            <Grid container direction="row">
              <Grid item xs={12} md={4}>
                <Typography className={classes.fontBold}>
                  Application Id -{' '}
                  <span className={classes.dataHeader}>{get(permitting_application, 'permit_id') || 'NA'}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} />
              <Grid item xs={12} md={5} alignItems="center" className={classes.topActionButtons}>
                <Select
                  id={'edit_form'}
                  value={this.state.selectedVersionId}
                  className={classes.solarSelect}
                  onChange={(e) => this.handleVersionChange(e.target.value)}
                >
                  <MenuItem value="LATEST">{this.getlatestVersionName(this.state.applicationDetails)}</MenuItem>
                  {this.state.versionsList.map((item, index) => {
                    let versionName = `Installer_${item.version}`;
                    if (item.orderStamp) {
                      versionName = `${versionName}_stamps_only`;
                    }
                    if (item.moreInfoRequest) {
                      versionName = `${versionName}_more_information`;
                    }
                    return (
                      <MenuItem value={item.id}>
                        {versionName}
                        {item.date ? '_' + moment(item.date).format('DD_MMM_YYYY_h:mm:ss') : null}
                      </MenuItem>
                    );
                  })}
                </Select>
                &nbsp;&nbsp;
                <OutlinedButton
                  size={'small'}
                  handleClick={() =>
                    this.downloadAllFiles(get(permitting_application, 'permit_id'), this.state.selectedVersionId)
                  }
                  text="Download All Images and Documents"
                  showDownloadButton
                />
                &nbsp;&nbsp;
                <ContainedButton size={'small'} handleClick={() => this.viewPermitApplication(false)} text="Go Back" />
                &nbsp;&nbsp;
              </Grid>
            </Grid>
            <br />
            <ApplicationView
              applicationDetail={permitting_application}
              isEdit={this.state.isEditPermitViewApplication}
              stateApplicationDetails={this.state.applicationDetails}
              originalApplicationDetails={this.state.originalApplicationDetails}
              version={this.state.selectedVersion}
              selectedVersionId={this.state.selectedVersionId}
              toggleImageView={this.state.toggleImageView}
            />
          </Fragment>
        )}

        {message && messageType && messageType === 'error' && <Snackbar severity={messageType} message={message} />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPermittingRequestDetails: (payload) => dispatch(getPermittingRequestDetails(payload)),
  getSolargrafToken: (payload) => dispatch(getSolargrafToken(payload)),
  createSolargrafAccount: (payload) => dispatch(createSolargrafAccount(payload)),
  downloadPermittingRequestDetails: (payload) => dispatch(downloadPermittingRequestDetails(payload)),
  downloadAttachments: (payload) => dispatch(downloadAttachments(payload)),
  getSFDCdata: (payload) => dispatch(getSFDCdata(payload)),
  getPreSignedUrl: (payload) => dispatch(getPresingedURL(payload)),
  moveToUnderProgress: (payload) => dispatch(moveToUnderProgress(payload)),
  getEditedVersion: (payload) => dispatch(getEditedVersion(payload)),
  getApplicationDetailsByVersion: (payload) => dispatch(getApplicationDetailsByVersion(payload)),
  updateSgProjectId: (payload) => dispatch(updateSgProjectId(payload)),
  getSgProjectPermitMap: (payload) => dispatch(getSgProjectPermitMap(payload)),
  getSgUser: (payload) => dispatch(getSolargrafUser(payload)),
  cloneProject: (payload) => dispatch(cloneProject(payload)),
  assignReviewer: (payload) => dispatch(assignReviewer(payload)),
  getReviewersList: (payload) => dispatch(getReviewersList(payload)),
  getExternalRevisionDetails: (payload) => dispatch(getExternalRevisionDetails(payload)),
  getABFeatures: (payload) => dispatch(getABFeatures(payload)),
});

const mapStateToProps = (state) => ({
  currentlySending: state.adminReducer.currentlySending,
  permitting_application: state.adminReducer.permitting_application,
  showMessageDialog: state.adminReducer.showMessageDialog,
  message: state.adminReducer.message,
  messageTitle: state.adminReducer.messageTitle,
  messageType: state.adminReducer.messageType,
  versionsList: state.adminReducer.versionsList,
  sgUser: state.adminReducer.sgUser,
  permitServiceRole: state.appReducer.permitServiceRole,
  reviewersList: state.adminReducer.reviewersList,
  emailId: state.appReducer.emailId,
  firstName: state.appReducer.firstName,
  lastName: state.appReducer.lastName,
  featureList: state.permitReducer.featureList,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RequestDetails));
