import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Snackbar,
  Typography
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import clsx from 'clsx';
import SystemUpdateAlt from '@material-ui/icons/SystemUpdateAlt';
import { clone, get, isEmpty, includes } from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { addDocs, addNotes, getAssignedTo, getIconPath } from '../../../../containers/Admin/helper';
import { APPLICATION_STATES } from '../../../../utils/constants';
import { downloadFile, getFile } from '../../../../utils/helper';

const useStyles = makeStyles((theme) => ({
  loaderSection: {
    textAlign: 'center',
    minHeight: '425px',
    maxHeight: '518px' 
  },
  listItem: {
    position: 'relative',
    padding: theme.spacing(1)
  },
  dateLabel: {
  },
  updateButton: {
    width: '100%',
    backgroundColor: '#00000061',
    color: '#ffffff',
    textTransform: 'none'
  },
  tag: {
    backgroundColor: '#f0f1f5',
    fontSize: 14,
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.2, 0.8)
  },
  primaryLabel: {
    fontSize: 14,
    overflow: 'hidden',
    width: '90%',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1
  },
  ListBoxItem: {
    maxWidth: '100%',
    flexGrow: 1
  },
  fileButton: {
    fontSize: 18
  },
  externalTag: {
    backgroundColor: theme.palette.primary.externalTag,
    color: theme.palette.primary.main
  },
  internalTag: {
    backgroundColor: theme.palette.primary.internalTag,
    color: theme.palette.primary.link
  }
}));

const CommentPopup = ({ open, handleClose, content }) => {
  return (
    <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={'md'}
        id="comment-popup"
      >
        <DialogContent>
          {content}
        </DialogContent>
      </Dialog>
  );
};

const DocumentLabel = ({ classes, label, tags }) => {
  const getColorClassByTag = (tag) => {
    return tag === 'Internal' ? classes.internalTag : tag === 'External' ? classes.externalTag : '';
  }
  return (
    <Fragment>
      <Typography className={classes.primaryLabel}>
        {label}
      </Typography>
      {tags && tags.map(tag => <Typography component="span" className={clsx(classes.tag, getColorClassByTag(tag))}>{tag}</Typography>)}
    </Fragment>
  )
};

const Documents = ({
  appDetails,
  documentType,
  downLoadError,
  downLoadErrorMsgs,
  getPresingedURL,
  pushErrorMessage,
  moveToUnderProgress,
  sDetails,
  emailId,
  handleEditInternalNotes,
  handleViewInternalNotes
}) => {
  const classes = useStyles();
  const [ appDocsLoading, setAppDocsLoading ] = useState(false);
  const [ appDocs, setAppDocs ] = useState([]);
  const [ fileDownloadInProgress, setFileDownloadInProgress ] = useState(false);
  const [ showCommentPopup, setShowCommentPopup ] = useState(false);
  const [ comment , setComment ] = useState('');

  useEffect(() => {
    if (!isEmpty(appDetails)) {
      if (documentType === 'installer') {
        getInstallerDocuments();
      } else if (documentType === 'admin') {
        getAdminDocuments();
      }
    }
  }, [ appDetails ]);

  const checkAndAddDocs = (key, allDocs) => {
    if (get(appDetails, key) && get(appDetails, key).length > 0) {
      addDocs(get(appDetails, key), allDocs, key);
    }
  };

  const checkAndAddNotes = (key, allDocs) => {
    const notes = get(appDetails, key);
    if (notes && notes.length > 0) {
      addNotes(notes, allDocs, key);
    }
  }

  const addSpecSheets = (arrays, allDocs, key) => {
    if (arrays.length > 0) {
      arrays.forEach(array => {
        if (array.spec_sheets && array.spec_sheets.length > 0) {
          addDocs(array.spec_sheets, allDocs, key);
        }
      });
    }
  }

  const getAdminDocuments = () => {
    setAppDocsLoading(true);
    let docs = [];

    checkAndAddDocs('admin_documents', docs);
    checkAndAddDocs('reference_permit_files', docs);
    checkAndAddDocs('permit_files', docs);
    checkAndAddNotes('internal_notes', docs);

    docs = docs.sort(function(a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    setAppDocs(docs);
    setAppDocsLoading(false);
  };

  const getInstallerDocuments = () => {
    setAppDocsLoading(true);
    let docs = [];

    checkAndAddDocs('project_details.documents', docs);
    checkAndAddDocs('material_details.documents', docs);
    checkAndAddDocs('material_details.photos', docs);
    checkAndAddDocs('installer_documents', docs);
    addSpecSheets(get(appDetails, 'material_details.arrays', []) || [], docs, 'material_details.arrays');
    addSpecSheets(get(appDetails, 'material_details.existing_arrays', []) || [], docs, 'material_details.existing_arrays');
    addSpecSheets(get(appDetails, 'material_details.inverters', []) || [], docs, 'material_details.inverters');
    addSpecSheets(get(appDetails, 'material_details.storage_list', []) || [], docs, 'material_details.storage_list');
    addSpecSheets(get(appDetails, 'material_details.other_materials', []) || [], docs, 'material_details.other_materials');

    docs = docs.sort(function(a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    setAppDocs(docs);
    setAppDocsLoading(false);
  };

  const downloadFiles = async (doc) => {
    const aDocs = clone(appDocs);
    let idxOfDownloadingDoc = 0;
    for (let i = 0; i < aDocs.length; i++) {
      if (aDocs[i].id === doc.id) {
        aDocs[i].downloadInProgress = true;
        idxOfDownloadingDoc = i;
      }
    }
    setAppDocs(aDocs);
    setFileDownloadInProgress(true);
    const fName = doc.file_name;
    const fUrl = await getFile(fName, getPresingedURL);
    if (fUrl) {
      downloadFile(fUrl, fName);
      const currentUser = getAssignedTo(appDetails);
      if (
        currentUser?.email === emailId &&
        (sDetails.currentStage === APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.id ||
        sDetails.currentStage === APPLICATION_STATES.PERMIT_DRAWINGS_COMPLETED.id)
      ) {
        moveToUnderProgress({
          permitId: get(appDetails, 'permit_id'),
          successCbk: () => window.location.reload()
        });
      }
    } else {
      pushErrorMessage(`Download Failed - ${fName}`);
    }
    aDocs[idxOfDownloadingDoc].downloadInProgress = false;
    setAppDocs(aDocs);
    setFileDownloadInProgress(false);
  }

  return (
    <div>
      {appDocsLoading === true ? (
        <div className={classes.loaderSection}>
          {' '}<br /><br /><br />
          <CircularProgress size={15} />
        </div>
      ) : appDocs.length > 0 ? (
        <Fragment>
          <List>
            {appDocs.map((doc, idx) => {
              return (
                <ListItem key={idx} title={doc.name} className={classes.listItem}>
                  <ListItemAvatar>
                    <Avatar src={getIconPath(doc)} variant="square" />
                  </ListItemAvatar>
                  <Box  className={classes.ListBoxItem}>
                    <ListItemText
                      primary={<DocumentLabel classes={classes} label={doc.name} tags={doc.versions} />}
                      secondaryTypographyProps={{ style: { fontSize: '12px' } }}
                    />
                    {doc.date && (
                      <ListItemText
                        className={classes.dateLabel}
                        secondary={`${doc.type === 'file' ? 'Uploaded ' : 'Added '}  ${doc.user_info ? 'by ' + doc.user_info.name : ''} on ${moment(doc.date).format('DD MMM YYYY')}`}
                        secondaryTypographyProps={{ style: { fontSize: '13px' } }}
                      />
                    )}
                  </Box>
                  { doc.type === 'file' &&
                    <ListItemSecondaryAction>
                      {doc.comment && (
                        <IconButton
                          edge="end"
                          aria-label="comment"
                          onClick={() => {
                            setComment(doc.comment);
                            setShowCommentPopup(true);
                          }}
                        >
                          <FileCopy className={classes.fileButton} />
                        </IconButton>
                      )}
                      <IconButton
                        edge="end"
                        aria-label="download"
                        onClick={() => downloadFiles(doc)}
                        disabled={doc.downloadInProgress || fileDownloadInProgress}
                      >
                        {doc.downloadInProgress === true ? (
                          <CircularProgress size={30} />
                        ) : (
                          <SystemUpdateAlt className={classes.fileButton} />
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  }
                  { doc.type === 'notes' &&
                    <ListItemSecondaryAction>
                      {doc.name && (
                        <IconButton
                          edge="end"
                          aria-label="comment"
                          onClick={() => {
                            emailId === doc.user_info.email ? handleEditInternalNotes(doc.name, doc.id) : handleViewInternalNotes(doc.name, doc.id);
                          }}
                        >
                          <FileCopy className={classes.fileButton} />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  }
                </ListItem>
              );
            })}
          </List>
          {downLoadError &&
            downLoadErrorMsgs.map((msg, idx) => {
              return <Snackbar key={idx} severity={'error'} message={msg} />;
            })}
        </Fragment>
      ) : (
        <div className={classes.loaderSection}>
          <br /><br /><br />
          <Typography variant="caption">** No Documents Available **</Typography>
        </div>
      )}
      <CommentPopup open={showCommentPopup} handleClose={() => setShowCommentPopup(false)} content={comment} />
    </div>
  );
}

export default Documents;
