import React from 'react';
import { Label } from '../../../../../../common/Label';
import { get, hasIn, isEmpty } from '../../../../../../../utils/lodash';
import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import CustomPanel from '../../../../../../common/PanelTemplateWithRadioButton';
import { SingleSelectDropdown } from '../../../../../../common/SingleSelectDropdown';
import { MODULE_COUNT_OPTIONS } from '../../../../../../../containers/Admin/constants';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
}));

const ElectricalDetailsSection = (props) => {
  const classes = useStyles();
  const {
    unit,
    errorData,
    handlePhysicalDetails,
    physicalDetails,
    sectionLabel,
    readOnly,
    getErrorText,
    getHelperText,
  } = props;
  const { weight = '', module_count_per_inverter = '' } = physicalDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel
        header={sectionLabel}
        radioDetails={{
          handleFunction: handlePhysicalDetails,
          value: unit,
        }}
      >
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'weight'}
                className={classes.inputField}
                label={
                  <Label
                    text={`Weight ${unit === 'imperial' ? '(lb)' : '(kg)'}`}
                    capitalizeLabelText={false}
                    isRequired
                  />
                }
                type="number"
                value={weight || ''}
                onChange={(e) => handlePhysicalDetails(e, 'weight')}
                error={!isEmpty(get(errorData, 'weight'))}
                helperText={get(errorData, 'weight')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Module Count Per Inverter'} isRequired />}
                dropDownOptions={MODULE_COUNT_OPTIONS}
                getErrorText={(fieldId) => hasIn(errorData, fieldId)}
                getHelperText={(fieldId) => get(errorData, fieldId)}
                handleField={handlePhysicalDetails}
                fieldId="module_count_per_inverter"
                fieldValue={module_count_per_inverter}
                readOnly={readOnly}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ElectricalDetailsSection;
