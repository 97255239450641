import React, { Component, createRef, Fragment } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withStyles, withTheme } from '@material-ui/core/styles';
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import { Grid, CircularProgress, Backdrop, Paper, Box, Typography, Badge, IconButton } from '@material-ui/core';
import PermitChats from '../Permitting/Chats';
import PageHeader from '../../components/common/PageHeader';
import PermitAlert from '../../components/Permitting/Alert';
import PermitStepper from './Stepper';
import {
  getUserSelectionData,
  getOtherUserSelectionData,
  getAllServices,
  getPermittingById,
  setPaymentStatus,
  getMessageList,
  getS3URL,
  putToS3,
  sendMessage,
  showHideLoader,
  getChatInfo,
  getReworkServices,
  getSelectedReworkServices,
  updateReworkServices,
  setIsSaveandClose,
  getPanelManufacturerList,
  getRackingMfs,
  getABFeatures,
  getMaterialDetails,
  getSelectedServices,
  getFeedback,
  getMostUsedValues,
  getAccountData,
  getProjectDetails,
  getArrayBuilderDetails,
  setV2SolarUI,
  setIsFromPackage,
  getCompanyInfo,
  getSiteDetails,
} from './action';
import { setEnlightenManagerToken } from '../Layouts/actions';
import { setToken, getToken, constructQueryString } from '../../utils/helper';
import Loader from '../../components/Loader';
import ApplicationStatus from '../Permitting/ApplicationStatus';
import { capitalize, get, includes, isEmpty } from '../../utils/lodash';
import ChatSvg from '../../components/Permitting/images/ChatSvg';
import Footer from '../../components/Footer';
import SuccessDialog from '../../components/Permitting/SuccessDialog';
import StatusDetails from '../../components/Permitting/ApplicationStatus/StatusDetails';
import HelpSection from '../../components/Permitting/HelpSection';
import { PERMIT_SERVICE_ROLES } from '../../utils/constants';
import settings from '../../utils/settings';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FBFBFB',
  },
  accountWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    gridColumnGap: theme.spacing(4.5),
    gridRowGap: theme.spacing(2.5),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  backdrop: {
    zIndex: 1301,
    color: theme.palette.primary.main,
  },
  statusNotificationWrapper: {
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(3),
    marginTop: 'auto',
  },
  stepFooterContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(3, 1.5, 12.5),
    marginTop: 'auto',
  },
  submittedFooter: {
    padding: theme.spacing(3, 1.5, 12.5),
  },
  editMaterialFooter: {
    padding: theme.spacing(3, 1.5, 25),
  },
  editFooter: {
    padding: theme.spacing(3, 1.5, 18),
  },
  privacyTerms: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    '& p, & a': {
      whiteSpace: 'nowrap',
    },
  },
  link: {
    color: '#F3731F !important',
    cursor: 'pointer !important',
    padding: `${theme.spacing(0, 0.5)} !important`,
  },
  copyRights: {
    fontSize: 12,
    color: '#686868',
  },
  footerLogo: {
    height: theme.spacing(3.5),
    width: theme.spacing(15.5),
    minHeight: theme.spacing(3.5),
    marginRight: theme.spacing(2.5),
  },
  chatLinkSection: {
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    padding: theme.spacing(1, 3),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  chatSection: {
    display: 'flex',
    alignItems: 'center',
  },
  chatLink: {
    marginLeft: theme.spacing(),
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  chatIcon: {
    cursor: 'pointer',
  },
  chatBadge: {
    marginLeft: theme.spacing(3),
    '& .MuiBadge-badge': {
      zIndex: 'auto',
      color: 'white',
    },
  },
  container: {
    padding: theme.spacing(1.5, 0),
  },
  headerPadding: {
    padding: theme.spacing(0, 2),
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  chatSvgWrapper: {
    marginRight: theme.spacing(3),
    backgroundColor: '#F37320',
    height: '50px',
    width: '50px',
    border: '1px solid #F37320 ',
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unreadMessageWrapper: {
    color: '#F37320',
    backgroundColor: 'white',
    border: '1px solid #F37320 ',
    borderRadius: 100,
    marginBottom: '40px',
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '-20px',
  },
  smallScreenFontSize: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px',
    },
  },
});

class index extends Component {
  stepperRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      permitId: '',
      customerName: '',
      sourceId: '',
      isMobile: window.innerWidth < this.props.theme.breakpoints.values.sm,
      isPermitViewApplication: false,
      isAddOnServicesClicked: false,
      isPaymentStatusChecked: false,
      isPermitChatButton: window.innerWidth < this.props.theme.breakpoints.values.lg,
      isSmallScreen: window.innerWidth < this.props.theme.breakpoints.values.md,
      permitChatDialog: false,
      fileDownloadInProgress: false,
      storePaymentFailure: 0, // 1 - normal, 2 - rework, 3 - storage
      storePaymentSuccess: 0,
    };
    this.breadcrumbPath = 'dashboard';
    this.forwarded = false;
    this.siteId = this.props.match.params.source_id || (this.props.location.state && this.props.location.state.siteId);
  }

  updateCustomerName() {
    if (!isEmpty(this.props.applicationDetail)) {
      const customerName =
        this.props.applicationDetail.project_details.customer_name || this.props.projectDetails.customer_name;
      this.setState({ customerName: customerName });
    } else if (!isEmpty(this.props.siteDetails)) {
      const customerName = this.props.siteDetails.first_name + ' ' + this.props.siteDetails.last_name;
      this.setState({ customerName: customerName });
    }
  }

  componentDidMount() {
    this.updateEnlightenManagerToken();
    const { permit_id: id } = this.props.match.params;
    this.setState({ permitId: id, sourceId: this.siteId });
    const { isFromPackage = '' } = this.props;
    this.props.getAccountData({
      successCbk: (companyId) => {
        // only installer can access the permit assistance portal
        // admin has the different portal
        if (this.props.permitServiceRole && PERMIT_SERVICE_ROLES.INSTALLER !== this.props.permitServiceRole) {
          window.location = settings.enlightenUrl('manager/dashboard');
        }
        this.props.getCompanyInfo(companyId);
      },
    });
    this.props.getUserSelectionData();
    this.props.getOtherUserSelectionData();
    this.props.getMostUsedValues();
    this.props.setIsSaveandClose(false);
    this.props.getPanelManufacturerList();
    this.props.getRackingMfs();
    this.props.getABFeatures();
    if (this.siteId) {
      this.props.getArrayBuilderDetails({ siteId: this.siteId });
      this.props.getSiteDetails(this.siteId);
    }
    if (id && id !== 'new') {
      this.getPermittingDetails(id);
      this.props.getAllServices(id);
      this.props.getReworkServices(id);
    }
    if (id === 'new') {
      this.setState({ isPaymentStatusChecked: true });
      this.props.setV2SolarUI(true);
    }
    this.props.setIsFromPackage(!!isFromPackage);
    const pathName = get(this.props, 'match.path', window.location.pathname) || window.location.pathname;
    this.breadcrumbPath = pathName && pathName.includes('account') ? 'account' : 'dashboard';
    window.addEventListener('resize', this.handleWindowSizeWindow);
    this.handlePaymentStatus();
    this.updateCustomerName();
  }

  handlePaymentStatus = () => {
    const params = get(this.props, 'history.location.search') || '';
    const { status, payment_for: paymentType } = queryString.parse(params);
    // showing error message/ dialog based on search params
    if (status === 'failure' || status === 'success') {
      const storePaymentStateVariable = `storePayment${capitalize(status)}`;
      if (isEmpty(paymentType)) {
        this.setState({ [storePaymentStateVariable]: 1 });
      } else if (paymentType === 'rework') {
        this.setState({ [storePaymentStateVariable]: 2 });
      } else {
        this.setState({ [storePaymentStateVariable]: 3 });
      }
    }
  };

  handleReset = () => {
    // removing status, payment_for from search params and reload
    const params = get(this.props, 'history.location.search') || '';
    const { origin } = queryString.parse(params);
    window.location = this.props.history.location.pathname + constructQueryString({ origin }, true);
  };

  getPermittingDetails = (permitId) => {
    this.props.getSelectedReworkServices({ permitId });
    this.props.getMessageList({ permitId });
    this.props.getChatInfo({ permitId });
    this.props.getPermittingById({
      permitId,
      successCbk: (app) => {
        this.checkPaymentSuccess(app);
        this.updateCustomerName();
      },
    });
    this.props.getFeedback({ id: permitId });
  };

  componentDidUpdate(prevProps) {
    const { permitId, getPermittingById, match } = this.props;
    if (prevProps.siteDetails != this.props.siteDetails) {
      this.updateCustomerName();
    }
    this.updateEnlightenManagerToken();
    if (permitId && permitId !== 'new' && permitId !== prevProps.permitId) {
      // TODO: this condition is not working
      getPermittingById({ permitId, successCbk: (app) => this.checkPaymentSuccess(app) });
      this.viewPermitApplication(false);
      this.isAddServicesClicked(false);
    }
    const { permit_id } = match.params;
    const { permit_id: prev_permit_id } = prevProps.match.params;
    if (permit_id !== prev_permit_id && permit_id !== 'new') {
      this.setState({ permitId: permit_id, sourceId: this.siteId, isPaymentStatusChecked: false });
      this.getPermittingDetails(permit_id);
      this.viewPermitApplication(false);
      this.isAddServicesClicked(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeWindow);
  }

  updateEnlightenManagerToken = () => {
    const { enlightenToken, enlightenManagerToken } = this.props;
    const token = getToken() || enlightenToken;
    if (!enlightenManagerToken && token) {
      this.props.setEnlightenManagerToken(token);
      setToken(token);
    }
  };

  handleWindowSizeWindow = () => {
    const isMobile = window.innerWidth < this.props.theme.breakpoints.values.sm;
    const isPermitChatButton = window.innerWidth < this.props.theme.breakpoints.values.lg;
    const isSmallScreen = window.innerWidth < this.props.theme.breakpoints.values.md;
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
    if (isPermitChatButton !== this.state.isPermitChatButton) {
      this.setState({ isPermitChatButton });
    }
    if (isSmallScreen !== this.state.isSmallScreen) {
      this.setState({ isSmallScreen });
    }
  };

  checkPaymentSuccess = async (applicationDetail) => {
    const { setPaymentStatus, getMaterialDetails, getSelectedServices, getProjectDetails } = this.props;
    const permitId = get(applicationDetail, 'permit_id');

    let isPaymentCompleted = false;
    const paymentDetails = get(applicationDetail, 'payment_details', []) || [];
    if (paymentDetails.length > 0) {
      paymentDetails.map((payment) => {
        if (payment.payment_status === 'succeeded') {
          isPaymentCompleted = true;
        }
        return null;
      });
    }
    setPaymentStatus({ paymentStatus: isPaymentCompleted });
    if (isPaymentCompleted) {
      await getSelectedServices({ permitId });
      await getMaterialDetails({ permitId });
      await getProjectDetails({ permitId });
    }
    if (!isEmpty(applicationDetail)) {
      this.setState({ isPaymentStatusChecked: true });
    }
    const systemTypeV2 = get(applicationDetail, 'project_details.system_type_v2', null);
    if (systemTypeV2 === null) {
      this.props.setV2SolarUI(false);
    } else {
      this.props.setV2SolarUI(true);
    }
  };

  fileDownloadStatus = (status) => {
    this.setState({ fileDownloadInProgress: status });
  };

  viewPermitApplication = (viewApplication) => {
    this.setState({ isPermitViewApplication: viewApplication });
  };

  isAddServicesClicked = (isClicked) => {
    this.setState({ isAddOnServicesClicked: isClicked });
  };

  togglePermitChatDialog = () => {
    this.setState({ permitChatDialog: !this.state.permitChatDialog });
  };

  render() {
    const { classes, inOrderStamps, ...other } = this.props;
    const {
      currentlySending = false,
      currentlySendingReworkRequest = false,
      paymentStatus,
      unreadMessageCount,
      getSelectedReworkServices,
      activeStep,
      enlightenManagerToken,
    } = other;
    const { permitId, sourceId, isMobile } = this.state;
    console.log('enlightenManagerToken=', enlightenManagerToken)
    console.log('paymentStatus=', paymentStatus)
    const params = get(this.props, 'history.location.search') || '';
    console.log('params=', params)
    const { origin, ...remainingQueryObj } = queryString.parse(params);
    this.searchParam = constructQueryString(remainingQueryObj) || '';
    const { search } = window.location;
    console.log('search=', this.searchParam)
    console.log('searchParam=', search)
    const { platform = '' } = queryString.parse(search);
    const fromITK = platform === 'itk' || includes(window.location.href, 'platform=itk');
    const applicationDetails = this.props.applicationDetail;
    const handleCustomerNameChange = (customerName) => {
      this.setState({ customerName: customerName });
    };
    if (!enlightenManagerToken || !this.state.isPaymentStatusChecked) {
      return <Loader loading />;
    }

    if (paymentStatus && !this.state.isPermitViewApplication) {
      const { classes, ...other } = this.props;
      return (
        <div className={classes.root}>
          <div className={clsx(classes.headerPadding, classes.displayFlex)}>
            <PageHeader className={classes.smallScreenFontSize} text="Permit Assistance" />
            {this.state.isSmallScreen && (
              <Fragment>
                <div className={classes.chatSvgWrapper}>
                  <IconButton disableRipple onClick={this.togglePermitChatDialog}>
                    <ChatSvg />
                  </IconButton>
                  {Number(unreadMessageCount) > 0 && (
                    <div className={classes.unreadMessageWrapper}>{unreadMessageCount}</div>
                  )}
                </div>
              </Fragment>
            )}
            <HelpSection />
          </div>
          <ApplicationStatus
            {...other}
            viewPermitApplication={this.viewPermitApplication}
            isAddServicesClicked={this.isAddServicesClicked}
            getSelectedReworkServices={getSelectedReworkServices}
            isMobile={isMobile}
            isPermitChatButton={this.state.isPermitChatButton}
            isSmallScreen={this.state.isSmallScreen}
            unreadMessageCount={unreadMessageCount}
            breadcrumbPath={this.breadcrumbPath}
            searchParam={this.searchParam}
            fileDownloadStatus={this.fileDownloadStatus}
            storePaymentFailure={this.state.storePaymentFailure}
            storePaymentSuccess={this.state.storePaymentSuccess}
            handleReset={this.handleReset}
          />
          {!fromITK && <Footer className={classes.footerContainer} />}
          {(currentlySendingReworkRequest || this.state.fileDownloadInProgress) && (
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}

          {this.state.permitChatDialog && (
            <PermitChats
              appDetails={applicationDetails}
              isFromApplicationStatusButton
              handleCloseDialog={this.togglePermitChatDialog}
              {...other}
            />
          )}
          {this.state.storePaymentFailure !== 0 && (
            <SuccessDialog
              showDialog={this.state.storePaymentFailure !== 0}
              dialogStatus={`failure`}
              toggleDialog={this.handleReset}
              permitId={this.props.permitId}
              handleReset={this.handleReset}
              resetData={() => {}}
            />
          )}
          {(this.state.storePaymentSuccess === 2 || this.state.storePaymentSuccess === 3) && (
            <SuccessDialog
              showDialog={this.state.storePaymentSuccess === 2 || this.state.storePaymentSuccess === 3}
              dialogStatus={`success`}
              toggleDialog={this.handleReset}
              permitId={this.props.permitId}
              handleReset={this.handleReset}
              resetData={() => {}}
              paymentType={this.state.storePaymentSuccess === 2 ? 'rework' : 'storage'}
            />
          )}
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <PermitAlert permitId={this.props.permitId || 'New'} customerName={this.state.customerName} />
        <Box className={classes.displayFlex}>
          <PageHeader text="Permit Assistance" />
          <HelpSection />
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9} sm={12} className={classes.container}>
            <PermitStepper
              sourceId={sourceId}
              permitId={permitId}
              isMobile={isMobile}
              handleCustomerNameChange={handleCustomerNameChange}
              reference={this.stepperRef}
              viewPermitApplication={this.viewPermitApplication}
              breadcrumbPath={this.breadcrumbPath}
              isSmallerThanLargeScreen={this.state.isPermitChatButton}
              searchParam={this.searchParam}
              checkPaymentSuccess={this.checkPaymentSuccess}
              isAddOnServicesClicked={this.state.isAddOnServicesClicked}
              disabled={inOrderStamps}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={8}>
            <Paper elevation={3} className={classes.statusNotificationWrapper}>
              <StatusDetails
                isSmallScreen={false}
                isAddOnServicesClicked={this.state.isAddOnServicesClicked}
                jumpToServiceScreen={() => this.stepperRef.current.click()}
                isAddServicesClicked={this.isAddServicesClicked}
              />
              {paymentStatus && (
                <Box className={classes.chatLinkSection} onClick={this.togglePermitChatDialog}>
                  <ChatRoundedIcon className={classes.chatIcon} color="primary" />
                  <Typography className={classes.chatLink}>{`Communicate with Enphase`}</Typography>
                  <Badge badgeContent={unreadMessageCount} color="primary" className={classes.chatBadge} />
                </Box>
              )}
            </Paper>
          </Grid>
          {!fromITK && (
            <Footer
              className={clsx(
                classes.stepFooterContainer,
                isMobile && paymentStatus && classes.submittedFooter,
                isMobile && !paymentStatus && (activeStep === 1 ? classes.editMaterialFooter : classes.editFooter)
              )}
            />
          )}
        </Grid>
        {this.state.permitChatDialog && (
          <PermitChats
            appDetails={applicationDetails}
            isFromApplicationStatusButton
            {...other}
            handleCloseDialog={this.togglePermitChatDialog}
          />
        )}
        {currentlySending && !this.state.permitChatDialog && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {this.state.storePaymentFailure !== 0 && (
          <SuccessDialog
            showDialog={this.state.storePaymentFailure !== 0}
            dialogStatus={`failure`}
            toggleDialog={this.handleReset}
            permitId={this.props.permitId}
            handleReset={this.handleReset}
            resetData={() => {}}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserSelectionData: () => dispatch(getUserSelectionData()),
  getOtherUserSelectionData: () => dispatch(getOtherUserSelectionData()),
  getAllServices: (id) => dispatch(getAllServices(id)),
  getReworkServices: (id) => dispatch(getReworkServices(id)),
  getPermittingById: (payload) => dispatch(getPermittingById(payload)),
  setPaymentStatus: (payload) => dispatch(setPaymentStatus(payload)),
  getMessageList: (payload) => dispatch(getMessageList(payload)),
  getS3URL: (payload) => dispatch(getS3URL(payload)),
  putToS3: (payload) => dispatch(putToS3(payload)),
  sendMessage: (payload) => dispatch(sendMessage(payload)),
  showHideLoader: (payload) => dispatch(showHideLoader(payload)),
  getChatInfo: (payload) => dispatch(getChatInfo(payload)),
  updateReworkServices: (payload) => dispatch(updateReworkServices(payload)),
  getSelectedReworkServices: (payload) => dispatch(getSelectedReworkServices(payload)),
  setIsSaveandClose: (payload) => dispatch(setIsSaveandClose(payload)),
  getPanelManufacturerList: (id) => dispatch(getPanelManufacturerList({ id })),
  getRackingMfs: (payload) => dispatch(getRackingMfs(payload)),
  getABFeatures: (payload) => dispatch(getABFeatures(payload)),
  getSelectedServices: (payload) => dispatch(getSelectedServices(payload)),
  getMaterialDetails: (payload) => dispatch(getMaterialDetails(payload)),
  getFeedback: (payload) => dispatch(getFeedback(payload)),
  getMostUsedValues: () => dispatch(getMostUsedValues()),
  setEnlightenManagerToken: (tokenVal) => dispatch(setEnlightenManagerToken(tokenVal)),
  getAccountData: (payload) => dispatch(getAccountData(payload)),
  getCompanyInfo: (payload) => dispatch(getCompanyInfo(payload)),
  getProjectDetails: (payload) => dispatch(getProjectDetails(payload)),
  getArrayBuilderDetails: (payload) => dispatch(getArrayBuilderDetails(payload)),
  setV2SolarUI: (payload) => dispatch(setV2SolarUI(payload)),
  setIsFromPackage: (isFromPackage) => dispatch(setIsFromPackage(isFromPackage)),
  getSiteDetails: (siteId) => dispatch(getSiteDetails({ siteId })),
});

const mapStateToProps = (state) => ({
  currentlySending: state.permitReducer.currentlySending,
  accountDetail: state.permitReducer.accountDetail,
  permitId: state.permitReducer.permitId,
  applicationDetail: state.permitReducer.applicationDetail,
  siteDetails: state.permitReducer.siteDetails,
  paymentStatus: state.permitReducer.paymentStatus,
  messageList: state.permitReducer.messageList,
  unreadMessageCount: state.permitReducer.unreadMessageCount,
  reworkServices: state.permitReducer.reworkServices,
  selectedReworkServices: state.permitReducer.selectedReworkServices,
  purchasedReworkServices: state.permitReducer.purchasedReworkServices,
  activeStep: state.permitReducer.activeStep,
  requiredServices: state.permitReducer.requiredServices,
  selectedServices: state.permitReducer.selectedServices,
  purchasedServices: state.permitReducer.purchasedServices,
  materialDetails: state.permitReducer.materialDetails,
  enlightenManagerToken: state.appReducer.enlightenManagerToken,
  currentlySendingReworkRequest: state.permitReducer.currentlySendingReworkRequest,
  permitCurrentlySending: state.permitReducer.permitCurrentlySending,
  inOrderStamps: state.permitReducer.inOrderStamps,
  permitServiceRole: state.permitReducer.permitServiceRole,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(index)));
