import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HelpSectionDownArrow from '../images/HelpSectionDownArrow';
import DownloadIcon from '../images/DownloadIcon';
import MailIcon from '../images/MailIcon';
import { HELP_GUIDE_LINK, CONTACT_EMAIL } from '../../../containers/Permitting/constants';

const useStyles = makeStyles((theme) => ({
  helpButton: {
    borderRadius: 'unset',
    marginLeft: theme.spacing(2.5),
    borderBottom: `${theme.spacing(0.125)}px solid ${theme.palette.primary.lightBlack}`,
  },
  helpButtonText: {
    color: theme.palette.primary.lightBlack,
  },
  helpButtonIcon: {
    marginLeft: theme.spacing(5),
  },
  listItemIcon: {
    minWidth: 'unset',
    paddingRight: theme.spacing(2),
  },
}));

const HelpSection = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openHelpGuide = () => {
    window.open(HELP_GUIDE_LINK, '_blank');
    handleClose();
  };

  const redirectToNewInstaller = () => {
    props.newInstallerView();
  };

  const openMailClient = () => {
    window.location.href = `mailto:${CONTACT_EMAIL}`;
    handleClose();
  };

  return (
    <Fragment>
      <Button
        classes={{
          root: classes.helpButton,
          text: classes.helpButtonText,
          endIcon: classes.helpButtonIcon,
        }}
        variant="text"
        disableRipple
        endIcon={<HelpSectionDownArrow />}
        onClick={handleClick}
      >
        Need Help?
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {props.isNewinstallerViewEnabled && (
          <MenuItem onClick={redirectToNewInstaller}>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText>Overview</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={openHelpGuide}>
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText>Help Guide</ListItemText>
        </MenuItem>
        <MenuItem onClick={openMailClient}>
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <MailIcon />
          </ListItemIcon>
          <ListItemText>Contact Us</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default HelpSection;
