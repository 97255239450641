import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { includes, isEmpty, isEqual, get } from '../../../../utils/lodash';
import Loader from '../../../../components/Loader';
import clsx from 'clsx';
import { selectMultipleRender } from '../../helper';
import Icons from '../../../../containers/Permitting/Chats/images';
import {
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  IconButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Tabs,
  Tab,
} from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import DateFilter from '../../../../components/Admin/Automation/List/DateFilter';
import { VIEW_ONLY_ROLES, DATE_FILTER_LIST } from '../../constants';
import Snackbar from '../../../../components/SnakBar';
import OutlinedButton from '../../../../components/common/OutlinedButton';
import { DEFAULT_ROW_PER_PAGE } from '../../StatusDashboard/constants';
import moment from 'moment';
import { getUserSelectionData } from '../../../Permitting/action';
import { getFileMetaData } from '../../helper';
import uploadFileDetails from '../../../../utils/uploadFileDetails';
import EditIcon from '../../../Permitting/TemplateDialog/EditTemplateIcon';
import DeleteIcon from '../../../Permitting/TemplateDialog/DeleteTemplateIcon';
import MultipleSelectAutoComplete from '../../../../components/common/MultipleSelectAutoComplete';
import Menu from '@material-ui/icons/Menu';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { useState } from 'react';
import { MODE_LISTING_COLUMNS, BATTERY_CONFIG_LISTING_COLUMNS } from '../../constants';
import AddMode from '../Info/Mode';
import { getSGMaterials } from '../../../Permitting/action';
import {
  createModeMapping,
  getAllModeMappings,
  createModeMappingSuccess,
  getModeMapping,
  getAllStorageConfigurations,
  getAllBatteryBackups,
  createStorageConfiguration,
  createStorageConfigurationSuccess,
  setBatteryConfigurationFilters,
  deleteModeMapping,
  deleteStorageConfiguration,
} from '../actions';
import { getAllManufacturers } from '../../Equipment/actions';
import DeleteMode from '../Info/Mode/delete';
import getConfig from '../../../../config/env';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiIconButton-root': {
      borderRadius: 0,
    },
    '& .highcharts-credits': {
      display: 'none',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  button: {
    color: theme.palette.primary.main,
    fontSize: 10,
  },
  container: {
    maxHeight: '70vh',
    minHeight: '65vh',
  },
  headerButton: {
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: '16px',
  },
  tableRow: {
    padding: theme.spacing(1.5),
  },
  tableHeader: {
    backgroundColor: '#335977',
  },
  headerContainer: {
    color: '#FFE785',
    fontSize: 13,
    fontWeight: 'bold',
    position: 'sticky',
    padding: 0,
    border: 'none',
    backgroundColor: '#fff',
  },
  headerText: {
    color: '#FFE785',
    fontSize: 13,
    fontWeight: 'bold',
    backgroundColor: '#335977',
    position: 'sticky',
    display: 'flex',
  },
  searchTableCell: {
    display: 'flex',
    height: theme.spacing(4.25),
    padding: theme.spacing(1.5),
    backgroundColor: '#fff',
  },
  inputField: {
    height: theme.spacing(1.75),
  },
  headerButtonText: {
    height: theme.spacing(2),
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: '14px',
    width: 'max-content',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(0.1),
  },
  headerSideElements: {
    minWidth: 'max-content',
    maxWidth: 'max-content',
  },
  selectContainer: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  clearFilterButton: {
    fontWeight: 400,
    height: theme.spacing(4.5),
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.6),
    marginRight: theme.spacing(2),
  },
  displayOptionText: {
    fontSize: theme.spacing(1.9),
    lineHeight: theme.spacing(0.18),
  },
  displayOptionBox: {
    width: theme.spacing(28),
    height: theme.spacing(5, '!important'),
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap',
      overflow: 'hidden',
      height: theme.spacing(4.4),
    },
  },
  styleSelect: {
    height: theme.spacing(4.25),
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  inputCheckboxForm: {
    width: theme.spacing(17),
  },
  textEditColor: {
    fontSize: '14px',
    color: '#008EEF',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  textDeleteColor: {
    fontSize: '14px',
    color: '#EF212C',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  setPointerCursor: {
    cursor: 'pointer',
  },
  menu: {
    cursor: 'pointer',
  },
  menuOptions: {
    position: 'absolute',
    top: '0',
    right: '0',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%',
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    padding: 0,
  },
  displayFlexButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
  leftPaddingDialog: {
    paddingLeft: theme.spacing(4),
  },
  rightPadding: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0.5),
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 3, 2),
  },
  cancelButton: {
    borderColor: 'black',
  },
  saveButton: {
    color: 'white',
    boxShadow: 'none',
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  pipe: {
    borderLeft: '1px solid #D3D3D3;',
    height: theme.spacing(3.5),
  },
  subHeaderTab: {
    '& .MuiTab-root': {
      textTransform: 'none',
      fontSize: theme.spacing(2),
      minWidth: theme.spacing(38),
      minHeight: theme.spacing(7),
      borderRadius: '10px 10px 0 0',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-textColorPrimary': {
      backgroundColor: '#E4D2D233',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      borderBottom: '4px solid #F3731F',
      backgroundColor: '#FFE8D9',
    },
  },
  subHeaderWrapper: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  tabPanel: {
    color: '#A3A3A3',
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
});

export class StatusDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.event = null;
    this.config = getConfig();
    this.state = {
      page: 0,
      showAddModePopup: false,
      tabName: '',
      isEdit: false,
      isDelete: false,
      deleteManufacturerName: '',
      showEditModePopup: false,
      sort_column: '',
      rowsPerPage: DEFAULT_ROW_PER_PAGE,
      sort_order: '',
      enableButton: false,
      enableSelectedButton: false,
      isServiceRoleFetched: false,
      filterValues: {},
      modeTotalElements: '',
      modeId: 'new',
      subHeaderTab: 'mode',
      basicDetails: {
        manufacturer: '',
        energy_independence: '',
        savings: '',
        backup: '',
        id: '',
      },
      errorData: {},
      recordSubmitted: false,
      manufacturersList: [],
      storageConfigurationTotalElements: '',
      showAddStorageConfigurationPopup: false,
      showEditStorageConfigurationPopup: false,
      isStorageConfigurationDelete: false,
      batteryBackupIdToNameMap: {},
      updatedStorageConfigurationList: [],
    };
    this.timeout = null;
    this.filterParams = { ...this.filterParams };
    this.setChartData = false;
  }

  // getRedirectionName(row) {
  //   const { subHeaderTab } = this.state;
  //   switch (subHeaderTab) {
  //     case 'battery manufacturer':
  //       return 'battery manufacturer';
  //     default:
  //       return 'mode';
  //   }
  // }

  handleBasicDetails = async (e, key, value) => {
    const basicDetails = this.state.basicDetails;
    let errorData = this.state.errorData;
    if (isEqual(key, 'manufacturer')) {
      basicDetails[key] = value;
      basicDetails['id'] = value.id;
      this.setState({ ...this.state, basicDetails });
    } else {
      if (e) {
        basicDetails[key] = e.target.value;
      } else {
        basicDetails[key] = value;
      }
      if (isEqual(key, 'energy_independence')) {
        this.setState({ ...this.state, basicDetails });
      }
      if (isEqual(key, 'savings')) {
        this.setState({ ...this.state, basicDetails });
      }
      if (isEqual(key, 'backup')) {
        this.setState({ ...this.state, basicDetails });
      }
    }
    this.setState({ basicDetails: basicDetails });
    this.setState({ ...this.state, basicDetails, errorData });
  };

  handleNewRecordDialog = (e) => {
    const { subHeaderTab, showAddModePopup } = this.state;
    switch (subHeaderTab) {
      case 'mode':
        this.setState({ showAddModePopup: true, tabName: 'mode', isEdit: false });
        break;
      default:
        this.setState({ tabName: subHeaderTab });
        this.props.history.push(`/battery-configuration/storage/new`);
    }
  };

  isEditAvailable() {
    const { emailId, permitServiceRole } = this.props;
    return includes(this.config.batteryEnabledUsers, emailId) && includes(['service_admin'], permitServiceRole);
  }

  handleSubHeaderTab(e, value) {
    this.setState({ subHeaderTab: value }, () => {
      this.setState({ isServiceRoleFetched: false });
      this.handleFilter();
    });
  }

  saveSubHeaderTab = () => {
    const filters = {
      subHeaderTab: this.state.subHeaderTab,
    };
    localStorage.setItem('batteryConfigurationFilter', JSON.stringify(filters));
  };
  generateBatteryBackupList = () => {
    const batteryBackupIdToNameMap = {};
    this.props.batteryBackupList.map(
      (batteryBackup) => (batteryBackupIdToNameMap[batteryBackup['id']] = batteryBackup['name'])
    );

    this.setState({ ...this.state, batteryBackupIdToNameMap });
  };

  handleFilter = () => {
    const subHeaderTab = this.state.subHeaderTab;
    clearTimeout(this.timeout);
    if (subHeaderTab === 'mode') {
      this.timeout = setTimeout(() => {
        this.props.getAllModeMappings({
          successCb: () => {
            this.setState({
              isServiceRoleFetched: true,
            });
          },
        });
      }, 1000);
      if (isEmpty(this.state.manufacturersList)) {
        this.props.getAllManufacturers({
          successCb: () => {
            this.setState({ manufacturersList: this.props.manufacturersList });
          },
        });
      }
    } else {
      this.timeout = setTimeout(() => {
        this.filterParams = { ...this.filterParams, current_status: 'All' };
        this.props.getAllBatteryBackups({
          equipmentType: 'batteries',
          ...this.filterParams,
          successCb: () => {
            this.setState({
              isServiceRoleFetched: true,
            });
            this.generateBatteryBackupList();
            this.props.getAllStorageConfigurations({
              equipmentType: 'storageConfigurations',
              ...this.filterParams,
              sort_order: 'desc',
              successCb: () => {
                const updatedStorageConfigurationList = this.props.storageConfigurationList.map(
                  (storageConfiguration) => {
                    const updatedstorageConfiguration = storageConfiguration;
                    const name = storageConfiguration.name;
                    const [capacity, ...rest] = name.split(':');
                    const batteryQuantityName = rest ? rest.join(':') : null;
                    let updatedConfigName = [];
                    if (batteryQuantityName) {
                      const batteryNameList = batteryQuantityName.split('+');
                      updatedConfigName = batteryNameList.map((batteryQuant) => {
                        const [first, ...rest] = batteryQuant.trim().split(' ');
                        const second = rest.join(' ');
                        return { quantity: first, name: second };
                      });
                    }
                    updatedstorageConfiguration['updatedName'] = { capacity: capacity };
                    if (updatedConfigName.length !== 0)
                      updatedstorageConfiguration['updatedName']['batteryQuantityList'] = updatedConfigName;
                    return updatedstorageConfiguration;
                  }
                );
                this.setState({
                  isServiceRoleFetched: true,
                  updatedStorageConfigurationList,
                });
              },
            });
          },
        });
      }, 1000);
    }
    this.saveSubHeaderTab();
  };
  componentDidMount() {
    // if (!isEmpty(this.props.permitServiceRole)) {
    if (!isEmpty(localStorage.getItem('batteryConfigurationFilter'))) {
      this.setState(
        {
          ...this.state,
          subHeaderTab: JSON.parse(localStorage.getItem('batteryConfigurationFilter')).subHeaderTab,
        },
        () => this.handleFilter()
      );
      // }
    } else this.handleFilter();
  }

  handleModeSubmit = () => {
    const payload = this.getPayload(this.event);
    payload.successCB = (modeMapping, response) => {
      this.props.createModeMappingSuccess({ response });
      this.handleCreateSuccessCallback(modeMapping);
      this.setState({
        basicDetails: {
          manufacturer: '',
          energy_independence: '',
          savings: '',
          backup: '',
        },
      });
      this.handleCancel();
      this.timeout = setTimeout(() => {
        this.props.getAllModeMappings({
          successCb: () => {
            this.setState({
              isServiceRoleFetched: true,
            });
          },
        });
      }, 1000);
    };
    payload.failureCB = (response) => {
      response = response.error;
      this.props.createModeMappingSuccess({ response });
    };
    this.props.createModeMapping(payload);
  };

  handleCancel() {
    this.setState({
      modeId: 'new',
      showAddModePopup: false,
      isEdit: false,
      basicDetails: {
        manufacturer: '',
        energy_independence: '',
        savings: '',
        backup: '',
        id: '',
      },
      errorData: {},
    });
  }

  getPayload = () => {
    const { modeId, basicDetails } = this.state;
    const manufacturerName = basicDetails.manufacturer.name;

    return {
      modeId,
      requestBody: {
        ...this.state.basicDetails,
        name: { en: manufacturerName },
        metadata: {
          backupDuringOutage: basicDetails.backup,
          energyIndependence: basicDetails.energy_independence,
          savingsOnElectricityBill: basicDetails.savings,
        },
      },
      successCB: (modeMapping, response = {}) => {
        this.props.createModeMappingSuccess({ response });
        this.handleCreateSuccessCallback(modeMapping);
        this.handleDialogClose();
      },
      failureCB: (response) => {
        this.props.createModeMappingSuccess({ response });
      },
    };
  };

  handleDialogClose = () => {
    this.setState({ showAddModePopup: false, isEdit: false });
  };

  handleCreateSuccessCallback = (newModeMapping) => {
    this.handleGetSuccessCallback(newModeMapping, () => { });
  };

  handleGetSuccessCallback = (modeMapping, callback = () => { }) => {
    const {
      modeId = 'new',
      manufacturer = '',
      energy_independence = '',
      savings = '',
      backup = '',
      id = '',
    } = modeMapping;

    this.setState(
      {
        modeId,
        basicDetails: {
          manufacturer,
          energy_independence,
          savings,
          backup,
          id,
        },
      },
      () => {
        callback();
      }
    );
  };

  deleteButtonClicked = () => {
    const { modeId } = this.state;
    this.props.deleteModeMapping({
      modeId: modeId,
      successCb: async () => {
        this.setState({ isDelete: false, modeId: 'new', page: 0 });
        try {
          this.timeout = setTimeout(() => {
            this.props.getAllModeMappings({
              successCb: () => {
                this.setState({
                  isServiceRoleFetched: true,
                });
              },
            });
          }, 1000);
          await this.deleteStorageConfigurations();
        } catch (error) {
          console.error('Error: Delete StorageConfigurations', error);
        }
      },
    });
  };

  deleteStorageConfigurations = async () => {
    try {
      const storageConfigurationListByManufacturerID = [...this.props.storageConfigurationList];
      await Promise.all(
        storageConfigurationListByManufacturerID.map((storageConfiguration) =>
          this.deleteStorageConfiguration(storageConfiguration.id)
        )
      );
    } catch (error) {
      throw new Error('Error: ' + error.message);
    }
  };

  deleteStorageConfiguration = async (id) => {
    this.props.deleteStorageConfiguration({
      equipmentType: 'storageConfiguration',
      id: id,
      successCb: () => { },
    });
  };

  deleteStorageConfigurationClicked = () => {
    const { modeId } = this.state;
    this.props.deleteStorageConfiguration({
      equipmentType: 'storageConfiguration',
      id: modeId,
      successCb: () => {
        this.setState({ isDelete: false, modeId: 'new', page: 0, isServiceRoleFetched: false }, () => {
          this.handleFilter();
        });
      },
    });
  };

  onClickDeleteHandler = (id, name) => {
    this.props.getAllStorageConfigurations({
      equipmentType: 'storageConfigurations',
      manufacturer: id,
      recordCount: '',
      sort_column: '',
      sort_order: '',
      successCb: () => {
        this.setState({
          isDelete: true,
          modeId: id,
          deleteManufacturerName: name,
        });
      },
    });
  };

  onClickDeleteStorageHandler = (id, name) => {
    this.setState({
      isDelete: true,
      modeId: id,
      deleteManufacturerName: name,
    });
  };

  render() {
    const {
      classes,
      currentlySending,
      message,
      messageType,
      permitServiceRole,
      getAllModeInProgress,
      getAllStorageConfigurationInProgress,
      getAllBatteryBackupInProgress,
      storageConfigurationList,
      modesList,
      modeMapping,
    } = this.props;
    const {
      page,
      rowsPerPage,
      sort_column,
      sort_order,
      showAddModePopup,
      isEdit,
      batteryConfigurationTotalElements,
      usersList,
      modeId,
      basicDetails,
      subHeaderTab,
      manufacturersList,
      deleteManufacturerName,
      batteryBackupIdToNameMap,
      updatedStorageConfigurationList,
    } = this.state;

    const LISTING_COLUMNS = subHeaderTab === 'mode' ? MODE_LISTING_COLUMNS : BATTERY_CONFIG_LISTING_COLUMNS;
    return (
      <div className={classes.root}>
        <Box className={classes.headerWrapper}>
          <Box sx={{ width: '100%' }} className={classes.subHeaderWrapper}>
            <Tabs
              value={subHeaderTab}
              onChange={(e, value) => this.handleSubHeaderTab(e, value)}
              textColor="primary"
              className={classes.subHeaderTab}
            >
              <Tab className={classes.tabPanel} value="mode" label="Manufacturer and Mode Mapping" />
              <Tab className={classes.tabPanel} value="Battery Configuration" label="Battery Configuration" />
            </Tabs>
          </Box>
          <Typography variant="h5" component="h5" className={classes.header}></Typography>
          {!includes(VIEW_ONLY_ROLES, permitServiceRole) && this.isEditAvailable() && (
            <Button
              className={classes.headerSideElements}
              variant="outlined"
              color="primary"
              size="small"
              onClick={(e) => this.handleNewRecordDialog(e)}
            >
              Create a New {subHeaderTab}
            </Button>
          )}
        </Box>
        {currentlySending === true ? (
          <Loader loading={currentlySending} />
        ) : (
          <Fragment>
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <Paper>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader>
                      <TableHead className={classes.tableHeader}>
                        <TableRow className={classes.tableRow}>
                          {LISTING_COLUMNS.map((column, index) => {
                            return (
                              <TableCell
                                className={classes.headerContainer}
                                key={`battery_configuration_columns_${index}`}
                                style={{ minWidth: column.minWidth + 'px' }}
                              >
                                <TableCell className={classes.headerText} key={index}>
                                  {column.key != 'menu' ? (
                                    <IconButton size="small" className={classes.headerButtonText} disableRipple={true}>
                                      {column.label}
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      size="small"
                                      className={classes.headerButtonText}
                                      disableRipple={true}
                                      disabled={true}
                                    />
                                  )}
                                </TableCell>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      {subHeaderTab === 'mode' ? (
                        getAllModeInProgress === true || !this.state.isServiceRoleFetched ? (
                          <TableBody>
                            <TableRow>
                              <TableCell
                                colSpan={LISTING_COLUMNS.length}
                                style={{ textAlign: 'center', border: 'none' }}
                              >
                                <br />
                                <br />
                                <br />
                                <CircularProgress size={15} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : isEqual(modesList, []) === true ? (
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={LISTING_COLUMNS.length} style={{ textAlign: 'center' }}>
                                <Typography variant="caption">** No Records Available **</Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <TableBody>
                            {modesList.map((row, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>{get(row, 'name') || 'NA'}</TableCell>
                                  <TableCell>{get(row['metadata'], 'energyIndependence') || 'NA'}</TableCell>
                                  <TableCell>{get(row['metadata'], 'savingsOnElectricityBill') || 'NA'}</TableCell>
                                  <TableCell>{get(row['metadata'], 'backupDuringOutage') || 'NA'}</TableCell>
                                  <TableCell>
                                    {!includes(VIEW_ONLY_ROLES, permitServiceRole) && this.isEditAvailable() && (
                                      <div className={clsx(classes.displayFlex)}>
                                        <div className={clsx(classes.displayFlex, classes.rightPadding)}>
                                          <Typography
                                            onClick={() => {
                                              this.props.getModeMapping({
                                                modeId: get(row, 'id'),
                                                successCB: (newModeMapping) => {
                                                  this.handleGetSuccessCallback(newModeMapping);
                                                  this.setState({
                                                    showAddModePopup: true,
                                                    modeId: get(row, 'id'),
                                                    isEdit: true,
                                                  });
                                                },
                                                failureCB: () => {
                                                  this.handleCancel();
                                                },
                                              });
                                            }}
                                            className={clsx(classes.textEditColor, classes.setPointerCursor)}
                                            display="inline"
                                          >
                                            Edit
                                          </Typography>
                                          <IconButton
                                            size="small"
                                            onClick={() => {
                                              this.props.getModeMapping({
                                                modeId: get(row, 'id'),
                                                successCB: (newModeMapping) => {
                                                  this.handleGetSuccessCallback(newModeMapping);
                                                  this.setState({
                                                    showAddModePopup: true,
                                                    modeId: get(row, 'id'),
                                                    isEdit: true,
                                                  });
                                                },
                                                failureCB: () => {
                                                  this.handleCancel();
                                                },
                                              });
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </div>
                                        <span className={clsx(classes.pipe, classes.rightPadding)} />
                                        <Typography
                                          onClick={() => this.onClickDeleteHandler(get(row, 'id'), get(row, 'name'))}
                                          className={clsx(classes.textDeleteColor, classes.setPointerCursor)}
                                          display="inline"
                                        >
                                          Delete
                                        </Typography>
                                        <div>
                                          <IconButton
                                            size="small"
                                            onClick={() => this.onClickDeleteHandler(get(row, 'id'), get(row, 'name'))}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </div>
                                      </div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        )
                      ) : getAllStorageConfigurationInProgress === true ||
                        getAllBatteryBackupInProgress === true ||
                        !this.state.isServiceRoleFetched ? (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={LISTING_COLUMNS.length} style={{ textAlign: 'center', border: 'none' }}>
                              <br />
                              <br />
                              <br />
                              <CircularProgress size={15} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : isEqual(updatedStorageConfigurationList, []) === true ? (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={LISTING_COLUMNS.length} style={{ textAlign: 'center' }}>
                              <Typography variant="caption">** No Records Available **</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {updatedStorageConfigurationList.map((row, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{get(row['Manufacturer'], 'name') || 'NA'}</TableCell>
                                <TableCell>
                                  {(
                                    <>
                                      <b>{get(row['updatedName'], 'capacity')}</b>
                                      {get(row['updatedName'], 'batteryQuantityList')?.reduce(
                                        (acc, cur, index) => (
                                          <>
                                            {acc} {cur.quantity} <b>{cur.name}</b>
                                            {index !== get(row['updatedName'], 'batteryQuantityList').length - 1 && (
                                              <span> +</span>
                                            )}
                                          </>
                                        ),
                                        ': '
                                      )}
                                    </>
                                  ) || 'NA'}
                                </TableCell>
                                <TableCell>
                                  {row['batteryBackups']
                                    .reduce(
                                      (acc, cur) =>
                                        `${acc}${batteryBackupIdToNameMap[cur['id']]} (${cur['quantity']}), `,
                                      ''
                                    )
                                    .slice(0, -2) || 'NA'}
                                </TableCell>
                                <TableCell>
                                  {!includes(VIEW_ONLY_ROLES, permitServiceRole) && this.isEditAvailable() && (
                                    <div className={clsx(classes.displayFlex)}>
                                      <div className={clsx(classes.displayFlex, classes.rightPadding)}>
                                        <Typography
                                          className={clsx(classes.textEditColor, classes.setPointerCursor)}
                                          display="inline"
                                          onClick={() =>
                                            this.props.history.push(`/battery-configuration/storage/${get(row, 'id')}`)
                                          }
                                        >
                                          Edit
                                        </Typography>
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            this.props.history.push(`/battery-configuration/storage/${get(row, 'id')}`)
                                          }
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </div>
                                      <span className={clsx(classes.pipe, classes.rightPadding)} />
                                      <Typography
                                        className={clsx(classes.textDeleteColor, classes.setPointerCursor)}
                                        display="inline"
                                        onClick={() =>
                                          this.onClickDeleteStorageHandler(get(row, 'id'), get(row, 'name'))
                                        }
                                      >
                                        Delete
                                      </Typography>
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          this.onClickDeleteStorageHandler(get(row, 'id'), get(row, 'name'))
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {/* <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={batteryConfigurationTotalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => this.handleChangePage(e, newPage)}
                    onRowsPerPageChange={(e) => this.handleChangeRowsPerPage(e)}
                  /> */}
                </Paper>
              </Grid>
            </Grid>
          </Fragment>
        )}
        {message && messageType && <Snackbar severity={messageType} message={message} />}
        {showAddModePopup && (
          <AddMode
            handleSubmit={this.handleModeSubmit}
            modeId={modeId}
            manufacturersList={manufacturersList}
            modesList={modesList}
            basicDetails={basicDetails}
            modeMapping={modeMapping}
            handleBasicDetails={this.handleBasicDetails}
            showAddModePopup={this.state.showAddModePopup}
            isEdit={this.state.isEdit}
            errorData={this.state.errorData}
            handleShowAddModePopup={() => {
              this.setState({
                modeId: 'new',
                showAddModePopup: !this.state.showAddModePopup,
                isEdit: false,
                basicDetails: {
                  manufacturer: '',
                  energy_independence: '',
                  savings: '',
                  backup: '',
                  id: '',
                },
                errorData: {},
              });
            }}
          />
        )}
        {this.state.isDelete && (
          <DeleteMode
            modeId={modeId}
            deleteManufacturerName={deleteManufacturerName}
            isDelete={this.state.isDelete}
            deleteButtonClicked={this.deleteButtonClicked}
            deleteStorageConfigurationClicked={this.deleteStorageConfigurationClicked}
            handleIsDelete={() =>
              this.setState({ isDelete: !this.state.isDelete, modeId: 'new', deleteManufacturerName: '' })
            }
            module={this.state.subHeaderTab}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSGMaterials: (payload) => dispatch(getSGMaterials(payload)),
  getAllModeMappings: (payload) => dispatch(getAllModeMappings(payload)),
  getAllManufacturers: (payload) => dispatch(getAllManufacturers(payload)),
  createModeMapping: (payload) => dispatch(createModeMapping(payload)),
  createModeMappingSuccess: (payload) => dispatch(createModeMappingSuccess(payload)),
  getModeMapping: (payload) => dispatch(getModeMapping(payload)),
  deleteModeMapping: (payload) => dispatch(deleteModeMapping(payload)),
  getAllStorageConfigurations: (payload) => dispatch(getAllStorageConfigurations(payload)),
  getAllBatteryBackups: (payload) => dispatch(getAllBatteryBackups(payload)),
  deleteStorageConfiguration: (payload) => dispatch(deleteStorageConfiguration(payload)),
});

const mapStateToProps = (state) => ({
  sgMaterials: state.permitReducer.sgMaterials,
  modesList: state.batteryConfigurationReducer.modesList,
  manufacturersList: state.equipmentReducer.manufacturersList,
  currentlySending: state.batteryConfigurationReducer.currentlySending,
  getAllModeInProgress: state.batteryConfigurationReducer.getAllModeInProgress,
  message: state.batteryConfigurationReducer.message,
  messageType: state.batteryConfigurationReducer.messageType,
  permitServiceRole: state.appReducer.permitServiceRole,
  modeMapping: state.batteryConfigurationReducer.modeMapping,
  storageConfigurationList: state.batteryConfigurationReducer.storageConfigurationList,
  getAllStorageConfigurationInProgress: state.batteryConfigurationReducer.getAllStorageConfigurationInProgress,
  storageConfigurationTotalElements: state.batteryConfigurationReducer.storageConfigurationTotalElements,
  batteryBackupList: state.batteryConfigurationReducer.batteryBackupList,
  getAllBatteryBackupInProgress: state.batteryConfigurationReducer.getAllBatteryBackupInProgress,
  batteryBackupTotalElements: state.batteryConfigurationReducer.batteryBackupTotalElements,
  emailId: state.appReducer.emailId,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusDashboard));
