import React from 'react';

function ViewApplication(props) {
  const { disabled } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30.469" height="40" viewBox="0 0 30.469 40">
      <defs />
      <g transform="translate(-61)">
        <path
          fill={disabled ? '#919191' : '#016ae3'}
          d="M91.125,9.718,81.751.343A1.173,1.173,0,0,0,80.922,0H64.516A3.52,3.52,0,0,0,61,3.516V36.484A3.52,3.52,0,0,0,64.516,40H87.953a3.52,3.52,0,0,0,3.516-3.516V10.547A1.183,1.183,0,0,0,91.125,9.718ZM82.094,4l5.374,5.374h-4.2A1.173,1.173,0,0,1,82.094,8.2Zm5.859,33.655H64.516a1.173,1.173,0,0,1-1.172-1.172V3.516a1.173,1.173,0,0,1,1.172-1.172H79.75V8.2a3.52,3.52,0,0,0,3.516,3.516h5.859V36.484A1.173,1.173,0,0,1,87.953,37.656Z"
          transform="translate(0 0)"
        />
        <path
          fill={disabled ? '#919191' : '#016ae3'}
          d="M166.234,212H152.172a1.172,1.172,0,0,0,0,2.344h14.063a1.172,1.172,0,0,0,0-2.344Z"
          transform="translate(-82.969 -195.437)"
        />
        <path
          fill={disabled ? '#919191' : '#016ae3'}
          d="M166.234,272H152.172a1.172,1.172,0,0,0,0,2.344h14.063a1.172,1.172,0,0,0,0-2.344Z"
          transform="translate(-82.969 -250.75)"
        />
        <path
          fill={disabled ? '#919191' : '#016ae3'}
          d="M166.234,332H152.172a1.172,1.172,0,0,0,0,2.344h14.063a1.172,1.172,0,0,0,0-2.344Z"
          transform="translate(-82.969 -306.063)"
        />
        <path
          fill={disabled ? '#919191' : '#016ae3'}
          d="M161.547,392h-9.375a1.172,1.172,0,0,0,0,2.344h9.375a1.172,1.172,0,0,0,0-2.344Z"
          transform="translate(-82.969 -361.375)"
        />
      </g>
    </svg>
  );
}

export default ViewApplication;
