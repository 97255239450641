import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import React from 'react';
import { get, isEmpty } from '../../../../utils/lodash';
import HelpTextIcon from '../../../common/HelpTextIcon';
import { Label } from '../../../common/Label';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  selectText: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.7)
  },
  toggleWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  radioWrapper: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(5.5, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
      height: theme.spacing(10),
      marginTop: theme.spacing(4.5)
    },
    fontSize: theme.spacing(0.1)
  },
  radioTitle: {
    color: '#4f4e68',
    fontSize: theme.spacing(1.8),
    lineHeight: theme.spacing(0.1875),
    height: theme.spacing(3.5),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(1.7),
      lineHeight: theme.spacing(0.125)
    }
  },
  longLabel: {
    transform: 'translate(0, -20px)',
  },
  conditionalLongLabel: {
    '@media (max-width: 1280px)': {
      transform: 'translate(0, -20px)',
    }
  },
}));

const FireSetbacksSection = props => {
  const classes = useStyles();
  const {
    errorData,
    handleFireSetbacks,
    firesetbacks,
    preventDefault,
    sectionLabel,
    readOnly,
    stateSelected,
    customFiresetback
  } = props;
  const {
    flat_roof = '',
    ridge_mp = '',
    ridge_both_sides_lpv = '',
    ridge_both_sides_hpv = '',
    gable = '',
    rake = '',
    eave = '',
    obstruction = '',
    service_drops = '',
    egress = '',
    path_ridge_to_eave = '',
    paths_on_separate_roof_plane = '',
    path_adjacent_array = '',
    paths_on_single_ridge_home = '',
    max_array_span = '',
    ground_mount_perimeter = '',
    ridge_home_with_sprinklers = '',
    ridge_home_with_sprinklers_high = '',
    fsb_each_side = ''
  } = firesetbacks;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel
        header={sectionLabel}
        icon={
          <HelpTextIcon
            message="Fields get populated based on the codes selection in governing codes section. 
                          Fire setbacks defined by IRC would be preferred over IFC code followed by IBC code when there are conflicts."
            position="right"
          />
        }
      >
        <Box className={classes.toggleWrapper}>
          <Typography className={classes.selectText}>Governing codes</Typography>
          <Switch
            checked={customFiresetback}
            color="primary"
            disabled={readOnly}
            onChange={e => handleFireSetbacks(e)}
          />
          <Typography className={classes.selectText}>Custom</Typography>
        </Box>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_array_span'}
                className={classes.inputField}
                label={<Label text={'Max array span (ft)'} capitalizeLabelText={false} />}
                value={max_array_span || ''}
                onChange={e => handleFireSetbacks(e, 'max_array_span')}
                type="number"
                error={!isEmpty(get(errorData, 'max_array_span'))}
                helperText={get(errorData, 'max_array_span')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'ground_mount_perimeter'}
                className={classes.inputField}
                label={<Label text={'Ground mount perimeter (ft)'} capitalizeLabelText={false} />}
                value={ground_mount_perimeter || ''}
                onChange={e => handleFireSetbacks(e, 'ground_mount_perimeter')}
                type="number"
                error={!isEmpty(get(errorData, 'ground_mount_perimeter'))}
                helperText={get(errorData, 'ground_mount_perimeter')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'service_drops'}
                className={classes.inputField}
                label={<Label text={'FSB - service drops (in)'} capitalizeLabelText={false} />}
                value={service_drops || ''}
                onChange={e => handleFireSetbacks(e, 'service_drops')}
                type="number"
                error={!isEmpty(get(errorData, 'service_drops'))}
                helperText={get(errorData, 'service_drops')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'egress'}
                className={classes.inputField}
                label={<Label text={'FSB - egress (in)'} capitalizeLabelText={false} />}
                value={egress || ''}
                onChange={e => handleFireSetbacks(e, 'egress')}
                type="number"
                error={!isEmpty(get(errorData, 'egress'))}
                helperText={get(errorData, 'egress')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'obstruction'}
                className={classes.inputField}
                label={<Label text={'FSB - obstruction (in)'} capitalizeLabelText={false} />}
                value={obstruction || ''}
                onChange={e => handleFireSetbacks(e, 'obstruction')}
                type="number"
                error={!isEmpty(get(errorData, 'obstruction'))}
                helperText={get(errorData, 'obstruction')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'ridge_mp'}
                className={classes.inputField}
                label={<Label text={'FSB - ridge MP side (in)'} capitalizeLabelText={false} />}
                value={ridge_mp || ''}
                onChange={e => handleFireSetbacks(e, 'ridge_mp')}
                type="number"
                error={!isEmpty(get(errorData, 'ridge_mp'))}
                helperText={get(errorData, 'ridge_mp')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'ridge_both_sides_lpv'}
                className={classes.inputField}
                label={<Label text={'Ridge (both sides) home with no sprinklers (in) [PV ≤ 33% Roof]'} capitalizeLabelText={false}
                  className={classes.conditionalLongLabel} />}
                value={ridge_both_sides_lpv || ''}
                onChange={e => handleFireSetbacks(e, 'ridge_both_sides_lpv')}
                type="number"
                error={!isEmpty(get(errorData, 'ridge_both_sides_lpv'))}
                helperText={get(errorData, 'ridge_both_sides_lpv')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'ridge_both_sides_hpv'}
                className={classes.inputField}
                label={<Label text={'Ridge (both sides) home with no sprinklers (in) [PV > 33% Roof]'} capitalizeLabelText={false}
                  className={classes.conditionalLongLabel} />}
                value={ridge_both_sides_hpv || ''}
                onChange={e => handleFireSetbacks(e, 'ridge_both_sides_hpv')}
                type="number"
                error={!isEmpty(get(errorData, 'ridge_both_sides_hpv'))}
                helperText={get(errorData, 'ridge_both_sides_hpv')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'ridge_home_with_sprinklers'}
                className={classes.inputField}
                label={<Label text={'Ridge (both sides) home with sprinklers (in) [PV ≤ 66% Roof]'} capitalizeLabelText={false}
                  className={classes.conditionalLongLabel} />}
                value={ridge_home_with_sprinklers || ''}
                onChange={e => handleFireSetbacks(e, 'ridge_home_with_sprinklers')}
                type="number"
                error={!isEmpty(get(errorData, 'ridge_home_with_sprinklers'))}
                helperText={get(errorData, 'ridge_home_with_sprinklers')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'ridge_home_with_sprinklers_high'}
                className={classes.inputField}
                label={<Label text={'Ridge (both sides) Home with Sprinklers (in.) [PV > 66% Roof]'} capitalizeLabelText={false}
                  className={classes.conditionalLongLabel} />}
                value={ridge_home_with_sprinklers_high || ''}
                onChange={e => handleFireSetbacks(e, 'ridge_home_with_sprinklers_high')}
                type="number"
                error={!isEmpty(get(errorData, 'ridge_home_with_sprinklers_high'))}
                helperText={get(errorData, 'ridge_home_with_sprinklers_high')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'gable'}
                className={classes.inputField}
                label={<Label text={'Gable (in)'} capitalizeLabelText={false} />}
                value={gable || ''}
                onChange={e => handleFireSetbacks(e, 'gable')}
                type="number"
                error={!isEmpty(get(errorData, 'gable'))}
                helperText={get(errorData, 'gable')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'eave'}
                className={classes.inputField}
                label={<Label text={'Eave (in)'} capitalizeLabelText={false} />}
                value={eave || ''}
                onChange={e => handleFireSetbacks(e, 'eave')}
                type="number"
                error={!isEmpty(get(errorData, 'eave'))}
                helperText={get(errorData, 'eave')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'rake'}
                className={classes.inputField}
                label={<Label text={'Rake (in)'} capitalizeLabelText={false} />}
                value={rake || ''}
                onChange={e => handleFireSetbacks(e, 'rake')}
                type="number"
                error={!isEmpty(get(errorData, 'rake'))}
                helperText={get(errorData, 'rake')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'paths_on_single_ridge_home'}
                className={classes.inputField}
                label={<Label text={'2 Paths - ridge-to-eave on single ridge home (in)'} capitalizeLabelText={false} />}
                value={paths_on_single_ridge_home || ''}
                onChange={e => handleFireSetbacks(e, 'paths_on_single_ridge_home')}
                type="number"
                error={!isEmpty(get(errorData, 'paths_on_single_ridge_home'))}
                helperText={get(errorData, 'paths_on_single_ridge_home')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'path_adjacent_array'}
                className={classes.inputField}
                label={<Label text={'Path - between adjacent arrays (in)'} capitalizeLabelText={false} />}
                value={path_adjacent_array || ''}
                onChange={e => handleFireSetbacks(e, 'path_adjacent_array')}
                type="number"
                error={!isEmpty(get(errorData, 'path_adjacent_array'))}
                helperText={get(errorData, 'path_adjacent_array')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'flat_roof'}
                className={classes.inputField}
                label={<Label text={'Flat roof perimeter (in)'} capitalizeLabelText={false} />}
                value={flat_roof || ''}
                onChange={e => handleFireSetbacks(e, 'flat_roof')}
                type="number"
                error={!isEmpty(get(errorData, 'flat_roof'))}
                helperText={get(errorData, 'flat_roof')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'paths_on_separate_roof_plane'}
                className={classes.inputField}
                label={
                  <Label
                    text={
                      '2 Paths - ridge-to-eave on street/driveway side and ridge-to-eave on separate roof plane (in)'
                    }
                    capitalizeLabelText={false} className={classes.longLabel}
                  />
                }
                value={paths_on_separate_roof_plane || ''}
                onChange={e => handleFireSetbacks(e, 'paths_on_separate_roof_plane')}
                type="number"
                error={!isEmpty(get(errorData, 'paths_on_separate_roof_plane'))}
                helperText={get(errorData, 'paths_on_separate_roof_plane')}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'path_ridge_to_eave'}
                className={classes.inputField}
                label={<Label text={'1 Path - ridge-to-eave (in)'} capitalizeLabelText={false} />}
                value={path_ridge_to_eave || ''}
                onChange={e => handleFireSetbacks(e, 'path_ridge_to_eave')}
                type="number"
                error={!isEmpty(get(errorData, 'path_ridge_to_eave'))}
                helperText={get(errorData, 'path_ridge_to_eave')}
                onKeyPress={e => preventDefault(e)}
                disabled={readOnly || !customFiresetback || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <FormControl
              component="div"
              className={classes.radioWrapper}
              disabled={readOnly || !customFiresetback || !stateSelected}
            >
              <FormLabel component="legend" className={classes.radioTitle}>
                18 inch FSBs on each side if adjacent MPs sharing a hip/valley (flush if no adjacent MP)
              </FormLabel>
              <RadioGroup value={fsb_each_side} row onChange={e => handleFireSetbacks(e, 'fsb_each_side')}>
                <FormControlLabel value={'true'} control={<Radio color="primary" size="small" />} label="Yes" tabIndex={0} />
                <FormControlLabel value={'false'} control={<Radio color="primary" size="small" />} label="No" tabIndex={0} />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default FireSetbacksSection;
