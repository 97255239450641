import React from 'react';
import { isEmpty } from '../../../utils/lodash';
import { makeStyles } from '@material-ui/core/styles';
import PanelTemplate from '../PanelTemplate';
import { Typography, Box, FormControlLabel, RadioGroup, Radio, FormControl } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: 'flex'
  },
  mandatoryWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  mandatoryFeild: {
    color: theme.palette.primary.lightBlack,
    marginLeft: theme.spacing(0.5),
    fontSize: 12
  },
  voltageRadioWrapper: {
    display: 'flex'
  },
  voltageRadioLabel: {
    margin: theme.spacing(-0.1, 3, 0, 0)
  },
  radioWrapper: {
    height: theme.spacing(2),
    margin: theme.spacing(-1, 0, 0, 0)
  },
  helpIcon: {
    margin: theme.spacing(0.125, 0, 0, 1)
  }
}));

const PanelTemplateWithMandatory = props => {
  const classes = useStyles();
  const { radioDetails = '' } = props;

  const headerText = (
    <Box className={classes.headerWrapper}>
      <Typography>{props.header}</Typography>
      {!isEmpty(props.icon) && <Typography className={classes.helpIcon}>{props.icon}</Typography>}
      <Box className={classes.mandatoryWrapper}>
        <Box className={classes.mandatoryFeild}>
          <FormControl component="div" className={classes.radioWrapper}>
            <RadioGroup row value={radioDetails.value} onChange={e => radioDetails.handleFunction(e)}>
              <FormControlLabel value={'imperial'} control={<Radio color="primary" size="small" />} label="Imperial" />
              <FormControlLabel value={'metric'} control={<Radio color="primary" size="small" />} label="Metric" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
  return <PanelTemplate header={headerText}>{props.children}</PanelTemplate>;
};

export default PanelTemplateWithMandatory;
