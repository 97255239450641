import React from 'react';

const Solar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.908" height="24.692" viewBox="0 0 19.908 24.692">
      <g transform="translate(-985.369 -974.737)">
        <path
          fill="none"
          stroke="#454545"
          d="M690.829,477.3h14.7l1.881,8.791-18.656-.111Z"
          transform="translate(297.247 507.481)"
        />
        <path
          fill="none"
          stroke="#454545"
          d="M688.751,490.672v1.616h18.692V490.9"
          transform="translate(297.249 502.812)"
        />
        <path fill="none" stroke="#454545" d="M690.824,481.775h15.923" transform="translate(296.526 505.918)" />
        <path fill="none" stroke="#454545" d="M689.759,486.193H707" transform="translate(296.897 504.376)" />
        <path fill="none" stroke="#454545" d="M699.021,477.3l-.621,8.674" transform="translate(293.88 507.481)" />
        <path fill="none" stroke="#454545" d="M706.9,477.3l.621,8.789" transform="translate(290.914 507.481)" />
        <path fill="none" stroke="#454545" d="M691.453,499.039h15.332" transform="translate(296.306 499.89)" />
        <path fill="none" stroke="#454545" d="M697.476,498.662v-1.8h7.357v1.6" transform="translate(294.203 500.387)" />
        <path fill="none" stroke="#454545" d="M701.9,493.155V495.4" transform="translate(292.658 501.945)" />
        <path fill="none" stroke="#454545" d="M701.9,493.155V495.4" transform="translate(293.96 501.945)" />
        <g transform="translate(991.858 974.737)">
          <g transform="translate(2.013 2.038)">
            <path fill="none" d="M1.627,0A1.627,1.627,0,1,1,0,1.627,1.627,1.627,0,0,1,1.627,0Z" />
            <path
              fill="#454545"
              d="M1.627.353A1.274,1.274,0,1,0,2.9,1.627,1.276,1.276,0,0,0,1.627.353m0-.353A1.627,1.627,0,1,1,0,1.627,1.627,1.627,0,0,1,1.627,0Z"
              transform="translate(0 0)"
            />
          </g>
          <g transform="translate(3.64)">
            <path fill="none" stroke="#454545" d="M650.5,466.869v1.425" transform="translate(-650.5 -466.869)" />
            <path fill="none" stroke="#454545" d="M650.5,466.869v1.425" transform="translate(-650.5 -461.011)" />
          </g>
          <g transform="translate(0 3.64) rotate(-90)">
            <path fill="none" stroke="#454545" d="M0,0V1.423" />
            <path fill="none" stroke="#454545" d="M0,0V1.423" transform="translate(0 5.858)" />
          </g>
          <g transform="translate(1.066 1.066) rotate(-45)">
            <path fill="none" stroke="#454545" d="M0,0V1.423" />
            <path fill="none" stroke="#454545" d="M0,0V1.423" transform="translate(0 5.858)" />
          </g>
          <g transform="translate(6.214 1.066) rotate(45)">
            <path fill="none" stroke="#454545" d="M0,0V1.423" transform="translate(0)" />
            <path fill="none" stroke="#454545" d="M0,0V1.423" transform="translate(0 5.858)" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Solar;
