import React, { Component } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import history from './history';
import { DefaultLayout, AdminLayout } from '../containers/Layouts';
import StatusDashboard from '../containers/Admin/StatusDashboard';
import NoMatch from '../components/NoMatch';
import RequestDetails from '../containers/Admin/RequestDetails';
import MessageHistory from '../containers/Admin/MessageHistory';
import PermittingListing from '../containers/Permitting/PermittingListing';
import PermitAssistance from '../containers/Permitting';
import PermitChats from '../containers/Permitting/Chats';
import NPSFeedback from '../components/Permitting/NPSFeedback';
import AHJList from '../containers/Admin/AHJ/List';
import AHJInfo from '../containers/Admin/AHJ/Info/index';
import AHJInfoOldUI from '../containers/Admin/AHJ/Info/oldUI';
import UtilityList from '../containers/Admin/Utility/List';
import UtilityInfo from '../containers/Admin/Utility/Info';
import EquipmentList from '../containers/Admin/Equipment/List';
import EquipmentACPanelInfo from '../containers/Admin/Equipment/Info/Panel/AC';
import EquipmentDCPanelInfo from '../containers/Admin/Equipment/Info/Panel/DC';
import EquipmentCentralInverterInfo from '../containers/Admin/Equipment/Info/Inverter/Central';
import EquipmentMicroInverterInfo from '../containers/Admin/Equipment/Info/Inverter/Micro';
import EquipmentHybridInverterInfo from '../containers/Admin/Equipment/Info/Inverter/Hybrid';
import EquipmentBatteryInfo from '../containers/Admin/Equipment/Info/Battery';
import EquipmentOptimizerInfo from '../containers/Admin/Equipment/Info/Optimizer';
import PermitFileDownload from '../components/Admin/PermitFileDownload';
import StructuralAssemblyList from '../containers/Admin/StructuralAssembly/List';
import BatteryConfigurationList from '../containers/Admin/BatteryConfiguration/List';
import BatteryConfigurationInfo from '../containers/Admin/BatteryConfiguration/Info/StorageConfiguration';

export default class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/permitting-assistance/feedback">
            <NPSFeedback />
          </Route>
          <Route exact path="/permitting-assistance/document">
            <PermitFileDownload />
          </Route>
          <Route exact path="/" render={() => <Redirect to="/ahj" />} />
          <AdminLayout exact path="/permitting_dashboard" component={StatusDashboard} />
          <AdminLayout exact path="/request/:request_id" component={RequestDetails} />
          <AdminLayout exact path="/request/:request_id/msg_history" component={MessageHistory} />
          <AdminLayout exact path="/ahj" component={AHJList} />
          <AdminLayout exact path="/ahj/:id/old" component={AHJInfoOldUI} />
          <AdminLayout exact path="/ahj/:id" component={AHJInfo} />
          <AdminLayout exact path="/utility" component={UtilityList} />
          <AdminLayout exact path="/utility/:id" component={UtilityInfo} />
          <AdminLayout exact path="/equipment" component={EquipmentList} />
          <AdminLayout exact path="/equipment/acpanel/:id" component={EquipmentACPanelInfo} />
          <AdminLayout exact path="/equipment/dcpanel/:id" component={EquipmentDCPanelInfo} />
          <AdminLayout exact path="/equipment/centralinverter/:id" component={EquipmentCentralInverterInfo} />
          <AdminLayout exact path="/equipment/microinverter/:id" component={EquipmentMicroInverterInfo} />
          <AdminLayout exact path="/equipment/hybridinverter/:id" component={EquipmentHybridInverterInfo} />
          <AdminLayout exact path="/equipment/battery/:id" component={EquipmentBatteryInfo} />
          <AdminLayout exact path="/equipment/optimizer/:id" component={EquipmentOptimizerInfo} />
          <AdminLayout exact path="/structuralAssembly" component={StructuralAssemblyList} />
          <AdminLayout exact path="/battery-configuration" component={BatteryConfigurationList} />
          <AdminLayout exact path="/battery-configuration/storage/:id" component={BatteryConfigurationInfo} />
          <DefaultLayout exact path="/manager/dashboard/permitting-assistance" component={PermittingListing} />
          <DefaultLayout
            exact
            path="/manager/dashboard/:source_id/permitting-assistance/:permit_id"
            component={PermitAssistance}
          />
          <DefaultLayout
            exact
            path="/manager/dashboard/permitting-assistance/:permit_id"
            component={PermitAssistance}
          />
          <DefaultLayout
            exact
            path="/manager/dashboard/permitting-assistance/:permit_id/chats"
            component={PermitChats}
          />
          <DefaultLayout exact path="/manager/account/permitting-assistance" component={PermittingListing} />
          <DefaultLayout
            exact
            path="/manager/account/:source_id/permitting-assistance/:permit_id"
            component={PermitAssistance}
          />
          <DefaultLayout exact path="/manager/account/permitting-assistance/:permit_id" component={PermitAssistance} />
          <DefaultLayout exact path="/manager/account/permitting-assistance/:permit_id/chats" component={PermitChats} />
          <DefaultLayout component={NoMatch} />
        </Switch>
      </Router>
    );
  }
}
