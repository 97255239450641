import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
    color: 'white'
  },
  listItem: {
    margin: theme.spacing(0.4),
    width: 'fit-content',
    borderRadius: theme.spacing(1),
    display: 'flex',
    padding: theme.spacing(0.4, 1),
    flexDirection: 'column',
    color: theme.palette.primary.mainText,
    wordBreak: 'break-word',
    pointerEvents: 'none',
    userSelect: 'auto'
  },
  listItemLeft: {
    backgroundColor: theme.palette.primary.white,
    border: 'solid 1px #c5c5c5',
    alignSelf: 'flex-start',
    marginRight: theme.spacing(6)
  },
  listItemRight: {
    border: 'solid 1px',
    borderColor: theme.palette.tertiary.buttonColor,
    backgroundColor: theme.palette.primary.chatText,
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(6)
  },
  chatList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
  },
  hide: {
    display: 'none'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.primary.white,
    borderTopRightRadius: theme.spacing(0.5),
    borderTopLeftRadius: theme.spacing(0.5)
  },
  litstItemHeader: {
    fontSize: 'xx-small',
    flex: 'inherit'
  },
  listItemAdminHeader: {
    color: theme.palette.primary.main
  },
  listItemHeaderRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  senderListItemHeaderRow: {
    justifyContent: 'flex-end'
  },
  dateText: {
    marginLeft: theme.spacing(3)
  },
  contentText: {
    fontSize: 'small',
    overflowWrap: 'anywhere',
    alignSelf: 'flex-start',
    userSelect: 'auto'
  },
  messageSection: {
    flexGrow: 1,
    margin: '0 !important'
  },
  messageAttachmentSection: {
    flexGrow: 1,
    margin: '0 !important'
  },
  attachmentSectionRoot: {
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingTop: `${theme.spacing(2.5)} important`
  },
  mainDialog: {
    // paddingTop: theme.spacing(1),
    '& .MuiDialog-paper': {
      minWidth: '30%',
      border: '1px solid #F37320',
      borderRadius: '10px 10px 0px 0px'
    }
  },
  fabProgress: {
    margin: 5
  },
  listItemAttachment: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start'
  },
  fileName: {
    paddingLeft: theme.spacing(2),
    fontSize: 'xx-small',
    fontWeight: 'bold',
    overflowWrap: 'anywhere',
    userSelect: 'auto'
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto'
  },
  iconCoverChat: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(0),
    '& svg': {
      height: '100%'
    }
  },
  inputAdornment: {
    alignSelf: 'flex-start',
    width: '100%',
    marginBottom: theme.spacing(2.5)
  },
  selectedAttachment: {
    borderRadius: 0,
    justifyContent: 'space-between',
    flexGrow: 1,
    width: 'inherit',
    position: 'relative',
    top: theme.spacing(0.5)
  },
  quoteMessage: {
    position: 'absolute',
    left: 0,
    top: theme.spacing(-3),
    backgroundColor: theme.palette.primary.white
  },
  dialogContent: {
    padding: 0
  },
  chatNotes: {
    background: theme.palette.primary.chatNotes,
    padding: theme.spacing(1),
    color: theme.palette.primary.subText,
    textAlign: 'center',
    fontSize: 'xx-small'
  },
  chatCrossButton: {
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  chatAlertsText: {
    marginLeft: theme.spacing(6)
  },
  chatAlerts: {
    background: theme.palette.primary.chatNotes,
    padding: theme.spacing(1),
    color: theme.palette.primary.errorText,
    textAlign: 'center',
    fontSize: 'small',
    display: 'flex'
  },
  chatPopupText: {
    fontSize: 'small',
    whiteSpace: 'pre-line'
  },
  chatPopupHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  chatDisclaimer: {
    background: theme.palette.secondary[50],
    padding: theme.spacing(1),
    color: theme.palette.primary,
    fontSize: 'xx-small',
    textAlign: 'left',
    marginBottom: theme.spacing(0.5)
  },
  closeChatPopupButton: {
    padding: 0,
    minWidth: 0
  },
  dialogActions: {
    alignItems: 'inherit',
    flexDirection: 'column'
  },
  messageInfo: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'grey',
    boxShadow: 'none',
    padding: theme.spacing(0, 1),
    '& .MuiAlert-icon': {
      alignItems: 'center'
    }
  },
  messageTextFieldRow: {
    display: 'flex',
    margin: '0 !important'
  },
  snackbar: {
    position: 'absolute',
    width: '98%',
    '& .MuiSnackbarContent-root': {
      marginTop: theme.spacing(3),
      backgroundColor: 'grey',
      flexWrap: 'nowrap'
    }
  },
  snackbarPadding: {
    paddingTop: theme.spacing(11.5)
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  chatBoxWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  leftPadding: {
    paddingLeft: theme.spacing(2)
  },
  chatBoxTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1.5),
    background: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '& svg': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5)
    }
  },
  chatBoxTitle: {
    paddingLeft: theme.spacing(1.5),
    fontWeight: 'bold'
  },
  replyIcon: {
    position: 'absolute',
    pointerEvents: 'auto',
    top: 0,
    right: 0
  },
  messageDate: {
    marginRight: theme.spacing(3)
  },
  quoteSection: {
    backgroundColor: theme.palette.primary.quoteText,
    borderWidth: theme.spacing(0.125, 0.125, 0.125, 0.5),
    borderStyle: 'solid',
    borderColor: theme.palette.primary.quoteTextBorder,
    borderRadius: theme.spacing(0.75),
    padding: theme.spacing(1),
    pointerEvents: 'auto',
    alignSelf: 'flex-start'
  }
}));
