import * as React from 'react';
import { DialogTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

const styles = theme => ({
  header: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold'
  }
});

const SGUserDialog = props => {
  const [ open, setOpen ] = React.useState(false);

  useEffect(
    () => {
      if (props.open) {
        setOpen(true);
      }
    },
    [ props ]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={props.classes.header} id="alert-dialog-title">
          {'Account Already Exists'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You already have an account on Solargraf
            {props.user.company_id ? ` with company id :  ${props.user.company_id}` : ''} which is not the right company.
            Please contact admin to remove your account from the existing company and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(SGUserDialog);
