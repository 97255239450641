import settings from '../../../utils/settings';
import { isEqual } from '../../../utils/lodash';
import { getToken } from '../../../utils/helper';
import { take, put, call, takeEvery } from 'redux-saga/effects';
import { deleteMethod, get } from '../../../utils/api';
import {
  GET_STRUCTURAL_USERS_REQUEST,
  GET_STRUCTURAL_USERS_SUCCESS,
  GET_STRUCTURAL_USERS_FAILURE,
  GET_STRUCTURAL_ASSEMBLY_RACK_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_RACK_SUCCESS,
  GET_STRUCTURAL_ASSEMBLY_RACK_FAILURE,
  GET_STRUCTURAL_ASSEMBLY_STANDOFF_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_STANDOFF_SUCCESS,
  GET_STRUCTURAL_ASSEMBLY_STANDOFF_FAILURE,
  GET_ALL_STRUCTURAL_ASSEMBLY_REQUEST,
  GET_ALL_STRUCTURAL_ASSEMBLY_SUCCESS,
  GET_ALL_STRUCTURAL_ASSEMBLY_FAILURE,
  GET_STRUCTURAL_ASSEMBLY_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_SUCCESS,
  GET_STRUCTURAL_ASSEMBLY_FAILURE,
  CREATE_STRUCTURAL_ASSEMBLY_REQUEST,
  CREATE_STRUCTURAL_ASSEMBLY_SUCCESS,
  CREATE_STRUCTURAL_ASSEMBLY_FAILURE,
  GET_STRUCTURAL_FILE_DELETE_REQUEST,
  GET_STRUCTURAL_FILE_DELETE_SUCCESS,
  GET_STRUCTURAL_FILE_DELETE_FAILURE,
  GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_REQUEST,
  GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_SUCCESS,
  GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_FAILURE,
  GET_STRUCTURAL_DELETE_REQUEST,
  GET_STRUCTURAL_DELETE_SUCCESS,
  GET_STRUCTURAL_DELETE_FAILURE,
  GET_ASSEMBLY_COM_SUCCESS,
  GET_ASSEMBLY_COM_REQUEST,
} from '../constants';
import { deleteStructuralFailure, deleteStructuralSuccess } from './actions';

export function* getAllUsersStFlow() {
  while (true) {
    const request = yield take(GET_STRUCTURAL_USERS_REQUEST);
    const { successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/structural/users`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_STRUCTURAL_USERS_SUCCESS,
          usersList: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_STRUCTURAL_USERS_FAILURE, message: error });
    }
  }
}

export function* getAllRackFlow() {
  while (true) {
    const request = yield take(GET_STRUCTURAL_ASSEMBLY_RACK_REQUEST);
    const { successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/structural/racks?status=All`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_STRUCTURAL_ASSEMBLY_RACK_SUCCESS,
          racksList: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_STRUCTURAL_ASSEMBLY_RACK_FAILURE, error: error });
    }
  }
}

export function* getAllStandoffFlow() {
  while (true) {
    const request = yield take(GET_STRUCTURAL_ASSEMBLY_STANDOFF_REQUEST);
    const { successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/structural/standoffs?status=All`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_STRUCTURAL_ASSEMBLY_STANDOFF_SUCCESS,
          standoffsList: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_STRUCTURAL_ASSEMBLY_STANDOFF_FAILURE, error: error });
    }
  }
}

export function* getAllRoofTypeFlow() {
  while (true) {
    const request = yield take(GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_REQUEST);
    const { successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/structural/roofMaterials`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_SUCCESS,
          rooftypesList: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_FAILURE, error: error });
    }
  }
}

let getAllStructural = false;
export function* getAllStructuralAssemblyFlow() {
  while (true) {
    const request = yield take(GET_ALL_STRUCTURAL_ASSEMBLY_REQUEST);
    const {
      after = '',
      before = '',
      structuralType,
      recordCount,
      sort_column,
      sort_order,
      roof_material = '',
      rack_details = '',
      standoff_details = '',
      modified_by = '',
      from_modified_at = '',
      to_modified_at = '',
      successCb = () => { },
    } = request.payload;
    getAllStructural = true;
    const desc = isEqual(sort_order, 'asc') ? false : isEqual(sort_order, 'desc') ? true : '';
    const sortColumn = isEqual(sort_column, 'modified_at') ? 'updatedAt' : sort_column;

    let defaultParams =
      `limit=${recordCount}&rackId=${rack_details}&standoffId=${standoff_details}&sort=${sortColumn}&desc=${desc}&roofType=${roof_material}&after=${after}&before=${before}` +
      `&fromModifiedAt=${from_modified_at}&toModifiedAt=${to_modified_at}&modifiedBy=${modified_by}`;

    try {
      let response;

      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/structural/${structuralType}/?${defaultParams}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      getAllStructural = false;
      if (success && status === 200) {
        yield put({
          type: GET_ALL_STRUCTURAL_ASSEMBLY_SUCCESS,
          structuralAssemblyList: result['data'],
          pagingData: result['paging']['cursors'],
          totalElements: result['paging']['total'],
          message: '',
        });

        successCb();
      } else {
        yield put({
          type: GET_ALL_STRUCTURAL_ASSEMBLY_FAILURE,
          message: result.message || result.messages,
        });
      }
    } catch (error) {
      getAllStructural = false;
      yield put({ type: GET_ALL_STRUCTURAL_ASSEMBLY_FAILURE, message: error });
    }
  }
}

let getAssemblyCom = false;
export function* getAssemblyComFlow() {
  while (true) {
    const request = yield take(GET_ASSEMBLY_COM_REQUEST);
    const {
      structuralType,
      roof_material = '',
      rack_details = '',
      standoff_details = '',
      failureCb,
      successCb = () => { },
    } = request.payload;
    getAssemblyCom = true;
    if (!getAllStructural) {
      let defaultParams =
        `limit=${1}&rackId=${rack_details}&standoffId=${standoff_details}&roofType=${roof_material}`;

      try {
        let response;
        response = yield call(get, {
          url: settings.permittingCoreUrl(`permit/structural/${structuralType}/?${defaultParams}`),
          withBearer: true,
        });
        const { success, status, result } = response;
        getAssemblyCom = false;
        if (success && status === 200) {
          successCb(result['data'].length, result['data']);
        } else {
          failureCb(0);
        }
      } catch (error) {
        getAssemblyCom = false;
        failureCb(0)
      }
    }
  }
}

export function* getStructuralAssemblyFlow() {
  while (true) {
    const request = yield take(GET_STRUCTURAL_ASSEMBLY_REQUEST);
    const { id, requestSource, successCB, failureCB } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/structural/${requestSource}/${id}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_STRUCTURAL_ASSEMBLY_SUCCESS,
          structural: result,
        });
        if (result.id) successCB(result);
      } else {
        yield put({ type: GET_STRUCTURAL_ASSEMBLY_FAILURE, message: result.message || result.messages });
        failureCB();
      }
    } catch (error) {
      yield put({ type: GET_STRUCTURAL_ASSEMBLY_FAILURE, message: error });
    }
  }
}

let createStructuralSuccess = false;
export function* createStructuralAssemblySuccessFlow() {
  if (!createStructuralSuccess) {
    while (true) {
      const request = yield take(CREATE_STRUCTURAL_ASSEMBLY_SUCCESS);
      const response = request.payload.response;
      const { success, status, result, message } = response;
      try {
        createStructuralSuccess = true;
        if (success && (status === 200 || status === 201)) {
          yield put({
            type: CREATE_STRUCTURAL_ASSEMBLY_SUCCESS,
            message,
            structural: result,
          });
          createStructuralSuccess = false;
        } else {
          yield put({ type: CREATE_STRUCTURAL_ASSEMBLY_FAILURE, message: response.description || response.messages });
          createStructuralSuccess = false;
        }
      } catch (error) {
        yield put({ type: CREATE_STRUCTURAL_ASSEMBLY_FAILURE, message: error });
        createStructuralSuccess = false;
      }
    }
  }
}

let createStructuralAssemblyRequest = false;
export function* createStructuralAssemblyFlow() {
  while (true) {
    const request = yield take(CREATE_STRUCTURAL_ASSEMBLY_REQUEST);
    const { requestBody, successCB, failureCB, id = 'new' } = request.payload;

    if (!createStructuralAssemblyRequest) {
      createStructuralAssemblyRequest = true
      const formData = new FormData();
      formData.append(
        'jsonPayload',
        new Blob([JSON.stringify(requestBody['jsonPayload'])], {
          type: 'application/json',
        })
      );
      formData.append('assembly', requestBody['assembly']);

      try {
        const token = getToken();
        const requestOptions = {
          method: isEqual(id, 'new') ? 'POST' : 'PUT',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        fetch(settings.permittingCoreUrl(`permit/structural/${requestBody['jsonPayload']['requestSource']}/${id}`), requestOptions).then((data) =>
          data.json().then((response) => {
            const { success, status, result } = response;
            createStructuralAssemblyRequest = false
            if (success && (status === 200 || status === 201)) {
              if (result.id) {
                successCB(result, response);
              }
            } else {
              failureCB(response);
            }
          })
        );
      } catch (error) {
        createStructuralAssemblyRequest = false;
        yield put({ type: CREATE_STRUCTURAL_ASSEMBLY_FAILURE, message: error });
      }
    }
  }
}

export function* deleteStructuralUploadedFileFlow() {
  yield takeEvery(GET_STRUCTURAL_FILE_DELETE_REQUEST, deleteStructuralUploadedFileFlowRequest);
}

export function* deleteStructuralUploadedFileFlowRequest(request) {
  const { id, fileType, requestSource, successCb } = request.payload;
  try {
    let response;
    response = yield call(deleteMethod, {
      url: settings.permittingCoreUrl(`permit/structural/${requestSource}/${id}/${fileType}`),
      withBearer: true,
    });
    const { success, status } = response;
    if (success && status === 200) {
      yield put({
        type: GET_STRUCTURAL_FILE_DELETE_SUCCESS,
      });
    }
    successCb();
  } catch (error) {
    yield put({ type: GET_STRUCTURAL_FILE_DELETE_FAILURE, message: error });
  }
}

export function* deleteStructuralFlow() {
  yield takeEvery(GET_STRUCTURAL_DELETE_REQUEST, deleteStructuralFlowRequest);
}

export function* deleteStructuralFlowRequest(request) {
  const { id, requestSource, successCb } = request.payload;
  try {
    // const { id, requestSource } = action.payload;
    const response = yield call(deleteMethod, {
      url: settings.permittingCoreUrl(`permit/structural/${requestSource}/${id}/`),
      withBearer: true,
    });
    const { success, status } = response;
    if (success && status === 200) {
      yield put({
        type: GET_STRUCTURAL_DELETE_SUCCESS,
      });
    }
    successCb();
  } catch (error) {
    yield put({ type: GET_STRUCTURAL_DELETE_FAILURE, message: error });
  }
}
