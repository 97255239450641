import {
  REQUEST_FAILURE,
  SET_EMAIL_ID,
  SET_ENLIGHTEN_MANAGER_TOKEN,
  SET_MOBILE_VIEW,
  GET_ACCOUNT_DETAIL_REQUEST,
  GET_ACCOUNT_DETAIL_SUCCESS,
  GET_ACCOUNT_DETAIL_FAILURE
} from './constants';

let initialState = {
  isMobileView: false,
  loading: false,
  emailId: '',
  firstName: '',
  lastName: '',
  enlightenManagerToken: '',
  permitServiceRole: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FAILURE:
      return { ...state, message: action.error, messageType: 'error', loading: false };
    case SET_EMAIL_ID:
      return { ...state, emailId: action.emailId };
    case SET_ENLIGHTEN_MANAGER_TOKEN:
      return { ...state, enlightenManagerToken: action.enlightenManagerToken };
    case SET_MOBILE_VIEW:
      return { ...state, isMobileView: action.isMobileView };
    case GET_ACCOUNT_DETAIL_REQUEST:
      return { ...state, loading: true };
    case GET_ACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        permitServiceRole: action.permitServiceRole,
        emailId: action.emailId,
        firstName: action.firstName,
        lastName: action.lastName
      };
    case GET_ACCOUNT_DETAIL_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
