import { SOURCE } from '../../../utils/constants';

export const MY_LIST = 'My List';
export const OTHERS_LIST = "Others' List";
export const LIST = 'list';
export const DEFAULT_ROW_PER_PAGE = 50;

export const EXPANDED_OPTION = {
  MY_LIST: 1,
  OTHERS_LIST: 2,
};

export const initialState = {
  page: 0,
  rowsPerPage: DEFAULT_ROW_PER_PAGE,
  sort_column: 'third_party_current_status.last_updated_date',
  sort_order: '',
  chartData: {},
  showInstallDateOption: false,
  installDateFilterText: '',
  isDateRangeOpen: false,
  selectSpecificDateOpen: false,
  isAllDaysBefore: false,
  isAllDaysAfter: false,
  enableButton: false,
  dateRange: {},
  enableSelectedButton: false,
  selectedDate: '',
  createdDateSelectValue: '',
  lastModifiedSelectValue: '',
  lastModifiedFilterText: '',
  isFromCreatedDate: false,
  isFromLastModifiedDate: false,
  anchorEl: null,
  selectedPermitId: '',
  selectedApplication: {},
  showExternalRevisionPopup: false,
  selectedStatus: [0],
  selectedVersion: ['ALL'],
  selectedProjectType: ['ALL'],
  selectedInstaller: ['All'],
  hideChart: true,
  isDownloadPopupVisible: false,
  overallApplicationsTableSize: 12,
  hideChartButtonName: 'Overall Application Status',
  showAllRecords: '',
  filterValues: {},
  installersList: [],
  selectedInstallerValue: [],
  globalFilter: 'all',
  openInstallerListDialog: false,
  showDropdown: false,
  expandedOption: EXPANDED_OPTION.MY_LIST,
  selectedAdmin: '',
  dropdownValue: MY_LIST,
  assignTo: '',
};

export const initialFilterParams = {
  installerIds: [],
  customerName: '',
  siteId: '',
  version: [],
  projectType: [],
  city: '',
  state: '',
  permitId: '',
  status: [],
  startDate: '',
  endDate: '',
  lastModifiedStartDate: '',
  lastModifiedEndDate: '',
  assignTo: '',
  showAllRecords: '',
  projectName: '',
  globalFilter: 'all',
  list: 'all',
};

export const initAppType = SOURCE.ENLIGHTEN_PERMIT;

export const unreadMessageCountFilterList = [
  { title: 'All', value: 'all', flag: false },
  { title: 'Applications with unread messages', value: 'unreadMessageCountFilter' },
  { title: 'Stamping Approval Pending', value: 'stampaingApprovalPending' },
];

export const MY_LIST_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Unassigned List', value: 'unassigned' },
];

export const OTHER_LIST_OPTIONS = [{ label: 'All', value: 'others' }];

export const DropdownMenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const CHANGE_TYPE = {
  OPTIONS_CHANGE: 'CHANGE',
  OPTIONS_RESET: 'RESET',
};
