import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Grid, ExpansionPanel, ExpansionPanelDetails, Tooltip, IconButton } from '@material-ui/core';
import ViewIcon from '../images/viewIcon';
import { get, isEmpty, split } from 'lodash';
import { handlePopupBlocker, formatString, downloadFileSG } from '../../../../utils/helper';
import { getS3URL } from '../../../../containers/Permitting/action';
import { TagLabel } from '../../../common/TagLabel';

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#111111',
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: theme.spacing(1),
    minWidth: theme.spacing(15),
    maxWidth: 350,
    pointerEvents: 'auto',
    fontWeight: 'normal'
  },
  arrow: {
    color: 'white',
    fontSize: 14
  }
}))(Tooltip);

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    fontSize: '14px',
    display: 'flex'
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px',
    height: '40px',
    cursor: 'unset'
  },
  panelDetails: {
    flexDirection: 'column',
    boxShadow: '1px -1px 4px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0
  },
  headingInfo: {
    color: 'darkgray'
  },
  dataSubHeader: {
    fontSize: '12px',
    color: 'grey',
    display: 'flex'
  },
  padding4: {
    paddingTop: theme.spacing(3.125),
    paddingBottom: theme.spacing(0.625)
  }
});

export class PanelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSheetToolTip: false
    };
  }

  splitOtherValues(value) {
    let otherValue = !isEmpty(value) && value.startsWith('Other-') ? split(value, '-', 2)[1] : value;
    return otherValue;
  }

  getSizeOfRafter(arrayItem) {
    return arrayItem && arrayItem.structural_number && arrayItem.structural_number === 'Other'
      ? arrayItem.other_structural_number
      : arrayItem.structural_number;
  }

  downloadFileS3 = (fileName, downloadFileName) => {
    this.props.getS3URL({
      fileName: fileName,
      methodType: 'GET',
      downloadFileName: downloadFileName,
      successCb: preSignedS3Url => {
        const myWindow = window.open(preSignedS3Url, '_blank');
        handlePopupBlocker(myWindow);
      },
      failureCb: () => {
        return false;
      }
    });
  };

  getModifiedtag(arrayItem, name) {
    const { originalApplicationDetails, toolTipId, applicationDetail } = this.props;
    let ExPanelArray = get(originalApplicationDetails, 'material_details.existing_arrays');
    let panelArray = get(originalApplicationDetails, 'material_details.arrays');

    let compareArray = [];
    if (isEmpty(originalApplicationDetails)) {
      return null;
    }
    if (toolTipId === 'existing_array' && !isEmpty(ExPanelArray)) {
      compareArray = ExPanelArray.filter(s => s.id === arrayItem.id);
    } else if (!isEmpty(panelArray)) {
      compareArray = panelArray.filter(s => s.id === arrayItem.id);
    }
    if (isEmpty(compareArray)) {
      if (arrayItem[name]) {
        return <TagLabel add applicationDetail={applicationDetail} />
      }
    } else {
      if (compareArray[0][name] != arrayItem[name]) {
        if (!compareArray[0][name]) {
          return <TagLabel add applicationDetail={applicationDetail} />;
        } else {
          return <TagLabel edit applicationDetail={applicationDetail} />;
        }
      }
    }
  }

  getDeletedtag() {
    const { originalApplicationDetails, toolTipId, arrayList, applicationDetail } = this.props;
    let deletedValue = 0;
    let ExPanelArray = get(originalApplicationDetails, 'material_details.existing_arrays');
    let panelArray = get(originalApplicationDetails, 'material_details.arrays');

    if (toolTipId === 'existing_array') {
      if (isEmpty(arrayList) && !isEmpty(ExPanelArray)) {
        return (
          <TagLabel edit text={'[' + ExPanelArray.length() + 'some array(s)'} applicationDetail={applicationDetail} />
        );
      } else if (!isEmpty(ExPanelArray)) {
        ExPanelArray.map(item => {
          let compareArray = arrayList.filter(s => s.id === item.id);
          if (isEmpty(compareArray)) {
            deletedValue += 1;
          }
        });
      }
    } else {
      if (isEmpty(arrayList) && !isEmpty(panelArray)) {
        return (
          <TagLabel edit text={'[' + panelArray.length() + 'some array(s)'} applicationDetail={applicationDetail} />
        );
      } else if (!isEmpty(panelArray)) {
        panelArray.map(item => {
          let compareArray = arrayList.filter(s => s.id === item.id);
          if (isEmpty(compareArray)) {
            deletedValue += 1;
          }
        });
      }
    }
    if (deletedValue > 0) {
      return <TagLabel edit text={'[' + deletedValue + ' array(s) deleted ]'} applicationDetail={applicationDetail} />;
    }
  }

  componentDidMount() {
    const { arrayList, toolTipId = '' } = this.props;
    const stateList = [];
    for (const i in arrayList) {
      let id = toolTipId + '_' + i;
      if (!isEmpty(arrayList[i].spec_sheets)) {
        stateList.push({ id: false });
      }
      if (i == arrayList.length - 1) {
        this.setState({ ...this.state, stateList });
      }
    }
  }

  render() {
    const { classes, arrayList, title, downloadFile, toolTipId = '', originalApplicationDetails } = this.props;

    return (
      <Fragment>
        {arrayList != null ? (
          <Fragment>
            <Grid container className={classes.padding4} direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.fontBold}>
                  {title}
                  {this.getDeletedtag()}
                </Typography>
                <br />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={12}>
                {arrayList != null &&
                  arrayList.map((arrayItem, index) => {
                    let array_id = toolTipId + '_' + index;
                    return (
                      <ExpansionPanel expanded={true}>
                        <ExpansionPanelDetails className={classes.panelDetails} style={{ flexDirection: 'column' }}>
                          <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Panel manufacturer {this.getModifiedtag(arrayItem, 'panel_manufacturer')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.panel_manufacturer ? (
                                  this.splitOtherValues(arrayItem.panel_manufacturer)
                                ) : (
                                  '--'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Panel Model {this.getModifiedtag(arrayItem, 'panel_model')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.panel_model ? this.splitOtherValues(arrayItem.panel_model) : '--'}
                                {!isEmpty(arrayItem.spec_sheets) ? (
                                  <LightTooltip title="View Spec Sheet" open={this.state.array_id} placement="right">
                                    <IconButton
                                      onClick={() =>
                                        this.downloadFileS3(
                                          arrayItem.spec_sheets[0].file_name,
                                          formatString(
                                            this.splitOtherValues(arrayItem.panel_manufacturer) +
                                              '_' +
                                              this.splitOtherValues(arrayItem.panel_model)
                                          )
                                        )}
                                      onMouseEnter={() =>
                                        this.setState({
                                          array_id: true
                                        })}
                                      onMouseLeave={() =>
                                        this.setState({
                                          array_id: false
                                        })}
                                      aria-label="info"
                                      className={classes.iconButton}
                                    >
                                      <ViewIcon />
                                    </IconButton>
                                  </LightTooltip>
                                ) : !isEmpty(arrayItem.sg_spec_sheet) ? (
                                  <LightTooltip title="View Spec Sheet" open={this.state.array_id} placement="right">
                                    <IconButton
                                      onClick={() => {
                                        downloadFileSG(
                                          arrayItem.sg_spec_sheet,
                                          formatString(
                                            this.splitOtherValues(arrayItem.panel_manufacturer) +
                                              '_' +
                                              this.splitOtherValues(arrayItem.panel_model)
                                          )
                                        );
                                      }}
                                      onMouseEnter={() =>
                                        this.setState({
                                          array_id: true
                                        })}
                                      onMouseLeave={() =>
                                        this.setState({
                                          array_id: false
                                        })}
                                      aria-label="info"
                                      className={classes.iconButton}
                                    >
                                      <ViewIcon />
                                    </IconButton>
                                  </LightTooltip>
                                ) : null}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Panel Wattage {this.getModifiedtag(arrayItem, 'panel_wattage')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.panel_wattage != null && arrayItem.panel_wattage != undefined ? (
                                  arrayItem.panel_wattage
                                ) : (
                                  '--'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Quantity {this.getModifiedtag(arrayItem, 'quantity')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.quantity != null && arrayItem.quantity != undefined ? (
                                  arrayItem.quantity
                                ) : (
                                  '--'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Length (Long Side) {this.getModifiedtag(arrayItem, 'length')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.length ? arrayItem.length : '--'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Width (Short Side) {this.getModifiedtag(arrayItem, 'width')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.width ? arrayItem.width : '--'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Weight {this.getModifiedtag(arrayItem, 'weight')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.weight ? arrayItem.weight : '--'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Installation Type {this.getModifiedtag(arrayItem, 'installation_type')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.installation_type || '--'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Roof Type {this.getModifiedtag(arrayItem, 'roof_type')}
                              </Typography>
                              <Typography className={classes.fontBold}>{arrayItem.roof_type || '--'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Roof Pitch (Deg) {this.getModifiedtag(arrayItem, 'roof_pitch')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.roof_pitch != null && arrayItem.roof_pitch != undefined ? (
                                  arrayItem.roof_pitch
                                ) : (
                                  '--'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Array Pitch (Deg) {this.getModifiedtag(arrayItem, 'pitch_deg')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.pitch_deg != null && arrayItem.pitch_deg != undefined ? (
                                  arrayItem.pitch_deg
                                ) : (
                                  '--'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Azimuth (Deg) {this.getModifiedtag(arrayItem, 'azimuth')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.azimuth != null && arrayItem.azimuth != undefined ? arrayItem.azimuth : '--'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Is Tilt Mounted {this.getModifiedtag(arrayItem, 'is_tilt_mounted')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.is_tilt_mounted &&
                                arrayItem.is_tilt_mounted != null &&
                                arrayItem.is_tilt_mounted != undefined ? arrayItem.is_tilt_mounted === true ? (
                                  'Yes'
                                ) : (
                                  'No'
                                ) : (
                                  '--'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Max Rafter Span (inches) {this.getModifiedtag(arrayItem, 'max_rafter_span')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.max_rafter_span != null && arrayItem.max_rafter_span != undefined ? (
                                  arrayItem.max_rafter_span
                                ) : (
                                  '--'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Size of Rafter {this.getModifiedtag(arrayItem, 'structural_number')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.structural_number != null && arrayItem.structural_number != undefined ? (
                                  this.getSizeOfRafter(arrayItem)
                                ) : (
                                  '--'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Oncenter Spacing (inches) {this.getModifiedtag(arrayItem, 'structural_at')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.structural_at != null && arrayItem.structural_at != undefined ? (
                                  arrayItem.structural_at
                                ) : (
                                  '--'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className={classes.dataSubHeader}>
                                Rafter Type {this.getModifiedtag(arrayItem, 'structural_type')}
                              </Typography>
                              <Typography className={classes.fontBold}>
                                {arrayItem.structural_type != null && arrayItem.structural_type != undefined ? (
                                  arrayItem.structural_type
                                ) : (
                                  '--'
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    );
                  })}
              </Grid>
            </Grid>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getS3URL: payload => dispatch(getS3URL(payload))
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PanelList));
